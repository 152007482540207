import React, { useState } from 'react';
import editIcon from '@src/assets/pencil.svg';
import deleteIcon from '@src/assets/delete2.svg';
import downloadIcon from '@src/assets/icon-download.svg';
import ModalConfirm from '@src/components/ModalConfirm';
import { useDispatch } from 'react-redux';
import useCaptions from '@src/hooks/useCaptions';
import {
    editCaption,
    removeCaption,
    toggleAddCaptionUploadModal,
} from '@src/store/modules/captions/actions';
import api from '@src/services/api';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import NoContent from '@src/components/NoContent';
import { useTranslation } from 'react-i18next';
import languageConstants from '@langs/constants';
import { useParams } from 'react-router-dom';
import { Container, TableButton } from './styles';

let selectedCaptionId = null;

function renderStatusText(status, t) {
    const type = {
        ERROR: t(languageConstants.CAPTION_STATUS_ERROR),
        QUEUED: t(languageConstants.CAPTION_STATUS_QUEUED),
        READY: t(languageConstants.CAPTION_STATUS_READY),
        DELETED: t(languageConstants.CAPTION_STATUS_REMOVED),
        IMPORTING: t(languageConstants.CAPTION_STATUS_IMPORTING),
        EXPORTING: t(languageConstants.CAPTION_STATUS_EXPORTING),
    };

    return type[status] || t(languageConstants.LOADING);
}
const LoadingSkeleton = () => (
    <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
        <div style={{ marginTop: 10, padding: 20 }}>
            <Skeleton height={50} count={10} style={{ margin: '5px 0' }} />
        </div>
    </SkeletonTheme>
);

export default function CaptionsTable() {
    const { id: roomId } = useParams();
    const dispatch = useDispatch();
    const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
    const [downloadingCaptionsList, setDownloadingCaptionsList] = useState([]);
    const { captionList, removingList, isFetching } = useCaptions();
    const { t } = useTranslation();

    function handleShowConfirmModal(captionId) {
        selectedCaptionId = captionId;
        setShowConfirmRemoveModal(true);
    }

    function closeShowConfirmModal() {
        setShowConfirmRemoveModal(false);
    }

    function handleRemoveCaption(captionId) {
        dispatch(removeCaption(roomId, captionId));
        closeShowConfirmModal();
    }

    function isRemovingItem(captionId) {
        return !!removingList.find(item => item === captionId);
    }

    function handleEditCaption(language) {
        dispatch(editCaption(language));
        dispatch(toggleAddCaptionUploadModal(true));
    }

    function handleAddNewCaption() {
        dispatch(toggleAddCaptionUploadModal(true));
    }

    async function handleDownloadCaption(caption) {
        setDownloadingCaptionsList(state => [...state, caption.id]);
        const { caption: captionStr } = await api.getCaptionDownloadUrl(roomId, caption.id);
        const str2blob = txt => new Blob([txt]);
        const resultFileName = `${caption.language}-${caption.createdAt}.srt`;
        const resultFileType = 'text/srt';
        const resultFile = new File([str2blob(captionStr)], resultFileName, { type: resultFileType });
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        const url = window.URL.createObjectURL(resultFile);
        a.href = url;
        a.download = resultFileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setDownloadingCaptionsList(state => state.filter(item => item !== caption.id));
    }

    function isDownloadingCaption(captionId) {
        const queuedCaption = downloadingCaptionsList.find(item => item === captionId);
        return !!queuedCaption;
    }

    if (isFetching) {
        return (<LoadingSkeleton />);
    }

    if (!isFetching && !captionList.length) {
        return (
            <NoContent
                title={t(languageConstants.NO_CAPTIONS_FOUND)}
                text={t(languageConstants.FIRST_CAPTION_MESSAGE)}
                button={{ text: t(languageConstants.ADD_CAPTION), action: handleAddNewCaption }}
            />
        );
    }

    return (
        <>
            <ModalConfirm
                visible={showConfirmRemoveModal}
                handleCancel={closeShowConfirmModal}
                handleModal={closeShowConfirmModal}
                handleConfirm={() => handleRemoveCaption(selectedCaptionId)}
                description={t(languageConstants.REMOVE_CAPTION_CONFIRMATION)}
                title={t(languageConstants.REMOVE)}
                featuredTitle={t(languageConstants.CAPTION)}
            />

            <Container>
                <thead>
                    <tr>
                        <th>{t(languageConstants.LANGUAGE)}</th>
                        <th>{t(languageConstants.STATUS)}</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        captionList.map(caption => (
                            <tr key={caption.id}>
                                <td>{t(caption.language.toUpperCase())}</td>
                                <td>{renderStatusText(caption.status, t)} </td>
                                <td>
                                    <TableButton
                                        disabled={isRemovingItem(caption.id) || isDownloadingCaption(caption.id)}
                                        type="button"
                                        onClick={() => handleEditCaption(caption)}
                                    >
                                        <img src={editIcon} alt="edit icon" />
                                        {t(languageConstants.EDIT)}
                                    </TableButton>
                                </td>
                                <td>
                                    <TableButton
                                        disabled={isRemovingItem(caption.id) || isDownloadingCaption(caption.id)}
                                        type="button"
                                        onClick={() => handleShowConfirmModal(caption.id)}
                                    >
                                        <img src={deleteIcon} alt="edit icon" />
                                        {
                                            isRemovingItem(caption.id)
                                                ? t(languageConstants.REMOVING)
                                                : t(languageConstants.REMOVE)
                                        }
                                    </TableButton>
                                </td>
                                <td>
                                    <TableButton
                                        disabled={isRemovingItem(caption.id) || isDownloadingCaption(caption.id)}
                                        type="button"
                                        onClick={() => handleDownloadCaption(caption)}
                                    >
                                        <img src={downloadIcon} alt="edit icon" />
                                        {
                                            isDownloadingCaption(caption.id)
                                                ? t(languageConstants.DOWNLOADING)
                                                : t(languageConstants.DOWNLOAD_CAPTION)
                                        }
                                    </TableButton>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Container>
        </>
    );
}
