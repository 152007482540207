import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import NoContent from '@src/components/NoContent';

import dashboardIcon from '@src/assets/dashboardIcon.svg';
import detailIcon from '@src/assets/shape.svg';
import playIcon from '@src/assets/play-1.svg';
import useRooms from '@src/hooks/useRooms';
import { getChannelRooms, fetchEditRoom } from '@src/store/modules/rooms/actions';
import downloadIcon from '@src/assets/download-icon.svg';
import eyeIcon from '@src/assets/eye.svg';

import mediaIcon from '@src/assets/media.svg';
import attachmentIcon from '@src/assets/attachment-1.svg';

import { useSidebar } from '@src/hooks/useSidebar';
import Notify from '@src/utils/notification';

import { urls } from '@src/utils/constants';
import api from '@src/services/api';

import constants from '@src/assets/i18n/constants';
import {
    Header,
    ContainerRectangle,
    RectangleCard,
    TableVideoStats,
    Main,
    TableFooterResults,
    UserWacthSpan,
} from './style';

const menuItemsListBuilder = (roomId, t) => ([
    {
        link: `/courseRecorded/${roomId}`,
        title: t(constants.STATISTIC_VIDEO_DETAIL),
        className: 'courseRecorded',
        dataTour: ' tour__courseRecorded',
        icon: detailIcon,
        alt: t(constants.STATISTIC_VIDEO_DETAIL),
    },
    {
        link: `/videoStats/${roomId}`,
        title: 'Dashboard',
        className: 'videoStats',
        dataTour: 'tour__dashboard',
        icon: dashboardIcon,
        alt: 'dashboard',
    }]);

/**
 * Formata o tempo recebido em minutos, retornando a string no formato HHh:MMm
 * @param {number} n tempo em minutos
 */
function formatFromMinutes(n) {
    if (!n) {
        return '0h0m';
    }
    const hours = (n / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return `${rhours}h${rminutes}m`;
}

export default function VideoStats() {
    const downloadButtonRef = useRef();
    const { selectedEditRoom, selectedChannelRooms, selectedChannel } = useRooms();
    const [selectedRoom, setSelectedRoom] = useState();
    const [errorHandlerVideo, setErrorHandlerVideo] = useState(false);
    const [dataVideo, setDataVideo] = useState([]);
    const [qtdPlay, setQtdPlay] = useState(0);
    const [qtdAvgTimeViewed, setQtdAvgTimeViewed] = useState('0h');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id: roomId } = useParams();
    const [downloadVideoUrl, setDownloadVideoUrl] = useState('');
    const { setItems } = useSidebar();
    const day = String(String(selectedEditRoom?.startDate).split('-')[2]).split('T')[0];

    const LoadingSkeleton = () => (
        <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
            <div style={{ marginTop: 10 }}>
                <Skeleton height={50} count={5} style={{ margin: '5px 0' }} wrapper={Main} />
            </div>
        </SkeletonTheme>
    );

    const ErrorNoContent = () => (
        <NoContent title={t(constants.CHANNEL_NOVIDEO)} />
    );

    async function handleDownloadLiveRecording() {
        await api.getLiveRecordingUrl(roomId).then((resp) => {
            const { downloadToken } = resp;
            const videodownloadAuthUrl = `${urls.BASE_API}rooms/${downloadToken}/start_download`;
            setDownloadVideoUrl(videodownloadAuthUrl);
            downloadButtonRef.current.click();
        }).catch(() => {
            Notify.warn(t(constants.DOWNLOAD_VIDEO_FAIL));
        });
    }

    useEffect(() => {
        (async () => {
            await api.getUploadVideoRecordingUrl(roomId).then((resp) => {
                const { data, meanAvgTimeViewed, totalPlays } = resp;
                setQtdPlay(totalPlays);
                setQtdAvgTimeViewed(formatFromMinutes(meanAvgTimeViewed));
                setDataVideo(data);
                setErrorHandlerVideo(false);
            }).catch(() => {
                setErrorHandlerVideo(true);
                Notify.warn(t(constants.CHANNEL_NOVIDEO));
            });
        })();
    }, [roomId, t]);

    useEffect(() => {
        if (selectedChannel?.id) { dispatch(getChannelRooms(selectedChannel?.id)); }
    }, [dispatch, roomId, selectedChannel]);

    useEffect(() => {
        if (roomId) {
            const foundRoom = selectedChannelRooms.find(item => item.id === roomId);
            setSelectedRoom(foundRoom);
        }
    }, [selectedChannelRooms, selectedRoom, roomId]);

    useEffect(() => {
        if (selectedRoom && roomId) {
            dispatch(fetchEditRoom(roomId, selectedRoom));
        }
    }, [dispatch, roomId, selectedRoom, setSelectedRoom]);

    useEffect(() => {
        setItems(menuItemsListBuilder(roomId, t));
    }, [roomId, setItems, t]);

    const ErrorNoData = () => {
        if (!(dataVideo.length > 0) && !selectedEditRoom) {
            return <LoadingSkeleton />;
        }

        return <ErrorNoContent />;
    };

    return (
        <>
            {errorHandlerVideo ? ErrorNoData() : (
                <Main>
                    <Header>
                        <h2> {`${selectedEditRoom?.name} - ${t(constants.STATISTIC_VIDEO_DAY)} ${day}`} </h2>
                        <div>
                            <button type="button" className="rectangle-header" onClick={() => handleDownloadLiveRecording()}>
                                <div className="rectangle-download-video">
                                    <img src={downloadIcon} alt={t(constants.STATISTIC_VIDEO_DOWNLOAD)} />
                                </div>
                                <span className="download-video">{t(constants.STATISTIC_VIDEO_DOWNLOAD)}</span>
                            </button>
                        </div>
                    </Header>
                    <ContainerRectangle>
                        <RectangleCard>
                            <div className="oval">
                                <img src={eyeIcon} alt={t(constants.STATISTIC_VIDEO_PEOPLE_WATCH)} />
                            </div>
                            <div className="content-container">
                                <span className="title">{dataVideo.length > 9 ? dataVideo.length : `0${dataVideo.length}` }</span>
                                <p className="description"> {t(constants.STATISTIC_VIDEO_PEOPLE_WATCH)} </p>
                            </div>
                        </RectangleCard>
                        <RectangleCard>
                            <div className="oval">
                                <img src={mediaIcon} alt={t(constants.STATISTIC_VIDEO_PEOPLE_AVERAGE_TIME_WATCH)} />
                            </div>
                            <div className="content-container">
                                <span className="title"> {qtdAvgTimeViewed} </span>
                                <p className="description"> {t(constants.STATISTIC_VIDEO_PEOPLE_AVERAGE_TIME_WATCH)} </p>

                            </div>
                        </RectangleCard>
                        <RectangleCard>
                            <div className="oval">
                                <img src={playIcon} alt={t(constants.STATISTIC_VIDEO_PLAY_IN_VIDEO)} />
                            </div>
                            <div className="content-container">
                                <span className="title">{qtdPlay }</span>
                                <p className="description"> {t(constants.STATISTIC_VIDEO_PLAY_IN_VIDEO)} </p>
                            </div>
                        </RectangleCard>
                        <RectangleCard>

                            <div className="oval">
                                <img src={attachmentIcon} alt={t(constants.STATISTIC_VIDEO_ATTACHMENTS_ADDED)} />
                            </div>
                            <div className="content-container">
                                <span className="title"> { selectedEditRoom?.attachments.length > 0 ? selectedEditRoom?.attachments.length : '01' } </span>
                                <div className="description">
                                    <span>{t(constants.STATISTIC_VIDEO_ATTACHMENTS_ADDED)}</span>
                                </div>
                            </div>

                        </RectangleCard>

                    </ContainerRectangle>
                    <UserWacthSpan>{t(constants.USERS_WHO_WATCH_THE_VIDEO)}</UserWacthSpan>
                    <div className="containerTable" data-tour="tour__container_table">

                        <TableVideoStats>
                            <Table>
                                <TableHead>
                                    <tr>
                                        <th align="left" colSpan="1">
                                            {t(constants.NAME)}
                                        </th>
                                        <th align="left">Email</th>

                                        <th align="left" colSpan="4">
                                            {t(constants.TIME_WATCHED)}
                                        </th>

                                    </tr>
                                </TableHead>

                                <TableBody>
                                    { dataVideo.map((data, index) => (
                                        <tr key={index}>
                                            <td align="left">
                                                {data?.name}
                                            </td>
                                            <td align="left">
                                                {data?.email}
                                            </td>
                                            <td align="left">
                                                {formatFromMinutes(data.sumTimeViewed)}
                                            </td>
                                        </tr>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableVideoStats>

                        <TableFooterResults>
                            <div className="table-footer">
                                <p className="result-found"> {dataVideo.length} RESULTADOS ENCONTRADOS</p>
                            </div>
                        </TableFooterResults>
                    </div>
                    <a className="download-video-button" href={downloadVideoUrl} ref={downloadButtonRef}>download video</a>
                </Main>
            )}
        </>
    );
}
