import styled from 'styled-components';

export const LiveContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .live-controllers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .chat-enabled {
    background-color: #24243e;
    word-break: break-word;
    margin: 15px 5px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 10px 10px;
    position: relative;
    border-radius: 8px;

    label {
      height: 1rem;
      right: 15px;
      width: 3.57rem;
      margin: auto;
      span {
          width: 1.42rem;
          height: 1.42rem;
          border-radius: 50%;
      }
    }

    .chat-mode-info {
        display: flex;
        justify-content: center;
    }

    h3 {
        color: #fff;
        font-size: 14px;

    }

    span {
            color: #ff6363;
            text-transform: uppercase;
            font-weight: bold;
            font-style: italic;
            margin-left: 5px;

            &.--active {
                color: #31a6a1;
            }
        }
  }

  .primary-url, .secondary-url, .password {
    background-color: #24243e;
    word-break: break-word;
    margin: 15px 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 0;
    position: relative;
    border-radius: 8px;
    
    h3{
        text-align: center;
        font-size: 14px;
        /* display: block; */
        align-self: center;
        color: #fff;
    }

    span {
        color: #fd745e;
        text-align: left;
    }

    p {
      font-weight: 800;
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;

        .copy-icon {
          width: 20px;
        }
    }

    div {
      display: inline;
    }
  }
`;

export const ControlHeader = styled.div`
    position: relative;
    width: 100%;
    display: flex !important;
    padding: 0.5rem 0.5rem;
    justify-content: space-between;
    img {
        width: 1rem !important;
        height: auto !important;
    }

    #tooltip {
      position: absolute;
    }
`   
