import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    height:calc(100% - 11%);
    width: 100%;
    position: relative;
    flex-flow: column nowrap;
    padding: 1rem;
    overflow: auto;

    .--warn-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;

        h5 {
            font-size: 18px;
            color: #fff;
        }

        button {
            border: none;
            color: #FFF;
            background: linear-gradient(90deg,#FFAC47 0%, #FF438D 100%);
            width: 300px;
            height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-weight: 600;
            border-radius: 8px;  
            font-weight: bold;
            text-transform: uppercase; 
            opacity: 0.8;
            transition: all 0.1s ease-in-out;

            &:hover {
                box-shadow: 0px 0px 10px -4px #000000;
                opacity: 1;
                transform: scale(1.02);
            }
        }

    }

`;

export const PageHeader = styled.div`  
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        font-size: clamp(18px, 0.5em + 1vw, 1.9em); 
        color:white;
    }

    /* button {
    border-width:0px;
    display:flex;
    align-self:flex-end;
    height: 47px;    
    min-width: 186px;
    border-radius: 12px;
    background-color: #212032;
    color: #ffffff;
    font-weight: bold;
    font-size: clamp(12px, 0.4em + 1vw, 1.5em);
    align-items: center;
    padding: 0 10px 0 34px;
    text-transform: uppercase;
    transition: all 0.1s ease-in-out;
    position: relative;
    img {
        width: 30px;
    }

    small {        
        position: absolute;
        margin-left: -15px;        
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        width: 34px;
        left: 0;
        top: 7px;
        border-radius: 7px;
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
    }

    &:hover {
        box-shadow: 0px 0px 10px -4px #000000;
        transform: scale(1.02);
    }
  } */
`;

export const VideoWallContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
`;

export const VideoWallSettingsContainer = styled.div`
    display: flex;
    color: #ffffff;
    align-items: center;
    border-radius: 12px;
    height: 47px;
    padding: 0px 10px;
    margin: 0 34px 0 0px;

    .carousel {
        width: 2rem;
        margin-right: 1rem;
}
`;
export const TilesControlContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: #212032;
    padding: 8px;
    border-radius: 12px;
    margin-right: 5px;

    h6 {
        font-size: 1rem;
        text-transform: uppercase;
    }

    span {
        color: #ff8f63;
        font-weight: bold;
    }

    button {
        background-color: #ff6677;
        color: #ffffff;
        border: none;
        border-radius: 5px; 
        display: flex;
        justify-content: center;
        align-items: center;   
        margin: 0 10px;    
    }

    img {
        pointer-events: none;
    }

`;
export const FullScreenButtonContainer = styled.div`
    button {
        background: #ff6579;
        border: none;
        padding: 5px 7px;
        border-radius: 5px;
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
    }
`;

export const TimerButton = styled.select`
     background: #ff6579;
    border: none;
    padding: 5px 7px;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    min-width: 1rem;

    span {
        color: #fff
    }
`;
