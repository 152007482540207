import actions from '../../../utils/actions';

export function getRanking(courseId, pageSize, page, ordination, sortBy, keyword) {
    return {
        type: actions.GET_RANKING,
        payload: {
            courseId,
            pagesize: pageSize,
            page,
            ordination,
            sortBy,
            keyword,
        },
    };
}

export function getRankingNextPage(courseId, pageSize, page, ordination, sortBy, keyword) {
    return {
        type: actions.GET_RANKING_NEXT_PAGE,
        payload: {
            courseId,
            pagesize: pageSize,
            page,
            ordination,
            sortBy,
            keyword,
        },
    };
}

export function setRanking(rank, isLastPage) {
    return {
        type: actions.SET_RANKING,
        payload: { rank, isLastPage },
    };
}

export function setRankingPaginated(rank, isLastPage) {
    return {
        type: actions.SET_RANKING_PAGINATED,
        payload: { rank, isLastPage },
    };
}

export function setRankingLoading(loader) {
    return {
        type: actions.SET_PAGE_RANKING_LOADING,
        payload: loader,
    };
}

export function getRankingFail() {
    return { type: actions.GET_RANKING_FAILURE };
}

export function toggleGamificationRequest(courseId, enabled) {
    return { type: actions.TOGGLE_GAMIFICATION_REQUEST, payload: { courseId, enabled } };
}

export function getGamificationRules(channelId) {
    return {
        type: actions.FETCH_GAMIFICATION_RULES,
        payload: channelId,
    };
}

export function saveGamificationRulesRequest(gamificationRules, courseId) {
    return {
        type: actions.SAVE_GAMIFICATION_RULES,
        payload: {
            courseId,
            gamificationRules,
        },
    };
}
