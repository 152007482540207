import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import VideoCard from '@src/components/VideoCard';
import useRooms from '@src/hooks/useRooms';
import LoadingSkeleton from '@src/components/VideoCardSkeleton';
import NoContent from '@src/components/NoContent';
import Modal from '@src/components/Modal';
import { useBreadcrumbs } from '@src/hooks/useBreadcrumbs';
import { limitCharacters } from '@utils/functions';
import paths from '@src/utils/paths';
import { Container, PageHeader, RoomsList } from './styles';

export default function QuizzesHome() {
    const [showWarn, setShowWarn] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const { selectedChannel, hasGamification } = useRooms();
    const { loading, selectedChannelRooms: rooms } = useRooms();
    const { setItems } = useBreadcrumbs();

    console.log(rooms);

    function handleViewQuizzes(room) {
        if (room?.recorded) {
            setShowWarn(true);
            return;
        }
        history.push(`quizzes/${room.id}/?roomName=${encodeURIComponent(room.name)}&gamified=${hasGamification || ''}`);
    }

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t('ORGANIZER_DASHBOARD') },
            { to: paths.QUIZZES, label: t('QUIZZES_SURVEYS') },
        ];
        setItems(breadcrumbsItems);
    }, [selectedChannel, setItems, t]);

    return (
        <Container>
            <Modal isOpen={showWarn} handleModal={() => setShowWarn(false)} title={t('VIEW_QUIZZES_WARN').split(':')[0]}>
                <div className="--warn-content">
                    <h5>{t('VIEW_QUIZZES_WARN').split(':')[1]}</h5>
                    <button type="button" onClick={() => setShowWarn(false)}>OK</button>
                </div>
            </Modal>
            <PageHeader>
                <h2>{selectedChannel?.id && limitCharacters(`#${selectedChannel?.id} - ${selectedChannel?.name}`, 40)}</h2>
            </PageHeader>
            {
                loading && (
                    <LoadingSkeleton marginLeft={0} />
                )
            }
            <RoomsList>
                {
                    !loading && rooms?.map(room => (
                        <VideoCard
                            key={room?.id}
                            room={room}
                            button={{ text: t('QUIZZES_SURVEYS'), action: () => handleViewQuizzes(room) }}
                        />
                    ))
                }
                {
                    !loading && !rooms?.length > 0 && (
                        <NoContent
                            title={t('OPS')}
                            text={t('CHANNEL', { context: 'NOVIDEO' })}
                        />
                    )
                }
            </RoomsList>
        </Container>
    );
}
