export const paginate = (items) => {
    if (!items) {
        return [];
    }

    const itemsPerPage = 10;

    const pages = Math.ceil(items.length / itemsPerPage);

    const paginatedItems = Array.from({ length: pages }, (_, index) => {
        const start = index * itemsPerPage;

        return items.slice(start, start + itemsPerPage);
    });

    return paginatedItems;
};
