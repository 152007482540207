import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function VideoCardSkeleton({ marginTop = 50, marginLeft = 50 }) {
    return (
        <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
            <div style={{ marginTop, marginLeft }}>
                <Skeleton width={398} height={202} count={10} style={{ margin: '10px 8px' }} />
            </div>
        </SkeletonTheme>
    );
}
