export default {
    ACTIVE: 'ACTIVE',
    ACCEPTTERMS: 'ACCEPTTERMS',
    ALREADY_LOGED_IN: 'ALREADY_LOGED_IN',
    ANSWER_DIFFERENT_MANDATORY: 'ANSWER_DIFFERENT_MANDATORY',
    ALL_ANSWERS: 'ALL_ANSWERS',
    APPLY: 'APPLY',
    ATTACHMENTS: 'ATTACHMENTS',
    AT_LEAST_3_CHARS: 'AT_LEAST_3_CHARS',
    APP_LOGIN: 'APP_LOGIN',
    BACKTO: 'BACKTO',
    BACK_INITIAL_PAGE: 'BACK_INITIAL_PAGE',
    BACK_TO_GUEST_PRESENTER_LIST: 'BACK_TO_GUEST_PRESENTER_LIST',
    BITRATE: 'BITRATE',
    BLOCK_USER_BY_CHAT: 'BLOCK_USER_BY_CHAT',
    BUFFER_CLASSIFICATION: 'BUFFER_CLASSIFICATION',
    BUFFER_CLASSIFICATION_ABREVIATION: 'BUFFER_CLASSIFICATION_ABREVIATION',
    DISABLED_CAMERA: 'DISABLED_CAMERA',
    CANCEL: 'CANCEL',
    CANT_GOLIVE_YET: 'CANT_GOLIVE_YET',
    CHUNK_UPLOAD_FAILURE: 'CHUNK_UPLOAD_FAILURE',
    CLICK_TO_ENDLIVE: 'CLICK_TO_ENDLIVE',
    CLICK_TO_GOLIVE: 'CLICK_TO_GOLIVE',
    CHANGES_SUCCESS: 'CHANGES_SUCCESS',
    CHANGES_FAIL: 'CHANGES_FAIL',
    CHANNEL_ACCEPTED_FORMATS: 'CHANNEL_ACCEPTED_FORMATS',
    CHANNEL_ADDNEWVIDEO: 'CHANNEL_ADDNEWVIDEO',
    CHANNEL_ADDNEWLIVEVIDEO: 'CHANNEL_ADDNEWLIVEVIDEO',
    CHANNEL_ADDNEWRECORDEDVIDEO: 'CHANNEL_ADDNEWRECORDEDVIDEO',
    CHANNEL_CLEANVIDEO: 'CHANNEL_CLEANVIDEO',
    CHANNEL_COPIED: 'CHANNEL_COPIED',
    CHANNEL_CREATEVIDEO: 'CHANNEL_CREATEVIDEO',
    CHANNEL_CREATEVIDEO2: 'CHANNEL_CREATEVIDEO2',
    CHANNEL_CHAT_ENABLED: 'CHANNEL_CHAT_ENABLED',
    XLSX_SAMPLE_FAIL: 'XLSX_SAMPLE_FAIL',
    CHANNEL_EDITVIDEO: 'CHANNEL_EDITVIDEO',
    CHANNEL_ENDDATE: 'CHANNEL_ENDDATE',
    CHANNEL_ENDLIVE: 'CHANNEL_ENDLIVE',
    CHANNEL_ENDLIVEIRREVERSIBLE: 'CHANNEL_ENDLIVEIRREVERSIBLE',
    CHANNEL_ENDLIVEDESCRIPTION: 'CHANNEL_ENDLIVEDESCRIPTION',
    CHANNEL_FAILREQUESTROOM: 'CHANNEL_FAILREQUEST',
    CHANNEL_FAILREQUESTROOMS: 'CHANNEL_FAILREQUESTROOMS',
    CHANNEL_FILES: 'CHANNEL_FILES',
    CHANNEL_FILEFORMAT: 'CHANNEL_FILEFORMAT',
    CHANNEL_GOLIVE: 'CHANNEL_GOLIVE',
    CHANNEL_GOLIVE_UNAVAILABLE: 'CHANNEL_GOLIVE_UNAVAILABLE',
    CHANNEL_MYVIDEOS: 'CHANNEL_MYVIDEOS',
    CHANNEL_NEWVIDEO: 'CHANNEL_NEWVIDEO',
    CHANNEL_NOVIDEO: 'CHANNEL_NOVIDEO',
    CHANNEL_PRIMARYURL: 'CHANNEL_PRIMARYURL',
    CHANNEL_PRIMARY_PASSWORD: 'CHANNEL_PRIMARY_PASSWORD',
    CHANNEL_SECONDARY_PASSWORD: 'CHANNEL_SECONDARY_PASSWORD',
    CHANNEL_PRODUCER_LINK: 'CHANNEL_PRODUCER_LINK',
    CHANNEL_PRODUCER_BACKUP_LINK: 'CHANNEL_PRODUCER_BACKUP_LINK',
    CHANNEL_ROOMNOTFOUND: 'CHANNEL_ROOMNOTFOUND',
    CHANNEL_ROOM_LOAD_DATA_ERROR: 'CHANNEL_ROOM_LOAD_DATA_ERROR',
    CHANNEL_SECONDARYURL: 'CHANNEL_SECONDARYURL',
    CHANNEL_SENDVIDEO: 'CHANNEL_SENDVIDEO',
    CHANNEL_SELECTCOURSE: 'CHANNEL_SELECTCOURSE',
    CHANNEL_SELECTCOURSEPLACEHOLDER: 'CHANNEL_SELECTCOURSE2',
    CHANNEL_SELECTTIMEZONE: 'CHANNEL_SELECTTIMEZONE',
    CHANNEL_STARTDATE: 'CHANNEL_STARTDATE',
    CHANNEL_THUMBNAIL: 'CHANNEL_THUMBNAIL',
    CHANNEL_THUMBNAILSIZE: 'CHANNEL_THUMBNAILSIZE',
    CHANNEL_UPLOADFILES: 'CHANNEL_UPLOADFILES',
    CHANNEL_UPLOADVIDEO: 'CHANNEL_UPLOADVIDEO',
    CHANNEL_UPLOADSPREADSHEET: 'CHANNEL_UPLOADSPREADSHEET',
    CHANNEL_VIDEOCOVER: 'CHANNEL_VIDEOCOVER',
    CHANNEL_VIDEODESCRIPTION: 'CHANNEL_VIDEODESCRIPTION',
    CHANNEL_VIDEOFORMAT: 'CHANNEL_VIDEOFORMAT',
    CHANNEL_VIDEOTITLE: 'CHANNEL_VIDEOTITLE',
    CHAT_INTERACTION: 'CHAT_INTERACTION',
    CHAT_INTERACTION_RULES: 'CHAT_INTERACTION_RULES',
    COMEBACK: 'COMEBACK',
    CONFIRM: 'CONFIRM',
    CONTENT_DESCRIPTION_PLACEHOLDER: 'CONTENT_DESCRIPTION_PLACEHOLDER',
    CORRECT_ANSWER_MANDATORY: 'CORRECT_ANSWER_MANDATORY',
    CREATE_ROOM_SUCCESS: 'CREATE_ROOM_SUCCESS',
    DISABLE_QUESTION: 'DISABLE_QUESTION',
    DISABLED_CAPS: 'DISABLED_CAPS',
    DOUBTS: 'DOUBTS',
    DOWNLOAD_ATTACHMENTS: 'DOWNLOAD_ATTACHMENTS',
    DURATION_MANDATORY: 'DURATION_MANDATORY',
    POSITIVE_FIELD: 'POSITIVE_FIELD',
    DURATION_NUMERIC: 'DURATION_NUMERIC',
    EDIT: 'EDIT',
    EDIT_CONTENT: 'EDIT_CONTENT',
    ENABLED: 'ENABLED',
    VIEW_CONTENT: 'VIEW_CONTENT',
    EDIT_ROOM_FAIL: 'EDIT_ROOM_FAIL',
    EDIT_VIDEO_COVER: 'EDIT_VIDEO_COVER',
    ENABLE_QUESTION: 'ENABLE_QUESTION',
    ENABLED_CAPS: 'ENABLED_CAPS',
    ENGLISH: 'ENGLISH',
    ENDDATE: 'ENDDATE',
    ENROLLED: 'ENROLLED',
    EMAIL: 'EMAIL',
    ENTER_TITLE: 'ENTER_TITLE',
    ERROR404: 'ERROR404',
    ERROR_CHANGEPASSWORD: 'ERROR_CHANGEPASSWORD',
    ERROR_CREDENTIALS: 'ERROR_CREDENTIALS',
    ERROR_PARAMETERS: 'ERROR_PARAMETERS',
    ERROR_DEFAULT: 'ERROR_DEFAULT',
    ERROR_EMAILNOTFOUND: 'ERROR_EMAILNOTFOUND',
    ERROR_LOGIN: 'ERROR_LOGIN',
    ERROR_LOGINFAIL: 'ERROR_LOGINFAIL',
    ERROR_NONETWORK: 'ERROR_NONETWORK',
    ERROR_RECOVERPASSFAIL: 'ERROR_RECOVERPASSFAIL',
    FAIL_CHANGELANGUAGE: 'FAIL_CHANGELANGUAGE',
    FAIL_CHANGEOFFERSTATUS: 'FAIL_CHANGEOFFERSTATUS',
    FAIL_CHATNOTFOUND: 'FAIL_CHATNOTFOUND',
    FAIL_CONNECTWEBSOCKET: 'FAIL_CONNECTWEBSOCKET',
    FAIL_CREATEOFFER: 'FAIL_CREATEOFFER',
    FAIL_CREATETEAMS: 'FAIL_CREATETEAMS',
    FAIL_ENTERROOM: 'FAIL_ENTERROOM',
    FAIL_EXITROOM: 'FAIL_EXITROOM',
    FAIL_GETCHAT: 'FAIL_GETCHAT',
    FAIL_GETOFFER: 'FAIL_GETOFFER',
    FAIL_GETTEAMSSTATUS: 'FAIL_GETTEAMSSTATUS',
    FAIL_GETTEAMS: 'FAIL_GETTEAMS',
    FAIL_LOGOUT: 'FAIL_LOGOUT',
    FAIL_PINMESSAGE: 'FAIL_PINMESSAGE',
    FAIL_UNPINMESSAGE: 'FAIL_UNPINMESSAGE',
    FAIL_SETTEAMSSTATUS: 'FAIL_SETTEAMSSTATUS',
    FILL_FIELDS_ADD_USER: 'FILL_FIELDS_ADD_USER',
    FILTER_FIRST: 'FILTER_FIRST',
    FILTER_SECOND: 'FILTER_SECOND',
    FILTER_SPECIFIC: 'FILTER_SPECIFIC',
    FILTER_PRESETOPTIONS: 'FILTER_PRESETOPTIONS',
    FILTER_TIME: 'FILTER_TIME',
    FILTER_NAME_ID: 'FILTER_NAME_ID',
    FLOW_PRIMARY: 'FLOW_PRIMARY',
    FLOW_SECONDARY: 'FLOW_SECONDARY',
    FORGETPASSWORD: 'FORGETPASSWORD',
    FROM_TIME: 'FROM_TIME',
    FULL_SCREEN: 'FULL_SCREEN',
    GAMIFICATION: 'GAMIFICATION',
    GAMIFICATION_PUNCTUALITY_MODE_RULE: 'GAMIFICATION_PUNCTUALITY_MODE_RULE',
    GAMIFICATION_DOWNLOAD_RULES: 'GAMIFICATION_DOWNLOAD_RULES',
    GAMIFICATION_ACCESS_ROOM_RULE: 'GAMIFICATION_ACCESS_ROOM_RULE',
    GAMIFICATION_DISABLED: 'GAMIFICATION_DISABLED',
    GAMIFICATION_LOGIN_APP_RULES: 'GAMIFICATION_LOGIN_APP_RULES',
    GAMIFICATION_POINTS_NUMERIC: 'GAMIFICATION_POINTS_NUMERIC',
    GAMIFICATION_QUIZZ_RULE: 'GAMIFICATION_QUIZZ_RULE',
    GAMIFICATION_SETTINGS: 'GAMIFICATION_SETTINGS',
    GAMIFICATION_SETTINGS_QUIZZ_TIP_A: 'GAMIFICATION_SETTINGS_QUIZZ_TIP_A',
    GAMIFICATION_SETTINGS_QUIZZ_TIP_B: 'GAMIFICATION_SETTINGS_QUIZZ_TIP_B',
    GAMIFICATION_RULES_REQUEST_FAIL: 'GAMIFICATION_RULES_REQUEST_FAIL',
    GENERAL: 'GENERAL',
    GEOLOCATION: 'GEOLOCATION',
    GRADUAL_MODE: 'GRADUAL_MODE',
    GRAPH_AVG_VIEWBITRATE: 'GRAPH_AVGVIEWBITRATE',
    GRAPH_AVG_VIEWDOWNSTREAMBANDWIDTH: 'GRAPH_AVGVIEWDOWNSTREAMBANDWIDTH',
    GRAPH_AVG_VIEWDROPPEDFRAMESRATIO: 'GRAPH_AVGVIEWDROPPEDFRAMESRATIO',
    GRAPH_AVG_VIEWLIVELATENCY: 'GRAPH_AVGVIEWLIVELATENCY',
    GRAPH_SUMVIEWTIME: 'GRAPH_SUMVIEWTIME',
    GRAPH_VIEWERS: 'GRAPH_VIEWERS',
    GRAPH_VIEWERSBUFFERING: 'GRAPH_VIEWERSBUFFERING',
    GRAPH_VIEWERSENGAGEMENT: 'GRAPH_VIEWERSENGAGEMENT',
    GRAPH_VIEWERSDVR: 'GRAPH_VIEWERSDVR',
    GRAPH_VIEWBUFFERTIMERATIO: 'GRAPH_VIEWBUFFERTIMERATIO',
    GUEST_AVAILABLE: 'GUEST_AVAILABLE',
    GUEST_PRESENTER: 'GUEST_PRESENTER',
    HOME: 'HOME',
    HOMEPAGE: 'HOMEPAGE',
    HOME_WELCOME: 'HOME_WELCOME',
    HOME_NOLIVE: 'HOME_NOLIVE',
    HOURS: 'HOURS',
    INACTIVE: 'INACTIVE',
    INVALID_IMG_TYPE: 'INVALID_IMG_TYPE',
    INITIAL_DATE_CANT_CHANGE: 'INITIAL_DATE_CANT_CHANGE',
    INSERT_VIDEO_THUMBNAIL: 'INSERT_VIDEO_THUMBNAIL',
    FINISHED: 'FINISHED',
    LANGUAGE: 'LANGUAGE',
    LAST: 'LAST',
    LIVE: 'LIVE',
    LIVE_UNAVAILABLE: 'LIVE_UNAVAILABLE',
    RECORDED: 'RECORDED',
    LOADING: 'LOADING',
    LOADINGCAPS: 'LOADINGCAPS',
    LOADINGMESSAGELINK: 'LOADINGMESSAGELINK',
    LOADINGPDF: 'LOADINGPDF',
    LOGIN: 'LOGIN',
    LOGIN_EMAIL: 'LOGIN_EMAIL',
    LOGIN_PASSWORD: 'LOGIN_PASSWORD',
    LOGIN_SIGNIN: 'LOGIN_SIGNIN',
    MAX_SCORE: 'MAX_SCORE',
    MIN_SCORE: 'MIN_SCORE',
    MAXIMUM_IMAGE_SIZE: 'MAXIMUM_IMAGE_SIZE',
    MINIMUM_IMAGE_SIZE: 'MINIMUM_IMAGE_SIZE',
    MESSAGE: 'MESSAGE',
    MUTED_PARTICIPANT_ERROR: 'MUTED_PARTICIPANT_ERROR',
    MUTED_USER: 'MUTED_USER',
    HOUR_ERROR_MESSAGE: 'HOUR_ERROR_MESSAGE',
    MONITORS: 'MONITORS',
    MINUTES: 'MINUTES',
    NAMECAPS: 'NAMECAPS',
    NEXT_STEP: 'NEXT_STEP',
    NO: 'NO',
    NO_USER_REGISTERED_CHECK_XLSX: 'NO_USER_REGISTERED_CHECK_XLSX',
    NO_USERS_SHOW: 'NO_USERS_SHOW',
    NO_PARTICIPANT_REPORT: 'NO_PARTICIPANT_REPORT',
    NO_QUIZ_SURVEY_REGISTERED: 'NO_QUIZ_SURVEY_REGISTERED',
    NO_QUIZ_SURVEY_REGISTERED_MESSAGE: 'NO_QUIZ_SURVEY_REGISTERED_MESSAGE',
    NO_USER_CONNECTED: 'NO_USER _CONNECTED',
    LIVE_ENDED: 'LIVE_OFF',
    NEXTPAGE: 'NEXTPAGE',
    NO_USER_BLOCKED: 'NO_USER_BLOCKED',
    NO_USER_FOUND: 'NO_USER_FOUND',
    NO_USER_FOUND_MESSAGE: 'NO_USER_FOUND_MESSAGE',
    NO_USERS_ROOM: 'NO_USERS_ROOM',
    OF: 'OF',
    OFFERS: 'OFFERS',
    OFFERS_ACTIVATEOFFERS: 'OFFERS_ACTIVATEOFFERS',
    OFFERS_BASEURL: 'OFFERS_BASEURL',
    OFFERS_CREATEOFFER: 'OFFERS_CREATEOFFER',
    OFFERS_EDITOFFER: 'OFFERS_EDITOFFER',
    OFFERS_ENABLECONFIRM: 'OFFERS_ENABLECONFIRM',
    OFFERS_MODEOFFERSINACTIVATE: 'OFFERS_MODEOFFERSINACTIVATE',
    OFFERS_NOOFFERS: 'OFFERS_NOOFFERS',
    OFFERS_SALESFORCEID: 'OFFERS_SALESFORCEID',
    OFFERS_SELECTOR: 'OFFERS_SELECTOR',
    OFFERS_TEMPLATEURL: 'OFFERS_TEMPLATEURL',
    OFFERS_TYPEID: 'OFFERS_TYPEID',
    OFFERS_WARN: 'OFFERS_WARN',
    OPS: 'OPS',
    PARTICIPANT_WITHOUT_GAMIFICATION: 'PARTICIPANT_WITHOUT_GAMIFICATION',
    PARTICIPANT_NOT_FOUND: 'PARTICIPANT_NOT_FOUND',
    PARTICIPANT_NOT_FOUND_MESSAGE: 'PARTICIPANT_NOT_FOUND_MESSAGE',
    PARTICIPANT_OUT_LIST: 'PARTICIPANT_OUT_LIST',
    PERMISSION_ENTERCHAT: 'PERMISSION_ENTERCHAT',
    PERMISSION_PINMESSAGE: 'PERMISSION_PINMESSAGE',
    PERMISSION_UNPINMESSAGE: 'PERMISSION_UNPINMESSAGE',
    PAGE: 'PAGE',
    PLEASECLOSE: 'PLEASECLOSE',
    PORTUGUESE: 'PORTUGUESE',
    PRESET: 'PRESET',
    PREVIOUSPAGE: 'PREVIOUSPAGE',
    PUNCTUALITY: 'PUNCTUALITY',
    PUNCTUALITY_MODE: 'PUNCTUALITY_MODE',
    QUESTION_MANDATORY: 'QUESTION_MANDATORY',
    RECOVERPASSWORD_MINLENGTH: 'RECOVERPASSWORD_MINLENGTH',
    RECOVERPASSWORD_NEWPASSWORD: 'RECOVERPASSWORD_NEWPASSWORD',
    RECOVERPASSWORD_NEWPASSWORDREPEAT: 'RECOVERPASSWORD_NEWPASSWORDREPEAT',
    RECOVERPASSWORD_NOLINK: 'RECOVERPASSWORD_NOLINK',
    RECOVERPASSWORD_NOMATCH: 'RECOVERPASSWORD_NOMATCH',
    RECOVERPASSWORD_REDEFINE: 'RECOVERPASSWORD_REDEFINE',
    RECOVERPASSWORD_SUCCESS: 'RECOVERPASSWORD_SUCCESS',
    RECOVERPASSWORD_VERIFY: 'RECOVERPASSWORD_VERIFY',
    RECOVERPASSWORD_VERIFYDESC: 'RECOVERPASSWORD_VERIFYDESC',
    REGISTER_QUIZ_SURVEY: 'REGISTER_QUIZ_SURVEY',
    REQUIRED_FIELD: 'REQUIRED_FIELD',
    ROOM: 'ROOM',

    SAVE_GAMIFICATION_RULES_SUCCESS: 'SAVE_GAMIFICATION_RULES_SUCCESS',
    SAVE_GAMIFICATION_RULES_FAIL: 'SAVE_GAMIFICATION_RULES_FAIL',
    SCORE: 'SCORE',
    SELECTED: 'SELECTED',
    SEARCH: 'SEARCH',
    SEARCH_MIN_CHARACTERS: 'SEARCH_MIN_CHARACTERS',
    SEARCH_NOT_FOUND: 'SEARCH_NOT_FOUND',
    SELECTQUESTION: 'SELECTQUESTION',
    SEND: 'SEND',
    SEVERITY_BAD: 'SEVERITY_BAD',
    SEVERITY_GOOD: 'SEVERITY_GOOD',
    SEVERITY_WARN: 'SEVERITY_WARN',
    SIMPLE_MODE: 'SIMPLE_MODE',
    SORRYPROBLEM: 'SORRYPROBLEM',
    SPANISH: 'SPANISH',
    STARTDATE: 'STARTDATE',
    STATUS: 'STATUS',
    STATUS_LIVE: 'STATUS_LIVE',
    SUCCESS: 'SUCCESS',
    TEAM: 'TEAM',
    TEAMS: 'TEAMS',
    TEAMS_ACTIVATETEAMS: 'TEAMS_ACTIVATETEAMS',
    TEAMS_CREATETEAMS: 'TEAMS_CREATETEAMS',
    TEAMS_CREATETEAMSDESC: 'TEAMS_CREATETEAMSDESC',
    TEAMS_GENERATETEAMS: 'TEAMS_GENERATETEAMS',
    TEAMS_MODETEAMS: 'TEAMS_MODETEAMS',
    TEAMS_MODETEAMSINACTIVATE: 'TEAMS_MODETEAMSINACTIVATE',
    TEAMS_NOTEAMS: 'TEAMS_NOTEAMS',
    TEAMS_QUANTITY: 'TEAMS_QUANTITY',
    THE: 'THE',
    TYPE_QUESTION: 'TYPE_QUESTION',
    TITLE_MANDATORY: 'TITLE_MANDATORY',
    TOLERANCY: 'TOLERANCY',
    TOGGLE_GAMIFICATION_SUCCESS: 'TOGGLE_GAMIFICATION_SUCCESS',
    TOGGLE_GAMIFICATION_SUCCESS_ENABLED: 'TOGGLE_GAMIFICATION_SUCCESS_ENABLED',
    TOGGLE_GAMIFICATION_SUCCESS_DISABLED: 'TOGGLE_GAMIFICATION_SUCCESS_DISABLED',
    TOGGLE_GAMIFICATION_FAIL: 'TOGGLE_GAMIFICATION_FAIL',
    TOTIME: 'TOTIME',
    TRY_AGAIN: 'TRY_AGAIN',
    TWO_ITEMS_MANDATORY: 'TWO_ITEMS_MANDATORY',
    UPLOAD_ERROR_MESSAGE: 'UPLOAD_ERROR_MESSAGE',
    UPLOAD_SUCCESS_MESSAGE: 'UPLOAD_SUCCESS_MESSAGE',
    UPLOAD_SUCCESS_MESSAGE_MULTIPLE: 'UPLOAD_SUCCESS_MESSAGE_MULTIPLE',
    UPLOAD_COVER_PLACEHOLDER: 'UPLOAD_COVER_PLACEHOLDER',
    UPLOAD_VIDEO_PLACEHOLDER: 'UPLOAD_VIDEO_PLACEHOLDER',
    UPLOAD_VIDEO_PROGRESS: 'UPLOAD_VIDEO_PROGRESS',
    UPLOAD_VIDEO_SUCCESS: 'UPLOAD_VIDEO_SUCCESS',
    UPLOAD_VIDEO_FAILURE: 'UPLOAD_VIDEO_FAILURE',
    UPLOAD_VIDEO_PREPARING: 'UPLOAD_VIDEO_PREPARING',
    UPLOADING: 'UPLOADING',
    UPLOADING_ATTACHMENTS: 'UPLOADING_ATTACHMENTS',
    CONVERTING: 'CONVERTING',
    UNABLE_TO_EDIT_VIDEO: 'UNABLE_TO_EDIT_VIDEO',
    UNENROLL_USER: 'UNENROLL_USER',
    UNENROLLED: 'UNENROLLED',
    UNMUTED_PARTICIPANT_ERROR: 'UNMUTED_PARTICIPANT_ERROR',
    UPLOAD_ERROR: 'UPLOAD_ERROR',
    UPLOAD_VIDEO: 'UPLOAD_VIDEO',
    UPLOAD: 'UPLOAD',
    USERS: 'USERS',
    USER: 'USER',
    USERS_ACTIVE: 'USERS_ACTIVE',
    USERS_ACTIVECAPS: 'USERS_ACTIVECAPS',
    USERS_DISTRIBUTIONSHORT: 'USERS_DISTRIBUTIONSHORT',
    USERS_ENGAGEMENT: 'USERS_ENGAGEMENT',
    USERNAME_MIN: 'USERNAME_MIN',
    VALID_DATE: 'VALID_DATE',
    VALID_EMAIL: 'VALID_EMAIL',
    VALID_PHONE: 'VALID_PHONE',
    VIDEO: 'VIDEO',
    VIDEO_STATS: 'VIDEO_STATS',
    VIDEO_DESCRIPTION: 'VIDEO_DESCRIPTION',
    VIDEO_OFF: 'VIDEO_OFF',
    VIDEO_UNAVAILABLE: 'VIDEO_UNAVAILABLE',
    VIDEO_TITLE_PLACEHOLDER: 'VIDEO_TITLE_PLACEHOLDER',
    VOUCHER_LOGIN: 'VOUCHER_LOGIN',
    VOUCHER_PASSWORD_CREATION_TITLE: 'VOUCHER_PASSWORD_CREATION_TITLE',
    VOUCHER_PASSWORD_LABEL: 'VOUCHER_PASSWORD_LABEL',
    VOUCHER_PASSWORD_CONFIRM_LABEL: 'VOUCHER_PASSWORD_CONFIRM_LABEL',
    VOUCHER_PASSWORD_SAME: 'VOUCHER_PASSWORD_SAME',
    VOUCHER_PASSWORD_MIN: 'VOUCHER_PASSWORD_MIN',
    VOUCHER_FINALIZE_REGISTRATION: 'VOUCHER_FINALIZE_REGISTRATION',

    VOUCHER_CODE_TITLE: 'VOUCHER_CODE_TITLE',
    VOUCHER_CODE_SUBTITLE: 'VOUCHER_CODE_SUBTITLE',
    VOUCHER_BUTTON_SUBMIT: 'VOUCHER_BUTTON_SUBMIT',
    VOUCHER_REGISTER_TITLE: 'VOUCHER_REGISTER_TITLE',
    VOUCHER_REGISTER_MESSAGE_SUCCESS: 'VOUCHER_REGISTER_MESSAGE_SUCCESS',

    VOUCHER_TERMS: 'VOUCHER_TERMS',
    VOUCHER_TERMS_ACEPPT: 'VOUCHER_TERMS_ACEPPT',
    VOUCHER_TERMS_TITLE: 'VOUCHER_TERMS_TITLE',
    VOUCHER_TERMS_VALIDATION: 'VOUCHER_TERMS_VALIDATION',
    VOUCHER_ACEPPT_TERMS_PRIVACY_PT1: 'VOUCHER_ACEPPT_TERMS_PRIVACY_PT1',
    VOUCHER_ACEPPT_TERMS_PRIVACY_PT2: 'VOUCHER_ACEPPT_TERMS_PRIVACY_PT2',
    VOUCHER_NEXT_STEP_BUTTON: 'VOUCHER_NEXT_STEP_BUTTON',

    VOUCHER_USERDATA_TITLE: 'VOUCHER_USERDATA_TITLE',
    VOUCHER_USERDATA_FILL: 'VOUCHER_USERDATA_FILL',
    VOUCHER_USERDATA_FULL_NAME: 'VOUCHER_USERDATA_FULL_NAME',
    VOUCHER_USERDATA_FULL_NAME_CC: 'VOUCHER_USERDATA_FULL_NAME_CC',
    VOUCHER_USERDATA_EMAIL: 'VOUCHER_USERDATA_EMAIL',
    VOUCHER_USERDATA_EMAIL_CONFIRM: 'VOUCHER_USERDATA_EMAIL_CONFIRM',
    VOUCHER_USERDATA_EMAIL_DIFFERS: 'VOUCHER_USERDATA_EMAIL_DIFFERS',
    VOUCHER_USERDATA_DATE_OF_BIRTH: 'VOUCHER_USERDATA_DATE_OF_BIRTH',
    VOUCHER_USERDATA_GENDER: 'VOUCHER_USERDATA_GENDER',
    VOUCHER_USERDATA_GENDER_MALE: 'VOUCHER_USERDATA_GENDER_MALE',
    VOUCHER_USERDATA_GENDER_FEM: 'VOUCHER_USERDATA_GENDER_FEM',
    VOUCHER_USERDATA_GENDER_SELECT: 'VOUCHER_USERDATA_GENDER_SELECT',
    VOUCHER_USERDATA_GENDER_OTHER: 'VOUCHER_USERDATA_GENDER_OTHER',
    VOUCHER_USERDATA_INVALID_DATE: 'VOUCHER_USERDATA_INVALID_DATE',
    VOUCHER_USERDATA_PHONE: 'VOUCHER_USERDATA_PHONE',
    VOUCHER_USERDATA_PHONE_PATTERN: 'VOUCHER_USERDATA_PHONE_PATTERN',
    VOUCHER_USERDATA_TYPE_FULL_NAME: 'VOUCHER_USERDATA_TYPE_FULL_NAME',
    VOUCHER: 'VOUCHER',
    VOUCHER_EMAIL_VALIDATION: 'VOUCHER_EMAIL_VALIDATION',
    VOUCHER_ENTER_FIELDS: 'VOUCHER_ENTER_FIELDS',
    VOUCHER_ERROR_COURSE: 'VOUCHER_ERROR_COURSE',
    VOUCHER_ERROR_VERIFY: 'VOUCHER_ERROR_VERIFY',
    VOUCHER_ERROR_USERCREATED: 'VOUCHER_ERROR_USERCREATED',
    VOUCHER_ERROR_INVALIDCODE: 'VOUCHER_ERROR_INVALIDCODE',
    VOUCHER_ERROR_EXPIREDCODE: 'VOUCHER_ERROR_EXPIREDCODE',
    VOUCHER_ERROR_RESENDEMAIL: 'VOUCHER_ERROR_RESENDEMAIL',
    VOUCHER_ERROR_CONFIRMPASSWORD: 'VOUCHER_ERROR_CONFIRMPASSWORD',
    VOUCHER_ERROR_REGISTERDATA: 'VOUCHER_ERROR_REGISTERDATA',
    VOUCHER_ERROR_REGISTER: 'VOUCHER_ERROR_REGISTER',
    VOUCHER_NEW_VOUCHER: 'VOUCHER_NEW_VOUCHER',
    VOUCHER_INVALID: 'VOUCHER_INVALID',
    VOUCHER_VALIDATE: 'VOUCHER_VALIDATE',
    VOUCHER_VALIDATEPT2: 'VOUCHER_VALIDATEPT2',
    VOUCHER_VALIDATE_CODE: 'VOUCHER_VALIDATE_CODE',
    VOUCHER_VALIDATE_DENIED: 'VOUCHER_VALIDATE_DENIED',
    VOUCHER_VALIDATE_CODECAPS: 'VOUCHER_VALIDATE_CODECAPS',
    VOUCHER_VALIDATE_QUESTION: 'VOUCHER_VALIDATE_QUESTION',
    VOUCHER_VALIDATE_QUESTIONPT2: 'VOUCHER_VALIDATE_QUESTIONPT2',
    VOUCHER_REGISTERED: 'VOUCHER_REGISTERED',
    VOUCHER_RESEND_EMAIL: 'VOUCHER_RESEND_EMAIL',
    VOUCHER_RESENT_EMAIL: 'VOUCHER_RESENT_EMAIL',
    VOUCHER_STEPS: 'VOUCHER_STEPS',
    VOUCHER_VERIFICATION_CODE: 'VOUCHER_VERIFICATION_CODE',
    VOUCHER_WARNING_VERIFY: 'VOUCHER_WARNING_VERIFY',
    VOUCHER_YOUR_CODE: 'VOUCHER_YOUR_CODE',
    WARNINGS_MESSAGES: 'WARNINGS_MESSAGES',
    WARNINGS_MESSAGES_FAIL: 'WARNINGS_MESSAGES_FAIL',
    WARNINGS_MESSAGES_NOTALLOWED: 'WARNINGS_MESSAGES_NOTALLOWED',
    WARNINGS_MESSAGES_NOTFOUND: 'WARNINGS_MESSAGES_NOTFOUND',
    WARNINGS_MESSAGES_PERMISSIONS: 'WARNINGS_MESSAGES_PERMISSIONS',
    YES: 'YES',
    ORGANIZER: 'ORGANIZER',
    PARTICIPANT: 'PARTICIPANT',
    ORGANIZER_DASHBOARD: 'ORGANIZER_DASHBOARD',
    NOTIFICATIONS: 'NOTIFICATIONS',
    PITCH: 'PITCH',
    SETTINGS: 'SETTINGS',
    INFORMATION: 'INFORMATION',
    INFORMATIONS: 'INFORMATIONS',
    LOGOUT: 'LOGOUT',
    CHANNEL: 'CHANNEL',
    CHANNEL_HEADER: 'CHANNEL_HEADER',
    CONTENT: 'CONTENT',
    CONTENT_TYPE_TO_ADD: 'CONTENT_TYPE_TO_ADD',
    SAVE_CHANGES: 'SAVE_CHANGES',
    FAIL_GET_CHANNELS: 'FAIL_GET_CHANNELS',
    CLOSE: 'CLOSE',
    CHARACTERS: 'CHARACTERS',
    OPTIONAL: 'OPTIONAL',
    USERS_THAT_ARE_NOW_VIEWING_THE_BROADCAST: 'USERS_THAT_ARE_NOW_VIEWING_THE_BROADCAST',
    USERS_WITH_TAB_AND_SOUND_ON_OR_IN_FULL_SCREEN_DIVIDED_BY_THE_TOTAL_USERS_VIEWED: 'USERS_WITH_TAB_AND_SOUND_ON_OR_IN_FULL_SCREEN_DIVIDED_BY_THE_TOTAL_USERS_VIEWED',
    USERS_THAT_ARE_EXPERIENCING_BUFFERING_ISSUE_OF_OVER_1_SECOND_DIVIDED_BY_TOTAL_USERS: 'USERS_THAT_ARE_EXPERIENCING_BUFFERING_ISSUE_OF_OVER_1_SECOND_DIVIDED_BY_TOTAL_USERS',
    THE_AVERAGE_BITRATE_REPORTED_BY_KOPA_PLAYERS: 'THE_AVERAGE_BITRATE_REPORTED_BY_KOPA_PLAYERS',
    WHAT_CHANNEL_LOOKING: 'WHAT_CHANNEL_LOOKING',
    TIME_WATCHED: 'TIME_WATCHED',
    USERS_WHO_WATCH_THE_VIDEO: 'USERS_WHO_WATCH_THE_VIDEO',

    // PAGE USERS - /organizer/users
    BUTTON_MESSAGE_YES: 'BUTTON_MESSAGE_YES',
    BUTTON_MESSAGE_NOT: 'BUTTON_MESSAGE_NOT',
    MODAL_DELETE_MESSAGE: 'MODAL_DELETE_MESSAGE',
    MODAL_UNSUBSCRIBE_MESSAGE: 'MODAL_UNSUBSCRIBE_MESSAGE',
    ADD_NEW_USER: 'ADD_NEW_USER',
    DELETE_USER: 'DELETE_USER',
    UNSUBSCRIBE_USER: 'UNSUBSCRIBE_USER',
    EDIT_USER: 'EDIT_USER',
    PERMISSION_LEVEL: 'PERMISSION_LEVEL',
    MONITOR: 'MONITOR',
    USERS_PER_PAGE: 'USERS_PER_PAGE',
    DELETE: 'DELETE',
    NAME: 'NAME',
    PERMISSION: 'PERMISSION',
    NEW_USER: 'NEW_USER',
    IMPORT_SPREADSHEET: 'IMPORT_SPREADSHEET',
    IMPORT: 'IMPORT',
    GENDER: 'GENDER',
    DATE_OF_BIRTH: 'DATE_OF_BIRTH',
    SAVE_USER: 'SAVE_USER',
    MALE: 'MALE',
    FEMALE: 'FEMALE',
    PHONE: 'PHONE',
    PHONE_CONTACT: 'PHONE_CONTACT',
    PASSWORD: 'PASSWORD',
    RESULTS: 'RESULTS',
    FAIL_EDIT_USER: 'FAIL_EDIT_USER',
    FAIL_GET_USER: 'FAIL_GET_USER',
    FAIL_DELETE_USER: 'FAIL_DELETE_USER',
    FAIL_UNSUBSCRIBE_USER: 'FAIL_UNSUBSCRIBE_USER',
    FAIL_POST_USER: 'FAIL_POST_USER',
    FAIL_ADD_USER: 'FAIL_ADD_USER',
    MORE_OPTIONS: 'MORE_OPTIONS',
    SPREADSHEET: 'SPREADSHEET',
    DOWNLOAD_SPREADSHEET: 'DOWNLOAD_SPREADSHEET',
    DOWNLOAD_VIDEO_RECORDING: 'DOWNLOAD_VIDEO_RECORDING',
    DOWNLOAD_VIDEO_FAIL: 'DOWNLOAD_VIDEO_FAIL',
    LABEL_SEARCH_INPUT_USERS: 'LABEL_SEARCH_INPUT_USERS',
    USERS_REPORT: 'USERS_REPORT',
    PREPARING_DOWNLOAD: 'PREPARING_DOWNLOAD',
    DONE: 'DONE',
    ERROR: 'ERROR',
    ERROR_LOAD_DATA: 'ERROR_LOAD_DATA',
    ERROR_ROOM_ID: 'ERROR_ROOM_ID',
    COMPLETEDWITHERROR: 'COMPLETEDWITHERROR',
    USERSLIMITREACHED: 'USERSLIMITREACHED',
    FILE: 'FILE',
    PROCESS_STATUS: 'PROCESS_STATUS',
    CREATION_DATE: 'CREATION_DATE',
    REGISTRATION_ERRORS: 'REGISTRATION_ERRORS',
    EXECUTE_IMPORT: 'EXECUTE_IMPORT',
    DOWNLOAD_SPREADSHEET_SAMPLE: 'DOWNLOAD_SPREADSHEET_SAMPLE',
    DRAG_AND_DROP_FILES: 'DRAG_AND_DROP_FILES',
    IMPORT_LIST_DESCRIPTION: 'IMPORT_LIST_DESCRIPTION',
    IMPORT_LIST_TIP: 'IMPORT_LIST_TIP',
    OTHERS: 'OTHERS',
    OR: 'OR',
    AND: 'AND',
    XLSX_DETAILS: 'XLSX_DETAILS',
    IMPORTXLSXHEADER: 'IMPORTXLSXHEADER',
    XLSX_FORMAT_ERROR: 'XLSX_FORMAT_ERROR',
    XLSX_FORMAT_ERROR_MESSAGE: 'XLSX_FORMAT_ERROR_MESSAGE',
    XLSX_FORMAT_NO_IMPORT: 'XLSX_FORMAT_NO_IMPORT',
    INCORRECT_COLUMNS: 'INCORRECT_COLUMNS',
    USERS_REGISTERED: 'USERS_REGISTERED',
    USER_REGISTERED: 'USER_REGISTERED',
    USERS_NOT_REGISTERED: 'USERS_NOT_REGISTERED',
    BACK_TO: 'BACK_TO',
    ERROR_IMAGE_UPLOAD: 'ERROR_IMAGE_UPLOAD',
    SOCIAL_MEDIA: 'SOCIAL_MEDIA',
    PROFILE_UPDATED: 'PROFILE_UPDATED',
    PROFILE_UPDATE_FAILED: 'PROFILE_UPDATE_FAILED',
    CROP_IMAGE: 'CROP_IMAGE',
    VIEW_PROFILE: 'VIEW_PROFILE',
    SAVE: 'SAVE',
    SUBS_LIMIT_REACHED: 'SUBS_LIMIT_REACHED',
    WELCOME_TO_KOPA: 'WELCOME_TO_KOPA',
    MY_PROFILE: 'MY_PROFILE',
    GUEST: 'GUEST',
    BUTTON_GUEST: 'BUTTON_GUEST',

    // REPORTS
    LOAD_MORE_PAGINATION: 'LOAD_MORE_PAGINATION',
    REPORTS: 'REPORTS',
    PRESENCE_REPORT: 'PRESENCE_REPORT',
    EXPORT: 'EXPORT',
    REFRESH: 'REFRESH',
    VIEW_REPORT: 'VIEW_REPORT',
    GET_PRESENCE_REPORT_FALURE: 'GET_PRESENCE_REPORT_FALURE',
    GET_COURSE_FAILURE: 'GET_COURSE_FAILURE',
    VIEW_REPORT_WARN: 'VIEW_REPORT_WARN',
    EXPORT_REPORT_FAILURE: 'EXPORT_REPORT_FAILURE',
    OF_VIEW: 'OF_VIEW',
    VIEWING_TIME: 'VIEWING_TIME',

    // QUIZZES
    QUIZ: 'QUIZ',
    QUIZZES: 'QUIZZES',
    SURVEY: 'SURVEY',
    QUIZ_DETAIL: 'QUIZZ_DETAIL',
    SURVEY_DETAIL: 'SURVEY_DETAIL',
    SURVEY_RIGHT_ANSWER: 'SURVEY_RIGHT_ANSWER',
    QUIZZES_SURVEYS: 'QUIZZES_SURVEYS',
    IN_PROGRESS: 'IN_PROGRESS',
    DISABLED: 'DISABLED',
    NEW_QUIZ: 'NEW_QUIZ',
    NEW_SURVEY: 'NEW_SURVEY',
    NEW_QUIZ_OR_SURVEY: 'NEW_QUIZ_OR_SURVEY',
    TYPE: 'TYPE',
    TITLE: 'TITLE',
    DURATION: 'DURATION',
    ANSWER: 'ANSWER',
    DETAILS: 'DETAILS',
    QUESTION: 'QUESTION',
    QUESTIONS: 'QUESTIONS',
    QUESTIONS_QUANTITY: 'QUESTIONS_QUANTITY',
    QUESTION_SCORE: 'QUESTION_SCORE',
    CORRECT_ANSWER: 'CORRECT_ANSWER',
    OPTION_CHARACTERS_INFO: 'OPTION_CHARACTERS_INFO',
    SELECT: 'SELECT',
    SCREEN_TIME_DURATION: 'SCREEN_TIME_DURATION',
    SAVE_QUIZ: 'SAVE_QUIZ',
    SAVE_SURVEY: 'SAVE_SURVEY',
    ADD: 'ADD',
    PUBLISH: 'PUBLISH',
    PUBLISHED: 'PUBLISHED',
    SURVEY_PUBLISHED: 'SURVEY_PUBLISHED',
    UNPUBLISHED: 'UNPUBLISHED',
    SURVEY_UNPUBLISHED: 'SURVEY_UNPUBLISHED',
    WARNING: 'WARNING',
    CHECK_DATA: 'CHECK_DATA',
    UPDATE_QUESTION_SUCCESS: 'UPDATE_QUESTION_SUCCESS',
    UPDATE_QUESTION_FAIL: 'UPDATE_QUESTION_FAIL',
    ERROR_DUPLICATE_QUESTION: 'ERROR_DUPLICATE_QUESTION',
    LIMIT_CHARACTER: 'LIMIT_CHARACTER',
    RELEASE_QUESTION_FAIL: 'RELEASE_QUESTION_FAIL',
    CREATE_QUESTION_SUCCESS: 'CREATE_QUESTION_SUCCESS',
    CREATE_QUESTION_FAIL: 'CREATE_QUESTION_FAIL',
    QUIZ_RESULT: 'QUIZ_RESULT',
    SURVEY_RESULT: 'SURVEY_RESULT',
    VIEW_QUIZZES_WARN: 'VIEW_QUIZZES_WARN',
    FIELD_MANDATORY: 'FIELD_MANDATORY',
    FIELD_DUPLICATE: 'FIELD_DUPLICATE',
    REQUIRED_QUESTIONS_FIELDS: 'REQUIRED_QUESTIONS_FIELDS',
    REQUIRED_OPTIONAL_QUESTION_FIELD: 'REQUIRED_OPTIONAL_QUESTION_FIELD',

    // RANKING
    SORT_BY: 'SORT_BY',
    TOTAL_OF_POINTS: 'TOTAL_OF_POINTS',
    LABEL_SEARCH_INPUT: 'LABEL_SEARCH_INPUT',
    DECREASING: 'DECREASING',
    POINTS: 'POINTS',
    LAST_UPDATE: 'LAST_UPDATE',
    ASCENDING: 'ASCENDING',
    POSITION: 'POSITION',
    PLACE: 'PLACE',

    // BLOCK LIST
    BLOCK_LIST: 'BLOCK_LIST',
    NAME_CHAT: 'NAME_CHAT',
    BLOCK_DATE: 'BLOCK_DATE',
    REMOVE_BLOCKING: 'REMOVE_BLOCKING',
    UNBLOCKING_MSG: 'UNBLOCKING_MSG',
    REMOVE: 'REMOVE',
    BLOCKING: 'BLOCKING',
    CONFIRM_UNBLOCKING_YES: 'CONFIRM_UNBLOCKING_YES',
    CONFIRM_UNBLOCKING_NO: 'CONFIRM_UNBLOCKING_NO',
    VIEW_BLOCKLIST_WARN: 'VIEW_BLOCKLIST_WARN',
    BLOCK_LIST_FAILURE: 'BLOCK_LIST_FAILURE',
    UNBLOCK_USER_SUCCESS: 'UNBLOCK_USER_SUCCESS',
    UNBLOCK_USER_FAILURE: 'UNBLOCK_USER_FAILURE',

    // WebRTC
    TRANSMISSION: 'TRANSMISSION',
    WAITING_TRANSMISSION: 'WAITING_TRANSMISSION',
    NO_LIVE_IN_PROGRESS: 'NO_LIVE_IN_PROGRESS',
    MUTE_USER: 'MUTE_USER',
    UNMUTE_USER: 'UNMUTE_USER',
    CREATING_ROOM_PLEASE_WAIT: 'CREATING_ROOM_PLEASE_WAIT',

    // Onbaording home
    ONBOARDING_HOME_WELCOME_TITLE: 'ONBOARDING_HOME_WELCOME_TITLE',
    ONBOARDING_HOME_WELCOME: 'ONBOARDING_HOME_WELCOME',
    ONBOARDING_HOME_LOGOUT_TITLE: 'ONBOARDING_HOME_LOGOUT_TITLE',
    ONBOARDING_HOME_LOGOUT: 'ONBOARDING_HOME_LOGOUT',
    ONBOARDING_HOME_MY_VIDEOS_TITLE: 'ONBOARDING_HOME_MYVIDEOS_TITLE',
    ONBOARDING_HOME_MY_VIDEOS: 'ONBOARDING_HOME_MYVIDEOS',
    ONBOARDING_HOME_VIDEO_CARD_TITLE: 'ONBOARDING_HOME_VIDEOCARD_TITLE',
    ONBOARDING_HOME_VIDEO_CARD: 'ONBOARDING_HOME_VIDEOCARD',
    ONBOARDING_HOME_USERS_TITLE: 'ONBOARDING_HOME_USERS_TITLE',
    ONBOARDING_HOME_USERS: 'ONBOARDING_HOME_USERS',
    ONBOARDING_HOME_GAMEFICATION_TITLE: 'ONBOARDING_HOME_GAMEFICATION_TITLE',
    ONBOARDING_HOME_GAMEFICATION: 'ONBOARDING_HOME_GAMEFICATION',
    ONBOARDING_HOME_NEW_VIDEO_TITLE: 'ONBOARDING_HOME_NEW_VIDEO_TITLE',
    ONBOARDING_HOME_NEW_VIDEO: 'ONBOARDING_HOME_NEW_VIDEO',
    ONBOARDING_HOME_USER_AREA_TITLE: 'ONBOARDING_HOME_USER_AREA_TITLE',
    ONBOARDING_HOME_USER_AREA: 'ONBOARDING_HOME_USER_AREA',
    ONBOARDING_HOME_MENU_TITLE: 'ONBOARDING_HOME_MENU_TITLE',
    ONBOARDING_HOME_MENU: 'ONBOARDING_HOME_MENU',
    ONBOARDING_HOME_TUTORIALS_TITLE: 'ONBOARDING_HOME_TUTORIALS_TITLE',
    ONBOARDING_HOME_TUTORIALS: 'ONBOARDING_HOME_TUTORIALS',

    // Onboarding buttons
    ONBOARDING_LETS_START: 'ONBOARDING_LETS_START',
    ONBOARDING_SKIP_TOUR: 'ONBOARDING_SKIP_TOUR',
    ONBOARDING_HOME_COMPLETE: 'ONBOARDING_HOME_COMPLETE',
    ONBOARDING_COMPLETE: 'ONBOARDING_COMPLETE',

    // Onboarding users
    ONBOARDING_USERS_AREA_TITLE: 'ONBOARDING_USERS_AREA_TITLE',
    ONBOARDING_USERS_AREA: 'ONBOARDING_USERS_AREA',
    ONBOARDING_USERS_SEARCH_TITLE: 'ONBOARDING_USERS_SEARCH_TITLE',
    ONBOARDING_USERS_SEARCH: 'ONBOARDING_USERS_SEARCH',
    ONBOARDING_USERS_OPTIONS_TITLE: 'ONBOARDING_USERS_OPTIONS_TITLE',
    ONBOARDING_USERS_OPTIONS: 'ONBOARDING_USERS_OPTIONS',
    ONBOARDING_USERS_CONTAINER_TITLE: 'ONBOARDING_USERS_CONTAINER_TITLE',
    ONBOARDING_USERS_CONTAINER: 'ONBOARDING_USERS_CONTAINER',
    ONBOARDING_USERS_ICON_DOTS_TITLE: 'ONBOARDING_USERS_ICON_DOTS_TITLE',
    ONBOARDING_USERS_ICON_DOTS: 'ONBOARDING_USERS_ICON_DOTS',

    // Onboarding gamification
    ONBOARDING_GAMIFICATION_AREA_TITLE: 'ONBOARDING_GAMIFICATION_AREA_TITLE',
    ONBOARDING_GAMIFICATION_AREA: 'ONBOARDING_GAMIFICATION_AREA',
    ONBOARDING_GAMIFICATION_SEARCH_AREA_TITLE: 'ONBOARDING_GAMIFICATION_SEARCH_AREA_TITLE',
    ONBOARDING_GAMIFICATION_SEARCH_AREA: 'ONBOARDING_GAMIFICATION_SEARCH_AREA',
    ONBOARDING_GAMIFICATION_FILTER_AREA: 'ONBOARDING_GAMIFICATION_FILTER_AREA',
    ONBOARDING_GAMIFICATION_FILTER: 'ONBOARDING_GAMIFICATION_FILTER',
    ONBOARDING_GAMIFICATION_SETTINGS_TITLE: 'ONBOARDING_GAMIFICATION_SETTINGS_TITLE',
    ONBOARDING_GAMIFICATION_SETTINGS: 'ONBOARDING_GAMIFICATION_SETTINGS',
    ONBOARDING_GAMIFICATION_MORE_OPTIONS_TITLE: 'ONBOARDING_GAMIFICATION_MORE_OPTIONS_TITLE',
    ONBOARDING_GAMIFICATION_MORE_OPTIONS: 'ONBOARDING_GAMIFICATION_MORE_OPTIONS',
    ONBOARDING_GAMIFICATION_CONTAINER_TITLE: 'ONBOARDING_GAMIFICATION_CONTAINER_TITLE',
    ONBOARDING_GAMIFICATION_CONTAINER: 'ONBOARDING_GAMIFICATION_CONTAINER',

    // Onboarding videos
    ONBOARDING_VIDEOS_EDIT_LIVE_TITLE: 'ONBOARDING_VIDEOS_EDIT_LIVE_TITLE',
    ONBOARDING_VIDEOS_EDIT_LIVE: 'ONBOARDING_VIDEOS_EDIT_LIVE',
    ONBOARDING_VIDEOS_DASHBOARD_TITLE: 'ONBOARDING_VIDEOS_DASHBOARD_TITLE',
    ONBOARDING_VIDEOS_DASHBOARD: 'ONBOARDING_VIDEOS_DASHBOARD',
    ONBOARDING_VIDEOS_QRCODE_TITLE: 'ONBOARDING_VIDEOS_QRCODE_TITLE',
    ONBOARDING_VIDEOS_QRCODE: 'ONBOARDING_VIDEOS_QRCODE',
    ONBOARDING_VIDEOS_QUIZZES_TITLE: 'ONBOARDING_VIDEOS_QUIZZES_TITLE',
    ONBOARDING_VIDEOS_QUIZZES: 'ONBOARDING_VIDEOS_QUIZZES',
    ONBOARDING_VIDEOS_REPORTS_TITLE: ' ONBOARDING_VIDEOS_REPORTS_TITLE',
    ONBOARDING_VIDEOS_REPORTS: ' ONBOARDING_VIDEOS_REPORTS',
    ONBOARDING_VIDEOS_BLOCK_LIST_CHAT_TITLE: 'ONBOARDING_VIDEOS_BLOCK_LIST_CHAT_TITLE',
    ONBOARDING_VIDEOS_BLOCK_LIST_CHAT: 'ONBOARDING_VIDEOS_BLOCK_LIST_CHAT',
    ONBOARDING_VIDEOS_ENGAGEMENT_TITLE: 'ONBOARDING_VIDEOS_ENGAGEMENT_TITLE',
    ONBOARDING_VIDEOS_ENGAGEMENT: 'ONBOARDING_VIDEOS_ENGAGEMENT',
    ONBOARDING_VIDEOS_PREVIEW_TITLE: 'ONBOARDING_VIDEOS_PREVIEW_TILE',
    ONBOARDING_VIDEOS_PREVIEW: 'ONBOARDING_VIDEOS_PREVIEW',
    ONBOARDING_VIDEOS_GRAPH_TITLE: 'ONBOARDING_VIDEOS_GRAPH_TITLE',
    ONBOARDING_VIDEOS_GRAPH: 'ONBOARDING_VIDEOS_GRAPH',
    ONBOARDING_VIDEOS_MONITORS_LIST_TITLE: 'ONBOARDING_VIDEOS_MONITORS_LIST_TITLE',
    ONBOARDING_VIDEOS_MONITORS_LIST: 'ONBOARDING_VIDEOS_MONITORS_LIST',
    ONBOARDING_VIDEOS_GELOCALIZATION_TITLE: 'ONBOARDING_VIDEOS_GELOCALIZATION_TITLE',
    ONBOARDING_VIDEOS_GELOCALIZATION: 'ONBOARDING_VIDEOS_GELOCALIZATION',

    // Captions
    CAPTION: 'CAPTION',
    CAPTIONS: 'CAPTIONS',
    VIDEO_DETAILS: 'VIDEO_DETAILS',
    SELECT_CAPTION_LANGUAGE: 'SELECT_CAPTION_LANGUAGE',
    SAVE_CAPTION: 'SAVE_CAPTION',
    ADD_CAPTION: 'ADD_CAPTION',
    NEW_CAPTION: 'NEW_CAPTION',
    REMOVE_CAPTION_CONFIRMATION: 'REMOVE_CAPTION_CONFIRMATION',
    CAPTION_UPLOAD_SUCCESSFULLY: 'CAPTION_UPLOAD_SUCCESSFULLY',
    CAPTION_REMOVED_SUCCESSFULLY: 'CAPTION_REMOVED_SUCCESSFULLY',
    CAPTION_REMOVED_FAILED: 'CAPTION_REMOVED_FAILED',
    CAPTION_UPLOAD_FAILED: 'CAPTION_UPLOAD_FAILED',
    CAPTION_UPDATE_SUCCESSFULLY: 'CAPTION_UPDATE_SUCCESSFULLY',
    CAPTION_UPDATE_FAILED: 'CAPTION_UPDATE_FAILED',
    CAPTION_GET_FAILED: 'CAPTION_GET_FAILED',
    CAPTION_STATUS_ERROR: 'CAPTION_STATUS_ERROR',
    CAPTION_STATUS_QUEUED: 'CAPTION_STATUS_QUEUED',
    CAPTION_STATUS_READY: 'CAPTION_STATUS_READY',
    CAPTION_STATUS_REMOVED: 'CAPTION_STATUS_REMOVED',
    CAPTION_STATUS_IMPORTING: 'CAPTION_STATUS_IMPORTING',
    CAPTION_STATUS_EXPORTING: 'CAPTION_STATUS_EXPORTING',
    NO_CAPTIONS_FOUND: 'NO_CAPTIONS_FOUND',
    FIRST_CAPTION_MESSAGE: 'FIRST_CAPTION_MESSAGE',
    REMOVING: 'REMOVING',
    DOWNLOADING: 'DOWNLOADING',
    DOWNLOAD_CAPTION: 'DOWNLOAD_CAPTION',
    BACK_TO_VIDEOS: 'BACK_TO_VIDEOS',
    CAPTION_LANGUAGE_EXISTS: 'CAPTION_LANGUAGE_EXISTS',
    LIVE_SETTINGS: 'LIVE_SETTINGS',
    VIDEO_SETTINGS: 'VIDEO_SETTINGS',

    // Possible languages
    ARABIC: 'ARABIC',
    CANTONESE: 'CANTONESE',
    CATALAN: 'CATALAN',
    CHINESE: 'CHINESE',
    DANISH: 'DANISH',
    DUTCH: 'DUTCH',
    FINNISH: 'FINNISH',
    FRENCH: 'FRENCH',
    GERMAN: 'GERMAN',
    GREEK: 'GREEK',
    HEBREW: 'HEBREW',
    HINDI: 'HINDI',
    HUNGARIAN: 'HUNGARIAN',
    ICELANDIC: 'ICELANDIC',
    INDONESIAN: 'INDONESIAN',
    IRISH: 'IRISH',
    ITALIAN: 'ITALIAN',
    JAPANESE: 'JAPANESE',
    KOREAN: 'KOREAN',
    MALAYALAM: 'MALAYALAM',
    'MANDARIN CHINESE': 'MANDARIN CHINESE',
    NORWEGIAN: 'NORWEGIAN',
    POLISH: 'POLISH',
    ROMANIAN: 'ROMANIAN',
    RUSSIAN: 'RUSSIAN',
    SWEDISH: 'SWEDISH',
    'TAIWANESE MANDARIN': 'TAIWANESE MANDARIN',
    TAMIL: 'TAMIL',
    THAI: 'THAI',
    TURKISH: 'TURKISH',
    UKRAINIAN: 'UKRAINIAN',
    URDU: 'URDU',
    VIETNAMESE: 'VIETNAMESE',
    WELSH: 'WELSH',
    ZULU: 'ZULU',

    // Onboarding tutorials
    ONBOARDING_TUTORIALS_AREA_TITLE: 'ONBOARDING_TUTORIALS_AREA_TITLE',
    ONBOARDING_TUTORIALS_AREA: 'ONBOARDING_TUTORIALS_AREA',
    ONBOARDING_TUTORIALS_TUTORIAL_CARD_TITLE: 'ONBOARDING_TUTORIALS_TUTORIAL_CARD_TITLE',
    ONBOARDING_TUTORIALS_TUTORIAL_CARD: 'ONBOARDING_TUTORIALS_TUTORIAL_CARD',

    // Tutorials
    TUTORIALS: 'TUTORIALS',
    FAIL_TO_LOAD_VIDEO_PROGRESS: 'FAIL_TO_LOAD_VIDEO_PROGRESS',
    FAIL_TO_LOAD_TUTORIALS: 'FAIL_TO_LOAD_TUTORIALS',

    // Network failed
    NETWORK_FAILED: 'NETWORK_FAILED',
    NETWORK_FAILED_MESSAGE: 'NETWORK_FAILED_MESSAGE',
    // statistic video
    STATISTIC_VIDEO_DETAIL: 'STATISTIC_VIDEO_DETAIL',
    STATISTIC_VIDEO_DAY: 'STATISTIC_VIDEO_DAY',
    STATISTIC_VIDEO_DOWNLOAD: 'STATISTIC_VIDEO_DOWNLOAD',
    STATISTIC_VIDEO_PEOPLE_WATCH: 'STATISTIC_VIDEO_PEOPLE_WATCH',
    STATISTIC_VIDEO_PEOPLE_AVERAGE_TIME_WATCH: 'STATISTIC_VIDEO_PEOPLE_AVERAGE_TIME_WATCH',
    STATISTIC_VIDEO_PLAY_IN_VIDEO: 'STATISTIC_VIDEO_PLAY_IN_VIDEO',
    STATISTIC_VIDEO_ATTACHMENTS_ADDED: 'STATISTIC_VIDEO_ATTACHMENTS_ADDED',
    STATISTIC_VIDEO_ATTACHMENTS_DOWNLOAD: 'STATISTIC_VIDEO_ATTACHMENTS_DOWNLOAD',

    // Notify poll status
    ROOM_POLL_STATUS_NOTIFY_FAILED: 'ROOM_POLL_STATUS_NOTIFY_FAILED',
    ROOM_POLL_STATUS_NOTIFY_SUCCESS: 'ROOM_POLL_STATUS_NOTIFY_SUCCESS',

    // Room status
    ROOM_STATUS_CREATED: 'ROOM_STATUS_CREATED',
    ROOM_STATUS_ERROR_CONVERTING: 'ROOM_STATUS_ERROR_CONVERTING',
    ROOM_STATUS_UNKNOWN: 'ROOM_STATUS_UNKNOWN',

    MAX_SCHEDULE_TIME: 'MAX_SCHEDULE_TIME',
};
