import React, { useEffect, useState } from 'react';
import {
    FormControl,
    FormControlLabel,
    InputLabel,
    withStyles,
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import Switch from '@material-ui/core/Switch';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { QUIZ_TYPE } from '@utils/constants';
import api from '@services/api';
import Modal from '@components/Modal';
import Notify from '@utils/notification';
import { numberToLetter } from '@utils/functions';
import { getQuizzes, getQuizzesSuccess } from '@src/store/modules/quizzes/actions';
import langs from '@langs/constants';
import { Form, SubmitButton } from './styles';

const CustomInput = withStyles((theme) => ({
    root: { 'label + &': { marginTop: '20px' } },
    input: {
        color: '#fff',
        fontWeight: 'bold',
        borderRadius: 8,
        position: 'relative',
        backgroundColor: '#24243e',
        border: '1px solid #24243e',
        fontSize: 16,
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': { borderColor: '#ff6479' },
    },
}))(InputBase);

const CustomSwitch = withStyles({
    switchBase: {
        color: '#24243e',
        '&$checked': { color: '#2d8182' },
        '&$checked + $track': { backgroundColor: '#2d8182' },
    },
    checked: {},
    track: { backgroundColor: '#24243e' },
})(Switch);

function hasDuplicatesValues(array) {
    const valuesSoFar = [];
    for (let i = 0; i < array.length; i += 1) {
        const value = array[i];
        if (valuesSoFar.indexOf(value) !== -1) {
            return true;
        }
        valuesSoFar.push(value);
    }
    return false;
}

function formatOption(options = '') {
    return Object.values(options)
        .filter(item => item !== '')
        .map((item, index) => ({ value: numberToLetter(index), label: item }));
}

export default function QuizContentForm({
    quizType, roomId, editQuestion = null, clearQuestion, closeForm, hasGamificationRule = false,
}) {
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [duration, setDuration] = useState(0);
    const [question, setQuestion] = useState('');
    const [title, setTitle] = useState('');
    const [released, setReleased] = useState(false);
    const [options, setOptions] = useState({ a: '', b: '', c: '', d: '' });
    const [showWarn, setShowWarn] = useState(false);
    const [errorText, setErrorText] = useState('');
    const { questions } = useSelector(state => state.quizzes);
    const [loading, setLoading] = useState(false);
    const [scoring, setScoring] = useState(10);
    const dispatch = useDispatch();

    const { t } = useTranslation();

    async function handleSubmit(e) {
        e.preventDefault();
        if (!title) {
            setErrorText(t(langs.TITLE_MANDATORY));
            setShowWarn(true);
            return;
        }
        if (!question) {
            setErrorText(t(langs.QUESTION_MANDATORY));
            setShowWarn(true);
            return;
        }
        if (!(options.a && options.b)) {
            setErrorText(t(langs.TWO_ITEMS_MANDATORY));
            setShowWarn(true);
            return;
        }
        if (quizType === QUIZ_TYPE.QUIZ && !correctAnswer) {
            setErrorText(t(langs.CORRECT_ANSWER_MANDATORY));
            setShowWarn(true);
            return;
        }
        if (!Number(duration) > 0) {
            setErrorText(t(langs.DURATION_MANDATORY));
            setShowWarn(true);
            return;
        }

        if (hasDuplicatesValues(Object.values(options).map(item => (item?.length > 0 ? item : null)).filter(item => item !== null))) {
            setErrorText(t(langs.ANSWER_DIFFERENT_MANDATORY));
            setShowWarn(true);
            return;
        }

        if (editQuestion) {
            let questionData = {};

            if (editQuestion.status === 'onExcution') {
                questionData = { duration: Number(duration) };
            } else {
                questionData = {
                    title,
                    question,
                    answers: Object.values(options).map(item => (item?.length > 0 ? item : null)).filter(item => item !== null),
                    correctAnswer: options[correctAnswer],
                    duration: Number(duration),
                    gamificationPoints: scoring,
                };
            }

            const rollBackList = questions;

            try {
                await api.editQuestion(roomId, questionData, editQuestion.id).then(() => {
                    dispatch(getQuizzes(roomId));
                });
                closeForm();
                Notify.success(t(langs.UPDATE_QUESTION_SUCCESS));
                if (editQuestion.released !== released) {
                    await api.editReleaseQuestion(roomId, editQuestion.id, { release: released })
                        .then(() => {
                            dispatch(getQuizzes(roomId));
                        })
                        .catch((error) => {
                            Notify.error(error?.response?.data?.error_description || t(langs.RELEASE_QUESTION_FAIL));
                            dispatch(getQuizzes(roomId));
                        });
                }
            } catch (error) {
                setLoading(false);
                dispatch(getQuizzesSuccess(rollBackList));
                Notify.error(error?.response?.data?.error_description || t(langs.UPDATE_QUESTION_FAIL));
            }
        } else {
            const questionData = {
                questionType: quizType,
                title,
                question,
                answers: Object.values(options).map(item => (item?.length > 0 ? item : null)).filter(item => item !== null),
                correctAnswer: options[correctAnswer],
                duration: Number(duration),
                gamificationPoints: scoring,
            };
            try {
                setLoading(true);
                const { id: createdQuestion } = await api.postQuestion(roomId, questionData);
                dispatch(getQuizzes(roomId));
                if (released) {
                    await api.editReleaseQuestion(roomId, createdQuestion, { release: released })
                        .then(() => dispatch(getQuizzes(roomId)))
                        .catch((error) => {
                            Notify.error(error?.response?.data?.error_description || t(langs.RELEASE_QUESTION_FAIL));
                            dispatch(getQuizzes(roomId));
                        });
                }
                setLoading(false);
                dispatch(getQuizzes(roomId));
                Notify.success(t(langs.CREATE_QUESTION_SUCCESS));
            } catch (error) {
                setLoading(false);
                Notify.error(error?.response?.data?.error_description || t(langs.CREATE_QUESTION_FAIL));
            }
            closeForm();
        }
    }

    function handleChangeOption(e) {
        setOptions({ ...options, [e.target.name]: e.target.value });
    }

    function handleChangeScore(e) {
        const value = e.target.value.replace(/\D/g, '');
        setScoring(Number(value));
    }

    useEffect(() => {
        if (editQuestion) {
            setTitle(editQuestion?.title);
            setQuestion(editQuestion?.question);
            setDuration(editQuestion?.duration);
            setReleased(editQuestion?.released);
            setOptions({ a: editQuestion?.answers[0], b: editQuestion?.answers[1], c: editQuestion?.answers[2], d: editQuestion?.answers[3] });
            setCorrectAnswer(numberToLetter(editQuestion.answers.findIndex(item => item === editQuestion?.correctAnswer)));
            setScoring(editQuestion?.gamificationPoints);
        }

        return () => clearQuestion();
    }, [clearQuestion, editQuestion]);

    return (
        <Form onSubmit={handleSubmit}>
            <Modal isOpen={showWarn} handleModal={() => setShowWarn(false)} title={t(langs.WARNING)} featuredTitle={t(langs.CHECK_DATA)}>
                <div className="--warn-content">
                    <h5>{errorText}</h5>
                    <button type="button" onClick={() => setShowWarn(false)}>OK</button>
                </div>
            </Modal>

            <FormControl disabled={editQuestion?.released}>
                <InputLabel shrink htmlFor="title">
                    {t(langs.TITLE)}
                </InputLabel>
                <CustomInput id="title" name="title" value={title} onChange={e => setTitle(e.target.value)} placeholder={t(langs.ENTER_TITLE)} />
            </FormControl>
            <FormControl disabled={editQuestion?.released}>
                <InputLabel shrink htmlFor="question">
                    {t(langs.QUESTION)}
                </InputLabel>
                <CustomInput
                    placeholder={t(langs.TYPE_QUESTION)}
                    id="question"
                    name="question"
                    value={question}
                    onChange={e => setQuestion(e.target.value)}
                    inputComponent="textarea"
                    rowsMin={5}
                />
            </FormControl>
            <FormControl disabled={editQuestion?.released}>
                <InputLabel shrink htmlFor="option-a">
                    Item A
                </InputLabel>
                <CustomInput id="option-a" placeholder="Item A" name="a" value={options.a} onChange={handleChangeOption} />
            </FormControl>
            <FormControl disabled={editQuestion?.released}>
                <InputLabel shrink htmlFor="option-b">
                    Item B
                </InputLabel>
                <CustomInput id="option-b" placeholder="Item B" name="b" value={options.b} onChange={handleChangeOption} />
            </FormControl>
            <FormControl disabled={editQuestion?.released}>
                <InputLabel shrink htmlFor="option-c">
                    Item C
                    <small>{t(langs.OPTIONAL)}</small>
                </InputLabel>
                <CustomInput id="option-c" placeholder="Item C" name="c" value={options.c} onChange={handleChangeOption} />
            </FormControl>
            <FormControl disabled={editQuestion?.released}>
                <InputLabel shrink htmlFor="option-d">
                    Item D
                    <small>{t(langs.OPTIONAL)}</small>
                </InputLabel>
                <CustomInput id="option-d" placeholder="Item D" name="d" value={options.d} onChange={handleChangeOption} />
            </FormControl>
            {
                quizType === QUIZ_TYPE.QUIZ
                    ? (
                        <FormControl className="quiz-form-control-type">
                            <FormControl disabled={editQuestion?.released}>
                                <InputLabel shrink htmlFor="correct-answer">
                                    {t(langs.CORRECT_ANSWER)}
                                </InputLabel>
                                <CustomInput
                                    id="correct-answer"
                                    inputComponent={() => (
                                        <Dropdown
                                            disabled={editQuestion?.released}
                                            options={formatOption(options)}
                                            onChange={option => setCorrectAnswer(option.value)}
                                            value={correctAnswer}
                                            placeholder={t(langs.SELECT)}
                                        />
                                    )}
                                />
                            </FormControl>
                            <FormControl>
                                <InputLabel shrink htmlFor="duration">
                                    {t(langs.SCREEN_TIME_DURATION)}
                                </InputLabel>
                                <CustomInput
                                    id="duration"
                                    name="duration"
                                    inputProps={{ min: '0', step: '1' }}
                                    type="number"
                                    value={duration}
                                    onChange={e => setDuration(e.target.value)}
                                />
                            </FormControl>
                        </FormControl>

                    )
                    : (
                        <FormControl>
                            <InputLabel shrink htmlFor="duration">
                                {t(langs.SCREEN_TIME_DURATION)}
                            </InputLabel>
                            <CustomInput
                                id="duration"
                                name="duration"
                                inputProps={{ min: '0', step: '1' }}
                                type="number"
                                value={duration}
                                onChange={e => setDuration(e.target.value)}
                            />
                        </FormControl>
                    )
            }

            {hasGamificationRule && (
                <FormControl disabled={editQuestion?.released} data-testid="quiz-scoring">
                    <InputLabel shrink htmlFor="scoring">
                        {t(langs.SCORE)}
                    </InputLabel>
                    <CustomInput
                        type="number"
                        id="scoring"
                        placeholder={t(langs.SCORE)}
                        name="gamificationPoints"
                        inputProps={{ min: '10', step: '1' }}
                        value={scoring}
                        onChange={handleChangeScore}
                    />
                </FormControl>

            )}
            <FormControl className="switch-wrapper">
                <FormControlLabel
                    control={(
                        <CustomSwitch
                            checked={released}
                            onChange={() => setReleased(!released)}
                        />
                    )}
                    label={`${t(langs.PUBLISH)} ${quizType === QUIZ_TYPE.QUIZ ? t(langs.QUIZ) : t(langs.SURVEY)}?`.toUpperCase()}
                    labelPlacement="start"
                />
            </FormControl>
            <FormControl className="submit-button-wrapper">
                <SubmitButton
                    disabled={loading}
                    type="submit"
                >
                    {quizType === QUIZ_TYPE.QUIZ ? t(langs.SAVE_QUIZ) : t(langs.SAVE_SURVEY)}
                </SubmitButton>
            </FormControl>
        </Form>
    );
}
