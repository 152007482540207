import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useDebounce } from 'use-debounce';
import { getRanking, getRankingNextPage } from '@src/store/modules/gamification/actions';
import langs from '@langs/constants';
import useRooms from '@src/hooks/useRooms';
import { getChannels } from '@src/store/modules/rooms/actions';
import { useBreadcrumbs } from '@src/hooks/useBreadcrumbs';
import MenuSearchBar from '@src/components/MenuSearchBar';
import NoContent from '@src/components/NoContent';
import Notify from '@utils/notification';
import api from '@services/api';
import i18n from '@langs/i18n';
import importDownload from '@src/assets/importdownload.svg';
import gamificationSettings from '@src/assets/gameficationSettings.svg';
import OnboardingGamification from '@src/components/Onbordings/onboardingGamification';
import useAuth from '@src/hooks/useAuth';
import SettingsModal from './SettingsModal';
import {
    Header,
    TableUsers,
    Main,
    TableFooterResults,
    LoadMoreButtonSection,
} from './style';

const LoadingSkeleton = () => (
    <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
        <div style={{ marginTop: 10 }}>
            <Skeleton height={50} count={10} style={{ margin: '5px 0' }} />
        </div>
    </SkeletonTheme>
);

export default function RankingGamification() {
    const dispatch = useDispatch();
    const { setItems } = useBreadcrumbs();
    const { selectedChannel, channelsListForSelectSidebar } = useRooms();
    const [page] = useState(1);
    const { ranking, loading, nextPageLoading, isLastPage } = useSelector(
        (state) => state.gamification,
    );
    const [ordination, setOrdination] = useState('desc');
    const [keyword, setKeyword] = useState('');
    const [settingsModalVisible, setSettingsModalVisible] = useState(false);
    const [debouncedKeyword] = useDebounce(keyword, 500);
    const [sortBy, setSortBy] = useState('points');
    const { t } = useTranslation();
    const [rowsPerPage] = useState(10);
    const selectedChannelId = selectedChannel?.id;

    function clearFilter() {
        setKeyword('');
    }

    const handleSearch = useCallback(() => {
        if (selectedChannel?.id != null) {
            if (debouncedKeyword === '') {
                dispatch(
                    getRanking(
                        selectedChannel?.id,
                        rowsPerPage,
                        page,
                        ordination,
                        sortBy,
                        debouncedKeyword,
                    ),
                );
            } else {
                dispatch(
                    getRanking(
                        selectedChannel?.id,
                        rowsPerPage,
                        1,
                        ordination,
                        sortBy,
                        debouncedKeyword,
                    ),
                );
            }
        }
    }, [
        debouncedKeyword,
        dispatch,
        ordination,
        page,
        rowsPerPage,
        selectedChannel,
        sortBy,
    ]);

    function handleOrdination(value, sort) {
        setSortBy(value);
        setOrdination(sort);
    }

    async function handleExport() {
        if (selectedChannel?.id) {
            try {
                Notify.success(t(langs.PREPARING_DOWNLOAD));
                const response = await api.exportRankingReport(
                    selectedChannel?.id,
                );
                const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${t(langs.RANKING).replace(/ /g, '_')}_${new Date()
                        .toLocaleString()
                        .replace(/\//g, '-')
                        .replace(/ /g, '_')}.csv`,
                );
                document.body.appendChild(link);
                link.click();
                link.remove();
            } catch (error) {
                Notify.error(t(langs.EXPORT_REPORT_FAILURE));
            }
        }
    }

    const handleGamificationSettings = () => {
        if (!selectedChannelId) {
            Notify.warn(t(langs.GET_COURSE_FAILURE));
            return;
        }
        setSettingsModalVisible(true);
    };

    const handleNextPage = () => {
        dispatch(
            getRankingNextPage(
                selectedChannel?.id,
                rowsPerPage,
                page + 1,
                ordination,
                sortBy,
                debouncedKeyword,
            ),
        );
    };

    const moreOptions = [
        {
            action: handleExport,
            text: t(langs.DOWNLOAD_SPREADSHEET),
            icon: importDownload,
        },
    ];

    useEffect(() => {
        if (!channelsListForSelectSidebar.length) {
            dispatch(getChannels());
        }
    }, [dispatch, channelsListForSelectSidebar]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    useEffect(() => {
        const breadcrumbsItems = [
            { to: '/', label: t(langs.ORGANIZER_DASHBOARD) },
            { to: '/ranking', label: t(langs.GAMIFICATION) },
            {
                to: '/ranking',
                label: `${selectedChannel?.name} - ${selectedChannel?.id}`,
            },
        ];
        setItems(breadcrumbsItems);
    }, [selectedChannel, setItems, t]);

    const { user } = useAuth();

    return (
        <>
            <Main>
                {(user)
                && <OnboardingGamification />}
                <Header className="header">
                    <MenuSearchBar
                        moreOptions={moreOptions}
                        orderSelect
                        options={[
                            {
                                label: `${t(langs.POINTS)} - ${t(langs.DECREASING)}`,
                                sort: 'desc',
                                value: 'points',
                            },
                            {
                                label: `${t(langs.POINTS)} - ${t(langs.ASCENDING)}`,
                                sort: 'asc',
                                value: 'points',
                            },
                            {
                                label: `${t(langs.NAME)} - A-Z`,
                                sort: 'asc',
                                value: 'name',
                            },
                            {
                                label: `${t(langs.NAME)} - Z-A`,
                                sort: 'desc',
                                value: 'name',
                            },
                            {
                                label: `${t(langs.LAST_UPDATE)}`,
                                sort: 'desc',
                                value: 'update',
                            },
                        ]}
                        setOption={handleOrdination}
                        handleChangeInputFilter={setKeyword}
                        placeholder={t(langs.LABEL_SEARCH_INPUT)}
                        orderBy={sortBy}
                        search={handleSearch}
                        ordination={ordination}
                        clearFilter={clearFilter}
                        valueInput={keyword}
                        extraButton
                        extraButtonLabel={t(langs.SETTINGS)}
                        extraButtonClick={handleGamificationSettings}
                        extraButtonIcon={gamificationSettings}
                    />
                </Header>
                <div className="containerTable" data-tour="tour__container">
                    {!loading
                        && !ranking?.length > 0
                        && debouncedKeyword === '' && (
                            <NoContent
                                title={t(langs.PARTICIPANT_NOT_FOUND)}
                                text={t(langs.PARTICIPANT_WITHOUT_GAMIFICATION)}
                            />
                    )}
                    {!loading
                        && !ranking?.length > 0
                        && debouncedKeyword !== '' && (
                            <NoContent
                                type="NOT_FOUND"
                                title={t(langs.PARTICIPANT_NOT_FOUND)}
                                text={t(langs.PARTICIPANT_NOT_FOUND_MESSAGE)}
                            />
                    )}
                    {loading && <LoadingSkeleton />}
                    {!loading && ranking?.length > 0 && (
                        <TableUsers>
                            <Table>
                                <TableHead>
                                    <tr>
                                        <th align="left">{t(langs.POSITION)}</th>
                                        <th align="left">{t(langs.NAME)}</th>
                                        <th align="left">Email</th>
                                        <th align="left">{t(langs.TOTAL_OF_POINTS)}</th>
                                    </tr>
                                </TableHead>
                                <TableBody>
                                    {ranking.map((user, index) => (
                                        <tr key={index}>
                                            <td align="left">
                                                {user.rank}
                                                {i18n.language === 'pt-BR' && 'º'}
                                            </td>
                                            <td align="left">{user.name}</td>
                                            <td align="left">{user.username}</td>
                                            <td align="left">{user.points}</td>
                                        </tr>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableUsers>
                    )}
                    {!loading && ranking?.length > 0 && (
                        <TableFooterResults>
                            <div className="table-footer">
                                <LoadMoreButtonSection>
                                    {nextPageLoading ? (
                                        <CircularProgress
                                            style={{ color: '#ff9162' }}
                                        />
                                    ) : (
                                        !isLastPage && (
                                            <button
                                                type="button"
                                                onClick={handleNextPage}
                                            >
                                                {t(langs.LOAD_MORE_PAGINATION)}
                                            </button>
                                        )
                                    )}
                                </LoadMoreButtonSection>

                                <span>
                                    {ranking?.length || 0} {t(langs.RESULTS)}
                                </span>
                            </div>
                        </TableFooterResults>
                    )}
                </div>
            </Main>
            <SettingsModal
                isOpen={settingsModalVisible}
                handleModal={() => setSettingsModalVisible(false)}
                channelId={selectedChannelId}
            />
        </>

    );
}
