import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_pt from './translations/ptBR';
import common_en from './translations/en';
import common_es from './translations/es';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
    'pt-BR': { translation: common_pt },
    'en-US': { translation: common_en },
    'es-ES': { translation: common_es },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'pt-BR',
        fallbackLng: 'pt-BR',
        fixLng: true,
        load: 'current',
        keySeparator: false,

        interpolation: { escapeValue: false },
    });

export default i18n;
