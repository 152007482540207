import { Accordion } from '@src/components/Accordion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Notify from '@utils/notification';
import api from '@services/api';
import { numberToLetter } from '@utils/functions';
import langs from '@langs/constants';
import useQuery from '@src/hooks/useQuery';
import {
    Container,
    QuestionSection,
    Title,
    Question,
    AnswersSection,
    ResultCardContainer,
    Left,
    Right,
    ResultCardContent,
    Footer,
} from './styles';

const LoadingSkeleton = () => (
    <SkeletonTheme color="#24243e" highlightColor="#1b1b32">
        <div style={{ height: 450, width: 378 }}>
            <Skeleton height={80} count={5} style={{ margin: '5px 0', zIndex: 50 }} />
        </div>
    </SkeletonTheme>
);

function ResultCard({ question, correctAnswer }) {
    const { t } = useTranslation();
    return (
        <ResultCardContainer>
            <ResultCardContent>
                {question.answers.map((item, index) => (
                    <div className="content-item" key={index}>
                        <Left className={`${item.answer === correctAnswer && 'correct'}`}>
                            <h4>{numberToLetter(index)}</h4>
                            <span>{t(langs.ANSWER)}</span>
                        </Left>

                        <div>
                            <h5>{item.answer}</h5>
                            <h6 className={`${correctAnswer === null ? undefined : item.answer !== correctAnswer ? 'incorrect' : undefined}`}>
                                {(item?.ratio * 100).toFixed(2)}% {t(langs.SELECTED)} - {item?.total_users} {t(langs.USERS)}
                            </h6>
                        </div>
                    </div>
                ))}
            </ResultCardContent>
        </ResultCardContainer>
    );
}

function ResultCardSimpleQuestion({ answer, correctAnswer, index }) {
    const { t } = useTranslation();

    return (
        <ResultCardContainer>
            <Left className={`${correctAnswer === answer?.answer ? 'correct' : undefined}`}>
                <h4>{numberToLetter(index)}</h4>
                <span>{t('ANSWER')}</span>
            </Left>
            <Right>
                <h5>{answer?.answer}</h5>
                <h6 className={`${correctAnswer !== answer.answer && 'incorrect'}`}>
                    {(answer?.ratio * 100).toFixed(2)}% {langs.SELECTED} - {answer?.total_users} {langs.USERS}
                </h6>
            </Right>
        </ResultCardContainer>
    );
}

export default function QuizDetailContent({ handleClose }) {
    const [questionReport, setQuestionReport] = useState({});
    const query = useQuery();
    const questionId = query.get('question');
    const groups = query.get('groups');
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (questionId) {
            api.getQuestionReport(questionId)
                .then(response => {
                    setLoading(false);
                    setQuestionReport(response);
                })
                .catch((error) => {
                    setLoading(false);
                    Notify.error(error?.response?.data?.error_description || t(langs.ERROR_LOAD_DATA));
                    handleClose();
                });
        }
    }, [handleClose, t, questionId]);

    return (
        <Container>
            {loading
                ? (
                    <LoadingSkeleton />
                )
                : (
                    <>
                        <QuestionSection>
                            <Title>{questionReport?.title}</Title>
                        </QuestionSection>
                        <AnswersSection>
                            { groups
                                ? questionReport?.questions?.map((question, index) => (
                                    <div className="accordion-container" key={index}>
                                        <Accordion heading={question?.question}>
                                            <Question>
                                                <small>{t(langs.QUESTION)}: </small>
                                                {question?.question}
                                            </Question>
                                            <ResultCard
                                                correctAnswer={question?.correctAnswer}
                                                key={index}
                                                question={question}
                                            />

                                        </Accordion>

                                    </div>
                                ))
                                : (
                                    questionReport?.answers?.map((answer, index) => (
                                        <ResultCardSimpleQuestion correctAnswer={questionReport?.correctAnswer} key={index} answer={answer} index={index} />
                                    ))
                                )}
                        </AnswersSection>
                        <Footer>
                            <h4>{t(langs.ALL_ANSWERS)}:
                                <span>{questionReport?.totalAnswers}</span>
                            </h4>
                        </Footer>
                    </>
                )}
        </Container>
    );
}
