import Modal from '@src/components/Modal';
import SelectInputField from '@src/components/SelectInputField';
import React, { useEffect, useState } from 'react';
import ButtonDefaultTheme from '@src/components/ButtonDefaultTheme';
import Notify from '@src/utils/notification';
import useCaptions from '@src/hooks/useCaptions';
import api from '@src/services/api';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    editCaption,
    getCaptions,
    setCaptions,
    toggleAddCaptionUploadModal,
} from '@src/store/modules/captions/actions';
import languagesConstants from '@langs/constants';
import { defaultErrorHandler } from '@src/store/utilities';
import { useParams } from 'react-router-dom';
import { Container } from './styles';
import { captionLanguages } from './possibleLanguages';
import UploadCaption from './UploadCaption';

export default function AddCaptionModal() {
    const [selectedCaptionLanguage, setSelectedCaptionLanguage] = useState('');
    const [uploadCaptionFiles, setUploadCaptionFiles] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const dispatch = useDispatch();
    const { id: roomId } = useParams();
    const { captionList, showUploadModal, editCaptionObject } = useCaptions();
    const editCaptionLanguage = editCaptionObject?.language;
    const editCaptionId = editCaptionObject?.id;
    const { t } = useTranslation();
    const normalizeCaptionLanguages = captionLanguages.map(
        language => ({ label: t(language.toUpperCase()), value: language }),
    );

    function convertString(txt) {
        const text = txt.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return text;
    }

    normalizeCaptionLanguages.sort((x, y) => {
        const a = convertString(x.label);
        const b = convertString(y.label);
        return a === b ? 0 : a > b ? 1 : -1;
    });

    const disableSaveButton = !selectedCaptionLanguage || !uploadCaptionFiles || isUploading || isUploading;

    function handleCloseUploadCaption() {
        dispatch(editCaption({}));
        setSelectedCaptionLanguage('');
        dispatch(toggleAddCaptionUploadModal(false));
    }

    async function handleUploadCaptionFile() {
        if (uploadCaptionFiles) {
            setIsUploading(true);
            try {
                const updatedCaption = await api.uploadCaption(roomId, uploadCaptionFiles[0], selectedCaptionLanguage);
                dispatch(setCaptions([...captionList, updatedCaption]));
                dispatch(getCaptions(roomId));
                setIsUploading(false);
                setSelectedCaptionLanguage('');
                Notify.success(t(languagesConstants.CAPTION_UPLOAD_SUCCESSFULLY));
                if (handleCloseUploadCaption) {
                    handleCloseUploadCaption();
                }
            } catch (error) {
                setIsUploading(false);
                if (error.response.status === 404) {
                    defaultErrorHandler(error, { default: t(languagesConstants.VIDEO_UNAVAILABLE) });
                } else if (error.response.status === 409) {
                    defaultErrorHandler(error, { default: t(languagesConstants.CAPTION_LANGUAGE_EXISTS) });
                } else {
                    defaultErrorHandler(error, { default: error?.response?.data?.error_description });
                }
            }
        }
    }

    async function handleUpdateCaption() {
        if (uploadCaptionFiles) {
            setIsUploading(true);
            try {
                await api.updateCaption(
                    roomId,
                    editCaptionId,
                    uploadCaptionFiles[0],
                );
                dispatch(setCaptions(captionList));
                dispatch(getCaptions(roomId));
                setIsUploading(false);
                setSelectedCaptionLanguage('');
                Notify.success(t(languagesConstants.CAPTION_UPDATE_SUCCESSFULLY));
                if (handleCloseUploadCaption) {
                    handleCloseUploadCaption();
                }
            } catch (error) {
                setIsUploading(false);
                defaultErrorHandler(error,
                    {
                        default: error?.response?.data?.error_description
                        || t(languagesConstants.CAPTION_UPDATE_FAILED),
                    });
            }
        }
    }

    function onSubmitCaption() {
        if (editCaptionId) {
            handleUpdateCaption();
        } else {
            handleUploadCaptionFile();
        }
    }

    useEffect(() => {
        if (editCaptionLanguage) {
            setSelectedCaptionLanguage(editCaptionLanguage);
        }
    }, [dispatch, editCaptionLanguage]);

    return (
        <Modal
            handleModal={isUploading ? null : handleCloseUploadCaption}
            isOpen={showUploadModal}
            width={585}
            title={editCaptionLanguage ? t(languagesConstants.EDIT) : t(languagesConstants.ADD)}
            featuredTitle={t(languagesConstants.CAPTION)}
        >
            <Container>
                <SelectInputField
                    disabled={editCaptionLanguage}
                    labelTitle={t(languagesConstants.SELECT_CAPTION_LANGUAGE)}
                    options={normalizeCaptionLanguages}
                    color="#24243e"
                    placeHolder={editCaptionLanguage || t(languagesConstants.SELECT_CAPTION_LANGUAGE)}
                    placeHolderColor="#3d3d6c"
                    onChange={setSelectedCaptionLanguage}
                />
                <UploadCaption
                    isLoading={isUploading}
                    selectedCaptionLanguage={selectedCaptionLanguage}
                    setUploadCaptionFiles={setUploadCaptionFiles}
                    uploadCaptionFiles={uploadCaptionFiles}
                />
                <ButtonDefaultTheme disabled={disableSaveButton} onClick={onSubmitCaption}>
                    {isUploading ? t(languagesConstants.UPLOADING) : t(languagesConstants.SAVE_CAPTION)}
                </ButtonDefaultTheme>
            </Container>
        </Modal>
    );
}
