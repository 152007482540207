import styled from 'styled-components';

export const ContainerDetails = styled.div`
    display:flex;
    padding:24px;
    flex-flow: column nowrap;
    background: #282b3a;
    overflow: auto;

    .loadDataErrorMessage {
        margin: 0 auto;
        color: #FFFFFF;
    }

    .header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: white;
        font-size: 16px;
        
        button {
            background: transparent;
            border: 0;
            font-weight: bolder;
            font-size: 16px;
            color: white;
        }

        p {
            line-height: 2;
            font-weight: 400;
        }
    }

    section  {
        /* background-color: #282b3a; */
        div.active-users, div.user-engagement, div.buffer, div.transmission {
            background-color: #212436;
            border-radius: 6px;
        }

        div.log {
            background-color: #282b3a;

            width: 100%;
            padding: 0 10px;
            .header {
                background-color: #212436;
                border-radius: 6px;
            }
        }
        div.preview  {
            background-color: #282b3a;
        }

        div.header  {
            background-color: #212436;
        }

        div.geo-content {
            background-color: #282b3a;
        }

        .geo-content thead {
            background-color: #212436;
        }

        div.Dropdown-control, .filter-graph p {
            background-color: #282b3a;
        }

        .button-filter .dropdown-button {
            background-color: #282b3a;
        }
    }

    button.goLiveButton {
        background-image: linear-gradient(90deg, #FE5F7A 0%, #F28F54 100%);
        text-transform: uppercase;
        border: none;
        border-radius: 6px;
    }

    div::-webkit-scrollbar {
        width: 4px;       
    }
    div::-webkit-scrollbar-track {
        background: transparent;        /* color of the tracking area */
    }
    div::-webkit-scrollbar-thumb {
        background-color: #616161;    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        border: 3px solid #5d5d5d;  /* creates padding around scroll thumb */
    }

    .filter, .Dropdown-control, .Dropdown-menu, .Dropdown-option{
        text-align: center;
        background-color: #302d2d;
        color: white;
        border: 0px solid black;
    }

    .title {
        font-size: 24px;
        font-weight: 800;
        margin: auto;
    }

    .geolocation {
        margin: 4%;
        position: relative;

        .header {
            background-color: #403e3f;
            justify-content: center;
            min-height: 55px;
            flex-wrap: wrap;
            h4 {
                line-height: 3;
                margin: 0 5px;
            }

            p {
                line-height: 3;
            }
        }

        .geo-content {
            background-color: #474343;
            width: 100%;

            svg {
                margin-top: 10px;
                width: 100%;
                height: 500px;
            }

            thead {
                border: 0;
                background-color: #403e3f;
                margin: 10px;
                color: white;
            }

            th {
                height: 38px;
                
            }
        }

        .button-filter {
            margin: auto;
            position: absolute;
            right: 0;
            top: 10px;

            .dropdown-button {
                color: white;
                margin-right: 10px;
                background-color: #302d2d;
                height: 38px;

                span {
                    font-size: 14px;
                }
            }

            p {
                width: inherit;
                text-align: center;
                margin: 0 15px;
                font-weight: 400;
                font-size: 16px; 
                line-height: 2.5 !important;
            }

            img {
                margin: auto;
                padding-right: 5px;
            }

            .selected {
                span {
                    color: #ff8072;
                }
            }
        }
    }
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 3%;
    align-items: center;

    h4 {
        font-size: 24px;
        font-weight: 800;
    }

    
`;

export const HeaderButtonsContainer = styled.div`
    display: flex;
`;

export const LineTable = styled.tr`
    background-color: ${props => (props.index % 2 === 0 ? '#4d4b4c' : '#393838')};
    color: white;
    font-weight: 600;
    height: 38px;
`;
