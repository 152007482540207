import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  padding: 30px 0;
  width: 100%;
  max-height: inherit;

  h4 {
      color: #fff;
      margin-bottom: 3px;
      font-size: 10px;
  }

  form {
    display: contents;
  }

  span{
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.16px;
    color: #313030;
  }

  .title {
    text-align: center;
    margin-bottom: 40px;

    span {
      font-size: 21px;
      font-weight: 700;
      color: #ff8271;
    }
  }
  .content-left,
  .content-right {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .content-left {
    flex-basis: 40%;

    .video-player {
      padding: 0;
    }
  }

  .content-right {
    display: block;
    flex-basis: 100%;
  }

  .content{
    display: flex;
    flex-direction: row;

    /* margin-left: 3%; */
  }

  .Dropdown-disabled {
    color: darkgray;
  }

  .thumb {
    width: 15rem;

    .upload {
        width: 100%;
        margin: auto;
    }

    h4 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    
    .dropzone {
      height: 190px;
      margin-top: 10px;
    }

    span {
        font-size: 10px;
        font-weight: bold;
    }

    .--cover-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: #24243e;
        margin-top: -6px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        flex-direction: column;
    }

    p {
      height: 100%;
      border-radius: 6px;
      background-color: #1f1f3a;          
    }

    span {        
        margin: 0;        
        font-size: 10px;
        font-weight: bold;
        color: #ffffff;

            &.thumb-span{                        
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;        
                color: #ff8e63;
            }
     }
  }

  .thumb-live {
    width: 100%;

    .upload {
        width: 100%;
        margin: auto;
    }

    h4 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    
    .dropzone {
      height: 190px;
      margin-top: 10px;
    }

    span {
        font-size: 10px;
        font-weight: bold;
    }

    .--cover-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: #24243e;
        margin-top: -6px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        flex-direction: column;
    }

    p {
      height: 100%;
      border-radius: 6px;
      background-color: #1f1f3a;          
    }

    span {        
        margin: 0;        
        font-size: 10px;
        font-weight: bold;
        color: #ffffff;

            &.thumb-span{                        
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;        
                color: #ff8e63;
            }
     }
  }

  .video-detail {
    width: calc(100% - 266px);
    display: flex;
    flex-direction: column;

    .autocomplete{
      div {
        height: 40px;
      }

      input {
        height: 25px;
        padding: 0;
        width: 100%;
        border: 0;
      }

      button {
        display: initial;
      }
    }

    .input-title {
      margin: 10px 0 10px 0;
      padding: 0 0 0 10px;
    }

    .datepick {
      flex-wrap: wrap;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 12px;
    }

    .timezone {
      li {
        background: rgba(255, 255, 255, 0.9)
      }
    }

    .MuiSelect-root {
        /* line-height: 0.5; */
    }

    .MuiInputBase-root {
      display: grid;
      padding-right: 50px;
    }

    .MuiFormControl-root.dates { 
      z-index: unset !important;
    }
  }

    
  .video-description {
    min-height: 10%;
    display:flex;
    flex-direction: column;

    textarea {
        padding: 5px;
        width: 100%;
        resize: vertical;
        height: 80px;
        margin-top: 5px;
        border-radius: 6px;
        background-color: #24243e;
        border: 0;
        color: #fff;
        font-weight: bold;

        &::placeholder {
            color: #3d3d6c;
        }
    }
  }



  .attachments {
    display:flex;
    flex-direction: column;

    h4 {
      margin: 20px 0 10px 0;
    }
    
    .dropzone {
      flex-grow: 1;      
      height: 96px;
    }

    span {
      font-size: 10px;
      font-weight: bold;
    }

    p {
      height: 100%;
      border-radius: 6px;
      background-color: #24243e;
    }

    .thumb-span {
      color: #989797;
      margin: 0;

      span {
        color: #ff6487;
      }
    }
  }

  .upload-attachments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    max-height: 108px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background-color: rgba(156, 156, 156, .6);
      border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: rgba(57,57,57, .6);
    }
  }

  section.chat {
    border-radius: 5px;
    background-color: #24243e;
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    .--toggle-wrapper {
        display: flex;
        align-items: center;
        height: 33px;
        
        input {
            display:none;
        }
    }

    .--toggle-info-wrapper {
        display: flex;
        align-items: center;
        height: 17px;
        h3 {
            color: #fff;
        }

        p {
            color: #ff6363;
            text-transform: uppercase;
            font-weight: bold;
            font-style: italic;
            margin-left: 5px;
            &.--active {
                color: #31a6a1;
            }
        }
    }
    
  }

  .submitButton{
    margin: 30px auto;
    display:flex;
    justify-content:center;
    width: 387px;
    height: 40px;
    border: 0;
    color:#fff;
    background: linear-gradient(90deg, rgba(255,66, 141,1) 0%, rgba(255,174,70,1) 100%);
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: 0.21px;
    border-radius: 8px;
    text-transform: uppercase;    

    &:disabled{
        opacity: 0.2;
    }
  }


  .goLiveButton {
    margin: 7px auto;
    display:flex;
    justify-content:center;
    width: 191px;
    height: 40px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.21px;
    line-height: 3;
    border: solid 1px;

    &:enabled {
      border-color: #ff468a;
      color:#fff;
      color: #ff448b;
      transition: all .2s ease-in-out;
    }
    
    &:hover {
      transform: scale(1.1);
    }  
  }

  .modal-confirm {
    text-align: center;
    p {
        font-size: clamp(13px, 0.2em + 1vw, 1.2em);
        color:#fff;
        font-weight: bold;
    }
    .confirm-buttons {      
        display: flex;
        flex-direction: column;
        margin: 20px 5px;

     button {       
        display:flex;
        justify-content:center;        
        height: 40px;
        border: 0;
        flex: 1;
        color:#fff;
        background: linear-gradient(90deg, rgba(255,66, 141,1) 0%, rgba(255,174,70,1) 100%);
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 3;
        letter-spacing: 0.21px;
        border-radius: 8px;
        text-transform: uppercase;
        transition: all 0.2s ease-in-out;

        &.cancel {
            background: transparent;
            width: 387px;                        
            border: solid 1px #ff5b7d;
            margin-top: 15px;
        }

        &:hover {
            box-shadow: 0px 0px 10px -4px #000000;
            transform: scale(1.03);
        }            
    }
  }

  @media(max-width:800px) {
    
    .attachments {
      margin: 0;
    }

    .video-description {
      margin: 3% 0;
    }
  }
  }
`;

export const VideoPreviewSection = styled.section`
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 90%;
    height: 300px;
    min-height: 300px; 
`;

export const VideoControlsSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 30%;

`;

export const LiveVideoInfo = styled.section`
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    .video-control-sec {
        display: none;
    }
`;

export const NewLiveMediaSection = styled.section`
    display: flex;
    flex-direction: column;
`;

export const Form = styled.form`
  display:flex;

  .--content {
      display: flex;
      width: 55%;
      justify-content: flex-end;
      margin-top: 2rem;

  }

  .content-recorded {
    display: flex;
    justify-content: center;

  }  

  input:not([type=time]) {
    width: 100%;
    height: 35px;
    border-radius: 6px;
    background-color: #24243e;
    border: 0;
    color: #fff;
    font-weight: bold;
    padding: 0 5px;

    &::placeholder {
        color: #3d3d6c;
    }
  }

  .field-header {
        display: flex;
        align-items: baseline;
        position: relative;
        z-index: 1;
        small {
            font-size: 8px;
            font-weight: 800;
            letter-spacing: 0.19px;
            color: #ff9869;
            text-transform: uppercase;
            margin-left: 5px;

            &.to-right {
                position: absolute;
                right: 2px;
                bottom: 5px;
            }

            &.limit {
                color: #b10029;
            }
        }
  }

  .MuiFormControl-root {
      z-index: 5;
  }

  section {
    display:flex;
    width: 100%;

    .--thumb-section {
        margin: 10px 40px;
    }
    
  }

  .--form-section {
      width: 90%;

      .dates {
          display: flex;
          width: 100%;
      }

    .--input-item {
        margin: 10px 0;
    }

    .Dropdown-control {
        background-color: #24243e;
        border: none;
        border-radius: 6px;
        box-sizing: border-box;
        color: #fff;
        font-weight: bold;        
    }

    .Dropdown-menu {
        background-color: #24243e;  
        border: none;          
    }

    .Dropdown-option {
        color: #fff;
        font-weight: bold;  
        &:hover {
            background-color: #34344c;
        }
    }

    .Dropdown-option.is-selected {
        background-color: #34344c;
        color: #fff;
    }
   
  }
  
  
`;

export const DateForm = styled.div`
  width: 100%;
  display:flex;  
  justify-content: space-between;
  margin-bottom: 10px;

`;

export const DateContainer = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  .start, .finish{
      width: 98%;
    }

  @media(max-width: 800px) {
    flex-direction: row;
    width: 90%;

    .start, .finish{
      width: 100%;
    }    
  }
`;

export const ModalContent = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  max-height: 85vh;
  overflow-y: auto;
  width: 100%;
`;

export const VideoPlayer = styled.div`
  display: inline-block;
  justify-content:center;
  align-items:center;
  width: 100%;
  height: 40%;

  @media(min-width: 650px) {
    height: 50%;
    margin-bottom: 10px;
    padding: 0 20px 0 20px;
    
    main {
      height: 250px !important;
    }
    
  }

  @media(max-width: 650px) and (orientation: landscape) {
    height: 100%;
  }
`;
