import { all, put, select, takeLatest } from 'redux-saga/effects';
import api from '../../../services/api';
import { defaultErrorHandler } from '../../utilities';
import * as blockListActions from './actions';
import actions from '../../../utils/actions';
import { translate } from '../../../utils/functions';
import Notify from '../../../utils/notification';

function* getBlockedUsers({ payload: { roomId, keyword, pageSize, LastEvaluatedKey } }) {
    try {
        const blockedUsers = yield api.getBlockedUsers(roomId, keyword, pageSize, LastEvaluatedKey);
        let isLastPage = false;
        if (blockedUsers.users?.length < pageSize) {
            isLastPage = true;
        }
        yield put(blockListActions.getBlockedUsersSuccess(blockedUsers, isLastPage));
    } catch (error) {
        yield put(blockListActions.getBlockedUsersFailed());
        defaultErrorHandler(error, { default: translate('BLOCK_LIST_FAILURE') });
    }
}

function* getBlockedUsersNextPage({ payload: { roomId, pageSize } }) {
    try {
        const blockedUsers = yield api.getBlockedUsers(roomId, null, pageSize);

        let isLastPage = false;
        if (blockedUsers.users?.length < pageSize) {
            isLastPage = true;
        }

        yield put(blockListActions.getBlockedUsersPaginatedSuccess(blockedUsers.users, isLastPage));
    } catch (error) {
        yield put(blockListActions.getBlockedUsersFailed());
        defaultErrorHandler(error, { default: translate('BLOCK_LIST_FAILURE') });
    }
}

function* unblockUser({ payload: { roomId, status } }) {
    try {
        yield api.unblockUser(roomId, status);
        const { users, LastEvaluatedKey } = yield select(state => state.blockList);
        yield put(blockListActions.getBlockedUsersSuccess({ users: users.filter(user => user.userId !== status.userId), LastEvaluatedKey }));
        Notify.success(translate('UNBLOCK_USER_SUCCESS'));
    } catch (error) {
        defaultErrorHandler(error, { default: translate('UNBLOCK_USER_FAILURE') });
    }
}

export default all([
    takeLatest(actions.BLOCK_LIST_REQUEST, getBlockedUsers),
    takeLatest(actions.UNBLOCK_USER, unblockUser),
    takeLatest(actions.BLOCK_LIST_NEXT_PAGE_REQUEST, getBlockedUsersNextPage),
]);
