import React, { useEffect } from 'react';

import { AnimatePresence } from 'framer-motion';
import { Switch, Route as DefaultRoute } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUserProfile } from '../store/modules/auth/actions';

import Route from './Route';
import paths from '../utils/paths';
import ForcedLogout from '../pages/ForcedLogout';
import Teams from '../pages/Teams';
import Offers from '../pages/GuestPresenterView/Offers';
import OffersDetails from '../pages/GuestPresenterView/Offers/details';
import Error404 from '../pages/404/index';
import Channels from '../pages/Channels';
import ChannelsDetails from '../pages/Channels/Details';
import Users from '../pages/Users';
import PresenceReport from '../pages/PresenceReport';
import PresenceReportExport from '../pages/PresenceReport/ExportReport';
import Quizzes from '../pages/Quizzes';
import QuizzesDetail from '../pages/Quizzes/QuizzesDetail';
import QuizzesControl from '../pages/Quizzes/QuizzesControl';
import SurveysControl from '@src/pages/Quizzes/SurveysControl';
import RankingGamification from '../pages/rankingGamification';
import BlockList from '../pages/BlockList';
import BlockListDetail from '../pages/BlockList/BlockListDetail';
import ImportCSV from '../pages/Users/ImportCSV';
import VideoWall from '../pages/VideoWall';
import GuestPresenter from '../pages/GuestPresenter';
import GuestPresenterView from '../pages/GuestPresenterView';
import AnimatedPage from './AnimatedPage';
import CourseRecorded from '@src/pages/CourseRecorded';
import CourseLive from '@src/pages/CourseLive';
import TutorialOrganizer from '@src/pages/TutorialOrganizer';
import PlayerTutorials from '@src/pages/PlayerTutorials';
import NetworkFailed from '@src/pages/NetworkFailed';
import VideoStats from '@src/pages/videoStats'

export default function Routes() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserProfile());
    }, [dispatch]);

    return (
        <AnimatePresence exitBeforeEnter presenceAffectsLayout>
            <Switch>
                <Route path={paths.HOME} component={() => <AnimatedPage component={Channels} />} exact />
                <Route path={`${paths.CHANNELS}/:id/details`} component={ChannelsDetails} />
                <Route path={paths.CHANNELS} component={() => <AnimatedPage component={Channels} />} />
                <Route path={`${paths.TEAMS}/id`} component={Teams} />
                <Route path={paths.OFFERS} exact component={() => <AnimatedPage component={Offers} />} />
                <Route path={`${paths.OFFERS}/:id`} exact component={() => <AnimatedPage component={OffersDetails} />} />
                <Route path={paths.USERS} component={() => <AnimatedPage component={Users} />} exact />
                <Route path={paths.IMPORT_CSV} component={() => <AnimatedPage component={ImportCSV} />} />
                <Route path={paths.RANKING} component={() => <AnimatedPage component={RankingGamification} />} />
                <Route path={paths.PRESENCE_REPORT} exact component={() => <AnimatedPage component={PresenceReport} />} />
                <Route path={paths.RANKING} component={() => <AnimatedPage component={RankingGamification} />} />
                <Route path={`${paths.PRESENCE_REPORT}/:id`} exact component={() => <AnimatedPage component={PresenceReportExport} />} />
                <Route path={paths.QUIZZES} exact component={() => <AnimatedPage component={Quizzes} />} />
                <Route path={`${paths.QUIZZES}/:id`} exact component={() => <AnimatedPage component={QuizzesDetail} />} />
                <Route path={`${paths.QUIZZES}/:id/quizzControl`} exact component={() => <AnimatedPage component={QuizzesControl} />} />
                <Route path={`${paths.QUIZZES}/:id/surveysControl`} exact component={() => <AnimatedPage component={SurveysControl} />} />
                <Route path={paths.BLOCK_LIST} exact component={() => <AnimatedPage component={BlockList} />} />
                <Route path={`${paths.BLOCK_LIST}/:id`} exact component={() => <AnimatedPage component={BlockListDetail} />} />
                <DefaultRoute path={paths.FORCE_LOGOUT} component={ForcedLogout} />
                <Route path={`${paths.VIDEO_WALL}/:id`} component={() => <AnimatedPage component={VideoWall} />} />
                <Route path={`${paths.VIDEO_STATS}/:id`} component={() => <AnimatedPage component={VideoStats} />} />
                <Route path={`${paths.GUEST_PRESENTER}/:id`} component={() => <AnimatedPage component={GuestPresenter} />} />
                <Route path={`${paths.GUEST_PRESENTER_VIEW}/:roomId/:guestId`} component={() => <AnimatedPage component={GuestPresenterView} />} />
                <Route path={`${paths.COURSE_RECORDED}/:id?`} component={() => <AnimatedPage component={CourseRecorded} />} />
                <Route path={`${paths.COURSE_LIVE}/:id?`} component={() => <AnimatedPage component={CourseLive} />} />
                <Route path={paths.TUTORIAL} component={TutorialOrganizer}/>
                <Route path={paths.PLAYER_TUTORIAL} component={PlayerTutorials}  />
                <DefaultRoute path={paths.NETWORK_FAILED} component={NetworkFailed} />
                <Route notFound component={() => <AnimatedPage component={Error404} />} />
            </Switch>
        </AnimatePresence>
    );
}
