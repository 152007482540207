import React from 'react';
import Lottie from 'react-lottie';
import animationData from './animation-hourglass.json';

import { RoomSettingUpWarningContainer } from './styles';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
};

const RoomSettingUpWarning = ({ title, isSettingUp }) => (

    <RoomSettingUpWarningContainer isSettingUp={isSettingUp}>
        <div className="warning-room-creating">
            <Lottie
                width={60}
                height={60}
                options={defaultOptions}
            />
            <span>{title}</span>
        </div>
    </RoomSettingUpWarningContainer>
);

export default RoomSettingUpWarning;
