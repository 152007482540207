import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useRooms from '../../../hooks/useRooms';
import { RoomCard } from './styles';
import { fetchEditRoom } from '../../../store/modules/rooms/actions';
import { ROOM_STATUS, LIVE_STATUS } from '../../../utils/constants';
import VideoCard from '../../../components/VideoCard';
import { isDateExpired } from '../../../utils/functions';

const RoomsList = ({ rooms = [] }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [listRooms, setListRooms] = useState([]);
    const { t } = useTranslation();
    const { selectedChannelRooms } = useRooms();

    useEffect(() => {
        const sortedRooms = rooms.sort((a, b) => moment(b.startDate).toDate() - moment(a.startDate).toDate());

        const dataRoom = sortedRooms.sort((a) => {
            if (a.liveStatus === LIVE_STATUS.on) {
                return -1;
            }
            return 1;
        });

        setListRooms(dataRoom);
    }, [rooms]);

    function isUploading(room) {
        if (
            (room.recorded && room?.status === ROOM_STATUS.UPLOADING)
            || (room.recorded && room?.status === ROOM_STATUS.CONVERTING)
            || room?.status === ROOM_STATUS.PREPARING
        ) {
            return true;
        }
        return false;
    }

    const handleClick = async (e, roomId, room, isRecorded) => {
        e.preventDefault();
        e.stopPropagation();

        if (isUploading(room)) {
            return;
        }

        dispatch(fetchEditRoom(room?.id, room));

        if (isRecorded) {
            history.push(`/courseRecorded/${roomId}`);
        } else {
            history.push(`/myChannels/${roomId}/details`);
        }
    };

    const handleEdit = (e, room) => {
        e.preventDefault();
        e.stopPropagation();

        if (isUploading(room)) {
            return;
        }
        dispatch(fetchEditRoom(room?.id, room));

        if (room?.recorded) {
            history.push(`/courseRecorded/${room.id}`);
        } else {
            history.push(`/courseLive/${room.id}`);
        }
    };

    useEffect(() => {
        if (history?.location?.state?.fromSearch) {
            const { roomId } = history.location?.state;
            const filteredRoom = selectedChannelRooms.filter(room => room.id === roomId);
            setListRooms(filteredRoom);
        }
    }, [history, selectedChannelRooms]);

    function getCardButtonText(endDate = null) {
        if (isDateExpired(endDate)) {
            return t('VIEW_CONTENT');
        }

        return t('EDIT_CONTENT');
    }

    return (
        <>
            <RoomCard data-testid="room-card">
                {listRooms.map((room) => (
                    <VideoCard
                        key={room?.id}
                        room={room}
                        upload_progress={room?.upload_progress}
                        isUploading={isUploading(room)}
                        onClick={e => handleClick(e, room?.id, room, room?.recorded)}
                        button={{ text: getCardButtonText(room?.endDate), action: e => handleEdit(e, room) }}
                    />
                ))}
            </RoomCard>
        </>
    );
};

export default React.memo(RoomsList);
