/* eslint-disable no-unused-expressions */
import React, { createRef } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import UploadIcon from '@src/assets/add-image-icon.svg';
import UploadMultipleIcon from '@src/assets/cloud-icon.svg';
import {
    DropContainer,
    UploadMessage,
} from './styles';

const dropzoneRef = createRef();

export const openDialog = () => {
    if (dropzoneRef.current) {
        dropzoneRef.current.open();
    }
};

export function Upload({
    onUpload,
    multiple = false,
    accept = 'image/*',
    message = '',
    getIsFileRejected = null,
    handleCancelDialog,
    customDropIcon = null,
}) {
    const { t } = useTranslation();

    const renderDragMessage = (isDragActive, fileRejections) => {
        let illustrationIcon = UploadIcon;
        if (customDropIcon) {
            illustrationIcon = customDropIcon;
        } else if (multiple) {
            illustrationIcon = UploadMultipleIcon;
        }
        if (!isDragActive) {
            return (
                <UploadMessage>
                    <img
                        src={illustrationIcon}
                        alt="Upload"
                    />
                    {message && <small>{message}</small>}
                </UploadMessage>
            );
        }

        if (fileRejections.lenght > 0) {
            return (
                <UploadMessage type="error">
                    {t('UPLOAD_ERROR_MESSAGE')}
                </UploadMessage>
            );
        }

        return (
            <UploadMessage type="success">
                {multiple
                    ? t('UPLOAD_SUCCESS_MESSAGE_MULTIPLE')
                    : t('UPLOAD_SUCCESS_MESSAGE')}
            </UploadMessage>
        );
    };

    return (
        <>
            <Dropzone
                ref={dropzoneRef}
                multiple={multiple}
                accept={accept}
                onDropAccepted={onUpload}
                onFileDialogCancel={handleCancelDialog}
            >
                {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                    fileRejections,
                }) => (
                    <DropContainer
                        className="upload-content"
                        {...getRootProps()}
                        isDragActive={isDragActive}
                        isDragReject={isDragReject}
                    >
                        <input multiple={multiple} {...getInputProps()} />
                        {renderDragMessage(isDragActive, fileRejections)}
                        {getIsFileRejected && getIsFileRejected(fileRejections)}
                    </DropContainer>
                )}
            </Dropzone>
        </>
    );
}
