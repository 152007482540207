import { Tabs } from '@mantine/core';
import styled from 'styled-components';

export const Container = styled.div`
    margin: 20px 0;
    width: 100%;
    padding: 19px 28px;
    border-radius: 8px;
    display: flex;
    background-color: #272637;

    .--warn-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;

        h5 {
            font-size: 18px;
            color: #fff;
        }

        button {
            border: none;
            color: #FFF;
            background: linear-gradient(90deg,#FFAC47 0%, #FF438D 100%);
            width: 300px;
            height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-weight: 600;
            border-radius: 8px;  
            font-weight: bold;
            text-transform: uppercase; 
            opacity: 0.8;
            transition: all 0.1s ease-in-out;

            &:hover {
                box-shadow: 0px 0px 10px -4px #000000;
                opacity: 1;
                transform: scale(1.02);
            }
        }

    }
`;

export const VideoTabs = styled(Tabs)`
    &.mantine-Tabs-root {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-left: 20px;
        color: unset;
    }

    .mantine-Group-root{
        border-radius: 10px;
        overflow: hidden;
    }

    .mantine-Tabs-tabLabel {
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.0rem;
    }

    .mantine-Tabs-tabsListWrapper {
        border: none;
    }
    
    .mantine-Tabs-tabControl {
        flex: 1;
        background-color: #212032;
        height: 64px;
        border-bottom: 3px solid #313044;
    }

    .mantine-Tabs-tabActive {
        border-bottom-color: #ff8f63 ;
        .mantine-Tabs-tabLabel {
            color: #ff8f63;
        }
    }

    .mantine-Tabs-body {
        height: 100%;
        display: flex;
        flex: 1;
    } 
`;

export const CaptionsTableContainer = styled.div`
    background-color: #272637;
    width: 100%;
`;
