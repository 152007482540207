import React from 'react';
import { motion } from 'framer-motion';

const animations = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
};

const AnimatedPage = ({ children, component: Component, style }) => (
    <motion.div
        id="motion.div"
        variants={animations}
        initial="initial"
        animate="animate"
        exit="exit"
        style={{ ...style }}
        transition={{ duration: 0.3, type: 'spring' }}
    >
        {children || <Component /> }
    </motion.div>
);

export default AnimatedPage;
