import React, { useEffect, useState } from 'react';
import { Table, TableHead, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import languageConstants from '@langs/constants';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Container, TableContainer, TableFooterPagination } from './styles';
import { getImportList } from '../../../store/modules/users/actions';
import useUsers from '../../../hooks/useUsers';
import successIcon from '../../../assets/check-success-icon.svg';
import NoContent from '../../../components/NoContent';
import ArrowNext from '../../../assets/arrow.svg';

const LoadingSkeleton = () => (
    <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
        <div style={{ marginTop: 10 }}>
            <Skeleton height={50} count={5} style={{ margin: '5px 0' }} />
        </div>
    </SkeletonTheme>
);

export default function CSVDetails({ fileOnDetail, failedUsers, succeedUsers }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { detailList, detailIsLoading, detailLastKey } = useUsers();

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);

    useEffect(() => {
        dispatch(getImportList(fileOnDetail.id, rowsPerPage, null));
    }, [dispatch, fileOnDetail, rowsPerPage]);

    function handleChangeRowsPerPage(e) {
        setRowsPerPage(Number(e.target.value));
    }

    function previousPage() {
        if (pageIndex >= 0) {
            dispatch(getImportList(fileOnDetail.id, rowsPerPage, detailLastKey));
            setPageIndex(pageIndex - 1);
        }
    }

    function nextPage() {
        setPageIndex(pageIndex + 1);
        dispatch(getImportList(fileOnDetail.id, rowsPerPage, detailLastKey));
    }

    return (
        <Container>
            {
                (failedUsers === 0 && succeedUsers > 0) && (
                    <div className="success-wrapper">
                        <img src={successIcon} alt="Sucesso" />
                        <h2>{succeedUsers} {succeedUsers > 1 ? t('USERS_REGISTERED') : t('USER_REGISTERED')}</h2>
                    </div>
                )
            }

            {
                failedUsers > 0 && (
                    detailIsLoading
                        ? <LoadingSkeleton />
                        : (
                            <>
                                <div className="containerTable">
                                    <h2> <span>{failedUsers}</span>  {t('OF')} {succeedUsers} {t('USERS_NOT_REGISTERED')}</h2>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <tr>
                                                    <th align="left">{t('NAME')}</th>
                                                    <th align="left">{t('EMAIL')}</th>
                                                    <th align="left">{t('INCORRECT_COLUMNS')}</th>
                                                </tr>
                                            </TableHead>
                                            <TableBody>
                                                {detailList.map((user) => (
                                                    <tr key={user?.username}>
                                                        <td align="left">{user?.fullName}</td>
                                                        <td align="left">{user?.username}</td>
                                                        <td align="left">{user?.errors.join(', ')}</td>
                                                    </tr>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                <TableFooterPagination>
                                    <div className="table-footer">
                                        <div>
                                            <span>
                                                {t('USERS_PER_PAGE')}
                                            </span>
                                            <select
                                                value={rowsPerPage}
                                                onChange={e => handleChangeRowsPerPage(e)}
                                            >
                                                <option value={10}>
                                                    10
                                                </option>
                                                <option value={25}>
                                                    25
                                                </option>
                                                <option value={50}>
                                                    50
                                                </option>
                                                <option value={100}>
                                                    100
                                                </option>
                                            </select>
                                        </div>
                                        <div>
                                            <div className="pagination">
                                                <button type="button" disabled={pageIndex < 1} onClick={previousPage}>
                                                    <img alt="previous" className="iconsPrev" src={ArrowNext} />
                                                </button>
                                                <button type="button" disabled={detailLastKey === null || detailLastKey === 'null'} onClick={nextPage}>
                                                    <img alt="next" className="iconsNext" src={ArrowNext} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </TableFooterPagination>
                            </>
                        )
                )
            }

            {
                (failedUsers === 0 && succeedUsers === 0) && (
                    <NoContent title={t('OPS')} text={t(languageConstants.NO_USER_REGISTERED_CHECK_XLSX)} />
                )
            }

        </Container>
    );
}
