import { all, put, takeLatest } from 'redux-saga/effects';
import api from '../../../services/api';
import { defaultErrorHandler } from '../../utilities';
import * as reportActions from './actions';
import actions from '../../../utils/actions';
import { translate } from '../../../utils/functions';

function* getPresenceReport({ payload }) {
    try {
        const res = yield api.getPresenceReport(payload);
        let fromSearch = false;
        if (payload.keyword) fromSearch = true;
        yield put(reportActions.getPresenceReportSuccess(res, fromSearch));
    } catch (error) {
        yield put(reportActions.getPresenceReportFailed());
        defaultErrorHandler(error, { default: translate('GET_PRESENCE_REPORT_FALURE') });
    }
}

function* getNextPagePresenceReport({ payload }) {
    try {
        const res = yield api.getPresenceReport(payload);
        yield put(reportActions.getNextPagePresenceReportSuccess(res));
    } catch (error) {
        yield put(reportActions.getPresenceReportFailed());
        defaultErrorHandler(error, { default: translate('GET_PRESENCE_REPORT_FALURE') });
    }
}

export default all([
    takeLatest(actions.PRESENCE_REPORT_REQUEST, getPresenceReport),
    takeLatest(actions.PRESENCE_REPORT_NEXT_PAGE, getNextPagePresenceReport),

]);
