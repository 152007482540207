import actions from '../../../utils/actions';

const DEFAULT_STATE = {
    offers: [],
    isOfferEnabled: false,
    loading: true,
    qrcodeUrl: '',
};

export default function offersReducer(state = DEFAULT_STATE, { type, payload }) {
    switch (type) {
        case actions.GET_OFFERS_STATUS:
            return { ...state, loading: true };

        case actions.SET_OFFERS_STATUS:
            return { ...state, loading: false, isOfferEnabled: payload };

        case actions.GET_OFFERS:
            return { ...state, loading: true };

        case actions.CREATE_OFFER:
            return { ...state, loading: true };

        case actions.SET_OFFERS:
            return { ...state, loading: false, offers: payload, isOfferEnabled: payload.saleEnabled };

        case actions.GET_OFFER_URL:
            return { ...state, loading: true };

        case actions.SET_OFFER_URL:
            return { ...state, loading: false, qrcodeUrl: payload };

        case actions.FINISH_OFFER_REQUEST:
            return { ...state, loading: false };

        default:
            return state;
    }
}
