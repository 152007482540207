import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useDebounce } from 'use-debounce';
import OutsideClickHandler from 'react-outside-click-handler';
import { useHistory } from 'react-router-dom';
import { Menu } from '@mantine/core';
import OnboardingUsers from '@src/components/Onbordings/onboardingUsers';
import useAuth from '@src/hooks/useAuth';
import { defaultErrorHandler } from '@src/store/utilities';
import languageConstants from '@langs/constants';
import { b64toBlob } from '@src/utils/functions';
import {
    Header,
    TableUsers,
    Main,
    ButtonOptions,
    TableFooterResults,
    LoadMoreButtonSection,
    ItemOption,
} from './style';
import {
    editUser,
    getUsers,
    setLoaderState,
    getUsersNextPage,
} from '../../store/modules/users/actions';
import dots from '../../assets/dots.svg';
import Modal from '../../components/Modal';
import delete2 from '../../assets/delete2.svg';
import pencil from '../../assets/pencil.svg';
import addIcon from '../../assets/add-4.svg';
import importDownload from '../../assets/importdownload.svg';
import importIcon from '../../assets/import.svg';
import useRooms from '../../hooks/useRooms';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import MenuSearchBar from '../../components/MenuSearchBar';
import paths from '../../utils/paths';
import AddNewUserModalContent from './AddUserModalContent';
import EditUserModalContent from './EditUserModalContent';
import DeleteUserModalContent from './DeleteUserModalContent';
import NoContent from '../../components/NoContent';
import Notify from '../../utils/notification';
import api from '../../services/api';
import useUsers from '../../hooks/useUsers';

const LoadingSkeleton = () => (
    <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
        <div style={{ marginTop: 10 }}>
            <Skeleton height={50} count={10} style={{ margin: '5px 0' }} />
        </div>
    </SkeletonTheme>
);

const renderPermission = (permission, t) => {
    const type = {
        user: <span>{t(languageConstants.PARTICIPANT)}</span>,
        organizer: <span className="organizer">{t(languageConstants.ORGANIZER)}</span>,
        monitor: <span className="monitor">{t(languageConstants.MONITOR)}</span>,
        extra_user: <span className="extra">{t(languageConstants.GUEST)}</span>,
    };
    return type[permission];
};

export default function Users() {
    const dispatch = useDispatch();
    const { selectedChannel } = useRooms();
    const {
        users, nextPage, nextPageLoading, loading, loaderInButton, isLastPage,
    } = useUsers();

    const { t } = useTranslation();

    const [userDeleted, setUserDeleted] = useState('');
    const [userEdited, setUserEdited] = useState('');
    const [showDropdown, setShowDropdown] = useState('');
    const [rowsPerPage] = useState(10);
    const [alert, setAlert] = useState({
        alert: false,
        alertTransition: false,
    });

    const [ordination, setOrdination] = useState('desc');
    const [keyword, setKeyword] = useState('');
    const [debouncedKeyword] = useDebounce(keyword, 500);
    const [sortBy, setSortBy] = useState('name');
    const [filterOption, setFilterOption] = useState('all');
    const { setItems } = useBreadcrumbs();
    const history = useHistory();

    const [modalOpen, setModalOpen] = useState({
        modalAdd: false,
        modalDelete: false,
        modalEdit: false,
    });

    function openModalAdd() {
        setModalOpen({ ...modalOpen, modalAdd: true });
    }

    function clearFilter() {
        setKeyword('');
    }

    function handleOrdination(value, sort) {
        setSortBy(value);
        setOrdination(sort);
    }

    function handleNextPage() {
        if (nextPage) {
            dispatch(getUsersNextPage(selectedChannel?.id, rowsPerPage, nextPage, debouncedKeyword));
        }
    }

    function setDropdown(index) {
        if (index === showDropdown) {
            setShowDropdown('');
        } else {
            setShowDropdown(index);
        }
    }

    async function handleExport() {
        if (selectedChannel?.id) {
            try {
                Notify.success(t(languageConstants.PREPARING_DOWNLOAD));
                const response = await api.exportUsersReport(
                    selectedChannel?.id,
                );
                const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const excelFile = b64toBlob(response.bytes, contentType);
                const url = window.URL.createObjectURL(excelFile);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${t(languageConstants.USERS_REPORT).replace(/ /g, '_')}_${new Date()
                        .toLocaleString()
                        .replace(/\//g, '-')
                        .replace(/ /g, '_')}.xlsx`,
                );
                document.body.appendChild(link);
                link.click();
                link.remove();
            } catch (error) {
                Notify.error(t(languageConstants.EXPORT_REPORT_FAILURE));
            }
        }
    }

    const moreOptions = [
        {
            action: openModalAdd,
            text: t(languageConstants.NEW_USER),
            icon: addIcon,
        },
        {
            action: () => history.push('/users/import'),
            text: t(languageConstants.IMPORT_SPREADSHEET),
            icon: importIcon,
        },
        {
            action: handleExport,
            text: t(languageConstants.DOWNLOAD_SPREADSHEET),
            icon: importDownload,
        },
    ];

    const deleteUserAction = (user, channel) => {
        dispatch(setLoaderState(true));
        api.deleteUser(user, channel.id)
            .then(() => {
                dispatch(getUsers(selectedChannel?.id, rowsPerPage, 0, debouncedKeyword, true));
            }).catch(
                (error) => {
                    defaultErrorHandler(error, { default: t(languageConstants.FAIL_UNSUBSCRIBE_USER) });
                },
            ).finally(() => {
                dispatch(setLoaderState(false));
            });
    };

    const addUserAction = (user) => {
        api.postUser(user)
            .then(() => {
                dispatch(getUsers(selectedChannel?.id, rowsPerPage, 0, debouncedKeyword, true));
            })
            .catch((error) => {
                const limitUsersError = error.response.data.error_description;
                if (error && limitUsersError.includes('Subs limit reached for course')) {
                    defaultErrorHandler(error, { default: t(languageConstants.SUBS_LIMIT_REACHED) });
                } else {
                    defaultErrorHandler(error, { default: t(languageConstants.FAIL_ADD_USER) });
                }
            })
            .finally(() => {
                dispatch(setLoaderState(false));
            });
    };

    const editUserAction = (user, courseId) => {
        dispatch(setLoaderState(true));
        dispatch(editUser(user, courseId));
    };

    function handleModal(value, action, user) {
        setShowDropdown('');
        if (action === 'add' && !loaderInButton) {
            setModalOpen({ ...modalOpen, modalAdd: value });
            setAlert({ alert: false, alertTransition: false });
        } else if (action === 'delete' && !loaderInButton) {
            setModalOpen({ ...modalOpen, modalDelete: value });
            setUserDeleted(user);
        } else if (!loaderInButton) {
            setModalOpen({ ...modalOpen, modalEdit: value });
            setUserEdited(user);
        }
    }

    useEffect(() => {
        if (selectedChannel?.id) {
            dispatch(
                getUsers(selectedChannel?.id, rowsPerPage, 0, debouncedKeyword),
            );
        }
    }, [debouncedKeyword, dispatch, rowsPerPage, selectedChannel]);

    useEffect(() => {
        if (!loaderInButton) {
            setModalOpen((prev) => ({
                ...prev,
                modalAdd: false,
                modalDelete: false,
                modalEdit: false,
            }));
        }
    }, [loaderInButton]);

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t(languageConstants.ORGANIZER_DASHBOARD) },
            { to: paths.USERS, label: t(languageConstants.USERS) },
            {
                to: paths.USERS,
                label: `${selectedChannel?.name || ''} ${
                    selectedChannel?.name ? '-' : ''
                } ${selectedChannel?.id || ''}`,
            },
        ];
        setItems(breadcrumbsItems);
    }, [selectedChannel, setItems, t]);

    const renderUserStatus = (status) => {
        const type = {
            false: <span>{t(languageConstants.ENROLLED)}</span>,
            true: <span>{t(languageConstants.UNENROLLED)}</span>,
        };

        return type[status] || type.false;
    };

    const { user } = useAuth();

    return (
        <Main>
            { (user) && <OnboardingUsers />}
            <Modal
                isOpen={modalOpen.modalAdd}
                title={t(languageConstants.ADD_NEW_USER)}
                handleModal={() => handleModal(false, 'add')}
                width={550}
            >
                <AddNewUserModalContent
                    alert={alert}
                    setAlert={setAlert}
                    handleAddUser={addUserAction}
                />
            </Modal>

            <Modal
                isOpen={modalOpen.modalDelete}
                title={t(languageConstants.UNSUBSCRIBE_USER)}
                handleModal={() => handleModal(false, 'delete', '')}
                width={550}
            >
                <DeleteUserModalContent
                    deleteUserAction={deleteUserAction}
                    handleModal={handleModal}
                    userDeleted={userDeleted}
                />
            </Modal>

            <Modal
                isOpen={modalOpen.modalEdit}
                title={t(languageConstants.EDIT_USER)}
                handleModal={() => handleModal(false, 'edit', '')}
                width={550}
            >
                <EditUserModalContent
                    editUserAction={editUserAction}
                    setUserEdited={setUserEdited}
                    userEdited={userEdited}
                />
            </Modal>

            <Header className="header">
                <div className="buttons">
                    <MenuSearchBar
                        moreOptions={moreOptions}
                        filterOptions={[
                            { label: `${t(languageConstants.ORGANIZER)}`, value: 'organizer' },
                            { label: `${t(languageConstants.MONITOR)}`, value: 'monitor' },
                            { label: `${t(languageConstants.PARTICIPANT)}`, value: 'user' },
                            { label: `${t(languageConstants.ALL)}`, value: 'all' },
                        ]}
                        selectedFilter={filterOption}
                        setFilterOption={setFilterOption}
                        options={[
                            {
                                label: `${t(languageConstants.POINTS)} - ${t(languageConstants.DECREASING)}`,
                                sort: 'desc',
                                value: 'points',
                            },
                            {
                                label: `${t(languageConstants.POINTS)} - ${t(languageConstants.ASCENDING)}`,
                                sort: 'asc',
                                value: 'points',
                            },
                            {
                                label: `${t(languageConstants.NAME)} - A-Z`,
                                sort: 'asc',
                                value: 'name',
                            },
                            {
                                label: `${t(languageConstants.NAME)} - Z-A`,
                                sort: 'desc',
                                value: 'name',
                            },
                            {
                                label: `${t(languageConstants.LAST_UPDATE)}`,
                                sort: 'desc',
                                value: 'update',
                            },
                        ]}
                        setOption={handleOrdination}
                        handleChangeInputFilter={setKeyword}
                        placeholder={t(languageConstants.LABEL_SEARCH_INPUT)}
                        orderBy={sortBy}
                        search={() => dispatch(
                            getUsers(
                                    selectedChannel?.id,
                                    rowsPerPage,
                                    0,
                                    debouncedKeyword,
                            ),
                        )}
                        ordination={ordination}
                        clearFilter={clearFilter}
                        valueInput={keyword}
                    />
                </div>
            </Header>
            <div className="containerTable" data-tour="tour__container_table">
                {!loading && !users.length > 0 && debouncedKeyword === '' && (
                    <NoContent
                        title={t(languageConstants.PARTICIPANT_NOT_FOUND)}
                        text={t(languageConstants.NO_USERS_SHOW)}
                    />
                )}
                {!loading && !users.length > 0 && debouncedKeyword !== '' && (
                    <NoContent
                        type="NOT_FOUND"
                        title={t(languageConstants.PARTICIPANT_NOT_FOUND)}
                        text={t(languageConstants.PARTICIPANT_NOT_FOUND_MESSAGE)}
                    />
                )}
                {loading && <LoadingSkeleton />}
                {!loading && users.length > 0 && (
                    <>
                        <TableUsers>
                            <Table>
                                <TableHead>
                                    <tr>
                                        <th align="left" colSpan="1">
                                            {t(languageConstants.NAME)}
                                        </th>
                                        <th align="left">Email</th>
                                        <th align="left" colSpan="1">
                                            {t(languageConstants.STATUS)}
                                        </th>
                                        <th align="left" colSpan="4">
                                            {t(languageConstants.PERMISSION)}
                                        </th>
                                    </tr>
                                </TableHead>
                                <TableBody>
                                    {users?.map((user, index) => (
                                        <tr key={user?.username}>
                                            <td align="left">
                                                {user?.fullName}
                                            </td>
                                            <td align="left">
                                                {user?.username}
                                            </td>
                                            <td align="left">
                                                {renderUserStatus(user?.expired)}
                                            </td>
                                            <td align="left">
                                                {renderPermission(
                                                    user?.permission,
                                                    t,
                                                )}
                                            </td>
                                            <td>
                                                {showDropdown === index && (
                                                    <OutsideClickHandler
                                                        onOutsideClick={() => setShowDropdown('')}
                                                        display="contents"
                                                    />
                                                )}
                                            </td>
                                            <td align="left">

                                                <Menu control={(
                                                    <ButtonOptions
                                                        onClick={() => setDropdown(index)}
                                                    >
                                                        <img
                                                            alt="icondots"
                                                            src={dots}
                                                            data-tour="tour__icon_dots"
                                                        />
                                                    </ButtonOptions>
                                                )}
                                                >
                                                    {/* Menu items */}
                                                    {user?.permission
                                                            !== 'extra_user' && (
                                                            <ItemOption>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleModal(
                                                                        !modalOpen.modalEdit,
                                                                        'edit',
                                                                        user,
                                                                    )}
                                                                >
                                                                    <img
                                                                        className="icon"
                                                                        alt="iconPencil"
                                                                        src={
                                                                            pencil
                                                                        }
                                                                    />
                                                                    {t(languageConstants.EDIT_USER)}
                                                                </button>
                                                                <hr />
                                                            </ItemOption>
                                                        )}
                                                    <ItemOption>
                                                        <button
                                                            type="button"
                                                            onClick={() => handleModal(
                                                                !modalOpen.modalDelete,
                                                                'delete',
                                                                user,
                                                            )}
                                                        >
                                                            <img
                                                                className="icon"
                                                                alt="iconDelete"
                                                                src={
                                                                    delete2
                                                                }
                                                            />
                                                            {t(languageConstants.UNENROLL_USER)}
                                                        </button>
                                                    </ItemOption>
                                                </Menu>
                                                <div />
                                            </td>
                                        </tr>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableUsers>
                    </>
                )}
                {!loading && users.length > 0 && (
                <TableFooterResults>
                    <div className="table-footer">
                        <LoadMoreButtonSection>
                            {nextPageLoading ? (
                                <CircularProgress
                                    style={{ color: '#ff9162' }}
                                />
                            ) : !isLastPage && (
                            <button onClick={handleNextPage} type="button">
                                {t(languageConstants.LOAD_MORE_PAGINATION)}
                            </button>
                            )}
                        </LoadMoreButtonSection>
                        <span>
                            {users?.length || 0} {t(languageConstants.RESULTS)}
                        </span>
                    </div>
                </TableFooterResults>
                )}
            </div>
        </Main>
    );
}
