import React, { useCallback, useEffect } from 'react';
import { Upload } from '@src/components/KopaUpload';
import DeleteIcon from '@src/assets/delete.svg';
import cloudUploadIcon from '@src/assets/cloud-icon.svg';
import thumbnailFile from '@src/assets/caption-icon.svg';
import { useTranslation } from 'react-i18next';
import { Loader } from '@mantine/core';
import languageConstants from '@langs/constants';
import { AttachIcon, Container, Content, FileInfo } from './styles';

export default function UploadCaption({ setUploadCaptionFiles, uploadCaptionFiles, isLoading }) {
    const { t } = useTranslation();

    function onUpload(uploadedFile) {
        if (setUploadCaptionFiles) {
            setUploadCaptionFiles(uploadedFile);
        }
    }

    const cleanCurrentUploadedFile = useCallback(() => {
        setUploadCaptionFiles(null);
    }, [setUploadCaptionFiles]);

    useEffect(() => () => cleanCurrentUploadedFile(), [cleanCurrentUploadedFile]);

    return (
        <Container className={isLoading ? '--loading' : ''}>
            {
                isLoading ? <Loader color="#ff617a" /> : (
                    <>
                        {!uploadCaptionFiles && (
                        <Upload
                            customDropIcon={cloudUploadIcon}
                            onUpload={onUpload}
                            message={t(languageConstants.DRAG_AND_DROP_FILES)}
                            handleCancelDialog={() => {}}
                            accept=".srt"
                        />
                        )}
                        {uploadCaptionFiles?.length && (
                            <>
                                <Content>
                                    <AttachIcon src={thumbnailFile} />
                                    <div className="upload-info">
                                        <button type="submit" onClick={cleanCurrentUploadedFile}><img alt="Delete icon" src={DeleteIcon} /></button>
                                    </div>
                                </Content>
                                <FileInfo>
                                    <abbr title={uploadCaptionFiles[0].name}>{uploadCaptionFiles[0].name}</abbr>
                                </FileInfo>
                            </>
                        )}
                    </>
                )
            }
        </Container>
    );
}
