import styled from 'styled-components';

export const ActiveMonitorsListContainer = styled.div`
    margin: 4%;
    margin-top: 75px;
    border-radius: 6px;
    overflow: hidden;
    `;

export const ActiveMonitorsListContent = styled.div`
    border-radius: 6px;
    overflow: hidden;
    thead {
        color: white;   
        background-color: transparent;
        height: 38px;
    }
`;

export const LineTable = styled.tr`
    height: 38px;
    background-color: ${props => (props.index % 2 === 0 ? '#212032' : '#242339')};
    color: #fff;
    
    td {
        width: 50%;
        padding: 0px 10px;
    }
`;

export const ActiveMonitorsTitle = styled.div`
    background-color: #212436;
    width: 100%;
    color: #fff;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NoActiveMonitorsText = styled.p`
    color: #fff;
    width: 100%;
    text-align: center;
    
`;
