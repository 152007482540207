import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ButtonConfirm, ModalContent } from '../style';
import useRooms from '../../../hooks/useRooms';


export default function DeleteUserModalContent({ deleteUserAction, userDeleted, handleModal }) {
    const { t } = useTranslation();
    const { selectedChannel } = useRooms();
    const { loaderInButton } = useSelector(state => state.users);
    return (
        <ModalContent>
            <div className="contentModalDelete">
                <h3>{t('MODAL_UNSUBSCRIBE_MESSAGE')} <span className="fullNameUSer">{userDeleted.fullName}</span> ?</h3>
                <div className="buttonsModalDelete">
                    <ButtonConfirm
                        type="button"
                        onClick={() => deleteUserAction(userDeleted, selectedChannel)}
                    >
                        {t('BUTTON_MESSAGE_YES')}
                        {loaderInButton
                            && (
                                <div className="loaderButton">
                                    <CircularProgress size={25} />
                                </div>
                            )}
                    </ButtonConfirm>
                    <button
                        type="button"
                        className="buttonDeleteModal"
                        onClick={() => handleModal(false, 'delete', '')}
                    >
                        {t('BUTTON_MESSAGE_NOT')}
                    </button>
                </div>
            </div>
        </ModalContent>
    );
}
