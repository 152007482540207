import React, { useCallback, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { fetchEditRoom } from '@src/store/modules/rooms/actions';
import plusDetailIcon from '@src/assets/plus-detail-icon.svg';
import useRooms from '@src/hooks/useRooms';
import { useBreadcrumbs } from '@src/hooks/useBreadcrumbs';
import useQuery from '@src/hooks/useQuery';
import paths from '@src/utils/paths';
import webRTCSocket from '@src/services/WebRTCSocket';
import mediaSoup from '@src/services/MediaSoupConsumerService';
import api from '@src/services/api';
import NoContent from '@src/components/NoContent';
import langs from '@src/assets/i18n/constants';
import { Container, PageHeader, GuestUserTableContainer, TableFooterResults } from './styles';

const LoadingSkeleton = () => (
    <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
        <div style={{ marginTop: 10 }}>
            <Skeleton height={50} count={10} style={{ margin: '5px 0' }} />
        </div>
    </SkeletonTheme>
);

function handleOnGotProducer(producers = [], producer, setState) {
    const foundProducer = producers.find(user => user.id === producer.id);
    if (!foundProducer) {
        setState([...producers, producer]);
    }
}

export default function GuestPresenter() {
    const { t } = useTranslation();
    const { videoUrl, socketPath } = useRooms();
    const { id: roomId } = useParams();
    const history = useHistory();
    const { setItems } = useBreadcrumbs();
    const query = useQuery();
    const dispatch = useDispatch();

    const [producers, setProducers] = useState([]);
    const [loading, setLoading] = useState(true);

    const accessToken = api.getAccessToken();

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t(langs.ORGANIZER_DASHBOARD) },
            { to: `${paths.CHANNELS}/${roomId}/details`, label: t(langs.DASHBOARD) },
            { to: `${paths.GUEST_PRESENTER}/${roomId}`, label: t(langs.GUEST_PRESENTER) },
        ];
        setItems(breadcrumbsItems);
    }, [query, roomId, setItems, t]);

    function handleOnGotProducersList(producersList) {
        setLoading(false);
        setProducers(producersList);
    }

    function handleOnDisconnectProducer(producer) {
        setProducers(state => state.filter(user => user.id !== producer.id));
    }

    const handleConnectWebRTC = useCallback(() => {
        mediaSoup.onGotProducerListener = (producer) => handleOnGotProducer(producers, producer, setProducers);
        mediaSoup.onGotProducersListListener = handleOnGotProducersList;
        mediaSoup.onDisconnectProducerListener = handleOnDisconnectProducer;
        mediaSoup.setSocket(webRTCSocket.socket);
        mediaSoup.connect(true, false);
    }, [producers]);

    useEffect(() => {
        if (videoUrl && socketPath && accessToken) {
            if (!webRTCSocket.socket?.connected) {
                webRTCSocket.onConnectListener.push(handleConnectWebRTC);
                webRTCSocket.authenticate(videoUrl, socketPath, accessToken, true);
            } else {
                setLoading(false);
                handleConnectWebRTC();
            }
        }
    }, [socketPath, videoUrl, accessToken, handleConnectWebRTC]);

    useEffect(() => {
        if (!videoUrl || socketPath) {
            dispatch(fetchEditRoom(roomId));
        }
    }, [dispatch, roomId, socketPath, videoUrl]);

    function handleGotoGuestPresenterView(guestId, guestName) {
        const currentPage = 'guest_list';
        history.push(`${paths.GUEST_PRESENTER_VIEW}/${roomId}/${guestId}?guestName=${encodeURIComponent(guestName)}&prevPage=${currentPage}`);
    }

    return (
        <Container>
            <PageHeader>
                <h2> SELECIONAR APRESENTADOR CONVIDADO </h2>
            </PageHeader>
            {
                loading && <LoadingSkeleton />
            }
            {
                !producers.length && !loading && (
                    <NoContent
                        title={t(langs.NO_USER_CONNECTED)}
                        text={t(langs.NO_USERS_ROOM)}
                    />
                )
            }

            {
                !!producers.length && (
                    <div className="containerTable">
                        <GuestUserTableContainer>
                            <Table>
                                <TableHead>
                                    <tr>
                                        <th align="left">{t(langs.NAME)}</th>
                                        <th align="left">{t(langs.EMAIL)}</th>
                                        <th align="left">{' '}</th>
                                    </tr>
                                </TableHead>
                                <TableBody>
                                    {
                                        producers.map(user => (
                                            <tr key={user.id}>
                                                <td align="left">{user?.name}</td>
                                                <td align="left">{user?.email}</td>
                                                <td align="right">
                                                    <div className="line-controls">
                                                        <button className="action-button" type="button" onClick={() => handleGotoGuestPresenterView(user.id, user.name)}>
                                                            <img src={plusDetailIcon} alt={t(langs.CONVIDAR)} />
                                                            {t(langs.CONVIDAR)}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </GuestUserTableContainer>
                    </div>
                )
            }
            {!!producers.length && (
                <TableFooterResults>
                    <div className="table-footer">

                        <span>
                            {producers.length || 0} {t(langs.RESULTS)}
                        </span>
                    </div>
                </TableFooterResults>
            )}
        </Container>
    );
}
