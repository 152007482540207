import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useDebounce } from 'use-debounce/lib';
import exportIcon from '../../../assets/export-icon.svg';
import refreshIcon from '../../../assets/refresh-icon.svg';

import {
    Container,
    PageHeader,
    ReportTable,
    LoadMoreButtonSection,
    TableFooterResults,
} from './styles';
import {
    getNextPagePresenceReport,
    getPresenceReport,
    setVerified,
} from '../../../store/modules/presenceReport/actions';
import api from '../../../services/api';
import avatarImg from '../../../assets/avatar.svg';
import NoContent from '../../../components/NoContent';
import MenuSearchBar from '../../../components/MenuSearchBar';
import Notify from '../../../utils/notification';
import { useBreadcrumbs } from '../../../hooks/useBreadcrumbs';
import useQuery from '../../../hooks/useQuery';
import paths from '../../../utils/paths';
import useRooms from '../../../hooks/useRooms';
import { useFetchEditRoom } from '../../../hooks/useFetchEditRoom';

const LoadingSkeleton = () => (
    <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
        <div style={{ marginTop: 10 }}>
            <Skeleton height={50} count={10} style={{ margin: '5px 0' }} />
        </div>
    </SkeletonTheme>
);

export default function PresenceReportExport() {
    const [downloading, setDownloading] = useState(false);
    const [minCharactersSearch] = useState(3);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        loading,
        users, nextToken,
        nextReportLoading,
        fromSearch,
        verified,
    } = useSelector(
        (state) => state.presenceReport,
    );
    const location = useLocation();
    const { selectedEditRoom } = useRooms();
    const rowsPerPage = 10;
    const roomId = location.pathname.split('/')[2];
    const { setItems } = useBreadcrumbs();
    const query = useQuery();
    const [keyword, setKeyword] = useState('');
    const [debouncedKeyword] = useDebounce(keyword, 500);

    const handleRefreshReportList = () => {
        setKeyword('');
        dispatch(getPresenceReport(roomId, rowsPerPage));
    };

    const handleNextPage = () => {
        dispatch(getNextPagePresenceReport(roomId, nextToken, rowsPerPage));
    };

    async function handleExport() {
        setDownloading(true);
        try {
            const response = await api.exportPresenceReport(roomId);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${t('PRESENCE_REPORT').replace(/ /g, '_')}_${new Date()
                    .toLocaleString()
                    .replace(/\//g, '-')
                    .replace(/ /g, '_')}.csv`,
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
            setDownloading(false);
        } catch (error) {
            Notify.error(t('EXPORT_REPORT_FAILURE'));
            setDownloading(false);
        }
    }

    function renderViewTime(timeInSeconds) {
        const sec_num = parseInt(timeInSeconds, 10);
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - hours * 3600) / 60);
        let seconds = sec_num - hours * 3600 - minutes * 60;

        if (hours < 10) {
            hours = `0${hours}`;
        }
        if (minutes < 10) {
            minutes = `0${minutes}`;
        }
        if (seconds < 10) {
            seconds = `0${seconds}`;
        }
        return `${hours}:${minutes}:${seconds}`;
    }

    useFetchEditRoom(roomId);

    useEffect(() => {
        if (!verified) {
            dispatch(getPresenceReport(roomId, rowsPerPage));
        }
        return () => dispatch(setVerified(false));
    }, [dispatch, roomId, verified]);

    useEffect(() => {
        if (debouncedKeyword.length < minCharactersSearch) return;
        dispatch(getPresenceReport(roomId, rowsPerPage, debouncedKeyword));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedKeyword]);

    useEffect(() => {
        if (debouncedKeyword.length < minCharactersSearch) return;
        dispatch(getPresenceReport(roomId, rowsPerPage, debouncedKeyword));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedKeyword]);

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t('ORGANIZER_DASHBOARD') },
            { to: paths.PRESENCE_REPORT, label: t('PRESENCE_REPORT') },
            {
                to: `${paths.PRESENCE_REPORT}/${roomId}/?roomName=${selectedEditRoom?.name || ''}`,
                label: selectedEditRoom?.name || '',
            },
        ];
        setItems(breadcrumbsItems);
    }, [query, roomId, setItems, t, selectedEditRoom]);

    function handleSearch() {
        if (debouncedKeyword.length < minCharactersSearch) {
            Notify.warn(t('SEARCH_MIN_CHARACTERS'));
            return;
        }
        dispatch(
            getPresenceReport(
                roomId,
                rowsPerPage,
                debouncedKeyword,
            ),
        );
    }

    function resultFromSearch() {
        if (!loading && fromSearch) return true;
        return false;
    }

    return (
        <Container>
            <h2>{t('PRESENCE_REPORT')}</h2>
            <PageHeader>
                <div className="presence-report-search">
                    <div>
                        <MenuSearchBar
                            placeholder={t('LABEL_SEARCH_INPUT')}
                            handleChangeInputFilter={setKeyword}
                            search={handleSearch}
                            valueInput={keyword}
                            clearFilter={() => setKeyword('')}
                        />

                    </div>
                </div>

                <div className="buttons-section">
                    <button
                        disabled={downloading}
                        type="button"
                        onClick={handleRefreshReportList}
                    >
                        <small>
                            <img alt="refreshIcon" src={refreshIcon} />
                        </small>
                        <span>{t('REFRESH')}</span>
                    </button>

                    <button
                        disabled={downloading}
                        type="button"
                        onClick={handleExport}
                    >
                        <small>
                            <img alt="plusIcon" src={exportIcon} />
                        </small>
                        <span>{t('EXPORT')}</span>
                    </button>
                </div>

            </PageHeader>
            {!loading && !users.length > 0 && (
                <NoContent
                    title={resultFromSearch() ? t('PARTICIPANT_NOT_FOUND') : t('OPS')}
                    text={resultFromSearch() ? t('PARTICIPANT_OUT_LIST') : t('NO_PARTICIPANT_REPORT')}
                    type={resultFromSearch() && 'NOT_FOUND'}
                />
            )}
            {loading && <LoadingSkeleton />}
            {!loading && users.length > 0 && (
                <div className="containerTable">
                    <ReportTable>
                        <Table>
                            <TableHead>
                                <tr>
                                    <th align="left"> </th>
                                    <th align="left">{t('NAME')}</th>
                                    <th align="left">{t('EMAIL')}</th>
                                    <th align="left">{t('VIEWING_TIME')}</th>
                                </tr>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 && users).map((user) => (
                                    <tr key={user?.username}>
                                        <td align="center">
                                            <img src={avatarImg} alt="Perfil" />
                                        </td>
                                        <td align="left">{user.fullName}</td>
                                        <td align="left">{user.username}</td>
                                        <td align="left">
                                            {renderViewTime(user?.sumViewTime)}
                                        </td>
                                    </tr>
                                ))}

                            </TableBody>
                        </Table>
                    </ReportTable>
                </div>
            )}
            {!loading && users.length > 0 && (
                <TableFooterResults>
                    <div className="table-footer">
                        <LoadMoreButtonSection>
                            {nextReportLoading ? (
                                <CircularProgress
                                    style={{ color: '#ff9162' }}
                                />
                            ) : nextToken === null ? null : (
                                <button type="button" onClick={handleNextPage}>
                                    {t('LOAD_MORE_PAGINATION')}
                                </button>
                            )}
                        </LoadMoreButtonSection>
                        <span>
                            {users?.length || 0} {t('RESULTS')}
                        </span>
                    </div>
                </TableFooterResults>
            )}
        </Container>
    );
}
