import styled from 'styled-components';

export const Header = styled.div`
    height: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    overflow-x: hidden;
    
    h2 {
        margin: auto 0;
        font-size: 24px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.27px;
        text-align: justify;
        color: #fff;
        margin-left: 12px;
    }
    @media (max-width: 650px) {
        padding: 0 1em;
    }
    .rectangle-header {
        display: flex;
        margin: 0 0 0 8px;
        border-radius: 12px;
        background-color: #212032;
        text-align:center;
        justify-content: center;
        padding:10px;
        border-style:none;
    }
    .download-video {
        margin: auto 0 auto 5px;
        font-size: 16px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        color: #fff;
    }
    .rectangle-download-video {
        width: 34px;
        height: 32px;
        margin: auto 0;
        padding: 6px;
        border-radius: 7px;
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
    }
`;

export const TableVideoStats = styled.div`
    width: 100%;
    padding: 0 0 1px;
    border-radius: 8px;
    background-color: #212032;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #ffffff;
    max-width: 100%;
    position: relative;

    th {
        background-color: #1c1b2d;
        padding: 12px 0.4em;
        text-transform: uppercase;
        &:first-child {
            padding-left: 18px;
        }
    }

    tr {
        cursor: pointer;
        background-color: #212032;
        &:nth-child(even) {
            background-color: #242339;
        }

        &:not(:last-child):hover {
            background-color: #2f2e3e;
        }

        .line-controls {
            display: flex;
            justify-content: flex-end;
        }

        td {
            padding: 10px 0.5em;
            &:first-child {
                padding-left: 18px;
            }
        }
    }

    .MuiTableCell-root.MuiTableCell-body {
        border-bottom: none;
    }

    .MuiPaginationItem-ellipsis {
        color: #fff;
    }

    @media (max-width: 650px) {
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0;
        @media (max-width: 650px) {
            max-height: 40vh;
        }
    }
`;

export const Main = styled.div`
    padding: 0 28px;
    margin-bottom: 2rem;
    .buttons {
        width: 100%;
        @media (max-width: 650px) {
            display: flex;
            flex-direction: column;
        }
    }
    .loader {
        z-index: 999;
        top: 50%;
        left: 50%;
        position: absolute;
    }
    .loaderButton {
        top: 0;
        margin-top: 5px;
        position: absolute;
    }

    .MuiCircularProgress-colorPrimary {
        color: #ffffff;
    }

    .containerTable {
        margin: 1.5em 0 0 0;
        max-height: 70vh;
        overflow: auto;
        border-radius: 8px 8px 0 0;
        min-height: 200px;

        @media (max-width: 650px) {
            max-height: 60vh;
        }
    }

    @media (max-width: 650px) {
        padding: 0 1em;
    }

    .organizer {
        color: #ff9162;
    }
    .monitor {
        color: #ff6479;
    }
    .extra {
        color: #31a6a1;
    }
    .download-video-button {
            display: none;
        }
`;

export const TableFooterResults = styled.div`
    display: flex;

    .table-footer {
        display: flex;
        justify-content: space-between;
        padding: 0.8rem 1rem;
        width: 100vw;
        border-radius: 0 0 8px 8px;
        background-color: #1c1b2d;
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        color: #ffffff;
        position: relative;
        
    }
     .result-found {
        flex: auto;
        width: 50px;
        height: 17px;
        margin: 5px 0px 6px 0;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.29px;
        color: #fff;
        
     }
    
     .rectangle-footer {
        width: 28px;
        height: 28px;
        padding: 5px 9px 4px 10px;
        border-radius: 7px;
        background-color: #ff6379;
     
        .span-number {
          width: 9px;
          height: 19px;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.34px;
          color: #fff;
          position: absolute;
        }
     }

`;

export const UserWacthSpan = styled.p`
            width: 305px;
            height: 22px;
            margin: 0 985px 22px 3px;
            font-family:"Open Sans", sans-serif;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.18px;
            text-align: center;
            color: #fff;
`;

export const ContainerRectangle = styled.div`
            display: flex;     
            justify-content: space-between;
            align-items:center;
            margin-bottom:20px;    
            overflow-x:hidden;
`;

export const RectangleCard = styled.div`
        width: 15vw;
        height: 10vw;
        border-radius: 5px;
        background-color: #1c1b2d;
        display:flex;
        justify-content: center;
        margin: 5px;
        flex: 1;

        .oval {
            border-radius:50%;
            margin: auto 0 auto clamp(1px, 1vw, 5px);
            background-image: linear-gradient(to left, #ffae46, #ff428d 100%);
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: clamp(27px, 2.6vw, 39px);
                height: clamp(27px, 2.6vw, 39px);
                padding: 8px;
            }
        }

        .container-title {
            display:flex;
        }

        .content-container {
            margin: auto 0 auto 5px;

            .title {
                font-size: clamp(24px, 2.1vw, 32px);
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.37px;
                text-align: center;
                color: #fff;
                margin-bottom:10px
            }

            .description {
                font-size: clamp(9px, 1.2vw, 13px);
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.23;
                letter-spacing: 0.15px;
                color: #fff;
                text-align:start;
                flex: 1;

                .download-anexo {
                    margin: 2px 0 0;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.16px;
                    color: #ff9062;
                }
            }
        }
`;
