import React from 'react';
import { AnimatePresence } from 'framer-motion';
import {
    Container,
    Content,
    CloseButton,
    ContentTitle,
    Title,
    ContentBody,
} from './styles';

import closeIcon from '../../assets/close-icon.svg';
import AnimatedPage from '../../routes/AnimatedPage';

export default function Modal({
    isOpen, handleModal, children, width, title = '', featuredTitle = '', bodyWidth = null,
}) {
    return (
        <AnimatePresence exitBeforeEnter>
            { isOpen && (
                <Container>
                    <AnimatedPage style={{
                        flex: 1, display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
                    }}
                    >
                        <Content width={width}>
                            <ContentTitle>
                                <Title>
                                    <p>{`${title}`}</p>
                                    <p className="featured">
                                        {`${featuredTitle}`}
                                    </p>
                                </Title>
                                <CloseButton className="--pulse" type="button" onClick={handleModal}>
                                    <img src={closeIcon} alt="Fechar modal" />
                                </CloseButton>
                            </ContentTitle>
                            <ContentBody bodyWidth={bodyWidth} className="--modal-body">
                                {children}
                            </ContentBody>
                        </Content>
                    </AnimatedPage>
                </Container>
            )}
        </AnimatePresence>
    );
}
