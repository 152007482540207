import { Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CopyIcon from '../../../../assets/copy.svg';
import { BroadcastClipBoardItemContainer, BroadcastUrl, BroadcastUrlArea, BroadcastUrlTitle, UrlClipboardArea } from './styles';

const BroadcastClipBoardItem = ({ title, data }) => {
    const [hasCopy, setHasCopy] = useState(false);

    const { t } = useTranslation();

    const handleClipboard = () => {
        navigator.clipboard.writeText(data).then(
            setHasCopy(true),
        );
    };

    useEffect(() => {
        let tooltipTimer;

        // eslint-disable-next-line prefer-const
        tooltipTimer = setTimeout(() => {
            setHasCopy(false);
        }, 3000);

        return () => clearTimeout(tooltipTimer);
    }, [hasCopy]);

    return (
        <BroadcastClipBoardItemContainer>
            <BroadcastUrlArea>
                <BroadcastUrlTitle>
                    {title}
                </BroadcastUrlTitle>
                <BroadcastUrl>
                    {data}
                </BroadcastUrl>
            </BroadcastUrlArea>
            <UrlClipboardArea>
                <Tooltip
                    PopperProps={{ disablePortal: true }}
                    open={hasCopy}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={t('CHANNEL', { context: 'COPIED' })}
                >
                    <img onClick={handleClipboard} src={CopyIcon} alt="copy" />
                </Tooltip>
            </UrlClipboardArea>
        </BroadcastClipBoardItemContainer>
    );
};

export default BroadcastClipBoardItem;
