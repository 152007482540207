import styled from 'styled-components';

export const CreateCourseContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export const CreateCourseContent = styled.div`
    width: 100%;
    height: 100vh;
    padding: 0px 25px 50px;

    .--modal-body{
        width: 100%;
        padding: 20px;
    }

    .confirm-buttons button.cancel {
        width: auto;
    }
`;

export const CreateCourseHeader = styled.div`
    display: flex;
    justify-content: space-between;

    .header-buttons-holder {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }

    & > div {
        width: 250px !important;

        button {
            width: 100% !important;

            img {
                transform: rotate(180deg);
            }
        }
    }
`;

export const CreateCourseHeaderTitle = styled.h1`
    color: #fff;
    text-transform: uppercase;
`;

export const CreateCourseFormArea = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    padding-bottom: 30px;
`;
