import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 40px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    overflow: hidden;

    img {
        width: auto;
        height: auto;
    }

    h2 {
        font-size: 25px;
        font-weight: 800;
        letter-spacing: 0.29px;
        color: #ff6976; 
        margin-top: 20px;
        text-transform: uppercase;
    }

    p {
        font-size: 20px;        
        font-weight: 600;
        letter-spacing: 0.23px;
        color: #fff;
    }

    @media(max-width: 680px) {
        img {
            width: 280px;
        }
    }

    button {
        border: none;
        color: #FFF;
        background: linear-gradient(90deg,#FFAC47 0%, #FF438D 100%);
        width: 300px;
        min-height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        font-weight: 600;
        border-radius: 8px;  
        font-weight: bold;
        text-transform: uppercase; 
        opacity: 0.8;
        transition: all 0.1s ease-in-out;
        
        &.--help-button {     
            color: #ff5b7d;             
            background: #282b3a;
            border: solid 1px #ff5a7d;
        }

        &:hover {
            box-shadow: 0px 0px 10px -4px #000000;
            opacity: 1;
            transform: scale(1.02);
        }
    }

    
`;
