import styled from 'styled-components';

export const CourseForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const CourseVideoFormContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    p {
        top: 0;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
    }

    em {
        color: rgba(255,66,141,1);
        font-size: 10px;
    }
`;

export const GroupInput = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-gap: 18px;
    margin-top: 18px;

    @media (max-width:769px) {
        grid-template-columns: 1fr;
    }
`;

export const CourseFormTextAreaContainer = styled.div`
    margin-top: 18px;
    width: 100%;
`;

export const CourseFormUploadArea = styled.div`
    margin-top: 18px;
    width: 100%;
`;

export const CourseFormUploadHeader = styled.div`
    p {
        color: #fff;
        font-weight: bold;
        font-size: 12px;
        margin-left: 15px;
    }
    
    span {
        color: #ff9869;
        font-size: 8px;
    }
`;

export const CourseFormUploadDropzone = styled.div`
    margin-top: 4px;
    height: 200px;
    border: 3px solid #212032;
    border-style: dashed;
    border-radius: 5px; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    .dropzone {
        flex-grow: 1;
    }
`;

export const ButtonArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ButtonAreaContainer = styled.div`
    margin-top: 20px;
    width: 387px;
    height: 37px;
`;
