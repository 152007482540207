import styled from 'styled-components';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(0,0,0,0.3);
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 999;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const Content = styled.main`
    min-height: 200px;
    max-height: 95vh;
    width: ${props => props.width || 416}px ;
    max-width: 90%;
    background: #1b1b32;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 0px 10px -4px #000000;
    @media(max-width: 500px) {
        width: 100%;
        margin: 0 13px;
    }
        
`;

export const CloseButton = styled.button`   
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
    position: absolute;
    right: -15px;
    top: -10px;
    outline: none;
    border: none;
    img {
        height: 25px;
        width: 25px;
    }
`;

export const ContentTitle = styled.div`
    background-color: #242441;
    height: 55px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(12px, 0.4em + 1vw, 1.5em);
    text-align: center;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 10px;
`;

export const Title = styled.h5`
    display: flex;

    p {
        color: #fff;
        &.featured {
            margin-left: 5px;
            color: #ff8e63;
        }
    }
`;

export const ContentBody = styled.section`
    width: ${props => `${props.bodyWidth}%` || null} ;
    flex: 1;
    justify-content: center;
    align-items:center;    
`;
