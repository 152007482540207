import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div`
    position: fixed;
    width: 100%;
    padding: 20px 28px 20px 100px;
    transition: all 0.5s ease-in-out;
    top: 0;
    z-index: 5;
    background: #282b3a;
    @media (max-width: 800px) {
        padding: 0;
        display: flex;
        flex-direction: column;
    }
`;

export const HeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 150ms;
    ${({ isMenuOpen }) => isMenuOpen && css`
        margin-left: 17em;
    `}

    @media (max-width: 800px) {
        transform: translateY(-200px);
        height: 0;
    }
`;

export const HeaderLeft = styled.div``;

export const HeaderMiddle = styled.div`
    flex: 1;
    max-width: 600px;
    margin: 0 30px;
`;

export const HeaderRight = styled.div``;
