import actions from '../../../utils/actions';

const initialState = {
    users: [],
    loading: true,
    LastEvaluatedKey: null,
    nextPageLoading: false,
    isLastPage: false,
};

export default function blockListReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.BLOCK_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case actions.BLOCK_LIST_NEXT_PAGE_REQUEST:
            return {
                ...state,
                nextPageLoading: true,
            };
        case actions.BLOCK_LIST_NEXT_PAGE_REQUEST_SUCCESS:
            return {
                ...state,
                users: [...state.users, ...payload.blockedUsers],
                nextPageLoading: false,
                isLastPage: payload.isLastPage,
            };
        case actions.BLOCK_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                users: payload.blockedUsers.users,
                LastEvaluatedKey: payload.LastEvaluatedKey,
                nextPageLoading: false,
                isLastPage: payload.isLastPage,

            };
        case actions.BLOCK_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                nextPageLoading: false,
            };

        default:
            return state;
    }
}
