import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from '@material-ui/core';
import Loading from '@src/components/Loading';
import Modal from '@src/components/Modal';
import ButtonDefaultTheme from '@src/components/ButtonDefaultTheme';
import { useTranslation } from 'react-i18next';
import langs from '@src/assets/i18n/constants';
import useGames from '@src/hooks/useGames';
import useRooms from '@src/hooks/useRooms';
import {
    getGamificationRules,
    saveGamificationRulesRequest,
    toggleGamificationRequest,
} from '@src/store/modules/gamification/actions';
import attentiondark from '@src/assets/attentiondark.svg';
import GamificationSettingsItem from '../GamificationSettingsItem';
import * as styles from './styles';

const minPermittedPoints = 1;

const SettingsModal = ({ isOpen, handleModal, channelId }) => {
    const { channelsListForSelectSidebar } = useRooms();
    const { gamificationRules, loading } = useGames();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [isGamificationActive, setIsGamificationActive] = useState(false);
    const [punctualDropdownActive, setPunctualDropdownActive] = useState(false);
    const [gameOptions, setGameOptions] = useState([]);

    const [accessRoomSimpleMode, setAccessRoomSimpleMode] = useState(true);
    const [accessRoomEnabled, setAccessRoomEnabled] = useState(false);
    const [accessRoomTolerance, setAccessRoomTolerance] = useState('');
    const [accessRoomMaxPoints, setAccessRoomMaxPoints] = useState('');
    const [answerQuestionEnabled, setAnswerQuestionEnabled] = useState(false);
    const [appLoginEnabled, setAppLoginEnabled] = useState(false);
    const [appLoginMaxPoints, setAppLoginMaxPoints] = useState('');
    const [chatInteractionEnabled, setChatInteractionEnabled] = useState(false);
    const [chatInteractionScore, setChatInteractionScore] = useState('');
    const [attachmentEnabled, setAttachmentEnabled] = useState(false);
    const [attachmentMaxPoints, setAttachmentMaxPoints] = useState('');
    const [gamificationItemSettings, setGamificationItemSettings] = useState('');
    const [settingsValues, setSettingsValues] = useState([]);
    const [fieldError, setFieldError] = useState(false);

    useEffect(() => {
        if (channelId) {
            dispatch(getGamificationRules(channelId || ''));
        }
    }, [dispatch, channelId, isOpen]);

    useEffect(() => {
        const foundChannelGamificationStatus = channelsListForSelectSidebar.find(channel => channel.id === channelId);
        if (foundChannelGamificationStatus) {
            setIsGamificationActive(foundChannelGamificationStatus.gamificationEnabled);
        }
    }, [channelId, channelsListForSelectSidebar, isOpen]);

    useEffect(() => {
        setGameOptions(gamificationRules);
    }, [gamificationRules]);

    useEffect(() => {
        if (gameOptions) {
            gameOptions.forEach((option) => {
                switch (option.event_type) {
                    case 'access_room':
                        setAccessRoomSimpleMode(option.simple_mode);
                        setAccessRoomEnabled(option.enabled);
                        setAccessRoomTolerance(option.tolerance);
                        setAccessRoomMaxPoints(option.max_points);
                        break;
                    case 'answer_question':
                        setAnswerQuestionEnabled(option.enabled);
                        break;
                    case 'app_login':
                        setAppLoginEnabled(option.enabled);
                        setAppLoginMaxPoints(option.max_points);
                        break;
                    case 'chat_interaction':
                        setChatInteractionEnabled(option.enabled);
                        setChatInteractionScore(option.max_points);
                        break;
                    case 'download_attachment':
                        setAttachmentEnabled(option.enabled);
                        setAttachmentMaxPoints(option.max_points);
                        break;
                    default:
                }
            });
        }
    }, [gameOptions]);

    useEffect(() => {
        setGamificationItemSettings({
            accessRoomSimpleMode,
            accessRoomTolerance,
            setAccessRoomTolerance,
            setAccessRoomSimpleMode,
        });
    }, [accessRoomSimpleMode, accessRoomTolerance]);

    useEffect(() => {
        setFieldError(false);
    }, [accessRoomMaxPoints, accessRoomTolerance, appLoginMaxPoints, attachmentMaxPoints]);

    const handleFieldsValidation = () => {
        if (accessRoomEnabled && (!accessRoomMaxPoints || !accessRoomTolerance)) {
            setFieldError(true);
            return true;
        }

        if (accessRoomEnabled && (accessRoomMaxPoints < minPermittedPoints || accessRoomTolerance < minPermittedPoints)) {
            setFieldError(true);
            return true;
        }

        if (appLoginEnabled && appLoginMaxPoints < minPermittedPoints) {
            setFieldError(true);
            return true;
        }

        if (chatInteractionEnabled && chatInteractionScore < minPermittedPoints) {
            setFieldError(true);
            return true;
        }

        if (attachmentEnabled && attachmentMaxPoints < minPermittedPoints) {
            setFieldError(true);
            return true;
        }
        return false;
    };

    const handleSave = () => {
        const hasError = handleFieldsValidation();
        if (hasError) return;

        setFieldError(false);
        dispatch(toggleGamificationRequest(channelId, isGamificationActive));
        if (isGamificationActive) {
            dispatch(saveGamificationRulesRequest(settingsValues, channelId));
        }
    };

    useEffect(() => {
        if (!gameOptions?.length) {
            setAccessRoomSimpleMode(true);
            setAccessRoomEnabled(false);
            setAccessRoomTolerance('');
            setAccessRoomMaxPoints('');
            setAnswerQuestionEnabled(false);
            setAppLoginEnabled(false);
            setAppLoginMaxPoints('');
            setChatInteractionEnabled(false);
            setChatInteractionScore('');
            setAttachmentEnabled(false);
            setAttachmentMaxPoints('');
        }
    }, [isOpen, gameOptions]);

    const handleGamificationActive = () => {
        setIsGamificationActive(!isGamificationActive);
    };

    const handlePunctualDroDown = () => {
        setPunctualDropdownActive(!punctualDropdownActive);
    };

    useEffect(() => {
        setSettingsValues([
            {
                event_type: 'access_room',
                enabled: accessRoomEnabled,
                simple_mode: accessRoomSimpleMode,
                tolerance: accessRoomEnabled ? Number(accessRoomTolerance) : 1,
                max_points: accessRoomEnabled ? Number(accessRoomMaxPoints) : minPermittedPoints,
            },
            {
                event_type: 'answer_question',
                enabled: answerQuestionEnabled,
            },
            {
                event_type: 'app_login',
                enabled: appLoginEnabled,
                max_points: appLoginEnabled ? Number(appLoginMaxPoints) : minPermittedPoints,
            },
            {
                event_type: 'chat_interaction',
                enabled: chatInteractionEnabled,
                max_points: chatInteractionEnabled ? Number(chatInteractionScore) : minPermittedPoints,
            },
            {
                event_type: 'download_attachment',
                enabled: attachmentEnabled,
                max_points: attachmentEnabled ? Number(attachmentMaxPoints) : minPermittedPoints,
            },

        ]);
    }, [
        accessRoomEnabled, accessRoomMaxPoints, accessRoomSimpleMode, accessRoomTolerance,
        answerQuestionEnabled, appLoginEnabled, appLoginMaxPoints, attachmentEnabled, attachmentMaxPoints,
        chatInteractionScore, chatInteractionEnabled,
    ]);

    const GAMIFICATION_SETTINGS_TYPE = {
        accessRoomType: 'accessRoomType',
        downloadAttachmentType: 'downloadAttachmentType',
        loginAppType: 'loginAppType',
        chatInteractionType: 'chatInteractionType',
        quizzType: 'quizzType',
    };

    return (
        <Modal
            isOpen={isOpen && channelId}
            bodyWidth={100}
            handleModal={handleModal}
            title={t(langs.GAMIFICATION_SETTINGS)}
            width={976}
        >
            <styles.SettingsModalContainer>
                {loading && <Loading />}
                <styles.GamificationSettingsArea>
                    <styles.GamificationToggling isActive={isGamificationActive}>
                        <Switch
                            checked={isGamificationActive}
                            onChange={handleGamificationActive}
                        />
                        <p>
                            {t(langs.GAMIFICATION).toUpperCase()} {isGamificationActive ? <strong>{t(langs.ENABLED)}</strong> : <strong>{t(langs.GAMIFICATION_DISABLED).toUpperCase()}</strong> }
                        </p>
                    </styles.GamificationToggling>

                    <styles.SettingsModalContent isGamificationActive={isGamificationActive}>
                        <GamificationSettingsItem
                            sectionTitle={t(langs.PUNCTUALITY)}
                            punctualDropdownActive={punctualDropdownActive}
                            setPunctualDropdownActive={handlePunctualDroDown}
                            gamificationItemSettings={gamificationItemSettings}
                            isChecked={accessRoomEnabled}
                            handleSwitchChange={() => setAccessRoomEnabled(oldState => !oldState)}
                            pointsValue={accessRoomMaxPoints}
                            handlePointsChange={setAccessRoomMaxPoints}
                            settingsType={GAMIFICATION_SETTINGS_TYPE.accessRoomType}

                        />
                        <GamificationSettingsItem
                            sectionTitle={t(langs.DOWNLOAD_ATTACHMENTS)}
                            isChecked={attachmentEnabled}
                            handleSwitchChange={() => setAttachmentEnabled(oldState => !oldState)}
                            pointsValue={attachmentMaxPoints}
                            handlePointsChange={setAttachmentMaxPoints}
                            settingsType={GAMIFICATION_SETTINGS_TYPE.downloadAttachmentType}
                        />
                        <GamificationSettingsItem
                            sectionTitle={t(langs.APP_LOGIN)}
                            isChecked={appLoginEnabled}
                            handleSwitchChange={() => setAppLoginEnabled(oldState => !oldState)}
                            pointsValue={appLoginMaxPoints}
                            handlePointsChange={setAppLoginMaxPoints}
                            settingsType={GAMIFICATION_SETTINGS_TYPE.loginAppType}
                        />
                        <GamificationSettingsItem
                            sectionTitle={t(langs.CHAT_INTERACTION)}
                            isChecked={chatInteractionEnabled}
                            handleSwitchChange={() => setChatInteractionEnabled(oldState => !oldState)}
                            pointsValue={chatInteractionScore}
                            handlePointsChange={setChatInteractionScore}
                            settingsType={GAMIFICATION_SETTINGS_TYPE.chatInteractionType}
                        />
                        <GamificationSettingsItem
                            sectionTitle={t(langs.QUIZZES_SURVEYS).toUpperCase()}
                            isChecked={answerQuestionEnabled}
                            handleSwitchChange={() => setAnswerQuestionEnabled(oldState => !oldState)}
                            settingsType={GAMIFICATION_SETTINGS_TYPE.quizzType}
                        />
                    </styles.SettingsModalContent>
                    <styles.ErrorContainer>
                        {fieldError && (
                            <>
                                <img src={attentiondark} alt="att" />
                                <p>Preencha todos os campos corretamente</p>
                            </>

                        )}
                    </styles.ErrorContainer>
                    <styles.GamificationButtonSaveArea>
                        <styles.GamificationButtonSave>
                            <ButtonDefaultTheme dataTestId="gamification-save-btn" onClick={handleSave}>
                                SALVAR
                            </ButtonDefaultTheme>
                        </styles.GamificationButtonSave>
                    </styles.GamificationButtonSaveArea>
                </styles.GamificationSettingsArea>
            </styles.SettingsModalContainer>
        </Modal>
    );
};

export default SettingsModal;
