import styled from 'styled-components';

export const Form = styled.form`
    padding: 20px 30px;
    max-height: 85vh;
    overflow-y: scroll;

    .MuiFormControl-root {
        margin-top: 10px;
        width: 100%;
    }

    .MuiInputBase-root {
        textarea {
            height: 75px;
            resize: none;
        }
    }

    .MuiFormLabel-root {
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;        

        &.Mui-focused {
            color: #ff6478;
        }
    }

    .switch-wrapper {
        height: 49px;
        border-radius: 6px;
        background-color: #111130;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 18px;
        
        .MuiFormControlLabel-label {
            margin-top: 2px;        
            font-size: 12px;
            font-weight: bold;            
            color: #ffffff;
        }

        .MuiSwitch-thumb {
                position: relative;                   
                    &::before {
                    content: 'DES';
                    position: absolute;
                    color: #fff;
                    font-size: 7px;
                    top: 5px;
                    left: 3px;
                    font-weight: bold;
                }        
            }

        .Mui-checked {
            .MuiSwitch-thumb {
                position: relative;                   
                    &::before {
                    content: 'PUB';                  
                }        
            }
        }
    }

    .submit-button-wrapper {
        padding: 7px 25px;
    }

    .quiz-form-control-type {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        position: relative;
        z-index: 1;
    }

    .Dropdown-root {
        width: 100%;
    }

    .Dropdown-control {
        background-color: #24243e;
        border: none;
        border-radius: 8px;
        box-sizing: border-box;
        color: #fff;
        font-weight: bold;    
        height: 41px;
        justify-content: center;
        align-items: center;    
    }

    .Dropdown-arrow {
        border-color: #fff transparent transparent;
        top: 18px;
    }

    .Dropdown-menu {
        background-color: #24243e;  
        border: none;          
    }

    .Dropdown-option {
        color: #fff;
        font-weight: bold;  
        &:hover {
            background-color: #34344c;
        }
    }

    .Dropdown-option.is-selected {
        background-color: #2c2c4a;
        color: #fff;
    }

    .--warn-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;

        h5 {
            font-size: 18px;
            color: #fff;
            text-align: center
        }

        button {
            border: none;
            color: #FFF;
            background: linear-gradient(90deg,#FFAC47 0%, #FF438D 100%);
            width: 300px;
            height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-weight: 600;
            border-radius: 8px;  
            font-weight: bold;
            text-transform: uppercase; 
            opacity: 0.8;
            transition: all 0.1s ease-in-out;

            &:hover {
                box-shadow: 0px 0px 10px -4px #000000;
                opacity: 1;
                transform: scale(1.02);
            }
        }

    }
    
    .MuiInputLabel-animated.MuiInputLabel-shrink {
        font-size: 14px;
        small {
                color: #ff9754;
                font-size: 11px;
                margin-left: 8px;
                text-transform: uppercase;
        }
    }
    .Mui-disabled.Mui-disabled.MuiFormLabel-filled {
        small {
            opacity: 0.4;
        }
    }

    .MuiInputBase-input.Mui-disabled {
        opacity: 0.4;
    }

    .MuiFormLabel-root.Mui-disabled {
        color: #dddddd78;
    }
    .Dropdown-disabled {
        opacity: 0.4;
    }
`;

export const SubmitButton = styled.button`
    border: none;
    color: #FFF;
    background: linear-gradient(90deg,#FFAC47 0%, #FF438D 100%);
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border-radius: 8px;  
    font-weight: bold;
    text-transform: uppercase; 
    opacity: 0.8;
    transition: all 0.1s ease-in-out;

    &:hover {
        box-shadow: 0px 0px 10px -4px #000000;
        opacity: 1;
        transform: scale(1.02);
    }

    &:disabled {
        opacity: 0.3;
        transform: none;
    }
`;
