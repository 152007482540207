import CONSTANTS from '../constants';

export default {
    [CONSTANTS.ACTIVE]: 'Active',
    [CONSTANTS.ACCEPTTERMS]: 'I accept the terms of use',
    [CONSTANTS.ALREADY_LOGED_IN]: 'You are already loged in other machine.',
    [CONSTANTS.ANSWER_DIFFERENT_MANDATORY]: 'The answers must be different!',
    [CONSTANTS.ALL_ANSWERS]: 'ALL ANSWERS',
    [CONSTANTS.APP_LOGIN]: 'USE APP',
    [CONSTANTS.APPLY]: 'Apply',
    [CONSTANTS.ATTACHMENTS]: 'Attachments',
    [CONSTANTS.AT_LEAST_3_CHARS]: 'AT LEAST 3 CHARACTERS',
    [CONSTANTS.BACKTO]: 'Back to',
    [CONSTANTS.BACK_INITIAL_PAGE]: 'BACK TO INITIAL PAGE',
    [CONSTANTS.BACK_TO_GUEST_PRESENTER_LIST]: 'BACK TO GUEST LIST',
    [CONSTANTS.BITRATE]: 'Bitrate',
    [CONSTANTS.BLOCK_USER_BY_CHAT]: 'Use chat to block a user.',
    [CONSTANTS.BUFFER_CLASSIFICATION]: 'Buffer Classification',
    [CONSTANTS.BUFFER_CLASSIFICATION_ABREVIATION]: 'Buf. Classification',
    [CONSTANTS.DISABLED_CAMERA]: 'Camera Disabled',
    [CONSTANTS.CANCEL]: 'Cancel',
    [CONSTANTS.CANT_GOLIVE_YET]: 'The room is not available yet, please wait',
    [CONSTANTS.CLICK_TO_ENDLIVE]: 'Click to end live',
    [CONSTANTS.CLICK_TO_GOLIVE]: 'Click to start live',
    [CONSTANTS.CHANGES_SUCCESS]: 'Changes successfully saved',
    [CONSTANTS.CHANGES_FAIL]: 'Failed to save changes',
    [CONSTANTS.CHANNEL_ACCEPTED_FORMATS]: 'Accepted formats',
    [CONSTANTS.CHANNEL_ADDNEWVIDEO]: 'Add video',
    [CONSTANTS.CHANNEL_ADDNEWLIVEVIDEO]: 'Live | Live',
    [CONSTANTS.CHANNEL_ADDNEWRECORDEDVIDEO]: 'Video upload',
    [CONSTANTS.CHANNEL_CHAT_ENABLED]: 'CHAT',
    [CONSTANTS.CHANNEL_CLEANVIDEO]: 'Clear video',
    [CONSTANTS.CHANNEL_COPIED]: 'Copied',
    [CONSTANTS.CHANNEL_CREATEVIDEO]: 'Create video',
    [CONSTANTS.CHANNEL_CREATEVIDEO2]: 'Create new video',
    [CONSTANTS.CHANNEL_EDITVIDEO]: 'Edit video',
    [CONSTANTS.CHANNEL_ENDLIVE]: 'End live!',
    [CONSTANTS.CHANNEL_ENDLIVEDESCRIPTION]: 'Do you really want to end the live?',
    [CONSTANTS.CHANNEL_ENDLIVEIRREVERSIBLE]: 'This operation cannot be undone',
    [CONSTANTS.CHANNEL_ENDDATE]: 'DATE AND END TIME',
    [CONSTANTS.CHANNEL_FILES]: 'File',
    [CONSTANTS.CHANNEL_FILEFORMAT]: 'Files must have the following formats: PPT, PPTX, DOC, DOCX, PDF, JPG, PNG',
    [CONSTANTS.CHANNEL_GOLIVE]: 'Go live!',
    [CONSTANTS.CHANNEL_GOLIVE_UNAVAILABLE]: 'Go live unavailable!',
    [CONSTANTS.CHANNEL_MYVIDEOS]: 'My videos',
    [CONSTANTS.CHANNEL_NEWVIDEO]: 'New Video',
    [CONSTANTS.CHANNEL_NOVIDEO]: 'No videos registered at the moment',
    [CONSTANTS.CHANNEL_PRIMARYURL]: 'PRIMARY URL',
    [CONSTANTS.CHANNEL_PRIMARY_PASSWORD]: "PRIMARY URL's PASSWORD",
    [CONSTANTS.CHANNEL_SECONDARY_PASSWORD]: "SECONDARY URL's PASSWORD",
    [CONSTANTS.CHANNEL_PRODUCER_LINK]: 'Producer link',
    [CONSTANTS.CHANNEL_PRODUCER_BACKUP_LINK]: 'Link backup link',
    [CONSTANTS.CHANNEL_ROOMNOTFOUND]: 'Room not found',
    [CONSTANTS.CHANNEL_ROOM_LOAD_DATA_ERROR]: 'There was an error loading the data.',
    [CONSTANTS.CHANNEL_SECONDARYURL]: 'SECONDARY URL',
    [CONSTANTS.CHANNEL_SELECTCOURSE]: 'THE VIDEO BELONGS TO EVENT',
    [CONSTANTS.CHANNEL_SELECTCOURSEPLACEHOLDER]: 'Select a course',
    [CONSTANTS.CHANNEL_SELECTTIMEZONE]: 'SELECT THE TIMEZONE',
    [CONSTANTS.CHANNEL_SENDVIDEO]: 'Upload video',
    [CONSTANTS.CHANNEL_STARTDATE]: 'DATE AND START TIME',
    [CONSTANTS.CHANNEL_THUMBNAIL]: 'Thumbnail',
    [CONSTANTS.CHANNEL_THUMBNAILSIZE]: 'Minimum size 300x300',
    [CONSTANTS.CHANNEL_UPLOADFILES]: 'UPLOAD FILES',
    [CONSTANTS.CHANNEL_UPLOADVIDEO]: 'VIDEO UPLOAD',
    [CONSTANTS.CHANNEL_UPLOADSPREADSHEET]: 'UPLOAD SPREADSHEET',
    [CONSTANTS.CHANNEL_VIDEOCOVER]: 'VIDEO COVER',
    [CONSTANTS.CHANNEL_VIDEODESCRIPTION]: 'VIDEO DESCRIPTION',
    [CONSTANTS.CHANNEL_VIDEOFORMAT]: 'The video must have the following formats: MP4, MKV',
    [CONSTANTS.CHANNEL_VIDEOTITLE]: 'VIDEO TITLE',
    [CONSTANTS.VIDEO_STATS]: 'Video stats',
    [CONSTANTS.CHAT_INTERACTION]: 'CHAT INTERACTION',
    [CONSTANTS.CHAT_INTERACTION_RULES]: 'The chat interaction rule refers to the score earned by the participant when performing their first interaction in the live chat. Remember, the score is for the first interaction only.',
    [CONSTANTS.CHUNK_UPLOAD_FAILURE]: 'Failed to upload to [ROOM_NAME]. Data: [ERROR_DATA]',
    [CONSTANTS.COMEBACK]: 'Return',
    [CONSTANTS.CONFIRM]: 'Confirm',
    [CONSTANTS.CONTENT_DESCRIPTION_PLACEHOLDER]: 'Write the content of your description here',
    [CONSTANTS.CORRECT_ANSWER_MANDATORY]: 'The "CORRECT ANSWER" field is required!',
    [CONSTANTS.CREATE_ROOM_SUCCESS]: 'Video created successfully',
    [CONSTANTS.DISABLE_QUESTION]: 'DISABLE?',
    [CONSTANTS.DISABLED_CAPS]: 'DISABLED',
    [CONSTANTS.DOWNLOAD_ATTACHMENTS]: 'DOWNLOAD ATTACHMENTS',
    [CONSTANTS.DOUBTS]: 'Doubts',
    [CONSTANTS.DURATION_MANDATORY]: 'The "DURATION" field is required!',
    [CONSTANTS.DURATION_NUMERIC]: 'The "DURATION" field must be numeric!',
    [CONSTANTS.EDIT]: 'Edit',
    [CONSTANTS.EDIT_ROOM_FAIL]: 'Failed to edit room',
    [CONSTANTS.EDIT_VIDEO_COVER]: 'EDIT COVER',
    [CONSTANTS.EDIT_CONTENT]: 'Edit Content',
    [CONSTANTS.VIEW_CONTENT]: 'View Content',
    [CONSTANTS.ENABLE_QUESTION]: 'ENABLE?',
    [CONSTANTS.ENABLED]: 'ENABLED',
    [CONSTANTS.ENABLED_CAPS]: 'ENABLED',
    [CONSTANTS.ENROLLED]: 'Enrolled',
    [CONSTANTS.ENDDATE]: 'End',
    [CONSTANTS.ENGLISH]: 'English',
    [CONSTANTS.EMAIL]: 'EMAIL',
    [CONSTANTS.ENTER_TITLE]: 'Enter the Title',
    [CONSTANTS.ERROR404]: 'Error 404',
    [CONSTANTS.ERROR_CHANGEPASSWORD]: 'Failed to change password, error status: ',
    [CONSTANTS.ERROR_CREDENTIALS]: 'Access credentials invalid, expired or nonexistent',
    [CONSTANTS.ERROR_PARAMETERS]: 'One or more input parameters are invalid',
    [CONSTANTS.ERROR_DEFAULT]: 'Unexpected server error',
    [CONSTANTS.ERROR_EMAILNOTFOUND]: 'Email not found',
    [CONSTANTS.ERROR_LOGIN]: 'Login or password incorrect',
    [CONSTANTS.ERROR_LOGINFAIL]: 'Failed to login, error status:',
    [CONSTANTS.ERROR_NONETWORK]: 'No internet access',
    [CONSTANTS.ERROR_RECOVERPASSFAIL]: 'Fail to restore password, error status: ',
    [CONSTANTS.FAIL_CHANGELANGUAGE]: 'Failed to perform language change',
    [CONSTANTS.FAIL_CHANGEOFFERSTATUS]: 'Failed to change offer status',
    [CONSTANTS.FAIL_CHATNOTFOUND]: 'Chat Room not found',
    [CONSTANTS.FAIL_CONNECTWEBSOCKET]: 'Failed to connect to websocket',
    [CONSTANTS.FAIL_CREATEOFFER]: 'Failed to create offer',
    [CONSTANTS.FAIL_CREATETEAMS]: 'Failed to create teams',
    [CONSTANTS.FAIL_ENTERROOM]: 'Failed to enter the room',
    [CONSTANTS.FAIL_EXITROOM]: 'Failed to exit the room',
    [CONSTANTS.FAIL_GETCHAT]: 'Chat room not found',
    [CONSTANTS.FAIL_GETOFFER]: 'Offer request failed',
    [CONSTANTS.FAIL_GETTEAMSSTATUS]: 'Failed to request team status',
    [CONSTANTS.FAIL_GETTEAMS]: 'Failed to request teams',
    [CONSTANTS.FAIL_LOGOUT]: 'Failed to log out',
    [CONSTANTS.FAIL_PINMESSAGE]: 'Failed to pin message',
    [CONSTANTS.FAIL_SETTEAMSSTATUS]: 'Failed to change team status',
    [CONSTANTS.FAIL_UNPINMESSAGE]: 'Failed to unpin message',
    [CONSTANTS.FILL_FIELDS_ADD_USER]: 'Fill in all fields to add user',
    [CONSTANTS.FILTER_FIRST]: 'Select the first filter',
    [CONSTANTS.FILTER_SECOND]: 'Select the second filter',
    [CONSTANTS.FILTER_SPECIFIC]: 'Date range',
    [CONSTANTS.FILTER_PRESETOPTIONS]: ['1 minute', '5 minutes', '30 minutes', '1 hour', '3 hours', '6 hours', '24 hours', '3 days', '7 days'],
    [CONSTANTS.FILTER_TIME]: 'Select time',
    [CONSTANTS.FILTER_NAME_ID]: 'FILTER NAME OR ID',
    [CONSTANTS.FLOW_PRIMARY]: 'Primary Flow',
    [CONSTANTS.FLOW_SECONDARY]: 'Secundary Flow',
    [CONSTANTS.FORGETPASSWORD]: 'FORGOT MY PASSWORD',
    [CONSTANTS.FROM_TIME]: 'From',
    [CONSTANTS.FULL_SCREEN]: 'Full Screen',
    [CONSTANTS.GAMIFICATION]: 'Gamification',
    [CONSTANTS.GAMIFICATION_DISABLED]: 'Disabled',
    [CONSTANTS.GAMIFICATION_DOWNLOAD_RULES]: 'The attachment download rule refers to the \n score earned by the participant when downloading each video attachment.',
    [CONSTANTS.GAMIFICATION_PUNCTUALITY_MODE_RULE]: `Simple mode: The participant earns the score by entering during the grace period. After the grace period, the participant does not earn any points.
    Gradual mode: The participant earns the maximum score by entering the exact time of the start of the live. When entering the live after the grace period has elapsed, the participant will not earn the maximum score.
    Granularity calculation: maximum points / tolerance time (in minutes)`,
    [CONSTANTS.GAMIFICATION_ACCESS_ROOM_RULE]: `The punctuality rule is aimed at participants who enter at the time the live starts or during the grace period.
    Punctuality can be measured using simple or gradual mode.`,
    [CONSTANTS.GAMIFICATION_LOGIN_APP_RULES]: 'The App Login rule refers to the score earned by the participant when logging into the Kopa app.',
    [CONSTANTS.GAMIFICATION_POINTS_NUMERIC]: 'Use numeric values',
    [CONSTANTS.GAMIFICATION_QUIZZ_RULE]: `Quizz: Refers to the points that the participant obtains by correctly answering a quiz during the live.
    Surveys: Refers to the points that the participant obtains simply for answering a survey during the live.`,
    [CONSTANTS.GAMIFICATION_SETTINGS]: 'GAMIFICATION SETTINGS',
    [CONSTANTS.GAMIFICATION_SETTINGS_QUIZZ_TIP_A]: 'The configuration of gamification points of',
    [CONSTANTS.GAMIFICATION_SETTINGS_QUIZZ_TIP_B]: 'is on the screen of',
    [CONSTANTS.GAMIFICATION_RULES_REQUEST_FAIL]: 'Failed to get gamification rules',
    [CONSTANTS.GENERAL]: 'General',
    [CONSTANTS.GEOLOCATION]: 'Geolocation',
    [CONSTANTS.GRADUAL_MODE]: 'GRADUAL MODE',
    [CONSTANTS.GRAPH_AVG_VIEWBITRATE]: 'Avg Viewing Bitrate',
    [CONSTANTS.GRAPH_AVG_VIEWDOWNSTREAMBANDWIDTH]: 'Avg Viewing Downstream Bandwidth',
    [CONSTANTS.GRAPH_AVG_VIEWDROPPEDFRAMESRATIO]: 'Avg Viewing Dropped Frames Ratio',
    [CONSTANTS.GRAPH_AVG_VIEWLIVELATENCY]: 'Avg Viewing Live Latency',
    [CONSTANTS.GRAPH_SUMVIEWTIME]: 'Sum Viewing Time',
    [CONSTANTS.GRAPH_VIEWERS]: 'Viewers',
    [CONSTANTS.GRAPH_VIEWBUFFERTIMERATIO]: 'Viewing Buffer time ratio',
    [CONSTANTS.GRAPH_VIEWERSBUFFERING]: 'Viewers Buffering',
    [CONSTANTS.GRAPH_VIEWERSENGAGEMENT]: 'Viewers Engagement',
    [CONSTANTS.GRAPH_VIEWERSDVR]: 'Viewers DVR',
    [CONSTANTS.HOME]: 'Home',
    [CONSTANTS.HOMEPAGE]: 'Homepage',
    [CONSTANTS.HOME_WELCOME]: "IT'S A PLEASURE TO HAVE YOU HERE WITH US",
    [CONSTANTS.HOME_NOLIVE]: 'Sorry, we currently have no live in progress.',
    [CONSTANTS.HOURS]: 'Hours.',
    [CONSTANTS.INACTIVE]: 'Inactive',
    [CONSTANTS.INVALID_IMG_TYPE]: 'Invalid type. The file must be an image.',
    [CONSTANTS.INITIAL_DATE_CANT_CHANGE]: 'The initial date of this room cannot be changed.',
    [CONSTANTS.INSERT_VIDEO_THUMBNAIL]: 'Insert the video thumbnail!',
    [CONSTANTS.FINISHED]: 'Unavailable',
    [CONSTANTS.LANGUAGE]: 'Language',
    [CONSTANTS.LAST]: 'Last',
    [CONSTANTS.LIVE]: 'Live',
    [CONSTANTS.LIVE_UNAVAILABLE]: 'Live unavailable',
    [CONSTANTS.RECORDED]: 'Recorded',
    [CONSTANTS.LOADING]: 'Loading',
    [CONSTANTS.LOADINGCAPS]: 'LOADING',
    [CONSTANTS.LOADINGMESSAGELINK]: 'Please wait while the stream links are created...',
    [CONSTANTS.LOADINGPDF]: 'Loading PDF',
    [CONSTANTS.LOGIN]: 'Login',
    [CONSTANTS.LOGIN_EMAIL]: 'EMAIL',
    [CONSTANTS.LOGIN_PASSWORD]: 'PASSWORD',
    [CONSTANTS.LOGIN_SIGNIN]: 'SIGN IN',
    [CONSTANTS.MAX_SCORE]: 'MAX SCORE',
    [CONSTANTS.MIN_SCORE]: 'Minimum score point is 10',
    [CONSTANTS.MAXIMUM_IMAGE_SIZE]: 'Maximum image size: 300x300',
    [CONSTANTS.MINIMUM_IMAGE_SIZE]: 'Minimum size: 300x300',
    [CONSTANTS.MESSAGE]: 'Message',
    [CONSTANTS.MINUTES]: 'MINUTES',
    [CONSTANTS.MUTED_PARTICIPANT_ERROR]: 'Failed to mute the participant',
    [CONSTANTS.MUTED_USER]: 'User is muted',
    [CONSTANTS.HOUR_ERROR_MESSAGE]: 'The HOUR of termination must be greater than the current HOUR.',
    [CONSTANTS.MONITORS]: 'Monitors',
    [CONSTANTS.NAMECAPS]: 'NAME',
    [CONSTANTS.NEXT_STEP]: 'Next Step',
    [CONSTANTS.NO]: 'No',
    [CONSTANTS.NO_USER_REGISTERED_CHECK_XLSX]: 'NO USER HAS BEEN REGISTERED! CHECK YOUR XLSX FILE.',
    [CONSTANTS.NO_USERS_SHOW]: 'No users to display',
    [CONSTANTS.NO_QUIZ_SURVEY_REGISTERED]: 'NO QUIZ OR SURVEY REGISTERED',
    [CONSTANTS.NO_QUIZ_SURVEY_REGISTERED_MESSAGE]: 'Haven`t registered any quiz or survey yet? It`s simple, easy and only takes a few minutes.',
    [CONSTANTS.NO_USER_BLOCKED]: 'NO USER BLOCKED',
    [CONSTANTS.NO_USER_CONNECTED]: 'NO USER CONNECTED',
    [CONSTANTS.NO_USER_FOUND]: 'NO USER FOUND',
    [CONSTANTS.NO_USER_FOUND_MESSAGE]: 'Sorry! We didn t find the user you were looking for.',
    [CONSTANTS.NO_USERS_ROOM]: 'No users in the room',
    [CONSTANTS.NO_PARTICIPANT_REPORT]: 'There is no participant in the presence report',
    [CONSTANTS.LIVE_ENDED]: 'Live ended',
    [CONSTANTS.NEXTPAGE]: 'Next page',
    [CONSTANTS.OF]: 'of',
    [CONSTANTS.OFFERS]: 'QR CODE',
    [CONSTANTS.OFFERS_ACTIVATEOFFERS]: 'Active QR CODE',
    [CONSTANTS.OFFERS_BASEURL]: 'Insert the template url',
    [CONSTANTS.OFFERS_CREATEOFFER]: 'Create QR CODE',
    [CONSTANTS.OFFERS_EDITOFFER]: 'Edit QR CODE',
    [CONSTANTS.OFFERS_ENABLECONFIRM]: 'Do you want to activate the QR CODE?',
    [CONSTANTS.OFFERS_MODEOFFERSINACTIVATE]: 'QR CODES are disabled',
    [CONSTANTS.OFFERS_NOOFFERS]: 'No QR CODE registered',
    [CONSTANTS.OFFERS_SALESFORCEID]: 'Salesforce Id',
    [CONSTANTS.OFFERS_SELECTOR]: 'Select the type of template ID',
    [CONSTANTS.OFFERS_TEMPLATEURL]: 'Template url',
    [CONSTANTS.OFFERS_TYPEID]: 'Id type',
    [CONSTANTS.OFFERS_WARN]: 'Attention: QR CODE is only available for LIVE content / videos ',
    [CONSTANTS.PARTICIPANT_WITHOUT_GAMIFICATION]: 'At the moment, none of the participants have generated gamification points.',
    [CONSTANTS.PARTICIPANT_NOT_FOUND]: 'PARTICIPANT NOT FOUND',
    [CONSTANTS.PARTICIPANT_NOT_FOUND_MESSAGE]: 'Sorry! We couldn`t find the participant you were looking for.',
    [CONSTANTS.PARTICIPANT_OUT_LIST]: 'At the moment, the participant is not on the presence report of this room',
    [CONSTANTS.PUNCTUALITY]: 'PUNCTUALITY',
    [CONSTANTS.PUNCTUALITY_MODE]: 'PUNCTUALITY MODE',
    [CONSTANTS.QUESTION_MANDATORY]: 'The "QUESTION" field is required!',
    [CONSTANTS.TYPE_QUESTION]: 'Type your question',
    [CONSTANTS.TITLE_MANDATORY]: 'The "TITLE" field is mandatory!',
    [CONSTANTS.USERNAME_MIN]: 'Username must be greater than 5 characters',
    [CONSTANTS.VALID_DATE]: 'Please enter a valid date',
    [CONSTANTS.VALID_EMAIL]: 'Enter a valid email.',
    [CONSTANTS.VALID_PHONE]: 'Please enter a valid phone number',
    [CONSTANTS.VOUCHER_LOGIN]: 'Register with your voucher',
    [CONSTANTS.VOUCHER_PASSWORD_CREATION_TITLE]: 'PASSWORD CREATION',
    [CONSTANTS.VOUCHER_CODE_TITLE]: 'VOUCHER CODE',
    [CONSTANTS.VOUCHER_PASSWORD_LABEL]: 'PASSWORD',
    [CONSTANTS.VOUCHER_PASSWORD_CONFIRM_LABEL]: 'CONFIRM PASSWORD',
    [CONSTANTS.VOUCHER_PASSWORD_SAME]: 'Passwords must be the same',
    [CONSTANTS.VOUCHER_PASSWORD_MIN]: 'Password must be at least 6 characters',
    [CONSTANTS.VOUCHER_FINALIZE_REGISTRATION]: 'Finalize registration',
    [CONSTANTS.VOUCHER_CODE_SUBTITLE]: 'Insert your voucher code here',
    [CONSTANTS.VOUCHER_BUTTON_SUBMIT]: 'VALIDATE VOUCHER',
    [CONSTANTS.VOUCHER_REGISTER_TITLE]: 'SUCCESSFUL REGISTRATION',
    [CONSTANTS.VOUCHER_REGISTER_MESSAGE_SUCCESS]: 'Su registro fue exitoso, ahora lo redireccionaremos a la página de inicio',
    [CONSTANTS.VOUCHER_TERMS]: ['O Usuário está ciente de que fornece informação de forma consciente e voluntária por meio de [FORMULÁRIO/ETC], ou por meio dos sites operados pela LBCA.',
        'O Usuário está ciente de que fornece informação de forma consciente e voluntária por meio de [FORMULÁRIO/ETC], ou por meio dos sites operados pela LBCA.'],
    [CONSTANTS.VOUCHER_TERMS_ACEPPT]: 'I ACCEPT THE TERMS OF USE',
    [CONSTANTS.VOUCHER_TERMS_TITLE]: 'TERMS OF USE',
    [CONSTANTS.VOUCHER_TERMS_VALIDATION]: 'Accept the terms to proceed',
    [CONSTANTS.VOUCHER_ACEPPT_TERMS_PRIVACY_PT1]: 'I ACCEPT THE ',
    [CONSTANTS.VOUCHER_ACEPPT_TERMS_PRIVACY_PT2]: 'PRIVACY POLICY',
    [CONSTANTS.VOUCHER_NEXT_STEP_BUTTON]: 'Next step',
    [CONSTANTS.VOUCHER_USERDATA_TITLE]: 'PERSONAL DATA',
    [CONSTANTS.VOUCHER_USERDATA_FILL]: 'Fill in all the data',
    [CONSTANTS.VOUCHER_USERDATA_FULL_NAME]: 'FULL NAME',
    [CONSTANTS.VOUCHER_USERDATA_FULL_NAME_CC]: 'Full Name',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL]: 'E-MAIL',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL_CONFIRM]: 'E-MAIL CONFIRM',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL_DIFFERS]: 'Email addresses are not the same',
    [CONSTANTS.VOUCHER_USERDATA_DATE_OF_BIRTH]: 'DATE OF BIRTH',
    [CONSTANTS.VOUCHER_USERDATA_GENDER]: 'GENDER',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_MALE]: 'Male',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_FEM]: 'Feminine',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_OTHER]: 'Other',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_SELECT]: 'Select gender',
    [CONSTANTS.VOUCHER_USERDATA_INVALID_DATE]: 'Invalid date of birth',
    [CONSTANTS.VOUCHER_USERDATA_PHONE]: 'PHONE',
    [CONSTANTS.VOUCHER_USERDATA_PHONE_PATTERN]: 'Use only numbers in the phone field',
    [CONSTANTS.VOUCHER_USERDATA_TYPE_FULL_NAME]: 'User full name',
    [CONSTANTS.OPS]: 'Ops!',
    [CONSTANTS.PERMISSION_ENTERCHAT]: 'You are not allowed to enter this chat room',
    [CONSTANTS.PERMISSION_PINMESSAGE]: 'You are not allowed to pin a message in this chat room',
    [CONSTANTS.PERMISSION_UNPINMESSAGE]: 'You are not allowed to unpin messages in this chat room',
    [CONSTANTS.PAGE]: 'Page',
    [CONSTANTS.PLEASECLOSE]: 'Please, close',
    [CONSTANTS.PORTUGUESE]: 'Portuguese',
    [CONSTANTS.PRESET]: 'Preset',
    [CONSTANTS.PREVIOUSPAGE]: 'Previous page',
    [CONSTANTS.RECOVERPASSWORD_MINLENGTH]: 'The password must be at least 8 digits',
    [CONSTANTS.RECOVERPASSWORD_NEWPASSWORD]: 'NEW PASSWORD',
    [CONSTANTS.RECOVERPASSWORD_NEWPASSWORDREPEAT]: 'REPEAT NEW PASSWORD',
    [CONSTANTS.RECOVERPASSWORD_NOLINK]: 'The link is no longer available',
    [CONSTANTS.RECOVERPASSWORD_NOMATCH]: 'Passwords do not match',
    [CONSTANTS.RECOVERPASSWORD_REDEFINE]: 'REDEFINE PASSWORD',
    [CONSTANTS.RECOVERPASSWORD_SUCCESS]: 'Password successfully reset',
    [CONSTANTS.RECOVERPASSWORD_VERIFY]: 'CHECK YOUR EMAIL',
    [CONSTANTS.RECOVERPASSWORD_VERIFYDESC]: 'An email for you to change the password will be sent to your email box.',
    [CONSTANTS.REGISTER_QUIZ_SURVEY]: 'REGISTER QUIZ OR SURVEY',
    [CONSTANTS.REQUIRED_FIELD]: 'REQUIRED FIELD',
    [CONSTANTS.SAVE_GAMIFICATION_RULES_SUCCESS]: 'Rules have been saved successfully',
    [CONSTANTS.SAVE_GAMIFICATION_RULES_FAIL]: 'Rules were not saved successfully',
    [CONSTANTS.SCORE]: 'SCORE',
    [CONSTANTS.SELECTED]: 'SELECTED',
    [CONSTANTS.STATUS]: 'Status',
    [CONSTANTS.STATUS_LIVE]: 'Live status',
    [CONSTANTS.SEARCH]: 'Search',
    [CONSTANTS.SEARCH_MIN_CHARACTERS]: 'Enter at least 3 characters to searching',
    [CONSTANTS.SEARCH_NOT_FOUND]: 'No entries to show',
    [CONSTANTS.SELECTQUESTION]: 'Select a question',
    [CONSTANTS.SEND]: 'Send',
    [CONSTANTS.SEVERITY_BAD]: 'Bad',
    [CONSTANTS.SEVERITY_GOOD]: 'Good',
    [CONSTANTS.SEVERITY_WARN]: 'Warn',
    [CONSTANTS.SIMPLE_MODE]: 'SIMPLE MODE',
    [CONSTANTS.SORRYPROBLEM]: 'Sorry, we had a problem.',
    [CONSTANTS.SPANISH]: 'Spanish',
    [CONSTANTS.STARTDATE]: 'Start',
    [CONSTANTS.SUCCESS]: 'SUCCESS',
    [CONSTANTS.TEAM]: 'Team',
    [CONSTANTS.TEAMS]: 'Teams',
    [CONSTANTS.TEAMS_ACTIVATETEAMS]: 'Activate teams',
    [CONSTANTS.TEAMS_CREATETEAMS]: 'Create teams',
    [CONSTANTS.TEAMS_CREATETEAMSDESC]: "Create teams and make the participant's experience even more extraordinary",
    [CONSTANTS.TEAMS_GENERATETEAMS]: 'Generate teams',
    [CONSTANTS.TEAMS_MODETEAMS]: 'Team mode',
    [CONSTANTS.TEAMS_MODETEAMSINACTIVATE]: 'TEAM MODE OFF',
    [CONSTANTS.TEAMS_NOTEAMS]: 'No team registered at the moment',
    [CONSTANTS.TEAMS_QUANTITY]: 'Number of people on the team',
    [CONSTANTS.THE]: 'The',
    [CONSTANTS.TOLERANCY]: 'TOLERANCE',
    [CONSTANTS.TOGGLE_GAMIFICATION_SUCCESS]: 'Gamification has been activated.',
    [CONSTANTS.TOGGLE_GAMIFICATION_FAIL]: 'There was an error activating gamification.',
    [CONSTANTS.TOTIME]: 'To',
    [CONSTANTS.TRY_AGAIN]: 'Try again',
    [CONSTANTS.TWO_ITEMS_MANDATORY]: 'Fill at least two Items!',
    [CONSTANTS.UNMUTED_PARTICIPANT_ERROR]: 'Failed to unmute the participant',
    [CONSTANTS.UPLOAD_ERROR_MESSAGE]: 'Unsupported file format',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE]: 'Drop the image here',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE_MULTIPLE]: 'Drop the files here',
    [CONSTANTS.UPLOAD_COVER_PLACEHOLDER]: 'Click here or drag the image',
    [CONSTANTS.UPLOAD_VIDEO_PLACEHOLDER]: 'Click here or drag the video',
    [CONSTANTS.UPLOAD_VIDEO_PROGRESS]: 'PROGRESS',
    [CONSTANTS.UPLOAD_VIDEO_SUCCESS]: 'Video upload finished!',
    [CONSTANTS.UPLOAD_VIDEO_FAILURE]: 'Video upload failure!',
    [CONSTANTS.UPLOAD_VIDEO_PREPARING]: 'Preparing video.',
    [CONSTANTS.UPLOADING]: 'Uploading',
    [CONSTANTS.UPLOADING_ATTACHMENTS]: 'Uploading Attachments',
    [CONSTANTS.CONVERTING]: 'Converting',
    [CONSTANTS.UNABLE_TO_EDIT_VIDEO]: 'Unable to edit the video at this time. Try again in a few moments',
    [CONSTANTS.UNENROLL_USER]: 'UNENROLL',
    [CONSTANTS.UNENROLLED]: 'Unenrolled',
    [CONSTANTS.UPLOAD_ERROR]: 'Error!',
    [CONSTANTS.UPLOAD_VIDEO]: 'Video upload',
    [CONSTANTS.UPLOAD]: 'Upload',
    [CONSTANTS.USERS]: 'Users',
    [CONSTANTS.USER]: 'User',
    [CONSTANTS.USERS_ACTIVE]: 'Active Users',
    [CONSTANTS.USERS_ACTIVECAPS]: 'ACTIVE USERS',
    [CONSTANTS.USERS_DISTRIBUTIONSHORT]: 'USERS DIST.',
    [CONSTANTS.USERS_ENGAGEMENT]: 'Users Engagement',
    [CONSTANTS.UPLOAD_ERROR_MESSAGE]: 'Unsupported file format',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE]: 'Drop the image here',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE_MULTIPLE]: 'Drop the files here',
    [CONSTANTS.VIDEO]: 'Video',
    [CONSTANTS.VIDEO_DESCRIPTION]: 'Video Description',
    [CONSTANTS.VIDEO_OFF]: 'Video Off',
    [CONSTANTS.VIDEO_UNAVAILABLE]: 'Video Unavailable',
    [CONSTANTS.VIDEO_TITLE_PLACEHOLDER]: 'e.g: Interactive Class 01',
    [CONSTANTS.VOUCHER]: 'VOUCHER',
    [CONSTANTS.VOUCHER_EMAIL_VALIDATION]: 'E-MAIL VALIDATION',
    [CONSTANTS.VOUCHER_ENTER_FIELDS]: 'Fill in all values',
    [CONSTANTS.VOUCHER_ERROR_COURSE]: 'User already has the course',
    [CONSTANTS.VOUCHER_ERROR_VERIFY]: 'Failed to verify voucher',
    [CONSTANTS.VOUCHER_ERROR_USERCREATED]: 'User already registered',
    [CONSTANTS.VOUCHER_ERROR_INVALIDCODE]: 'Failed to check code',
    [CONSTANTS.VOUCHER_ERROR_EXPIREDCODE]: 'Expired code, resend code to email',
    [CONSTANTS.VOUCHER_ERROR_RESENDEMAIL]: 'Failed to resend email',
    [CONSTANTS.VOUCHER_ERROR_CONFIRMPASSWORD]: 'Failed to confirm password',
    [CONSTANTS.VOUCHER_ERROR_REGISTERDATA]: 'Failed to register data',
    [CONSTANTS.VOUCHER_ERROR_REGISTER]: 'Failed to register voucher',
    [CONSTANTS.VOUCHER_INVALID]: 'Invalid voucher',
    [CONSTANTS.VOUCHER_NEW_VOUCHER]: 'New Voucher',
    [CONSTANTS.VOUCHER_VALIDATE]: 'Your voucher with the code',
    [CONSTANTS.VOUCHER_VALIDATEPT2]: 'has been successfully validated',
    [CONSTANTS.VOUCHER_VALIDATE_CODE]: 'Validate voucher',
    [CONSTANTS.VOUCHER_VALIDATE_DENIED]: 'has not been successfully validated',
    [CONSTANTS.VOUCHER_VALIDATE_CODECAPS]: 'VOUCHER VALIDATION',
    [CONSTANTS.VOUCHER_VALIDATE_QUESTION]: 'Do you really want to validate the voucher',
    [CONSTANTS.VOUCHER_VALIDATE_QUESTIONPT2]: 'that releases the channel',
    [CONSTANTS.VOUCHER_YOUR_CODE]: 'YOUR VOUCHER CODE',
    [CONSTANTS.VOUCHER_REGISTERED]: 'Voucher successfully registered',
    [CONSTANTS.VOUCHER_RESEND_EMAIL]: 'Resend code',
    [CONSTANTS.VOUCHER_RESENT_EMAIL]: 'Code Resent',
    [CONSTANTS.VOUCHER_STEPS]: ['Terms of use', 'Personal data', 'Validation', 'Password creation'],
    [CONSTANTS.VOUCHER_VERIFICATION_CODE]: 'INSERT THE VERIFICATION CODE THAT WAS SUBMITTED TO THE EMAIL HERE',
    [CONSTANTS.VOUCHER_WARNING_VERIFY]: 'The voucher could not be verified',
    [CONSTANTS.WARNINGS_MESSAGES]: 'Too many messages sent in a short time. Try again later.',
    [CONSTANTS.WARNINGS_MESSAGES_FAIL]: 'Failed to send message',
    [CONSTANTS.WARNINGS_MESSAGES_NOTALLOWED]: 'For your security it is not allowed to send links, phone numbers and e-mails',
    [CONSTANTS.WARNINGS_MESSAGES_NOTFOUND]: 'Chat room not found',
    [CONSTANTS.WARNINGS_MESSAGES_PERMISSIONS]: 'You are not allowed to send a message in this chat room',
    [CONSTANTS.YES]: 'Yes',
    [CONSTANTS.ORGANIZER]: 'Organizer',
    [CONSTANTS.PARTICIPANT]: 'Participant',
    [CONSTANTS.ORGANIZER_DASHBOARD]: 'Organizer',
    [CONSTANTS.NOTIFICATIONS]: 'Notifications',
    [CONSTANTS.PITCH]: 'Pitch',
    [CONSTANTS.SETTINGS]: 'Settings',
    [CONSTANTS.INFORMATION]: 'Information',
    [CONSTANTS.INFORMATIONS]: 'Information',
    [CONSTANTS.LOGOUT]: 'Logout',
    [CONSTANTS.CHANNEL]: 'Channel',
    [CONSTANTS.CHANNEL_HEADER]: 'CURRENT CHANNEL:',
    [CONSTANTS.CONTENT_TYPE_TO_ADD]: 'What kind of content do you want to add?',
    [CONSTANTS.CONTENT]: 'Media',
    [CONSTANTS.SAVE_CHANGES]: 'Save changes',
    [CONSTANTS.CLOSE]: 'Close',
    [CONSTANTS.CHARACTERS]: 'Characters',
    [CONSTANTS.OPTIONAL]: 'Optional',
    [CONSTANTS.WHAT_CHANNEL_LOOKING]: 'WHAT CHANNEL ARE YOU LOOKING FOR?',
    [CONSTANTS.ROOM]: 'Room',
    [CONSTANTS.TIME_WATCHED]: 'TIME WATCHED',
    [CONSTANTS.USERS_WHO_WATCH_THE_VIDEO]: 'USERS WHO WATCH THE VIDEO',

    [CONSTANTS.BUTTON_MESSAGE_YES]: 'YES, I WANT',
    [CONSTANTS.BUTTON_MESSAGE_NOT]: 'NO, I DONT WANT',
    [CONSTANTS.MODAL_DELETE_MESSAGE]: 'YOU REALLY WANT DELETE THE USER',
    [CONSTANTS.ADD_NEW_USER]: 'ADD NEW USER',
    [CONSTANTS.EDIT_USER]: 'EDIT USER',
    [CONSTANTS.DELETE_USER]: 'DELETE USER',
    [CONSTANTS.PERMISSION_LEVEL]: 'User permission level',
    [CONSTANTS.USERS_PER_PAGE]: 'Users per page',
    [CONSTANTS.PARTICIPANT]: 'Participant',
    [CONSTANTS.MONITOR]: 'Moderator',
    [CONSTANTS.DELETE]: 'Delete',
    [CONSTANTS.NAME]: 'Name',
    [CONSTANTS.PERMISSION]: 'Permission',
    [CONSTANTS.NEW_USER]: 'NEW USER',
    [CONSTANTS.IMPORT_SPREADSHEET]: 'IMPORT WORKSHEET',
    [CONSTANTS.GENDER]: 'Gender',
    [CONSTANTS.DATE_OF_BIRTH]: 'Date of birth',
    [CONSTANTS.SAVE_USER]: 'Save',
    [CONSTANTS.MALE]: 'Male',
    [CONSTANTS.FEMALE]: 'Female',
    [CONSTANTS.PHONE_CONTACT]: 'Contact phone',
    [CONSTANTS.PASSWORD]: 'Password',
    [CONSTANTS.RESULTS]: 'Results found',
    [CONSTANTS.PHONE]: 'Phone',
    [CONSTANTS.FAIL_EDIT_USER]: 'Could not edit user',
    [CONSTANTS.FAIL_GET_USER]: 'Could not list users',
    [CONSTANTS.FAIL_DELETE_USER]: 'It was not possible to delete the user',
    [CONSTANTS.FAIL_UNSUBSCRIBE_USER]: 'It was not possible unsubscribe the user',
    [CONSTANTS.FAIL_POST_USER]: 'The user could not be saved',
    [CONSTANTS.FAIL_GET_CHANNELS]: 'Failed to retrieve channels',
    [CONSTANTS.FAIL_ADD_USER]: 'Failed to add user',
    [CONSTANTS.DONE]: 'Done',
    [CONSTANTS.ERROR]: 'Error',
    [CONSTANTS.ERROR_LOAD_DATA]: 'Failed to load data!',
    [CONSTANTS.ERROR_ROOM_ID]: 'You must provide the "roomId" ind order to send stats!',
    [CONSTANTS.COMPLETEDWITHERROR]: 'Completed with error',
    [CONSTANTS.USERSLIMITREACHED]: 'Users limit reached',
    [CONSTANTS.FILE]: 'File name',
    [CONSTANTS.PROCESS_STATUS]: 'Process status',
    [CONSTANTS.CREATION_DATE]: 'Creation date',
    [CONSTANTS.REGISTRATION_ERRORS]: 'Registration errors',
    [CONSTANTS.IMPORT]: 'Import',
    [CONSTANTS.EXECUTE_IMPORT]: 'Execute Import',
    [CONSTANTS.DOWNLOAD_SPREADSHEET_SAMPLE]: 'Download a ready to import worksheet template',
    [CONSTANTS.DRAG_AND_DROP_FILES]: 'Click here or drag the file',
    [CONSTANTS.IMPORT_LIST_DESCRIPTION]: 'Import your users from a xlsx file',
    [CONSTANTS.IMPORT_LIST_TIP]: 'The XLSX file must have the following fields, with the same ordering and formatting:',
    [CONSTANTS.OTHERS]: 'Others',
    [CONSTANTS.OR]: 'Or',
    [CONSTANTS.AND]: 'AND',
    [CONSTANTS.XLSX_DETAILS]: 'Details ',
    [CONSTANTS.IMPORTXLSXHEADER]: 'IMPORT YOUR USERS USING A XLSX',
    [CONSTANTS.XLSX_FORMAT_ERROR]: 'THE FORMAT OF THE FILE YOU ADDED IS INCORRECT! ADD A FILE WITH XLSX FORMAT.',
    [CONSTANTS.XLSX_SAMPLE_FAIL]: 'ERROR DOWNLOADING FILE.',
    [CONSTANTS.XLSX_FORMAT_ERROR_MESSAGE]: 'Haven`t imported any XLSX yet? It`s simple, easy and only takes a few minutes.',
    [CONSTANTS.XLSX_FORMAT_NO_IMPORT]: 'No import was performed.',
    [CONSTANTS.INCORRECT_COLUMNS]: 'Incorrect columns',
    [CONSTANTS.USERS_REGISTERED]: 'Users successfully registered!',
    [CONSTANTS.USER_REGISTERED]: 'User successfully registered!',
    [CONSTANTS.USERS_NOT_REGISTERED]: 'Unregistered users!',
    [CONSTANTS.BACK_TO]: 'Back to',
    [CONSTANTS.ERROR_IMAGE_UPLOAD]: 'FAILED TO UPLOAD THE IMAGE',
    [CONSTANTS.SOCIAL_MEDIA]: 'SOCIAL MEDIAS',
    [CONSTANTS.PROFILE_UPDATED]: 'PROFILE UPDATED',
    [CONSTANTS.PROFILE_UPDATE_FAILED]: 'PROFILE UPDATED FAILED',
    [CONSTANTS.CROP_IMAGE]: 'CROP IMAGE',
    [CONSTANTS.VIEW_PROFILE]: 'VIEW PROFILE',
    [CONSTANTS.GUEST_AVAILABLE]: 'GUEST AVAILABLE',
    [CONSTANTS.GUEST_PRESENTER]: 'CONNECTED USERS',
    [CONSTANTS.SAVE]: 'SAVE',
    [CONSTANTS.SUBS_LIMIT_REACHED]: 'User limit reached',
    [CONSTANTS.WELCOME_TO_KOPA]: 'WELCOME TO KOPA',
    [CONSTANTS.MY_PROFILE]: 'MY PROFILE',
    [CONSTANTS.GUEST]: 'Guest',
    [CONSTANTS.BUTTON_GUEST]: 'GUEST',

    [CONSTANTS.BUTTON_MESSAGE_YES]: 'YES, I WANT',
    [CONSTANTS.BUTTON_MESSAGE_NOT]: 'NO, I DONT WANT',
    [CONSTANTS.MODAL_DELETE_MESSAGE]: 'YOU REALLY WANT DELETE THE USER',
    [CONSTANTS.MODAL_UNSUBSCRIBE_MESSAGE]: 'YOU REALLY WANT UNSUBSCRIBE THE USER',
    [CONSTANTS.ADD_NEW_USER]: 'ADD NEW USER',
    [CONSTANTS.EDIT_USER]: 'EDIT USER',
    [CONSTANTS.DELETE_USER]: 'DELETE USER',
    [CONSTANTS.UNSUBSCRIBE_USER]: 'UNSUBSCRIBE USER',
    [CONSTANTS.PERMISSION_LEVEL]: 'User permission level',
    [CONSTANTS.USERS_PER_PAGE]: 'Users per page',
    [CONSTANTS.PARTICIPANT]: 'Participant',
    [CONSTANTS.MONITOR]: 'Moderator',
    [CONSTANTS.DELETE]: 'Delete',
    [CONSTANTS.NAME]: 'Name',
    [CONSTANTS.PERMISSION]: 'Permission',
    [CONSTANTS.MORE_OPTIONS]: 'MORE ACTIONS',
    [CONSTANTS.SPREADSHEET]: 'Spreadsheet',
    [CONSTANTS.DOWNLOAD_SPREADSHEET]: 'Download Spreadsheet',
    [CONSTANTS.DOWNLOAD_VIDEO_RECORDING]: 'DOWNLOAD VIDEO',
    [CONSTANTS.DOWNLOAD_VIDEO_FAIL]: 'Failed to download video',
    [CONSTANTS.REPORTS]: 'Reports',
    [CONSTANTS.PRESENCE_REPORT]: 'Presence Report',
    [CONSTANTS.EXPORT]: 'EXPORT',
    [CONSTANTS.REFRESH]: 'REFRESH',
    [CONSTANTS.LOAD_MORE_PAGINATION]: 'LOAD MORE',
    [CONSTANTS.VIEW_REPORT]: 'View Report',
    [CONSTANTS.GET_PRESENCE_REPORT_FALURE]: 'Failed to get Report ',
    [CONSTANTS.GET_COURSE_FAILURE]: 'Failed to get Channel ',
    [CONSTANTS.VIEW_REPORT_WARN]: 'Attention: The presence report is only available for LIVE content / videos ',
    [CONSTANTS.EXPORT_REPORT_FAILURE]: 'Fail to download report',
    [CONSTANTS.OF_VIEW]: 'of view',
    [CONSTANTS.VIEWING_TIME]: 'Viewing time',
    [CONSTANTS.USERS_REPORT]: 'Users Report',
    [CONSTANTS.PREPARING_DOWNLOAD]: 'Preparing download...',

    [CONSTANTS.QUIZ]: 'Quiz',
    [CONSTANTS.SURVEY]: 'Survey',
    [CONSTANTS.SURVEY_RIGHT_ANSWER]: 'are surveys that have the right answer.',
    [CONSTANTS.SURVEY_DETAIL]: 'Survey details',
    [CONSTANTS.QUIZZES]: 'Quizzes',
    [CONSTANTS.QUIZZES_SURVEYS]: 'Quizzes and Surveys',
    [CONSTANTS.IN_PROGRESS]: 'In progress',
    [CONSTANTS.DISABLED]: 'Disabled',
    [CONSTANTS.NEW_QUIZ]: 'New quiz',
    [CONSTANTS.NEW_SURVEY]: 'New survey',
    [CONSTANTS.NEW_QUIZ_OR_SURVEY]: 'New quiz or survey',
    [CONSTANTS.TYPE]: 'Type',
    [CONSTANTS.TITLE]: 'Title',
    [CONSTANTS.DURATION]: 'Duration',
    [CONSTANTS.ANSWER]: 'Answer',
    [CONSTANTS.DETAILS]: 'Details',
    [CONSTANTS.QUESTION]: 'Question',
    [CONSTANTS.QUESTIONS]: 'Questions',
    [CONSTANTS.QUESTION_SCORE]: 'Question Score',
    [CONSTANTS.QUESTIONS_QUANTITY]: 'Number of questions',
    [CONSTANTS.CORRECT_ANSWER]: 'Correct answer',
    [CONSTANTS.OPTION_CHARACTERS_INFO]: 'characters remaining',
    [CONSTANTS.SELECT]: 'Select',
    [CONSTANTS.SCREEN_TIME_DURATION]: 'Display time ( in seconds ) ',
    [CONSTANTS.SAVE_QUIZ]: 'Save Quiz',
    [CONSTANTS.SAVE_SURVEY]: 'Save survey',
    [CONSTANTS.ADD]: 'Add',
    [CONSTANTS.PUBLISH]: 'Publish',
    [CONSTANTS.PUBLISHED]: 'Published',
    [CONSTANTS.SURVEY_PUBLISHED]: 'Published',
    [CONSTANTS.UNPUBLISHED]: 'Unpublished',
    [CONSTANTS.SURVEY_UNPUBLISHED]: 'Unpublished',
    [CONSTANTS.WARNING]: 'Warning',
    [CONSTANTS.CHECK_DATA]: 'Check your data!',
    [CONSTANTS.UPDATE_QUESTION_SUCCESS]: 'Question updated successfully!',
    [CONSTANTS.UPDATE_QUESTION_FAIL]: 'Failed to update question!',
    [CONSTANTS.ERROR_DUPLICATE_QUESTION]: 'Error: Questionnaire items must have unique value!',
    [CONSTANTS.LIMIT_CHARACTER]: 'Character limit reached!',
    [CONSTANTS.RELEASE_QUESTION_FAIL]: 'Failed to post question!',
    [CONSTANTS.CREATE_QUESTION_SUCCESS]: 'Question created successfully!',
    [CONSTANTS.CREATE_QUESTION_FAIL]: 'Failed to create question!',
    [CONSTANTS.QUIZ_RESULT]: 'Quiz result',
    [CONSTANTS.SURVEY_RESULT]: 'Survey result',
    [CONSTANTS.VIEW_QUIZZES_WARN]: 'Attention: Quizzes/Surveys is only available for LIVE content / videos ',
    [CONSTANTS.FIELD_MANDATORY]: 'Required Field',
    [CONSTANTS.FIELD_DUPLICATE]: 'Duplicate Field',
    [CONSTANTS.POSITIVE_FIELD]: 'The field must be positive!',
    [CONSTANTS.REQUIRED_QUESTIONS_FIELDS]: 'Fill in the required question items',
    [CONSTANTS.REQUIRED_OPTIONAL_QUESTION_FIELD]: 'Fill in the "optional" field in sequence',

    [CONSTANTS.SORT_BY]: 'SORT BY:',
    [CONSTANTS.TOTAL_OF_POINTS]: 'Total of Points',
    [CONSTANTS.LABEL_SEARCH_INPUT]: 'SEARCH BY NAME AND EMAIL',
    [CONSTANTS.DECREASING]: 'Decreasing',
    [CONSTANTS.ASCENDING]: 'Ascending',
    [CONSTANTS.POSITION]: 'Place',
    [CONSTANTS.POINTS]: 'Points',
    [CONSTANTS.LAST_UPDATE]: 'Last Update',
    [CONSTANTS.PLACE]: 'Place',
    [CONSTANTS.LABEL_SEARCH_INPUT_USERS]: 'SEARCH BY EMAIL',
    [CONSTANTS.BLOCK_LIST]: 'Chat block list',
    [CONSTANTS.BLOCK_DATE]: 'Blocking date',
    [CONSTANTS.REMOVE_BLOCKING]: 'Remove blocking',
    [CONSTANTS.NAME_CHAT]: 'Chat name',
    [CONSTANTS.UNBLOCKING_MSG]: 'Are you really sure you want to remove the chat blocking from ',
    [CONSTANTS.REMOVE]: 'Remove',
    [CONSTANTS.BLOCKING]: 'Blocking',
    [CONSTANTS.CONFIRM_UNBLOCKING_YES]: 'Yes, I do',
    [CONSTANTS.CONFIRM_UNBLOCKING_NO]: 'No, I don\'t',
    [CONSTANTS.VIEW_BLOCKLIST_WARN]: 'Attention: Block List is only available for LIVE content / videos ',
    [CONSTANTS.BLOCK_LIST_FAILURE]: 'Fail to load blocked users!',
    [CONSTANTS.UNBLOCK_USER_SUCCESS]: 'User successfully unblocked!',
    [CONSTANTS.UNBLOCK_USER_FAILURE]: 'Fail to unblock user!',
    [CONSTANTS.USERS_THAT_ARE_NOW_VIEWING_THE_BROADCAST]: 'Users that are now viewing the broadcast.',
    [CONSTANTS.USERS_WITH_TAB_AND_SOUND_ON_OR_IN_FULL_SCREEN_DIVIDED_BY_THE_TOTAL_USERS_VIEWED]: 'Users with tab and sound on or in fullscreen divided by the total users viewed.',
    [CONSTANTS.USERS_THAT_ARE_EXPERIENCING_BUFFERING_ISSUE_OF_OVER_1_SECOND_DIVIDED_BY_TOTAL_USERS]: 'Users that are experiencing buffering issue of over 1 second divided by total users.',
    [CONSTANTS.THE_AVERAGE_BITRATE_REPORTED_BY_KOPA_PLAYERS]: 'The average bitrate reported by Kopa players.',

    [CONSTANTS.TRANSMISSION]: 'Transmission',
    [CONSTANTS.WAITING_TRANSMISSION]: 'Waiting Transmission',
    [CONSTANTS.NO_LIVE_IN_PROGRESS]: 'The live can only be started when the PRESENTER starts his broadcast.',
    [CONSTANTS.MUTE_USER]: 'Mute',
    [CONSTANTS.UNMUTE_USER]: 'Unmute',
    [CONSTANTS.UNMUTE_USER]: 'Unmute',
    [CONSTANTS.CREATING_ROOM_PLEASE_WAIT]: 'The room is being created, please wait...',

    [CONSTANTS.ONBOARDING_HOME_WELCOME_TITLE]: 'Welcome to Kopa!',
    [CONSTANTS.ONBOARDING_HOME_WELCOME]: "It's a pleasure to have you here at Kopa. You are in the organizer area, which is the exclusive space for you to manage your channels. We invite you to take a quick tour of our platform. Come with us?",
    [CONSTANTS.ONBOARDING_HOME_LOGOUT_TITLE]: 'Logout',
    [CONSTANTS.ONBOARDING_HOME_LOGOUT]: 'Click here to exit Kopa.',
    [CONSTANTS.ONBOARDING_LETS_START]: "Let's start our tour",
    [CONSTANTS.ONBOARDING_SKIP_TOUR]: 'Skip tour',
    [CONSTANTS.ONBOARDING_HOME_MY_VIDEOS_TITLE]: 'My videos',
    [CONSTANTS.ONBOARDING_HOME_MY_VIDEOS]: 'Here you can manage your videos and live streaming.',
    [CONSTANTS.ONBOARDING_HOME_VIDEO_CARD_TITLE]: 'Videos',
    [CONSTANTS.ONBOARDING_HOME_VIDEO_CARD]: 'Here you can edit and view your videos and lives, generate reports and quizzes.',
    [CONSTANTS.ONBOARDING_HOME_USERS_TITLE]: 'Users',
    [CONSTANTS.ONBOARDING_HOME_USERS]: 'Within users you will have access to the list of users and you will be able to perform actions to manage the users of your channels, such as: edit, unsubscribe and add a new user.',
    [CONSTANTS.ONBOARDING_HOME_GAMEFICATION_TITLE]: 'Gamification',
    [CONSTANTS.ONBOARDING_HOME_GAMEFICATION]: 'Within gamification you will have access to the list of participants with their respective points.',
    [CONSTANTS.ONBOARDING_HOME_NEW_VIDEO_TITLE]: 'New video',
    [CONSTANTS.ONBOARDING_HOME_NEW_VIDEO]: 'Here you can start a live and add a new video.',
    [CONSTANTS.ONBOARDING_HOME_USER_AREA_TITLE]: 'User area',
    [CONSTANTS.ONBOARDING_HOME_USER_AREA]: 'Here you can view and edit your profile, adding photo and social media information. You can also select your preferred language.',
    [CONSTANTS.ONBOARDING_HOME_COMPLETE]: 'End tour through home page.',
    [CONSTANTS.ONBOARDING_HOME_MENU_TITLE]: 'User menu',
    [CONSTANTS.ONBOARDING_HOME_MENU]: 'Within the user menu you will have access to the home buttons, change settings for videos, users, QR code, quizzes, reports and blocking users in chat, exit Kopa and change the default Kopa language.',
    [CONSTANTS.ONBOARDING_COMPLETE]: 'Complete tutorial',
    [CONSTANTS.ONBOARDING_HOME_TUTORIALS_TITLE]: 'Tutorials',
    [CONSTANTS.ONBOARDING_HOME_TUTORIALS]: 'Here you will have access to tutorials that explain how Kopa works.',

    [CONSTANTS.ONBOARDING_USERS_AREA_TITLE]: 'User area',
    [CONSTANTS.ONBOARDING_USERS_AREA]: 'In the user area you will have access to the list of users and you will be able to perform actions to manage the users of your channels, such as: editing, unsubscribing and adding a new user.',
    [CONSTANTS.ONBOARDING_USERS_SEARCH_TITLE]: 'Search',
    [CONSTANTS.ONBOARDING_USERS_SEARCH]: 'Here you can search your users by name and email.',
    [CONSTANTS.ONBOARDING_USERS_OPTIONS_TITLE]: 'More actions',
    [CONSTANTS.ONBOARDING_USERS_OPTIONS]: 'Here you can add new users manually or via XLSX spreadsheet and download user list.',
    [CONSTANTS.ONBOARDING_USERS_CONTAINER_TITLE]: 'User listing',
    [CONSTANTS.ONBOARDING_USERS_CONTAINER]: 'Here you will have access to the list of users.',
    [CONSTANTS.ONBOARDING_USERS_ICON_DOTS_TITLE]: 'Listing options',
    [CONSTANTS.ONBOARDING_USERS_ICON_DOTS]: "Finally, you will have access to edit the user's permission level and unenroll the user.",

    [CONSTANTS.ONBOARDING_GAMIFICATION_AREA_TITLE]: 'Gamification area',
    [CONSTANTS.ONBOARDING_GAMIFICATION_AREA]: 'In the gamification area you will have access to the list of users with their respective points and you can make settings such as: enable or disable gamification, choose the action that will add points to the user and choose how this addition of points will be.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_SEARCH_AREA_TITLE]: 'Search',
    [CONSTANTS.ONBOARDING_GAMIFICATION_SEARCH_AREA]: 'Here you can search your users with their respective points by name and email.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_FILTER_AREA]: 'Filter',
    [CONSTANTS.ONBOARDING_GAMIFICATION_FILTER]: 'Here you can filter the list of users.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_SETTINGS_TITLE]: 'Settings',
    [CONSTANTS.ONBOARDING_GAMIFICATION_SETTINGS]: 'In the settings you can enable or disable gamification, choose the action that will add points to the user and choose how this addition of points will be.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_MORE_OPTIONS_TITLE]: 'More actions',
    [CONSTANTS.ONBOARDING_GAMIFICATION_MORE_OPTIONS]: 'Here you can download the list of users.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_CONTAINER_TITLE]: 'Listagem de usuários',
    [CONSTANTS.ONBOARDING_GAMIFICATION_CONTAINER]: 'Finally, you will have access to the list of users.',

    [CONSTANTS.ONBOARDING_VIDEOS_EDIT_LIVE_TITLE]: 'Live area',
    [CONSTANTS.ONBOARDING_VIDEOS_EDIT_LIVE]: 'Here you can manage your live and get information about it.',
    [CONSTANTS.ONBOARDING_VIDEOS_DASHBOARD_TITLE]: 'Dashboard',
    [CONSTANTS.ONBOARDING_VIDEOS_DASHBOARD]: 'In the Dashboard you will be able to start your live and you will find a visual panel that contains information and indicators about your live.',
    [CONSTANTS.ONBOARDING_VIDEOS_QRCODE_TITLE]: 'QR-Code',
    [CONSTANTS.ONBOARDING_VIDEOS_QRCODE]: 'Within the QR-Code you can create a QR-Code to sell products on your live.',
    [CONSTANTS.ONBOARDING_VIDEOS_QUIZZES_TITLE]: 'Quizzes',
    [CONSTANTS.ONBOARDING_VIDEOS_QUIZZES]: 'Within quizzes you can register quizzes or surveys to be used during the live.',
    [CONSTANTS.ONBOARDING_VIDEOS_REPORTS_TITLE]: 'Reports',
    [CONSTANTS.ONBOARDING_VIDEOS_REPORTS]: 'In the reports, you will see the live presence report.',
    [CONSTANTS.ONBOARDING_VIDEOS_BLOCK_LIST_CHAT_TITLE]: 'Block list chat',
    [CONSTANTS.ONBOARDING_VIDEOS_BLOCK_LIST_CHAT]: 'This area contains the list of users blocked in the live.',
    [CONSTANTS.ONBOARDING_VIDEOS_ENGAGEMENT_TITLE]: 'Engagement',
    [CONSTANTS.ONBOARDING_VIDEOS_ENGAGEMENT]: 'Here you will see the engagement indicators of your live.',
    [CONSTANTS.ONBOARDING_VIDEOS_PREVIEW_TITLE]: 'Live',
    [CONSTANTS.ONBOARDING_VIDEOS_PREVIEW]: 'Here you can start and enable live chat.',
    [CONSTANTS.ONBOARDING_VIDEOS_GRAPH_TITLE]: 'Graph',
    [CONSTANTS.ONBOARDING_VIDEOS_GRAPH]: 'Here you can filter by indicator and get a chart to better visualize your live information.',
    [CONSTANTS.ONBOARDING_VIDEOS_MONITORS_LIST_TITLE]: 'List of monitors',
    [CONSTANTS.ONBOARDING_VIDEOS_MONITORS_LIST]: 'You will have access to the list of monitors.',
    [CONSTANTS.ONBOARDING_VIDEOS_GELOCALIZATION_TITLE]: 'Geolocation',
    [CONSTANTS.ONBOARDING_VIDEOS_GELOCALIZATION]: 'Here you can check users geolocation.',

    [CONSTANTS.CAPTION]: 'Caption',
    [CONSTANTS.CAPTIONS]: 'Captions',
    [CONSTANTS.VIDEO_DETAILS]: 'Video details',
    [CONSTANTS.SELECT_CAPTION_LANGUAGE]: 'Select caption language',
    [CONSTANTS.SAVE_CAPTION]: 'Save caption',
    [CONSTANTS.ADD_CAPTION]: 'Add caption',
    [CONSTANTS.NEW_CAPTION]: 'New caption',
    [CONSTANTS.REMOVE_CAPTION_CONFIRMATION]: 'Are you sure you wanna remove this subtitle?',
    [CONSTANTS.CAPTION_UPLOAD_SUCCESSFULLY]: 'Caption uploaded successfully!',
    [CONSTANTS.CAPTION_REMOVED_SUCCESSFULLY]: 'Caption removed successfully!',
    [CONSTANTS.CAPTION_REMOVED_FAILED]: 'Failed to remove caption!',
    [CONSTANTS.CAPTION_UPLOAD_FAILED]: 'Failed to upload caption!',
    [CONSTANTS.CAPTION_UPDATE_SUCCESSFULLY]: 'Caption updated successfully!',
    [CONSTANTS.CAPTION_UPDATE_FAILED]: 'Failed to update caption!',
    [CONSTANTS.CAPTION_GET_FAILED]: 'Failed to get captions!',
    [CONSTANTS.CAPTION_STATUS_ERROR]: 'Error',
    [CONSTANTS.CAPTION_STATUS_QUEUED]: 'Queued',
    [CONSTANTS.CAPTION_STATUS_READY]: 'Ready',
    [CONSTANTS.CAPTION_STATUS_REMOVED]: 'Removed',
    [CONSTANTS.CAPTION_STATUS_IMPORTING]: 'Importing',
    [CONSTANTS.CAPTION_STATUS_EXPORTING]: 'Exporting',
    [CONSTANTS.NO_CAPTIONS_FOUND]: 'No captions was found!',
    [CONSTANTS.FIRST_CAPTION_MESSAGE]: 'You still haven\'t registered any subtitles? It\'s simple, easy and only takes a few minutes.',
    [CONSTANTS.REMOVING]: 'Removing',
    [CONSTANTS.DOWNLOADING]: 'Downloading',
    [CONSTANTS.DOWNLOAD_CAPTION]: 'Download caption',
    [CONSTANTS.BACK_TO_VIDEOS]: 'Back to videos',
    [CONSTANTS.CAPTION_LANGUAGE_EXISTS]: 'There is already a subtitle with this language!',
    [CONSTANTS.VIDEO_SETTINGS]: 'Video settings',
    [CONSTANTS.LIVE_SETTINGS]: 'Live settings',

    [CONSTANTS.ARABIC]: 'Arabic',
    [CONSTANTS.CANTONESE]: 'Cantonese',
    [CONSTANTS.CATALAN]: 'Catalan',
    [CONSTANTS.CHINESE]: 'Chinese',
    [CONSTANTS.DANISH]: 'Danish',
    [CONSTANTS.DUTCH]: 'Dutch',
    [CONSTANTS.ENGLISH]: 'English',
    [CONSTANTS.FINNISH]: 'Finnish',
    [CONSTANTS.FRENCH]: 'French',
    [CONSTANTS.GERMAN]: 'German',
    [CONSTANTS.GREEK]: 'Greek',
    [CONSTANTS.HEBREW]: 'Hebrew',
    [CONSTANTS.HINDI]: 'Hindi',
    [CONSTANTS.HUNGARIAN]: 'Hungarian',
    [CONSTANTS.ICELANDIC]: 'Icelandic',
    [CONSTANTS.INDONESIAN]: 'Indonesian',
    [CONSTANTS.IRISH]: 'Irish',
    [CONSTANTS.ITALIAN]: 'Italian',
    [CONSTANTS.JAPANESE]: 'Japanese',
    [CONSTANTS.KOREAN]: 'Korean',
    [CONSTANTS.MALAYALAM]: 'Malayalam',
    [CONSTANTS['MANDARIN CHINESE']]: 'Mandarin Chinese',
    [CONSTANTS.NORWEGIAN]: 'Norwegian',
    [CONSTANTS.POLISH]: 'Polish',
    [CONSTANTS.PORTUGUESE]: 'Portuguese',
    [CONSTANTS.ROMANIAN]: 'Romanian',
    [CONSTANTS.RUSSIAN]: 'Russian',
    [CONSTANTS.SPANISH]: 'Spanish',
    [CONSTANTS.SWEDISH]: 'Swedish',
    [CONSTANTS['TAIWANESE MANDARIN']]: 'Taiwanese Mandarin',
    [CONSTANTS.TAMIL]: 'Tamil',
    [CONSTANTS.THAI]: 'Thai',
    [CONSTANTS.TURKISH]: 'Turkish',
    [CONSTANTS.UKRAINIAN]: 'Ukrainian',
    [CONSTANTS.URDU]: 'Urdu',
    [CONSTANTS.VIETNAMESE]: 'Vietnamese',
    [CONSTANTS.WELSH]: 'Welsh',
    [CONSTANTS.ZULU]: 'Zulu',

    [CONSTANTS.ONBOARDING_TUTORIALS_AREA_TITLE]: 'Tutorials area',
    [CONSTANTS.ONBOARDING_TUTORIALS_AREA]: 'In the tutorials area you will have access to tutorial videos that explain how Kopa works.',
    [CONSTANTS.ONBOARDING_TUTORIALS_TUTORIAL_CARD_TITLE]: 'Tutorials',
    [CONSTANTS.ONBOARDING_TUTORIALS_TUTORIAL_CARD]: 'Here you can view the tutorial videos.',

    [CONSTANTS.TUTORIALS]: 'Tutorials',
    [CONSTANTS.FAIL_TO_LOAD_VIDEO_PROGRESS]: 'Failed to load video progress!',
    [CONSTANTS.FAIL_TO_LOAD_TUTORIALS]: 'Failed to load tutorials!',

    [CONSTANTS.NETWORK_FAILED]: 'We are unable to access our server.',
    [CONSTANTS.NETWORK_FAILED_MESSAGE]: 'Check your connection and try again.',
    // statistic video
    [CONSTANTS.STATISTIC_VIDEO_DETAIL]: 'Details',
    [CONSTANTS.STATISTIC_VIDEO_DAY]: 'Day',
    [CONSTANTS.STATISTIC_VIDEO_DOWNLOAD]: 'Download Video',
    [CONSTANTS.STATISTIC_VIDEO_PEOPLE_WATCH]: 'People Who Watched the Video',
    [CONSTANTS.STATISTIC_VIDEO_PEOPLE_AVERAGE_TIME_WATCH]: 'Average Watched Time',
    [CONSTANTS.STATISTIC_VIDEO_PLAY_IN_VIDEO]: 'Play In Vídeo',
    [CONSTANTS.STATISTIC_VIDEO_ATTACHMENTS_ADDED]: 'Added Attachments',
    [CONSTANTS.STATISTIC_VIDEO_ATTACHMENTS_DOWNLOAD]: 'download attachments here',

    // Poll status notification
    [CONSTANTS.ROOM_POLL_STATUS_NOTIFY_FAILED]: 'Video [NAME] upload failed!',
    [CONSTANTS.ROOM_POLL_STATUS_NOTIFY_SUCCESS]: 'Video [NAME] has been completed.',

    [CONSTANTS.ROOM_STATUS_CREATED]: 'Created',
    [CONSTANTS.ROOM_STATUS_ERROR_CONVERTING]: 'error',
    [CONSTANTS.ROOM_STATUS_UNKNOWN]: 'Unknown',
    [CONSTANTS.MAX_SCHEDULE_TIME]: 'Is only possible to schedule a live at maximum 4 hours from the current time!',
};
