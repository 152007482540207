import React from 'react';
import ContentLoader from 'react-content-loader';

const Skeleton = (props) => (
    <ContentLoader
        speed={2}
        width={props?.width}
        height={props?.height}
        backgroundColor="#212436"
        foregroundColor="#2b2f42"
        {...props}
    >
        <rect x="-66" y="16" rx="3" ry="3" width="1920" height="1080" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
    </ContentLoader>
);

export default Skeleton;
