import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import { ContainerPreview, ContainerLogs, Circle } from './styles';
import useRooms from '../../../hooks/useRooms';
import { goLiveRoom, endLiveRoom, roomIsLive, fetchEditRoom } from '../../../store/modules/rooms/actions';
import Modal from '../../Modal';
import Skeleton from '../../Skeleton';
import { ROOM_STATUS, TIMERS } from '../../../utils/constants';
import PlayerPreview from './PlayerPreview';

const ReportPreview = ({ roomEventLog, roomId, startTime, canGoLive, isSettingUp }) => {
    const { statusLiveRoomEditSelected, isChatEnabled, selectedEditRoom } = useRooms();
    const [showWarn, setShowWarn] = useState(false);
    const finishedLive = selectedEditRoom?.status === ROOM_STATUS.DONE;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const flowSeverity = Object.freeze({
        0: t('SEVERITY', { context: 'GOOD' }),
        1: t('SEVERITY', { context: 'WARN' }),
        2: t('SEVERITY', { context: 'BAD' }),
    });
    const flowOptions = Object.freeze({
        primary_stream: t('FLOW', { context: 'PRIMARY' }),
        secondary_stream: t('FLOW', { context: 'SECONDARY' }),
    });
    const severityColors = Object.freeze({
        0: '#8dd45c',
        1: '#ffbb33',
        2: '#ff4444',
    });
    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
    const [liveTime, setLiveTime] = useState(null);

    const handleNoPresenterAvailable = () => {
        setShowWarn(true);
    };

    const handleGoLive = () => {
        if (!statusLiveRoomEditSelected?.live && !canGoLive) {
            return;
        }
        if (statusLiveRoomEditSelected && statusLiveRoomEditSelected.preview) {
            dispatch(goLiveRoom(roomId, handleNoPresenterAvailable));
        } else if (statusLiveRoomEditSelected && statusLiveRoomEditSelected.live) {
            setIsModalConfirmOpen(isModalConfirmOpen => !isModalConfirmOpen);
        }
    };

    const handleConfirm = () => {
        if (!statusLiveRoomEditSelected?.live) {
            return;
        }
        dispatch(endLiveRoom(roomId));
        setIsModalConfirmOpen(isModalConfirmOpen => !isModalConfirmOpen);
    };

    useEffect(() => {
        if (startTime) {
            const duration = Moment.duration(Moment.utc(Moment()).diff(Moment.utc(startTime)));
            setLiveTime(Moment.utc(duration.asMilliseconds()));
        }
    }, [startTime]);

    useEffect(() => {
        const timerLive = setInterval(() => {
            if (liveTime && statusLiveRoomEditSelected && statusLiveRoomEditSelected.live) {
                setLiveTime((liveTime) => Moment(liveTime).add(1, 'seconds'));
            }
        }, TIMERS.SECOND);

        return () => clearInterval(timerLive);
        // eslint-disable-next-line
    }, [statusLiveRoomEditSelected])

    useEffect(() => {
        const checkIsLive = setInterval(() => {
            dispatch(roomIsLive(roomId));
        }, TIMERS.CHECK_ROOM_LIVE);

        return () => {
            if (checkIsLive) {
                clearInterval(checkIsLive);
            }
        };
    }, [dispatch, roomId]);

    useEffect(() => {
        const checkChatStatus = setInterval(() => {
            dispatch(fetchEditRoom(roomId));
        }, TIMERS.CHECK_CHAT_STATUS);

        return () => {
            clearInterval(checkChatStatus);
        };
    }, [dispatch, roomId]);

    return (
        <ContainerPreview className="--preview" data-tour="tour__preview">
            <Modal isOpen={showWarn} handleModal={() => setShowWarn(false)} title={t('VIEW_REPORT_WARN').split(':')[0]}>
                <div className="--warn-content">
                    <h5>{t('NO_LIVE_IN_PROGRESS')}</h5>
                    <button type="button" onClick={() => setShowWarn(false)}>OK</button>
                </div>
            </Modal>
            {!roomEventLog ? <Skeleton width="1920" height="420" />
                : (
                    <>
                        {
                            !finishedLive && (
                                <PlayerPreview
                                    isChatEnabled={isChatEnabled}
                                    roomId={roomId}
                                    canGoLive={canGoLive}
                                    handleGoLive={handleGoLive}
                                    isSettingUp={isSettingUp}
                                />
                            )
                        }
                        <div className="log">
                            <div className="header">
                                <picture className="circle"><img alt="" className="inner-circle" /></picture>
                                <h4>{t('LIVE')}</h4>
                                <p> {liveTime && statusLiveRoomEditSelected && statusLiveRoomEditSelected.live
                                    ? Moment(liveTime).format('HH:mm:ss')
                                    : ''}
                                </p>
                            </div>
                            <div className="log-content" id="log-content">
                                {roomEventLog.map && roomEventLog.map((event, index) => (
                                    <ContainerLogs key={index} severity={severityColors[event.severity]}>
                                        <div className="logs">
                                            <Circle severity={severityColors[event.severity]} />
                                            <p className="flow">
                                                {flowOptions[event.current_stream]}: <span>{flowSeverity[event.severity]}</span>
                                            </p>
                                            <p className="flow-date">
                                                {Moment(event.time).format('kk:mm DD/MM/YYYY')}
                                            </p>
                                        </div>
                                        {event.events.map((subevent, index) => (
                                            <div key={index} className="subevent">
                                                <picture alt="" className="subevent-circle" />
                                                <p>
                                                    {subevent.message}
                                                </p>
                                            </div>
                                        ))}
                                        <div alt="" className="vertical-line" />
                                    </ContainerLogs>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            <Modal title={t('CHANNEL', { context: 'ENDLIVEDESCRIPTION' })} isOpen={isModalConfirmOpen} handleModal={() => setIsModalConfirmOpen((isModalConfirmOpen => !isModalConfirmOpen))}>
                <div className="modal-confirm">
                    <p>{t('CHANNEL', { context: 'ENDLIVEIRREVERSIBLE' })}.</p>
                    <div className="confirm-buttons">
                        <button
                            onClick={handleConfirm}
                            type="submit"
                        >{t('CONFIRM')}
                        </button>
                        <button
                            onClick={() => setIsModalConfirmOpen(isModalConfirmOpen => !isModalConfirmOpen)}
                            className="cancel"
                            type="submit"
                        >{t('CANCEL')}
                        </button>
                    </div>
                </div>
            </Modal>
        </ContainerPreview>
    );
};

export default React.memo(ReportPreview);
