import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';
import {
    MenuContainer,
    DropdownList,
    InputSearch,
    DropdownButtonWrapper,
    DropdownContainer,
    SectionContainer,
    Button,
    MoreOptionsList,
    SectionContainerMoreOptions,
} from './styles';
import searchIcon from '../../assets/search-icon.svg';
import arrowDownIcon from '../../assets/arrow-down-icon.svg';
import closeIcon from '../../assets/close-icon.svg';
// import importIcon from '../../assets/import.svg'; próxima sprint
import option from '../../assets/option.svg';

function MenuSearchBar({
    mobile = false,
    options,
    setOption,
    placeholder,
    filterOptions,
    setFilterOption,
    selectedFilter,
    orderBy,
    search,
    handleChangeInputFilter,
    ordination,
    clearFilter,
    valueInput,
    filter,
    orderSelect,
    moreOptions,
    extraButton,
    extraButtonLabel,
    extraButtonClick,
    extraButtonIcon,
}) {
    const [showOrdinationBy, setShowOrdinationBy] = useState(false);
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const [showFilterBy, setShowFilterBy] = useState(false);
    const [inputFocus, setInputFocus] = useState(false);
    const { t } = useTranslation();

    function toggleOrdinationBy() {
        setShowOrdinationBy(!showOrdinationBy);
    }

    function toggleMoreOptions() {
        setShowMoreOptions(!showMoreOptions);
    }

    function toggleFilterBy() {
        setShowFilterBy(!showOrdinationBy);
    }

    function chooseFilterOption(value) {
        setFilterOption(value);
        setShowFilterBy(false);
    }

    function chooseOrdinationOption(value, sort) {
        setOption(value, sort);
        setShowOrdinationBy(false);
    }

    return (
        <MenuContainer className={`${mobile ? 'mobile' : undefined}`}>
            <div className="searchAndFilter">
                <InputSearch isFocused={inputFocus} data-tour="tour__search">
                    <span className="imgSearch">
                        <img alt="search" src={searchIcon} />
                    </span>
                    <input
                        type="text"
                        value={valueInput}
                        placeholder={placeholder}
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                        onChange={(e) => handleChangeInputFilter(e.target.value)}
                    />
                    {valueInput && (
                        <img
                            className="clearIcon"
                            onClick={clearFilter}
                            src={closeIcon}
                            alt="clear"
                        />
                    )}

                    <button type="button" onClick={search}>
                        {t('SEARCH')}
                    </button>
                </InputSearch>
                {filter && (
                    <OutsideClickHandler
                        onOutsideClick={() => setShowFilterBy(false)}
                        display="contents"
                    >
                        <DropdownContainer>
                            <DropdownButtonWrapper
                                tabIndex={0}
                                role="button"
                                onKeyPress={toggleFilterBy}
                                onClick={toggleFilterBy}
                            >
                                <div className="dropdown-component">
                                    <SectionContainer>
                                        <small>FILTRAR POR: </small>
                                        <p className="emphasis">
                                            {
                                                filterOptions.find(
                                                    (a) => a.value
                                                        === selectedFilter,
                                                )?.label
                                            }
                                        </p>
                                        <img
                                            src={arrowDownIcon}
                                            alt="Mostrar Mais"
                                            className={`${
                                                showFilterBy
                                                    ? '--open'
                                                    : undefined
                                            }`}
                                        />
                                    </SectionContainer>
                                </div>
                            </DropdownButtonWrapper>
                            <DropdownList className="top right">
                                {showFilterBy
                                    && filterOptions?.map((option) => (
                                        <li
                                            onClick={() => chooseFilterOption(option.value)}
                                        >
                                            {option.label}
                                        </li>
                                    ))}
                            </DropdownList>
                        </DropdownContainer>
                    </OutsideClickHandler>
                )}

                {orderSelect && (
                    <OutsideClickHandler
                        onOutsideClick={() => setShowOrdinationBy(false)}
                        display="contents"
                    >
                        <DropdownContainer data-tour="tour__filter">
                            <DropdownButtonWrapper
                                tabIndex={0}
                                role="button"
                                onKeyPress={toggleOrdinationBy}
                                onClick={toggleOrdinationBy}
                            >
                                <div className="dropdown-component">
                                    <SectionContainer>
                                        <div>
                                            <small>{t('SORT_BY')} </small>
                                            <p className="emphasis">
                                                {
                                                    options.find(
                                                        (a) => a.value === orderBy
                                                            && a.sort === ordination,
                                                    )?.label
                                                }
                                            </p>
                                        </div>
                                        <img
                                            src={arrowDownIcon}
                                            alt="Mostrar Mais"
                                            className={`${
                                                showOrdinationBy
                                                    ? '--open'
                                                    : undefined
                                            }`}
                                        />
                                    </SectionContainer>
                                </div>
                            </DropdownButtonWrapper>
                            <DropdownList className="top right">
                                {showOrdinationBy
                                    && options?.map((option, index) => (
                                        <li
                                            key={index}
                                            onClick={() => chooseOrdinationOption(
                                                option.value,
                                                option.sort,
                                            )}
                                        >
                                            {option.label}
                                        </li>
                                    ))}
                            </DropdownList>
                        </DropdownContainer>
                    </OutsideClickHandler>
                )}
            </div>
            <div className="buttonSettings" data-tour="tour__settings">
                {extraButton && (
                    <Button>
                        <DropdownButtonWrapper
                            data-tour="tour__settings"
                            tabIndex={0}
                            role="button"
                            onClick={extraButtonClick}
                        >
                            <div className="iconButton">
                                <img alt="icon" src={extraButtonIcon} />
                            </div>
                            <p>{extraButtonLabel}</p>
                        </DropdownButtonWrapper>
                    </Button>
                )}
            </div>
            <div className="buttonOptions" data-tour="tour__more_options">
                {moreOptions && (
                    <OutsideClickHandler
                        onOutsideClick={() => setShowMoreOptions(false)}
                        display="contents"
                    >
                        <Button>
                            <DropdownButtonWrapper
                                tabIndex={0}
                                role="button"
                                onKeyPress={toggleMoreOptions}
                                onClick={toggleMoreOptions}
                            >
                                <div className="dropdown-component">
                                    <SectionContainerMoreOptions>
                                        <div className="iconButton">
                                            <img alt="icon" src={option} />
                                        </div>
                                        <p>{t('MORE_OPTIONS')}</p>
                                    </SectionContainerMoreOptions>
                                </div>
                            </DropdownButtonWrapper>
                            <MoreOptionsList className="top right">
                                <ul onClick={toggleMoreOptions}>
                                    {showMoreOptions
                                        && moreOptions.map((option, index) => (
                                            <li
                                                key={index}
                                                onClick={option.action}
                                            >
                                                <img
                                                    alt={option.text}
                                                    src={option.icon}
                                                />
                                                {option.text}
                                            </li>
                                        ))}
                                </ul>
                            </MoreOptionsList>
                        </Button>
                    </OutsideClickHandler>
                )}
            </div>
        </MenuContainer>
    );
}

export default MenuSearchBar;
