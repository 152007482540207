import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-dropdown/style.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useDebounce } from 'use-debounce/lib';
import { Container, BlockUsersTable, Header, TableFooterResults, LoadMoreButtonSection } from './styles';
import useRooms from '../../../hooks/useRooms';
import Modal from '../../../components/Modal';
import NoContent from '../../../components/NoContent';
import { useBreadcrumbs } from '../../../hooks/useBreadcrumbs';
import paths from '../../../utils/paths';
import { getBlockedUsers, getBlockedUsersNextPage, unblockUser } from '../../../store/modules/blockList/actions';
import useQuery from '../../../hooks/useQuery';
import MenuSearchBar from '../../../components/MenuSearchBar';
import { useFetchEditRoom } from '../../../hooks/useFetchEditRoom';

const LoadingSkeleton = () => (
    <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
        <div style={{ marginTop: 10 }}>
            <Skeleton height={50} count={10} style={{ margin: '5px 0' }} />
        </div>
    </SkeletonTheme>
);

export default function BlockListDetail() {
    const [confirmUnblock, setConfirmUnblock] = useState(false);
    const [currentBlockedUser, setCurrentBlockedUser] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { selectedChannel, selectedEditRoom } = useRooms();
    const [rowsPerPage] = useState(10);
    const { users, loading, nextPageLoading, isLastPage } = useSelector(state => state.blockList);
    const { id: roomId } = useParams();
    const { setItems } = useBreadcrumbs();
    const query = useQuery();
    const [keyword, setKeyword] = useState('');
    const [debouncedKeyword] = useDebounce(keyword, 500);

    useFetchEditRoom(roomId);

    useEffect(() => {
        if (roomId) {
            dispatch(getBlockedUsers(roomId, debouncedKeyword, rowsPerPage, null));
        }
    }, [dispatch, debouncedKeyword, roomId, rowsPerPage]);

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t('ORGANIZER_DASHBOARD') },
            { to: paths.BLOCK_LIST, label: t('BLOCK_LIST') },
            { to: `${paths.BLOCK_LIST}/${roomId}/${selectedEditRoom?.name || ''}`, label: selectedEditRoom?.name || '' },
        ];
        setItems(breadcrumbsItems);
    }, [query, roomId, setItems, t, selectedEditRoom]);

    function handleRequestUnblockUser(user) {
        setCurrentBlockedUser(user);
        setConfirmUnblock(true);
    }

    function handleUnblockUser(_) {
        if (currentBlockedUser?.userId) {
            dispatch(unblockUser(roomId, { userId: currentBlockedUser?.userId, block: false }));
            setCurrentBlockedUser(null);
            setConfirmUnblock(false);
        }
    }

    function clearFilter() {
        setKeyword('');
    }

    function handleNextPage() {
        dispatch(getBlockedUsersNextPage(roomId, rowsPerPage));
    }

    return (
        <Container>

            <Modal isOpen={confirmUnblock} width={550} handleModal={() => setConfirmUnblock(false)} title={t('REMOVE')} featuredTitle={t('BLOCKING')}>
                <div className="--confirm-modal">
                    <h5>{t('UNBLOCKING_MSG')} <span>{currentBlockedUser?.name}</span> ?</h5>
                    <div className="--controls">
                        <button type="button" onClick={handleUnblockUser}>{t('CONFIRM_UNBLOCKING_YES')}</button>
                        <button type="button" className="--cancel-button" onClick={() => setConfirmUnblock(false)}>{t('CONFIRM_UNBLOCKING_NO')}</button>
                    </div>
                </div>
            </Modal>
            <Header className="header">
                <MenuSearchBar
                    selectedFilter=""
                    setFilterOption={() => { }}
                    setOption={() => { }}
                    handleChangeInputFilter={setKeyword}
                    placeholder={t('LABEL_SEARCH_INPUT_USERS')}
                    orderBy=""
                    search={() => { }}
                    ordination=""
                    clearFilter={clearFilter}
                    valueInput={keyword}
                    courseId={selectedChannel?.id}
                />
            </Header>
            {
                !loading && !users?.length > 0 && debouncedKeyword === '' && (
                    <NoContent
                        type="NOT_FOUND"
                        title={t('NO_USER_BLOCKED')}
                        text= {t('BLOCK_USER_BY_CHAT')}
                    />
                )
            }
            {
                !loading && !users?.length > 0 && debouncedKeyword !== '' && (
                    <NoContent
                        type="NOT_FOUND"
                        title= {t('NO_USER_FOUND')}
                        text={t('NO_USER_FOUND_MESSAGE')}
                    />
                )
            }
            {
                loading && <LoadingSkeleton />
            }
            {
                !loading && users?.length > 0 && (
                    <div className="containerTable">
                        <BlockUsersTable>
                            <Table>
                                <TableHead>
                                    <tr>
                                        <th align="left">{t('NAME_CHAT')}</th>
                                        <th align="left">{t('EMAIL')}</th>
                                        <th align="left">{t('BLOCK_DATE')}</th>
                                        <th align="left">{' '}</th>
                                    </tr>
                                </TableHead>
                                <TableBody>
                                    {users.map((user) => (
                                        <tr key={user?.username}>
                                            <td align="left">{user?.name}</td>
                                            <td align="left">{user?.username}</td>
                                            <td align="left">{moment(user?.chatBlockedAt).format('DD/MM/YYYY [as] HH:MM:SS')}</td>
                                            <td align="right">
                                                <button className="action-button" type="button" onClick={() => handleRequestUnblockUser(user)}>
                                                    {t('REMOVE_BLOCKING')}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </TableBody>
                            </Table>
                        </BlockUsersTable>
                    </div>
                )
            }
            {!loading && users?.length > 0 && (
            <TableFooterResults>
                <div className="table-footer">
                    <LoadMoreButtonSection>
                        {nextPageLoading ? (
                            <CircularProgress
                                style={{ color: '#ff9162' }}
                            />
                        ) : (
                            !isLastPage && (
                            <button onClick={handleNextPage} type="button">
                                {t('LOAD_MORE_PAGINATION')}
                            </button>
                            )
                        )}
                    </LoadMoreButtonSection>

                    <span>
                        {users?.length || 0} {t('RESULTS')}
                    </span>
                </div>
            </TableFooterResults>
            )}
        </Container>
    );
}
