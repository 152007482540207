import React from 'react';
import { useSidebar } from '../../../../hooks/useSidebar';

import { ContentWrapper } from './styles';

const Content = ({ children }) => {
    const { isMenuOpen } = useSidebar();

    return (
        <ContentWrapper isMenuOpen={isMenuOpen}>
            {children}
        </ContentWrapper>
    );
};

export default Content;
