/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl } from '@material-ui/core';
import ToggleSwitch from '@components/ToggleSwitch';
import 'react-dropdown/style.css';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import langs from '@langs/constants';

import Notify from '@src/utils/notification';

import useQuery from '@hooks/useQuery';
import api from '@services/api';
import { useFetchEditRoom } from '@hooks/useFetchEditRoom';
import { getQuizzes } from '../../../store/modules/quizzes/actions';
import {
    SubmitButton,
    Container,
    QuizStatusText,
    CustomInput,
    PageHeader,
    CancelButton,
    AccordionHeader,
    AccordionContent,
    SelectOptions,
    QuestionsSections,
} from './styles';
import { QuestionAccordion } from '../QuestionAccordion';
import { ErrorMessage } from '../styles';

const LoadingSkeleton = () => (
    <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
        <div style={{ marginTop: 10 }}>
            <Skeleton height={50} count={10} style={{ margin: '5px 0' }} />
        </div>
    </SkeletonTheme>
);

export default function SurveysControl() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const query = useQuery();
    const history = useHistory();
    const [duration, setDuration] = useState(120);
    const [questionTitle, setQuestionTitle] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [selectedSizeList, setSelectedSizeList] = useState(1);
    const [questionId, setQuestionId] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [question, setQuestion] = useState(null);
    const [disabledFields, setDisabledFields] = useState(false);
    const [quizzFormSchema, setQuizzFormSchema] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [schemaObj, setSchemaObj] = useState({});

    const { id: roomId } = useParams();

    const { register, handleSubmit, formState: { errors }, watch, reset } = useForm({ resolver: yupResolver(quizzFormSchema) });
    watch('questionTitle');

    const [questionsForm, setQuestionsForm] = useState([{
        answers: { a: '', b: '', c: '', d: '' },
        gamificationPoints: 10,
        id: '',
        question: '',
    }]);

    const handleSubmitQuizzes = async () => {
        if (isEditing) {
            let questionData = {};

            if (question?.status === 'onExcution') {
                questionData = { duration: Number(duration) };
            } else {
                questionData = {
                    title: questionTitle,
                    questions: questionsForm.map((item) => ({
                        ...item,
                        gamificationPoints: Number(item.gamificationPoints),
                        answers: Object.values(item.answers).filter(el => el !== '' && el),
                    })),
                    duration: Number(duration) || 120,
                };
            }
            try {
                await api.editQuestion(roomId, questionData, question.id).then(() => {
                    dispatch(getQuizzes(roomId));
                });
                Notify.success(t('UPDATE_QUESTION_SUCCESS'));

                if (question.released !== isPublished) {
                    await api.editReleaseQuestion(roomId, question.id, { release: isPublished })
                        .then(() => {
                            dispatch(getQuizzes(roomId));
                            history.push(`/quizzes/${roomId}`);
                        })
                        .catch((error) => {
                            Notify.error(error?.response?.data?.error_description || t('RELEASE_QUESTION_FAIL'));
                            dispatch(getQuizzes(roomId));
                        });
                }
                history.push(`/quizzes/${roomId}`);
            } catch (error) {
                Notify.error(error?.response?.data?.error_description || t('UPDATE_QUESTION_FAIL'));
            }
        } else {
            const questionData = {
                title: questionTitle,
                questions: questionsForm.map((item) => ({
                    ...item,
                    gamificationPoints: Number(item.gamificationPoints),
                    answers: Object.values(item.answers).filter(el => el !== '' && el),
                })),
                duration: Number(duration) || 120,
                questionType: 'research',
                groups: true,
            };

            try {
                const { id: questionId } = await api.postQuestion(roomId, questionData);
                dispatch(getQuizzes(roomId));

                if (isPublished) {
                    await api.editReleaseQuestion(roomId, questionId, { release: isPublished })
                        .then(() => {
                            dispatch(getQuizzes(roomId));
                        })
                        .catch((error) => {
                            Notify.error(error?.response?.data?.error_description || t('RELEASE_QUESTION_FAIL'));
                            dispatch(getQuizzes(roomId));
                        });
                }
                dispatch(getQuizzes(roomId));
                Notify.success(t('CREATE_QUESTION_SUCCESS'));
                history.push(`/quizzes/${roomId}`);
            } catch (error) {
                Notify.error(t(langs.CREATE_QUESTION_FAIL));
            }
        }
    };

    const getQuestionData = async () => {
        setIsLoading(true);
        try {
            const question = await api.getQuestion(roomId, questionId);

            setQuestion(question);
            setQuestionTitle(question?.title);
            setSelectedSizeList(question?.questions?.length);
            setDuration(question?.duration);
            setQuestionsForm(question?.questions?.map((question) => ({
                ...question,
                answers: {
                    a: question?.answers?.[0],
                    b: question?.answers?.[1],
                    c: question?.answers?.[2],
                    d: question?.answers?.[3],
                },
            })));
            setIsPublished(question?.released);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        } finally {
            reset(question);
            setIsLoading(false);
        }
    };

    const handleSelectQuestionsFormSize = (event) => {
        reset(question);
        event.persist();
        setSelectedSizeList(event.target.value);

        const questionData = {
            answers: { a: '', b: '', c: '', d: '' },
            gamificationPoints: 10,
            id: '',
            question: '',
        };

        if (Number(event.target.value) < questionsForm.length) {
            const questionFormCopy = questionsForm.slice(0, Number(event.target.value));
            setQuestionsForm(questionFormCopy);
            return;
        }

        setQuestionsForm(oldState => [
            ...oldState,
            ...new Array(Number(event.target.value) - questionsForm.length).fill(questionData)]);
    };

    const onChangeFieldValue = (event, index) => {
        event.preventDefault();
        event.persist();

        setQuestionsForm(prevState => prevState.map((item, i) => {
            if (i !== index) {
                return item;
            }

            const elementKeyName = event.target.name.replace(index, '');
            return {
                ...item,
                [elementKeyName]: event.target.value,
            };
        }));
    };

    const onChangeAnswers = (event, index) => {
        event.preventDefault();
        event.persist();

        setQuestionsForm(prevState => prevState.map((item, i) => {
            if (i !== index) {
                return item;
            }

            const elementKeyName = event.target.name.replace(index, '');

            return {
                ...item,
                answers: {
                    ...item.answers,
                    [elementKeyName]: event.target.value,
                },
            };
        }));
    };

    useFetchEditRoom(roomId);
    useEffect(() => {
        const obj = {};
        if (questionsForm?.length > 0) {
            questionsForm.map((_, index) => {
                Object.assign(obj, {
                    questionTitle: yup.string().required(t('TITLE_MANDATORY')),
                    duration: yup
                        .number(t('DURATION_NUMERIC'))
                        .typeError(t(langs.GAMIFICATION_POINTS_NUMERIC))
                        .positive(t('POSITIVE_FIELD'))
                        .integer(t('DURATION_NUMERIC'))
                        .required(t('DURATION_MANDATORY')),
                    [`question${index}`]: yup.string().required(t('FIELD_MANDATORY')),
                    [`gamificationPoints${index}`]: yup
                        .number(t(langs.GAMIFICATION_POINTS_NUMERIC))
                        .typeError(t(langs.GAMIFICATION_POINTS_NUMERIC))
                        .integer(t(langs.GAMIFICATION_POINTS_NUMERIC))
                        .required(t(langs.FIELD_MANDATORY))
                        .positive(t(langs.POSITIVE_FIELD))
                        .min(10, t(langs.MIN_SCORE)),
                    [`a${index}`]: yup.string().required(t('FIELD_MANDATORY')),
                    [`b${index}`]: yup.string().required(t('FIELD_MANDATORY')),
                    [`d${index}`]: yup.string(),
                    [`c${index}`]: yup.string().when(`d${index}`, { is: (value) => value && true, then: yup.string().required(t(langs.REQUIRED_OPTIONAL_QUESTION_FIELD)) }),
                });

                return true;
            });
        }
        setSchemaObj(obj);
    }, [questionsForm]);

    useEffect(() => {
        setQuizzFormSchema(yup.object(schemaObj).required());
    }, [questionsForm, schemaObj]);

    useEffect(() => {
        if (roomId) {
            dispatch(getQuizzes(roomId));
        }
    }, [dispatch, roomId]);

    useEffect(() => {
        const questionId = query;
        setQuestionId(questionId.get('id'));
    }, [query]);

    useEffect(() => {
        if (questionId) {
            try {
                setIsEditing(true);
                getQuestionData();
            } catch (error) {
                console.log(error);
            }
        }
    }, [questionId]);

    useEffect(() => {
        if (isEditing && question?.status === 'onExcution') {
            setDisabledFields(true);
        }

        if (isEditing && question?.status === 'postExcution') {
            history.push(`/quizzes/${roomId}`);
        }
    }, [isEditing, question]);

    useEffect(() => {
        if (questionId && questionsForm.length) {
            try {
                setQuestionsForm(oldState => oldState.map((question) => ({
                    ...question,
                    answers: {
                        a: question?.answers?.[0],
                        b: question?.answers?.[1],
                        c: question?.answers?.[2],
                        d: question?.answers?.[3],
                    },
                })));
            } catch (error) {
                console.log(error);
            }
        }
    }, [questionId]);

    const onInputKeyPress = (e) => {
        if (e.keyCode === 13 || e.key === 'Enter') {
            e.persist();
            e.preventDefault();
        }
    };

    if (isLoading) return LoadingSkeleton();

    return (
        <Container>

            <PageHeader>
                <h2>{t('ADD')} {t('SURVEY')}</h2>
                <FormControl className="switch-wrapper">
                    <div className="quiz-toggle">
                        <ToggleSwitch
                            isOn={isPublished}
                            handleToggle={() => setIsPublished(!isPublished)}
                        />
                        <span className="quizz-toggle-status">
                            {t(langs.SURVEY)} {' '} {isPublished
                                ? <QuizStatusText isPublished={isPublished}>{' '}{t(langs.SURVEY_PUBLISHED)}</QuizStatusText>
                                : <QuizStatusText isPublished={false}>{' '}{t(langs.SURVEY_UNPUBLISHED)}</QuizStatusText>}
                        </span>
                    </div>
                </FormControl>
            </PageHeader>

            <form onSubmit={handleSubmit(handleSubmitQuizzes)}>
                <AccordionHeader>
                    <div>
                        <h3>{t(langs.INFORMATIONS)} <span>{t('SURVEY')}</span></h3>

                    </div>
                </AccordionHeader>
                <AccordionContent>
                    <div>
                        <h4>{t(langs.TITLE)}</h4>

                        <CustomInput
                            disabledFields={disabledFields}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={() => setIsInputFocused(false)}
                            isInputFocused={isInputFocused}
                            id="questionTitle"
                            name="questionTitle"
                            placeholder={t(langs.ENTER_TITLE)}
                            onKeyPress={onInputKeyPress}
                            {...register('questionTitle', { value: questionTitle, onChange: e => setQuestionTitle(e.target.value) })}
                        />
                        <ErrorMessage>{errors.questionTitle?.message}</ErrorMessage>
                    </div>

                    <div>
                        <h4>{t(langs.QUESTIONS_QUANTITY)}</h4>

                        <SelectOptions
                            disabledFields={disabledFields}
                            name="selectedSizeList"
                            id="selectedSizeList"
                            value={selectedSizeList}
                            onChange={handleSelectQuestionsFormSize}
                            onKeyPress={onInputKeyPress}
                        >
                            <option value="1">01 {t('QUESTION')}</option>
                            {[...Array(9)].map((_, i) => (
                                <option key={i} value={i + 2}>{i + 2 !== 10 ? `0${i + 2}` : `${i + 2}`} {t('QUESTIONS')}</option>

                            ))}
                        </SelectOptions>
                    </div>

                    <div>
                        <h4>
                            {t(langs.SCREEN_TIME_DURATION)}
                        </h4>
                        <CustomInput
                            type="number"
                            id="duration"
                            placeholder="Duração"
                            name="duration"
                            inputProps={{ min: '1', step: '1' }}
                            onKeyPress={onInputKeyPress}
                            {...register('duration', { onChange: e => setDuration(e.target.value), value: duration })}
                        />
                        <ErrorMessage>{errors.duration?.message}</ErrorMessage>

                    </div>

                </AccordionContent>
                <QuestionsSections>
                    {
                        questionsForm?.map((item, index) => (
                            <QuestionAccordion
                                key={index}
                                questionIndex={index}
                                questionNumber={index + 1}
                                question={item.question}
                                gamificationPoints={item.gamificationPoints}
                                answers={item.answers}
                                onChangeAnswers={(evt) => onChangeAnswers(evt, index)}
                                id={item.id}
                                onChange={(evt) => onChangeFieldValue(evt, index)}
                                type="research"
                                loading={isLoading}
                                register={register}
                                errors={errors}
                                disabledFields={disabledFields}
                                handleKeyDown={onInputKeyPress}
                            />
                        ))
                    }
                </QuestionsSections>

                <div className="buttons-actions">

                    <CancelButton
                        disabled={false}
                        onClick={() => {
                            history.push(`/quizzes/${roomId}`);
                        }}
                    >
                        {t('CANCEL')}
                    </CancelButton>

                    <SubmitButton disabled={false} type="submit">
                        {t(langs.SAVE_SURVEY)}
                    </SubmitButton>

                </div>
            </form>

        </Container>
    );
}
