import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useSize from '@hooks/useSize';
import useAuth from '@hooks/useAuth';
import langs from '@src/assets/i18n/constants';
import { changeOnBoardingStatus, setOnBoarding } from '../../store/modules/auth/actions';
import { Onboarding } from './styles';

export default function OnboardingVideos() {
    const [isTourOpen, setTourOpen] = useState(false);
    const { width } = useSize();
    const { user, onBoarding } = useAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeOnBoardingStatus());
    }, [dispatch]);

    useEffect(() => {
        if (onBoarding?.data) {
            if (!(onBoarding.data.OnboardingOrganizerVideos) && !(onBoarding.skipped)) {
                setTourOpen(true);
            }
        }
    }, [dispatch, onBoarding]);

    useLayoutEffect(() => {
        if (isTourOpen) {
            const originalStyle = window.getComputedStyle(document.body).overfowY;
            document.body.style.overflowY = 'visible';
            return () => {
                document.body.style.overflow = originalStyle;
            };
        }
        return undefined;
    }, [isTourOpen]);

    function handleCloseOnboarding(key, skipped, global_skip) {
        dispatch(setOnBoarding(key, skipped, global_skip));
        setTourOpen(false);
    }

    function editLive() {
        return (
            {
                level: 'all',
                selector: '',
                content: ({ goTo }) => (
                    <>
                        <h2>{t(langs.ONBOARDING_VIDEOS_EDIT_LIVE_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_VIDEOS_EDIT_LIVE)}</p>
                        <div className="buttons">
                            <button
                                type="button"
                                className="btn continue-btn"
                                onClick={() => goTo(1)}
                            >
                                {t(langs.ONBOARDING_LETS_START)}
                            </button>
                            <button
                                href="#scroll-to"
                                id="scroll-to"
                                type="button"
                                className="btn ignore-btn"
                                onClick={() => handleCloseOnboarding('OnboardingOrganizerVideos', true)}
                            >
                                {t(langs.ONBOARDING_SKIP_TOUR)}
                            </button>
                        </div>
                    </>
                ),
                position: 'center',
            }
        );
    }

    function dashboard() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__dashboard"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_VIDEOS_DASHBOARD_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_VIDEOS_DASHBOARD)}</p>
                    </>
                ),
            }
        );
    }

    function qrCode() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__qrCode"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_VIDEOS_QRCODE_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_VIDEOS_QRCODE)}</p>
                    </>
                ),
            }
        );
    }

    function quizzes() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__quizzes"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_VIDEOS_QUIZZES_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_VIDEOS_QUIZZES)}</p>
                    </>
                ),
            }
        );
    }

    function reports() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__reports"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_VIDEOS_REPORTS_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_VIDEOS_REPORTS)}</p>
                    </>
                ),
            }
        );
    }

    function blockListChat() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__block_list_chat"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_VIDEOS_BLOCK_LIST_CHAT_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_VIDEOS_BLOCK_LIST_CHAT)}</p>
                    </>
                ),
            }
        );
    }

    function engagement() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__engagement"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_VIDEOS_ENGAGEMENT_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_VIDEOS_ENGAGEMENT)}</p>
                    </>
                ),
            }
        );
    }

    function preview() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__preview"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_VIDEOS_PREVIEW_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_VIDEOS_PREVIEW)}</p>
                    </>
                ),
            }
        );
    }

    function graph() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__graph"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_VIDEOS_GRAPH_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_VIDEOS_GRAPH)}</p>
                    </>
                ),
            }
        );
    }

    function monitorsList() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__monitors_list"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_VIDEOS_MONITORS_LIST_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_VIDEOS_MONITORS_LIST)}</p>
                    </>
                ),
            }
        );
    }

    function gelocalization() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__gelocalization"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_VIDEOS_GELOCALIZATION_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_VIDEOS_GELOCALIZATION)}</p>
                        <button
                            type="button"
                            className="btn complete-btn"
                            onClick={() => handleCloseOnboarding('OnboardingOrganizerVideos')}
                        >
                            {t(langs.ONBOARDING_COMPLETE)}
                        </button>
                    </>
                ),
            }
        );
    }

    function dropDownMenu() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__burger__button"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_HOME_MENU_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_HOME_MENU)}</p>
                        <button
                            type="button"
                            className="btn complete-btn"
                            onClick={() => handleCloseOnboarding('OnboardingOrganizerVideos')}
                        >
                            {t(langs.ONBOARDING_COMPLETE)}
                        </button>
                    </>
                ),
                position: 'bottom',
            }
        );
    }

    const mobileSteps = [
        editLive(),
        dropDownMenu(),
    ];

    const desktopSteps = [
        editLive(),
        dashboard(),
        qrCode(),
        quizzes(),
        reports(),
        blockListChat(),
        engagement(),
        preview(),
        graph(),
        monitorsList(),
        gelocalization(),
    ];

    function defineSteps(width, user) {
        const filteredSteps = width > 800 ? desktopSteps : mobileSteps;
        if (!(user.admin || user.organizer)) {
            const userSteps = filteredSteps.filter(step => step.level === 'all');
            return userSteps;
        }
        return filteredSteps;
    }

    const steps = defineSteps(width, user);

    function onScroll(step) {
        if (step > 0) {
            document.querySelector(steps[step].selector).scrollIntoView({ block: 'center' });
        }
    }

    return (
        <Onboarding
            steps={(steps)}
            isOpen={(isTourOpen)}
            getCurrentStep={onScroll}
            maskClassName="onboardingOverlay"
            accentColor="#ff438d"
            disableInteraction
            closeWithMask={false}
            rounded={4}
            onRequestClose={() => handleCloseOnboarding('OnboardingOrganizerVideos', true)}
            closeButtonAriaLabel={t(langs.ONBOARDING_COMPLETE)}
        />
    );
}
