import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useRooms from '@src/hooks/useRooms';
import langs from '../../../../assets/i18n/constants';
import { ActiveMonitorsListContainer, ActiveMonitorsListContent, ActiveMonitorsTitle, LineTable } from './styles';

const ActiveMonitorsList = () => {
    const [activeMonitors, setActiveMonitors] = useState([]);
    const { roomListActiveMonitors } = useRooms();
    const { t } = useTranslation();

    useEffect(() => {
        const sortedList = roomListActiveMonitors?.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
        setActiveMonitors(sortedList);
    }, [roomListActiveMonitors]);

    return (
        <ActiveMonitorsListContainer data-testid="active-monitors-list" data-tour="tour__monitors_list">
            <ActiveMonitorsTitle>{t(langs.MONITORS)?.toUpperCase()} ONLINE</ActiveMonitorsTitle>
            <ActiveMonitorsListContent>
                <TableContainer>
                    <Table aria-label="customized table">
                        <TableHead>
                            <tr>
                                <th align="center">{t(langs.NAMECAPS)}</th>
                                <th align="center">{t(langs.EMAIL)}</th>
                            </tr>
                        </TableHead>
                        <TableBody>
                            {activeMonitors?.map((monitorData, index) => (
                                <LineTable key={index} index={index}>
                                    <td align="center">{monitorData?.name}</td>
                                    <td align="center">{monitorData?.email}</td>
                                </LineTable>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ActiveMonitorsListContent>
        </ActiveMonitorsListContainer>
    );
};

export default ActiveMonitorsList;
