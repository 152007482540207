import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;

    .div-nexos {
        margin-right: 20px;
        margin-bottom: 20px;
    }
`;

export const Content = styled.div`
    position: relative;
    width: 260px;
    display: grid;
    grid-template-columns: 45px calc(100% - 80px) 30px;
    gap: 10px;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    background-color: #212032;
    justify-content: space-between;

    div.upload-info {
        width: 28px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        button {
            height: 100%;
            background-color: #fcefe2;
            background-image: linear-gradient(to bottom,#ff9460,#ff617a 100%);
            border: none;
            border-radius: 5px;
            padding: 8px;
            transition: background-color 0.2s;

            &:hover {
                background-color: #fdf9f5;
            }
        }
    }
`;

export const FileInfo = styled.div`
    width: 100%;
    word-wrap: break-word;
    margin: 4px 0;

    span {
        color: #989797;
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: 600;
    }
`;

export const Preview = styled.div`
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 5px;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
`;

export const AttachIcon = styled.img`
    width: 30px;
    height: 44px;
`;
