/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import screenFull from 'screenfull';

import { fetchEditRoom } from '../../store/modules/rooms/actions';

import { Container, VideoPreview, DevicesContainer, Device, BackToListButton } from './styles';
import useRooms from '../../hooks/useRooms';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import useQuery from '../../hooks/useQuery';
import paths from '../../utils/paths';
import webRTCSocket from '../../services/WebRTCSocket';
import mediaSoup from '../../services/MediaSoupConsumerService';
import api from '../../services/api';
import producerMicIcon from '../../assets/producer-mic-icon.svg';
import producerMicMuteIcon from '../../assets/producer-mic-mute-icon.svg';
import producerCamIcon from '../../assets/producer-cam-icon.svg';
import producerCamMuteIcon from '../../assets/producer-cam-mute-icon.svg';
import fullScreenIcon from '../../assets/full-screen-icon.svg';
import noVideoIcon from '../../assets/no-video.svg';
import { NoVideo } from '../VideoWall/VideoCardsList/styles';

export default function GuestPresenterView() {
    const { t } = useTranslation();
    const { videoUrl, socketPath } = useRooms();
    const { roomId, guestId } = useParams();
    const { setItems } = useBreadcrumbs();
    const query = useQuery();
    const dispatch = useDispatch();
    const history = useHistory();

    const [isMutedUser, setIsMutedUser] = useState(null);
    const [isCamMute, setIsCamMute] = useState(false);
    const [isMicMute, setIsMicMute] = useState(false);
    const [guestName, setGuestName] = useState('');
    const [showControls, setShowControls] = useState(true);
    const [isVideoPaused, setIsVideoPaused] = useState(false);

    const videoRef = useRef();
    const videoWrapper = useRef();
    const showControlsTimeRef = useRef();

    const accessToken = api.getAccessToken();
    const previousPage = query.get('prevPage');

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t('ORGANIZER_DASHBOARD') },
            { to: `${paths.GUEST_PRESENTER}/${roomId}`, label: t('GUEST_PRESENTER') },
            { to: `${paths.GUEST_PRESENTER_VIEW}/${roomId}/${guestId}?guestName=${guestName}`, label: t('BUTTON_GUEST') },
        ];
        setItems(breadcrumbsItems);
    }, [roomId, setItems, guestId, t, guestName]);

    useEffect(() => {
        const guestNameFromSearch = history.location?.search;
        if (guestNameFromSearch) {
            const rawGuestName = guestNameFromSearch.split('=')[1];
            setGuestName(rawGuestName);
        }
    }, [history.location.search]);

    useEffect(() => () => mediaSoup.uninvite(guestId), [guestId]);

    const handleOnGotProducersList = useCallback((producersList) => {
        mediaSoup.processConsume(producersList.filter(user => user.id === String(guestId)));
        if (producersList?.length) setIsMutedUser(producersList[0]?.blocked);
        mediaSoup.invite(String(guestId));
    }, [guestId]);

    async function handleMuteCamera() {
        const index = mediaSoup._producers.findIndex(user => user.id === String(guestId));
        const track = mediaSoup._producers[index]?.stream.getVideoTracks()[0];
        if (track) {
            track.enabled = !track.enabled;
            setIsCamMute(!track.enabled);
        }
    }

    async function handleMuteMicrofone() {
        const index = mediaSoup._producers.findIndex(user => user.id === String(guestId));
        const track = mediaSoup._producers[index]?.stream.getAudioTracks()[0];
        if (track) {
            track.enabled = !track.enabled;
            setIsMicMute(!track.enabled);
        }
    }

    function handleFullScreen() {
        if (screenFull.isEnabled) {
            screenFull.toggle(videoWrapper.current);
        }
    }

    function handleGoBackToUsersList() {
        switch (previousPage) {
            case 'video_wall':
                history.push(`${paths.VIDEO_WALL}/${roomId}`);
                break;
            case 'guest_list':
                history.push(`${paths.GUEST_PRESENTER}/${roomId}`);
                break;
            default:
                history.goBack();
        }
    }

    async function handleSetGuestStream(user) {
        const audioStream = await mediaSoup.getProducerAudio(user.id);
        user.stream.addTrack(...audioStream.getAudioTracks());
        if (videoRef.current) videoRef.current.srcObject = user.stream;
        mediaSoup.requestChangeMediaQuality(user.id, 'video');

        if (user.isPaused) {
            setIsVideoPaused(true);
        }
    }

    const handleConnectWebRTC = useCallback(() => {
        mediaSoup.onGotProducersListListener = handleOnGotProducersList;
        mediaSoup.onGotStream = handleSetGuestStream;
        mediaSoup.onPauseVideoListener = () => setIsVideoPaused(true);
        mediaSoup.onResumeVideoListener = () => setIsVideoPaused(false);
        mediaSoup.onMutedUserListener = () => setIsMutedUser(true);
        mediaSoup.onUnmutedUserListener = () => setIsMutedUser(false);
        mediaSoup.setSocket(webRTCSocket.socket);
        mediaSoup.ignoreNewProducer = true;
        mediaSoup.connect();
    }, [handleOnGotProducersList]);

    useEffect(() => {
        if (videoUrl && socketPath && accessToken) {
            if (!webRTCSocket.socket?.connected) {
                webRTCSocket.onConnectListener.push(handleConnectWebRTC);
                webRTCSocket.authenticate(videoUrl, socketPath, accessToken, true);
            } else {
                handleConnectWebRTC();
            }
        }
    }, [socketPath, videoUrl, accessToken, handleConnectWebRTC]);

    useEffect(() => {
        if (!videoUrl || socketPath) {
            dispatch(fetchEditRoom(roomId));
        }
    }, [dispatch, roomId, socketPath, videoUrl]);

    useEffect(() => () => {
        mediaSoup.removeConsumer(String(guestId), 'video');
        mediaSoup.removeConsumer(String(guestId), 'audio');
        mediaSoup.ignoreNewProducer = false;
    }, [guestId]);

    const videoControlsIds = [
        'guest-presenter-video',
        'devices-wrapper',
        'device-item',
    ];

    const handleShowControls = useCallback((e) => {
        if (!screenFull.isFullscreen) {
            return;
        }

        if (videoControlsIds.includes(e.target.id) || e.target.type === 'button') {
            clearTimeout(showControlsTimeRef.current);
        }

        if (e.target.id === 'guest-presenter-video') {
            if (showControlsTimeRef.current) {
                clearTimeout(showControlsTimeRef.current);
            }

            setShowControls(true);

            showControlsTimeRef.current = setTimeout(() => {
                setShowControls(false);
                showControlsTimeRef.current = null;
            }, 1000);
        }
    }, [videoControlsIds]);

    useEffect(() => {
        screenFull.on('change', () => {
            if (!screenFull.isFullscreen) setShowControls(true);
        });
    }, []);

    return (
        <Container>
            <VideoPreview>
                <section className="video-container">
                    <div className="video-header">
                        <h5> {query.get('guestName') || ''} </h5>
                    </div>
                    <div className="video-player" ref={videoWrapper} onMouseMove={handleShowControls}>
                        {(isVideoPaused || isCamMute || isMutedUser) && (
                            <NoVideo width="100%">
                                <img src={noVideoIcon} alt="No video available" />
                                <h3>{isCamMute ? t('VIDEO_OFF') : isMutedUser ? t('MUTED_USER').toUpperCase() : t('VIDEO_UNAVAILABLE')}</h3>
                            </NoVideo>
                        )}
                        <video ref={videoRef} autoPlay id="guest-presenter-video" />
                        <div className="video-details">
                            <DevicesContainer id="devices-wrapper" showMediaControls={showControls}>
                                <div>
                                    <div className="device-wrapper">
                                        <Device onClick={handleMuteMicrofone}>
                                            {isMicMute ? (
                                                <img src={producerMicMuteIcon} alt="Microfone" />
                                            ) : (
                                                <img src={producerMicIcon} alt="Microfone" />
                                            )}
                                        </Device>
                                    </div>

                                    <div className="device-wrapper">
                                        <Device className={isCamMute ? 'mute-device' : ''} onClick={handleMuteCamera}>
                                            {isCamMute ? (
                                                <img src={producerCamMuteIcon} alt="Camera" />
                                            ) : (
                                                <img src={producerCamIcon} alt="Camera" />
                                            )}
                                        </Device>
                                    </div>

                                    <div className="device-wrapper">
                                        <Device onClick={handleFullScreen}>
                                            <img src={fullScreenIcon} alt="Full Screen" />
                                        </Device>
                                    </div>
                                    <BackToListButton className="end-transmission" id="device-item" onClick={handleGoBackToUsersList}>
                                        {t('BACK_TO_GUEST_PRESENTER_LIST')}
                                    </BackToListButton>
                                </div>
                            </DevicesContainer>
                        </div>
                    </div>
                </section>
            </VideoPreview>
        </Container>
    );
}
