/* eslint-disable indent */
import {
    all, put, takeLatest, takeEvery, select, takeLeading, delay,
} from 'redux-saga/effects';

import axios from 'axios';
import constants from '@src/assets/i18n/constants';
import history from '../../../services/history';
import api from '../../../services/api';
import * as roomActions from './actions';
import { defaultErrorHandler } from '../../utilities';
import actions from '../../../utils/actions';
import { translate } from '../../../utils/functions';
import { ROOM_STATUS } from '../../../utils/constants';
import Notify from '../../../utils/notification';

function* getRooms() {
    try {
        const { channels } = yield api.getUserRooms();
        yield put(roomActions.setRooms(channels));
    } catch (error) {
        defaultErrorHandler(error, { default: translate('CHANNEL_FAILREQUESTROOMS') });
    }
}

function* getUserRooms() {
    try {
        const { channels } = yield api.getUserRooms();
        yield put(roomActions.setRooms(channels));
    } catch (error) {
        defaultErrorHandler(error, { default: translate('CHANNEL_FAILREQUESTROOM') });
    }
}

function* getRoom({ payload: roomID }) {
    try {
        const room = yield api.getRoom(roomID);
        yield put(roomActions.setRoom(room));
        if (room.live) {
            yield put(roomActions.enterChat(roomID));
        }
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('CHANNEL_FAILREQUESTROOM'),
            404: translate('CHANNEL_ROOMNOTFOUND'),
        });

        if (error.response) {
            switch (error.response.status) {
                case 403: {
                    const { courseId, roomId, dataTermUrl } = error.response.data;
                    history.push(`/terms/${courseId}/${roomId}/${encodeURIComponent(dataTermUrl)}`);
                    break;
                }
                case 404: {
                    history.replace('/');
                    break;
                }
                default:
                    break;
            }
        }
    }
}

function* getRoomEdit({ payload: { roomId, currentRoom } }) {
    if (roomId) {
        try {
            if (currentRoom?.recorded) {
                const room = yield api.getRoom(roomId);
                const { startDate, endDate } = currentRoom;
                yield put(roomActions.setEditRoom({ ...room, endDate, startDate }));
            } else {
                const room = yield api.getRoomEdit(roomId);
                yield put(roomActions.setEditRoom(room));
            }
        } catch (error) {
            yield put({ type: actions.ROOM_FAIL });
            defaultErrorHandler(error, {
                default: translate('CHANNEL_FAILREQUESTROOM'),
                404: translate('CHANNEL_ROOMNOTFOUND'),
            });
            if (error.response) {
                switch (error.response.status) {
                    case 404:
                        history.replace('/');
                        break;
                    default:
                        break;
                }
            }
        }
    }
    yield put({ type: actions.ROOM_FAIL });
}

function* getRoomEditLinks({ payload: { roomId, currentRoom } }) {
    if (roomId) {
        try {
            if (currentRoom?.recorded) {
                const room = yield api.getRoom(roomId);
                const { startDate, endDate } = currentRoom;
                yield put(roomActions.setEditRoomLinks({ ...room, endDate, startDate }));
            } else {
                const room = yield api.getRoomEditLinks(roomId);
                yield put(roomActions.setEditRoomLinks(room));
            }
        } catch (error) {
            yield put({ type: actions.ROOM_FAIL });
            defaultErrorHandler(error, {
                default: translate('CHANNEL_FAILREQUESTROOM'),
                404: translate('CHANNEL_ROOMNOTFOUND'),
            });
            if (error.response) {
                switch (error.response.status) {
                    case 404:
                        history.replace('/');
                        break;
                    default:
                        break;
                }
            }
        }
    }
    yield put({ type: actions.ROOM_FAIL });
}

function* editRoom({ payload }) {
    try {
        yield api.editRoom(payload.room, payload.id);
        yield put({ type: 'USER_ROOMS_REQUEST' });
        Notify.success(translate('CHANGES_SUCCESS'));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                case 403:
                    Notify.warn(translate('UNABLE_TO_EDIT_VIDEO'));
                    break;
                default:
                    Notify.warn(translate('EDIT_ROOM_FAIL'));
                    break;
            }
        }
    }
}

function* createRoom({ payload }) {
    try {
        yield api.createRoom(payload);
        yield put(roomActions.getChannelRooms(payload?.course_id));
        Notify.success(translate('CREATE_ROOM_SUCCESS'));
        history.push('/');
    } catch (error) {
        yield put({ type: actions.ROOM_FAIL });
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                default:
                    break;
            }
        }
    }
}

function* exitRoom() {
    try {
        const selectedRoom = yield select(state => state.room.selectedRoom);
        if (selectedRoom && selectedRoom.id) {
            yield put(roomActions.exitChat(selectedRoom.id));
        }
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_EXITROOM') });
    } finally {
        yield put(roomActions.clearSelected());
    }
}

function* activeGoLive({ payload }) {
    const { roomId, noPresenterErrorCallBack } = payload;
    try {
        yield api.activeGoLive(roomId);
        yield put({ payload: roomId, type: actions.ROOM_EDIT_SELECT });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                case 403:
                    if (noPresenterErrorCallBack) {
                        noPresenterErrorCallBack();
                    }
                    break;
                default:
                    break;
            }
        }
    }
}

function* activeEndLive({ payload: roomId }) {
    try {
        yield api.activeEndLive(roomId);
        yield put({ payload: roomId, type: actions.ROOM_EDIT_SELECT });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                default:
                    break;
            }
        }
    }
}

function* isRoomLive({ payload: roomId }) {
    try {
        const live_status = yield api.isRoomLive(roomId);
        yield put({ payload: live_status, type: actions.ROOM_SET_STATUS_ROOM });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                default:
                    break;
            }
        }
    }
}

function* getRoomEngagement({ payload: roomId }) {
    try {
        const data = yield api.getRoomEngagement(roomId);
        yield put({ payload: data, type: actions.ROOM_SET_ENGAGEMENT });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                default:
                    break;
            }
        }
    }
}

function* getRoomEventLog({ payload: roomId }) {
    try {
        const { data } = yield api.getRoomEventLog(roomId);
        yield put({ payload: data, type: actions.ROOM_SET_EVENTLOG });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                default:
                    break;
            }
        }
    }
}

function* getRoomGraph({ payload }) {
    try {
        const { roomID, startDate, endDate } = payload;
        const { data } = yield api.getRoomGraph(roomID, startDate, endDate);
        yield put({ payload: data, type: actions.ROOM_SET_GRAPH });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                default:
                    break;
            }
        }
    }
}

function* getRoomGeolocalization({ payload }) {
    try {
        const { roomID, startDate, endDate } = payload;
        const data = yield api.getRoomGeolocalization(roomID, startDate, endDate);
        yield put({ payload: data, type: actions.ROOM_SET_GEOLOCALIZATION });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                default:
                    break;
            }
        }
    }
}

function* sendMessage({ payload: { roomID, message, type } }) {
    try {
        if (type === 1) { // questions
            yield api.sendQuestion(roomID, message);
        } else if (type === 2) { //
            yield api.sendMessageMonitorChat(roomID, message);
        } else if (type === 3) {
            yield api.sendMessageTeamChat(roomID, message);
        } else {
            yield api.sendMessage(roomID, message);
        }
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('WARNINGS_MESSAGES_FAIL'),
            400: translate('WARNINGS_MESSAGES_NOTALLOWED'),
            403: translate('PERMISSION_SENDMESSAGE'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
            429: translate('WARNINGS_MESSAGES'),
        });
    }
}

function* enterChat({ payload: roomID }) {
    try {
        const chatUserType = yield api.enterChat(roomID);
        yield put({ type: actions.ROOM_SET_CHAT_USER_TYPE, payload: { chatUserType } });
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('FAIL_ENTERROOM'),
            403: translate('PERMISSION_ENTERCHAT'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* exitChat({ payload: roomID }) {
    try {
        yield api.exitChat(roomID);
        yield put(roomActions.setChatTabs(null));
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('FAIL_EXITROOM'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* replyMessage({ payload: { roomID, text, originalMessageId } }) {
    try {
        yield api.sendReply(roomID, text, originalMessageId);
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('WARNINGS_MESSAGES_FAIL'),
            403: translate('WARNINGS_MESSAGES_PERMISSIONS'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* roomPinMesssage({ payload: { roomID, messageID } }) {
    try {
        yield api.pinMessage(roomID, messageID);
        yield put({ type: 'ROOM_REQUEST', payload: roomID });
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('FAIL_PINMESSAGE'),
            403: translate('PERMISSION_PINMESSAGE'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* roomUnpinMesssage({ payload: { roomID, messageID } }) {
    try {
        yield api.unpinMessage(roomID, messageID);
        yield put({ type: 'ROOM_REQUEST', payload: roomID });
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('FAIL_UNPINMESSAGE'),
            403: translate('PERMISSION_UNPINMESSAGE'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* setChatEnableRoom({ payload }) {
    try {
        const { selectedRoom } = yield select(({ room }) => room);
        if (selectedRoom.id === payload.room.roomId) {
            yield put(
                {
                    type: actions.ROOM_CHAT_SET_CHAT_ENABLE_ROOM,
                    payload: { isChatEnabled: payload.room.enabled },
                },
            );
        }
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_TO_ENABLE_ROOM') });
    }
}

function* setChatEnableTeam({ payload }) {
    try {
        const { enabled, roomId } = payload;
        const { selectedRoom } = yield select(({ room }) => room);
        if (payload && selectedRoom.id === roomId) {
            yield put(
                {
                    type: actions.ROOM_CHAT_SET_ENABLE_TEAM,
                    payload: { isEnabledTeamChat: enabled },
                },
            );
        }
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_TO_ENABLE_TEAM') });
    }
}

function* handleRoomRequests(action) {
    if (action.type === 'ROOM_REQUEST') {
        yield getRoom(action);
    } else if (action.type === 'ROOM_EXIT') {
        yield exitRoom(action);
    }
}

function* setTermOfUsage({ payload }) {
    try {
        const { courseId, userId, roomId } = payload;
        yield api.setUserTerm(courseId, userId);
        history.push(`/course/${roomId}`);
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_TO_SET_TERMS') });
    }
}

function* setChatEnabled({ payload }) {
    try {
        const { roomID, chatEnabled } = payload;
        yield api.setChatEnabled(roomID, chatEnabled);
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_TO_SET_CHAT') });
    }
}

export function* pollStatus() {
    while (true) {
        const { isUploadingInBackground, selectedChannelRooms } = yield select(({ room }) => room);
        const filteredUploads = [];

        if (isUploadingInBackground.length) {
            isUploadingInBackground.forEach(upload => {
                if (!filteredUploads.some(video => video.id === upload.id)) {
                    filteredUploads.push(upload);
                }
            });

            yield delay(5000);

            let newRooms = [...selectedChannelRooms];
            let currentlyUploading = [...filteredUploads];

            const responses = yield Promise.allSettled(currentlyUploading.map(room => api.getRoomStatus(room.id)));
            for (let i = 0; i < responses.length; i += 1) {
                const { status: promiseStatus, value: { status } } = responses[i];
                const { id, name } = isUploadingInBackground[i];

                if (promiseStatus === 'rejected') {
                    currentlyUploading = currentlyUploading.filter(video => video.id !== id);
                    Notify.error(translate(constants.ROOM_POLL_STATUS_NOTIFY_FAILED).replace('[NAME]', name));
                } else if (status === ROOM_STATUS.UPLOADED || status === ROOM_STATUS.DONE) {
                    currentlyUploading = currentlyUploading.filter(video => video.id !== id);
                    Notify.success(translate(constants.ROOM_POLL_STATUS_NOTIFY_SUCCESS).replace('[NAME]', name));
                }
                if (status === 'converting') {
                    newRooms = newRooms.map(video => (video.id === id ? ({ ...video, status }) : video));
                    yield put(roomActions.getChannelRoomsSuccess(newRooms));
                }
            }

            if (currentlyUploading.length !== isUploadingInBackground.length) {
                yield put(roomActions.setIsUploadingInBackground(currentlyUploading));
            }
        }

        yield delay(5000);
    }
}

/**
 *
 * @param {{ payload: { videoToUpload: File, roomObject: {} } }} param0
 */
export function* uploadVideo({ payload }) {
    try {
        const { roomObject, videoToUpload } = payload;
        const uploadFileName = videoToUpload.name;
        Notify.success(translate('UPLOAD_VIDEO_PREPARING'));
        const data = yield api.getVideoUploadUrl(roomObject);
        const tempRoom = {
            endDate: roomObject?.end_date,
            id: data.room_id,
            image: roomObject?.thumbnail_url,
            live: false,
            name: roomObject?.room_name,
            recorded: true,
            startDate: roomObject?.start_date,
            status: ROOM_STATUS.UPLOADING,
            course_id: roomObject.course_id,
            upload_progress: null,
        };
        const { selectedChannelRooms, isUploadingInBackground } = yield select(({ room }) => room);

        yield put(roomActions.getChannelRoomsSuccess([tempRoom, ...selectedChannelRooms]));

        yield put(roomActions.setIsUploadingInBackground([tempRoom, ...isUploadingInBackground]));

        let chunkPosition = 1;
        const blocksize = 10240000;
        const chunks = Math.ceil(videoToUpload.size / blocksize, blocksize);
        let formData = new FormData();
        let slice;
        const param = { 'Content-Type': 'multipart/form-data', params: { format: 1 } };

        const uploadUrl = data.url;
        if (uploadUrl.includes('format=')) {
            delete param.params.format;
        }

        while (chunkPosition <= chunks) {
            const blockStart = (chunkPosition - 1) * blocksize;
            slice = videoToUpload.slice(blockStart, chunkPosition * blocksize, videoToUpload.type);
            formData = new FormData();
            formData.append('fileData', slice, uploadFileName);
            formData.append('ks', data.ks);
            formData.append('uploadTokenId', data.upload_token_id);
            formData.append('resume', chunkPosition !== 1);
            formData.append('resumeAt', chunkPosition === 1 ? '0' : '-1');
            formData.append('finalChunk', chunkPosition === chunks);
            const response = yield axios.post(uploadUrl, formData, param);
            if (!response.data || response.data.objectType === 'KalturaAPIException' || response.data.objectType !== 'KalturaUploadToken') {
                Notify.error(translate().replace('[ROOM_NAME]', roomObject?.room_name).replace('[ERROR_DATA]', JSON.stringify(response.data)));
                console.error('Chunk upload error', response.data);
                return;
            }
            tempRoom.upload_progress = ((chunkPosition / chunks) * 100).toFixed(2);
            yield put(roomActions.setIsUploadingInBackground([tempRoom, ...isUploadingInBackground]));

            chunkPosition += 1;
        }
        tempRoom.upload_progress = null;
        yield put(roomActions.setIsUploadingInBackground([tempRoom, ...isUploadingInBackground]));
    } catch (error) {
        defaultErrorHandler(error, { default: translate('UPLOAD_VIDEO_FAILURE') });
    }
}

function* getChannels() {
    try {
        const channels = yield api.getChannels();
        yield put(roomActions.setChannels(channels.channels));
    } catch (error) {
        defaultErrorHandler(error, { default: translate('GET_CHANNELS_FAILURE') });
    }
}

function* getChannelRooms({ payload }) {
    try {
        const { rooms, hasGamification, extra_user_supports } = yield api.getChannelRooms(payload);
        yield put(roomActions.setHasExtraUsers(extra_user_supports));
        yield put(roomActions.setHasgamification(hasGamification));
        yield put(roomActions.getChannelRoomsSuccess(rooms));
        const roomsToPoll = rooms.filter(room => room.status === ROOM_STATUS.CONVERTING);
        yield put(roomActions.setIsUploadingInBackground([...roomsToPoll]));
    } catch (error) {
        yield put(roomActions.getChannelRoomsFailed());
        defaultErrorHandler(error, { default: translate('GET_COURSE_FAILURE') });
    }
}

function* mutedParticipantRequest({ payload }) {
    try {
        const { roomId, userEmail } = payload;
        yield api.muteParticipant(roomId, userEmail);
    } catch (error) {
        defaultErrorHandler(error, { default: translate('MUTED_PARTICIPANT_ERROR') });
    }
}

function* unmutedParticipantRequest({ payload }) {
    try {
        const { roomId, userEmail } = payload;
        yield api.unmuteParticipant(roomId, userEmail);
    } catch (error) {
        defaultErrorHandler(error, { default: translate('UNMUTED_PARTICIPANT_ERROR') });
    }
}

function* getRoomOnlineMonitors({ payload }) {
    try {
        const res = yield api.getRoomOnlineMonitorsRequest(payload);
        yield put({ type: actions.GET_ONLINE_MONITORS_SUCCESS, payload: res.instructors });
    } catch (error) {
        yield put({ type: actions.GET_ONLINE_MONITORS_FAIL });
    }
}

function* listTutorials() {
    try {
        const tutorials = yield api.listTutorials();
        yield put(roomActions.setTutorials(tutorials));
    } catch (error) {
        defaultErrorHandler(error, { default: translate(constants.FAIL_TO_LOAD_TUTORIALS) });
    }
}

function* videoTutorials({ payload: tutorialId }) {
    try {
        const tutorial = yield api.videoTutorials(tutorialId);
        yield put(roomActions.setVideoTutorials(tutorial));
    } catch (error) {
        defaultErrorHandler(error, { default: translate(constants.FAIL_TO_LOAD_TUTORIALS) });
    }
}

export default all([
    takeLatest(actions.GET_CHANNELS_FOR_SIDEBAR, getChannels),
    takeLatest('ROOMS_REQUEST', getRooms),
    takeLatest('USER_ROOMS_REQUEST', getUserRooms),
    takeLatest(['ROOM_REQUEST', 'ROOM_EXIT'], handleRoomRequests),
    takeLatest('ROOM_SEND_MESSAGE', sendMessage),
    takeLatest('ROOM_ENTER_CHAT', enterChat),
    takeLeading('ROOM_EXIT_CHAT', exitChat),
    takeLatest(actions.ROOM_EDIT_SELECT, getRoomEdit),
    takeLatest(actions.ROOM_EDIT_SELECT_LINKS, getRoomEditLinks),
    takeLatest(actions.ROOM_IS_LIVE, isRoomLive),
    takeLatest(actions.ROOM_REPLY_MESSAGE, replyMessage),
    takeLatest(actions.ROOM_CHAT_ASYNC_SET_CHAT_ENABLE_ROOM, setChatEnableRoom),
    takeLatest(actions.CHANGE_TEAM_CHAT, setChatEnableTeam),
    takeLatest(actions.ROOM_CREATE, createRoom),
    takeLatest(actions.ROOM_EDIT, editRoom),
    takeLatest(actions.ROOM_GOLIVE, activeGoLive),
    takeLatest(actions.ROOM_ENDLIVE, activeEndLive),
    takeLatest(actions.ROOM_ENGAGEMENT, getRoomEngagement),
    takeLatest(actions.ROOM_EVENTLOG, getRoomEventLog),
    takeLatest(actions.ROOM_GRAPH, getRoomGraph),
    takeLatest(actions.ROOM_GEOLOCALIZATION, getRoomGeolocalization),
    takeLatest(actions.ROOM_PIN_MESSAGE, roomPinMesssage),
    takeLatest(actions.ROOM_UNPIN_MESSAGE, roomUnpinMesssage),
    takeLatest(actions.ROOM_SET_CHAT_ENABLED, setChatEnabled),
    takeLatest(actions.ACCEPT_TERMS_OF_USAGE, setTermOfUsage),
    takeEvery(actions.ROOM_VIDEO_UPLOAD, uploadVideo),
    takeLatest(actions.GET_COURSE_REQUEST, getChannelRooms),
    takeLatest(actions.GET_ONLINE_MONITORS_REQUEST, getRoomOnlineMonitors),
    takeLatest(actions.MUTED_PARTICIPANT_REQUEST, mutedParticipantRequest),
    takeLatest(actions.UNMUTED_PARTICIPANT_REQUEST, unmutedParticipantRequest),
    pollStatus(),
    takeLatest('ROOMS_TUTORIALS', listTutorials),
    takeLatest('VIDEO_TUTORIALS', videoTutorials),
]);
