export const setUser = user => ({
    type: 'SET_USER',
    payload: { user },
});

export const logout = () => ({ type: 'LOGOUT' });

export const changeUserLanguage = language => ({
    type: 'CHANGE_USER_LANGUAGE',
    payload: language,
});

export function clearAll() {
    return { type: 'CLEAR_ALL' };
}

export const getUserProfile = () => ({ type: 'GET_USER_PROFILE' });

export const getUserError = (error) => ({
    type: 'GET_USER_ERROR',
    payload: error,
});

export const editProfileRequest = (profile) => ({
    type: 'EDIT_PROFILE_REQUEST',
    payload: profile,
});

export const editProfileSuccess = (profile) => ({
    type: 'EDIT_PROFILE_SUCCESS',
    payload: profile,
});

export const editProfileFail = (error) => ({
    type: 'EDIT_PROFILE_FAIL',
    payload: error,
});

export const setToken = () => ({ type: 'SET_TOKEN' });

export const clearUserInfo = () => ({ type: 'CLEAR_USER_INFO' });

export const getOnBoardingStatus = (key) => (
    {
        type: 'GET_ONBOARDING_STATUS',
        payload: key,
    }
);

export const setOnBoardingStatus = (onBoarding) => (
    {
        type: 'SET_ONBOARDING_STATUS',
        payload: onBoarding,
    }
);

export const setOnBoarding = (key, skipped, global_skip) => (
    {
        type: 'SET_ONBOARDING',
        payload: { key, skipped, global_skip },
    }
);

export const changeOnBoardingStatus = () => ({ type: 'CHANGE_ONBOARDING_STATUS' });
