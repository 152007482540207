import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { VideoPlayer as TokyoPlayer } from 'react-tokyo';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { exitRoom, getRooms, videoTutorials } from '@src/store/modules/rooms/actions';
import VideoPlayerSkeleton from '@src/components/VideoPlayerSkeleton';
import constants from '@src/assets/i18n/constants';
import { useBreadcrumbs } from '@hooks/useBreadcrumbs';
import OverlayVideoControls from '@src/components/OverlayVideoControls';
import paths from '@utils/paths';
import { Container, VideoPlayer } from './styles';

function selector({ room, login }) {
    return {
        tutorial: room.selectedTutorial,
        user: login.user,
    };
}

function getRoomId(history) {
    const path = history.location.pathname;
    return path.split('/')[2];
}

const idleTime = 1500;

const PlayerTutorials = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { user, tutorial } = useSelector(selector, shallowEqual);
    const videoWrapper = useRef();
    const { userId } = user || {};
    const { setItems } = useBreadcrumbs();
    const [show, setShow] = useState(false);
    const timeoutRef = useRef();
    const iframe = document.querySelector('#player_ifp');
    const holder = document.querySelector('.video-container');
    const { tutorialId } = useParams();

    useEffect(() => {
        dispatch(getRooms(getRoomId(history)));

        return function cleanup() {
            dispatch(exitRoom());
        };
    }, [dispatch, history]);

    useEffect(() => {
        if (tutorialId) {
            dispatch(videoTutorials(tutorialId));
        }
    }, [dispatch, tutorialId]);

    function maintainTimeout() {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => setShow(false), idleTime);
    }

    const onMouseMove = useCallback(() => {
        setShow(true);
        maintainTimeout();
    }, []);

    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t(constants.ORGANIZER_DASHBOARD) },
            { to: paths.TUTORIAL, label: t(constants.TUTORIALS) },
        ];
        setItems(breadcrumbsItems);
    }, [setItems, t]);

    useEffect(() => {
        let videoHolder = null;
        if (iframe) {
            videoHolder = iframe.contentWindow?.document.querySelector('.videoHolder');
            if (videoHolder) {
                videoHolder.addEventListener('mousemove', onMouseMove);
            }
        }

        if (videoHolder) {
            return () => videoHolder.removeEventListener('mousemove', onMouseMove);
        }
        return () => null;
    }, [iframe, onMouseMove]);

    useEffect(() => {
        if (holder) {
            holder.addEventListener('mousemove', onMouseMove);
        }
        return () => {
            if (holder) {
                holder.removeEventListener('mousemove', onMouseMove);
            }
        };
    }, [onMouseMove, holder]);

    function classicCourseSection() {
        return (
            <VideoPlayer>
                <>
                    <section className="video-container">
                        <div className="video-header">
                            <h5>{ tutorial.name }</h5>
                        </div>
                        <div className="video-player" ref={videoWrapper}>
                            <TokyoPlayer
                                key={tutorial.video_url}
                                overlayVideoControls={(handles) => <OverlayVideoControls {...handles} show={show} maintainTimeout={maintainTimeout} />}
                                sendProgressPosition
                                disableNativePlayerFullScreenControl
                                decodeUrl={tutorial.video_url}
                                watermark={userId}
                                quizComponent={() => { }}
                            />
                        </div>
                    </section>
                </>
            </VideoPlayer>
        );
    }

    return (
        <Container>
            {tutorial?.video_url === undefined ? <VideoPlayerSkeleton /> : classicCourseSection()}
        </Container>
    );
};

export default PlayerTutorials;
