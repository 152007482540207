import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
    width: 100%;
    max-height: 90vh;
    overflow: auto;
`;

export const QuestionSection = styled.section``;

export const Title = styled.h4`  
    font-size: clamp(12px, 0.4em + 1vw, 1.2em);
    font-weight: 800;    
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 20px;
`;

export const Question = styled.p`
    font-size: clamp(12px, 0.4em + 1vw, 1.2em);    
    color: #ffffff;
    text-align: center;
    font-weight: 600;
    small {
        font-size: clamp(12px, 0.4em + 1vw, 1.2em);    
        color: #ffffff;
        text-align: center;
        font-weight: 600;
        color: #ff8e63;
        text-transform: uppercase;
    }
`;

export const AnswersSection = styled.section`
    margin-top: 30px;

    .accordion-container {
        margin: 10px auto;
    }
`;

export const ResultCardContainer = styled.div`
    display: flex;
    align-items: center; 
    padding: 18px;
    min-height: 80px;
`;

export const ResultCardContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 6px;
    
    h4 {
        font-size: 40px;    
        color: #fff;
        text-transform: uppercase;
    }

    h5 {
        font-size: clamp(12px, 0.4em + 1vw, 1em);    
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.34px;
        color: #ffffff;
    }
    h6 {
        font-size: clamp(12px, 0.4em + 1vw, 1em);    
        font-weight: bold;
        letter-spacing: 0.36px;        
        color: #38bbbc;

        &.incorrect {
            color: #ff6876;
        }
    }

    .content-item {
        background-color: #24243e;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin: 15px auto;

        div {
            margin-left: 22px;
        }
    }

`;
export const Left = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2b2b4e;
    border-radius: 6px;
    width: 120px;
    height: 100%;
    position: relative;
    z-index: 10;    

    &.correct {
        background-color: #2d8182;
    }

    h4 {
        font-size: 40px;    
        color: #fff;
        text-transform: uppercase;
    }
    span {
        color: #fff;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
    }
`;

export const Right = styled.div`
    background-color: #24243e;
    height: 100%;
    border-radius: 6px;
    position: relative;
    z-index: 5;
    margin-left: -12px;
    padding: 20px 20px 20px 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    h5 {
        font-size: clamp(12px, 0.4em + 1vw, 1em);    
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.34px;
        color: #ffffff;
    }
    h6 {
        margin-top: 7px;
        font-size: clamp(12px, 0.4em + 1vw, 1em);    
        font-weight: bold;
        letter-spacing: 0.36px;        
        color: #38bbbc;

        &.incorrect {
            color: #ff6876;
        }
    }
`;

export const Footer = styled.div`
    border-radius: 6px;
    background-color: #24243e;
    padding: 20px 20px 20px 24px;  
    display: flex;
    justify-content:center;
    align-items: center;  

    h4 {
        font-size: 15px;
        font-weight: bold;  
        letter-spacing: 0.36px;
        text-align: center;
        color: #ffffff;
        display: inline-flex;
        align-items: center;
    }

    span {
        font-size: 22px;
        font-weight: 800;  
        letter-spacing: 0.53px;
        text-align: center;
        color: #ff8e63;
        margin-left: 7px;
    }
`;
