import styled from 'styled-components';

export const Header = styled.div`
    height: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    max-width: 100%;

    @media (max-width: 650px) {
        padding: 0 1em;
    }
`;

export const ModalContent = styled.div`
    padding: 2em 0;
    @media (max-width: 650px) {
        padding: 3em;
    }

    .inputGrid {
        width: 48%;
        span {
            display: flex;
        }
    }

    .gridDiv {
        width: 100%;
        justify-content: space-between;
        display: flex;

        input {
            ::placeholder {
                color: #3d3d6c;
            }
        }
    }

    .contentModalDelete {
        padding: 0 3em;
    }
    .modalEditContent {
        select {
            margin: 0.5em 0;
        }
    }
    .disabled-style {
        opacity: 0.55;
    }

    .buttonDeleteModal {
        display: block;
        height: 37px;
        color: #ff5a7d;
        border-radius: 8px;
        border: solid 1px #ff5b7d;
        font-size: 1rem;
        width: 100%;
        padding: 0 1em;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.18px;
        background: #212032;
        margin: 1em 0;
    }

    .buttonsModalDelete {
        margin: 2em 0;
    }

    .attentionDiv {
        color: #ffffff;
        opacity: ${(props) => (props.isShow ? 1 : 0)};
        transition: opacity 0.25s ease-in-out;
        img {
            margin-right: 5px;
        }
    }

    span {
        height: 22px;
        margin-bottom: 0.3em;
        font-family: "Open Sans", sans-serif;
        font-size: 0.8rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.18px;
        color: #ffffff;
    }

    input,
    select {
        background: #24243e;
        width: 100%;
        border: 0;
        height: 35px;
        border-radius: 6px;
        padding: 0 15px;
        margin: 0 0 1em;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.21px;
        color: #ffffff;

        &:focus {
            border: solid 1px #ff6479;
        }
    }

    h3 {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.43px;
        text-align: center;
        color: #ffffff;
    }

    .fullNameUSer {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.43px;
        color: #fe8d62;
    }
`;

export const ButtonConfirm = styled.button`
    display: block;
    height: 37px;
    border-radius: 8px;
    color: #ffffff;
    border: 0;
    font-size: 1rem;
    width: 100%;
    padding: 0 1em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;
    background: linear-gradient(45deg, #ff9b52 30%, #ff597e 60%);
    margin-top: 1em;
    position: relative;
`;

export const TableUsers = styled.div`
    width: 100%;
    padding: 0 0 1px;
    border-radius: 8px;
    background-color: #212032;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #ffffff;
    max-width: 100%;
    position: relative;

    th {
        background-color: #1c1b2d;
        padding: 12px 0.4em;
        text-transform: uppercase;
        &:first-child {
            padding-left: 18px;
        }
    }

    tr {
        cursor: pointer;
        background-color: #212032;
        &:nth-child(even) {
            background-color: #242339;
        }

        &:not(:last-child):hover {
            background-color: #2f2e3e;
        }

        .line-controls {
            display: flex;
            justify-content: flex-end;
        }

        td {
            padding: 10px 0.5em;
            &:first-child {
                padding-left: 18px;
            }
        }
    }

    .MuiTableCell-root.MuiTableCell-body {
        border-bottom: none;
    }

    .MuiPaginationItem-ellipsis {
        color: #fff;
    }

    @media (max-width: 650px) {
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0;
        @media (max-width: 650px) {
            max-height: 40vh;
        }
    }
`;

export const Main = styled.div`
    padding: 0 28px;
    margin-bottom: 2rem;
    .buttons {
        width: 100%;
        @media (max-width: 650px) {
            display: flex;
            flex-direction: column;
        }
    }
    .loader {
        z-index: 999;
        top: 50%;
        left: 50%;
        position: absolute;
    }
    .loaderButton {
        top: 0;
        margin-top: 5px;
        position: absolute;
    }

    .MuiCircularProgress-colorPrimary {
        color: #ffffff;
    }

    .containerTable {
        margin: 1.5em 0 0 0;
        max-height: 70vh;
        overflow: auto;
        border-radius: 8px 8px 0 0;
        min-height: 200px;

        @media (max-width: 650px) {
            max-height: 60vh;
        }
    }

    @media (max-width: 650px) {
        padding: 0 1em;
    }

    .organizer {
        color: #ff9162;
    }
    .monitor {
        color: #ff6479;
    }
    .extra {
        color: #31a6a1;
    }
`;

export const ButtonOptions = styled.button`
    background: transparent;
    border: 0;
    //position: absolute;
`;

export const Button = styled.button`
    background-color: #212032;
    border-radius: 12px;
    border: 0;
    cursor: pointer;
    padding: 12px 16px 12px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #ffffff;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #ffffff;

    &:hover {
        box-shadow: 0px 0px 10px -4px #000000;
        transform: scale(1.02);
    }

    p {
        margin-left: 0.7em;
    }

    &.active {
        position: relative;
        top: 1px;
    }
    .iconButton {
        position: absolute;
        width: 2.3em;
        height: 2.3em;
        margin: -0.5em 0 0 -2em;
        padding: 8px 0px;
        border-radius: 7px;
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
    }

    @media (max-width: 650px) {
        margin-top: 0.5em;
        padding: 8px 8px;
    }
`;

export const TableFooterResults = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .table-footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.8rem 1rem;
        border-radius: 0 0 8px 8px;
        background-color: #1c1b2d;
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        color: #ffffff;
    }
    span {
        font-size: .8rem;
        min-width: 200px;
        text-align: center;
        align-self: center;
    }

`;

export const LoadMoreButtonSection = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    button {
        color: #ff9162;
        margin: 5px auto;
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 387px;
        border: 0;
        background: transparent;
        font-size: 1.1rem;
        margin: 0 auto;
        color: #fff;
        background: linear-gradient(
            90deg,
            rgba(255, 174, 70, 1) 0%,
            rgba(255, 66, 141, 1) 100%
        );
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 3;
        letter-spacing: 0.21px;
        border-radius: 8px;
        text-transform: uppercase;

        &:disabled {
            opacity: 0.2;
        }
    }
`;

export const ItemOption = styled.div`
    .icon {
        -webkit-filter: grayscale(1) brightness(4.5);
        filter: grayscale(1) brightness(4.5);
    }
`;