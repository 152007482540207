import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    position: relative;
    flex-flow: column nowrap;
    height: 100%;
    text-align: center;;

    .terms-of-usage {
        width: 80%;
        height: 90%;
        margin: auto;

        iframe {
            width: 100%;
            height: 100%;
        }

        div {
            color: white;
            display: flex;
            justify-content: center;
        }
    }

    @media (min-width: 650px) {
        flex-flow: row nowrap;
        padding: 8px;
    }

    @media (max-width: 600px) and (orientation: landscape) {
        flex-flow: column nowrap;
    }

    @media(max-width: 799px) and (orientation: portrait) {
        flex-flow: column nowrap;
    }

    @media (max-width: 990px) and (orientation: landscape) {
        flex-flow: row nowrap;
    }
`;

export const VideoPlayer = styled.div`
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 40%;

    @media (min-width: 650px) {
        height: 100%;
        margin-bottom: 10px;
        padding: 0 20px 0 20px;
    }

    @media (max-width: 650px) {
        height: ${props => (props.isOnboarding ? '50%' : props.isChatEnabled && props.isLive ? '70%' : '100%')};
    }

    @media (max-width: 650px) and (orientation: landscape) {
        height: 100%;
    }

    .video-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 62px;
        padding: 10px;
        margin-bottom: 15px;
        background-color: #1c1b2d;
        min-height: 65px;

        span {
            height: 28px;
            border-radius: 3px;
            background-color: #ff5b7d;
            position: relative;
            text-transform: uppercase;
            color: #fff;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 10px 5px 25px;
            min-width: 104px;

            &:before {
                content: "";
                position: absolute;
                height: 10px;
                width: 10px;
                background-color: #fff;
                left: 10px;
                border-radius: 50%;
            }
        }

        h5 {
            width: 100%;
            font-size: clamp(16px, 0.4em + 1vw, 1.5em);
            font-weight: 800;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.48px;
            color: #ffffff;
            text-align: center;
        }

        @media (max-width: 450px) {
            text-align: center;
            h5 {
                display: none;
            }
        }
        
        @media (max-width: 400px) {
            span {
                font-size: 10px;
                min-width: 80px;
            }
        }
    }

    .video-container {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        border-radius: 8px;
        background-color: #212032;
        overflow: hidden;
    }

    .video-player {
        overflow: hidden;
        position: relative;
        height: ${(props) => (props.collapsed
        ? 'calc(98% - 65px)'
        : props.hasAttachments
            ? '68%'
            : '100%')};
    }
    @media (max-width: 650px) {
        .video-player {
            height: ${(props) => (props.collapsed
        ? 'calc(98% - 45px)'
        : props.hasAttachments
            ? '68%'
            : '100%')};
        }
    }
`;
