import styled from "styled-components";

export const Header = styled.div`
    height: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    max-width: 100%;

    @media (max-width: 650px) {
        padding: 0 1em;
    }
`;

export const TableUsers = styled.div`
    width: 100%;
    padding: 0 0 1px;
    border-radius: 8px;
    background-color: #212032;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #ffffff;
    max-width: 100%;

    th {
        background-color: #1c1b2d;
        padding: 12px 0.4em;
        text-transform: uppercase;
        &:first-child {
            padding-left: 18px;
        }
    }

    tr {
        cursor: pointer;
        background-color: #212032;
        &:nth-child(even) {
            background-color: #242339;
        }

        &:not(:last-child):hover {
            background-color: #2f2e3e;
        }

        .line-controls {
            display: flex;
            justify-content: flex-end;
        }

        td {
            padding: 10px 0.5em;
            &:first-child {
                padding-left: 18px;
            }
        }
    }

    .MuiTableCell-root.MuiTableCell-body {
        border-bottom: none;
    }

    .MuiPaginationItem-ellipsis {
        color: #fff;
    }

    .pagination {
        display: flex;
        justify-content: flex-end;

        button {
            color: #ffffff;
        }
        .MuiPaginationItem-textPrimary.Mui-selected {
            background-color: #ff6379;
        }
    }

    @media (max-width: 650px) {
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0;
        @media (max-width: 650px) {
            max-height: 40vh;
        }
    }
`;

export const ImageContainer = styled.div`
    margin: 0 1em;
    .first {
        color: #f7bc14;
    }

    .second {
        color: #838487;
    }

    .third {
        color: #c5741c;
    }
`;

export const Main = styled.div`
    padding: 0 28px;
    .buttons {
        @media (max-width: 650px) {
            display: flex;
            flex-direction: column;
        }
    }

    .position {
        display: flex;
    }

    .MuiTablePagination-root {
        color: #ffffff;
    }

    .loader {
        z-index: 999;
        top: 50%;
        left: 50%;
        position: absolute;
    }
    .loaderButton {
        top: 0;
        margin-top: 5px;
        position: absolute;
    }

    .MuiCircularProgress-colorPrimary {
        color: #ffffff;
    }

    .containerTable {
        margin: 1.5em 0;
        overflow: auto;
        border-radius: 8px;

        @media (max-width: 650px) {
            max-height: 60vh;
        }
    }

    @media (max-width: 650px) {
        padding: 0 1em;
    }

    .pagination {
        display: flex;
        justify-content: flex-end;

        .iconsNext {
            margin: 0 1em;
            width: 28px;
            height: 19px;
            cursor: pointer;
        }
        .iconsPrev {
            margin: 0 1em;
            width: 28px;
            height: 19px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            cursor: pointer;
        }

        button {
            color: #ffffff;
        }
        .MuiPaginationItem-textPrimary.Mui-selected {
            background-color: #ff6379;
        }
    }
    .table-footer {
        background-color: #1c1b2d;
        td {
            padding: 12px 0.4em;
        }
        select {
            background: #24243e;
            border: 0;
            border-radius: 6px;
            letter-spacing: 0.21px;
            color: #ffffff;
            margin: 0 1em;
            height: 2em;

            &:focus {
                border: solid 1px #ff6479;
            }
        }
    }
`;

export const TableFooterResults = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .table-footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.8rem 1rem;
        border-radius: 0 0 8px 8px;
        background-color: #1c1b2d;
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        color: #ffffff;
    }
    span {
        font-size: .8rem;
        min-width: 200px;
        text-align: center;
        align-self: center;
    }

`;

export const LoadMoreButtonSection = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    button {
        color: #ff9162;
        margin: 5px auto;
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 387px;
        border: 0;
        background: transparent;
        font-size: 1.1rem;
        margin: 0 auto;
        color: #fff;
        background: linear-gradient(
            90deg,
            rgba(255, 174, 70, 1) 0%,
            rgba(255, 66, 141, 1) 100%
        );
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 3;
        letter-spacing: 0.21px;
        border-radius: 8px;
        text-transform: uppercase;

        &:disabled {
            opacity: 0.2;
        }
    }
`;
