import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, VideoCard, Footer, VideoProgress, ContainerButton } from './styles';

const formatValue = (value) => (value ? Number(value).toFixed(2) : 0);
const normalize = (value, maxValue) => ((value - 0) * 100) / (maxValue - 0);
const numberToHHMMSS = (value) => {
    if ((!value && value !== 0) || (Number.isNaN(value))) return null;

    const sec_num = parseInt(value, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) hours = `0${hours}`;
    if (minutes < 10) minutes = `0${minutes}`;
    if (seconds < 10) seconds = `0${seconds}`;
    return `${hours}:${minutes}:${seconds}`;
};

function renderProgress(rawVideos = [], rawProgress) {
    const result = rawVideos;
    result.forEach(video => {
        const matchedVideoProgress = rawProgress.find(item => item.videoId === video.tokyo_video_id);
        if (matchedVideoProgress) {
            video.duration = matchedVideoProgress.duration;
            video.position = matchedVideoProgress.position;
        }
    });
    return result;
}

export default function RoomSliders({ videos, progress = [] }) {
    const history = useHistory();

    return (
        <Container>
            {
                renderProgress(videos, progress).map(video => (
                    <ContainerButton key={video.id} onClick={() => history.push(`/playTutorials/${video.id}`)}>
                        <VideoCard data-tour="tour__tutorial_card">
                            <img src={video.image} alt={video.name} />
                            <div className="progress-wrap">
                                <VideoProgress progress={normalize(formatValue(video.position), formatValue(video.duration))} />
                            </div>
                            <Footer>
                                <h5>{video.name}</h5>
                                <p>{numberToHHMMSS(video?.duration)}</p>
                            </Footer>
                        </VideoCard>
                    </ContainerButton>
                ))
            }
        </Container>
    );
}
