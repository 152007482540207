import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import attention from '@src/assets/attention.svg';
import useAuth from '@hooks/useAuth';
import DatePicker from '@components/DatePicker';
import useRooms from '@hooks/useRooms';
import { handleInputMaskAndPlaceholder } from '@utils/functions';
import { ButtonConfirm, ModalContent } from '../style';
import { setLoaderState } from '../../../store/modules/users/actions';

export default function AddUserModalContent({ alert, setAlert, handleAddUser }) {
    const { user } = useAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { selectedChannel } = useRooms();

    const [messageFail, setMessageFail] = useState('');
    const { loaderInButton } = useSelector(state => state.users);
    const [newUser, setNewUser] = useState({
        name: '',
        username: '',
        email: '',
        gender: 'M',
        birthdate: null,
        phone: '',
        permission: 'user',
    });

    const addUser = () => {
        const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (newUser.name === '' || newUser.username === '' || newUser.email === '' || newUser.phone === '') {
            setAlert({ ...alert, alert: true });
            setMessageFail(t('FILL_FIELDS_ADD_USER'));
            setTimeout(() => {
                setAlert({ alert: true, alertTransition: true });
            }, 100);
            return;
            // eslint-disable-next-line no-else-return
        } if (!regexEmail.test(newUser.email)) {
            setAlert({ ...alert, alert: true });
            setMessageFail(t('VALID_EMAIL'));
            setTimeout(() => {
                setAlert({ alert: true, alertTransition: true });
            }, 100);
            return;
        } if (!newUser.phone || newUser.phone.includes('_')) {
            setAlert({ ...alert, alert: true });
            setMessageFail(t('VALID_PHONE'));
            setTimeout(() => {
                setAlert({ alert: true, alertTransition: true });
            }, 100);
            return;
        } if (!newUser.birthdate) {
            setAlert({ ...alert, alert: true });
            setMessageFail(t('VALID_DATE'));
            setTimeout(() => {
                setAlert({ alert: true, alertTransition: true });
            }, 100);
            return;
        } if (newUser.name.length < 5) {
            setAlert({ ...alert, alert: true });
            setMessageFail(t('USERNAME_MIN'));
            setTimeout(() => {
                setAlert({ alert: true, alertTransition: true });
            }, 100);
            return;
        }
        setAlert({ alert: false, alertTransition: false });

        dispatch(setLoaderState(true));
        const data = moment(newUser.birthdate, 'DD/MM/YYYY');
        const postNewUser = {
            username: newUser.email,
            name: newUser.username,
            fullname: newUser.name,
            language: user.language,
            gender: newUser.gender,
            dateOfBirth: data.format('YYYY-MM-DD'),
            phone: parseInt(newUser.phone.replace(/[-()\s]/g, ''), 10),
            course: {
                courseId: selectedChannel.id,
                permission: newUser.permission,
            },
        };

        handleAddUser(postNewUser);
        if (!loaderInButton) {
            setNewUser({
                name: '',
                username: '',
                email: '',
                gender: 'M',
                birthdate: null,
                phone: '',
                permission: 'user',
            });
        }
    };

    return (
        <ModalContent isShow={alert.alertTransition}>
            <div>
                <span>{t('VOUCHER_USERDATA_FULL_NAME_CC')}</span>
            </div>
            <input type="text" autoComplete="off" value={newUser.name} onChange={e => setNewUser({ ...newUser, name: e.target.value })} />
            <div>
                <span>{t('NAME_CHAT')}</span>
            </div>
            <input type="text" autoComplete="off" value={newUser.username} onChange={e => setNewUser({ ...newUser, username: e.target.value })} />
            <div>
                <span>Email</span>
            </div>
            <input type="email" autoComplete="off" value={newUser.email} onChange={e => setNewUser({ ...newUser, email: (e.target.value).toLowerCase() })} />
            <div className="gridDiv">
                <div className="inputGrid">
                    <span> {t('GENDER')}</span>
                    <select
                        onChange={e => setNewUser({ ...newUser, gender: e.target.value })}
                    >
                        <option value="M">
                            {t('MALE')}
                        </option>
                        <option value="F">
                            {t('FEMALE')}
                        </option>
                    </select>
                </div>
                <div className="inputGrid">
                    <span> {t('DATE_OF_BIRTH')}</span>
                    <DatePicker
                        id="user-birth-date"
                        dateMask={handleInputMaskAndPlaceholder(user?.language).mask}
                        showTimeInput={false}
                        valueVariable={newUser.birthdate}
                        maxDate={new Date()}
                        minDate={null}
                        fontColorInput="#ffffff"
                        inputHeight="35px"
                        marginTopImg="-42px"
                        showYearDropdown
                        language={user.language}
                        showMonthDropdown
                        setVariable={date => setNewUser({ ...newUser, birthdate: date })}
                        placeholder={handleInputMaskAndPlaceholder(user?.language).placeholder}
                    />
                </div>
            </div>
            <div>
                <span>{t('PHONE_CONTACT')}</span>
            </div>
            <div>
                <MaskedInput
                    onChange={e => setNewUser({ ...newUser, phone: e.target.value })}
                    mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                />
            </div>
            <span>{t('PERMISSION_LEVEL')}</span>
            <select onChange={e => setNewUser({ ...newUser, permission: e.target.value })}>
                <option value="user">
                    {t('PARTICIPANT')}
                </option>
                <option value="organizer">
                    {t('ORGANIZER')}
                </option>
                <option value="monitor">
                    {t('MONITOR')}
                </option>
            </select>
            {alert.alert
                && (
                    <div className="attentionDiv">
                        <img alt="attention" src={attention} />
                        <span>
                            {messageFail}

                        </span>
                    </div>
                )}
            <ButtonConfirm type="button" onClick={addUser}>
                {t('SAVE_USER')}
                {loaderInButton
                    && (
                        <div className="loaderButton">
                            <CircularProgress size={25} />
                        </div>
                    )}
            </ButtonConfirm>
        </ModalContent>
    );
}
