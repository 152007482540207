import React, { useState, useEffect, Suspense, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import Moment from 'moment-timezone';
import { FormControl } from '@material-ui/core';
import Dropdown from 'react-dropdown';
import { VideoPlayer as TokyoPlayer } from 'react-tokyo';
import { KopaMultipleUpload } from '@components/KopaUpload/MultipleFilesUpload';
import { KopaSingleImgUpload } from '@components/KopaUpload/SingleFileUpload';
import RecordedVideo from '@components/Channels/Recorded';
import LiveVideo from '@components/Channels/Live';
import CONSTANTS from '@langs/constants';
import Notify from '@utils/notification';
import NoVideo from '@components/NoVideo';
import ToggleSwitch from '@components/ToggleSwitch';
import Loading from '@components/Loading';
import Modal from '@components/Modal';
import { toISOLocal, handleInputMaskAndPlaceholder } from '@utils/functions';
import useRooms from '@hooks/useRooms';
import useAuth from '@hooks/useAuth';
import { TIMERS, VIDEO_TYPES } from '@utils/constants';
import Tz from '@src/assets/timezones.json';
import { createRoom, roomIsLive, editRoom, endLiveRoom, uploadVideo } from '@src/store/modules/rooms/actions';
import {
    ModalContainer,
    VideoPreviewSection,
    VideoControlsSection,
    LiveVideoInfo,
    NewLiveMediaSection,
    Form,
    DateForm,
    DateContainer,
    ModalContent,
} from './styles';
import 'react-dropdown/style.css';
import DownloadVideoButton from '../../../components/DownloadVideoButton';

const WebRTCPlayer = React.lazy(() => import('@components/WebRTCPlayer'));
const webRtcTechnologyType = 1;

function ModalChannelContent({
    roomEdit,
    loading,
    setIsModalOpen,
    onClose,
    typeVideo,
}) {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState();
    const [roomName, setRoomName] = useState('');
    const [roomDescription, setRoomDescription] = useState('');
    const [uploadedFile, setUploadedFile] = useState({});
    const [uploadedMultipleFiles, setUploadedMultipleFiles] = useState([]);
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
    const [createChatEnabled, setCreateChatEnabled] = useState(true);
    const [timezoneValue, setTimezoneValue] = useState('');
    const timezoneOptions = Tz.timezones.map(tz => ({ label: tz.label, value: tz.value }));
    const isPortal = window.screen.width < 800;
    const { videoToUpload, selectedChannel, technology, videoUrl, socketPath } = useRooms();
    const { user } = useAuth();

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isUploading, setIsUploading] = useState([]);

    const roomDescriptionHandler = (e) => {
        const { value } = e.target;
        if (value.length <= 333) {
            setRoomDescription(e.target.value);
        }
    };

    const handleCloseModal = () => {
        setIsEdit(false);
        setStartDate(new Date());
        setEndDate();
        setRoomDescription('');
        setThumbnailUrl('');
        setRoomName('');
        setUploadedFile({});
        setUploadedMultipleFiles([]);
        setIsModalOpen(false);
        setCreateChatEnabled(true);
        onClose();
    };

    const handleNewRoom = (e) => {
        e.preventDefault();
        if (!thumbnailUrl) {
            Notify.warn(t('INSERT_VIDEO_THUMBNAIL'));
        } else {
            const roomObject = {
                room_name: roomName,
                description: roomDescription,
                thumbnail_url: thumbnailUrl,
                course_id: selectedChannel.id,
                chat_enabled: createChatEnabled,
                start_date: timezoneValue
                    ? toISOLocal(new Date(Moment(startDate)
                        .tz(timezoneValue, true)
                        .format()))
                    : toISOLocal(startDate),
                end_date: timezoneValue
                    ? endDate ? toISOLocal(new Date(Moment(endDate)
                        .tz(timezoneValue, true)
                        .format())) : ''
                    : endDate ? toISOLocal(endDate) : '',
                attachments: uploadedMultipleFiles.map(file => ({
                    attachment_url: file.url,
                    attachment_extension: file.name.split('.').reverse()[0],
                    attachment_name: file.name,
                })),
            };
            Object.keys(roomObject).forEach((key) => (roomObject[key] === null || roomObject[key] === '') && delete roomObject[key]);

            if (roomObject.end_date) {
                const { start_date, end_date } = roomObject;
                if (Moment(end_date).isBefore(start_date)) {
                    Notify.warn(t('HOUR_ERROR_MESSAGE'));
                    return;
                }
            }

            if (isEdit) {
                try {
                    dispatch(editRoom(roomObject, roomEdit?.id));
                    handleCloseModal();
                } catch (error) {
                    Notify.error(error?.response?.data?.error_description || t('CHANGES_FAIL'));
                }
            } else if (typeVideo === VIDEO_TYPES.LIVE) {
                dispatch(createRoom(roomObject));
                handleCloseModal();
            } else if (typeVideo === VIDEO_TYPES.RECORDED && videoToUpload) {
                dispatch(uploadVideo({ roomObject, videoToUpload }));
                handleCloseModal();
            }
        }
    };

    const handleTitle = (e) => {
        setRoomName(e.target.value);
    };

    const handleConfirm = () => {
        dispatch(endLiveRoom(roomEdit?.id));
        setIsModalConfirmOpen(isModalConfirmOpen => !isModalConfirmOpen);
    };

    const handleToggleCreate = () => {
        setCreateChatEnabled(createChatEnabled => !createChatEnabled);
    };

    useEffect(() => {
        const checkIsLive = setInterval(() => {
            if (roomEdit && typeVideo === VIDEO_TYPES.LIVE) {
                dispatch(roomIsLive(roomEdit?.id));
            }
        }, TIMERS.CHECK_ROOM_LIVE);

        return () => {
            clearInterval(checkIsLive);
        };
    }, [dispatch, roomEdit, typeVideo]);

    useEffect(() => {
        if (roomEdit && typeVideo === VIDEO_TYPES.LIVE) {
            if (roomEdit.startDate) {
                const gmt = roomEdit.startDate.substr(roomEdit.startDate.length - 6);
                const currentTz = Tz.timezones.filter(el => el.label.indexOf(gmt) >= 0);
                if (currentTz) {
                    setTimezoneValue(currentTz[0].value);
                }
            }
            dispatch(roomIsLive(roomEdit?.id));
            setIsEdit(true);
            setStartDate(new Date(roomEdit.startDate));
            setRoomDescription(roomEdit.description);
            setThumbnailUrl(roomEdit.image);

            setRoomName(roomEdit.name);
            const imageName = roomEdit.image ? roomEdit.image.substr(roomEdit.image.lastIndexOf('/') + 1) : '';

            if (roomEdit.endDate) {
                setEndDate(new Date(roomEdit.endDate));
            }
            const uploadedImage = {
                undefined,
                id: uniqueId(),
                name: imageName,
                readableSize: 0,
                preview: roomEdit.image,
                progress: 0,
                uploaded: true,
                error: false,
                url: roomEdit.image,
            };
            setUploadedFile(uploadedImage);

            const uploadedFile = (roomEdit.attachments && roomEdit.attachments.map(attach => ({
                id: uniqueId(),
                name: attach.attachment_name,
                readableSize: 0,
                preview: attach.attachment_url,
                progress: 0,
                uploaded: true,
                error: false,
                url: attach.attachment_url,
            }))) || [];
            setUploadedMultipleFiles(uploadedFile);
        }

        if (roomEdit && typeVideo === VIDEO_TYPES.RECORDED) {
            setIsEdit(true);
            setRoomDescription(roomEdit.description);
            setRoomName(roomEdit.name);
            setStartDate(new Date(roomEdit.startDate));
            if (roomEdit.endDate) {
                setEndDate(new Date(roomEdit.endDate));
            }

            setThumbnailUrl(roomEdit.image);
            const imageName = roomEdit.image ? roomEdit.image.substr(roomEdit.image.lastIndexOf('/') + 1) : '';
            const uploadedImage = {
                undefined,
                id: uniqueId(),
                name: imageName,
                readableSize: 0,
                preview: roomEdit.image,
                progress: 0,
                uploaded: true,
                error: false,
                url: roomEdit.image,
            };
            setUploadedFile(uploadedImage);

            const uploadedFile = (roomEdit.attachments && roomEdit.attachments.map(attach => ({
                id: uniqueId(),
                name: attach.attachment_name,
                readableSize: 0,
                preview: attach.attachment_url,
                progress: 0,
                uploaded: true,
                error: false,
                url: attach.attachment_url,
            }))) || [];
            setUploadedMultipleFiles(uploadedFile);
        }
    }, [roomEdit, dispatch, typeVideo]);

    useEffect(() => {
        setIsEdit(false);
        setStartDate(new Date());
        setEndDate();
        setRoomDescription('');
        setThumbnailUrl('');
        setRoomName('');
        setUploadedFile({});
        setUploadedMultipleFiles([]);

        return () => {
            if (window.revokeObjectURL) {
                URL.revokeObjectURL(uploadedFile.preview);
                uploadedMultipleFiles.forEach(file => URL.revokeObjectURL(file.preview));
                onClose();
            }
        };
        // eslint-disable-next-line
    }, [])

    function countCharacters() {
        let count = 333;
        count -= roomDescription?.length || 0;
        if (count < 0) {
            count = 0;
        }
        return count;
    }

    // eslint-disable-next-line
    const DatePicker = useMemo(() => React.lazy(() => import('../../../components/DatePicker')), [startDate, endDate])

    function renderPlayer() {
        if (videoUrl && technology !== webRtcTechnologyType) {
            return (
                <TokyoPlayer decodeUrl={roomEdit?.videoUrl} className="live-player" quizComponent={() => { }} />
            );
        }

        if (videoUrl) {
            return (
                <Suspense fallback={<Loading />}>
                    <WebRTCPlayer
                        roomId={roomEdit?.id}
                        socketPath={socketPath}
                        videoUrl={videoUrl}
                        waitingTransmissionText={t('WAITING_TRANSMISSION')}
                        loadingComponent={<Loading />}
                        noVideoComponent={<NoVideo title={t('OPS')} subtitle={t('DISABLED_CAMERA')} />}
                    />
                </Suspense>
            );
        }
        return null;
    }

    function renderSubmitButtonText() {
        if (isUploading.length) {
            return t(CONSTANTS.UPLOADING_ATTACHMENTS);
        }
        if (isEdit) {
            return t(CONSTANTS.SAVE_CHANGES);
        }
        return t(CONSTANTS.CHANNEL_CREATEVIDEO);
    }

    return (
        <>
            {loading ? <Loading />
                : (
                    <ModalContainer isEdit={isEdit}>

                        <ModalContent>

                            {typeVideo === VIDEO_TYPES.LIVE && isEdit
                                && (

                                    <VideoPreviewSection>
                                        {renderPlayer()}
                                    </VideoPreviewSection>

                                )}
                            <LiveVideoInfo>

                                <VideoControlsSection className={!isEdit ? 'video-control-sec' : typeVideo === VIDEO_TYPES.RECORDED && isEdit ? 'video-control-sec' : ''}>
                                    {typeVideo === VIDEO_TYPES.LIVE && isEdit
                                        && (
                                            <>

                                                <div className="thumb-live">
                                                    <KopaSingleImgUpload
                                                        uploadedFile={uploadedFile}
                                                        fileType="thumbnail"
                                                        setUploadedFile={setUploadedFile}
                                                        uploadBaseUrl="/config/thumbnail/upload"
                                                        thumbnailUrl={thumbnailUrl}
                                                        setThumbnailUrl={setThumbnailUrl}
                                                    />
                                                </div>
                                                <LiveVideo
                                                    primaryBroadcastingUrl={roomEdit?.primaryBroadcastingUrl}
                                                    secondaryBroadcastingUrl={roomEdit?.secondaryBroadcastingUrl}
                                                    roomId={roomEdit?.id}
                                                    streamName={roomEdit?.streamName}
                                                    setIsModalConfirmOpen={setIsModalConfirmOpen}
                                                    cb={onClose}
                                                />

                                                <DownloadVideoButton roomEdit={roomEdit} />

                                            </>
                                        )}
                                </VideoControlsSection>

                                <Form onSubmit={handleNewRoom}>
                                    <section className={typeVideo === VIDEO_TYPES.LIVE && isEdit ? '--content' : 'content-recorded'}>

                                        <div className={typeVideo === VIDEO_TYPES.RECORDED ? '--thumb-section' : typeVideo === VIDEO_TYPES.LIVE && !isEdit ? '--thumb-section' : ''}>
                                            <div className={typeVideo === VIDEO_TYPES.RECORDED ? 'thumb' : typeVideo === VIDEO_TYPES.LIVE && !isEdit ? 'thumb' : ''}>

                                                {typeVideo === VIDEO_TYPES.RECORDED && (
                                                    <div className="upload">
                                                        <RecordedVideo videoUrl={roomEdit ? roomEdit.videoUrl : ''} message={t('UPLOAD_VIDEO_PLACEHOLDER')} />
                                                        <div className="--cover-footer">
                                                            <span className="thumb-span"> {t('VIDEO')}</span>
                                                            <span>{t('CHANNEL', { context: 'VIDEOFORMAT' })}</span>
                                                        </div>
                                                    </div>
                                                )}

                                                {typeVideo === VIDEO_TYPES.RECORDED && (
                                                    <>
                                                        <h4>{t('CHANNEL', { context: 'VIDEOCOVER' })}</h4>
                                                        <div className="upload">
                                                            <KopaSingleImgUpload
                                                                fileType="thumbnail"
                                                                uploadedFile={uploadedFile}
                                                                setUploadedFile={setUploadedFile}
                                                                uploadBaseUrl="/config/thumbnail/upload"
                                                                thumbnailUrl={thumbnailUrl}
                                                                setThumbnailUrl={setThumbnailUrl}

                                                            />

                                                        </div>
                                                    </>
                                                )}

                                                {!isEdit && typeVideo === VIDEO_TYPES.LIVE
                                                    && (
                                                        <NewLiveMediaSection>
                                                            <h4>{t('CHANNEL', { context: 'VIDEOCOVER' })}</h4>
                                                            <div className="upload">
                                                                <KopaSingleImgUpload
                                                                    fileType="thumbnail"
                                                                    uploadedFile={uploadedFile}
                                                                    setUploadedFile={setUploadedFile}
                                                                    uploadBaseUrl="/config/thumbnail/upload"
                                                                    thumbnailUrl={thumbnailUrl}
                                                                    setThumbnailUrl={setThumbnailUrl}
                                                                />

                                                            </div>
                                                            <section className="chat">
                                                                <div className="--toggle-wrapper">
                                                                    <ToggleSwitch
                                                                        isOn={createChatEnabled}
                                                                        handleToggle={handleToggleCreate}
                                                                        disabled={loading}
                                                                    />
                                                                </div>
                                                                <div className="--toggle-info-wrapper">
                                                                    <h3>CHAT </h3>
                                                                    <p className={`${createChatEnabled ? '--active' : undefined}`}>{createChatEnabled ? t('ENABLED_CAPS') : t('DISABLED_CAPS')}</p>
                                                                </div>

                                                            </section>
                                                        </NewLiveMediaSection>
                                                    )}
                                            </div>
                                            {typeVideo === VIDEO_TYPES.RECORDED && isEdit && (
                                                <DownloadVideoButton roomEdit={roomEdit} />
                                            ) }

                                        </div>

                                        <div className="--form-section">
                                            <div className="--input-item">
                                                <h4>{t('CHANNEL', { context: 'VIDEOTITLE' })}</h4>
                                                <input
                                                    data-testid="modal-edit-video-descritpion-input"
                                                    placeholder={t('VIDEO_TITLE_PLACEHOLDER')}
                                                    className="input-title"
                                                    value={roomName}
                                                    onChange={e => handleTitle(e)}
                                                    maxLength="70"
                                                    required
                                                />
                                            </div>
                                            <div className="--input-item">
                                                <h4>{t('CHANNEL', { context: 'SELECTCOURSE' })}</h4>
                                                <input className="input-title" defaultValue={`${selectedChannel.name} #${selectedChannel.id}`} disabled />
                                            </div>
                                            {typeVideo === VIDEO_TYPES.LIVE ? (
                                                <div className="--input-item" data-testid="modal-timezone-container">
                                                    <h4>{t('CHANNEL', { context: 'SELECTTIMEZONE' })}</h4>
                                                    <Dropdown
                                                        options={timezoneOptions}
                                                        onChange={e => setTimezoneValue(e.value)}
                                                        value={timezoneValue}
                                                        placeholder="Selecione um fuso horário"
                                                    />
                                                </div>
                                            ) : undefined}

                                            <FormControl className="dates">
                                                <DateForm className="datepick">
                                                    <DateContainer>
                                                        <div className="start">
                                                            <h4>{t('CHANNEL', { context: 'STARTDATE' })}</h4>
                                                            <Suspense fallback={<div>{t('LOADING')}</div>}>
                                                                <div data-testid="room-start-date-input">
                                                                    <DatePicker
                                                                        id="start"
                                                                        withPortal={isPortal}
                                                                        setVariable={setStartDate}
                                                                        valueVariable={startDate}
                                                                        dateMask={handleInputMaskAndPlaceholder(user?.language).maskWithTime}
                                                                        placeholder={handleInputMaskAndPlaceholder(user?.language).placeholderWithTime}
                                                                        timeLabel={`${t('HOURS')}:`}
                                                                        timeMask="HH:mm"
                                                                        showTimeInput
                                                                    />
                                                                </div>
                                                            </Suspense>
                                                        </div>
                                                    </DateContainer>
                                                    <DateContainer>
                                                        <div className="finish">
                                                            <div className="field-header">
                                                                <h4>{t('CHANNEL', { context: 'ENDDATE' })}</h4>
                                                                <small>{t('OPTIONAL')}</small>
                                                            </div>
                                                            <Suspense fallback={<div>{t('LOADING')}</div>}>
                                                                <div data-testid="room-end-date-input">
                                                                    <DatePicker
                                                                        id="finish"
                                                                        withPortal={isPortal}
                                                                        setVariable={setEndDate}
                                                                        valueVariable={endDate}
                                                                        dateMask={handleInputMaskAndPlaceholder(user?.language).maskWithTime}
                                                                        placeholder={handleInputMaskAndPlaceholder(user?.language).placeholderWithTime}
                                                                        timeLabel={`${t('HOURS')}:`}
                                                                        timeMask="HH:mm"
                                                                        showTimeInput
                                                                        minDate={new Date()}
                                                                    />
                                                                </div>
                                                            </Suspense>
                                                        </div>
                                                    </DateContainer>
                                                </DateForm>
                                            </FormControl>

                                            <div>
                                                <section className="video-description">
                                                    <div className="field-header">
                                                        <h4>{t('CHANNEL', { context: 'VIDEODESCRIPTION' })}</h4>
                                                        <small>{t('OPTIONAL')}</small>
                                                        <small className={`to-right ${countCharacters() <= 0 ? 'limit' : ''}`}>{countCharacters()} {t('CHARACTERS')}</small>
                                                    </div>
                                                    <textarea data-testid="video-description-textarea" placeholder={t('CONTENT_DESCRIPTION_PLACEHOLDER')} onChange={e => roomDescriptionHandler(e)} value={roomDescription || ''} maxLength="1000" />
                                                </section>
                                                <section className="attachments">
                                                    <div className="field-header">
                                                        <h4>{t(CONSTANTS.CHANNEL_UPLOADFILES)}</h4>
                                                        <small>{t('OPTIONAL')}</small>
                                                    </div>
                                                    <div className="upload-attachments">
                                                        <KopaMultipleUpload
                                                            multiple
                                                            uploadBaseUrl="/config/attachment/upload"
                                                            fileType="attachments"
                                                            accept=".ppt,.pptx,.doc,.docx,.xls,.xlsx,.pdf,.jpg,.jpeg,.png"
                                                            uploadedMultipleFiles={uploadedMultipleFiles}
                                                            setUploadedMultipleFiles={setUploadedMultipleFiles}
                                                            setIsUploading={setIsUploading}
                                                        />
                                                    </div>
                                                    <span className="thumb-span"><span>{t(CONSTANTS.CHANNEL_FILES)} - </span>{t(CONSTANTS.CHANNEL_FILEFORMAT)}</span>
                                                </section>

                                                <button
                                                    disabled={isUploading.length}
                                                    className="submitButton large"
                                                    type="submit"
                                                >
                                                    {renderSubmitButtonText()}
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                    <Modal title={t('CHANNEL', { context: 'ENDLIVEDESCRIPTION' })} isOpen={isModalConfirmOpen} handleModal={() => setIsModalConfirmOpen((isModalConfirmOpen => !isModalConfirmOpen))}>
                                        <div className="modal-confirm">

                                            <p>{t('CHANNEL', { context: 'ENDLIVEIRREVERSIBLE' })}.</p>
                                            <div className="confirm-buttons">
                                                <button
                                                    onClick={handleConfirm}
                                                    type="submit"
                                                >{t('CONFIRM')}
                                                </button>
                                                <button
                                                    onClick={() => setIsModalConfirmOpen(isModalConfirmOpen => !isModalConfirmOpen)}
                                                    className="cancel"
                                                    type="submit"
                                                >{t('CANCEL')}
                                                </button>
                                            </div>
                                        </div>
                                    </Modal>
                                </Form>

                            </LiveVideoInfo>

                        </ModalContent>
                    </ModalContainer>
                )}

        </>
    );
}

export default React.memo(ModalChannelContent);
