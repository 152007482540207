import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: auto;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  width: 100%;
  height: 300px;

  color: #444;

  position: relative;

  div.upload-info {
    width: 28px;
    height: 28px;

    position: absolute;
    right: 8px;

    button {
      background-color: #fcefe2;
      border-radius: 50%;
      padding: 7px 8px;
      transition: background-color 0.2s;

      &:hover {
        background-color: #fdf9f5;
      }
    }
  }
`;

export const FileInfo = styled.div`
  width: 100%;
  max-height: 10.71rem;
  
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  color: #989797;

  display: inline-block;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  hyphens: auto;

  .--cover-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3rem;
    background-color: #24243e;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    flex-direction: column;

    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    color: #ff8e63;

     button.footer-button {
        background: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        color: #ff8e63;
        font-size: 1rem;
        font-weight: bold;
        margin: auto;

        img {

            margin-right: 0.5rem;
        }

     }
  }

  p {
      height: 100%;
      border-radius: 6px;
      background-color: #1f1f3a;          
    }

    span {        
        margin: 0;        
        font-size: 10px;
        font-weight: bold;
        color: #ffffff;

            &.thumb-span{                        
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;        
                color: #ff8e63;
            }
     }

  abbr {
    text-decoration: none;
    color: #ffffff;
  }
`;

export const Preview = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

`;
