import React from 'react';
import { CustomFormInputContainer, CustomFormInputHeader, CustomTextArea } from './styles';

const CustomFormTextArea = ({ labelTitle, helpText, maxChars, ...props }) => (
    <CustomFormInputContainer>
        <CustomFormInputHeader>
            <p>{labelTitle || 'Label'} <span>{helpText}</span></p>
            <strong>{maxChars}</strong>
        </CustomFormInputHeader>
        <CustomTextArea {...props} />
    </CustomFormInputContainer>
);

export default CustomFormTextArea;
