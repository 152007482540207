import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RecordedVideo from '@src/components/Channels/Recorded';
import DownloadVideoButton from '@src/components/DownloadVideoButton';
import CONSTANTS from '@langs/constants';
import useRooms from '@src/hooks/useRooms';
import { KopaSingleImgUpload } from '@src/components/KopaUpload/SingleFileUpload';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
    CourseRecordedUploadContainer,
    CourseRecordedUploadContent,
    DownloadVideoButtonArea,
    DropzoneArea,
    DropzoneCoverArea,
    UploadVideoArea,
    UploadVideoInfo,
} from './styles';

const CourseRecordedUpload = ({
    uploadedFile,
    setUploadedFile,
    setThumbnailUrl,
    thumbnailUrl,
    videoUrl,
    selectedEditRoom,
}) => {
    const [isVideoUploaded, setIsVideoUploaded] = useState(false);
    const { loading } = useRooms();
    const { t } = useTranslation();

    const hasVideoUploaded = (videoFile) => {
        if (videoFile) {
            setIsVideoUploaded(true);
        } else {
            setIsVideoUploaded(false);
        }
    };

    const LoadingSkeleton = () => (
        <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
            <Skeleton height={260} count={2} style={{ margin: '10px 0' }} />
        </SkeletonTheme>
    );

    return (
        <CourseRecordedUploadContainer>
            <CourseRecordedUploadContent>
                {loading ? (
                    <LoadingSkeleton />
                ) : (
                    <UploadVideoArea>
                        <div>
                            <DropzoneArea hasVideo={isVideoUploaded}>
                                <RecordedVideo
                                    handleFileCallback={hasVideoUploaded}
                                    videoUrl={videoUrl || ''}
                                    message={t('UPLOAD_VIDEO_PLACEHOLDER')}
                                />
                            </DropzoneArea>

                            {!isVideoUploaded && (
                                <UploadVideoInfo>
                                    <p>{t(CONSTANTS.UPLOAD_VIDEO).toUpperCase()}</p>
                                    <span>{t(CONSTANTS.CHANNEL_ACCEPTED_FORMATS)?.toUpperCase()}: MP4, AVI E MKV</span>
                                </UploadVideoInfo>
                            )}
                        </div>

                        <div style={{ marginTop: '22px' }}>
                            <DropzoneCoverArea hasThumb={Object.keys(uploadedFile).length}>
                                <KopaSingleImgUpload
                                    fileType="thumbnail"
                                    uploadedFile={uploadedFile}
                                    setUploadedFile={setUploadedFile}
                                    uploadBaseUrl="/config/thumbnail/upload"
                                    thumbnailUrl={thumbnailUrl}
                                    setThumbnailUrl={setThumbnailUrl}
                                />
                            </DropzoneCoverArea>
                            {Object.keys(uploadedFile).length === 0 && (
                                <UploadVideoInfo>
                                    <p>{t(CONSTANTS.CHANNEL_VIDEOCOVER)}</p>
                                    <span>{t(CONSTANTS.MINIMUM_IMAGE_SIZE)?.toUpperCase()}</span>
                                </UploadVideoInfo>
                            )}
                        </div>
                        {selectedEditRoom && (
                            <DownloadVideoButtonArea>
                                <DownloadVideoButton roomEdit={selectedEditRoom} />
                            </DownloadVideoButtonArea>
                        )}
                    </UploadVideoArea>
                )};
            </CourseRecordedUploadContent>
        </CourseRecordedUploadContainer>
    );
};

export default CourseRecordedUpload;
