import { changeChatEnabled } from '@src/store/modules/rooms/actions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ToggleSwitch from '@src/components/ToggleSwitch';
import PlayerHandler from '../PlayerHandler';
import GoLiveButton from '../GoLiveButton';

export default function PlayerPreview({ roomId, isChatEnabled, canGoLive, handleGoLive, isSettingUp }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleToggle = () => {
        dispatch(changeChatEnabled(roomId, !isChatEnabled));
    };

    return (
        <div className="preview">
            <div className={`player ${isSettingUp ? '' : 'isBlank'}`}>
                <PlayerHandler isSettingUp={isSettingUp} />
            </div>
            <div className="--video-controls">
                <GoLiveButton canGoLive={canGoLive} handleGoLive={handleGoLive} />
                <div className="chat-enabled">
                    <span>CHAT {isChatEnabled ? t('ENABLED_CAPS') : t('DISABLED_CAPS')}</span>
                    <ToggleSwitch
                        isOn={isChatEnabled}
                        handleToggle={handleToggle}
                    />
                </div>
            </div>
        </div>
    );
}
