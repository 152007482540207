/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import tutorialIcon from '@src/assets/tutorial-video.svg';
import {
    SidebarWrapper,
    SidebarContainer,
    LogoContainer,
    SidebarMenu,
    SidebarMenuTop,
    SidebarMenuBottom,
    SidebarExpansion,
    ExpansionList,
    SidebarToggler,
    DropdownContainer,
    DropdownButtonWrapper,
    DropdownList,
    SectionContainer,
    ExtraUserInfo,
} from './styles';
import logoIcon from '../../assets/logo-kopa.svg';

import arrowDownIcon from '../../assets/arrow-down-icon.svg';
import arrowRightIcon from '../../assets/arrow-right-icon.svg';
import videoIcon from '../../assets/video-icon.svg';
import userIcon from '../../assets/person.svg';
import signOutIcon from '../../assets/sign-out-icon.svg';

import gameficationIcon from '../../assets/gamefication.svg';
import homeIcon from '../../assets/home-icon.svg';
import cleanIcon from '../../assets/close.svg';

import Tooltip from '../Tooltip';
import useRooms from '../../hooks/useRooms';
import { logout } from '../../store/modules/auth/actions';
import { getChannels } from '../../store/modules/rooms/actions';
import { limitCharacters } from '../../utils/functions';
import { useFetchChannelsRooms } from '../../hooks/useFetchChannelsRooms';
import { useSidebar } from '../../hooks/useSidebar';
import langs from '../../assets/i18n/constants';
import SubMenuItem from './components/SubMenuItem';

function sortCourseList(courseList) {
    const sortedList = courseList.sort((a, b) => ((a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase())
        ? 1 : ((b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()) ? -1 : 0)));
    return sortedList;
}

function Sidebar() {
    const { hasExtraUsers } = useRooms();
    const { selectedChannel, selectedEditRoom, selectedChannelRooms } = useRooms();
    const { t } = useTranslation();
    const [showTopHeaderContent, setShowTopHeaderContent] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { id: roomId } = useParams();
    const [searchText, setSearchText] = useState('');
    const [filteredRoomsList, setFilteredRoomsList] = useState();
    const [extraUsersAvailable, setExtraUsersAvailable] = useState(false);
    const [selectedLocationPath, setSelectedLocationPath] = useState();
    const [selectedRoomId, setSelectedRoomId] = useState(undefined);
    const { isMenuOpen, setIsMenuOpen, items } = useSidebar();
    useFetchChannelsRooms(selectedChannel, isMenuOpen);

    function handleLogout() {
        dispatch(logout());
    }

    function handleMultiMenuClick() {
        if (!selectedRoomId) return;
        setIsMenuOpen(!isMenuOpen);
    }

    function toggleTopHeaderContent() {
        setShowTopHeaderContent(!showTopHeaderContent);
    }

    function handleClickOutside() {
        setShowTopHeaderContent(false);
    }

    function handleMenuClick(path) {
        history.push({ pathname: path });
    }

    function handleFilter(e) {
        setSearchText(e.target.value);
        const filteredList = sortCourseList(
            selectedChannelRooms.filter((item) => {
                if (e.target.value === '') {
                    return item;
                } if (
                    item.name
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    || item.id
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())) {
                    return item;
                }
            }),
        );
        setFilteredRoomsList(filteredList);
    }

    function handleClearFilter() {
        setSearchText('');
        setFilteredRoomsList(sortCourseList(selectedChannelRooms));
    }

    useEffect(() => {
        dispatch(getChannels());
    }, [dispatch]);

    useEffect(() => {
        setFilteredRoomsList(sortCourseList(selectedChannelRooms));
    }, [selectedChannelRooms]);

    useEffect(() => {
        setExtraUsersAvailable(hasExtraUsers);
    }, [hasExtraUsers]);

    useEffect(() => {
        const currentLocationPath = history.location.pathname.split('/')[1];
        setSelectedLocationPath(currentLocationPath);
    }, [history.location]);

    useEffect(() => {
        if (roomId) {
            setSelectedRoomId(roomId);
            setIsMenuOpen(true);
        } else {
            setSelectedRoomId(null);
            setIsMenuOpen(false);
        }
    }, [roomId, setIsMenuOpen]);

    function checkSelectedItem(path) {
        if (path === selectedLocationPath) return '--menu-item --menu-item-selected';
        return '--menu-item';
    }

    function checkSelectedBasePath(currentPath, itemPath) {
        const basePaths = {
            users: 'users',
            ranking: 'ranking',
            tutorial: 'playTutorials',
            playTutorials: 'playTutorials',
            videoStats: 'videoStats',
            courseRecorded: 'courseRecorded',
        };

        let selectedBasePath = '';

        if (basePaths[currentPath]) {
            selectedBasePath = basePaths[currentPath];
        }

        if (selectedBasePath === itemPath) return '--menu-item --menu-item-selected';
        return '--menu-item';
    }

    function handleSelectedRoom(room) {
        if (!room.recorded) {
            setShowTopHeaderContent(false);
            setSearchText('');
            history.push({ pathname: `/myChannels/${room.id}/details` });
        } else if (room.recorded) {
            setShowTopHeaderContent(false);
            setSearchText('');
            history.push({ pathname: '/myChannels', state: { roomId: room.id, fromSearch: true } });
        }
    }

    function handleRedirectHome() {
        window.location.replace('/');
    }

    return (
        <SidebarWrapper>
            <OutsideClickHandler
                onOutsideClick={handleClickOutside}
                display="contents"
            >
                <SidebarToggler
                    active={roomId}
                    onClick={handleMultiMenuClick}
                    className={`--pulse ${isMenuOpen ? 'expanded' : undefined}`}
                >
                    <img
                        src={arrowRightIcon}
                        alt="Mostrar Mais"
                        className={isMenuOpen ? 'left' : undefined}
                    />
                </SidebarToggler>

                <AnimatePresence exitBeforeEnter>
                    {isMenuOpen && (

                        <motion.div
                            initial={{ opacity: 1, x: -100, height: '100%', position: 'absolute' }}
                            animate={{ opacity: 1, x: -10 }}
                            exit={{ opacity: 0, x: -100, left: 0 }}
                            transition={{ duration: 0.5, type: 'spring', ease: 'easeInOut' }}
                        >
                            <SidebarExpansion>
                                <OutsideClickHandler
                                    onOutsideClick={() => setShowTopHeaderContent(false)}
                                    display="contents"
                                >
                                    <DropdownContainer>
                                        <DropdownButtonWrapper
                                            tabIndex={0}
                                            role="button"
                                            onKeyPress={toggleTopHeaderContent}
                                            onClick={toggleTopHeaderContent}
                                        >
                                            <div className="dropdown-component">
                                                <SectionContainer>
                                                    <small>{t(langs.ROOM)}</small>
                                                    <p className="emphasis">{selectedEditRoom?.name ? limitCharacters(`${selectedEditRoom.name}`, 15) : `${t(langs.LOADING)}...`}</p>
                                                    <img
                                                        src={arrowDownIcon}
                                                        alt="Mostrar Mais"
                                                        className={`${showTopHeaderContent ? '--open' : undefined}`}
                                                    />
                                                </SectionContainer>
                                            </div>
                                        </DropdownButtonWrapper>
                                        <DropdownList className="top right">
                                            {showTopHeaderContent
                                                && (
                                                    <>
                                                        <li className="--search-wrapper">
                                                            {searchText !== '' && (
                                                                <button type="button" onClick={handleClearFilter}>
                                                                    <img src={cleanIcon} alt="Limpar" />
                                                                </button>
                                                            )}
                                                            <input
                                                                autoFocus
                                                                value={searchText}
                                                                onChange={handleFilter}
                                                                className="--search"
                                                                type="text"
                                                                placeholder={t(langs.FILTER_NAME_ID)}
                                                            />
                                                        </li>
                                                        {
                                                            filteredRoomsList.map(room => (
                                                                <li onClick={() => handleSelectedRoom(room)} key={room.id}>
                                                                    {limitCharacters(`${room?.name}`, 15)}
                                                                </li>
                                                            ))
                                                        }

                                                    </>
                                                )}
                                        </DropdownList>
                                    </DropdownContainer>
                                </OutsideClickHandler>
                                <ExpansionList>
                                    {extraUsersAvailable && <ExtraUserInfo><p>{t(langs.GUEST_AVAILABLE)}</p></ExtraUserInfo>}
                                    <ul>
                                        {items.map(items => (
                                            <SubMenuItem
                                                key={items?.dataTour}
                                                onClick={() => handleMenuClick(items.link)}
                                                title={items.title}
                                                className={checkSelectedItem(items.className)}
                                                dataTour={items.dataTour}
                                                icon={items.icon}
                                                alt={items.alt}
                                            />
                                        ))}

                                    </ul>
                                </ExpansionList>
                            </SidebarExpansion>
                        </motion.div>
                    )}
                </AnimatePresence>
                <SidebarContainer>
                    <LogoContainer>
                        <img src={logoIcon} alt="Kopa Live" />
                    </LogoContainer>
                    <SidebarMenu>
                        <SidebarMenuTop>
                            <ul>
                                <li>
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        className="--menu-item emphasis"
                                        onClick={handleRedirectHome}

                                    >
                                        <Tooltip
                                            content={t(langs.HOME)}
                                            direction="right"
                                        >
                                            <img src={homeIcon} alt={t(langs.HOME)} />
                                        </Tooltip>
                                    </div>
                                </li>
                                <li>
                                    <div
                                        role="button"
                                        tabIndex="0"
                                        className={checkSelectedBasePath(selectedLocationPath, '')}
                                        onClick={() => handleMenuClick('/')}
                                    >
                                        <Tooltip
                                            content={t(langs.CHANNEL_MYVIDEOS)}
                                            direction="right"
                                        >
                                            <img src={videoIcon} alt={t(langs.CHANNEL_MYVIDEOS)} data-tour="tour__myvideos" />
                                        </Tooltip>
                                    </div>
                                </li>
                                <li>
                                    <div
                                        role="button"
                                        tabIndex="0"
                                        className={checkSelectedBasePath(selectedLocationPath, 'users')}
                                        onClick={() => handleMenuClick('/users')}
                                    >
                                        <Tooltip
                                            content={t(langs.USERS)}
                                            direction="right"
                                        >
                                            <img src={userIcon} alt={t(langs.USERS)} data-tour="tour__users" />
                                        </Tooltip>
                                    </div>
                                </li>
                                <li>
                                    <div
                                        role="button"
                                        tabIndex="0"
                                        className={checkSelectedBasePath(selectedLocationPath, 'ranking')}
                                        onClick={() => handleMenuClick('/ranking')}
                                    >
                                        <Tooltip
                                            content={t(langs.GAMIFICATION)}
                                            direction="right"
                                        >
                                            <img src={gameficationIcon} alt="gamification" data-tour="tour__gamefication" />
                                        </Tooltip>
                                    </div>
                                </li>
                                <li>
                                    <div
                                        role="button"
                                        tabIndex="0"
                                        className={checkSelectedBasePath(selectedLocationPath, 'playTutorials')}
                                        onClick={() => handleMenuClick('/tutorial')}
                                    >
                                        <Tooltip
                                            content={t(langs.TUTORIALS)}
                                            direction="right"
                                        >
                                            <img src={tutorialIcon} alt="tutorial" data-tour="tour__tutorials" />
                                        </Tooltip>
                                    </div>
                                </li>
                            </ul>
                        </SidebarMenuTop>
                        <SidebarMenuBottom>
                            <ul>
                                <li>
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        className="--menu-item emphasis"
                                        onClick={handleLogout}
                                    >
                                        <Tooltip
                                            content={t(langs.LOGOUT)}
                                            direction="right"
                                        >
                                            <img src={signOutIcon} alt={t(langs.LOGOUT)} data-tour="tour__logout" />
                                        </Tooltip>
                                    </div>
                                </li>
                            </ul>
                        </SidebarMenuBottom>
                    </SidebarMenu>
                </SidebarContainer>
            </OutsideClickHandler>
        </SidebarWrapper>
    );
}

export default Sidebar;
