import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Content, ItemOption, ItemOptionDescription, Footer } from './styles';
import surveyIcon from '../../../../assets/survey-icon.svg';
import groupIcon from '../../../../assets/group-icon.png';

export default function QuizTypeContent({ handleAddQuiz, handleAddSurvey, handleClose }) {
    const { t } = useTranslation();
    return (
        <Container>
            <Content>
                <ItemOption onClick={() => {
                    handleClose();
                    handleAddQuiz();
                }}
                >
                    <img src={groupIcon} alt={t('QUIZ')} />
                    <ItemOptionDescription>
                        <p>{t('QUIZ')}</p>
                    </ItemOptionDescription>
                </ItemOption>

                <ItemOption onClick={() => {
                    handleClose();
                    handleAddSurvey();
                }}
                >
                    <img src={surveyIcon} alt={t('SURVEY')} />
                    <ItemOptionDescription>
                        <p>{t('SURVEY')}</p>
                    </ItemOptionDescription>
                </ItemOption>

            </Content>
            <Footer>
                <p><small>*</small> {t('THE')} <small>{t('QUIZZES')}</small> {t('SURVEY_RIGHT_ANSWER')}</p>
            </Footer>
        </Container>
    );
}
