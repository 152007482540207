import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { clearEditSelected, fetchEditRoom } from '../store/modules/rooms/actions';

export const useFetchEditRoom = (roomId) => {
    const dispatch = useDispatch();

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (roomId) {
            dispatch(fetchEditRoom(roomId));
        } else {
            return () => dispatch(clearEditSelected());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomId]);
};
