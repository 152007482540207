import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { urls } from '../../utils/constants';
import downloadIcon from '../../assets/download-icon.svg';
import api from '../../services/api';
import Notify from '../../utils/notification';
import { DownloadVideoButtonContainer } from './styles';

const DownloadVideoButton = ({ roomEdit, disabled }) => {
    const [downloadVideoUrl, setDownloadVideoUrl] = useState('');

    const downloadButtonRef = useRef();
    const { t } = useTranslation();

    async function handleDownloadLiveRecording() {
        await api.getLiveRecordingUrl(roomEdit.id).then((resp) => {
            const { downloadToken } = resp;
            const videodownloadAuthUrl = `${urls.BASE_API}rooms/${downloadToken}/start_download`;
            setDownloadVideoUrl(videodownloadAuthUrl);
            downloadButtonRef.current.click();
        }).catch(() => {
            Notify.warn(t('DOWNLOAD_VIDEO_FAIL'));
        });
    }

    return (
        <DownloadVideoButtonContainer disabled={disabled} data-testid="edit-room-modal-download-video-button">
            <button
                onClick={handleDownloadLiveRecording}
                className="liveVideoDownloadButton"
                disabled={disabled}
                title={disabled ? t('VIDEO_UNAVAILABLE') : t('STATISTIC_VIDEO_DOWNLOAD')}
                type="button"
            >

                <img src={downloadIcon} alt="download video" />
                {t('DOWNLOAD_VIDEO_RECORDING')}
            </button>

            {/* Elemento nao exibido. Link sera chamado na funcao handleDownloadLiveRecording  */}
            <a className="download-video-button" href={downloadVideoUrl} ref={downloadButtonRef}>download video</a>
        </DownloadVideoButtonContainer>

    );
};

export default DownloadVideoButton;
