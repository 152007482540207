import styled from 'styled-components';

export const SidebarWrapper = styled.div`
    padding: 25px 12px;
    height: 100vh;
    width: 100px;
    position: fixed;
    top: 0;
    transition: all 0.5s ease-in-out;
    z-index: 5;

    @media (max-width: 800px) {
        transform: translateX(-100%);
        visibility: hidden;
    }
`;

export const SidebarContainer = styled.div`
    background-color: #212032;
    width: 70px;
    height: 100%;
    border-radius: 18px;    
    box-shadow: 0px 0px 10px -4px #000000;
    z-index: 100;
    position: relative;

    ul {
        list-style: none;
    }

    .--menu-item {
        padding: 15px;
        cursor: pointer;
        filter: grayscale(1);
        &.emphasis {
            filter: brightness(4.5);
        }        
        opacity: 0.5;
        &:hover {
            transform: scale(1.1);
            filter: none;
            opacity: 1;
        }
        &:active {
            transform: scale(1);
        }
    }

    .--menu-item-selected {
                filter: none;
                transform: scale(1.05);
                opacity: 1;
            }
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    position: relative;
    cursor: pointer;

    &::after {
        content: "";
        position: absolute;
        background-color: #1a1a2a;
        height: 4px;
        width: 30px;
        bottom: 0;
    }
`;

export const SidebarToggler = styled.div`
    cursor: pointer;
    background-color: #ff8f63;
    height: 18px;
    width: 18px;
    position: absolute;
    border-radius: 100%;
    right: 9px;
    top: 60px;
    border: 3px solid #282b3a;
    z-index: 150;
    display: flex;
    transition: all 150ms ease-in-out;
    opacity: ${({ active }) => (active ? 1 : 0)};

    &:hover {
        transform: scale(1.2);
        border: 3px solid #282b3a;
    }
    &:active {
        transform: scale(1);
    }
    &.expanded {
        right: -220px;
        opacity: 1;
    }

    img {
        &.left {
            transform: rotate(180deg);
        }
    }
`;

export const SidebarMenu = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    height: calc(100% - 100px);
`;

export const SidebarMenuTop = styled.div``;

export const SidebarMenuBottom = styled.div``;

export const SidebarExpansion = styled.div`
    width: 70px;
    height: calc(100% - 50px);
    border-radius: 18px;
    box-shadow: 0px 0px 10px -4px #000000;
    z-index: 100;
    position: absolute;    
    z-index: 1;
    background-color: #272636;    
    padding: 17px 24px 17px 40px;
   
    opacity: 1;
    width: 250px;
    transform: translateX(60px);
`;

export const ExpansionHeader = styled.div`
    height: 57px;
    background-color: #232233;
    border-radius: 3px;
    padding: 10px;
    position: relative;

    small {
        font-size: 10px;
        color: #fff;
    }

    p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 155px;
        font-size: clamp(12px, 0.2em + 1vw, 1em);
        color: #ff8f63;
        font-weight: bold;
    }

    .--content-toggler {
        background-color: transparent;
        border: 0;
        outline: none;
        position: absolute;
        right: 4px;
        top: 20px;
        padding: 3px;
        cursor: pointer;
        transition: all 0.1s ease-in-out;

        &.--up {
            transform: rotate(180deg);
        }
    }

    &.--show-content {
        height: initial;

        p {
            white-space: normal;
            text-overflow: initial;
        }
    }
`;

export const ExpansionList = styled.div`
    ul {
        list-style: none;
        margin-top: 20px;
        li {
            .--menu-item {
                min-width: 200px;
                display: flex;
                padding: 15px 0;
                cursor: pointer;
                filter: grayscale(1);
                transition: all 100ms cubic-bezier(0.075, 0.82, 0.165, 1);
                opacity: 0.5;
                align-items: center;
                text-transform: uppercase;

                &:hover {
                    transform: scale(1.05);
                    filter: none;
                    opacity: 1;
                }

                &:active {
                    transform: scale(1);
                }

                img {
                    width: 20px;
                }

                p {
                    margin-left: 10px;
                    font-size: 16px;
                    color: #fff;
                    font-weight: bold;
                }

            }
            .--menu-item-selected {
                filter: none;
                transform: scale(1.05);
                opacity: 1;
            }
        }
    }
`;
export const ExtraUserInfo = styled.div`
    width: 100%;
    padding: .3rem .4rem;
    background-color: #ff6479;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    border-radius: 3px;
    color: #fff;
    font-weight: bold;
    overflow: hidden;

    p {
        font-size: .8rem;
    }
`;

export const DropdownContainer = styled.div`
    position: relative;
    .left {
        left: 0;
    }

    .right {
        right: 0;
    }

    .top {
        top: 100%;
    }

    .bottom {
        bottom: 100%;
        margin-bottom: 10px;
    }
    height: 57px;
    background-color: #232233;
    border-radius: 3px;
    padding: 10px;
    position: relative;
`;

export const DropdownButtonWrapper = styled.div`
    outline: none;
    cursor: pointer;
    small {
        font-size: 10px;
        color: #fff;
        margin-left: 4px;
    }
`;

export const DropdownList = styled.div`
    background: #232233;
    display: block;
    z-index: 99;
    position: absolute;
    min-width: 100%;
    box-shadow: 0px 0px 10px -6px #000000;
    max-height: 50vh;
    overflow-y: scroll;
    margin-top: 2px;

    .--search-wrapper {
        position: sticky;
        top: 0;
        background-color: #232233;
        z-index: 5;
        button {
            position: absolute;
            right: 15px;
            bottom: 14px;
            background: transparent;
            border: none;
            margin: 0;
            padding: 1px;
            img {
                width: 12px;
            }
        }
    }

    .--search {
        border-radius: 5px;
        height: 30px;
        width: 100%;
        background-color: #212032;
        border: 1px solid #b8b8ba73;
        padding: 0 20px 0 5px;
        color: #d5d5d5d5;
        font-weight: bold;
        font-size: 12px;
    }

    li {
        list-style: none;
        padding: 10px;
        color: #d5d5d5d5;
        font-weight: bold;
        position: relative;

        &:not(:last-child)::after {
            content: "";
            height: 1px;
            position: absolute;
            bottom: 0;
            background-color: #302f3d;
            width: 90%;
            left: 0;
            margin-left: 5%;
        }
        &:first-child {
            margin-top: 0;
            padding: 0 10px 10px 10px;
        }
        &:last-child {
            margin-bottom: 5px;
        }
        &:hover {
            cursor: pointer;
            color: #ff8f63;
        }
    }
`;

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 57px;
    position: relative;

    img {
        transition: all 0.1s ease-in-out;
        width: 12px;
        position: absolute;
        right: -5px;
        top: 19px;
    }

    p {
        font-size: clamp(12px, 0.2em + 1vw, 1em);
        font-weight: bold;
        margin-right: 10px;
        color: #fff;
        text-transform: uppercase;
        &.emphasis {
            margin-left: 4px;
            color: #ff8f63;
        }
    }
    .--open {
        transform: rotate(180deg);
    }
`;
