import styled from 'styled-components';

export const RoomSettingUpWarningContainer = styled.div`
    margin: 0 0.5rem;
    opacity: ${({ isSettingUp }) => (isSettingUp ? 1 : 0)};
    transition: 0.5s ease-in;
    height: 100%;
    svg {
        width: 100% !important;

        &:hover {
            border: 0;
            outline: 0;
        }
    }
    
    .warning-room-creating {
        height: 100%;
        border-width: 0px;
        display: flex;
        border-radius: 12px;
        background-color: #212436 !important;
        color: #ffffff;
        font-weight: bold;
        font-size: clamp(12px, 0.4em + 1vw, 1.2em);
        align-items: center;
        padding: 1rem 15px;
        text-transform: uppercase;
        transition: all 0.1s ease-in-out;
        position: relative;
        flex-direction: column;
        justify-content: center;

        &:hover {
            box-shadow: 0px 0px 10px -4px #000000;
        }
        &:disabled {
            opacity: 0.4;
        }
    }
    span {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

`;
