import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ToggleSwitch from '../../components/ToggleSwitch';
import {
    Container,
    Section,
    Content,
    Button,
    ModalButton,
    ModalContainer,
    TeamsList,
    Column,
} from './styles';
import plusIcon from '../../assets/plus.svg';
import Modal from '../../components/Modal';
import Loading from '../../components/Loading';
import { getTeamsStatus, asyncSetTeamsStatus, asyncCreateTeams } from '../../store/modules/teams/actions';
import useTeams from '../../hooks/useTeams';
import { MAX_TEAM_PEOPLE } from '../../utils/constants';
import NoContent from '../../components/NoContent';
import Caution from '../../components/Caution';
import { useTranslation } from 'react-i18next';

function Item({ team, index }) {
    const preview = team && team.members.length > 0 ? team.members : [];
    const { t } = useTranslation();

    return (
        <li>
            <h2>{t('TEAM')} { index + 1 }</h2>
            <div>
                {preview.length > 0 && preview.map((member, i) => (
                    <p key={i.toString()}>{member.name}</p>
                ))}
            </div>
        </li>
    );
}

function ModalContent({
    createTeam,
    loading,
}) {
    const maxPeople = new Array(MAX_TEAM_PEOPLE).fill(0);
    const selectRef = useRef(null);
    const { t } = useTranslation();

    return (
        <ModalContainer>
            { loading
                ? <Loading />
                : (
                    <>
                        <h2>{t('TEAMS', {context: 'CREATETEAMS'})}</h2>
                        <p>{t('TEAMS', {context: 'QUANTITY'})}</p>
                        <select ref={selectRef}>
                            {maxPeople && maxPeople.map((item, index) => (
                                <option key={`${item}-${index.toString()}`} value={index + 2}>
                                    {(index + 2) < 10 ? `0${index + 2}` : index + 2 }
                                </option>
                            ))}
                        </select>
                        <ModalButton
                            onClick={() => createTeam(selectRef)}
                        >
                            {t('TEAMS', {context: 'GENERATETEAMS'})}
                        </ModalButton>
                    </>
                )}
        </ModalContainer>
    );
}

export default function Teams() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [id, setId] = useState('');
    const { teams, loading, isTeamsEnabled } = useTeams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();


    useEffect(() => {
        function getRoomId() {
            const path = history.location.pathname;
            return path.split('/')[2];
        }

        const path = getRoomId();
        setId(path);
    }, [setId, history]);

    useEffect(() => {
        if (id !== '') {
            dispatch(getTeamsStatus(id));
        }
    }, [id, dispatch]);

    function handleToggle() {
        if (id !== '') {
            dispatch(asyncSetTeamsStatus({ status: !isTeamsEnabled, roomId: id }));
        }
    }

    function handleModal() {
        setIsModalOpen(!isModalOpen);
    }

    function createTeam({ current }) {
        if (id !== '') {
            dispatch(asyncCreateTeams({ roomId: id, people: parseInt(current.value, 10) }));
        }
    }

    return (
        <Container>
            <Modal isOpen={isModalOpen} handleModal={handleModal}>
                <ModalContent
                    createTeam={createTeam}
                    loading={loading}
                />
            </Modal>
            <Section>
                <Column>
                    <ToggleSwitch
                        isOn={isTeamsEnabled}
                        handleToggle={handleToggle}
                        disabled={loading}
                    />
                    <p> {t('TEAMS', {context: 'MODETEAMS'})}: <span>{isTeamsEnabled ? t('ACTIVE') : t('INACTIVE')}</span></p>
                </Column>
                <Column>
                    <h2>{t('TEAMS')}</h2>
                </Column>
                <Column>
                    { !isTeamsEnabled && (
                        <Button onClick={handleModal} disabled={isTeamsEnabled}>
                            <img src={plusIcon} alt={t('TEAMS', {context: 'CREATETEAMS'})} />
                            <span>{t('TEAMS', {context: 'CREATETEAMS'})}</span>
                        </Button>
                    )}
                </Column>
            </Section>
            <Content>
                {isTeamsEnabled ? (
                    teams && teams.length > 0 ? (
                        <TeamsList>
                            {teams.map((team, i) => <Item team={team} key={i.toString()} index={i} />)}
                        </TeamsList>
                    ) : (
                        <NoContent 
                            title={t('OPS')}
                            text={t('TEAMS', {context: 'NOTEAMS'})}
                            button={{
                                text: t('TEAMS', {context: 'ACTIVATETEAMS'}),
                                action: handleToggle,
                            }}
                        />
                    )
                ) : (<Caution 
                        title={t('TEAMS', {context: 'MODETEAMSINACTIVATE'})}
                        text={t('TEAMS', {context: 'CREATETEAMSDESC'})}
                        button={{
                            text: t('TEAMS', {context: 'CREATETEAMS'}),
                            action: handleModal,
                        }}
                    />)
                }
            </Content>
        </Container>
    );
}
