/* eslint-disable indent */
import actions from '../../../utils/actions';
import { ITEMS_PER_PAGE_FRONTEND_PAGINATION } from '../../../utils/constants';
import { paginate } from '../../../utils/frontPagination';

const initialState = {
    questions: [],
    paginatedQuestions: [],
    page: 0,
    loading: true,
    isLastPage: false,
    nextPageLoading: false,
};
export default function quizzesReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.QUIZZES_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case actions.GET_QUIZZES_NEXT_PAGE: {
            if (state.page === state.paginatedQuestions.length - 1) {
                state.isLastPage = true;
            }
            return {
                ...state,
                questions: [...state.questions, ...state.paginatedQuestions[state.page]],
                nextPageLoading: false,
                page: state.page + 1,
            };
        }

        case actions.QUIZZES_SUCCESS: {
            if (payload?.length <= ITEMS_PER_PAGE_FRONTEND_PAGINATION) {
                state.isLastPage = true;
            } else {
                state.isLastPage = false;
            }
            return {
                ...state,
                loading: false,
                page: 1,
                questions: paginate(payload)[0] || [],
                paginatedQuestions: paginate(payload),
                nextPageLoading: false,
            };
        }
        case actions.QUIZZES_FAILURE:
            return { ...state, loading: false, questions: [], nextPageLoading: false };
        default:
            return state;
    }
}
