import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: calc(100% - 11%);
    width: 100%;
    position: relative;
    flex-flow: column nowrap;
    padding: 1rem;
    overflow: auto;

    padding: 0 4em 0 25px;

    td {
        img {
            width: 33px;
        }
    }

    h2 {
        font-size: clamp(18px, 0.5em + 1vw, 1.9em);
        color: white;
        text-transform: uppercase;
    }

    .loader {
        z-index: 999;
        top: 50%;
        left: 50%;
        position: absolute;
    }
    .loaderButton {
        top: 0;
        margin-top: 5px;
        position: absolute;
    }

    .MuiCircularProgress-colorPrimary {
        color: #ffffff;
    }

    .containerTable {
        margin-top: 1rem;
        margin-bottom: 0;
        max-height: 70vh;
        overflow: auto;
        border-radius: 8px;

        @media (max-width: 650px) {
            max-height: 60vh;
        }
    }

    @media (max-width: 650px) {
        padding: 0 1em;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const PageHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
    background-color: #1c1b2d;
    border-radius: 12px;
    padding: 0.1rem;

    .presence-report-search {
        width: 100%;

        button {
            height: 28px;
            border-radius: 8px;
            padding: 0.2rem 0.5rem;
            font-size: 0.9rem;
            text-align: center;
        }        
    }

    .buttons-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            text-justify: center;
            text-align: center;

            span {
                margin: 0 auto;
            }
        }

        button + button {
            margin: 0 1rem;
        }

        @media screen and (max-width: 1100px) {
            width: 100% ;
            justify-content: center;
            align-items: center;
            padding: 0px 1rem ;
            padding-bottom: 1rem;
            button {
                width: 100%;
                margin: 0 0.5rem;
            }

            button + button {
                margin: 0;
                margin-right: 0.5rem ;
            }
        }
    }

    button {
        border-width: 0px;
        display: flex;
        align-self: flex-end;
        height: 47px;
        border-radius: 12px;
        background-color: #29283f;
        color: #ffffff;
        font-weight: bold;
        font-size: clamp(12px, 0.4em + 1vw, 1.2em);
        align-items: center;
        padding: 0 15px;
        text-transform: uppercase;
        transition: all 0.1s ease-in-out;
        &:hover {
            box-shadow: 0px 0px 10px -4px #000000;
            transform: scale(1.01);
        }
        &:disabled {
            opacity: 0.4;
        }
    }

    small {
        margin-left: -15px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        width: 34px;
        border-radius: 7px;
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
    }

    @media screen and (max-width: 1100px) {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`;

export const ReportTable = styled.div`
    width: 100%;
    padding: 0 0 1px;
    border-radius: 8px;
    background-color: #212032;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #ffffff;

    th {
        background-color: #1c1b2d;
        padding: 12px 0.4em;
        text-transform: uppercase;
    }

    tr {
        background-color: #212032;
        &:nth-child(even) {
            background-color: #242339;
        }
        td {
            padding: 10px 0.5em;
        }
        &:not(:last-child):hover {
            background-color: #2f2e3e;
        }
    }

    .MuiTableCell-root.MuiTableCell-body {
        border-bottom: none;
    }

    .MuiPaginationItem-ellipsis {
        color: #fff;
    }

    .table-footer {
        background-color: #1c1b2d;
        td {
            padding: 12px 0.4em;
        }

        span {
            font-size: 12px;
        }

        select {
            background: #24243e;
            border: 0;
            border-radius: 6px;
            letter-spacing: 0.21px;
            color: #ffffff;
            margin: 0 1em;
            height: 2em;

            &:focus {
                border: solid 1px #ff6479;
            }
        }
    }
    .organizer {
        color: #ff9162;
    }

    .monitor {
        color: #ff6479;
    }

    @media (max-width: 650px) {
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0;
        @media (max-width: 650px) {
            max-height: 40vh;
        }
    }
`;

export const TableFooterResults = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .table-footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.8rem 1rem;
        border-radius: 0 0 8px 8px;
        background-color: #1c1b2d;
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        color: #ffffff;
    }
    span {
        font-size: .8rem;
        min-width: 200px;
        text-align: center;
        align-self: center;
    }

`;

export const LoadMoreButtonSection = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    button {
        color: #ff9162;
        margin: 5px auto;
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 387px;
        border: 0;
        background: transparent;
        font-size: 1.1rem;
        margin: 0 auto;
        color: #fff;
        background: linear-gradient(
            90deg,
            rgba(255, 174, 70, 1) 0%,
            rgba(255, 66, 141, 1) 100%
        );
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 3;
        letter-spacing: 0.21px;
        border-radius: 8px;
        text-transform: uppercase;

        &:disabled {
            opacity: 0.2;
        }
    }
`;
