import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAuth from '@hooks/useAuth';
import { changeOnBoardingStatus, setOnBoarding } from '@src/store/modules/auth/actions';
import constants from '@src/assets/i18n/constants';
import { Onboarding } from './styles';

export default function OnboardingTutorials() {
    const [isTourOpen, setTourOpen] = useState(false);
    const { onBoarding } = useAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeOnBoardingStatus());
    }, [dispatch]);

    useEffect(() => {
        if (onBoarding?.data) {
            if (!(onBoarding.data.onBoardingOrganizerTutorials) && !(onBoarding.skipped)) {
                setTourOpen(true);
            }
        }
    }, [dispatch, onBoarding]);

    function handleCloseOnboarding(key, skipped, global_skip) {
        dispatch(setOnBoarding(key, skipped, global_skip));
        setTourOpen(false);
    }

    const steps = {
        steps: [
            {
                level: 'all',
                selector: '',
                content: ({ goTo }) => (
                    <>
                        <h2>{t(constants.ONBOARDING_TUTORIALS_AREA_TITLE)}</h2>
                        <p>{t(constants.ONBOARDING_TUTORIALS_AREA)}</p>
                        <div className="buttons">
                            <button
                                type="button"
                                className="btn continue-btn"
                                onClick={() => goTo(1)}
                            >
                                {t(constants.ONBOARDING_LETS_START)}
                            </button>
                            <button
                                type="button"
                                className="btn ignore-btn"
                                onClick={() => handleCloseOnboarding('onBoardingOrganizerTutorials', true)}
                            >
                                {t(constants.ONBOARDING_SKIP_TOUR)}
                            </button>
                        </div>
                    </>
                ),
                position: 'center',
            },
            {
                level: 'all',
                selector: '[data-tour="tour__tutorial_card"]',
                content: () => (
                    <>
                        <h2>{t(constants.ONBOARDING_TUTORIALS_TUTORIAL_CARD_TITLE)}</h2>
                        <p>{t(constants.ONBOARDING_TUTORIALS_TUTORIAL_CARD)}</p>
                        <button
                            type="button"
                            className="btn complete-btn"
                            onClick={() => handleCloseOnboarding('onBoardingOrganizerTutorials')}
                        >
                            {t(constants.ONBOARDING_COMPLETE)}
                        </button>
                    </>
                ),
            },
        ],
    };

    return (
        <Onboarding
            steps={(steps.steps)}
            isOpen={(isTourOpen)}
            maskClassName="onboardingOverlay"
            accentColor="#ff438d"
            maskSpace={6}
            disableInteraction
            closeWithMask={false}
            rounded={4}
            onRequestClose={() => handleCloseOnboarding('onBoardingOrganizerTutorials', true)}
            closeButtonAriaLabel={t(constants.ONBOARDING_COMPLETE)}

        />
    );
}
