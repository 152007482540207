import styled, { css } from 'styled-components';

const dragActive = css`
    border-color: #78e5d5;
`;

const dragReject = css`
    border-color: #e57878;
`;

export const DropContainer = styled.div.attrs({ className: 'dropzone' })`  
    border-radius:4px;
    cursor: pointer;

    transition: height 0.2s ease;
    
    ${props => props.isDragActive && dragActive};
    ${props => props.isDragReject && dragReject};
`;

const messageColors = {
    default: '#999',
    error: '#e57878',
    success: '#78e5d5',
};

export const UploadMessage = styled.p`
    display:flex;
    flex-direction: column;
    color: ${props => messageColors[props.type || 'default']};
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    font-size: 12px;
    font-weight: bold;  
    color: #474783;
    text-transform: uppercase;

    img {
        margin-bottom: 8px;
    }
`;

export const UploadContainer = styled.div`
    width: 100%;
`;

export const UploadMultipleContainer = styled.div`
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: ${(p) => (p.attachmentsPosition ? '300px calc(100% - 300px)' : '1fr')};
`;

export const ClearButton = styled.button.attrs(() => ({ type: 'button' }))`
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
`;

export const FileFormatError = styled.div`
    padding: .4rem .3rem;
    color: #fff;
    font-size: 12px;
    background-color: #ff6363;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        margin: 0 .5rem;
    }
    
    p {
        font-weight: bold;
    }

`;

export const CropContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    max-height: 60vh;
    overflow-y: auto;
    
    img {
        max-height: 60vh;
    }

    .crop-component {
        width: 90%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    
    button {
        margin: 2rem 0;
        width: 100%;
        padding: 15px 0;
        border: 0;
        border-radius: 8px;
        color:#fff;
        background: linear-gradient(90deg, rgba(255,66,141,1)  0%, rgba(255,174,70,1) 100%);
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.21px;
    }
 
`;
