import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import 'react-dropdown/style.css';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { getGamificationRules } from '@src/store/modules/gamification/actions';
import useGames from '@src/hooks/useGames';
import plusIcon from '@src/assets/plus-icon.svg';
import pencilIcon from '@src/assets/pencil.svg';
import plusDetailIcon from '@src/assets/plus-detail-icon.svg';
import useRooms from '@src/hooks/useRooms';
import Modal from '@src/components/Modal';
import NoContent from '@src/components/NoContent';
import { QUIZ_TYPE } from '@utils/constants';
import paths from '@utils/paths';
import { useBreadcrumbs } from '@src/hooks/useBreadcrumbs';
import useQuery from '@src/hooks/useQuery';
import useQuizzes from '@src/hooks/useQuizzes';
import api from '@services/api';
import { limitCharacters } from '@utils/functions';
import { getChannelRooms } from '@src/store/modules/rooms/actions';
import { useFetchEditRoom } from '@src/hooks/useFetchEditRoom';
import { getQuizzes, getQuizzesSuccess, getQuizzNextPage } from '@src/store/modules/quizzes/actions';
import { Container, PageHeader, QuizTable, TableFooterResults, LoadMoreButtonSection } from './styles';
import QuizTypeContent from './QuizTypeContent';
import QuizContentForm from './QuizContentForm';
import QuizDetailContent from './QuizDetailContent';

const LoadingSkeleton = () => (
    <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
        <div style={{ marginTop: 10 }}>
            <Skeleton height={50} count={10} style={{ margin: '5px 0' }} />
        </div>
    </SkeletonTheme>
);

export default function QuizzesDetail() {
    const [quizType, setQuizType] = useState(QUIZ_TYPE.QUIZ);
    const [showQuizTypeModal, setShowQuizTypeModal] = useState(false);
    const [showQuizForm, setShowQuizForm] = useState(false);
    const [showQuizDetail, setShowQuizDetail] = useState(false);
    const [isGamificationEnabled, setIsGamificationEnabled] = useState(false);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { questions, loading, nextPageLoading, paginatedQuestions, isLastPage } = useQuizzes();
    const { selectedChannel, selectedEditRoom } = useRooms();
    const { id: roomId } = useParams();
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const { setItems } = useBreadcrumbs();
    const [isEdit, setIsEdit] = useState(false);
    const { gamificationRules } = useGames();

    const query = useQuery();
    const history = useHistory();

    function handleNextPage() {
        dispatch(getQuizzNextPage());
    }

    function renderStatus(status) {
        const type = {
            preExcution: <span className="disabled">{t('DISABLED')}</span>,
            onExcution: <span className="in-progress">{t('IN_PROGRESS')}</span>,
            postExcution: <span className="finished">{t('FINISHED')}</span>,
        };
        return type[status];
    }

    function renderType(quizType) {
        const type = {
            quizz: t('QUIZ'),
            research: t('SURVEY'),
        };
        return type[quizType];
    }

    function handleAddQuiz() {
        setIsEdit(false);
        setQuizType(QUIZ_TYPE.QUIZ);
        setShowQuizForm(true);
        history.push(`/quizzes/${roomId}/quizzControl`);
    }

    function handleAddSurvey() {
        setIsEdit(false);
        setQuizType(QUIZ_TYPE.SURVEY);
        setShowQuizForm(true);
        history.push(`/quizzes/${roomId}/surveysControl`);
    }

    function handleDetailQuiz(question) {
        if (question.type === QUIZ_TYPE.QUIZ) {
            setQuizType(QUIZ_TYPE.QUIZ);
        } else {
            setQuizType(QUIZ_TYPE.SURVEY);
        }
        history.push(`?question=${question.id}&groups=true`);
        setShowQuizDetail(true);
    }

    function handleEditQuiz(question) {
        if (question.type === QUIZ_TYPE.QUIZ) {
            setQuizType(QUIZ_TYPE.QUIZ);
            if (question.groups) {
                history.push(`/quizzes/${roomId}/quizzControl?id=${question.id}`);
            } else {
                setShowQuizForm(true);
            }
        } else {
            setQuizType(QUIZ_TYPE.SURVEY);
            if (question.groups) {
                history.push(`/quizzes/${roomId}/surveysControl?id=${question.id}`);
            } else {
                setShowQuizForm(true);
            }
        }
        setCurrentQuestion(question);
    }

    const getQuestionStatus = useCallback(async (questionOnExecution, questionsList) => {
        const { status } = await api.getQuestion(roomId, questionOnExecution?.id);
        if (status === 'postExcution') {
            const updatedQuestions = questionsList
                .map(question => (question.id === questionOnExecution.id
                    ? { ...question, status: 'postExcution' }
                    : question));
            dispatch(getQuizzesSuccess(updatedQuestions));
        }
    }, [roomId, dispatch]);

    useEffect(() => {
        if (roomId) {
            dispatch(getQuizzes(roomId));
        }
    }, [dispatch, roomId]);

    useEffect(() => {
        if (selectedChannel.id) {
            dispatch(getGamificationRules(selectedChannel.id));
        }
    }, [dispatch, selectedChannel.id]);

    useFetchEditRoom(roomId);

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t('ORGANIZER_DASHBOARD') },
            { to: paths.QUIZZES, label: t('QUIZZES_SURVEYS') },
            { to: `${paths.QUIZZES}/${roomId}/?roomName=${selectedEditRoom?.name || ''}`, label: selectedEditRoom?.name || '' },
        ];
        setItems(breadcrumbsItems);
    }, [query, roomId, setItems, t, selectedEditRoom]);

    useEffect(() => {
        const onExecutionQuizzes = !!questions?.find(question => question?.status === 'onExcution') || false;
        const pool = setInterval(() => {
            if (onExecutionQuizzes) {
                questions.forEach(question => {
                    if (question?.status === 'onExcution') {
                        getQuestionStatus(question, questions);
                    }
                });
            }
        }, 2000);
        return () => clearInterval(pool);
    }, [getQuestionStatus, questions]);

    useEffect(() => {
        if (selectedChannel?.id) {
            getChannelRooms(selectedChannel);
        }
    }, [selectedChannel]);

    useEffect(() => {
        const checkIsEnabledGamificationRule = gamificationRules
            && gamificationRules[1]
            && gamificationRules[1].enabled;

        if (checkIsEnabledGamificationRule) {
            setIsGamificationEnabled(checkIsEnabledGamificationRule);
        }
    }, [gamificationRules]);

    return (
        <Container>
            <Modal
                isOpen={showQuizTypeModal}
                handleModal={() => setShowQuizTypeModal(false)}
                title={t('CONTENT_TYPE_TO_ADD')}
                width={600}
            >
                <QuizTypeContent
                    handleAddQuiz={handleAddQuiz}
                    handleAddSurvey={handleAddSurvey}
                    handleClose={() => setShowQuizTypeModal(false)}
                />
            </Modal>

            <Modal
                isOpen={showQuizForm}
                handleModal={() => setShowQuizForm(false)}
                title={isEdit ? t('EDIT') : t('ADD')}
                width={600}
                featuredTitle={quizType === QUIZ_TYPE.QUIZ ? t('QUIZ') : t('SURVEY')}
            >
                <QuizContentForm
                    quizType={quizType}
                    roomId={roomId}
                    editQuestion={currentQuestion}
                    clearQuestion={() => setCurrentQuestion(null)}
                    closeForm={() => setShowQuizForm(false)}
                    hasGamificationRule={isGamificationEnabled}
                />
            </Modal>

            <Modal
                isOpen={showQuizDetail}
                handleModal={() => setShowQuizDetail(false)}
                title={quizType === QUIZ_TYPE.QUIZ ? t('QUIZ_RESULT') : t('SURVEY_RESULT')}
                width={800}
                bodyWidth={90}
            >
                <QuizDetailContent
                    quizType={quizType}
                    handleClose={() => setShowQuizDetail(false)}
                />
            </Modal>

            <PageHeader>
                <h2>{selectedChannel?.id && limitCharacters(`#${selectedChannel?.id} - ${selectedChannel?.name}`, 40)}</h2>
                <button type="button" onClick={() => setShowQuizTypeModal(true)}>
                    <small>
                        <img alt="plusIcon" src={plusIcon} width={30} />
                    </small>
                    <span>{t('NEW_QUIZ_OR_SURVEY')}</span>
                </button>
            </PageHeader>
            {
                !loading && !questions?.length > 0 && (
                    <NoContent
                        title={t('NO_QUIZ_SURVEY_REGISTERED')}
                        text={t('NO_QUIZ_SURVEY_REGISTERED_MESSAGE')}
                        button={{ text: t('REGISTER_QUIZ_SURVEY'), action: () => setShowQuizTypeModal(true) }}
                    />
                )
            }
            {
                loading && <LoadingSkeleton />
            }
            {
                !loading && questions?.length > 0 && (
                    <div className="containerTable">
                        <QuizTable>
                            <Table>
                                <TableHead>
                                    <tr>
                                        <th align="left">{t('TYPE')}</th>
                                        <th align="left">{t('TITLE')}</th>
                                        <th align="left">{t('DURATION')}</th>
                                        <th align="left">{t('ANSWER')}</th>
                                        <th align="left">{t('STATUS')}</th>
                                        <th align="left">{' '}</th>
                                    </tr>
                                </TableHead>
                                <TableBody>
                                    {(paginatedQuestions.length > 0 && questions
                                    ).map((question) => (
                                        <tr key={question?.id}>
                                            <td align="left">{renderType(question?.type)}</td>
                                            <td align="left">{question?.title}</td>
                                            <td align="left">{question?.duration}</td>
                                            <td align="left">{question?.correctAnswer || '-'}</td>
                                            <td align="left">{renderStatus(question?.status)}</td>
                                            <td align="right">
                                                <div className="line-controls">
                                                    {
                                                        (question?.status === 'preExcution' || question?.status === 'onExcution')
                                                        && (
                                                            <button className="action-button edit" type="button" onClick={() => handleEditQuiz(question)}>
                                                                <img src={pencilIcon} alt={t('EDIT')} />
                                                                {t('EDIT')}
                                                            </button>
                                                        )
                                                    }
                                                    <button className="action-button" type="button" onClick={() => handleDetailQuiz(question)}>
                                                        <img src={plusDetailIcon} alt={t('DETAILS')} />
                                                        {t('DETAILS')}
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </TableBody>
                            </Table>
                        </QuizTable>
                    </div>
                )
            }
            {!loading && questions?.length > 0 && (
                <TableFooterResults>
                    <div className="table-footer">
                        <LoadMoreButtonSection>
                            {nextPageLoading ? (
                                <CircularProgress
                                    style={{ color: '#ff9162' }}
                                />
                            ) : (
                                !isLastPage && (
                                    <button type="button" onClick={handleNextPage}>
                                        {t('LOAD_MORE_PAGINATION')}
                                    </button>
                                )
                            )}
                        </LoadMoreButtonSection>

                        <span>
                            {questions?.length || 0} {t('RESULTS')}
                        </span>
                    </div>
                </TableFooterResults>
            )}
        </Container>
    );
}
