import actions from '../../../utils/actions';

const initialState = {
    loading: true,
    ranking: [],
    totalPages: null,
    activePage: [],
    isLastPage: false,
    nextPageLoading: false,
    gamificationRules: null,
};
export default function rankReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.SET_RANKING:
            return {
                ...state,
                ranking: payload.rank.users,
                totalPages: payload.totalPages,
                loading: false,
                isLastPage: payload.isLastPage,
            };
        case actions.SET_PAGE_RANKING_LOADING:
            return { ...state, loading: payload };
        case actions.GET_RANKING_NEXT_PAGE:
            return {
                ...state,
                nextPageLoading: true,
            };
        case actions.SET_RANKING_PAGINATED:
            {
                return {
                    ...state,
                    ranking: [...state.ranking, ...payload.rank.users],
                    nextPageLoading: false,
                    isLastPage: payload.isLastPage,
                };
            }
        case actions.GET_RANKING_FAILURE:
            return {
                ...state,
                nextPageLoading: false,
                loading: false,
            };
        case actions.TOGGLE_GAMIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case actions.TOGGLE_GAMIFICATION_SUCCESS:
        case actions.TOGGLE_GAMIFICATION_FAIL:
            return {
                ...state,
                loading: false,
            };
        case actions.SAVE_GAMIFICATION_RULES:
            return {
                ...state,
                loading: true,
            };
        case actions.SAVE_GAMIFICATION_RULES_SUCCESS:
            case actions.SAVE_GAMIFICATION_RULES_FAIL:
            return {
                ...state,
                loading: false,
            };
        case actions.FETCH_GAMIFICATION_RULES:
            return {
                ...state,
                loading: false,
            };
        case actions.FETCH_GAMIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                gamificationRules: payload,
            };
        case actions.FETCH_GAMIFICATION_FAIL:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}
