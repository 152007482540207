import styled from 'styled-components';

export const Container = styled.div`     
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100vw;
    height: 100vh;
    .video-wall-full-screen-controls {
        position: absolute;
        z-index: 1;
        bottom: 10px;
        opacity: 0.8;
    }
    
    .navigation-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 0;
        top: 0;        
        z-index: 1;        
        display: ${props => (props.isFullscreen ? 'none' : 'flex')};

        &.left {
            left: ${props => (props.isFullscreen ? '0' : '100px')};
            img {
                transform: rotate(90deg);
            }
        }
        &.right {
            right: ${props => (props.isFullscreen ? '0' : '47px')};
            img {
                transform: rotate(-90deg);
            }
        }

        &.showControls {
            display: flex;
        }

        button {
            border: none;
            background-color: #ff8f63;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
            width: 30px;
            border-radius: 3px;
            opacity: 0.9;

            &:disabled {
                opacity: 0.3;
                cursor: not-allowed; 
            }
        }  
    }
`;

export const VideoWallVideo = styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;       
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 12px;
        margin: 5px;
        justify-self: center;
        
        width: ${({ width }) => width && width}px;
        height: ${({ width }) => width && width * 0.65}px;
        
        .video-wall-video-description {
            min-height: 47px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #0202028f;
            color: #fff;
            position: absolute;
            bottom: 0;
            padding: 0 15px;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;

            h5 {
                font-size: 1.2rem;
                overflow: hidden;
                max-width: 75ch;
                text-overflow: ellipsis;
                white-space: nowrap;
            }   
        }
`;

export const NoVideo = styled.div`
    background-color: #000000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    padding: 10px;
    border-radius: 12px;
    width: ${({ width }) => width && width};
    
    h2 {
        margin: 10px;
        text-transform: uppercase;
    }
`;

export const GridAreaContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${({ gridSize }) => gridSize}, 1fr);
    grid-template-rows: repeat(${({ gridSize }) => gridSize}, 1fr);
    grid-gap: 10px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: 10px;
    video {
        border-radius: 12px;
        width: 100%;
    }
`;
