import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerEngagement } from './styles';
import QuestionIcon from '../../../assets/question-2.svg';
import EyeIcon from '../../../assets/eye.svg';
import GroupIcon from '../../../assets/group.svg';
import StreamingIcon from '../../../assets/streaming.svg';
import SpeedIcon from '../../../assets/speedometer.svg';
import Skeleton from '../../Skeleton';
import TooltipEngagement from '../../TooltipEngagement';

const ReportEngagement = ({ roomEngagement }) => {
    const { t } = useTranslation();

    return (
        <ContainerEngagement data-tour="tour__engagement">
            {!roomEngagement ? <Skeleton width="1920px" />
                : (
                    <>
                        <div className="engagement-card">
                            <div className="engagement-card-tooltip-area">
                                <TooltipEngagement
                                    content={t('USERS_THAT_ARE_NOW_VIEWING_THE_BROADCAST')}
                                >
                                    <img alt="Question Icon" src={QuestionIcon} className="question" />
                                </TooltipEngagement>
                            </div>
                            <div className="engagement-card-info">
                                <img alt="Eye Icon" src={EyeIcon} className="main-icon" />
                                <h4>{roomEngagement ? roomEngagement.viewers : ''}</h4>
                                <br />
                                <p>{t('USERS', { context: 'ACTIVE' })}</p>
                            </div>
                        </div>
                        <div className="engagement-card">
                            <div className="engagement-card-tooltip-area">
                                <TooltipEngagement
                                    content={t('USERS_WITH_TAB_AND_SOUND_ON_OR_IN_FULL_SCREEN_DIVIDED_BY_THE_TOTAL_USERS_VIEWED')}
                                >
                                    <img alt="Question Icon" src={QuestionIcon} className="question" />
                                </TooltipEngagement>
                            </div>
                            <div className="engagement-card-info">
                                <img alt="Group Icon" src={GroupIcon} className="main-icon" />
                                <h4>{roomEngagement && roomEngagement.viewers > 0 ? ((roomEngagement.viewers_engagement / roomEngagement.viewers) * 100).toFixed(2) : 0}%</h4>
                                <br />
                                <p>{t('USERS', { context: 'ENGAGEMENT' })}</p>
                                <br />
                                <p className="users-ratio">{roomEngagement.viewers_engagement}/{roomEngagement.viewers}</p>
                            </div>
                        </div>
                        <div className="engagement-card">
                            <div className="engagement-card-tooltip-area">
                                <TooltipEngagement
                                    content={t('USERS_THAT_ARE_EXPERIENCING_BUFFERING_ISSUE_OF_OVER_1_SECOND_DIVIDED_BY_TOTAL_USERS')}
                                >
                                    <img alt="Question Icon" src={QuestionIcon} className="question" />
                                </TooltipEngagement>
                            </div>
                            <div className="engagement-card-info">
                                <img alt="Streaming Icon" src={StreamingIcon} className="main-icon" />
                                <h4>{roomEngagement && roomEngagement.view_buffer_time_ratio > 0 ? (roomEngagement.view_buffer_time_ratio * 100).toFixed(2) : 0}%</h4>
                                <br />
                                <p>{t('BUFFER_CLASSIFICATION')}</p>
                            </div>
                        </div>
                        <div className="engagement-card">
                            <div className="engagement-card-tooltip-area">
                                <TooltipEngagement
                                    content={t('THE_AVERAGE_BITRATE_REPORTED_BY_KOPA_PLAYERS')}
                                >
                                    <img alt="Question Icon" src={QuestionIcon} className="question" />
                                </TooltipEngagement>
                            </div>
                            <div className="engagement-card-info">
                                <img alt="Speed Icon" src={SpeedIcon} className="main-icon" />
                                <h4>{roomEngagement && roomEngagement.avg_view_bitrate > 0 ? (roomEngagement.avg_view_bitrate).toFixed(2) : 0}kbps</h4>
                                <br />
                                <p>{t('BITRATE')}</p>
                            </div>
                        </div>
                    </>
                )}
        </ContainerEngagement>
    );
};

export default React.memo(ReportEngagement);
