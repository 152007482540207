import React, { useRef, useState, useEffect } from 'react';
import {
    LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer,
} from 'recharts';
import Moment from 'moment';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import { ContainerGraph } from './styles';
import TimeFilter from '../TimeFilter';
import ArrowDown from '../../../assets/arrow-down.svg';
import Skeleton from '../../Skeleton';

const defaultGraphMockData = [
    {
        name: "No Content",
        a: 100,
        b: 100,
    },
];

const ReportGraph = ({ roomGraph, roomId }) => {
    const wrapperRef = useRef(null);
    const [graphLine1Selected, setGraphLine1Selected] = useState(null);
    const [graphLine2Selected, setGraphLine2Selected] = useState(null);
    const [graphOptions, setGraphOptions] = useState(null);
    const [graphData, setGraphData] = useState(null);
    const [filterTimeGraph, setFilterTimeGraph] = useState(false);
    const [presetTimeFilter, setPresetTimeFilter] = useState(0);

    const { t } = useTranslation();

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setFilterTimeGraph(false);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideAlerter(wrapperRef);

    const handleGraphLine1 = (dropdown) => {
        setGraphLine1Selected(dropdown.value);
    };

    const handleGraphLine2 = (dropdown) => {
        setGraphLine2Selected(dropdown.value);
    };

    useEffect(() => {
        if (roomGraph) {
            const graphData = [];
            if (graphLine1Selected || graphLine2Selected) {
                for (let index = 0; index < roomGraph[0].coordinates.length; index++) {
                    const line1 = roomGraph.findIndex(e => e.id === graphLine1Selected);
                    const line2 = roomGraph.findIndex(e => e.id === graphLine2Selected);

                    graphData.push({
                        time: Moment.utc(roomGraph[roomGraph[line1] ? line1 : line2].coordinates[index][0]).local().format('DD/MM/YYYY HH:mm'),
                        id: roomGraph[line1] ? Number(roomGraph[line1].coordinates[index][1]) : '',
                        id2: roomGraph[line2] ? Number(roomGraph[line2].coordinates[index][1]) : '',
                    });
                }
            }
            setGraphData(graphData);
        }
    }, [graphLine1Selected, graphLine2Selected, roomGraph]);

    useEffect(() => {
        if (roomGraph) {
            setGraphOptions(roomGraph.map(option => ({ value: option.id, label: t('GRAPH', { context: option.id.replace(/_/g, '').toUpperCase() }) })));
        }
    // eslint-disable-next-line
    }, [roomGraph])
    return (
        <ContainerGraph isVisible={roomGraph} data-tour="tour__graph">
            { !roomGraph ? <div className="skeleton-container"><Skeleton width="1920" height="305" /></div>
                : (
                    <>
                        <div className="header">
                            <div className="filter-graph">
                                <Dropdown
                                    className="filter-graph1"
                                    options={graphOptions || ''}
                                    onChange={handleGraphLine1}
                                    value={graphOptions ? graphOptions.find(el => el.value === graphLine1Selected) : ''}
                                    placeholder={t('FILTER_FIRST')}
                                />
                                <p> VS </p>
                                <Dropdown
                                    className="filter-graph2"
                                    options={graphOptions || ''}
                                    onChange={handleGraphLine2}
                                    value={graphOptions ? graphOptions.find(el => el.value === graphLine2Selected) : ''}
                                    placeholder={t('FILTER_SECOND')}
                                />
                            </div>
                            <div className="button-filter" ref={wrapperRef}>
                                <button
                                    type="button"
                                    className="dropdown-button"
                                    onClick={() => (filterTimeGraph ? setFilterTimeGraph(false) : setFilterTimeGraph(true))}
                                >
                                    <p>{t('FILTER_TIME')}</p><img id="arrow-down" src={ArrowDown} alt="arrow down" />
                                </button>
                                {filterTimeGraph
                                    ? <TimeFilter
                                        roomId={roomId}
                                        presetTimeFilter={presetTimeFilter}
                                        setPresetTimeFilter={setPresetTimeFilter}
                                        />
                                    : '' }
                            </div>
                        </div>

                        <div className="graph-content">
                            {graphData && graphData.length > 0
                                ? (
                                    <ResponsiveContainer width="97%" height={260}>
                                        <LineChart
                                            data={graphData}
                                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                        >
                                            <XAxis dataKey="time" />
                                            <YAxis />
                                            <YAxis yAxisId="right" orientation="right" />
                                            <Tooltip />
                                            { graphLine1Selected ? <Line type="monotone" dot={false} dataKey="id" stroke="#f99652" activeDot={{ r: 8 }} /> : ''}
                                            { graphLine2Selected ? <Line type="monotone" dot={false} dataKey="id2" stroke="#8884d8" activeDot={{ r: 8 }} yAxisId="right" /> : ''}
                                        </LineChart>
                                    </ResponsiveContainer>
                                )
                                : (
                                    <ResponsiveContainer width="97%" height={260}>
                                        <LineChart
                                            data={defaultGraphMockData}
                                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                        >
                                            <XAxis dataKey="time" />
                                            <YAxis />
                                            <YAxis yAxisId="right" orientation="right" />
                                            <Line type="monotone" dataKey="a" stroke="#f99652" />
                                            <Line type="monotone" dataKey="b" stroke="#8884d8" yAxisId="right" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                )}
                        </div>
                    </>
                )}
        </ContainerGraph>
    );
};

export default React.memo(ReportGraph);
