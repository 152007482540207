import SocketIO from 'socket.io-client';

class WebRTCSocket {
    /**
     * @type { import('socket.io-client').Socket }
     */
    socket = null;

    /**
     * @type { Boolean }
     */
    connected = false;

    constructor() {
        this.token = null;
        this.onConnectListener = [];
        this.onDisconnectListener = [];

        this.authenticate = this.authenticate.bind(this);
        this.request = this.request.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.onConnect = this.onConnect.bind(this);
        this.onDisconnect = this.onDisconnect.bind(this);
    }

    disconnect() {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
    }

    onConnect() {
        if (this.onConnectListener.length) {
            this.onConnectListener.forEach(listener => {
                listener(true);
            });
        }
        this.connected = true;
    }

    onDisconnect(reason) {
        if (this.onDisconnectListener.length) {
            if (reason !== 'io client disconnect') {
                this.onDisconnectListener.forEach(listener => {
                    listener(false);
                });
            }
        }
        this.connected = false;
    }

    /**
     * WebSocket URL and SocketPath for WebRTC connection, both are required
     * @param {string} websocketUrl
     * @param {string} socketPath
     * @param {string} accessToken
     * @param {boolean} previewMode
     */
    async authenticate(websocketUrl, socketPath, accessToken, previewMode = false) {
        if (!accessToken) {
            throw Error('Access Token not provided!');
        }

        if (!websocketUrl) {
            throw Error('WebSocket URL not provided!');
        }

        if (!socketPath) {
            throw Error('WebSocket Path not provided!');
        }

        this.token = accessToken;

        if (!this.socket) {
            try {
                const connection = SocketIO(websocketUrl,
                    {
                        auth: previewMode ? { token: this.token, preview: true } : { token: this.token },
                        transports: ['websocket', 'polling'],
                        path: socketPath,
                    });
                this.socket = connection;
                this.socket.on('connect', this.onConnect);
                this.socket.on('disconnect', this.onDisconnect);
                this.socket.on('connect_error', () => this.onDisconnect);
                this.socket.request = this.request;
                window.socket = this.socket;
            } catch (error) {
                console.log('Fail to connect to websocket: ', error);
            }
        } else {
            this.socket.connect();
        }
    }

    /**
     * @param {string} type
     * @param {any} data
     * @returns {Promise}
     */
    request(type, data = {}) {
        return new Promise((resolve) => {
            this.socket.emit(type, data, resolve);
        });
    }
}

export default new WebRTCSocket();
