import React from 'react';
import { useTranslation } from 'react-i18next';
import constants from '@src/assets/i18n/constants';
import {
    Container, VideoTypeOption, VideoTypeOptionDisabled, VideoDescription, LiveVideo, LiveVideoItem, LiveVideoDisabled,
} from './styles';
import VideoIcon2 from '../../../assets/camera-icon.svg';
import { ENABLE_LIVE_CREATION } from '../../../utils/constants';

function ModalChannelType({ handleLiveModal, handleRecordedModal, handleClose }) {
    const { t } = useTranslation();
    const liveAvailable = ENABLE_LIVE_CREATION === 'true';
    return (
        <Container>
            {liveAvailable ? (
                <VideoTypeOption onClick={() => {
                    handleClose();
                    handleLiveModal();
                }}
                >
                    <LiveVideo>
                        <LiveVideoItem>
                            {t('LIVE')}
                        </LiveVideoItem>
                    </LiveVideo>
                    <VideoDescription>
                        <p>{t('LIVE')}</p>
                    </VideoDescription>
                </VideoTypeOption>
            ) : (
                <VideoTypeOptionDisabled>
                    <LiveVideoDisabled>
                        <LiveVideoItem>
                            {t('LIVE')}
                        </LiveVideoItem>
                    </LiveVideoDisabled>
                    <VideoDescription>
                        <p>{t(constants.LIVE_UNAVAILABLE)}</p>
                    </VideoDescription>
                </VideoTypeOptionDisabled>
            )}

            <VideoTypeOption onClick={() => {
                handleClose();
                handleRecordedModal();
            }}
            >
                <img src={VideoIcon2} alt="Video" />
                <VideoDescription>
                    <p>{t('UPLOAD_VIDEO')}</p>
                </VideoDescription>
            </VideoTypeOption>
        </Container>
    );
}

export default ModalChannelType;
