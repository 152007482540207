import Axios from 'axios';
import fileSize from 'filesize';
import { uniqueId } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import api from '@services/api';
import MultipleFileList from '@components/MultipleFileList';
import { Upload } from '..';
import { UploadMultipleContainer } from '../styles';

export const KopaMultipleUpload = ({
    fileType,
    accept,
    uploadedMultipleFiles,
    setUploadedMultipleFiles,
    multiple = false,
    uploadBaseUrl,
    setIsUploading,
}) => {
    const { t } = useTranslation();

    const updateFiles = useCallback(
        (uploadingFile) => {
            setUploadedMultipleFiles((oldFiles) => oldFiles.map((file) => (file.id === uploadingFile.id
                ? { ...file, ...uploadingFile }
                : file)));
            // eslint-disable-next-line
        },
        [setUploadedMultipleFiles],
    );

    function updateFileProgress(data, progress, isMultipleUpload = false) {
        const uploadingFile = data;
        uploadingFile.progress = progress.progress;
        if (isMultipleUpload) {
            updateFiles(uploadingFile);
        }
    }

    function updateFileUrl(data, url, isMultipleUpload = false) {
        const uploadingFile = data;
        uploadingFile.url = url;
        uploadingFile.uploaded = true;
        if (isMultipleUpload) {
            updateFiles(uploadingFile);
        }
    }

    const processUpload = async (uploadedFile, isMultipleUpload = false) => {
        const sanitizedFilenameUrl = uploadedFile.name.replace(/[^a-z0-9.]/gi, '');
        const data = new FormData();
        const baseUrl = isMultipleUpload && fileType === 'attachments'
            && `${uploadBaseUrl}/${sanitizedFilenameUrl}`;

        setIsUploading(prev => [...prev, uploadedFile.name]);
        try {
            const response = await api._api.post(baseUrl);
            const { fields } = response.data.uploadObject;
            Object.keys(fields).forEach((key) => {
                data.append(key, fields[key]);
            });
            data.append('file', uploadedFile.file);
            await Axios.post(`${response.data.uploadObject.url}`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: (e) => {
                    const progress = Number(
                        Math.round((e.loaded * 100) / e.total),
                    );
                    updateFileProgress(
                        uploadedFile,
                        { progress },
                        isMultipleUpload,
                    );
                },
            });

            setIsUploading((prev) => prev.filter((item) => item !== uploadedFile.name));
            const urlFile = isMultipleUpload
                ? response.data.attachmentUrl
                : response.data.imageUrl;
            updateFileUrl(uploadedFile, urlFile, isMultipleUpload);
        } catch (err) {
            const error = uploadedFile;
            error.error = true;
            setIsUploading((prev) => prev.filter((item) => item !== uploadedFile.name));
            if (isMultipleUpload) {
                setUploadedMultipleFiles([error]);
            }
        }
    };

    const handleUploadMultiple = (files) => {
        const newUploadedFiles = files.map((file) => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: fileSize(file.size),
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            url: null,
        }));

        setUploadedMultipleFiles(
            uploadedMultipleFiles.concat(newUploadedFiles),
        );
        newUploadedFiles.forEach((newUploadedFile) => processUpload(newUploadedFile, true));
    };

    return (
        <UploadMultipleContainer attachmentsPosition={uploadedMultipleFiles.length > 0}>
            { multiple && fileType === 'attachments' && (
                <Upload
                    onUpload={handleUploadMultiple}
                    multiple
                    accept={accept}
                    uploaded={!!uploadedMultipleFiles.length}
                    message={t('UPLOAD_COVER_PLACEHOLDER')}
                />
            )}
            {uploadedMultipleFiles.length > 0 && (
                <MultipleFileList
                    clearList={setUploadedMultipleFiles}
                    files={uploadedMultipleFiles}
                />
            )}
        </UploadMultipleContainer>
    );
};
