import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import './config/ReactotronConfig';

import { withTranslation } from 'react-i18next';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Routes from './routes';
import history from './services/history';

import { store } from './store';
import GlobalStyle from './styles/global';
import ChatEvents from './components/Chat/events';
import { BreadcrumbsProvider } from './hooks/useBreadcrumbs';
import RehydrateConnection from './components/RehydrateConnection';
import { SidebarProvider } from './hooks/useSidebar';

function GlobalSnackQueue() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    window.enqueueSnackbar = enqueueSnackbar;
    window.closeSnackbar = closeSnackbar;
    return null;
}

function App() {
    return (
        <Provider store={store}>
            <SnackbarProvider maxSnack={5}>
                <BreadcrumbsProvider>
                    <Router history={history}>
                        <SidebarProvider>
                            <RehydrateConnection />
                            <ChatEvents />
                            <Routes />
                            <GlobalStyle />
                            <GlobalSnackQueue />
                        </SidebarProvider>
                    </Router>
                </BreadcrumbsProvider>
            </SnackbarProvider>
        </Provider>
    );
}

export default withTranslation()(App);
