const { REACT_APP_BASE_API, REACT_APP_BASE_WS, REACT_APP_GAMES_API, REACT_APP_ENABLE_LIVE_CREATION } = process.env;

export const urls = {
    BASE_API: REACT_APP_BASE_API,
    BASE_WS: REACT_APP_BASE_WS,
    REACT_GAMES_API: REACT_APP_GAMES_API,
};

export const CHAT_TABS = {
    GENERAL: 0,
    QUESTIONS: 1,
    INSTRUCTORS: 2,
    TEAM: 3,
};

export const LIVE_STATUS = { on: 'on' };

export const TIMERS = {
    CHECK_ROOM_LIVE: 5000,
    CHECK_CHAT_STATUS: 5000,
    REFRESH_REPORTS: 10000,
    REFRESH_ENGAGEMENT: 10000,
    REFRESH_CHANNEL_ROOMS: 10000,
    REFRESH_HOME: 20000,
    SECOND: 1000,
    TIMEOUT: 60000,
    WATERMARK_CHECK: 5000,
    WATERMARK_TIMER_ON: 5000,
    WATERMARK_TIMER_OFF: 60000,
};

export const VIDEO_TYPES = Object.freeze({
    LIVE: 'LIVE',
    RECORDED: 'RECORDED',
});

export const VIDEO_TECHNOLOGY_TYPES = Object.freeze({ WEBRTC: 1 });

export const MAX_TEAM_PEOPLE = 50;

export const PROCESSING_USERS = Object.freeze({
    ERROR: 'error',
    DONE: 'done',
    WAITING: 'waiting',
});

export const ROOM_STATUS = Object.freeze({
    CONVERTING: 'converting',
    DONE: 'done',
    UPLOADED: 'uploaded',
    UPLOADING: 'uploading',

    PREPARING: 'preparing',
    CREATED: 'created',
    LIVE: 'live',
    READY: 'ready',
    OFF: 'off',
    GOING_OFF: 'going_off',

    CONVERTING_ERROR: 'error_converting',

    // extension
    UPLOAD_ERROR: 'upload_error',
});

export const QUIZ_TYPE = {
    QUIZ: 'quizz',
    SURVEY: 'research',
};

export const ITEMS_PER_PAGE_FRONTEND_PAGINATION = 10;

export const ENABLE_LIVE_CREATION = REACT_APP_ENABLE_LIVE_CREATION;
