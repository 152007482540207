import actions from '@utils/actions';

export function getCaptions(roomId, withLoading = false) {
    return {
        type: actions.GET_CAPTIONS,
        payload: { roomId, withLoading },
    };
}

export function setCaptions(captions) {
    return {
        type: actions.SET_CAPTIONS,
        payload: captions,
    };
}

export function removeCaption(roomId, captionId) {
    return {
        type: actions.REMOVE_CAPTION_REQUEST,
        payload: { roomId, captionId },
    };
}

export function removeCaptionSuccess(captionId) {
    return {
        type: actions.REMOVE_CAPTION_SUCCESS,
        payload: captionId,
    };
}

export function toggleAddCaptionUploadModal(isVisible) {
    return {
        type: actions.TOGGLE_ADD_CAPTION_UPLOAD_MODAL,
        payload: isVisible,
    };
}

export function editCaption(captionObject) {
    return {
        type: actions.EDIT_CAPTION,
        payload: captionObject,
    };
}
