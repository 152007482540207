import styled from 'styled-components';

export const ContainerPreview = styled.section`
    display: flex;
    justify-content: space-around;
    min-height: 120px;

    .log, .preview{
        width: 47%;
        background-color: #474343;
        height: 480px;

        .player {
            height: 78%;
            &.isBlank {
                background-color: #000;
            }
        }

        .header {
            background-color: #403e3f;
            justify-content: center;
            height: 55px;
            h4 {
                line-height: 3;
                margin: 0 5px;
            }

            p {
                line-height: 3;
            }
        }

        .circle {
            background-color: rgba(255, 255, 255, 0.39);
            margin-top: 15px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            position: relative;
        }

        .inner-circle {
            background-color: #fff;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            margin: -5px 0 0 -5px;
            position: absolute;
        }

        .event-circle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            z-index: 1;
        }

        .log-content {
            overflow-y: auto;
            height: 85%;
            color: white;
            font-size: 14px;
            position: relative;
            
            
        }
    }

    .goLiveButton {       
        display:flex;
        justify-content:center;
        width: 191px;
        height: 40px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.16px;
        color: white;
        line-height: 3;
        letter-spacing: 0.21px;

        &:enabled {
            border: solid 1px #ff468a;
            color:#fff;
            transition: all .2s ease-in-out;
            background-image: linear-gradient(to left, #ffae45, #ff428d 100%);
        }

        &:hover {
            transform: scale(1.1);
        }  
    }

    .goLiveButtonDisabled {
        opacity: 0.5;
    }

    .submitButton{
    margin: 30px auto;
    display:flex;
    justify-content:center;
    width: 191px;
    height: 40px;
    border: 0;
    color:#fff;
    background: linear-gradient(90deg, rgba(255,66, 141,1) 0%, rgba(255,174,70,1) 100%);
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: 0.21px;

    &:hover {
      background: linear-gradient(90deg, rgba(255,66,141,0.8) 0%, rgba(255,174,70,0.8) 100%);
    }
  }

  .modal-confirm {
    text-align: center;
    p {
        font-size: clamp(13px, 0.2em + 1vw, 1.2em);
        color:#fff;
        font-weight: bold;
    }

    .confirm-buttons {      
        display: flex;
        flex-direction: column;
        margin: 20px 5px;

     button {       
        display:flex;
        justify-content:center;        
        height: 40px;
        border: 0;
        flex: 1;
        color:#fff;
        background: linear-gradient(90deg, rgba(255,66, 141,1) 0%, rgba(255,174,70,1) 100%);
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 3;
        letter-spacing: 0.21px;
        border-radius: 8px;
        text-transform: uppercase;
        transition: all 0.2s ease-in-out;

        &.cancel {
            background: transparent;
            width: 387px;                        
            border: solid 1px #ff5b7d;
            margin-top: 15px;
        }

        &:hover {
            box-shadow: 0px 0px 10px -4px #000000;
            transform: scale(1.03);
        }            
    }
  }
  }
    
    .loading {
        z-index: 0;
        background: transparent
    }

    .--video-controls {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        flex-direction: column;

        .chat-enabled {
            background-color: #212436;
            margin-top: 10px;
            width: 100%;
            display: flex;
            padding: 10px;
            color: #fff;
            border-radius: 8px;
            justify-content: center;
            span {
                margin-right: 10px;
            }
        }
    
    }

    .--warn-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;

        h5 {
            font-size: 17px;
            color: #fff;
            text-align: justify;
        }

        button {
            border: none;
            color: #FFF;
            background: linear-gradient(90deg,#FFAC47 0%, #FF438D 100%);
            width: 300px;
            height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-weight: 600;
            border-radius: 8px;  
            font-weight: bold;
            text-transform: uppercase; 
            opacity: 0.8;
            transition: all 0.1s ease-in-out;

            &:hover {
                box-shadow: 0px 0px 10px -4px #000000;
                opacity: 1;
                transform: scale(1.02);
            }
        }

    }
`;

export const ContainerLogs = styled.div`
    position: relative;
    
    p {
      line-height: 1.5;
    }

    img {
        margin: 10px;
    }

    .flow {
        font-size: 18px;
        font-weight: 600;
        width: 75%;
        span {
            color: ${props => props.severity};
        }
    }

    .flow-date {
        margin: 5px 0;
    }

    .subevent-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 10px 5px;
        background-color: ${props => props.severity};
    }


    div {
        display: flex;
    }

    .vertical-line {
        border-left: 1px solid #565454;
        position: absolute;
        top: 0;
        left: 9px;
        height: 100%;
        z-index: 0;
    }

    .subevent {
        padding: 0 3%;

        p {
            line-height: 2;
            word-break: break-word;
            width: 100%;
        }
    }
`;

export const Circle = styled.picture`
    width: 10px;
    height: 10px;
    margin: 8px 5px;
    border-radius: 50%;
    background-color: ${props => props.severity};
    z-index: 1;
`;
