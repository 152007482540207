import React, { useState, useEffect, useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    DropdownContainer,
    DropdownButtonWrapper,
    DropdownList,
    LanguageSectionContainer,
} from './styles';
import { changeUserLanguage } from '../../store/modules/auth/actions';
import arrowDownIcon from '../../assets/arrow-down-icon.svg';
import i18n from '../../assets/i18n/i18n';
import useAuth from '../../hooks/useAuth';

function SelectLanguage({ position }) {
    const { t } = useTranslation();
    const { user } = useAuth();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [currentLang, setCurrentLang] = useState('PT');
    const languagesDescription = [
        `PT-BR - ${t('PORTUGUESE')}`,
        `EN-US - ${t('ENGLISH')}`,
        `ES-ES - ${t('SPANISH')}`,
    ];
    const languages = ['pt-BR', 'en-US', 'es-ES'];

    const toggle = useCallback(() => {
        setOpen(!open);
    }, [open]);

    useEffect(() => {
        if (user && user.language) {
            setCurrentLang(user.language.substring(0, 2).toUpperCase());
            i18n.changeLanguage(user.language);
        }
    }, [user]);

    const languageHandler = useCallback((_, index) => {
        setCurrentLang(languages[index].substring(0, 2).toUpperCase());
        dispatch(changeUserLanguage(languages[index]));
        i18n.changeLanguage(languages[index]);
        toggle();
    }, [dispatch, languages, toggle]);

    return (
        <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
            <DropdownContainer>
                <DropdownButtonWrapper
                    tabIndex={0}
                    role="button"
                    onKeyPress={toggle}
                    onClick={toggle}
                >
                    <div className="dropdown-component">
                        <LanguageSectionContainer>
                            <p>{t('LANGUAGE')}:</p>
                            <p className="emphasis">{currentLang}</p>
                            <img
                                src={arrowDownIcon}
                                alt="Mostrar Mais"
                                className={`${open ? '--open' : undefined}`}
                            />
                        </LanguageSectionContainer>
                    </div>
                </DropdownButtonWrapper>
                <DropdownList
                    className={position === '' ? 'top right' : position}
                >
                    {open
                        && languagesDescription.map((item, index) => (
                            <li
                                key={item}
                                onClick={() => languageHandler(item, index)}
                            >
                                {item}
                            </li>
                        ))}
                </DropdownList>
            </DropdownContainer>
        </OutsideClickHandler>
    );
}

export default SelectLanguage;
