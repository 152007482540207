import { urls } from '../../utils/constants';
import api from '../api';
import history from '../history';

function addListener(vec, func) {
    const length = vec.push(func);
    return () => {
        vec.splice(length - 1, 1);
    };
}

const maxRetries = 6;

class _ChatSocket {
    constructor() {
        this._token = null;
        this._session = null;
        this._promises = {};
        this._messageListeners = [];
        this._disconnectListeners = [];
        this._closeListeners = [];
        this._connectedListeners = [];
        this._timesRetriedConnection = 0;
    }

    addMessageListener = (func) => addListener(this._messageListeners, func);

    addDisconnectListener = (func) => addListener(this._disconnectListeners, func);

    addConnectedListener = (func) => addListener(this._connectedListeners, func);

    addCloseListener = (func) => addListener(this._closeListeners, func);

    _messageHandler = ({ data }) => {
        const message = JSON.parse(data);
        if (message.error_code === 'unauthorized') {
            this._socket.onclose = this._closeConnection;
        }
        this._messageListeners.forEach(l => l(message));

        return null;
    }

    _errorHandler = () => {
        console.log('websocket error');
    }

    _connected = () => {
        console.log('chat connected');
        this._timesRetriedConnection = 0;
        this._socket.onclose = this._disconnected;

        const split = history.location.pathname.split('/');
        const base = split[1];
        const roomName = split[2];
        if (base === 'course' && roomName) {
            api.enterChat(roomName);
        }
        if (this._promises.connect) {
            this._promises.connect.resolve();
            delete this._promises.connect;
        }
        this._connectedListeners.forEach(l => l());
    }

    _closeConnection = (fromLogout = false) => {
        this._closeListeners.forEach(l => l(fromLogout));
        if (this._promises.disconnect) {
            this._promises.disconnect.resolve();
            delete this._promises.disconnect;
        }
    }

    _disconnected = () => {
        console.log('disconnected');
        this._disconnectListeners.forEach(l => l());
        setTimeout(() => this.connect(this._token, this._session), 1000 * (2 ** this._timesRetriedConnection));
        this._timesRetriedConnection += 1;
    }

    connect = (token, session) => {
        if (!token) {
            return new Promise(resolve => resolve());
        }
        this._token = token;
        this._session = session;

        this._socket = new WebSocket(`${urls.BASE_WS}=${token}&session=${session}`);

        if (this._timesRetriedConnection < maxRetries) {
            this._socket.onclose = this._disconnected;
        } else {
            this._socket.onclose = this._closeConnection;
        }

        this._socket.onmessage = this._messageHandler;
        this._socket.onerror = this._errorHandler;
        this._socket.onopen = this._connected;

        return new Promise((resolve, reject) => {
            this._promises.connect = { resolve, reject };
        });
    }

    disconnect = (fromLogout = false) => {
        console.log('disconnecting');
        return new Promise((resolve, reject) => {
            const isClosable = this._socket && (
                this._socket.readyState !== this._socket.CLOSED
            );

            if (isClosable) {
                this._socket.onclose = () => this._closeConnection(fromLogout);
                this._promises.disconnect = { resolve, reject };
                this._socket.close();
                this._socket = null;
            } else {
                resolve();
            }
        });
    }
}

const ChatSocket = new _ChatSocket();

export default ChatSocket;
