import styled from 'styled-components';

export const ContainerEngagement = styled.section`
    display: flex;
    flex-wrap: wrap;
    margin: 3%;
    margin-top: 1.5%;

    .engagement-card {
        display: flex;
        flex-direction: column;
        background-color: #212436;
        flex-grow: 1;
        flex-basis: 22%;
        color: white;
        margin: 10px;
        position: relative;
        justify-content: center;
        align-items: center;

        .engagement-card-tooltip-area {
            position: relative;
            width: 100%;
            height: 2rem;
            display: flex;
            justify-content: flex-end;
        }

        .engagement-card-info {
            padding: 1.2rem 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
        }
    }

    h4 {
        font-size: 24px;
        display: contents;
        margin-left: 2%;
    }

    p {
        font-size: 18px;
        display: contents;
        margin-left: 2%;
        font-weight: 600;
    }

    .users-ratio {
        font-size: 12px;
        color: #eb7c6d;
    }

    .question {
        position: absolute;
        right: 5px;
        top: 5px;
    }

    .main-icon {
        border-radius: 30px;
        border: none;
        object-fit: none;
        width: 56px;
        height: 56px;
        background-image: linear-gradient(to left, #ffae46, #ff428d 100%);
        margin-right: 8%;
    }

    .loading {
        z-index: 0;
        background: transparent;
    }
`;
