import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { listTutorials } from '@src/store/modules/rooms/actions';
import { useBreadcrumbs } from '@hooks/useBreadcrumbs';
import RoomSliders from '@components/SliderTutorials';
import constants from '@src/assets/i18n/constants';
import useRoomsProgress from '@hooks/useRoomsProgress';
import { getWatchedVideosProgress as getVideosProgress } from '@src/store/modules/roomsProgress/actions';
import paths from '@utils/paths';
import useAuth from '@src/hooks/useAuth';
import OnboardingTutorials from '@src/components/Onbordings/onboardingTutorials';
import { Container, ContainerTutorials } from './styles';

function selector({ room }) {
    return { tutorials: room.tutorials };
}

const TutorialOrganizer = () => {
    const { setItems } = useBreadcrumbs();
    const { t } = useTranslation();
    const { videos: videosProgress } = useRoomsProgress();
    const dispatch = useDispatch();
    const { tutorials } = useSelector(selector, shallowEqual);
    const { user } = useAuth();

    tutorials.sort((a, b) => a.name.localeCompare(b.name));

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t(constants.ORGANIZER_DASHBOARD) },
            { to: paths.TUTORIAL, label: t(constants.TUTORIALS) },
        ];
        setItems(breadcrumbsItems);
    }, [setItems, t]);

    useEffect(() => {
        dispatch(listTutorials());
    }, [dispatch]);

    useEffect(() => {
        if (tutorials.length) {
            dispatch(getVideosProgress(tutorials));
        }
    }, [dispatch, tutorials]);

    return (
        <Container>
            {(user) && <OnboardingTutorials />}
            <ContainerTutorials>
                <RoomSliders videos={tutorials} progress={videosProgress} />
            </ContainerTutorials>
            <footer />
        </Container>
    );
};

export default TutorialOrganizer;
