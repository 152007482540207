import React from 'react';
import { Input, Label, Span } from './styles';

export default function ToogleSwitch({ isOn, handleToggle, disabled = false, style }) {
    return (
        <>
            <Input
                id="react-switch-new"
                type="checkbox"
                onChange={handleToggle}
                checked={isOn}
                disabled={disabled}
                style={style}
            />
            <Label
                htmlFor="react-switch-new"
                isOn={isOn}

            >
                <Span isOn={isOn} />
            </Label>
        </>
    );
}
