/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VideoPlayer as TokyoPlayer } from 'react-tokyo';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch } from 'react-redux';
import Upload from '../../KopaUpload/Upload';
import CopyIcon from '../../../assets/copy.svg';
import { RecordedContainer, VideoPlayer, UrlContainer, UploadButton } from './styles';
import { setVideoUpload } from '../../../store/modules/rooms/actions';

const RecordedVideo = ({ playUrl, videoUrl, message, handleFileCallback }) => {
    const { t } = useTranslation();
    const [openTooltip, setOpenTooltip] = useState(false);
    const [uploadVideo, setUploadVideo] = useState();
    const dispatch = useDispatch();

    const primaryClickHandle = () => {
        navigator.clipboard.writeText('').then(
            setOpenTooltip(true),
        );
    };

    const handleUpload = ([file]) => {
        dispatch(setVideoUpload(file));
        setUploadVideo({ file, uploaded: true, name: file.name, preview: URL.createObjectURL(file) });
    };

    const handleClear = () => {
        dispatch(setVideoUpload(null));
        setUploadVideo();
    };

    useEffect(() => {
        if (typeof handleFileCallback === 'function') {
            handleFileCallback(uploadVideo);
        }
    }, [uploadVideo, handleFileCallback]);

    const warnUser = useCallback((e) => {
        if (uploadVideo && uploadVideo?.uploaded) {
            e.preventDefault();
            e.returnValue = '';
        }
    }, [uploadVideo]);

    useEffect(() => {
        window.addEventListener('beforeunload', warnUser);
        return () => window.removeEventListener('beforeunload', warnUser);
    }, [uploadVideo, warnUser]);

    return (
        <RecordedContainer>
            <h4>{t('CHANNEL', { context: 'UPLOADVIDEO' })}</h4>
            {videoUrl
                ? (
                    <VideoPlayer className="video-player">
                        <TokyoPlayer decodeUrl={videoUrl} quizComponent={() => { }} />
                    </VideoPlayer>
                )
                : (
                    <div className="upload">
                        { (!uploadVideo) && <Upload onUpload={handleUpload} accept="video/*" message={message} />}
                        { (uploadVideo) && (
                            <video id="teste" width="270" height="300" controls>
                                <source src={uploadVideo.preview} type="video/mp4" />
                            </video>
                        )}
                        {uploadVideo
                            && (
                                <UploadButton
                                    disabled={false}
                                    type="submit"
                                    onClick={handleClear}
                                >
                                    {t('CHANNEL', { context: 'CLEANVIDEO' })}
                                </UploadButton>
                            )}
                    </div>
                )}
            {playUrl
                && (
                    <UrlContainer>
                        <h3>{t('CHANNEL', { context: 'PRIMARYURL' })}: </h3>
                        <span />
                        <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                            <Tooltip
                                id="tooltip"
                                PopperProps={{ disablePortal: true }}
                                onClose={() => setOpenTooltip(false)}
                                open={openTooltip}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={t('CHANNEL', { context: 'COPIED' })}
                            >
                                <a href="#/"><img onClick={primaryClickHandle} alt="Copy Icon" className="copy-icon" src={CopyIcon} /> </a>
                            </Tooltip>
                        </ClickAwayListener>
                    </UrlContainer>
                )}
        </RecordedContainer>
    );
};

export default RecordedVideo;
