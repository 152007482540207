import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthLayout from '../pages/_layouts/auth';
import DefaultLayout from '../pages/_layouts/default';
import Loading from '../components/Loading';
import useAuth from '../hooks/useAuth';

export default function RouteWrapper({ component: Component, ...rest }) {
    const [isOrganizer, setIsOrganizer] = useState(false);

    const { user } = useAuth();

    const isOrg = user?.organizer;
    const Layout = isOrganizer ? DefaultLayout : AuthLayout;

    useEffect(() => {
        setIsOrganizer(isOrg);
    }, [isOrg]);

    return (
        <Route
            {...rest}
            render={props => (
                <Layout>
                    {isOrganizer ? <Component {...props} /> : <Loading />}
                </Layout>
            )}
        />
    );
}

RouteWrapper.propTypes = {
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
        .isRequired,
};

RouteWrapper.defaultProps = {};
