import actions from '../../../utils/actions';

const initialState = {
    users: [],
    loading: true,
    loaderInButton: false,
    LastEvaluatedKey: '',
    csvList: [],
    csvListLastKey: null,
    detailImportList: [],
    detailImportListLoading: false,
    detailImportListLastKey: null,
    nextPage: null,
    nextPageLoading: false,
    isLastPage: false,
};

export default function userReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.SET_LOADING:
            return { ...state, loading: payload };
        case actions.CLEAR_USERS:
            return { ...state, users: [] };
        case actions.LAST_EVALUATE_KEY:
            return { ...state, LastEvaluatedKey: payload };
        case actions.GET_USERS_NEXT_PAGE_REQUEST:
            return {
                ...state,
                nextPageLoading: true,
            };
        case actions.GET_USERS_REQUEST:
            return { ...state, loading: !payload.disableLoading };
        case actions.EDIT_USER_REQUEST: {
            const { user } = payload;

            const updatedUserList = state.users.map((usr) => {
                if (usr?.username === user?.username) {
                    usr = user;
                    return usr;
                }
                return usr;
            });

            return { ...state, users: updatedUserList };
        }
        case actions.USERS_SUCCESS: {
            return {
                ...state,
                users: payload.users.users,
                nextPage: payload.users.next_page,
                activePage: payload.users,
                LastEvaluatedKey: payload.LastEvaluatedKey,
                loading: false,
                nextPageLoading: false,
                isLastPage: payload.isLastPage,
            };
        }
        case actions.USERS_PAGINATED_SUCCESS:
            return {
                ...state,
                users: [...state.users, ...payload.users.users],
                nextPage: payload.users.next_page,
                activePage: payload.users,
                LastEvaluatedKey: payload.LastEvaluatedKey,
                loading: false,
                nextPageLoading: false,
                isLastPage: payload.isLastPage,
            };
        case actions.SET_LOADER_STATE:
            return { ...state, loaderInButton: payload };
        case actions.SET_LOADING_NEXT_PAGE:
            return { ...state, nextPageLoading: true };
        case actions.SET_LOADING_NEXT_PAGE_FAIL:
            return { ...state, nextPageLoading: false, loading: false };
        case actions.GET_CSV_LIST_REQUEST:
            return {
                ...state,
                loading: payload.withLoading ? true : state.loading,
            };
        case actions.GET_CSV_LIST_SUCCESS:
            return {
                ...state,
                loading: payload.withLoading ? false : state.loading,
                csvList: [...state.csvList, ...payload.items],
                csvListLastKey: payload.lastKey,
            };
        case actions.GET_CSV_LIST_FAILURE:
            return { ...state, loading: false, csvList: [] };

        case actions.DETAIL_IMPORT_LIST_REQUEST:
            return { ...state, detailImportListLoading: true };
        case actions.DETAIL_IMPORT_LIST_SUCCESS:
            return {
                ...state,
                detailImportListLoading: false,
                detailImportList: payload.items,
                detailImportListLastKey: payload.lastKey,
            };
        case actions.DETAIL_IMPORT_LIST_FAILURE:
            return { ...state, detailImportListLoading: false };
        default:
            return state;
    }
}
