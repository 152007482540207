import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex-direction:column;
  padding: 0 20px;  
  flex-flow: column nowrap;
  /* overflow: auto; */
  position: relative;
  /* z-index: 1; */
  /* :after {
    content:'';
    position: absolute;
    top: 34px;
    left: 20px;
    right: 0;
    height: 20px;
    width: 96%;
    background: linear-gradient(180deg, rgb(40, 43, 58) 50%, rgba(0,0,0,0) 100%);
  } */

  

  span{
    margin-left:5px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.21px;
    text-align: center;
    color: #ffffff;
  }

  .channel-title { 
    padding: 40px 0 10px 10px;
    font-size: 28px;
    font-weight: 900;
    color: white;
  }

  .channel-header {
    left: 100px;
    right: 23px;
    z-index: 4;
    top: 102px;
    background: #282b3a;
    height: 47px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;

    h2 {
      font-size: clamp(20px, 0.4em + 1.2vw, 1.6em);
      color:white;
      width: 45rem;
      padding: 8px;
    }


   > button {
    border-width:0px;
    display:flex;
    align-self:flex-end;
    height: 47px;    
    min-width: 186px;
    border-radius: 12px;
    background-color: #212032;
    color: #ffffff;
    font-weight: bold;
    font-size: clamp(12px, 0.4em + 1vw, 1.5em);
    align-items: center;
    padding: 0 8px 0 34px;
    text-transform: uppercase;
    transition: all 0.1s ease-in-out;
    position: relative;
    img {
        width: 30px;
    }

    small {        
        position: absolute;
        margin-left: -15px;        
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        width: 34px;
        left: 0;
        top: 7px;
        border-radius: 7px;
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
    }

    &:hover {
        box-shadow: 0px 0px 10px -4px #000000;
        transform: scale(1.02);
    }
  }
  }

  .channel-header {
    .dropdown {
    min-width: 260px;
    left: -250px;
    top: 40px;
    color: white;
    text-align: center;

    .checked {
      display: none;
    }
  }


  }

  @media (max-width: 800px) {
        .channel-header {        
            left: 20px;       
            top: 85px;
        }
  }

  @media(max-width:680px) {
    .channel-header {      
      h2 {
        display:none;
      }

      button {
        margin: auto;
        position: relative;
      }
    }

    .channel-title {
      text-align: center;
    }
  }
`;

export const CardList = styled.div`
  display: block;
  max-height: calc(100vh - 164px);
  flex-flow: row wrap;
  margin-top: 30px;

  @media(max-width:680px) {
    margin: 20px auto 0 auto;
  }
`;

export const StreamCard = styled.div`
  position:relative;
  display:flex;
  flex-direction:column;
  margin: 5px;
  width: 23%;
  border-radius: 8px;
  background-color: #555151;
  padding: 8px;
  
  h1{
    height: 28px;
    font-size: 21px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.24px;
    color: #ffffff;
  }


  strong{
    height: 28px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.16px;
    color: #ffffff
  }

  .thumb{
    margin-top:10px;
    width:100%;
    height:144px;
  }
  .icon{
    position:absolute;
    right: 10px;
    top: 10px;
  }

`;
