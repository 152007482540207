import actions from '../../../utils/actions';

export function getBlockedUsers(roomId, keyword, pageSize, LastEvaluatedKey) {
    return {
        type: actions.BLOCK_LIST_REQUEST,
        payload: {
            roomId,
            keyword: keyword === '' ? null : keyword.toLowerCase(),
            pageSize,
            LastEvaluatedKey,
        },
    };
}

export function getBlockedUsersNextPage(roomId, pageSize) {
    return {
        type: actions.BLOCK_LIST_NEXT_PAGE_REQUEST,
        payload: {
            roomId,
            pageSize,
        },
    };
}

export function getBlockedUsersPaginatedSuccess(blockedUsers, isLastPage) {
    return {
        type: actions.BLOCK_LIST_NEXT_PAGE_REQUEST_SUCCESS,
        payload: {
            blockedUsers,
            isLastPage,
        },
    };
}

export function getBlockedUsersSuccess(blockedUsers, isLastPage) {
    return {
        type: actions.BLOCK_LIST_SUCCESS,
        payload: {
            blockedUsers,
            isLastPage,
        },
    };
}

export function getBlockedUsersFailed() {
    return { type: actions.BLOCK_LIST_FAILURE };
}

export function unblockUser(roomId, status) {
    return { type: actions.UNBLOCK_USER, payload: { roomId, status } };
}
