import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash';
import languageConstants from '@langs/constants';
import fileSize from 'filesize';
import alertIco from '@src/assets/alert-white.svg';
import DeleteIcon from '@src/assets/delete.svg';
import MultipleFileList from '@components/MultipleFileList';
import { Upload } from '..';
import { ClearButton, FileFormatError, UploadContainer } from '../styles';

export const KopaCsvUpload = ({
    fileType,
    uploadedFile,
    setUploadedFile,
    accept,
}) => {
    const { t } = useTranslation();
    const [wasFileRejected, setWasFileRejected] = useState(false);

    function handleAddFile([file]) {
        const newUploadedFiles = {
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: fileSize(file.size),
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            url: null,
        };
        setUploadedFile(newUploadedFiles);
    }

    function getIsFileRejected(rejections) {
        if (rejections.length > 0) {
            setWasFileRejected(true);
        }
    }

    return (
        (
            <UploadContainer>
                { fileType && (
                    <>
                        {!uploadedFile.id && (
                            <Upload
                                multiple
                                onUpload={handleAddFile}
                                accept={accept}
                                message={t('DRAG_AND_DROP_FILES')}
                                getIsFileRejected={getIsFileRejected}
                            />
                        )}
                        {(uploadedFile.id) && (
                            <MultipleFileList clearList={setUploadedFile} files={[uploadedFile]} />
                        )}
                        {uploadedFile.id && <ClearButton type="button" onClick={() => setUploadedFile({})}><img src={DeleteIcon} alt="clean" /></ClearButton>}

                        {
                            wasFileRejected && (
                                <FileFormatError>
                                    <img src={alertIco} alt="alert" />
                                    <p>{t(languageConstants.XLSX_FORMAT_ERROR)}</p>
                                </FileFormatError>
                            )
                        }
                    </>
                ) }
            </UploadContainer>
        )
    );
};
