import actions from '../../../utils/actions';

export function getQuizzes(roomId) {
    return {
        type: actions.QUIZZES_REQUEST,
        payload: { roomId },
    };
}

export function getQuizzesSuccess(payload) {
    return {
        type: actions.QUIZZES_SUCCESS,
        payload,
    };
}

export function getQuizzNextPage(){
    return {
        type: actions.GET_QUIZZES_NEXT_PAGE,
    }
}
