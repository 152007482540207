import React, { useState, createContext, useContext } from 'react';

export const SidebarContext = createContext({});

export function SidebarProvider({ children }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [items, setItems] = useState([]);

    return (
        <SidebarContext.Provider value={{ isMenuOpen, setIsMenuOpen, items, setItems }}>
            {children}
        </SidebarContext.Provider>
    );
}
export function useSidebar() {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error('useSidebar must be used within a sidebar provider');
    }
    const { isMenuOpen, setIsMenuOpen, items, setItems } = context;
    return { isMenuOpen, setIsMenuOpen, items, setItems };
}
