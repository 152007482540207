import { useSelector, shallowEqual } from 'react-redux';

function usersSelector({ users }) {
    return {
        users: users.users,
        loading: users.loading,
        csvList: users.csvList,
        csvListLastKey: users.csvListLastKey,
        detailList: users.detailImportList,
        detailIsLoading: users.detailImportListLoading,
        detailLastKey: users.detailImportListLastKey,
        nextPage: users.nextPage,
        prevPage: users.prevPage,
        loaderInButton: users.loaderInButton,
        nextPageLoading: users.nextPageLoading,
        isLastPage: users.isLastPage,
    };
}

export default function useUsers() {
    const {
        users,
        loading,
        csvList,
        csvListLastKey,
        detailList,
        detailIsLoading,
        detailLastKey,
        nextPage,
        prevPage,
        loaderInButton,
        nextPageLoading,
        isLastPage,
    } = useSelector(usersSelector, shallowEqual);
    return {
        users,
        loading,
        csvList,
        csvListLastKey,
        detailList,
        detailIsLoading,
        detailLastKey,
        nextPage,
        prevPage,
        loaderInButton,
        nextPageLoading,
        isLastPage,
    };
}
