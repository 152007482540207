import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAuth from '@hooks/useAuth';
import { changeOnBoardingStatus, setOnBoarding } from '@src/store/modules/auth/actions';
import langs from '@src/assets/i18n/constants';
import { Onboarding } from './styles';

export default function OnboardingGamification() {
    const [isTourOpen, setTourOpen] = useState(false);
    const { onBoarding } = useAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeOnBoardingStatus());
    }, [dispatch]);

    useEffect(() => {
        if (onBoarding?.data) {
            if (!(onBoarding.data.onBoardingOrganizerGamification) && !(onBoarding.skipped)) {
                setTourOpen(true);
            }
        }
    }, [dispatch, onBoarding]);

    useLayoutEffect(() => {
        if (isTourOpen) {
            const originalStyle = window.getComputedStyle(document.body).overflow;
            document.body.style.overflow = 'hidden';
            return () => {
                document.body.style.overflow = originalStyle;
            };
        }
        return undefined;
    }, [isTourOpen]);

    function handleCloseOnboarding(key, skipped, global_skip) {
        dispatch(setOnBoarding(key, skipped, global_skip));
        setTourOpen(false);
    }

    const steps = {
        steps: [
            {
                level: 'all',
                selector: '',
                content: ({ goTo }) => (
                    <>
                        <h2>{t(langs.ONBOARDING_GAMIFICATION_AREA_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_GAMIFICATION_AREA)}</p>
                        <div className="buttons">
                            <button
                                type="button"
                                className="btn continue-btn"
                                onClick={() => goTo(1)}
                            >
                                {t(langs.ONBOARDING_LETS_START)}
                            </button>
                            <button
                                type="button"
                                className="btn ignore-btn"
                                onClick={() => handleCloseOnboarding('onBoardingOrganizerGamification', true)}
                            >
                                {t(langs.ONBOARDING_SKIP_TOUR)}
                            </button>
                        </div>
                    </>
                ),
                position: 'center',
            },
            {
                level: 'all',
                selector: '[data-tour="tour__search"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_GAMIFICATION_SEARCH_AREA_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_GAMIFICATION_SEARCH_AREA)}</p>
                    </>
                ),
            },
            {
                level: 'all',
                selector: '[data-tour="tour__filter"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_GAMIFICATION_FILTER_AREA)}</h2>
                        <p>{t(langs.ONBOARDING_GAMIFICATION_FILTER)}</p>
                    </>
                ),
            },
            {
                level: 'all',
                selector: '[data-tour="tour__settings"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_GAMIFICATION_SETTINGS_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_GAMIFICATION_SETTINGS)}</p>
                    </>
                ),
            },
            {
                level: 'all',
                selector: '[data-tour="tour__more_options"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_GAMIFICATION_MORE_OPTIONS_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_GAMIFICATION_MORE_OPTIONS)}</p>
                    </>
                ),
            },
            {
                level: 'all',
                selector: '[data-tour="tour__container"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_GAMIFICATION_CONTAINER_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_GAMIFICATION_CONTAINER)}</p>
                        <button
                            type="button"
                            className="btn complete-btn"
                            onClick={() => handleCloseOnboarding('onBoardingOrganizerGamification')}
                        >
                            {t(langs.ONBOARDING_COMPLETE)}
                        </button>
                    </>
                ),
            },
        ],
    };

    return (
        <Onboarding
            steps={(steps.steps)}
            isOpen={(isTourOpen)}
            maskClassName="onboardingOverlay"
            accentColor="#ff438d"
            maskSpace={6}
            disableInteraction
            closeWithMask={false}
            rounded={4}
            onRequestClose={() => handleCloseOnboarding('onBoardingOrganizerGamification', true)}
            closeButtonAriaLabel={t(langs.ONBOARDING_COMPLETE)}

        />
    );
}
