import { useSelector, shallowEqual } from 'react-redux';

function quizSelector({ quizzes }) {
    return {
        questions: quizzes.questions,
        loading: quizzes.loading,
        page: quizzes.page,
        nextPageLoading: quizzes.nextPageLoading,
        paginatedQuestions: quizzes.paginatedQuestions,
        isLastPage: quizzes.isLastPage,
    };
}

export default function useQuizzes() {
    const {
        questions, loading, page, paginatedQuestions, nextPageLoading, isLastPage,
    } = useSelector(quizSelector, shallowEqual);
    return {
        questions, loading, page, paginatedQuestions, nextPageLoading, isLastPage,
    };
}
