import { all, put, takeLatest } from 'redux-saga/effects';
import Notify from '@src/utils/notification';
import actions from '@utils/actions';
import api from '../../../services/api';
import { defaultErrorHandler } from '../../utilities';
import * as gamificationActions from './actions';
import { translate } from '../../../utils/functions';

function* getRanking({ payload }) {
    try {
        yield put(gamificationActions.setRankingLoading(true));
        const rank = yield api.getRanking(
            payload.courseId,
            payload.pagesize,
            payload.page,
            payload.ordination,
            payload.sortBy,
            encodeURIComponent(payload.keyword),
        );

        let isLastPage = false;
        if (rank.users.length < payload.pagesize) {
            isLastPage = true;
        }
        yield put(gamificationActions.setRanking(rank, isLastPage));
    } catch (error) {
        yield put(gamificationActions.getRankingFail());
        defaultErrorHandler(error, { default: translate('FAIL_GET_USERS') });
    }
}

function* getRankingNextPage({ payload }) {
    try {
        const rank = yield api.getRanking(
            payload.courseId,
            payload.pagesize,
            payload.page,
            payload.ordination,
            payload.sortBy,
            encodeURIComponent(payload.keyword),
        );

        let isLastPage = false;
        if (rank.users.length < payload.pagesize) {
            isLastPage = true;
        }
        yield put(
            gamificationActions.setRankingPaginated(
                rank,
                isLastPage,
            ),
        );
    } catch (error) {
        yield put(gamificationActions.getRankingFail());
        defaultErrorHandler(error, { default: translate('FAIL_GET_USERS') });
    }
}

function* gamificationRulesRequest({ payload }) {
    try {
        const res = yield api.gamificationRulesRequest(payload);
        yield put({ type: actions.FETCH_GAMIFICATION_SUCCESS, payload: res });
    } catch (error) {
        yield put({ type: actions.FETCH_GAMIFICATION_FAIL });
        defaultErrorHandler(error, { default: translate('GAMIFICATION_RULES_REQUEST_FAIL') });
    }
}

function* toggleGamificationRequest({ payload }) {
    const { courseId, enabled } = payload;
    try {
        yield api.toggleGamificationRequest(courseId, enabled);
        yield put({ type: actions.TOGGLE_GAMIFICATION_SUCCESS });

        if (enabled) {
            Notify.success(translate('TOGGLE_GAMIFICATION_SUCCESS_ENABLED'));
        } else {
            Notify.success(translate('TOGGLE_GAMIFICATION_SUCCESS_DISABLED'));
        }
    } catch (error) {
        yield put({ type: actions.TOGGLE_GAMIFICATION_FAIL });
        defaultErrorHandler(error, { default: translate('TOGGLE_GAMIFICATION_FAIL') });
    }
}

function* saveGamificationRules({ payload }) {
    const { courseId, gamificationRules } = payload;

    try {
        yield api.saveGamificationRequest(courseId, gamificationRules);
        yield put({ type: actions.SAVE_GAMIFICATION_RULES_SUCCESS });
        Notify.success(translate('SAVE_GAMIFICATION_RULES_SUCCESS'));
    } catch (error) {
        yield put({ type: actions.SAVE_GAMIFICATION_RULES_FAIL });
        defaultErrorHandler(error, { default: translate('SAVE_GAMIFICATION_RULES_FAIL') });
    }
}

export default all([
    takeLatest(actions.GET_RANKING, getRanking),
    takeLatest(actions.GET_RANKING_NEXT_PAGE, getRankingNextPage),
    takeLatest(actions.FETCH_GAMIFICATION_RULES, gamificationRulesRequest),
    takeLatest(actions.TOGGLE_GAMIFICATION_REQUEST, toggleGamificationRequest),
    takeLatest(actions.SAVE_GAMIFICATION_RULES, saveGamificationRules),

]);
