import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { KopaCsvUpload } from '@components/KopaUpload/CsvUpload';
import api from '@services/api';
import useRooms from '@hooks/useRooms';
import Notify from '@utils/notification';
import sampleIcon from '@src/assets/file.svg';
import languageConstants from '@langs/constants';
import {
    Container,
    DownloadSample,
    ImportButton,
    TipMessage,
    ImportCSVHeader,
} from './styles';
import { downloadXlsx } from '../utils/functions';

export default function UploadCSVModal({ fetchList, handleClose }) {
    const [uploadedFile, setUploadedFile] = useState({});
    const { selectedChannel } = useRooms();
    const [isUploading, setIsUploading] = useState(false);
    const { t } = useTranslation();

    async function handleDownloadSample() {
        await downloadXlsx('import_sample.xlsx', t(languageConstants.XLSX_SAMPLE_FAIL));
    }

    async function handleUpload() {
        const data = new FormData();
        const baseUrl = '/users/organizer/imports';

        try {
            setIsUploading(true);
            const response = await api._api.post(baseUrl, null, { params: { courseId: selectedChannel.id, fileName: uploadedFile.name } });
            const { fields } = response.data.uploadObject;
            Object.keys(fields).forEach(key => {
                data.append(key, fields[key]);
            });
            data.append('file', uploadedFile.file);

            await axios.post(`${response.data.uploadObject.url}`, data,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: e => {
                        const progress = Number(Math.round((e.loaded * 100) / e.total));
                        if (progress >= 100) {
                            fetchList(false);
                            Notify.success(t('UPLOADING'));
                        }
                    },
                });
            setIsUploading(false);
            handleClose();
        } catch (err) {
            setIsUploading(false);
            const error = uploadedFile;
            error.error = true;
            setUploadedFile([error]);
        }
    }

    return (
        <Container>
            <TipMessage>
                <p>
                    {t('IMPORT_LIST_TIP').toUpperCase()} {' '}
                    NAME_CHAT, FULL_NAME, E-MAIL, GENDER (F - <strong>{t('FEMALE').toUpperCase()}</strong>,
                    M - <strong>{t('MALE').toUpperCase()}</strong> {t('OR').toUpperCase()} O - <strong>{t('OTHERS').toUpperCase()}</strong>),
                    DATE_OF_BIRTH, PHONE {t('AND')} PERMISSION (<strong>ORGANIZER, MONITOR, USER</strong>).
                </p>
            </TipMessage>
            <DownloadSample onClick={handleDownloadSample}>
                <img className="icon-xlsx" src={sampleIcon} alt="download sample" />
                {t(languageConstants.DOWNLOAD_SPREADSHEET_SAMPLE)}
            </DownloadSample>

            <ImportCSVHeader>
                <p>
                    {t(languageConstants.IMPORTXLSXHEADER)}
                </p>
            </ImportCSVHeader>
            <div className="upload-container">
                <KopaCsvUpload
                    fileType="xlsx"
                    uploadedFile={uploadedFile}
                    setUploadedFile={setUploadedFile}
                    multiple
                    accept=".xlsx"
                />
            </div>

            <section className="footer">
                <ImportButton disabled={!uploadedFile?.id || isUploading} type="button" onClick={handleUpload}>{t('EXECUTE_IMPORT')}</ImportButton>
            </section>

        </Container>
    );
}
