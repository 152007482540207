import React from 'react';
import { CustomDropdown, SelectInputFieldContainer } from './styles';

export default function SelectInputField({
    labelTitle = '',
    helpText = '',
    placeHolder = '',
    options = [{ label: '', value: '' }],
    onChange = () => {},
    color = null,
    placeHolderColor = null,
    disabled = false,
    defaultLabelText = '',
}) {
    return (
        <SelectInputFieldContainer>
            <p>{labelTitle || defaultLabelText} <span>{helpText}</span></p>
            <CustomDropdown
                disabled={disabled}
                placeHolderColor={placeHolderColor}
                color={color}
                options={options}
                onChange={option => onChange(option.value)}
                placeholder={placeHolder}
            />
        </SelectInputFieldContainer>
    );
}
