import styled from 'styled-components';

export const CustomFormInputContainer = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    
    p {
        top: 0;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
        margin-left: 15px;
    }

    span {
        color: #ff9869;
        font-size: 8px;
    }

    
    input:not([type=time]) {
        width: 100%;
        height: 35px;
        background-color: #212032;
        border: 0;
        outline: 0;
        border-radius: 6px;
        padding: 12px;
        margin-top: 4px;

        font-size: 12px;
        font-weight: bold;
        color: #fff;
    }


    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
`;
