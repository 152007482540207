import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAuth from '@hooks/useAuth';
import useSize from '@hooks/useSize';
import { changeOnBoardingStatus, setOnBoarding } from '@src/store/modules/auth/actions';
import constants from '@src/assets/i18n/constants';
import { Onboarding } from './styles';

export default function OnboardingHome() {
    const [isTourOpen, setTourOpen] = useState(false);
    const { width } = useSize();
    const { user, onBoarding } = useAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeOnBoardingStatus());
    }, [dispatch]);

    useEffect(() => {
        if (onBoarding?.data) {
            if (!(onBoarding.data.onBoardingOrganizerHome) && !(onBoarding.skipped)) {
                setTourOpen(true);
            }
        }
    }, [dispatch, onBoarding]);

    useLayoutEffect(() => {
        if (isTourOpen) {
            const originalStyle = window.getComputedStyle(document.body).overflow;
            document.body.style.overflow = 'hidden';
            return () => {
                document.body.style.overflow = originalStyle;
            };
        }
    }, [isTourOpen]);

    function handleCloseOnboarding(key, skipped, global_skip) {
        dispatch(setOnBoarding(key, skipped, global_skip));
        setTourOpen(false);
    }

    function welcome() {
        return (
            {
                level: 'all',
                selector: '',
                content: ({ goTo }) => (
                    <>
                        <h1>{t(constants.ONBOARDING_HOME_WELCOME_TITLE)}</h1>
                        <p>{t(constants.ONBOARDING_HOME_WELCOME)}</p>
                        <div className="buttons">
                            <button
                                type="button"
                                className="btn continue-btn"
                                onClick={() => goTo(1)}
                            >
                                {t(constants.ONBOARDING_LETS_START)}
                            </button>
                            <button
                                type="button"
                                className="btn ignore-btn"
                                onClick={() => handleCloseOnboarding('onBoardingOrganizerHome', true)}
                            >
                                {t(constants.ONBOARDING_SKIP_TOUR)}
                            </button>
                        </div>
                    </>
                ),
                position: 'center',
            }
        );
    }

    function myVideos() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__myvideos"]',
                content: () => (
                    <>
                        <h2>{t(constants.ONBOARDING_HOME_MY_VIDEOS_TITLE)}</h2>
                        <p>{t(constants.ONBOARDING_HOME_MY_VIDEOS)}</p>
                    </>
                ),
            }
        );
    }

    function users() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__users"]',
                content: () => (
                    <>
                        <h2>{t(constants.ONBOARDING_HOME_USERS_TITLE)}</h2>
                        <p>{t(constants.ONBOARDING_HOME_USERS)}</p>
                    </>
                ),
            }
        );
    }

    function gamefication() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__gamefication"]',
                content: () => (
                    <>
                        <h2>{t(constants.ONBOARDING_HOME_GAMEFICATION_TITLE)}</h2>
                        <p>{t(constants.ONBOARDING_HOME_GAMEFICATION)}</p>
                    </>
                ),
            }
        );
    }

    function tutorials() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__tutorials"]',
                content: () => (
                    <>
                        <h2>{t(constants.ONBOARDING_HOME_TUTORIALS_TITLE)}</h2>
                        <p>{t(constants.ONBOARDING_HOME_TUTORIALS)}</p>
                    </>
                ),
            }
        );
    }

    function logout() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__logout"]',
                content: () => (
                    <>
                        <h2>{t(constants.ONBOARDING_HOME_LOGOUT_TITLE)}</h2>
                        <p>{t(constants.ONBOARDING_HOME_LOGOUT)}</p>
                    </>
                ),
            }
        );
    }

    function videoCard() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__videocard"]',
                content: () => (
                    <>
                        <h2>{t(constants.ONBOARDING_HOME_VIDEO_CARD_TITLE)}</h2>
                        <p>{t(constants.ONBOARDING_HOME_VIDEO_CARD)}</p>
                    </>
                ),
            }
        );
    }

    function newVideo() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__newvideo"]',
                content: () => (
                    <>
                        <h2>{t(constants.ONBOARDING_HOME_NEW_VIDEO_TITLE)}</h2>
                        <p>{t(constants.ONBOARDING_HOME_NEW_VIDEO)}</p>
                    </>
                ),
            }
        );
    }

    function userArea() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__user"]',
                content: () => (
                    <>
                        <h2>{t(constants.ONBOARDING_HOME_USER_AREA_TITLE)}</h2>
                        <p>{t(constants.ONBOARDING_HOME_USER_AREA)}</p>
                        <button
                            type="button"
                            className="btn complete-btn"
                            onClick={() => handleCloseOnboarding('onBoardingOrganizerHome')}
                        >
                            {t(constants.ONBOARDING_HOME_COMPLETE)}
                        </button>
                    </>
                ),
            }
        );
    }

    function dropDownMenu() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__burger__button"]',
                content: () => (
                    <>
                        <h2>{t(constants.ONBOARDING_HOME_MENU_TITLE)}</h2>
                        <p>{t(constants.ONBOARDING_HOME_MENU)}</p>
                        <button
                            type="button"
                            className="btn complete-btn"
                            onClick={() => handleCloseOnboarding('onBoardingOrganizerHome')}
                        >
                            {t(constants.ONBOARDING_HOME_COMPLETE)}
                        </button>
                    </>
                ),
                position: 'bottom',
            }
        );
    }

    const mobileSteps = [
        welcome(),
        videoCard(),
        newVideo(),
        dropDownMenu(),

    ];

    const desktopSteps = [
        welcome(),
        myVideos(),
        users(),
        gamefication(),
        tutorials(),
        logout(),
        videoCard(),
        newVideo(),
        userArea(),

    ];

    function defineSteps(width, user) {
        const filteredSteps = width > 800 ? desktopSteps : mobileSteps;

        if (!(user.admin || user.organizer)) {
            const userSteps = filteredSteps.filter(step => step.level === 'all');
            return userSteps;
        }

        return filteredSteps;
    }

    const steps = defineSteps(width, user);

    return (
        <Onboarding
            steps={(steps)}
            isOpen={(isTourOpen)}
            maskClassName="onboardingOverlay"
            maskSpace={16}
            accentColor="#ff438d"
            disableInteraction
            closeWithMask={false}
            rounded={4}
            onRequestClose={() => handleCloseOnboarding('onBoardingOrganizerHome', true)}
            closeButtonAriaLabel={t(constants.ONBOARDING_COMPLETE)}

        />
    );
}
