import actions from '../../../utils/actions';

export function getTeamsStatus(roomId) {
    return {
        type: actions.GET_TEAMS_STATUS,
        payload: roomId,
    };
}

export function asyncSetTeamsStatus({ status, roomId }) {
    return {
        type: actions.ASYNC_SET_TEAMS_STATUS,
        payload: { status, roomId },
    };
}

export function setTeamsStatus(status) {
    return {
        type: actions.SET_TEAMS_STATUS,
        payload: status,
    };
}

export function getTeams(roomId) {
    return {
        type: actions.GET_TEAMS,
        payload: roomId,
    };
}

export function setTeams(teams) {
    return {
        type: actions.SET_TEAMS,
        payload: teams,
    };
}

export function asyncCreateTeams({ roomId, people }) {
    return {
        type: actions.ASYNC_CREATE_TEAMS,
        payload: { roomId, people },
    };
}
