import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import api from '@services/api';
import { defaultErrorHandler } from '@src/store/utilities';
import languagesConstants from '@langs/constants';
import actions from '@utils/actions';
import { translate } from '@utils/functions';
import Notify from '@src/utils/notification';
import * as captionsActions from './actions';

function* getCaptions({ payload }) {
    const { roomId } = payload;
    try {
        const response = yield api.getCaptions(roomId);
        yield put(captionsActions.setCaptions(response.captions));
    } catch (error) {
        yield put(captionsActions.setCaptions([]));
        defaultErrorHandler(error,
            {
                default: error?.response?.data?.error_description
                || translate(languagesConstants.CAPTION_GET_FAILED),

            });
        console.log(error);
    }
}

function* removeCaption({ payload }) {
    const { roomId, captionId } = payload;
    try {
        yield api.removeCaption(roomId, captionId);
        yield put(captionsActions.removeCaptionSuccess(captionId));
        Notify.success(translate(languagesConstants.CAPTION_REMOVED_SUCCESSFULLY));
    } catch (error) {
        console.log(error);
        defaultErrorHandler(error, { default: translate(languagesConstants.CAPTION_REMOVED_FAILED) });
    }
}

export default all([
    takeLatest(actions.GET_CAPTIONS, getCaptions),
    takeEvery(actions.REMOVE_CAPTION_REQUEST, removeCaption),
]);
