import { all, put, takeLatest } from 'redux-saga/effects';
import constants from '@src/assets/i18n/constants';
import actions from '@utils/actions';
import api from '@services/api';
import { defaultErrorHandler } from '@src/store/utilities';
import { makeIdsList, splitListInBatches, translate } from '@utils/functions';
import { setWatchedVideosProgress } from './actions';

function* getWatchedVideosProgress({ payload }) {
    try {
        const idsList = makeIdsList(payload);
        const splittedIdsList = splitListInBatches(idsList, 50);
        let progressList = [];

        for (let i = 0; i < splittedIdsList.length; i += 1) {
            const { progress } = yield api.getWatchedVideosProgress(splittedIdsList[i]);
            yield progressList = [...progressList, ...progress];
        }

        yield put(setWatchedVideosProgress(progressList));
    } catch (error) {
        defaultErrorHandler(error, { default: translate(constants.FAIL_TO_LOAD_VIDEO_PROGRESS) });
    }
}

export default all([
    takeLatest(actions.ROOM_GET_PROGRESS, getWatchedVideosProgress),
]);
