import actions from '../../../utils/actions';

export function getUsers(courseId, pageSize, page, keyword, disableLoading) {
    return {
        type: actions.GET_USERS_REQUEST,
        payload: {
            courseId,
            pageSize,
            page,
            keyword,
            disableLoading,
        },
    };
}

export function getUsersNextPage(courseId, pageSize, page, keyword) {
    return {
        type: actions.GET_USERS_NEXT_PAGE_REQUEST,
        payload: {
            courseId,
            pageSize,
            page,
            keyword,
        },
    };
}

export function getUserFilter(courseId, pageSize, LastEvaluatedKey, keyword) {
    return {
        type: actions.GET_USER_BY_FILTER,
        payload: {
            courseId,
            pageSize,
            LastEvaluatedKey: LastEvaluatedKey || '',
            keyword,
        },
    };
}

export function changeRows() {
    return { type: actions.USERS_CHANGE_ROWS_PER_PAGE };
}

export function setLastEvaluateKey(key) {
    return {
        type: actions.LAST_EVALUATE_KEY,
        payload: key,
    };
}

export function clearUsers() {
    return { type: actions.CLEAR_USERS };
}

export function setUsers(users, isLastPage) {
    return {
        type: actions.USERS_SUCCESS,
        payload: { users, isLastPage },
    };
}

export function setUsersNexPage(users, isLastPage) {
    return {
        type: actions.USERS_PAGINATED_SUCCESS,
        payload: {
            users,
            isLastPage,
        },
    };
}

export function setLoading(value) {
    return {
        type: actions.SET_LOADING,
        payload: value,
    };
}

export function usersNextPageFail() {
    return { type: actions.SET_LOADING_NEXT_PAGE_FAIL };
}

export function setLoaderState(state) {
    return {
        type: actions.SET_LOADER_STATE,
        payload: state,
    };
}

export function deleteUser(user, courseId) {
    return {
        type: actions.DELETE_USER_REQUEST,
        payload: {
            user,
            courseId,
        },
    };
}

export function deleteSuccess(user) {
    return {
        type: actions.DELETE_SUCESS,
        payload: user,
    };
}

export function editUser(user, courseId) {
    return {
        type: actions.EDIT_USER_REQUEST,
        payload: {
            user,
            courseId,
        },
    };
}

export function updateUsersList(user, courseId) {
    return {
        type: actions.UPDATE_USER_LIST,
        payload: {
            user,
            courseId,
        },
    };
}

export function getCsvList(withLoading, courseId, pageSize, detailLastKey) {
    return {
        type: actions.GET_CSV_LIST_REQUEST,
        payload: { withLoading, courseId, pageSize, detailLastKey },
    };
}

export function getCsvListSuccess(list) {
    return {
        type: actions.GET_CSV_LIST_SUCCESS,
        payload: list,
    };
}

export function getCsvListFailure() {
    return { type: actions.GET_CSV_LIST_FAILURE };
}

export function getImportList(importId, pageSize, lastKey) {
    return { type: actions.DETAIL_IMPORT_LIST_REQUEST, payload: { importId, pageSize, lastKey } };
}

export function getImportListSuccess(payload) {
    return { type: actions.DETAIL_IMPORT_LIST_SUCCESS, payload };
}

export function getImportListFailure() {
    return { type: actions.DETAIL_IMPORT_LIST_FAILURE };
}
