export default {
    HOME: '/',
    CHANNELS: '/myChannels',
    COURSE_RECORDED: '/courseRecorded',
    COURSE_LIVE: '/courseLive',
    FORCE_LOGOUT: '/forcedLogout',
    TEAMS: '/teams',
    OFFERS: '/offers',
    USERS: '/users',
    PRESENCE_REPORT: '/presenceReport',
    QUIZZES: '/quizzes',
    RANKING: '/ranking',
    BLOCK_LIST: '/blockList',
    IMPORT_CSV: '/users/import',
    VIDEO_WALL: '/videoWall',
    VIDEO_STATS: '/videoStats',
    GUEST_PRESENTER: '/guestPresenter',
    GUEST_PRESENTER_VIEW: '/guestPresenterView',
    TUTORIAL: '/tutorial',
    PLAYER_TUTORIAL: '/playTutorials/:tutorialId',
    NETWORK_FAILED: '/networkFailed',
};
