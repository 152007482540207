import styled, { css } from 'styled-components';

export const GamificationSettingsItemContainer = styled.div`
    display: flex;
    margin: 1rem 0px;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const GamificationSettingsContentLeft = styled.div`
    width: 30%;
`;

export const GamificationSection = styled.div`
    height: 56px;
    background-color:rgba(0,0,0,0.2);
    border-radius: 6px;
    padding: 6px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;

    .MuiSwitch-colorSecondary {
        &.Mui-checked {
            color: #31a6a1 !important;
            
            & + .MuiSwitch-track {
                background-color: #31a6a1 !important;
            }
        }
    }

    .MuiSwitch-switchBase {
        color: #29283f !important;
    } 
    
    .MuiSwitch-track {
        background-color: #29283f !important;
    }
    `;

export const SwitchArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
        margin: 0px 5px;
    }

    p {
        font-size: .75rem;
    }

    `;

export const GamificationSettingsContentRight = styled.div`
    width: 70%;
    margin-left: 20px;
    opacity: ${({ isSectionActive }) => (isSectionActive ? 1 : 0.2)} ;
    ${({ isSectionActive }) => !isSectionActive && css`
        pointer-events: none;
    `}
    
    input {
        background-color: #29283f;
        height: 33px;
        width: 100%;
        outline: 0;
        border-radius: 8px;
        border: 0;
        padding: .5rem;
        color: #fff;
    }
    
    
`;

export const GamificationAccessRoom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    p {
        font-size: .75rem;
        font-weight: bold;
        margin-left: 6px;
        margin-bottom: 2px;
    }
`;

export const GamificationDefaultScore = styled.div`

    p {
        font-size: .75rem;
        font-weight: bold;
        margin-left: 6px;
        margin-bottom: 2px;
    }

    input {
        ${(props) => props.isErroredAccessRoom
        && css`
        border: 1px solid #c53030;
        color: #c53030;
        `}
    }
`;

export const GamificationAccessRoomScore = styled.div`
    margin-left: 10px;
    position: relative;

    input {
        ${(props) => props.isErroredAccessRoom
        && css`
        border: 1px solid #c53030;
        color: #c53030;
        `}
    }
`;

export const GamificationAccessRoomMode = styled.div`
    position: relative;

    .punctuality-mode-title {
        display: flex;
        justify-content: space-between;
    }
    
`;

export const GamificationModeSelect = styled.div`
    background-color: #29283f;
    height: 33px;
    width: 230px;
    outline: 0;
    border-radius: 8px;
    border: 0;
    padding: .5rem;
    color: #fff;
    display: flex;
    justify-content: space-between;

    border-bottom-left-radius: ${({ isActive }) => isActive && 0};
    border-bottom-right-radius: ${({ isActive }) => isActive && 0};  

    img {
        width: 12px;
        ${({ isActive }) => isActive && css`
            transform: rotate(180deg);  
        `}
    }


`;

export const GamificationModeOptions = styled.div`
    background-color: #29283f;
    width: 100%;
    padding: 0.2rem;
    position: absolute;
    top: 50px;
    border: 1px solid rgba(0,0,0,0.2) ;
    border-top: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    z-index: 10;

`;

export const GamificationModeOption = styled.div`
    width: 100%;
    color: #fff;
    border-bottom: 1px solid #29283f;
    padding: .2rem;
    cursor: pointer;
    font-size: .8rem;
`;

export const GamificationQuizzScore = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-color: #15152b;

    .image-area {
        background-color:rgba(0,0,0,0.2);
        width: 73px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;

        img {
            width: 27px; 
        }
    }

    .text-area {
        padding: 8px;
        font-weight: bold;

        strong {
            color: #ff9460
        }
    }
`;

export const TooltipFormatText = styled.p`
   text-align: justify;
   line-height: 1.1rem;
   font-size: 1.2rem;
`;
