import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import RoomSettingUpWarning from '@src/components/RoomSettingUpWarning';
import { VideoPlayer as TokyoPlayer } from 'react-tokyo';
import useRooms from '@src/hooks/useRooms';
import Loading from '@src/components/Loading';
import NoVideo from '@src/components/NoVideo';
import languageConstants from '@langs/constants';

const WebRTCPlayer = React.lazy(() => import('../../../WebRTCPlayer'));

const webRtcTechnologyType = 1;

export default function PlayerHandler({ isSettingUp, roomId }) {
    const { t } = useTranslation();
    const { technology, socketPath, videoUrl } = useRooms();

    if (isSettingUp) {
        return (
            <RoomSettingUpWarning
                isSettingUp={isSettingUp}
                title={t(languageConstants.CREATING_ROOM_PLEASE_WAIT)}
            />
        );
    }

    if (videoUrl && technology !== webRtcTechnologyType) {
        return (
            <TokyoPlayer decodeUrl={videoUrl} quizComponent={() => { }} />
        );
    }

    if (videoUrl) {
        return (
            <Suspense fallback={<Loading />}>
                <WebRTCPlayer
                    roomId={roomId}
                    socketPath={socketPath}
                    videoUrl={videoUrl}
                    waitingTransmissionText={t(languageConstants.WAITING_TRANSMISSION)}
                    loadingComponent={<Loading />}
                    noVideoComponent={<NoVideo title="OOps!" subtitle={t(languageConstants.DISABLED_CAMERA)} />}
                />
            </Suspense>
        );
    }
    return null;
}
