import React from 'react';
import ToggleSwitch from '@components/ToggleSwitch';
import { KopaSingleImgUpload } from '@src/components/KopaUpload/SingleFileUpload';
import { VIDEO_TECHNOLOGY_TYPES, ROOM_STATUS, ENABLE_LIVE_CREATION } from '@src/utils/constants';
import CONSTANTS from '@langs/constants';
import { useTranslation } from 'react-i18next';
import DownloadVideoButton from '@src/components/DownloadVideoButton';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
    BroadcastInfoArea,
    ChatToggleArea, 
    ChatToggleContent, 
    ChatToggleItem, 
    ContainerSpinner, 
    CourseLiveUploadContainer, 
    CourseLiveUploadContent, 
    DownloadVideoButtonArea, 
    DropzoneCoverArea, 
    MessageLoadingLink, 
    UploadVideoArea, 
    UploadVideoInfo,
} from './styles';
import BroadcastClipBoardItem from './BroadcastClipboardItem';
import CircularProgress from '@material-ui/core/CircularProgress';

const CourseLiveUpload = ({
    uploadedFile = {},
    setUploadedFile,
    thumbnailUrl,
    setThumbnailUrl,
    createChatEnabled,
    setCreateChatEnabled,
    primaryBroadcastingUrl,
    secondaryBroadcastingUrl,
    primaryStreamName,
    secondaryStreamName,
    roomEditMode,
    technologyType,
    selectedEditRoom,
    showSkeleton,
}) => {
    const { t } = useTranslation();

    const liveAvailable = ENABLE_LIVE_CREATION === 'true';
    const liveAvailablePrimaryUrl = (technologyType === VIDEO_TECHNOLOGY_TYPES.WEBRTC ? t(CONSTANTS.CHANNEL_PRODUCER_LINK) : t(CONSTANTS.CHANNEL_PRIMARYURL))?.toUpperCase();
    const liveAvailableSecondaryUrl = (technologyType === VIDEO_TECHNOLOGY_TYPES.WEBRTC ? t(CONSTANTS.CHANNEL_PRODUCER_BACKUP_LINK) : t(CONSTANTS.CHANNEL_SECONDARYURL))?.toUpperCase();

    const LoadingSkeleton = () => (
        <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
            <Skeleton height={260} count={1} style={{ margin: '10px 0' }} />
            <Skeleton height={60} count={2} style={{ margin: '10px 0' }} />
        </SkeletonTheme>
    );

    const LoadingLinks = () => (
        <div>
            <MessageLoadingLink>
                <p>{t(CONSTANTS.LOADINGMESSAGELINK)}</p>
            </MessageLoadingLink>
            <ContainerSpinner>
                <CircularProgress />
            </ContainerSpinner>
        </div>
    );

    return (
        <CourseLiveUploadContainer>
            <CourseLiveUploadContent>
                {showSkeleton ? (
                    <LoadingSkeleton />
                ) : (
                    <UploadVideoArea>
                        <div>
                            <DropzoneCoverArea
                                hasThumb={Object.keys(uploadedFile).length}
                            >
                                <KopaSingleImgUpload
                                    fileType="thumbnail"
                                    uploadedFile={uploadedFile}
                                    setUploadedFile={setUploadedFile}
                                    uploadBaseUrl="/config/thumbnail/upload"
                                    thumbnailUrl={thumbnailUrl}
                                    setThumbnailUrl={setThumbnailUrl}
                                />
                            </DropzoneCoverArea>
                            {Object.keys(uploadedFile).length === 0 && (
                            <UploadVideoInfo>
                                <p>{t(CONSTANTS.CHANNEL_VIDEOCOVER)}</p>
                                <span>{t(CONSTANTS.MINIMUM_IMAGE_SIZE)?.toUpperCase()}</span>
                            </UploadVideoInfo>
                            )}
                            <ChatToggleArea>
                                <ChatToggleContent createChatEnabled={createChatEnabled}>
                                    <ChatToggleItem>
                                        <ToggleSwitch
                                            isOn={createChatEnabled}
                                            handleToggle={() => setCreateChatEnabled(!createChatEnabled)}
                                            disabled={false}
                                        />
                                    </ChatToggleItem>
                                    <p>CHAT <strong> {(createChatEnabled ? t(CONSTANTS.ENABLED_CAPS) : t(CONSTANTS.DISABLED_CAPS))?.toUpperCase() }</strong></p>
                                </ChatToggleContent>
                            </ChatToggleArea>
                            {roomEditMode && (
                            <BroadcastInfoArea>

                                {
                                    !(primaryBroadcastingUrl && secondaryBroadcastingUrl) && (
                                        <LoadingLinks />
                                    )
                                }

                                {
                                    primaryBroadcastingUrl && primaryBroadcastingUrl !== '' && (
                                        <BroadcastClipBoardItem
                                            title={liveAvailablePrimaryUrl}
                                            data={liveAvailable ? primaryBroadcastingUrl : t(CONSTANTS.LIVE_UNAVAILABLE)}
                                        />
                                    )
                                }

                                {
                                    primaryStreamName && primaryStreamName !== '' && (
                                        <BroadcastClipBoardItem
                                            title={t(CONSTANTS.CHANNEL_PRIMARY_PASSWORD)}
                                            data={primaryStreamName}
                                        />
                                    )
                                }

                                {
                                    secondaryBroadcastingUrl && secondaryBroadcastingUrl !== '' && (
                                        <BroadcastClipBoardItem
                                            title={liveAvailableSecondaryUrl}
                                            data={liveAvailable ? secondaryBroadcastingUrl : t(CONSTANTS.LIVE_UNAVAILABLE)}
                                        />
                                    )
                                }

                                {
                                    secondaryStreamName && secondaryStreamName !== '' && (
                                        <BroadcastClipBoardItem
                                            title={t(CONSTANTS.CHANNEL_SECONDARY_PASSWORD)}
                                            data={secondaryStreamName}
                                        />
                                    )
                                }

                            </BroadcastInfoArea>
                            )}

                            {selectedEditRoom && (
                                <DownloadVideoButtonArea>
                                    <DownloadVideoButton roomEdit={selectedEditRoom} disabled={selectedEditRoom.status !== ROOM_STATUS.DONE} />
                                </DownloadVideoButtonArea>
                            )}
                        </div>
                    </UploadVideoArea>
                )}
            </CourseLiveUploadContent>
        </CourseLiveUploadContainer>
    );
};

export default CourseLiveUpload;
