import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import VideoCard from '@src/components/VideoCard';
import useRooms from '@src/hooks/useRooms';
import { getChannelRooms } from '@src/store/modules/rooms/actions';
import LoadingSkeleton from '@src/components/VideoCardSkeleton';
import Modal from '@src/components/Modal';
import { useBreadcrumbs } from '@src/hooks/useBreadcrumbs';
import { limitCharacters } from '@src/utils/functions';
import NoContent from '@src/components/NoContent';
import paths from '@src/utils/paths';
import { Container, PageHeader, RoomsList } from './styles';

export default function PresenceReportHome() {
    const [showWarn, setShowWarn] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { selectedChannel, selectedChannelRooms: rooms, loading } = useRooms();
    const { setItems } = useBreadcrumbs();

    function handleAddQRCode(room) {
        if (room?.recorded) {
            setShowWarn(true);
            return;
        }
        history.push(`offers/${room?.id}/?roomName=${room?.name}`);
    }

    useEffect(() => {
        if (selectedChannel?.id) {
            dispatch(getChannelRooms(selectedChannel?.id));
        }
    }, [dispatch, selectedChannel]);

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t('ORGANIZER_DASHBOARD') },
            { to: paths.OFFERS, label: t('OFFERS') },
        ];
        setItems(breadcrumbsItems);
    }, [selectedChannel, setItems, t]);

    return (
        <Container>
            <Modal isOpen={showWarn} handleModal={() => setShowWarn(false)} title={t('OFFERS', { context: 'WARN' }).split(':')[0]}>
                <div className="--warn-content">
                    <h5>{t('OFFERS', { context: 'WARN' }).split(':')[1]}</h5>
                    <button type="button" onClick={() => setShowWarn(false)}>OK</button>
                </div>
            </Modal>
            <PageHeader>
                <h2>{selectedChannel?.id && limitCharacters(`#${selectedChannel?.id} - ${selectedChannel?.name}`, 40)}</h2>
            </PageHeader>
            {
                loading && (
                    <LoadingSkeleton marginLeft={0} />
                )
            }
            <RoomsList>
                {
                    !loading && rooms?.map(room => (
                        <VideoCard
                            key={room?.id}
                            room={room}
                            button={{ text: t('OFFERS', { context: 'CREATEOFFER' }), action: () => handleAddQRCode(room) }}
                        />
                    ))
                }
                {
                    !loading && !rooms?.length > 0 && (
                        <NoContent
                            title={t('OPS')}
                            text={t('CHANNEL', { context: 'NOVIDEO' })}
                        />
                    )
                }
            </RoomsList>
        </Container>
    );
}
