import React from 'react';
import { CustomFormInputContainer, CustomInput } from './styles';

const CustomFormInput = ({ labelTitle, helpText, register, ...props }) => (
    <CustomFormInputContainer>
        <p>{labelTitle || 'Label'} <span>{helpText}</span></p>
        <CustomInput {...props} {...register} />
    </CustomFormInputContainer>
);

export default CustomFormInput;
