import styled from 'styled-components';

export const Container = styled.div`
    background-color: #1b1b32;
    padding: 35px;

    h3 {
        font-size: 14px;
        font-weight: bold;        
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .upload-container {
        height: 185px;
        background-color: #24243e;
        border-radius: 6px;
        position: relative;
    }

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 17px;

        > div .upload-info {
            display: none;
        }
    }
    .label {
        color: #ffffff;
        font-size: 10px;
        font-weight: bold;
        text-align: justify;
        span {
            text-align:justify; 
            color: #f76b8e;
        }
        strong {
            color: #fff;
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        flex-direction: column;
        
        button {
            width: 90%;
            max-width: 390px;
        }
    }    
`;

export const TipMessage = styled.div`
    padding: .3rem 0;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    color: #fff;

    p {
        word-wrap: break-word;
        text-align: center;
    }
`;

export const DownloadSample = styled.button.attrs(() => ({ type: 'button' }))`
    background: none;
    display: flex;
    width: 100%;
    padding: .7rem 0;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border: none;
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 0.24px;
    text-align: center;
    background-color: #15152b;
    color: #ffa356;

    margin-bottom: 20px;
    
    .icon-xlsx {
        width: 12px;
    }

    img {
        margin-right: 5px;
    }
`;

export const ImportCSVHeader = styled.div`
    p {
        color: #fff;
        font-weight: bold;
    }
`;

export const ImportButton = styled.button.attrs(() => ({ type: 'button' }))`
    border: none;
    color: #FFF;
    background: linear-gradient(90deg,#FFAC47 0%, #FF438D 100%);
    width: 300px;
    min-height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;   
    font-weight: 600;
    border-radius: 8px;  
    font-weight: bold;
    text-transform: uppercase; 
    &:disabled {
        opacity: 0.3;
    }    
`;
