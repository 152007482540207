import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;

    img {
        width: 150px;
        height: auto;
    }

    h2 {
        font-size: 25px;
        font-weight: 800;
        letter-spacing: 0.29px;
        color: #f9f9f9; 
        margin-top: 20px;
        text-transform: uppercase;
    }

    p {
        font-size: 20px;
        font-weight: 800;
        letter-spacing: 0.23px;
        color: #8c8c8c;
        max-width: 423px;
    }

    button {
        border: none;
        color: #FFF;
        background: linear-gradient(90deg,#FF438D 0%,#FFAC47 100%);
        width: 191px;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        font-weight: 600;
    }

    @media(max-width: 680px) {
        p {
            max-width: 280px;
        }
    }
`;