import { all } from 'redux-saga/effects';

import login from './auth/sagas';
import room from './rooms/saga';
import recoverPassword from './recoverPassword/sagas';
import teams from './teams/saga';
import offers from './offers/saga';
import users from './users/saga';
import presenceReport from './presenceReport/saga';
import quizzes from './quizzes/saga';
import gamification from './gamification/saga';
import blockList from './blockList/saga';
import captions from './captions/saga';
import roomsProgress from './roomsProgress/saga';

export default function* rootSaga() {
    return yield all([
        login,
        room,
        recoverPassword,
        teams,
        offers,
        users,
        presenceReport,
        quizzes,
        gamification,
        blockList,
        captions,
        roomsProgress,
    ]);
}
