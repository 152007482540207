import styled from 'styled-components';

export const CustomFormInputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const CustomFormInputHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;

    p {
        top: 0;
        color: #fff;
        font-weight: bold;
        font-size: 12px;

        span {
            color: #ff9869;
            font-size: 8px;
            font-weight: bold;
        }
    }

    strong {
        color: #ff9869;
        font-size: 12px;
        font-weight: bold;
    }
`;

export const CustomTextArea = styled.textarea`
    background-color: #212032;
    height: 135px;
    width: 100%;
    resize: none;
    outline: 0;
    border: 0;
    margin-top: 4px;
    border-radius: 6px;
    padding: 16px;

    font-size: 12px;
    font-weight: bold;
    color: #fff;
`;
