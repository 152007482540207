import axios from 'axios';
import { urls, TIMERS } from '../utils/constants';

class Api {
    constructor() {
        this._baseURL = urls.BASE_API;
        this._api = axios.create({
            baseURL: this._baseURL,
            timeout: TIMERS.TIMEOUT,
        });
        this._token = null;
        this._session = null;
    }

    isLogged() {
        return !!this._token;
    }

    getAccessToken() {
        return this._token;
    }

    getSessionToken() {
        return this._session.session_token;
    }

    async login(username, password) {
        const { data: token } = await this._api.post('auth/login', { username, password });
        this._token = token.accessToken;
        this._api.defaults.headers.common.Authorization = `Bearer ${this._token}`;
    }

    async recoverPassword({ email }) {
        await this._api.post('auth/recover', { username: email });
    }

    async changePassword(password, token) {
        this._api.defaults.headers.common.Authorization = `Bearer ${token}`;
        await this._api.post('auth/change', { password });
    }

    async logout() {
        try {
            if (this._token) {
                await this._api.post('auth/logout');
            }
        } finally {
            this.clear();
        }
    }

    editProfile(profile) {
        const { social_medias, thumbnail_url } = profile;
        return this._api.put('/users/profile', { social_medias, thumbnail_url }).then(r => r);
    }

    setToken(token) {
        this._token = token.accessToken;
        this._api.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
    }

    clear() {
        this._token = null;
        this._api.defaults.headers.common.Authorization = null;
    }

    getRooms() {
        return this._api.get('rooms/').then(r => r.data);
    }

    getUserRooms() {
        return this._api.get('rooms/list/all').then(r => r.data);
    }

    getRoom(roomID) {
        return this._api.get(`rooms/${roomID}`).then(r => r.data);
    }

    getRoomEdit(roomID) {
        return this._api.get(`rooms/info/${roomID}`).then(r => r.data);
    }

    getRoomEditLinks(roomID) {
        return this._api.get(`rooms/info/${roomID}`).then(r => r.data);
    }

    getRoomEditRecorded(roomID) {
        return this._api.get(`rooms/${roomID}`).then(r => r.data);
    }

    createRoom(room) {
        return this._api.post('rooms/', room).then(r => r.data);
    }

    editRoom(room, roomID) {
        return this._api.put(`rooms/${roomID}`, room).then(r => r.data);
    }

    activeGoLive(roomID) {
        return this._api.post(`rooms/go/live/${roomID}`).then(r => r.data);
    }

    activeEndLive(roomID) {
        return this._api.post(`rooms/end/live/${roomID}`).then(r => r.data);
    }

    isRoomLive(roomID) {
        return this._api.get(`rooms/live/status/${roomID}`).then(r => r.data);
    }

    getRoomEngagement(roomID) {
        return this._api.get(`rooms/engagement/report/${roomID}`).then(r => r.data);
    }

    getRoomEventLog(roomID) {
        return this._api.get(`rooms/eventlog/report/${roomID}`).then(r => r.data);
    }

    getRoomGraph(roomID, startDate, endDate) {
        return this._api.get(`rooms/graph/report/${roomID}${startDate ? `?fromDate=${startDate}` : ''}${endDate ? `&toDate=${endDate}` : ''}`).then(r => r.data);
    }

    getRoomGeolocalization(roomID, startDate, endDate) {
        return this._api.get(`rooms/geolocalization/report/${roomID}${startDate ? `?fromDate=${startDate}` : ''}${endDate ? `&toDate=${endDate}` : ''}`).then(r => r.data);
    }

    sendMessage(roomID, text) {
        return this._api.post(`/rooms/${roomID}/message`, { text }).then(r => r.data);
    }

    sendQuestion(roomID, text) {
        return this._api.post(`/rooms/${roomID}/message/question`, { text }).then(r => r.data);
    }

    sendReply(roomID, text, originalMessageId) {
        return this._api.post(`/rooms/${roomID}/message/reply`, { text, originalMessageId }).then(r => r.data);
    }

    sendMessageMonitorChat(roomID, text) {
        return this._api.post(`/rooms/${roomID}/message/instructor`, { text }).then(r => r.data);
    }

    sendMessageTeamChat(roomID, text) {
        return this._api.post(`/rooms/${roomID}/message/team`, { text }).then(r => r.data);
    }

    pinMessage(roomID, messageID) {
        return this._api.post(`/rooms/pin/message/${messageID}/${roomID}`).then(r => r.data);
    }

    unpinMessage(roomID, messageID) {
        return this._api.post(`/rooms/unpin/message/${messageID}/${roomID}`).then(r => r.data);
    }

    enterChat(roomID) {
        return this._api.post(`/rooms/${roomID}/enter`).then(r => r.data);
    }

    exitChat(roomID) {
        return this._api.post(`/rooms/${roomID}/exit`).then(r => r.data);
    }

    getVideo(videoID) {
        return this._api.get(`video/${videoID}`).then(r => r.data);
    }

    setChatEnabled(roomId, enabled) {
        return this._api.post(`config/${roomId}/chat/enable`, { enabled });
    }

    getVideoUploadUrl(payload) {
        return this._api.post('rooms/upload/component', payload).then(r => r.data);
    }

    getLiveRecordingUrl(roomId) {
        return this._api.get(`rooms/${roomId}/generate_download_link`).then(r => r.data);
    }

    getUploadVideoRecordingUrl(roomId) {
        return this._api.get(`rooms/${roomId}/report/uploaded`).then(r => r.data);
    }

    // teams
    getTeamsEnable(roomID) {
        return this._api.get(`config/${roomID}/teams/enable`).then(r => r.data);
    }

    getTeams(roomID) {
        return this._api.get(`config/${roomID}/teams`).then(r => r.data);
    }

    setTeamsStatus(status, roomID) {
        return this._api.post(`config/${roomID}/teams/enable`, { enabled: status }).then(r => r.data);
    }

    createTeams(roomID, size) {
        return this._api.post(`config/${roomID}/teams/create`, { size }).then(r => r.data);
    }

    // offers
    getOffers(roomID) {
        return this._api.get(`rooms/${roomID}/sale`).then(r => r.data);
    }

    getOfferUrl(roomID) {
        return this._api.get(`rooms/${roomID}/sale/url`).then(r => r.data);
    }

    setOffersStatus(status, roomID) {
        return this._api.put(`rooms/${roomID}/sale/enable`, { enabled: status }).then(r => r.data);
    }

    createOffer(roomID, templateUrl, useSalesforce) {
        return this._api.put(`rooms/${roomID}/sale`, { saleBaseUrl: templateUrl, useSalesForceId: useSalesforce }).then(r => r.data);
    }

    // vouchers
    verifyVoucher(voucherId) {
        return this._api.get(`vouchers/${voucherId}/check/status`).then(r => r.data);
    }

    registerVoucherUser(voucher) {
        return this._api.post('vouchers/users/register', voucher).then(r => r.data);
    }

    verifyEmail(voucherId, code) {
        return this._api.post(`vouchers/users/verify?voucherId=${voucherId}&code=${code}`).then(r => r.data);
    }

    resendEmail(email) {
        return this._api.post(`vouchers/users/resend/email?username=${email}`).then(r => r.data);
    }

    verifyPassword(voucherId, password) {
        return this._api.post('vouchers/users/register/password', { voucherId, password }).then(r => r.data);
    }

    associateVoucher(voucherId) {
        return this._api.put(`vouchers/${voucherId}/associate`).then(r => r.data);
    }

    associateVoucherRegistered(voucherId, renew) {
        return this._api.put(`vouchers/${voucherId}/users/associate?renew=${renew}`).then(r => r.data);
    }

    getUser() {
        return this._api.get('auth/me', { headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('KopaLive'))?.accessToken}` } });
    }

    changeUserLanguage(language) {
        return this._api.post(`config/change/language/${language}`);
    }

    setUserTerm(courseId, userId) {
        return this._api.put(`users/${userId}/accept/course/terms/${courseId}`);
    }

    async getUploadStatus(roomId) {
        const res = await this._api.get(`/rooms/${roomId}/upload/status`);
        return res.data;
    }

    async getChannels() {
        const response = await this._api.get('users/organizer/channels');
        return response.data;
    }

    // organizer/users---------------
    async getUsers(courseId, pageSize, page, keyword) {
        const response = await this._api.get('users/organizer', { params: { courseId, pageSize, page, keyword } });
        return response.data;
    }

    async postUser(user) {
        const response = await this._api.post('users/organizer', user);
        return response.data;
    }

    async deleteUser(user, courseId) {
        const response = await this._api.delete(`/users/organizer/username/${user.username}?courseId=${courseId}`);
        return response.data;
    }

    async getUserFilter(courseId, pageSize, LastEvaluatedKey, keyword) {
        const response = await this._api.get('users/organizer', { params: { courseId, pageSize, LastEvaluatedKey, keyword } });
        return response.data;
    }

    async exportUsersReport(courseId) {
        return this._api.post('/users/organizer/export', null, { params: { courseId } }).then(r => r.data);
    }

    async getChannelRooms(courseId) {
        return this._api.get(`/rooms/course/${courseId}`).then(r => r.data);
    }

    async editUser(user, courseId) {
        const response = await this._api.put(`/users/organizer/username/${user.username}?courseId=${courseId}`, { permission: user.permission });
        return response.data;
    }

    async getPresenceReport({ roomId, nextToken: startingToken, rowsPerPage: pageSize, keyword: search }) {
        return this._api.get(`/rooms/${roomId}/report/presence`, { params: { startingToken, pageSize, search } }).then(r => r.data);
    }

    async exportPresenceReport(roomId) {
        return this._api.get(`/rooms/${roomId}/report/presence/export`, { responseType: 'blob' });
    }

    async getQuestions(roomId) {
        return this._api.get(`/rooms/${roomId}/questions`).then(r => r.data);
    }

    async getQuestion(roomId, questionId) {
        return this._api.get(`/rooms/${roomId}/questions/${questionId}`).then(r => r.data);
    }

    async postQuestion(roomId, question) {
        return this._api.post(`/rooms/${roomId}/questions`, question).then(r => r.data);
    }

    async editQuestion(roomId, question, questionId) {
        return this._api.put(`/rooms/${roomId}/questions/${questionId}`, question).then(r => r.data);
    }

    async editReleaseQuestion(roomId, questionId, release) {
        return this._api.put(`/rooms/${roomId}/questions/${questionId}/release`, release);
    }

    async getQuestionReport(questionId) {
        return this._api.get(`/rooms/questions/${questionId}/report`).then(r => r.data);
    }

    async getRanking(courseId, pagesize, page, ordination, sortBy, keyword) {
        const response = await this._api.get(`${urls.REACT_GAMES_API}/ranking?courseId=${courseId}&pagesize=${pagesize}&page=${page}&sortMode=${ordination}&sortBy=${sortBy}&keyword=${keyword}`);
        return response.data;
    }

    async getBlockedUsers(roomId, keyword, pageSize, LastEvaluatedKey) {
        return this._api.get(`/rooms/${roomId}/chat/block`, { params: { keyword, pageSize, LastEvaluatedKey } }).then(r => r.data);
    }

    async unblockUser(roomId, status) {
        return this._api.post(`/rooms/${roomId}/chat/block`, status).then(r => r.data);
    }

    async getRoomOnlineMonitorsRequest(roomId) {
        const res = await this._api.get(`/rooms/${roomId}/list_monitors`);
        return res.data;
    }

    async exportRankingReport(courseId) {
        return this._api.get(`${urls.REACT_GAMES_API}/ranking/export`, { params: { courseId }, responseType: 'blob' });
    }

    async toggleGamificationRequest(courseId, enabled) {
        const res = await this._api.patch(`courses/${courseId}/toggle_gamification`, { enabled });
        return res.data;
    }

    async gamificationRulesRequest(courseId) {
        const res = await this._api.get(`${urls.REACT_GAMES_API}rules/${courseId}`);
        return res.data;
    }

    async saveGamificationRequest(courseId, gamificationRules) {
        const res = await this._api.post(`${urls.REACT_GAMES_API}/rules`, {
            courseId,
            rules: gamificationRules,
        });

        return res.data;
    }

    async getImportCsvList(courseId, pageSize, lastKey = null) {
        return this._api.get('/users/organizer/imports', { params: { courseId, pageSize, lastKey } }).then(r => r.data);
    }

    async downloadImportCSVSample() {
        return this._api.get('/users/organizer/imports/example').then(r => r.data);
    }

    async detailImportList(importId, pageSize, lastKey = null) {
        return this._api.get(`/users/organizer/imports/${importId}`, { params: { pageSize, lastKey } }).then(r => r.data);
    }

    async createSession() {
        const resultSessionToken = await this._api.post('/auth/session').then(r => r.data);
        this._session = resultSessionToken;
        return resultSessionToken;
    }

    async muteParticipant(roomId, participantEmail) {
        const response = await this._api.post(`/rooms/${roomId}/participant/mute`, { email: participantEmail });
        return response.data;
    }

    async unmuteParticipant(roomId, participantEmail) {
        const response = await this._api.post(`/rooms/${roomId}/participant/unmute`, { email: participantEmail });
        return response.data;
    }

    getUserOnBoarding() {
        return this._api.get('/users/onboarding').then(r => r.data);
    }

    setOnBoarding(key, skipped, global_skip) {
        let data = {};
        if (global_skip !== undefined) {
            data = { global_skip };
        } else if (skipped !== undefined) {
            data = { key, skipped };
        } else {
            data = { key };
        }
        return this._api.put('/users/onboarding', data).then(r => r.data);
    }

    async getUploadCaptionUrl(roomId, language) {
        return this._api.post(`/rooms/${roomId}/captions`, { language }).then(r => r.data);
    }

    async uploadCaption(roomId, captionFile, language) {
        const formData = new FormData();
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        formData.append('file', captionFile);
        formData.append('language', language);
        return this._api.post(`/rooms/${roomId}/captions/upload`, formData, config).then(r => r.data);
    }

    async updateCaption(roomId, captionId, captionFile) {
        const formData = new FormData();
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        formData.append('file', captionFile);
        return this._api.patch(`/rooms/${roomId}/captions/${captionId}/upload`, formData, config).then(r => r.data);
    }

    async getCaptions(roomId) {
        return this._api.get(`/rooms/${roomId}/captions`).then(r => r.data);
    }

    async removeCaption(roomId, captionId) {
        return this._api.delete(`/rooms/${roomId}/captions/${captionId}`).then(r => r.data);
    }

    async getCaptionDownloadUrl(roomId, captionId) {
        return this._api.get(`/rooms/${roomId}/captions/${captionId}/export`).then(r => r.data);
    }

    getWatchedVideosProgress(idsList = []) {
        return this._api.post('/rooms/progress', { ids_list: idsList }).then(r => r.data);
    }

    listTutorials() {
        return this._api.get('/tutorials/organizer').then(r => r.data.tutorials);
    }

    videoTutorials(tutorialId) {
        return this._api.get(`/tutorials/${tutorialId}`).then(r => r.data);
    }

    getRoomStatus(roomId) {
        return this._api.get(`/rooms/${roomId}/status`).then(r => r.data);
    }
}

const api = new Api();

export default api;
