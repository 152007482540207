import { all, takeLatest, put } from 'redux-saga/effects';
import { translate } from '@utils/functions';
import api from '@services/api';
import history from '@services/history';
import { changePasswordSuccess, changePasswordFailed } from './actions';

function* changePassword({ payload }) {
    const { email, voucherId } = payload;
    try {
        yield api.recoverPassword({ email });
        yield put(changePasswordSuccess());
        history.push(voucherId ? `/checkEmail/${voucherId}` : '/checkEmail');
    } catch (error) {
        let message = translate('ERROR_NONETWORK');
        if (error.response) {
            switch (error.response.status) {
            case 404:
                message = translate('ERROR_EMAILNOTFOUND');
                break;
            default:
                message = translate('ERROR_RECOVERPASSFAIL') + error.response.status;
            }
        }
        console.log('login error', error, message);
        yield put(changePasswordFailed(message));
    }
}

function* newPassword(action) {
    const { password, token } = action.payload;
    try {
        yield api.changePassword(password, token);
        yield put(changePasswordSuccess());
        history.push('/successNewPassword');
    } catch (error) {
        let message = translate('ERROR_NONETWORK');
        if (error.response) {
            switch (error.response.status) {
            case 401:
                message = translate('ERROR_CREDENTIALS');
                history.push('/failedNewPassword');
                break;
            default:
                message = translate('ERROR_RECOVERPASSFAIL') + error.response.status;
            }
        }
        console.log('login error', error, message);
        yield put(changePasswordFailed(message));
    }
}

export default all([
    takeLatest('CHANGE_PASSWORD_REQUEST', changePassword),
    takeLatest('NEW_PASSWORD_REQUEST', newPassword),

]);
