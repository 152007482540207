import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  height:calc(100% - 11%);
  width: 100%;
  position: relative;
  flex-flow: column nowrap;
  padding: 1rem;
  overflow: auto;

  @media(max-width: 600px) and (orientation: landscape) {
    height:calc(100% - 18%);
  }

  @media(max-width: 990px) and (orientation: landscape) {
    height:calc(100% - 18%);
  }
`;

export const Section = styled.div`
    display: flex;
    width: 100%;
    align-self: stretch;
    flex-wrap: wrap;
    min-height: 80px;
    
    h2 {
        font-size: 24px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.27px;
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
    }
    
    @media(max-width: 600px) {
      flex-direction: column;
      min-height: 150px;
    }
`;

export const LeftContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
`;

export const Button = styled.button`
  border: none;
  background: none;
  height: 100%;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }

  span {
    color: #FFF;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.21px;
    text-align: center;
  }
`;

export const ModalButton = styled.button`
  border: none;
  color: #FFF;
  background: linear-gradient(90deg,#FF438D 0%,#FFAC47 100%);
  width: 191px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-weight: 600;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #313030;
  padding: 20px 0;
  h2 {
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.16px;
    text-align: center;
    color: #313030;
    padding-bottom: 10px;
    text-transform: uppercase;
  }

  select {
    width: 266px;
    height: 41px;
    border: solid 1px #979797;
    option {
      width: 19px;
      height: 22px;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.18px;
      text-align: center;
      color: #313030;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
`;

export const TeamsList = styled.ul`
  padding: 50px 0 30px;
  display: flex;
  align-self: stretch;
  flex-wrap: wrap;
  margin: 0 -10px;
  
  @media(max-width: 500px) {
    flex-direction: column;
    margin: 0;
  }
  
  li {
    margin: 10px;
    border-radius: 8px;
    background-color: #555151;
    color: #FFF;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
    min-height: 150px;
    min-width: 205px;

    h2 {
      font-weight: 600;
      flex: 2;
      display: flex;
      align-items: center;
      padding: 8px 0;
    }

    div {
      flex: 4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px 0;
    }

    p {
      font-size: 18;
      font-weight: 600;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 33.3%;
  flex: 1;
  height: 80px;

  &:last-child {
    justify-content: flex-end;
  }

  &:nth-child(1) {
    justify-content: flex-start;
    p {
        margin-left: 20px;
        color: #FFF;
        font-size: 18px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.21px;
        text-align: center;
        
        span {
            font-weight: 600;
        }
    }
  }

  @media(max-width: 600px) {
    height: 50px;
    &:last-child {
      justify-content: center;
    }

    &:nth-child(1) {
      justify-content: center;
    }
  }
`;
