import React from 'react';

const SubMenuItem = ({
    title, icon, alt, className, dataTour, onClick,
}) => (
    <li key={dataTour}>
        <div
            role="button"
            tabIndex="0"
            className={className}
            onClick={onClick}
            data-tour={dataTour}
        >
            <img src={icon} alt={alt} />
            <p>{title}</p>
        </div>
    </li>
);
export default SubMenuItem;
