import React from 'react';
import { useTranslation } from 'react-i18next';
import networkFailed from '@src/assets/networkFailed.svg';
import languageConstants from '@src/assets/i18n/constants';

import { Container, Image, Problem, Message, ExitButton } from './styles';

const NetworkFailed = () => {
    const { t } = useTranslation();

    function returnHomePage() {
        window.location.replace('/organizer');
    }

    return (
        <Container>
            <Image src={networkFailed} alt="networkFailed" />
            <Problem>{t(languageConstants.NETWORK_FAILED)}</Problem>
            <Message>{t(languageConstants.NETWORK_FAILED_MESSAGE)}</Message>
            <ExitButton onClick={returnHomePage}>
                {t(languageConstants.BACK_INITIAL_PAGE)}
            </ExitButton>
        </Container>
    );
};

export default NetworkFailed;
