import React from 'react';
import { ButtonDefaultThemeContainer } from './styles';

const ButtonDefaultTheme = ({ onClick, children, dataTestId, disabled }) => (
    <ButtonDefaultThemeContainer disabled={disabled} onClick={onClick} data-testid={dataTestId}>
        {children}
    </ButtonDefaultThemeContainer>
);

export default ButtonDefaultTheme;
