import CONSTANTS from '../constants';

export default {
    [CONSTANTS.ACTIVE]: 'Ativo',
    [CONSTANTS.ACCEPTTERMS]: 'Eu aceito os termos de uso',
    [CONSTANTS.ALREADY_LOGED_IN]: 'Você já está logado em outra máquina.',
    [CONSTANTS.ANSWER_DIFFERENT_MANDATORY]: 'As respostas devem ser diferentes!',
    [CONSTANTS.ALL_ANSWERS]: 'TOTAL DE RESPOSTAS',
    [CONSTANTS.APP_LOGIN]: 'USAR O APP',
    [CONSTANTS.APPLY]: 'Aplicar',
    [CONSTANTS.ATTACHMENTS]: 'Anexos',
    [CONSTANTS.AT_LEAST_3_CHARS]: 'PELO MENOS 3 CARACTERES',
    [CONSTANTS.BACKTO]: 'Voltar para',
    [CONSTANTS.BACK_INITIAL_PAGE]: 'VOLTAR PARA PÁGINA INICIAL',
    [CONSTANTS.BACK_TO_GUEST_PRESENTER_LIST]: 'VOLTAR PARA A LISTA DE CONVIDADOS',
    [CONSTANTS.BITRATE]: 'Taxa de transmissão de bits',
    [CONSTANTS.BLOCK_USER_BY_CHAT]: 'Use o chat para bloquear um usuário.',
    [CONSTANTS.BUFFER_CLASSIFICATION]: 'Classificação do buffer',
    [CONSTANTS.BUFFER_CLASSIFICATION_ABREVIATION]: 'CLAS. DO BUFFER',
    [CONSTANTS.DISABLED_CAMERA]: 'Câmera Desativada',
    [CONSTANTS.CANCEL]: 'Cancelar',
    [CONSTANTS.CANT_GOLIVE_YET]: 'A sala ainda não está disponível, por favor aguarde',
    [CONSTANTS.CLICK_TO_ENDLIVE]: 'Clique para encerrar a live',
    [CONSTANTS.CLICK_TO_GOLIVE]: 'Clique para começar a live',
    [CONSTANTS.CHANGES_SUCCESS]: 'Alteração realizada com sucesso',
    [CONSTANTS.CHANGES_FAIL]: 'Falha ao realizar mudanças',
    [CONSTANTS.CHANNEL_ACCEPTED_FORMATS]: 'Formatos aceitos',
    [CONSTANTS.CHANNEL_ADDNEWVIDEO]: 'Novo vídeo',
    [CONSTANTS.CHANNEL_ADDNEWLIVEVIDEO]: 'Ao vivo | Live',
    [CONSTANTS.CHANNEL_ADDNEWRECORDEDVIDEO]: 'Upload de vídeo',
    [CONSTANTS.CHANNEL_CLEANVIDEO]: 'Limpar vídeo',
    [CONSTANTS.CHANNEL_COPIED]: 'Copiado',
    [CONSTANTS.CHANNEL_CREATEVIDEO]: 'Criar vídeo',
    [CONSTANTS.CHANNEL_CREATEVIDEO2]: 'Criar novo vídeo',
    [CONSTANTS.CHANNEL_CHAT_ENABLED]: 'CHAT',
    [CONSTANTS.CHANNEL_EDITVIDEO]: 'Editar vídeo',
    [CONSTANTS.CHANNEL_ENDDATE]: 'DATA E HORA DE TÉRMINO',
    [CONSTANTS.CHANNEL_ENDLIVE]: 'End live!',
    [CONSTANTS.CHANNEL_ENDLIVEDESCRIPTION]: 'Deseja realmente encerrar a live?',
    [CONSTANTS.CHANNEL_ENDLIVEIRREVERSIBLE]: 'Esta operação não poderá ser desfeita',
    [CONSTANTS.CHANNEL_FAILREQUESTROOM]: 'Falha ao solicitar sala',
    [CONSTANTS.CHANNEL_FAILREQUESTROOMS]: 'Falha ao solicitar salas',
    [CONSTANTS.CHANNEL_FILES]: 'Arquivo',
    [CONSTANTS.CHANNEL_FILEFORMAT]: 'Os arquivos devem possuir os seguintes formatos: PPT, PPTX, DOC, DOCX, PDF, JPG, PNG',
    [CONSTANTS.CHANNEL_FILES]: 'Arquivo',
    [CONSTANTS.CHANNEL_FILEFORMAT]: 'Os arquivos devem possuir os seguintes formatos: PPT, PPTX, DOC, DOCX, PDF, JPG, PNG',
    [CONSTANTS.CHANNEL_GOLIVE]: 'Go live!',
    [CONSTANTS.CHANNEL_GOLIVE_UNAVAILABLE]: 'Go live indisponível!',
    [CONSTANTS.CHANNEL_MYVIDEOS]: 'Meus vídeos',
    [CONSTANTS.CHANNEL_NEWVIDEO]: 'Novo Vídeo',
    [CONSTANTS.CHANNEL_NOVIDEO]: 'Nenhum vídeo cadastrado no momento',
    [CONSTANTS.CHANNEL_PRIMARYURL]: 'URL PRIMÁRIA',
    [CONSTANTS.CHANNEL_PRIMARY_PASSWORD]: 'SENHA DA URL PRIMÁRIA',
    [CONSTANTS.CHANNEL_SECONDARY_PASSWORD]: 'SENHA DA URL SECUNDÁRIA',
    [CONSTANTS.CHANNEL_PRODUCER_LINK]: 'Link do produtor',
    [CONSTANTS.CHANNEL_PRODUCER_BACKUP_LINK]: 'Link backup do produtor',
    [CONSTANTS.CHANNEL_ROOMNOTFOUND]: 'Sala não encontrada',
    [CONSTANTS.CHANNEL_ROOM_LOAD_DATA_ERROR]: 'Houve um erro ao carregar os dados',
    [CONSTANTS.CHANNEL_SECONDARYURL]: 'URL SECUNDÁRIA',
    [CONSTANTS.CHANNEL_SELECTCOURSE]: 'O VÍDEO PERTENCE AO EVENTO',
    [CONSTANTS.CHANNEL_SELECTTIMEZONE]: 'SELECIONE O FUSO HORÁRIO',
    [CONSTANTS.CHANNEL_SELECTCOURSEPLACEHOLDER]: 'Selecione um curso',
    [CONSTANTS.CHANNEL_SENDVIDEO]: 'Enviar vídeo',
    [CONSTANTS.CHANNEL_STARTDATE]: 'DATA E HORA DE EXIBIÇÃO',
    [CONSTANTS.CHANNEL_THUMBNAIL]: 'Thumbnail',
    [CONSTANTS.CHANNEL_THUMBNAILSIZE]: 'Tamanho mínimo 300x300',
    [CONSTANTS.CHANNEL_UPLOADFILES]: 'UPLOAD DE ANEXOS',
    [CONSTANTS.CHANNEL_UPLOADVIDEO]: 'UPLOAD DE VÍDEO',
    [CONSTANTS.CHANNEL_UPLOADSPREADSHEET]: 'UPLOAD DE PLANILHA',
    [CONSTANTS.CHANNEL_VIDEOCOVER]: 'CAPA DO VÍDEO',
    [CONSTANTS.CHANNEL_VIDEODESCRIPTION]: 'DESCRIÇÃO DO VÍDEO',
    [CONSTANTS.CHANNEL_VIDEOFORMAT]: 'O vídeo deve ter os seguintes formatos: MP4, MKV',
    [CONSTANTS.CHANNEL_VIDEOTITLE]: 'TÍTULO DO VÍDEO',
    [CONSTANTS.VIDEO_STATS]: 'Estatísticas dos vídeos ',
    [CONSTANTS.CHAT_INTERACTION]: 'INTERAÇÃO NO CHAT',
    [CONSTANTS.CHAT_INTERACTION_RULES]: 'A regra de interação no chat refere-se à pontuação ganha pelo participante ao realizar sua primeira interação no chat da live. Lembre-se, a pontuação é exclusivamente para a primeira interação.',
    [CONSTANTS.CHUNK_UPLOAD_FAILURE]: 'Falha ao realizar upload para [ROOM_NAME]. Data: [ERROR_DATA]',
    [CONSTANTS.COMEBACK]: 'Voltar',
    [CONSTANTS.CONFIRM]: 'Confirmar',
    [CONSTANTS.CONTENT_DESCRIPTION_PLACEHOLDER]: 'Escreva aqui a descrição do seu conteúdo',
    [CONSTANTS.CORRECT_ANSWER_MANDATORY]: 'O Campo "RESPOSTA CORRETA" é obrigatório!',
    [CONSTANTS.CREATE_ROOM_SUCCESS]: 'Vídeo criado com sucesso',
    [CONSTANTS.DISABLE_QUESTION]: 'DESABILITAR?',
    [CONSTANTS.DISABLED_CAPS]: 'DESABILITADO',
    [CONSTANTS.DOUBTS]: 'Dúvidas',
    [CONSTANTS.DOWNLOAD_ATTACHMENTS]: 'BAIXAR ANEXOS',
    [CONSTANTS.DURATION_MANDATORY]: 'O Campo "DURAÇÃO" é obrigatório!',
    [CONSTANTS.DURATION_NUMERIC]: 'O Campo "DURAÇÃO" deve ser númerico!',
    [CONSTANTS.EDIT]: 'Editar',
    [CONSTANTS.EDIT_CONTENT]: 'Editar Conteúdo',
    [CONSTANTS.VIEW_CONTENT]: 'Visualizar Conteúdo',
    [CONSTANTS.EDIT_ROOM_FAIL]: 'Falha ao editar a sala',
    [CONSTANTS.EDIT_VIDEO_COVER]: 'EDITAR CAPA',
    [CONSTANTS.ENABLE_QUESTION]: 'HABILITAR?',
    [CONSTANTS.ENABLED]: 'HABILITADA',
    [CONSTANTS.ENABLED_CAPS]: 'HABILITADO',
    [CONSTANTS.ENROLLED]: 'Matriculado',
    [CONSTANTS.ENDDATE]: 'Fim',
    [CONSTANTS.ENGLISH]: 'Inglês',
    [CONSTANTS.EMAIL]: 'E-MAIL',
    [CONSTANTS.ENTER_TITLE]: 'Digite o Título',
    [CONSTANTS.ERROR404]: 'Erro 404',
    [CONSTANTS.ERROR_CHANGEPASSWORD]: 'Falha ao alterar senha, status de erro: ',
    [CONSTANTS.ERROR_CREDENTIALS]: 'Credenciais de acesso inválida, expirada ou inexistente',
    [CONSTANTS.ERROR_PARAMETERS]: 'Um ou mais parâmetros de entrada estão inválidos.',
    [CONSTANTS.ERROR_DEFAULT]: 'Erro inesperado do servidor',
    [CONSTANTS.ERROR_EMAILNOTFOUND]: 'E-mail não encontrado',
    [CONSTANTS.ERROR_LOGIN]: 'Login ou senha incorretos',
    [CONSTANTS.ERROR_LOGINFAIL]: 'Falha ao realizar login, status de erro:',
    [CONSTANTS.ERROR_NONETWORK]: 'Sem acesso a internet',
    [CONSTANTS.ERROR_RECOVERPASSFAIL]: 'Falha ao restaurar senha, status de erro: ',
    [CONSTANTS.FAIL_CHANGELANGUAGE]: 'Falha ao realizar mudança de linguagem',
    [CONSTANTS.FAIL_CHANGEOFFERSTATUS]: 'Falha ao alterar status das ofertas',
    [CONSTANTS.FAIL_CHATNOTFOUND]: 'Sala de Chat não encontrado',
    [CONSTANTS.FAIL_CONNECTWEBSOCKET]: 'Falha ao conectar com websocket',
    [CONSTANTS.FAIL_CREATEOFFER]: 'Falha ao criar oferta',
    [CONSTANTS.FAIL_CREATETEAMS]: 'Falha ao criar equipes',
    [CONSTANTS.FAIL_ENTERROOM]: 'Falha ao entrar na sala',
    [CONSTANTS.FAIL_EXITROOM]: 'Falha ao sair da sala',
    [CONSTANTS.FAIL_GETCHAT]: 'Sala de chat não encontrada',
    [CONSTANTS.FAIL_GETOFFER]: 'Falha ao solicitar oferta',
    [CONSTANTS.FAIL_GETTEAMSSTATUS]: 'Falha ao solicitar status das equipes',
    [CONSTANTS.FAIL_GETTEAMS]: 'Falha ao solicitar equipes',
    [CONSTANTS.FAIL_LOGOUT]: 'Falha ao realizar logout',
    [CONSTANTS.FAIL_PINMESSAGE]: 'Falha ao fixar mensagem',
    [CONSTANTS.FAIL_SETTEAMSSTATUS]: 'Falha ao alterar status das equipes',
    [CONSTANTS.FAIL_UNPINMESSAGE]: 'Falha ao desafixar mensagem',
    [CONSTANTS.FILL_FIELDS_ADD_USER]: 'Preencha todos os campos para adicionar o usuário',
    [CONSTANTS.FILTER_FIRST]: 'Selecione o primeiro filtro',
    [CONSTANTS.FILTER_SECOND]: 'Selecione o segundo filtro',
    [CONSTANTS.FILTER_SPECIFIC]: 'Período de tempo',
    [CONSTANTS.FILTER_PRESETOPTIONS]: ['1 minuto', '5 minutos', '30 minutos', '1 hora', '3 horas', '6 horas', '24 horas', '3 dias', '7 dias'],
    [CONSTANTS.FILTER_TIME]: 'Selecione o tempo',
    [CONSTANTS.FILTER_NAME_ID]: 'FILTRAR NOME OU ID',
    [CONSTANTS.FLOW_PRIMARY]: 'Fluxo Primário',
    [CONSTANTS.FLOW_SECONDARY]: 'Fluxo Secundário',
    [CONSTANTS.FORGETPASSWORD]: 'ESQUECI MINHA SENHA',
    [CONSTANTS.FROM_TIME]: 'De',
    [CONSTANTS.FULL_SCREEN]: 'Tela Cheia',
    [CONSTANTS.GAMIFICATION]: 'Gamificação',
    [CONSTANTS.GAMIFICATION_DISABLED]: 'Desabilitada',
    [CONSTANTS.GAMIFICATION_DOWNLOAD_RULES]: 'A regra de baixar anexos refere-se à pontuação ganha pelo participante ao realizar download de cada anexo do vídeo.',
    [CONSTANTS.GAMIFICATION_PUNCTUALITY_MODE_RULE]: `Modo simples: O participante ganha a pontuação ao entrar durante o tempo de tolerância. Após o tempo de tolerância, o participante não ganha nenhum ponto.
    Modo gradual: O participante ganha a pontuação máxima ao entrar na hora exata do inicio da live. Ao entrar na live após transcorrer o tempo de tolerância o participante não ganhará a pontuação máxima.
    Cálculo de granulosidade: Pontos máximos/tempo de tolerância (em minutos)`,
    [CONSTANTS.GAMIFICATION_ACCESS_ROOM_RULE]: `A regra de pontualidade é voltada para os participantes que entram na hora em que a live inicia ou durante o tempo de tolerância.
    A pontualidade pode ser medida através do modo simples ou gradual.`,
    [CONSTANTS.GAMIFICATION_LOGIN_APP_RULES]: 'A regra de Logar no Aplicativo refere-se à pontuação ganha pelo participante ao realizar login no aplicativo do Kopa.',
    [CONSTANTS.GAMIFICATION_POINTS_NUMERIC]: 'Informe valores numéricos',
    [CONSTANTS.GAMIFICATION_QUIZZ_RULE]: `Quiz: Refere-se aos pontos que o participante obtém ao acertar a resposta correta de um quiz durante a live.
    Pesquisas: Refere-se aos pontos que o participante obtém simplesmente por responder uma pesquisa durante a live.`,
    [CONSTANTS.GAMIFICATION_SETTINGS]: 'Configurações da gamificação',
    [CONSTANTS.GAMIFICATION_SETTINGS_QUIZZ_TIP_A]: 'A configuração de pontos da gamificação de',
    [CONSTANTS.GAMIFICATION_SETTINGS_QUIZZ_TIP_B]: 'se encontra na tela de',
    [CONSTANTS.GAMIFICATION_RULES_REQUEST_FAIL]: 'Falha ao buscar as regras da gamificação',
    [CONSTANTS.GENERAL]: 'Geral',
    [CONSTANTS.GEOLOCATION]: 'Geolocalização',
    [CONSTANTS.GRADUAL_MODE]: 'MODO GRADUAL',
    [CONSTANTS.GRAPH_AVG_VIEWBITRATE]: 'Média de taxa de bits',
    [CONSTANTS.GRAPH_AVG_VIEWDOWNSTREAMBANDWIDTH]: 'Largura de banda de downstream',
    [CONSTANTS.GRAPH_AVG_VIEWDROPPEDFRAMESRATIO]: 'Média de frames perdido',
    [CONSTANTS.GRAPH_AVG_VIEWLIVELATENCY]: 'Latência média',
    [CONSTANTS.GRAPH_SUMVIEWTIME]: 'Somatório de tempo de visualização',
    [CONSTANTS.GRAPH_VIEWERS]: 'Usuários',
    [CONSTANTS.GRAPH_VIEWBUFFERTIMERATIO]: 'Tempo médio de buffer',
    [CONSTANTS.GRAPH_VIEWERSBUFFERING]: 'Buffering de usuários',
    [CONSTANTS.GRAPH_VIEWERSENGAGEMENT]: 'Engajamento de usuários',
    [CONSTANTS.GRAPH_VIEWERSDVR]: 'Usuários DVR',
    [CONSTANTS.HOME]: 'Início',
    [CONSTANTS.HOMEPAGE]: 'Página Inicial',
    [CONSTANTS.HOME_WELCOME]: 'É UM PRAZER TER VOCÊ AQUI CONOSCO',
    [CONSTANTS.HOME_NOLIVE]: 'Desculpe, no momento não temos nenhuma live em andamento.',
    [CONSTANTS.HOURS]: 'Horas',
    [CONSTANTS.INACTIVE]: 'Inativo',
    [CONSTANTS.INVALID_IMG_TYPE]: 'Tipo inválido. O arquivo deve ser uma imagem.',
    [CONSTANTS.INITIAL_DATE_CANT_CHANGE]: 'A data de início desta sala não pode ser alterada.',
    [CONSTANTS.INSERT_VIDEO_THUMBNAIL]: 'Insira a thumbnail do vídeo!',
    [CONSTANTS.FINISHED]: 'Encerrado',
    [CONSTANTS.LANGUAGE]: 'Idioma',
    [CONSTANTS.LAST]: 'Ultimo(s)',
    [CONSTANTS.LIVE]: 'Ao vivo',
    [CONSTANTS.LIVE_UNAVAILABLE]: 'Live indisponível',
    [CONSTANTS.LIVE_ENDED]: 'Live encerrada',
    [CONSTANTS.RECORDED]: 'Gravado',
    [CONSTANTS.LOADING]: 'Carregando',
    [CONSTANTS.LOADINGCAPS]: 'CARREGANDO',
    [CONSTANTS.LOADINGMESSAGELINK]: 'Aguarde enquanto os links de transmissão são criados...',
    [CONSTANTS.LOADINGPDF]: 'Carregando PDF',
    [CONSTANTS.LOGIN]: 'Login',
    [CONSTANTS.LOGIN_EMAIL]: 'EMAIL',
    [CONSTANTS.LOGIN_PASSWORD]: 'SENHA',
    [CONSTANTS.LOGIN_SIGNIN]: 'ENTRAR',
    [CONSTANTS.MAX_SCORE]: 'PONTUAÇÃO MÁXIMA',
    [CONSTANTS.MIN_SCORE]: 'Pontuação mínima: 10',
    [CONSTANTS.MAXIMUM_IMAGE_SIZE]: 'Tamanho máximo da imagem: 300x300',
    [CONSTANTS.MINIMUM_IMAGE_SIZE]: 'Tamanho mínimo: 300x300',
    [CONSTANTS.MESSAGE]: 'Mensagem',
    [CONSTANTS.MINUTES]: 'MINUTOS',
    [CONSTANTS.MUTED_PARTICIPANT_ERROR]: 'Falha ao tentar mutar o participante',
    [CONSTANTS.MUTED_USER]: 'Usuário silenciado',
    [CONSTANTS.HOUR_ERROR_MESSAGE]: 'A HORA de término deve ser maior do que a HORA atual.',
    [CONSTANTS.MONITORS]: 'Monitores',
    [CONSTANTS.NAMECAPS]: 'NOME',
    [CONSTANTS.NO]: 'Não',
    [CONSTANTS.NO_USER_REGISTERED_CHECK_XLSX]: 'NENHUM USUÁRIO FOI REGISTRADO! VERIFIQUE SEU ARQUIVO DE PLANILHA.',
    [CONSTANTS.NO_USERS_SHOW]: 'Nenhum usuário para exibir',
    [CONSTANTS.NO_QUIZ_SURVEY_REGISTERED]: 'NENHUM QUIZ OU PESQUISA CADASTRADOS',
    [CONSTANTS.NO_QUIZ_SURVEY_REGISTERED_MESSAGE]: 'Ainda não cadastrou nenhum quiz ou pesquisa? É simples, fácil e só leva alguns minutos.',
    [CONSTANTS.NO_PARTICIPANT_REPORT]: 'Não há participantes no relatório de presença',
    [CONSTANTS.LIVE_ENDED]: 'Live encerrada',
    [CONSTANTS.NEXT_STEP]: 'Próximo passo',
    [CONSTANTS.NEXTPAGE]: 'Próxima página',
    [CONSTANTS.NO_USER_BLOCKED]: 'NENHUM USUÁRIO BLOQUEADO',
    [CONSTANTS.NO_USER_CONNECTED]: 'NENHUM USUÁRIO CONECTADO',
    [CONSTANTS.NO_USER_FOUND]: 'NENHUM USUÁRIO ENCONTRADO',
    [CONSTANTS.NO_USER_FOUND_MESSAGE]: 'Desculpa! Não encontramos o usuário que você buscava.',
    [CONSTANTS.NO_USERS_ROOM]: 'Nenhum usuário na sala',
    [CONSTANTS.OF]: 'de',
    [CONSTANTS.OFFERS]: 'QR CODE',
    [CONSTANTS.OFFERS_ACTIVATEOFFERS]: 'Ativar QR CODE',
    [CONSTANTS.OFFERS_BASEURL]: 'Insira a url template',
    [CONSTANTS.OFFERS_CREATEOFFER]: 'Criar QR CODE',
    [CONSTANTS.OFFERS_EDITOFFER]: 'Editar QR CODE',
    [CONSTANTS.OFFERS_ENABLECONFIRM]: 'Deseja ativar O QR CODE?',
    [CONSTANTS.OFFERS_MODEOFFERSINACTIVATE]: 'Os QR CODEs estão desativadas',
    [CONSTANTS.OFFERS_NOOFFERS]: 'Nenhum QR CODE cadastrado',
    [CONSTANTS.OFFERS_SALESFORCEID]: 'Salesforce Id',
    [CONSTANTS.OFFERS_SELECTOR]: 'Selecione o tipo de ID do template',
    [CONSTANTS.OFFERS_TEMPLATEURL]: 'Template url',
    [CONSTANTS.OFFERS_TYPEID]: 'Tipo de Id',
    [CONSTANTS.OFFERS_WARN]: 'Atenção: O QR CODE só está disponível para conteúdos/vídeos do tipo LIVE',
    [CONSTANTS.OPS]: 'Ops!',
    [CONSTANTS.PARTICIPANT_WITHOUT_GAMIFICATION]: 'No momento nenhum participante gerou pontos de gamificação.',
    [CONSTANTS.PARTICIPANT_NOT_FOUND]: 'NENHUM PARTICIPANTE ENCONTRADO',
    [CONSTANTS.PARTICIPANT_NOT_FOUND_MESSAGE]: 'Desculpa! Não encontramos o participante que você buscava.',
    [CONSTANTS.PARTICIPANT_OUT_LIST]: 'No momento o participante procurado não consta na lista de presença dessa sala.',
    [CONSTANTS.PERMISSION_ENTERCHAT]: 'Você não tem permissão para entrar nessa sala de chat',
    [CONSTANTS.PERMISSION_PINMESSAGE]: 'Você não tem permissão para fixar mensagem nessa sala de chat',
    [CONSTANTS.PERMISSION_UNPINMESSAGE]: 'Você não tem permissão para desafixar mensagem nessa sala de chat',
    [CONSTANTS.PUNCTUALITY]: 'PONTUALIDADE',
    [CONSTANTS.PUNCTUALITY_MODE]: 'MODO DE PONTUALIDADE',
    [CONSTANTS.QUESTION_MANDATORY]: 'O Campo "PERGUNTA" é obrigatório!',
    [CONSTANTS.TYPE_QUESTION]: 'Digite sua pergunta',
    [CONSTANTS.TITLE_MANDATORY]: 'O Campo "TÍTULO" é obrigatório!',
    [CONSTANTS.TWO_ITEMS_MANDATORY]: 'Preencha pelo menos dois Itens!',
    [CONSTANTS.VALID_DATE]: 'Insira uma data válida',
    [CONSTANTS.VALID_EMAIL]: 'Insira um email válido',
    [CONSTANTS.VALID_PHONE]: 'Insira um telefone válido',
    [CONSTANTS.VOUCHER_LOGIN]: 'Cadastre-se com seu voucher',
    [CONSTANTS.VOUCHER_PASSWORD_CREATION_TITLE]: 'CRIAÇÃO DE SENHA',
    [CONSTANTS.VOUCHER_PASSWORD_LABEL]: 'SENHA',
    [CONSTANTS.VOUCHER_PASSWORD_CONFIRM_LABEL]: 'CONFIRMAR SENHA',
    [CONSTANTS.VOUCHER_PASSWORD_SAME]: 'As senhas precisam ser iguais',
    [CONSTANTS.VOUCHER_PASSWORD_MIN]: 'A senha deve ter no mínimo 6 caracteres',
    [CONSTANTS.VOUCHER_FINALIZE_REGISTRATION]: 'Finalizar cadastro',
    [CONSTANTS.VOUCHER_CODE_TITLE]: 'CÓDIGO DO VOUCHER',
    [CONSTANTS.VOUCHER_CODE_SUBTITLE]: 'Insira aqui o código do seu voucher',
    [CONSTANTS.VOUCHER_BUTTON_SUBMIT]: 'VALIDAR VOUCHER',
    [CONSTANTS.VOUCHER_REGISTER_TITLE]: 'CADASTRO REALIZADO COM SUCESSO',
    [CONSTANTS.VOUCHER_REGISTER_MESSAGE_SUCCESS]: 'Seu cadastro foi realizado com sucesso, agora vamos te redirecionar para a home',
    [CONSTANTS.VOUCHER_TERMS]: ['O Usuário está ciente de que fornece informação de forma consciente e voluntária por meio de [FORMULÁRIO/ETC], ou por meio dos sites operados pela LBCA.',
        'O Usuário está ciente de que fornece informação de forma consciente e voluntária por meio de [FORMULÁRIO/ETC], ou por meio dos sites operados pela LBCA.'],
    [CONSTANTS.VOUCHER_TERMS_ACEPPT]: 'ACEITO OS TERMOS DE USO',
    [CONSTANTS.VOUCHER_TERMS_VALIDATION]: 'Aceite os termos para prosseguir',
    [CONSTANTS.VOUCHER_TERMS_TITLE]: 'TERMOS DE USO',
    [CONSTANTS.VOUCHER_ACEPPT_TERMS_PRIVACY_PT1]: 'ACEITO A ',
    [CONSTANTS.VOUCHER_ACEPPT_TERMS_PRIVACY_PT2]: 'POLÍTICA DE PRIVACIDADE',
    [CONSTANTS.VOUCHER_NEXT_STEP_BUTTON]: 'Próximo passo',

    [CONSTANTS.VOUCHER_USERDATA_TITLE]: 'DADOS PESSOAIS',
    [CONSTANTS.VOUCHER_USERDATA_FILL]: 'Preencha todos os dados',
    [CONSTANTS.VOUCHER_USERDATA_FULL_NAME]: 'NOME COMPLETO',
    [CONSTANTS.VOUCHER_USERDATA_FULL_NAME_CC]: 'Nome Completo',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL]: 'E-MAIL',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL_CONFIRM]: 'CONFIRMAR E-MAIL',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL_DIFFERS]: 'Endereços de email não são o mesmo',
    [CONSTANTS.VOUCHER_USERDATA_DATE_OF_BIRTH]: 'DATA DE NASCIMENTO',
    [CONSTANTS.VOUCHER_USERDATA_GENDER]: 'SEXO',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_SELECT]: 'Selecione o gênero',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_MALE]: 'Masculino',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_FEM]: 'Feminino',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_OTHER]: 'Outro',
    [CONSTANTS.VOUCHER_USERDATA_INVALID_DATE]: 'Data de nascimento inválida',
    [CONSTANTS.VOUCHER_USERDATA_PHONE]: 'TELEFONE',
    [CONSTANTS.VOUCHER_USERDATA_PHONE_PATTERN]: 'Utilize apenas números no campo de telefone',
    [CONSTANTS.VOUCHER_USERDATA_TYPE_FULL_NAME]: 'Nome completo do usuário',

    [CONSTANTS.PAGE]: 'Página',
    [CONSTANTS.PLEASECLOSE]: 'Por favor, feche',
    [CONSTANTS.PORTUGUESE]: 'Português',
    [CONSTANTS.PRESET]: 'Predefinido',
    [CONSTANTS.PREVIOUSPAGE]: 'Página anterior',
    [CONSTANTS.RECOVERPASSWORD_MINLENGTH]: 'A senha deve ter no mínimo 8 dígitos',
    [CONSTANTS.RECOVERPASSWORD_NEWPASSWORD]: 'NOVA SENHA',
    [CONSTANTS.RECOVERPASSWORD_NEWPASSWORDREPEAT]: 'REPETIR NOVA SENHA',
    [CONSTANTS.RECOVERPASSWORD_NOLINK]: 'O link não está mais disponível',
    [CONSTANTS.RECOVERPASSWORD_NOMATCH]: 'As senhas não conferem',
    [CONSTANTS.RECOVERPASSWORD_REDEFINE]: 'REDEFINIR SENHA',
    [CONSTANTS.RECOVERPASSWORD_SUCCESS]: 'Senha redefinida com sucesso',
    [CONSTANTS.RECOVERPASSWORD_VERIFY]: 'VERIFIQUE SEU E-MAIL',
    [CONSTANTS.RECOVERPASSWORD_VERIFYDESC]: 'Um e-mail para que você realize a troca de senha, será enviada para a sua caixa de e-mail.',
    [CONSTANTS.REGISTER_QUIZ_SURVEY]: 'CADASTRAR QUIZ OU PESQUISA',
    [CONSTANTS.REQUIRED_FIELD]: 'CAMPO OBRIGATÓRIO',
    [CONSTANTS.SAVE_GAMIFICATION_RULES_SUCCESS]: 'As regras foram salvas com sucesso',
    [CONSTANTS.SAVE_GAMIFICATION_RULES_FAIL]: 'Ocorreu um erro ao salvar as regras',
    [CONSTANTS.SEARCH]: 'Pesquisar',
    [CONSTANTS.SEARCH_MIN_CHARACTERS]: 'Informe no minimo 3 caracteres para a pesquisa',
    [CONSTANTS.SEARCH_NOT_FOUND]: 'Nenhuma entrada para mostrar',
    [CONSTANTS.SELECTQUESTION]: 'Selecione uma pergunta',
    [CONSTANTS.SEND]: 'Enviar',
    [CONSTANTS.SIMPLE_MODE]: 'MODO SIMPLES',
    [CONSTANTS.SEVERITY_BAD]: 'Ruim',
    [CONSTANTS.SEVERITY_GOOD]: 'Bom',
    [CONSTANTS.SEVERITY_WARN]: 'Aviso',
    [CONSTANTS.SORRYPROBLEM]: 'Desculpa, tivemos um problema.',
    [CONSTANTS.SPANISH]: 'Espanhol',
    [CONSTANTS.STARTDATE]: 'Início',
    [CONSTANTS.STATUS]: 'Status',
    [CONSTANTS.STATUS_LIVE]: 'Status da live',
    [CONSTANTS.SUCCESS]: 'SUCESSO',
    [CONSTANTS.TEAM]: 'Equipe',
    [CONSTANTS.TEAMS]: 'Equipes',
    [CONSTANTS.TEAMS_ACTIVATETEAMS]: 'Ativar equipes',
    [CONSTANTS.TEAMS_CREATETEAMS]: 'Criar equipes',
    [CONSTANTS.TEAMS_CREATETEAMSDESC]: 'Crie equipes e deixe a experiência dos participantes ainda mais extraordinária',
    [CONSTANTS.TEAMS_GENERATETEAMS]: 'Gerar equipes',
    [CONSTANTS.TEAMS_MODETEAMS]: 'Modo equipe',
    [CONSTANTS.TEAMS_MODETEAMSINACTIVATE]: 'MODO EQUIPES DESATIVADO',
    [CONSTANTS.TEAMS_NOTEAMS]: 'Nenhuma equipe cadastrada no momento',
    [CONSTANTS.TEAMS_QUANTITY]: 'Quantidade de pessoas na equipe',
    [CONSTANTS.THE]: 'Os',
    [CONSTANTS.TOTIME]: 'Às',
    [CONSTANTS.TRY_AGAIN]: 'Tentar novamente',
    [CONSTANTS.TOTIME]: 'Às',
    [CONSTANTS.TOGGLE_GAMIFICATION_SUCCESS_ENABLED]: 'A gamificação foi habilidata',
    [CONSTANTS.TOGGLE_GAMIFICATION_SUCCESS_DISABLED]: 'A gamificação foi desabilitada',
    [CONSTANTS.TOGGLE_GAMIFICATION_FAIL]: 'Ocorreu um erro ao ativar a gamificação.',
    [CONSTANTS.TOLERANCY]: 'TOLERÂNCIA',
    [CONSTANTS.UNMUTED_PARTICIPANT_ERROR]: 'Falha ao desmutar o participante',
    [CONSTANTS.UPLOAD_ERROR_MESSAGE]: 'Formato de arquivo não suportado',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE]: 'Solte a imagem aqui',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE_MULTIPLE]: 'Solte o(s) arquivo(s) aqui',
    [CONSTANTS.UPLOAD_VIDEO_SUCCESS]: 'Upload de vídeo concluído!',
    [CONSTANTS.UPLOAD_COVER_PLACEHOLDER]: 'Clique aqui ou arraste a imagem',
    [CONSTANTS.UPLOAD_VIDEO_PLACEHOLDER]: 'Clique aqui ou arraste o vídeo',
    [CONSTANTS.UPLOAD_VIDEO_FAILURE]: 'Upload de vídeo falhou!',
    [CONSTANTS.UPLOAD_VIDEO_PREPARING]: 'Preparando video.',
    [CONSTANTS.UPLOAD_VIDEO_PROGRESS]: 'PROGRESSO',
    [CONSTANTS.UPLOADING]: 'Enviando',
    [CONSTANTS.UPLOADING_ATTACHMENTS]: 'Enviando anexos',
    [CONSTANTS.CONVERTING]: 'Convertendo',
    [CONSTANTS.UNABLE_TO_EDIT_VIDEO]: 'O vídeo não pode ser editado no momento. Tente novamente em alguns instantes',
    [CONSTANTS.UNENROLL_USER]: 'CANCELAR MATRÍCULA',
    [CONSTANTS.UNENROLLED]: 'Cancelado',
    [CONSTANTS.UPLOAD_ERROR]: 'Erro!',
    [CONSTANTS.UPLOAD_VIDEO]: 'Upload de vídeo',
    [CONSTANTS.UPLOAD]: 'Upload',
    [CONSTANTS.USERS]: 'Usuários',
    [CONSTANTS.USER]: 'Usuário',
    [CONSTANTS.USERS_ACTIVE]: 'Usuários ativos',
    [CONSTANTS.USERS_ACTIVECAPS]: 'USUÁRIOS ATIVOS',
    [CONSTANTS.USERS_DISTRIBUTIONSHORT]: 'DIS. DE USUÁRIOS',
    [CONSTANTS.USERS_ENGAGEMENT]: 'Usuários engajados',
    [CONSTANTS.USERNAME_MIN]: 'O nome do usuário deve ser maior que 5 caracteres',
    [CONSTANTS.VIDEO]: 'Vídeo',
    [CONSTANTS.VIDEO_DESCRIPTION]: 'Descrição do Vídeo',
    [CONSTANTS.VIDEO_OFF]: 'Vídeo Desligado',
    [CONSTANTS.VIDEO_UNAVAILABLE]: 'Vídeo Indisponível',
    [CONSTANTS.VIDEO_TITLE_PLACEHOLDER]: 'Ex: Aula Interativa 01 ',
    [CONSTANTS.VOUCHER]: 'VOUCHER',
    [CONSTANTS.VOUCHER_EMAIL_VALIDATION]: 'VALIDAÇÃO DO E-MAIL',
    [CONSTANTS.VOUCHER_ENTER_FIELDS]: 'Preencha todos os valores',
    [CONSTANTS.VOUCHER_ERROR_COURSE]: 'Usuário já possui o curso',
    [CONSTANTS.VOUCHER_ERROR_VERIFY]: 'Falha ao verificar voucher',
    [CONSTANTS.VOUCHER_ERROR_USERCREATED]: 'Usuário já cadastrado',
    [CONSTANTS.VOUCHER_ERROR_INVALIDCODE]: 'Falha ao verificar código',
    [CONSTANTS.VOUCHER_ERROR_EXPIREDCODE]: 'Código expirado, reenviar código para email',
    [CONSTANTS.VOUCHER_ERROR_RESENDEMAIL]: 'Falha ao reenviar email',
    [CONSTANTS.VOUCHER_ERROR_CONFIRMPASSWORD]: 'Falha ao confirmar senha',
    [CONSTANTS.VOUCHER_ERROR_REGISTERDATA]: 'Falha ao cadastrar dados',
    [CONSTANTS.VOUCHER_ERROR_REGISTER]: 'Falha ao registrar voucher',
    [CONSTANTS.VOUCHER_NEW_VOUCHER]: 'Novo Voucher',
    [CONSTANTS.VOUCHER_INVALID]: 'Voucher inválido',
    [CONSTANTS.VOUCHER_VALIDATE]: 'Seu voucher com o código',
    [CONSTANTS.VOUCHER_VALIDATEPT2]: 'foi validado com sucesso',
    [CONSTANTS.VOUCHER_VALIDATE_CODE]: 'Validar voucher',
    [CONSTANTS.VOUCHER_VALIDATE_DENIED]: 'não foi validado com sucesso',
    [CONSTANTS.VOUCHER_VALIDATE_CODECAPS]: 'VALIDAÇÃO DO VOUCHER',
    [CONSTANTS.VOUCHER_VALIDATE_QUESTION]: 'Você realmente deseja validar o voucher',
    [CONSTANTS.VOUCHER_VALIDATE_QUESTIONPT2]: 'que libera o canal',
    [CONSTANTS.VOUCHER_REGISTERED]: 'Voucher cadastrado com sucesso',
    [CONSTANTS.VOUCHER_RESEND_EMAIL]: 'Reenviar código',
    [CONSTANTS.VOUCHER_RESENT_EMAIL]: 'Código reenviado',
    [CONSTANTS.VOUCHER_STEPS]: ['Termos de uso', 'Dados Pessoais', 'Validação', 'Criação de senha'],
    [CONSTANTS.VOUCHER_VERIFICATION_CODE]: 'INSIRA AQUI O CÓDIGO DE VERIFICAÇÃO QUE FOI ENCAMINHADO PARA O E-MAIL',
    [CONSTANTS.VOUCHER_WARNING_VERIFY]: 'O cupom não pôde ser verificado',
    [CONSTANTS.VOUCHER_YOUR_CODE]: 'CÓDIGO DO SEU VOUCHER',
    [CONSTANTS.WARNINGS_MESSAGES]: 'Muitas mensagens enviadas em um curto intervalo de tempo. Tentar novamente depois.',
    [CONSTANTS.WARNINGS_MESSAGES_FAIL]: 'Falha ao enviar mensagem',
    [CONSTANTS.WARNINGS_MESSAGES_NOTALLOWED]: 'Para a sua segurança não é permitido enviar links, números telefônicos e e-mails',
    [CONSTANTS.WARNINGS_MESSAGES_NOTFOUND]: 'Sala de chat não encontrada',
    [CONSTANTS.WARNINGS_MESSAGES_PERMISSIONS]: 'Você não tem permissão para enviar mensagem nessa sala de chat',
    [CONSTANTS.YES]: 'Sim',
    [CONSTANTS.ORGANIZER]: 'Organizador',
    [CONSTANTS.PARTICIPANT]: 'Participante',
    [CONSTANTS.ORGANIZER_DASHBOARD]: 'Organizador',
    [CONSTANTS.NOTIFICATIONS]: 'Notificações',
    [CONSTANTS.PITCH]: 'Pitch',
    [CONSTANTS.SETTINGS]: 'Configurações',
    [CONSTANTS.INFORMATION]: 'Informação',
    [CONSTANTS.INFORMATIONS]: 'Informações',
    [CONSTANTS.LOGOUT]: 'Cerrar sesión',
    [CONSTANTS.LOGOUT]: 'Sair',
    [CONSTANTS.CHANNEL]: 'Canal',
    [CONSTANTS.CHANNEL_HEADER]: 'CANAL ATUAL:',
    [CONSTANTS.CONTENT_TYPE_TO_ADD]: 'Que tipo de conteúdo você deseja adicionar?',
    [CONSTANTS.CONTENT]: 'Conteúdo ',
    [CONSTANTS.SAVE_CHANGES]: 'Salvar alterações',
    [CONSTANTS.CLOSE]: 'Fechar',
    [CONSTANTS.CHARACTERS]: 'Caracteres',
    [CONSTANTS.OPTIONAL]: 'Opcional',
    [CONSTANTS.WHAT_CHANNEL_LOOKING]: 'QUAL CANAL VOCÊ PROCURA?',
    [CONSTANTS.ROOM]: 'Sala',
    [CONSTANTS.TIME_WATCHED]: 'TEMPO ASSISTIDO',
    [CONSTANTS.USERS_WHO_WATCH_THE_VIDEO]: 'USUÁRIOS QUE ASSISTIRAM AO VÍDEO',

    [CONSTANTS.BUTTON_MESSAGE_YES]: 'SIM, EU DESEJO',
    [CONSTANTS.BUTTON_MESSAGE_NOT]: 'NÃO, NÃO DESEJO',
    [CONSTANTS.MODAL_DELETE_MESSAGE]: 'VOCÊ REALMENTE DESEJA EXCLUIR O USUÁRIO',
    [CONSTANTS.MODAL_UNSUBSCRIBE_MESSAGE]: 'VOCÊ REALMENTE DESEJA DESMATRICULAR O USUÁRIO',
    [CONSTANTS.ADD_NEW_USER]: 'ADICIONAR NOVO USUÁRIO',
    [CONSTANTS.ADD_NEW_USER]: 'ADICIONAR NOVO USUÁRIO',
    [CONSTANTS.EDIT_USER]: 'EDITAR USUÁRIO',
    [CONSTANTS.DELETE_USER]: 'EXCLUIR USUÁRIO',
    [CONSTANTS.UNSUBSCRIBE_USER]: 'DESMATRICULAR USUÁRIO',
    [CONSTANTS.PERMISSION_LEVEL]: 'Nível de permissão do usuário',
    [CONSTANTS.USERS_PER_PAGE]: 'Usuários por página',
    [CONSTANTS.PARTICIPANT]: 'Participante',
    [CONSTANTS.MONITOR]: 'Monitor',
    [CONSTANTS.DELETE]: 'Excluir',
    [CONSTANTS.NAME]: 'Nome',
    [CONSTANTS.PERMISSION]: 'Permissão',
    [CONSTANTS.NEW_USER]: 'NOVO USUÁRIO',
    [CONSTANTS.IMPORT_SPREADSHEET]: 'IMPORTAR PLANILHA',
    [CONSTANTS.GENDER]: 'Sexo',
    [CONSTANTS.DATE_OF_BIRTH]: 'Data de nascimento',
    [CONSTANTS.SAVE_USER]: 'SALVAR USUÁRIO',
    [CONSTANTS.MALE]: 'Masculino',
    [CONSTANTS.FEMALE]: 'Feminino',
    [CONSTANTS.PHONE]: 'Telefone',
    [CONSTANTS.PASSWORD]: 'Senha',
    [CONSTANTS.RESULTS]: 'Resultados encontrados',
    [CONSTANTS.PHONE_CONTACT]: 'Telefone para contato',
    [CONSTANTS.FAIL_EDIT_USER]: 'Não foi possível editar o usuário',
    [CONSTANTS.FAIL_DELETE_USER]: 'Não foi possível deletar o usuário',
    [CONSTANTS.FAIL_UNSUBSCRIBE_USER]: 'Não foi possível desmatricular o usuário',
    [CONSTANTS.FAIL_GET_USER]: 'Não foi possível listar os usuários',
    [CONSTANTS.FAIL_POST_USER]: 'Não foi possível salvar o usuário',
    [CONSTANTS.FAIL_GET_CHANNELS]: 'Falha em recuperar canais',
    [CONSTANTS.FAIL_ADD_USER]: 'Falha em adicionar usuário',
    [CONSTANTS.BUTTON_MESSAGE_YES]: 'SIM, EU DESEJO',
    [CONSTANTS.BUTTON_MESSAGE_NOT]: 'NÃO, NÃO DESEJO',
    [CONSTANTS.MODAL_DELETE_MESSAGE]: 'VOCÊ REALMENTE DESEJA EXCLUIR O USUÁRIO',
    [CONSTANTS.ADD_NEW_USER]: 'ADICIONAR NOVO USUÁRIO',
    [CONSTANTS.EDIT_USER]: 'EDITAR USUÁRIO',
    [CONSTANTS.DELETE_USER]: 'EXCLUIR USUÁRIO',
    [CONSTANTS.PERMISSION_LEVEL]: 'Nível de permissão do usuário',
    [CONSTANTS.USERS_PER_PAGE]: 'Usuários por página',
    [CONSTANTS.PARTICIPANT]: 'Participante',
    [CONSTANTS.MONITOR]: 'Monitor',
    [CONSTANTS.DELETE]: 'Excluir',
    [CONSTANTS.NAME]: 'Nome',
    [CONSTANTS.PERMISSION]: 'Permissão',
    [CONSTANTS.MORE_OPTIONS]: 'MAIS AÇÕES',
    [CONSTANTS.SPREADSHEET]: 'Planilha',
    [CONSTANTS.DOWNLOAD_SPREADSHEET]: 'Baixar Planilha',
    [CONSTANTS.DOWNLOAD_VIDEO_RECORDING]: 'BAIXAR VIDEO',
    [CONSTANTS.DOWNLOAD_VIDEO_FAIL]: 'Falha ao baixar video',
    [CONSTANTS.USERS_REPORT]: 'Relatório de usuários',
    [CONSTANTS.PREPARING_DOWNLOAD]: 'Preparando download...',
    [CONSTANTS.DONE]: 'Concluído',
    [CONSTANTS.ERROR]: 'Erro',
    [CONSTANTS.ERROR_LOAD_DATA]: 'Falha ao carregar dados!',
    [CONSTANTS.ERROR_ROOM_ID]: 'Você deve fornecer o "id da sala" para enviar estatísticas!',
    [CONSTANTS.COMPLETEDWITHERROR]: 'Erro nos cadastros',
    [CONSTANTS.USERSLIMITREACHED]: 'Limite de usuários atingido',
    [CONSTANTS.FILE]: 'Arquivo',
    [CONSTANTS.PROCESS_STATUS]: 'Processamento',
    [CONSTANTS.CREATION_DATE]: 'Data de criação',
    [CONSTANTS.REGISTRATION_ERRORS]: 'Erros de cadastro',
    [CONSTANTS.IMPORT]: 'Importar',
    [CONSTANTS.EXECUTE_IMPORT]: 'Fazer importação',
    [CONSTANTS.DOWNLOAD_SPREADSHEET_SAMPLE]: 'BAIXE AQUI UM MODELO DE PLANILHA PRONTO PARA IMPORTAÇÃO',
    [CONSTANTS.DRAG_AND_DROP_FILES]: 'Clique aqui ou arraste o arquivo',
    [CONSTANTS.IMPORT_LIST_DESCRIPTION]: 'Importe os seus usuários através de uma planilha',
    [CONSTANTS.IMPORT_LIST_TIP]: 'A PLANILHA DEVE POSSUIR OS SEGUINTES CAMPOS, COM A MESMA ORDENAÇÃO E FORMATAÇÃO:',
    [CONSTANTS.mas]: 'A planilha deve possuir os seguintes campos, com a mesma ordenação e formatação:',
    [CONSTANTS.OTHERS]: 'Outros',
    [CONSTANTS.OR]: 'Ou',
    [CONSTANTS.AND]: 'E',
    [CONSTANTS.XLSX_DETAILS]: 'Detalhes da',
    [CONSTANTS.IMPORTXLSXHEADER]: 'IMPORTE OS SEUS USUÁRIOS ATRAVÉS DE UMA PLANILHA',
    [CONSTANTS.XLSX_FORMAT_ERROR]: 'O FORMATO DO ARQUIVO QUE VOCÊ ADICIONOU É INCORRETO! ADICIONE UM ARQUIVO COM FORMATO XLSX.',
    [CONSTANTS.XLSX_SAMPLE_FAIL]: 'ERRO AO FAZER O DOWNLOAD DO ARQUIVO.',
    [CONSTANTS.XLSX_FORMAT_ERROR_MESSAGE]: 'Ainda não importou nenhuma planilha? É simples, fácil e só leva alguns minutos.',
    [CONSTANTS.XLSX_FORMAT_NO_IMPORT]: 'Não foi realizada nenhuma importação.',
    [CONSTANTS.INCORRECT_COLUMNS]: 'Colunas incorretas',
    [CONSTANTS.USERS_REGISTERED]: 'Usuários cadastrados com sucesso!',
    [CONSTANTS.USER_REGISTERED]: 'Usuário cadastrado com sucesso!',
    [CONSTANTS.USERS_NOT_REGISTERED]: 'Usuários não cadastrados',
    [CONSTANTS.BACK_TO]: 'Voltar para',
    [CONSTANTS.ERROR_IMAGE_UPLOAD]: 'FALHA AO FAZER UPLOAD DA IMAGEM',
    [CONSTANTS.SOCIAL_MEDIA]: 'REDES SOCIAIS',
    [CONSTANTS.PROFILE_UPDATED]: 'PERFIL ATUALIZADO',
    [CONSTANTS.PROFILE_UPDATE_FAILED]: 'FALHA NA ATUALIZAÇÃO DO PERFIL',
    [CONSTANTS.CROP_IMAGE]: 'CORTAR IMAGEM',
    [CONSTANTS.VIEW_PROFILE]: 'VISUALIZAR PERFIL',
    [CONSTANTS.GUEST_AVAILABLE]: 'CONVIDADO DISPONÍVEL',
    [CONSTANTS.GUEST_PRESENTER]: 'USUÁRIOS CONECTADOS',
    [CONSTANTS.SAVE]: 'SALVAR',
    [CONSTANTS.SUBS_LIMIT_REACHED]: 'Limite de usuários atingido',
    [CONSTANTS.WELCOME_TO_KOPA]: 'BEM-VINDO AO KOPA',
    [CONSTANTS.MY_PROFILE]: 'MEU PERFIL',
    [CONSTANTS.GUEST]: 'Convidado',
    [CONSTANTS.BUTTON_GUEST]: 'CONVIDADO',

    [CONSTANTS.REPORTS]: 'Relatórios',
    [CONSTANTS.PRESENCE_REPORT]: 'Relatório de Presença',
    [CONSTANTS.EXPORT]: 'EXPORTAR',
    [CONSTANTS.REFRESH]: 'ATUALIZAR',
    [CONSTANTS.LOAD_MORE_PAGINATION]: 'CARREGAR MAIS',
    [CONSTANTS.VIEW_REPORT]: 'Visualizar Relatório',
    [CONSTANTS.GET_PRESENCE_REPORT_FALURE]: 'Falha ao Buscar Relatório',
    [CONSTANTS.GET_COURSE_FAILURE]: 'Falha ao obter Canal ',
    [CONSTANTS.VIEW_REPORT_WARN]: 'Atenção: O relatório de presença só é disponível para conteúdos/vídeos do tipo LIVE',
    [CONSTANTS.EXPORT_REPORT_FAILURE]: 'Falha ao baixar relatório',
    [CONSTANTS.OF_VIEW]: 'de visualização',
    [CONSTANTS.VIEWING_TIME]: 'Tempo de visualização',

    [CONSTANTS.QUIZZ]: 'Quiz',
    [CONSTANTS.SURVEY]: 'Pesquisa',
    [CONSTANTS.SCORE]: 'PONTUAÇÃO',
    [CONSTANTS.SELECTED]: 'SELECIONARAM',
    [CONSTANTS.SURVEY_RIGHT_ANSWER]: 'são pesquisas que possuem resposta certa.',
    [CONSTANTS.QUIZ_DETAIL]: 'Detalhes do quiz',
    [CONSTANTS.SURVEY_DETAIL]: 'Detalhes da pesquisa',
    [CONSTANTS.QUIZZES]: 'Quizzes',
    [CONSTANTS.QUIZZES_SURVEYS]: 'Quizzes e Pesquisas',
    [CONSTANTS.IN_PROGRESS]: 'Em andamento',
    [CONSTANTS.DISABLED]: 'Desativado',
    [CONSTANTS.NEW_QUIZ]: 'Novo quiz',
    [CONSTANTS.NEW_SURVEY]: 'Nova Pesquisa',
    [CONSTANTS.NEW_QUIZ_OR_SURVEY]: 'Novo quiz ou pesquisa',
    [CONSTANTS.TYPE]: 'Tipo',
    [CONSTANTS.TITLE]: 'Título',
    [CONSTANTS.DURATION]: 'Duração',
    [CONSTANTS.ANSWER]: 'Resposta',
    [CONSTANTS.DETAILS]: 'Detalhes',
    [CONSTANTS.QUESTION]: 'Pergunta',
    [CONSTANTS.QUESTIONS]: 'Perguntas',
    [CONSTANTS.QUESTIONS_QUANTITY]: 'Quantidade de Perguntas',
    [CONSTANTS.QUESTION_SCORE]: 'Pontuação da Pergunta',
    [CONSTANTS.CORRECT_ANSWER]: 'Resposta correta',
    [CONSTANTS.OPTION_CHARACTERS_INFO]: 'caracteres restantes',
    [CONSTANTS.SELECT]: 'Selecione',
    [CONSTANTS.SCREEN_TIME_DURATION]: 'Duração em tela ( em segundos )',
    [CONSTANTS.SAVE_QUIZ]: 'Salvar quiz',
    [CONSTANTS.SAVE_SURVEY]: 'Salvar pesquisa',
    [CONSTANTS.ADD]: 'Adicionar',
    [CONSTANTS.PUBLISH]: 'Publicar',
    [CONSTANTS.PUBLISHED]: 'Publicado',
    [CONSTANTS.SURVEY_PUBLISHED]: 'Publicada',
    [CONSTANTS.UNPUBLISHED]: 'Não Publicado',
    [CONSTANTS.SURVEY_UNPUBLISHED]: 'Não Publicada',
    [CONSTANTS.WARNING]: 'Aviso',
    [CONSTANTS.CHECK_DATA]: 'Verifique seus dados!',
    [CONSTANTS.UPDATE_QUESTION_SUCCESS]: 'Pergunta atualizada com sucesso!',
    [CONSTANTS.UPDATE_QUESTION_FAIL]: 'Falha ao atualizar pergunta!',
    [CONSTANTS.ERROR_DUPLICATE_QUESTION]: 'Erro: Itens do questionario devem ter valor único!',
    [CONSTANTS.LIMIT_CHARACTER]: 'Limite de caracteres atingido!',
    [CONSTANTS.RELEASE_QUESTION_FAIL]: 'Falha ao publicar pergunta!',
    [CONSTANTS.CREATE_QUESTION_SUCCESS]: 'Pergunta criada com sucesso!',
    [CONSTANTS.CREATE_QUESTION_FAIL]: 'Falha ao criar pergunta!',
    [CONSTANTS.QUIZ_RESULT]: 'Resultado do quiz',
    [CONSTANTS.SURVEY_RESULT]: 'Resultado da pesquisa',
    [CONSTANTS.VIEW_QUIZZES_WARN]: 'Atenção: Quizzes/Pesquisas só está disponível para conteúdos/vídeos do tipo LIVE',
    [CONSTANTS.FIELD_MANDATORY]: 'Campo obrigatório',
    [CONSTANTS.FIELD_DUPLICATE]: 'Campo duplicado',
    [CONSTANTS.POSITIVE_FIELD]: 'O Campo deve ser acima de Zero!',
    [CONSTANTS.REQUIRED_QUESTIONS_FIELDS]: 'Preencha os itens de questões obrigatórios',
    [CONSTANTS.REQUIRED_OPTIONAL_QUESTION_FIELD]: 'Preencha o campo "opcional" em sequência',

    [CONSTANTS.SORT_BY]: 'ORDENAR POR:',
    [CONSTANTS.TOTAL_OF_POINTS]: 'Total de pontos',
    [CONSTANTS.LABEL_SEARCH_INPUT]: 'PESQUISE POR NOME E E-MAIL',
    [CONSTANTS.DECREASING]: 'Decrescente',
    [CONSTANTS.ASCENDING]: 'Crescente',
    [CONSTANTS.POSITION]: 'Lugar',
    [CONSTANTS.POINTS]: 'Pontos',
    [CONSTANTS.LAST_UPDATE]: 'Última atualização',
    [CONSTANTS.PLACE]: 'Lugar',
    [CONSTANTS.LABEL_SEARCH_INPUT_USERS]: 'Pesquise por E-MAIL',
    [CONSTANTS.BLOCK_LIST]: 'Block list chat',
    [CONSTANTS.BLOCK_DATE]: 'Data e hora do bloqueio',
    [CONSTANTS.REMOVE_BLOCKING]: 'Remover bloqueio',
    [CONSTANTS.NAME_CHAT]: 'Nome chat',
    [CONSTANTS.UNBLOCKING_MSG]: 'Você realmente deseja remover o bloqueio do participante',
    [CONSTANTS.REMOVE]: 'Remover',
    [CONSTANTS.BLOCKING]: 'Bloqueio',
    [CONSTANTS.CONFIRM_UNBLOCKING_YES]: 'Sim, Desejo!',
    [CONSTANTS.CONFIRM_UNBLOCKING_NO]: 'Não, Não desejo!',
    [CONSTANTS.VIEW_BLOCKLIST_WARN]: 'Atenção: Block List Chat só está disponível para conteúdos/vídeos do tipo LIVE',
    [CONSTANTS.BLOCK_LIST_FAILURE]: 'Falha ao carregar usuários bloqueados!',
    [CONSTANTS.UNBLOCK_USER_SUCCESS]: 'Usuário desbloqueado com sucesso!',
    [CONSTANTS.UNBLOCK_USER_FAILURE]: 'Falha ao desbloquear usuário!',
    [CONSTANTS.USERS_THAT_ARE_NOW_VIEWING_THE_BROADCAST]: 'Total de usuários que estão acessando a transmissão.',
    [CONSTANTS.USERS_WITH_TAB_AND_SOUND_ON_OR_IN_FULL_SCREEN_DIVIDED_BY_THE_TOTAL_USERS_VIEWED]: 'Porcentagem de usuários com a guia e o som ativados ou em tela cheia.',
    [CONSTANTS.USERS_THAT_ARE_EXPERIENCING_BUFFERING_ISSUE_OF_OVER_1_SECOND_DIVIDED_BY_TOTAL_USERS]: 'Porcentagem de usuários que estão com buffer de mais de 1 segundo.',
    [CONSTANTS.THE_AVERAGE_BITRATE_REPORTED_BY_KOPA_PLAYERS]: 'A taxa de bits média relatada pelos players do Kopa.',

    [CONSTANTS.TRANSMISSION]: 'Transmissão',
    [CONSTANTS.WAITING_TRANSMISSION]: 'Aguardando Transmissão',
    [CONSTANTS.NO_LIVE_IN_PROGRESS]: 'A live só poderá ser iniciada quando o APRESENTADOR iniciar a sua transmissão.',
    [CONSTANTS.MUTE_USER]: 'Mutar',
    [CONSTANTS.UNMUTE_USER]: 'Desmutar',
    [CONSTANTS.CREATING_ROOM_PLEASE_WAIT]: 'A sala está sendo criada, aguarde...',

    [CONSTANTS.ONBOARDING_HOME_WELCOME_TITLE]: 'Bem-vindo(a) ao Kopa!',
    [CONSTANTS.ONBOARDING_HOME_WELCOME]: 'É um prazer ter você aqui no Kopa. Você está na área do organizador, que é o espaço exclusivo para você gerenciar os seus canais. Convidamos você a realizar um tour rápido pela nossa plataforma. Vem com a gente?',
    [CONSTANTS.ONBOARDING_HOME_LOGOUT_TITLE]: 'Sair',
    [CONSTANTS.ONBOARDING_HOME_LOGOUT]: 'Clique aqui para sair do Kopa.',
    [CONSTANTS.ONBOARDING_LETS_START]: 'Começar o tour',
    [CONSTANTS.ONBOARDING_SKIP_TOUR]: 'Pular tour',
    [CONSTANTS.ONBOARDING_HOME_MY_VIDEOS_TITLE]: 'Meus vídeos',
    [CONSTANTS.ONBOARDING_HOME_MY_VIDEOS]: 'Aqui você poderá gerenciar seus vídeos e lives.',
    [CONSTANTS.ONBOARDING_HOME_VIDEO_CARD_TITLE]: 'Vídeos',
    [CONSTANTS.ONBOARDING_HOME_VIDEO_CARD]: 'Aqui você poderá editar e visualizar seus vídeos e lives, gerar relatórios e quizzes.',
    [CONSTANTS.ONBOARDING_HOME_USERS_TITLE]: 'Usuários',
    [CONSTANTS.ONBOARDING_HOME_USERS]: 'Dentro de usuários você terá acesso à listagem de usuários e poderá realizar ações para gerenciar os usuários de seus canais, como: editar, cancelar matrícula e adicionar um novo usuário.',
    [CONSTANTS.ONBOARDING_HOME_GAMEFICATION_TITLE]: 'Gamificação',
    [CONSTANTS.ONBOARDING_HOME_GAMEFICATION]: 'Dentro de gamificação você terá acesso à listagem dos participantes com seus respectivos pontos.',
    [CONSTANTS.ONBOARDING_HOME_NEW_VIDEO_TITLE]: 'Novo vídeo',
    [CONSTANTS.ONBOARDING_HOME_NEW_VIDEO]: 'Aqui você poderá iniciar uma live e adicionar um novo vídeo.',
    [CONSTANTS.ONBOARDING_HOME_USER_AREA_TITLE]: 'Área do usuário',
    [CONSTANTS.ONBOARDING_HOME_USER_AREA]: 'Aqui você poderá visualizar e editar o seu perfil, adicionando foto e informações das redes sociais. Também pode selecionar o idioma de sua preferência.',
    [CONSTANTS.ONBOARDING_HOME_COMPLETE]: 'Finalizar tour da página inicial',
    [CONSTANTS.ONBOARDING_HOME_MENU_TITLE]: 'Menu do usuário',
    [CONSTANTS.ONBOARDING_HOME_MENU]: 'Dentro do menu do usuário você terá acesso aos botões da página inicial, configurações de vídeos, usuários, QR code, quizzes, relatórios e ao bloqueio de usuários no chat , sair do Kopa e mudar o idioma padrão do Kopa.',
    [CONSTANTS.ONBOARDING_COMPLETE]: 'Concluir tutorial',
    [CONSTANTS.ONBOARDING_HOME_TUTORIALS_TITLE]: 'Tutoriais',
    [CONSTANTS.ONBOARDING_HOME_TUTORIALS]: 'Aqui você terá acesso à tutoriais que explicam o funcionamento do Kopa.',

    [CONSTANTS.ONBOARDING_USERS_AREA_TITLE]: 'Área do Usuário',
    [CONSTANTS.ONBOARDING_USERS_AREA]: 'Na área do usuário você terá acesso à listagem de usuários e poderá realizar ações para gerenciar os usuários de seus canais, como: editar, cancelar matrícula e adicionar um novo usuário.',
    [CONSTANTS.ONBOARDING_USERS_SEARCH_TITLE]: 'Pesquisa',
    [CONSTANTS.ONBOARDING_USERS_SEARCH]: 'Aqui você poderá pesquisar seus usuários por nome e e-mail.',
    [CONSTANTS.ONBOARDING_USERS_OPTIONS_TITLE]: 'Mais ações',
    [CONSTANTS.ONBOARDING_USERS_OPTIONS]: 'Aqui você poderá incluir novos usuários manualmente ou via planilha xlsx e realizar o download da listagem de usuários .',
    [CONSTANTS.ONBOARDING_USERS_CONTAINER_TITLE]: 'Listagem de usuários',
    [CONSTANTS.ONBOARDING_USERS_CONTAINER]: 'Aqui você terá acesso à listagem de usuários.',
    [CONSTANTS.ONBOARDING_USERS_ICON_DOTS_TITLE]: 'Opções da listagem',
    [CONSTANTS.ONBOARDING_USERS_ICON_DOTS]: 'Por fim, você terá acesso a editar o nível de permissão do usuário e cancelar a matrícula do usuário.',

    [CONSTANTS.ONBOARDING_GAMIFICATION_AREA_TITLE]: 'Área da Gamificação',
    [CONSTANTS.ONBOARDING_GAMIFICATION_AREA]: 'Na área de gamificação você terá acesso à listagem de usuários com respectivos pontos e poderá realizar configurações como: habilitar ou desabilitar a gamificação, escolher a ação que irá adicionar pontos ao usuário e escolher como será essa adição de pontos.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_SEARCH_AREA_TITLE]: 'Pesquisa',
    [CONSTANTS.ONBOARDING_GAMIFICATION_SEARCH_AREA]: 'Aqui você poderá pesquisar seus usuários com respectivos pontos por nome e e-mail.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_FILTER_AREA]: 'Filtro',
    [CONSTANTS.ONBOARDING_GAMIFICATION_FILTER]: 'Aqui você poderá filtrar a listagem de usuários.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_SETTINGS_TITLE]: 'Configurações',
    [CONSTANTS.ONBOARDING_GAMIFICATION_SETTINGS]: 'Nas configurações você poderá habilitar ou desabilitar a gamificação, escolher a ação que irá adicionar pontos ao usuário e escolher como será essa adição de pontos.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_MORE_OPTIONS_TITLE]: 'Mais ações',
    [CONSTANTS.ONBOARDING_GAMIFICATION_MORE_OPTIONS]: 'Aqui você poderá realizar o download da listagem de usuários.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_CONTAINER_TITLE]: 'Listagem de usuários',
    [CONSTANTS.ONBOARDING_GAMIFICATION_CONTAINER]: 'Por fim, você terá acesso à listagem de usuários.',

    [CONSTANTS.ONBOARDING_VIDEOS_EDIT_LIVE_TITLE]: 'Área da live',
    [CONSTANTS.ONBOARDING_VIDEOS_EDIT_LIVE]: 'Aqui você poderá gerenciar sua live e obter informações sobre ela.',
    [CONSTANTS.ONBOARDING_VIDEOS_DASHBOARD_TITLE]: 'Dashboard',
    [CONSTANTS.ONBOARDING_VIDEOS_DASHBOARD]: 'No Dashboard você poderá iniciar sua live e encontrará um painel visual que contém informações e indicadores sobre sua live.',
    [CONSTANTS.ONBOARDING_VIDEOS_QRCODE_TITLE]: 'QR-Code',
    [CONSTANTS.ONBOARDING_VIDEOS_QRCODE]: 'Dentro de QR-Code você poderá criar um QR-Code para comercialização de produtos em sua live.',
    [CONSTANTS.ONBOARDING_VIDEOS_QUIZZES_TITLE]: 'Quizzes',
    [CONSTANTS.ONBOARDING_VIDEOS_QUIZZES]: 'Dentro de quizzes você poderá cadastrar quizzes ou pesquisas para serem utilizados durante a live.',
    [CONSTANTS.ONBOARDING_VIDEOS_REPORTS_TITLE]: 'Relatórios',
    [CONSTANTS.ONBOARDING_VIDEOS_REPORTS]: 'Dentro de relatórios você visualizará o relatório de presença da live.',
    [CONSTANTS.ONBOARDING_VIDEOS_BLOCK_LIST_CHAT_TITLE]: 'Block list chat',
    [CONSTANTS.ONBOARDING_VIDEOS_BLOCK_LIST_CHAT]: 'Essa área contém a listagem dos usuários bloqueados na live.',
    [CONSTANTS.ONBOARDING_VIDEOS_ENGAGEMENT_TITLE]: 'Engajamento',
    [CONSTANTS.ONBOARDING_VIDEOS_ENGAGEMENT]: 'Aqui você visualizará os indicadores de engajamento da sua live.',
    [CONSTANTS.ONBOARDING_VIDEOS_PREVIEW_TITLE]: 'Live',
    [CONSTANTS.ONBOARDING_VIDEOS_PREVIEW]: 'Aqui você poderá iniciar e habilitar o chat da live.',
    [CONSTANTS.ONBOARDING_VIDEOS_GRAPH_TITLE]: 'Gráfico',
    [CONSTANTS.ONBOARDING_VIDEOS_GRAPH]: 'Aqui você poderá filtrar por indicador e obter um gráfico para visualizar melhor as informações de sua live.',
    [CONSTANTS.ONBOARDING_VIDEOS_MONITORS_LIST_TITLE]: 'Lista de monitores',
    [CONSTANTS.ONBOARDING_VIDEOS_MONITORS_LIST]: 'Você terá acesso à lista de monitores.',
    [CONSTANTS.ONBOARDING_VIDEOS_GELOCALIZATION_TITLE]: 'Geolocalização',
    [CONSTANTS.ONBOARDING_VIDEOS_GELOCALIZATION]: 'Aqui você pode verificar a geolocalização dos usuários.',

    [CONSTANTS.CAPTION]: 'Legenda',
    [CONSTANTS.CAPTIONS]: 'Legendas',
    [CONSTANTS.VIDEO_DETAILS]: 'Detalhes do vídeo',
    [CONSTANTS.SELECT_CAPTION_LANGUAGE]: 'Selecione o idioma da legenda',
    [CONSTANTS.SAVE_CAPTION]: 'Salvar legenda',
    [CONSTANTS.ADD_CAPTION]: 'Adicionar Legenda',
    [CONSTANTS.NEW_CAPTION]: 'Nova legenda',
    [CONSTANTS.REMOVE_CAPTION_CONFIRMATION]: 'Tem certeza de que deseja remover esta legenda?',
    [CONSTANTS.CAPTION_UPLOAD_SUCCESSFULLY]: 'Legenda enviada com sucesso!',
    [CONSTANTS.CAPTION_REMOVED_SUCCESSFULLY]: 'Legenda excluída com sucesso!',
    [CONSTANTS.CAPTION_REMOVED_FAILED]: 'Falha ao excluir legenda!',
    [CONSTANTS.CAPTION_UPLOAD_FAILED]: 'Falha ao enviar legenda!',
    [CONSTANTS.CAPTION_UPDATE_SUCCESSFULLY]: 'Legenda atualizada com sucesso!',
    [CONSTANTS.CAPTION_UPDATE_FAILED]: 'Falha ao autalizar legenda!',
    [CONSTANTS.CAPTION_GET_FAILED]: 'Falha ao listar legendas!',
    [CONSTANTS.CAPTION_STATUS_ERROR]: 'Erro',
    [CONSTANTS.CAPTION_STATUS_QUEUED]: 'Na fila',
    [CONSTANTS.CAPTION_STATUS_READY]: 'Pronta',
    [CONSTANTS.CAPTION_STATUS_REMOVED]: 'Excluída',
    [CONSTANTS.CAPTION_STATUS_IMPORTING]: 'Importando',
    [CONSTANTS.CAPTION_STATUS_EXPORTING]: 'Exportando',
    [CONSTANTS.NO_CAPTIONS_FOUND]: 'Nenhuma legenda cadastrada!',
    [CONSTANTS.FIRST_CAPTION_MESSAGE]: 'Ainda não cadastrou nenhuma legenda? É simples, fácil e só leva alguns minutos.',
    [CONSTANTS.REMOVING]: 'Excluindo',
    [CONSTANTS.DOWNLOADING]: 'Baixando',
    [CONSTANTS.DOWNLOAD_CAPTION]: 'Baixar legenda',
    [CONSTANTS.BACK_TO_VIDEOS]: 'Voltar para vídeos',
    [CONSTANTS.CAPTION_LANGUAGE_EXISTS]: 'Já existe uma legenda com este idioma!',
    [CONSTANTS.VIDEO_SETTINGS]: 'Configurações do vídeo',
    [CONSTANTS.LIVE_SETTINGS]: 'Configurações da live',

    [CONSTANTS.ARABIC]: 'Árabe',
    [CONSTANTS.CANTONESE]: 'Cantonês',
    [CONSTANTS.CATALAN]: 'Catalão',
    [CONSTANTS.CHINESE]: 'Chinês',
    [CONSTANTS.DANISH]: 'Dinamarquês',
    [CONSTANTS.DUTCH]: 'Holandês',
    [CONSTANTS.ENGLISH]: 'Inglês',
    [CONSTANTS.FINNISH]: 'Finlandês',
    [CONSTANTS.FRENCH]: 'Francês',
    [CONSTANTS.GERMAN]: 'Alemão',
    [CONSTANTS.GREEK]: 'Grego',
    [CONSTANTS.HEBREW]: 'Hebraico',
    [CONSTANTS.HINDI]: 'Hindi',
    [CONSTANTS.HUNGARIAN]: 'Húngaro',
    [CONSTANTS.ICELANDIC]: 'Islandês',
    [CONSTANTS.INDONESIAN]: 'Indonésio',
    [CONSTANTS.IRISH]: 'Irlandês',
    [CONSTANTS.ITALIAN]: 'Italiano',
    [CONSTANTS.JAPANESE]: 'Japonês',
    [CONSTANTS.KOREAN]: 'Coreano',
    [CONSTANTS.MALAYALAM]: 'Malaiala',
    [CONSTANTS['MANDARIN CHINESE']]: 'Chinês mandarim',
    [CONSTANTS.NORWEGIAN]: 'Norueguês',
    [CONSTANTS.POLISH]: 'Polonês',
    [CONSTANTS.PORTUGUESE]: 'Português',
    [CONSTANTS.ROMANIAN]: 'Romena',
    [CONSTANTS.RUSSIAN]: 'Russo',
    [CONSTANTS.SPANISH]: 'Espanhol',
    [CONSTANTS.SWEDISH]: 'Sueco',
    [CONSTANTS['TAIWANESE MANDARIN']]: 'Mandarim taiwanês',
    [CONSTANTS.TAMIL]: 'Tâmil',
    [CONSTANTS.THAI]: 'Tailandês',
    [CONSTANTS.TURKISH]: 'Turco',
    [CONSTANTS.UKRAINIAN]: 'Ucraniano',
    [CONSTANTS.URDU]: 'Urdu',
    [CONSTANTS.VIETNAMESE]: 'Vietnamita',
    [CONSTANTS.WELSH]: 'Galês',
    [CONSTANTS.ZULU]: 'Zulu',

    [CONSTANTS.ONBOARDING_TUTORIALS_AREA_TITLE]: 'Área de tutoriais',
    [CONSTANTS.ONBOARDING_TUTORIALS_AREA]: 'Na área de tutoriais você terá acesso a vídeos tutoriais que explicam o funcionamento do Kopa.',
    [CONSTANTS.ONBOARDING_TUTORIALS_TUTORIAL_CARD_TITLE]: 'Tutoriais',
    [CONSTANTS.ONBOARDING_TUTORIALS_TUTORIAL_CARD]: 'Aqui você poderá visualizar os vídeos tutoriais.',

    [CONSTANTS.TUTORIALS]: 'Tutoriais',
    [CONSTANTS.FAIL_TO_LOAD_VIDEO_PROGRESS]: 'Falha ao carregar progressos de vídeos!',
    [CONSTANTS.FAIL_TO_LOAD_TUTORIALS]: 'Falha ao carregar tutoriais!',

    [CONSTANTS.NETWORK_FAILED]: 'Não foi possível acessar nosso servidor.',
    [CONSTANTS.NETWORK_FAILED_MESSAGE]: ' Verifique a sua conexão e tente novamente.',

    // statistic video
    [CONSTANTS.STATISTIC_VIDEO_DETAIL]: 'Detalhes',
    [CONSTANTS.STATISTIC_VIDEO_DAY]: 'Dia',
    [CONSTANTS.STATISTIC_VIDEO_DOWNLOAD]: 'BAIXAR VÍDEO',
    [CONSTANTS.STATISTIC_VIDEO_PEOPLE_WATCH]: 'Pessoas Que Assistiram Ao Vídeo',
    [CONSTANTS.STATISTIC_VIDEO_PEOPLE_AVERAGE_TIME_WATCH]: 'Tempo Médio Assistido',
    [CONSTANTS.STATISTIC_VIDEO_PLAY_IN_VIDEO]: 'Play No Vídeo',
    [CONSTANTS.STATISTIC_VIDEO_ATTACHMENTS_ADDED]: 'Anexos Adicionados',
    [CONSTANTS.STATISTIC_VIDEO_ATTACHMENTS_DOWNLOAD]: 'baixe Aqui os anexos',

    [CONSTANTS.ROOM_POLL_STATUS_NOTIFY_FAILED]: 'O upload do vídeo [NAME] falhou!',
    [CONSTANTS.ROOM_POLL_STATUS_NOTIFY_SUCCESS]: 'O video [NAME] foi concluído.',

    [CONSTANTS.ROOM_STATUS_CREATED]: 'Criada',
    [CONSTANTS.ROOM_STATUS_ERROR_CONVERTING]: 'Erro',
    [CONSTANTS.ROOM_STATUS_UNKNOWN]: 'Desconhecido',
    [CONSTANTS.MAX_SCHEDULE_TIME]: 'Só é possível agendar lives com no máximo 4 horas a partir da hora atual!',
};
