import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    position: relative;
    flex-flow: column nowrap;
    height: 100%;

    @media (min-width: 650px) {
        flex-flow: row nowrap;
        padding: 2rem 1.3rem;
    }

    @media (max-width: 600px) and (orientation: landscape) {
        flex-flow: column nowrap;
    }

    @media(max-width: 799px) and (orientation: portrait) {
        flex-flow: column nowrap;
        .end-transmission {
            position: relative;
            right: initial;
            margin-right: 15px;
        }        
    }

    @media (max-width: 990px) and (orientation: landscape) {
        flex-flow: row nowrap;
    }

    @media screen and (min-width: 48.75rem) and (max-width: 64rem ) {
        & h2 {
            font-size: 1rem;
        }
    }
`;

export const VideoPreview = styled.div`
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 185px);;
    position: relative;

    @media (min-width: 650px) {
        /* height: 100%; */
        margin-bottom: 10px;
        padding: 0 20px 0 20px;
    }

    @media (max-width: 650px) {
        /* height: 100%; */
    }

    @media (max-width: 650px) and (orientation: landscape) {
        height: 100%;
    }

    .mute-controls {
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-25%, 50%);        

        img {
            height: 60px;
        }
    }

    .video-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 62px;
        padding: 10px;
        margin-bottom: 15px;
        background-color: #1c1b2d;
        min-height: 65px;

        span {
            height: 28px;
            border-radius: 3px;
            background-color: #ff5b7d;
            position: relative;
            text-transform: uppercase;
            color: #fff;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 10px 5px 25px;
            min-width: 104px;

            &:before {
                content: "";
                position: absolute;
                height: 10px;
                width: 10px;
                background-color: #fff;
                left: 10px;
                border-radius: 50%;
            }
        }

        h5 {
            font-size: clamp(12px, 0.4em + 1vw, 1.5em);
            font-weight: 800;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.48px;
            color: #ffffff;
        }

        h6 {
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.02px;
            color: #ffffff;
        }

        @media (max-width: 450px){
            h5 {
                display: none;
            }
        }
        @media (max-width: 400px){
            span {
                font-size: 10px;
                min-width: 80px;
            }
        
        }
    }

    .video-container {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        border-radius: 8px;
        background-color: #212032;
        overflow: hidden;
    }
    
    .video-player {
        position: relative;
        overflow: hidden;
        video {
            width: 100%;
            height: 100%;
        }
        
        height: ${(props) => (props.collapsed
        ? 'calc(98% - 65px)'
        : props.hasAttachments
            ? '68%'
            : '100%')};
        
        .video-details {
            /* max-height: 20rem; */
            /* height: 50px;         */
            width: 100%;
            position: absolute;
            bottom: 20px;
            z-index: 99;
        }
    }
    @media (max-width: 650px) {
        .video-player {
            height: ${(props) => (props.collapsed
        ? 'calc(98% - 45px)'
        : props.hasAttachments
            ? '68%'
            : '100%')};
        }
    }

    @media (max-width: 600px) {
       video {
           /* max-height: 200px; */
       }
    }
`;

export const DevicesContainer = styled.div`
    position: relative;
    /* bottom: 15px; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    transition: opacity 0.2s;
    opacity: ${({ showMediaControls }) => (showMediaControls ? 1 : 0)};

    > div {
        display: flex;
    }

    .device-wrapper {
        position: relative;
        margin: 0 10px;
    }

    .list-devices {
        position: absolute;
        border-radius: 50%;
        border: none;
        height: 1.2rem;
        width: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #c35a36;
        padding: 3px;
        z-index: 4;
        bottom: -2px;
        right: -5px;
        transition: all 100ms cubic-bezier(0.075, 0.82, 0.165, 1);
        

        &.up {
            transform: rotate(180deg);
        }
    }
    .show-device-arrow {        
        width: 80%;        
    }

    @media(max-width: 799px){
        &.is-live {
            justify-content: space-between;
            .device-wrapper {
                .device-list {
                   left: 0;
                   right: unset;
                   &::before {
                       right: unset;
                   }
                }
            }
        }
    }
`;

export const Device = styled.button`
    border-radius: 4px;
    border: none;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    background: #312f48;
    opacity: 0.60;      
    
    img {
        height: 60%;
        width: 60%;
        object-fit: contain;
    }
`;

export const BackToListButton = styled.button`
    border: none;
    background: #ff6363;
    height: 48px;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;       
    border-radius: 3px;
    padding: 0 15px;

    &:hover {
        transform: scale(1.02);
    }
`;
