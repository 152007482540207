const INITIAL_STATE = {
    error: false,
    loading: false,
    token: '',
};

export default function recoverPassword(state = INITIAL_STATE, action) {
    switch (action.type) {
    case 'CHANGE_PASSWORD_REQUEST':
        return { ...state, loading: true };
    case 'CHANGE_PASSWORD_SUCCESS':
        return { ...state, loading: false };
    case 'CHANGE_PASSWORD_FAILED':
        return { ...state, loading: false, error: action.payload.error };
    case 'NEW_PASSWORD_REQUEST':
        return { ...state, loading: true };
    case 'NEW_PASSWORD_SUCCESS':
        return { ...state, loading: false };
    case 'NEW_PASSWORD_FAILED':
        return { ...state, loading: false, error: action.payload.error };
    case 'CLEAR_ERRORS':
        return { ...INITIAL_STATE };
    default:
        return state;
    }
}
