import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import OnboardingVideos from '@src/components/Onbordings/onboardingVideos';
import useAuth from '@src/hooks/useAuth';
import ReportEngagement from '@components/Reports/Engagement';
import ReportPreview from '@components/Reports/Preview';
import ReportGraph from '@components/Reports/Graph';
import ReportGeolocalization from '@components/Reports/Geolocalization';
import ButtonHeader from '@components/ButtonHeader';
import Loading from '@components/Loading';
import useRooms from '@hooks/useRooms';
import { useBreadcrumbs } from '@hooks/useBreadcrumbs';
import convidadoIco from '@src/assets/convidado.svg';
import dashboardIcon from '@src/assets/dashboardIcon.svg';
import qrCodeIcon from '@src/assets/qr-code-icon.svg';
import paperIcon from '@src/assets/paper-icon.svg';
import quizzesIcon from '@src/assets/quizzes-icon.svg';
import langs from '@src/assets/i18n/constants';
import muralIco from '@src/assets/mural.svg';
import { TIMERS } from '@utils/constants';
import paths from '@utils/paths';
import {
    fetchEditRoom,
    getRoomEngagement,
    getRoomEventLog,
    getRoomGeolocalization,
    getRoomGraph,
    roomIsLive,
    clearEditSelected,
    getOnlineMonitors,
} from '@src/store/modules/rooms/actions';
import blockIcon from '@src/assets/block-icon.svg';
import { useSidebar } from '@src/hooks/useSidebar';
import { ContainerDetails, Header, HeaderButtonsContainer } from './styles';
import ActiveMonitorsList from './ActiveMonitorsList';

const menuItemsListBuilder = (id, t) => ([
    {
        link: `/myChannels/${id}/details`,
        title: 'Dashboard',
        className: 'myChannels',
        dataTour: 'tour__dashboard',
        icon: dashboardIcon,
        alt: t(langs.CHANNEL_MYVIDEOS),
    },
    {
        link: `/offers/${id}`,
        title: 'QR-CODE',
        className: 'offers',
        dataTour: 'tour__qrCode',
        icon: qrCodeIcon,
        alt: t(langs.PITCH),
    },
    {
        link: `/quizzes/${id}`,
        title: t(langs.QUIZZES),
        className: 'quizzes',
        dataTour: 'tour__quizzes',
        icon: quizzesIcon,
        alt: t(langs.QUIZZES),
    },
    {
        link: `/presenceReport/${id}`,
        title: t(langs.REPORTS),
        className: 'presenceReport',
        dataTour: 'tour__reports',
        icon: paperIcon,
        alt: t(langs.REPORTS),
    },
    {
        link: `/blockList/${id}`,
        title: t(langs.BLOCK_LIST),
        className: 'blockList',
        dataTour: 'tour__block_list_chat',
        icon: blockIcon,
        alt: t(langs.BLOCK_LIST),
    },
]);

export default function RoomDetails() {
    const { id: roomId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { setItems } = useBreadcrumbs();
    const {
        selectedEditRoom,
        roomEngagement,
        roomEventLog,
        roomGraph,
        roomGeolocalization,
        loading,
    } = useRooms();
    const { setItems: setSideBarItems } = useSidebar();
    const [isFirstFetch, setIsFirstFetch] = useState(true);

    const shouldShowLoading = isFirstFetch && loading;

    const history = useHistory();

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t('ORGANIZER_DASHBOARD') },
            { to: paths.CHANNELS, label: selectedEditRoom?.name },
            { to: `${paths.CHANNELS}/${selectedEditRoom?.id}/details`, label: t('DASHBOARD') },
        ];
        setItems(breadcrumbsItems);
    }, [selectedEditRoom, setItems, t]);

    useEffect(() => {
        if (roomId) {
            dispatch(roomIsLive(roomId));
            dispatch(fetchEditRoom(roomId));
            setIsFirstFetch(false);
            dispatch(getRoomEngagement(roomId));
            dispatch(getRoomEventLog(roomId));
            dispatch(getRoomGraph(roomId));
            dispatch(getRoomGeolocalization(roomId));
        }

        return () => dispatch(clearEditSelected());
    }, [dispatch, roomId]);

    useEffect(() => {
        const checkRoomEngagement = setInterval(() => {
            dispatch(getRoomEngagement(roomId));
            dispatch(getRoomEventLog(roomId));
            dispatch(getOnlineMonitors(roomId));
        }, TIMERS.REFRESH_ENGAGEMENT);

        return () => {
            clearInterval(checkRoomEngagement);
        };
    }, [dispatch, roomId]);

    useEffect(() => {
        setSideBarItems(menuItemsListBuilder(roomId, t));
    }, [roomId, setSideBarItems, t]);

    function handleGoToVideoWall() {
        history.push(`${paths.VIDEO_WALL}/${roomId}`);
    }

    function handleGoToGuestPresenter() {
        history.push(`${paths.GUEST_PRESENTER}/${roomId}`);
    }

    const { user } = useAuth();

    if (shouldShowLoading) return <Loading />;

    return (
        <ContainerDetails className="container">
            {(user)
                && <OnboardingVideos />}

            {!loading && !selectedEditRoom?.id && (
                <h2 className="loadDataErrorMessage">{(t(langs.CHANNEL_ROOM_LOAD_DATA_ERROR))}</h2>
            )}

            {selectedEditRoom?.id
                && (
                    <>
                        <Header className="header">
                            <h4>{selectedEditRoom ? selectedEditRoom.name : ''}</h4>
                            {
                                selectedEditRoom?.interactive && (
                                    <HeaderButtonsContainer>
                                        <ButtonHeader
                                            onClick={handleGoToGuestPresenter}
                                            title={t('BUTTON_GUEST')}
                                            icon={convidadoIco}
                                        />
                                        <ButtonHeader
                                            onClick={handleGoToVideoWall}
                                            title="VIDEO WALL"
                                            icon={muralIco}
                                        />
                                    </HeaderButtonsContainer>
                                )
                            }
                        </Header>

                        <ReportEngagement roomEngagement={roomEngagement} />

                        <ReportPreview
                            roomEventLog={roomEventLog}
                            videoUrl={selectedEditRoom ? selectedEditRoom.videoUrl : ''}
                            roomId={roomId}
                            startTime={selectedEditRoom ? selectedEditRoom.currentBroadcastStartTime : ''}
                            canGoLive={selectedEditRoom && selectedEditRoom?.canGoLive}
                            isSettingUp={selectedEditRoom && selectedEditRoom?.isSettingUp}
                        />

                        <ReportGraph
                            roomGraph={roomGraph}
                            roomId={roomId}
                        />

                        <ActiveMonitorsList />

                        <ReportGeolocalization roomGeolocalization={roomGeolocalization} />
                    </>
                ) }
        </ContainerDetails>
    );
}
