import React from 'react';
import caution from '../../assets/caution.svg'
import { Container } from './styles';

function Caution({
    title = '',
    text = '',
    button = {
        text: '',
        action: null,
    }
}) {
    return (
        <Container>
            <img src={caution} alt="Caution icon" />
            <h2>{title}</h2>
            <p>{text}</p>
            {button.action && <button onClick={button.action}>{button.text}</button>}
        </Container>
    );
}

export default Caution;