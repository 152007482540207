import styled from 'styled-components';

export const Container = styled.div`
    color: #fff;
    padding: 40px;

    .success-wrapper {
        text-align: center;
         h2 {
            margin-top: 20px;
         }
    }

    h2 {
        text-align: center;
        margin-bottom: 20px;
        text-transform: uppercase;

        span {
            color: #ff9869;
            font-weight: 800;
        }
    }

    img {
        max-width: 200px;
    }

    .containerTable {
        margin: 1.5em 0 0 0;
        max-height: 70vh;
        overflow: auto;
        border-radius: 8px;

        @media (max-width: 650px) {
            max-height: 60vh;
        }
    }
`;

export const TableContainer = styled.div`
    width: 100%;
    padding: 0 0 1px;
    border-radius: 8px;
    background-color: #212032;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #ffffff;
    max-width: 100%;

    th {
        background-color: #1c1b2d;
        padding: 12px 0.4em;
        text-transform: uppercase;
        &:first-child {
            padding-left: 18px;
        }
    }

    tr {
        cursor: pointer;
        background-color: #212032;
        &:nth-child(even) {
            background-color: #242339;
        }

        &:hover {
            background-color: #2f2e3e;
        }  

        td {
            padding: 10px 0.5em;
            &:first-child {
                padding-left: 18px;
            }            
        }
    }    
    
`;

export const TableFooterPagination = styled.div`
    display: flex;
    width: 100%;

    .table-footer {
        margin-top: -5px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: .8rem 1rem;
        border-radius: 0 0 8px 8px;
        background-color: #1c1b2d;
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        color: #ffffff;
        max-width: 100%;

        select {
            background: #24243e;
            border: 0;
            border-radius: 6px;
            letter-spacing: 0.21px;
            color: #ffffff;
            margin: 0 1em;
            height: 2em;

            &:focus {
            border: solid 1px #ff6479;
            }
        }

    .pagination {
        display: flex;
        justify-content: flex-end;

        .iconsNext, .iconsPrev {
            margin: 0 1em;
            height: 19px;
            cursor: pointer;
            width: 20px;
        }

        .iconsPrev { 
            transform: scaleX(-1); 
        }

        button { 
            background: transparent;
            border: none;
            color: #ffffff;
            &:disabled {
                opacity: 0.2;
            }
        }
        .MuiPaginationItem-textPrimary.Mui-selected {
            background-color: #ff6379;
        }
    }
}

`;
