import { all, put, takeLatest } from 'redux-saga/effects';
import api from '../../../services/api';
import { defaultErrorHandler } from '../../utilities';
import * as userActions from './actions';
import actions from '../../../utils/actions';
import { translate } from '../../../utils/functions';

function* getUsers({ payload }) {
    try {
        const users = yield api.getUsers(payload.courseId, payload.pageSize, payload.page, payload.keyword);

        let isLastPage = false;
        if (users.users.length < payload.pageSize) {
            isLastPage = true;
        }

        yield put(userActions.setUsers(users, isLastPage));
    } catch (error) {
        yield put(userActions.usersNextPageFail());
        defaultErrorHandler(error, { default: translate('FAIL_GET_USERS') });
    }
}

function* getUsersNextPage({ payload }) {
    try {
        const users = yield api.getUsers(payload.courseId, payload.pageSize, payload.page, payload.keyword);

        let isLastPage = false;
        if (users.users.length < payload.pageSize) {
            isLastPage = true;
        }

        yield put(userActions.setUsersNexPage(users, isLastPage));
    } catch (error) {
        yield put(userActions.usersNextPageFail());
        defaultErrorHandler(error, { default: translate('FAIL_GET_USERS') });
    }
}

function* deleteUser({ payload }) {
    try {
        yield api.deleteUser(payload.user, payload.courseId);
        yield put(userActions.deleteSuccess(payload.username));
        yield put(userActions.setLoaderState(false));
    } catch (error) {
        yield put(userActions.setLoaderState(false));
        defaultErrorHandler(error, { default: translate('FAIL_DELETE_USER') });
    }
}

function* changeRowsPerPage() {
    try {
        yield put(userActions.clearUsers());
        yield put(userActions.setLastEvaluateKey(''));
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_DELETE_USER') });
    }
}

function* editUSer({ payload }) {
    try {
        yield api.editUser(payload.user, payload.courseId);
        yield put(userActions.updateUsersList(payload.user, payload.courseId));
        yield put(userActions.setLoaderState(false));
    } catch (error) {
        yield put(userActions.setLoaderState(false));
        defaultErrorHandler(error, { default: translate('FAIL_EDIT_USER') });
    }
}

function* getUserFilter({ payload }) {
    try {
        yield put(userActions.setLoading(true));
        const users = yield api.getUserFilter(payload.courseId, payload.pageSize, payload.LastEvaluatedKey, payload.keyword);
        yield put(userActions.setUsers(users.users, users.LastEvaluatedKey));
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_GET_USERS') });
    }
}

function* getCsvList({ payload: { withLoading, courseId, pageSize, detailLastKey } }) {
    try {
        const response = yield api.getImportCsvList(courseId, pageSize, detailLastKey);
        response.withLoading = withLoading;
        yield put(userActions.getCsvListSuccess(response));
    } catch (error) {
        yield put(userActions.getCsvListFailure());
        defaultErrorHandler(error, { default: translate('FAIL_GET_CSV_LIST') });
    }
}

function* getImportCsvList({ payload: { importId, pageSize, lastKey } }) {
    try {
        const response = yield api.detailImportList(importId, pageSize, lastKey);
        yield put(userActions.getImportListSuccess(response));
    } catch (error) {
        yield put(userActions.getCsvListFailure());
        defaultErrorHandler(error, { default: translate('FAIL_GET_DETAIL_IMPORT_LIST') });
    }
}

export default all([
    takeLatest(actions.GET_USER_BY_FILTER, getUserFilter),
    takeLatest(actions.USERS_CHANGE_ROWS_PER_PAGE, changeRowsPerPage),
    takeLatest(actions.GET_USERS_REQUEST, getUsers),
    takeLatest(actions.GET_USERS_NEXT_PAGE_REQUEST, getUsersNextPage),
    takeLatest(actions.DELETE_USER_REQUEST, deleteUser),
    takeLatest(actions.EDIT_USER_REQUEST, editUSer),
    takeLatest(actions.GET_CSV_LIST_REQUEST, getCsvList),
    takeLatest(actions.DETAIL_IMPORT_LIST_REQUEST, getImportCsvList),
]);
