import styled, { css } from 'styled-components';

const SECTION_HEIGHT = '33px';

export const SettingsModalContainer = styled.div`
    padding: 1rem 1.5rem;
    max-height: 90vh;
    min-height: 25vh;
    overflow: auto;

    button {
        align-self: center;
    }
`;

export const GamificationSettingsArea = styled.article`
    color: #fff;

    h3 {
        color: #fff;
    }

    .MuiSwitch-colorSecondary {
        &.Mui-checked {
            color: #268783;
            
            & + .MuiSwitch-track {
                background-color: #31a6a1
            }
        }
    }
    
    .MuiSwitch-track {
        background-color: #e2e2e2
    }

`;

export const GamificationToggling = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #29283f;
    width: 100%;
    border-radius: 8px;
    height: ${SECTION_HEIGHT};
    margin-top: 8px;


    strong {
        color: ${({ isActive }) => (isActive ? '#31a6a1' : '#FF438D')}
    }

    p {
        font-weight: bold;
        margin-right: 20px;
    }

`;

export const GamificationButtonSave = styled.div`
    width: 400px;
    margin: 1rem 0px;
`;

export const SettingsModalContent = styled.div`
    margin-top: 20px;

    ${({ isGamificationActive }) => !isGamificationActive && css`
        opacity: 0.2;
        pointer-events: none;
    `}
`;

export const GamificationButtonSaveArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ErrorContainer = styled.div`
    width: 100% ;
    display: flex;
    justify-content: center;

    p {
        color: #ff5b7d;
        margin-left: 5px;
    }
`;
