import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: calc(100% - 11%);
    width: 100%;
    position: relative;
    flex-flow: column nowrap;
    padding: 1rem;
    overflow: auto;

    padding: 0 4em;

    td {
        img {
            width: 33px;
        }
    }

    .MuiCircularProgress-colorPrimary {
        color: #ffffff;
    }

    .containerTable {
        margin: 1.5em 0;
        max-height: 70vh;
        overflow: auto;
        border-radius: 8px;

        @media (max-width: 650px) {
            max-height: 60vh;
        }
    }

    main {
        min-height: 230px;
    }

    .--confirm-modal {
        padding-top: 18px;
        display: flex;
        padding: 15px;
        flex-direction: column;
        padding-top: 18px;
        align-items: center;

        h5 {
            color: #fff;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;

            span {
                color: #fe8d62;
            }
        }

        button {
            border: none;
            color: #fff;
            background: linear-gradient(90deg, #ffac47 0%, #ff438d 100%);
            width: 300px;
            min-height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-weight: 600;
            border-radius: 8px;
            font-weight: bold;
            text-transform: uppercase;
            opacity: 0.8;
            transition: all 0.1s ease-in-out;

            &.--cancel-button {
                color: #ff5b7d;
                background: transparent;
                border: solid 1px #ff5a7d;
            }

            &:hover {
                box-shadow: 0px 0px 10px -4px #000000;
                opacity: 1;
                transform: scale(1.02);
            }
        }
    }

    @media (max-width: 650px) {
        padding: 0 1em;
    }
`;

export const PageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        font-size: clamp(18px, 0.5em + 1vw, 1.9em);
        color: white;
        text-transform: uppercase;
    }

    button {
        border-width: 0px;
        display: flex;
        align-self: flex-end;
        height: 47px;
        border-radius: 12px;
        background-color: #212032;
        color: #ffffff;
        font-weight: bold;
        font-size: clamp(12px, 0.4em + 1vw, 1.2em);
        align-items: center;
        padding: 0 15px;
        text-transform: uppercase;
        transition: all 0.1s ease-in-out;
        &:hover {
            box-shadow: 0px 0px 10px -4px #000000;
            transform: scale(1.01);
        }
        &:disabled {
            opacity: 0.4;
        }
    }

    small {
        margin-left: -15px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        width: 34px;
        border-radius: 7px;
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
    }
`;

export const BlockUsersTable = styled.div`
    width: 100%;
    padding: 0 0 1px;
    border-radius: 8px;
    background-color: #212032;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #ffffff;
    max-width: 100%;

    th {
        background-color: #1c1b2d;
        padding: 12px 0.4em;
        text-transform: uppercase;
        &:first-child {
            padding-left: 18px;
        }
    }

    tr {
        cursor: pointer;
        background-color: #212032;
        &:nth-child(even) {
            background-color: #242339;
        }

        &:not(:last-child):hover {
            background-color: #2f2e3e;
        }

        .line-controls {
            display: flex;
            justify-content: flex-end;
        }

        td {
            padding: 10px 0.5em;
            &:first-child {
                padding-left: 18px;
            }
            button {
                &.action-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    color: #ff9460;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 12px;
                    padding: 5px 10px 5px 0;

                    &:hover {
                        color: #ff6379;
                    }
                    img {
                        margin-right: 5px;
                        width: 16px;
                    }
                }
                &.edit {
                    margin-right: 15px;
                }
            }
        }
    }

    .MuiTableCell-root.MuiTableCell-body {
        border-bottom: none;
    }

    .MuiPaginationItem-ellipsis {
        color: #fff;
    }

    .pagination {
        display: flex;
        justify-content: flex-end;

        .iconsNext, .iconsPrev {
            margin: 0 1em;
            height: 19px;
            cursor: pointer;
            width: 20px;
        }

        .iconsPrev { 
            transform: scaleX(-1);            
        }

        button {            
            background: transparent;
            border: none;
            &:disabled {
                opacity: 0.2;
            }
        }
        
        .MuiPaginationItem-textPrimary.Mui-selected {
            background-color: #ff6379;
        }

    }

    .table-footer {
        background-color: #1c1b2d;
        td {
            padding: 12px 0.4em;
        }
        select {
            background: #24243e;
            border: 0;
            border-radius: 6px;
            letter-spacing: 0.21px;
            color: #ffffff;
            margin: 0 1em;
            height: 2em;

            &:focus {
                border: solid 1px #ff6479;
            }
        }
    }


    @media (max-width: 650px) {
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0;
        @media (max-width: 650px) {
            max-height: 40vh;
        }
    }
`;

export const Header = styled.div`
    height: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    max-width: 100%;

    @media (max-width: 650px) {
        padding: 0 1em;
    }
`;

export const TableFooterResults = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .table-footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.8rem 1rem;
        border-radius: 0 0 8px 8px;
        background-color: #1c1b2d;
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        color: #ffffff;
    }
    span {
        font-size: .8rem;
        min-width: 200px;
        text-align: center;
        align-self: center;
    }

`;

export const LoadMoreButtonSection = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    button {
        color: #ff9162;
        margin: 5px auto;
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 387px;
        border: 0;
        background: transparent;
        font-size: 1.1rem;
        margin: 0 auto;
        color: #fff;
        background: linear-gradient(
            90deg,
            rgba(255, 174, 70, 1) 0%,
            rgba(255, 66, 141, 1) 100%
        );
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 3;
        letter-spacing: 0.21px;
        border-radius: 8px;
        text-transform: uppercase;

        &:disabled {
            opacity: 0.2;
        }
    }
`;
