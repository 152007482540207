import styled from 'styled-components';

export const BroadcastClipBoardItemContainer = styled.div`
    width: 100%;
    min-height: 64px;
    display: flex;
    background-color: #212032;
    padding: 7px;
    border-radius: 5px;
    position: relative;
    margin-bottom: 20px;
`;

export const BroadcastUrlArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    word-break: break-all;
`;

export const BroadcastUrlTitle = styled.h3`
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
`;

export const BroadcastUrl = styled.h3`
    color: #ff8e63;
    font-weight: bold;
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const UrlClipboardArea = styled.div`
    height: 100%;
    width: 20px;

    img {
        top: 7px;
        width: 17px;
        cursor: pointer;
    }
`;
