import styled from 'styled-components';

export const DownloadVideoButtonContainer = styled.div`

  width: 100%;
  display: flex;

    .liveVideoDownloadButton {
        width: 100%;
        margin: 30px auto;
        display:flex;
        justify-content:center;
        padding: 5px;
        border: 0;
        color:#fff;
        background-color: #ff8560;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 3;
        letter-spacing: 0.21px;
        border-radius: 5px;
        text-align: center;
        align-items: center;
        transition: opacity .3s ease;

        opacity: ${(p) => (p.disabled ? '0.3' : '1')};

        img {
            margin-right: 1rem;
        }

        &:hover {
        opacity: 0.3
        }
    }

    .download-video-button {
            display: none;
        }

`;
