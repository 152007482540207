import React from 'react';

import { ButtonHeaderContainer } from './styles';

const ButtonHeader = ({ onClick, icon, title }) => (
    <ButtonHeaderContainer>
        <button
            type="button"
            onClick={onClick}
        >
            <small>
                <img alt={title} src={icon} />
            </small>
            <span>{title}</span>
        </button>
    </ButtonHeaderContainer>
);

export default ButtonHeader;
