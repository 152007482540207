/* eslint-disable indent */
import actions from '../../../utils/actions';

const initialState = {
    users: [],
    loading: true,
    totalPages: null,
    nextReportLoading: false,
    nextToken: null,
    fromSearch: false,
    verified: false,
};
export default function presenceReportReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.PRESENCE_REPORT_REQUEST:
            return { ...state, loading: true };
        case actions.PRESENCE_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                users: payload.result.users,
                nextToken: payload.result.nextToken,
                totalPages: payload.result.totalPages,
                fromSearch: payload.fromSearch,
            };
        case actions.PRESENCE_REPORT_FAILURE:
            return { ...state, loading: false, nextReportLoading: false, users: [] };
        case actions.PRESENCE_REPORT_NEXT_PAGE:
            return { ...state, nextReportLoading: true };
        case actions.PRESENCE_REPORT_NEXT_PAGE_SUCCESS:
            return {
                ...state,
                users: [...state.users, ...payload.users],
                nextToken: payload.nextToken,
                nextReportLoading: false,
            };
        case actions.PRESENCE_REPORT_VERIFIED:
            return { ...state, verified: payload };
        default:
            return state;
    }
}
