import { useSelector, shallowEqual } from 'react-redux';

function captionsSelector({ captions }) {
    return {
        captionList: captions.captionList || [],
        isFetching: captions.isFetching,
        removingList: captions.removingList,
        showUploadModal: captions.showUploadModal,
        editCaptionObject: captions.editCaptionObject,
    };
}

export default function useCaptions() {
    const {
        captionList,
        isFetching,
        removingList,
        showUploadModal,
        editCaptionObject,
    } = useSelector(captionsSelector, shallowEqual);
    return {
        captionList,
        isFetching,
        removingList,
        showUploadModal,
        editCaptionObject,
    };
}
