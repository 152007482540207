import styled from 'styled-components';

export const Container = styled.div`
    background-color: #24243e;
    border-radius: 6px;
    margin-bottom: 7px;
    &.--loading {
        display: flex;
        justify-content: center;
        min-height: 85px;
        align-items: center;
    }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;  
  height: 96px;
  color: #444;
  background-color: #24243e;
  position: relative;
  div.upload-info {
    width: 28px;
    height: 28px;

    position: absolute;
    top: 6px;
    right: 8px;

    button {
      background-color: #fcefe2;
      border-radius: 50%;
      padding: 7px 8px;
      transition: background-color 0.2s;

      &:hover {
        background-color: #fdf9f5;
      }
    }
  }
`;

export const Preview = styled.div`
  width: 54px;
  height: 54px;
  object-fit: cover;
  border-radius: 50%;  
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
`;

export const AttachIcon = styled.img`
  width: 30px;
  height: 44px;
`;

export const FileInfo = styled.div`
  margin-top: 7px;
  height: 17px;  
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  color: #989797;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  hyphens: auto;
  abbr {
    text-decoration: none;
  }
`;
