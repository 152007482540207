import { createGlobalStyle } from 'styled-components';
import 'react-circular-progressbar/dist/styles.css';

export default createGlobalStyle`

html {
   overflow: overlay;
}

*{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*:focus{
  outline: 0;
}

html, body, #root {
  height: 100%;
  background-color: #282b3a;
}

body {
  -webkit-font-smoothing: antialiased !important;
}

button {
  cursor: pointer;
}

body, input, button {
  font: 14px 'Open Sans', sans-serif;
}

a{
  text-decoration: none;
}

ul{
  list-style: none
}

.--pulse {
    &:hover {
        animation: pulse 1s infinite;
    }

    @-webkit-keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(255,172,71, 0.3);
        }
        70% {
            -webkit-box-shadow: 0 0 0 10px rgba(255,67,141, 0.3);
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(255,67,141, 0.3);
        }
        }
        @keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0 rgba(255,172,71, 0.3);
            box-shadow: 0 0 0 0 rgba(255,172,71, 0.3);
        }
        70% {
            -moz-box-shadow: 0 0 0 10px rgba(255,67,141, 0.3);
            box-shadow: 0 0 0 10px rgba(255,67,141, 0.3);
        }
        100% {
            -moz-box-shadow: 0 0 0 0 rgba(255,67,141, 0.3);
            box-shadow: 0 0 0 0 rgba(255,67,141, 0.3);
        }
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #1b1d2b;
    border-radius: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #5555;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .mantine-kekquh {
    border-radius: 10px;
    border-color: #34334e ;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #34334e;
    flex-direction: column;
    display: flex;
    padding: 8px;
    margin-left: -12em;
    border-top-right-radius: 0;
    width: 210px;
   
    button {
      display: flex;
      padding: 0.5em;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      margin: 4px;
      letter-spacing: 0.29px;
      color: #ffffff;
      background: transparent;
      border: 0;
      transition: all 100ms cubic-bezier(0.075, 0.82, 0.165, 1);
      transform: scale(1.05);
      opacity: 1;
      text-transform: uppercase;
      
      img {   
        width: 20px;
        height: 20px;
        margin-right: 10px;
        filter: grayscale(1);
        
        &.emphasis {
          filter: brightness(4.5);
        }
      }

      &:hover {
        transform: scale(1.05);
        color: #fe8d62;
        img {
          transform: scale(1.05);
          filter: none;
            
        }
      }

    }

    hr {
        border: 1px solid #1e1d30;
    }
  }
  
  svg.onboardingOverlay {
  opacity: 50%;
}
`;
