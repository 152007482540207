import React, { useState } from 'react';
import arrowDownIcon from '../../assets/arrow-down-icon.svg';

import { Container, Content } from './styles';

export const Accordion = ({
    heading,
    children,
}) => {
    const [isActive, setIsActive] = useState(false);
    return (
        <>
            <Container onClick={() => setIsActive(!isActive)}>
                <div>
                    <h3>{heading}</h3>
                    <button onClick={() => setIsActive(!isActive)} type="button">
                        <img src={arrowDownIcon} alt="arrow-icons" />
                    </button>
                </div>
            </Container>

            <Content showContent={isActive}>
                {children}
            </Content>

        </>
    );
};
