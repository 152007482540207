import { combineReducers } from 'redux';

import login from './auth/reducer';
import room from './rooms/reducer';
import teams from './teams/reducer';
import offers from './offers/reducer';
import recoverPassword from './recoverPassword/reducer';
import users from './users/reducer';
import presenceReport from './presenceReport/reducer';
import quizzes from './quizzes/reducer';
import gamification from './gamification/reducer';
import blockList from './blockList/reducer';
import captions from './captions/reducer';
import roomsProgress from './roomsProgress/reducer';

export default combineReducers({
    login,
    room,
    teams,
    offers,
    recoverPassword,
    users,
    presenceReport,
    quizzes,
    gamification,
    blockList,
    captions,
    roomsProgress,
});
