import React, {useState, useEffect, useMemo, Suspense} from 'react';
import { useDispatch } from 'react-redux';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getRoomGraph, getRoomGeolocalization } from '../../../store/modules/rooms/actions';
import { toISOLocal } from '../../../utils/functions';
import { DropDownContainer } from './styles';
import SelectedIcon from '../../../assets/checked.svg';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { TIMERS } from '../../../utils/constants';
import useAuth from '../../../hooks/useAuth';
import { registerLocale } from  "react-datepicker";
import pt from 'date-fns/locale/pt-BR';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';

const TimeFilter = ({roomId, presetTimeFilter, setPresetTimeFilter}) => {
    const { user } = useAuth();
    const language = user.language.split('-')[0].toLowerCase();
    const switchLanguage = language => ({
        'es': registerLocale(language, es),
        'en': registerLocale(language, en),
        'pt': registerLocale(language, pt),
    })[language];
    
    switchLanguage(language);

    const [filterType, setFilterType] = useState('preset');
    const [startDate, setStartDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [fromTime, setFromTime] = useState('00:00');
    const [endDate, setEndDate] = useState();
    const [toDate, setToDate] = useState('');
    const [toTime, setToTime] = useState('23:00');
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handlePreset = (option) => {
        setToDate(toISOLocal(new Date()))
        setPresetTimeFilter(presetOptions.indexOf(option))
        switch (presetOptions.indexOf(option)) {
            case 0:
                setFromDate(toISOLocal(new Date(Moment().subtract(1, 'minutes').toLocaleString())));
                break;
            case 1:
                setFromDate(toISOLocal(new Date(Moment().subtract(5, 'minutes').toLocaleString())));
                break;
            case 2: 
                setFromDate(toISOLocal(new Date(Moment().subtract(30, 'minutes').toLocaleString())));
                break;
            case 3: 
                setFromDate(toISOLocal(new Date(Moment().subtract(1, 'hours').toLocaleString())));
                break;
            case 4: 
                setFromDate(toISOLocal(new Date(Moment().subtract(3, 'hours').toLocaleString())));
                break;
            case 5: 
                setFromDate(toISOLocal(new Date(Moment().subtract(6, 'hours').toLocaleString())));
                break;
            case 6: 
                setFromDate(toISOLocal(new Date(Moment().subtract(24, 'hours').toLocaleString())));
                break;
            case 7: 
                setFromDate(toISOLocal(new Date(Moment().subtract(3, 'days').toLocaleString())));
                break;
            case 8: 
                setFromDate(toISOLocal(new Date(Moment().subtract(7, 'days').toLocaleString())));
                break;
            default:
                break;
        }
    }

    const handleRangeFilter = () => {
        let timeFrom = fromTime.split(':');
        let timeTo = toTime.split(':');
        setFromDate(toISOLocal(new Date(Moment(startDate).add(timeFrom[0], 'hours').add(timeFrom[1], 'minutes').toLocaleString())))
        setToDate(toISOLocal(new Date(Moment(endDate).add(timeTo[0], 'hours').add(timeTo[1], 'minutes').toLocaleString())))
    }

    // eslint-disable-next-line
    const DatePickerRange = useMemo(() => React.lazy(() => import('../../../components/DatePickerRange')), [startDate])

        
    const presetOptions = t('FILTER', { context: 'PRESETOPTIONS', returnObjects: true });

    useEffect(() => {
        if (fromDate && toDate) {
            dispatch(getRoomGraph(roomId, fromDate, toDate))
            dispatch(getRoomGeolocalization(roomId, fromDate, toDate))
        }
    // eslint-disable-next-line
    }, [fromDate, toDate])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (roomId) {
                dispatch(getRoomGraph(roomId, fromDate, toDate));
                dispatch(getRoomGeolocalization(roomId, fromDate, toDate));
            }
        }, TIMERS.REFRESH_REPORTS)

        return () => clearTimeout(timer)
    // eslint-disable-next-line        
    }, [fromDate, startDate]);

    return (
    <DropDownContainer >
        <div className="dropdown">
             <div className="type-buttons">
                <Button 
                    className={filterType === 'preset' ? 'selected' : ''} 
                    onClick={() => setFilterType('preset')}
                    variant="contained" 
                    color="primary" 
                    id='preset'>
                    {t('PRESET')}
                </Button>
                <Button 
                    className={filterType === 'range' ? 'selected' : ''} 
                    onClick={() => setFilterType('range')}
                    variant="contained" 
                    color="primary" 
                    id='date-range'>
                    {t('FILTER_SPECIFIC')}
                </Button>
            </div>
            { filterType === 'preset' ? 
            (<div className="preset">
                <h4>{t('LAST')}</h4>
                <ul>
                    {presetOptions ? presetOptions.map((option, index) => {
                        
                        return (
                        <li key={index} onClick={(e) => handlePreset(option)}>
                            {option}
                            {presetOptions.indexOf(option) === presetTimeFilter ? 
                            <img alt="Selected Icon" src={SelectedIcon}/> : ''}
                        </li>
                    )}
                    ) : ''}
                </ul>
            </div>)
            : 
            <div className="range">
            <Suspense fallback={<div>{t('LOADING')}</div>}>
                <DatePickerRange id="start"
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    language={language}
                    setEndDate={setEndDate}
                    dateMask="dd/MM/yyyy"
                    showTimeInput={false}
                    inline />
                <h4>
                    <span>{t('FROM_TIME')}:</span>      
                    <TextField
                        id="time"
                        type="time"
                        value={fromTime}
                        className="timeFrom"
                        onChange={(e) => setFromTime(e.target.value)}
                        inputProps={{
                        step: 60, 
                        }}
                    /> 
                </h4>
                <h4>
                    <span>{t('TOTIME')}:</span> 
                    <TextField
                        id="time"
                        type="time"
                        value={toTime}
                        className="timeTo"
                        onChange={(e) => setToTime(e.target.value)}
                        inputProps={{
                        step: 60, 
                        }}
                    /> 
                </h4>
                <button className="submitButton" onClick={handleRangeFilter}><p>{t('APPLY')}</p></button>
            </Suspense>
            </div>
            }

        </div>
    </DropDownContainer> 
    )
}

export default React.memo(TimeFilter);