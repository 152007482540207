import actions from '../../../utils/actions';

// Report
export function getPresenceReport(roomId, rowsPerPage, keyword = '') {
    return {
        type: actions.PRESENCE_REPORT_REQUEST,
        payload: { roomId, rowsPerPage, keyword },
    };
}

export function getPresenceReportSuccess(result, fromSearch = false) {
    return {
        type: actions.PRESENCE_REPORT_SUCCESS,
        payload: { result, fromSearch },
    };
}

// course
export function getPresenceReportFailed() {
    return { type: actions.PRESENCE_REPORT_FAILURE };
}

export function setVerified(verifiedStatus) {
    return {
        type: actions.PRESENCE_REPORT_VERIFIED,
        payload: verifiedStatus,
    };
}

export function getNextPagePresenceReport(roomId, nextToken, rowsPerPage) {
    return {
        type: actions.PRESENCE_REPORT_NEXT_PAGE,
        payload: {
            roomId,
            nextToken,
            rowsPerPage,
        },
    };
}

export function getNextPagePresenceReportSuccess(data) {
    return {
        type: actions.PRESENCE_REPORT_NEXT_PAGE_SUCCESS,
        payload: {
            nextToken: data.nextToken,
            users: data.users,
        },
    };
}
