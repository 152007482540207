import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAuth from '@hooks/useAuth';
import useSize from '@hooks/useSize';
import { changeOnBoardingStatus, setOnBoarding } from '@src/store/modules/auth/actions';
import langs from '@src/assets/i18n/constants';
import { Onboarding } from './styles';

export default function OnboardingUsers() {
    const [isTourOpen, setTourOpen] = useState(false);
    const { width } = useSize();
    const { user, onBoarding } = useAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeOnBoardingStatus());
    }, [dispatch]);

    useEffect(() => {
        if (onBoarding?.data) {
            if (!(onBoarding.data.onBoardingOrganizerUsers) && !(onBoarding.skipped)) {
                setTourOpen(true);
            }
        }
    }, [dispatch, onBoarding]);

    useLayoutEffect(() => {
        if (isTourOpen) {
            const originalStyle = window.getComputedStyle(document.body).overflow;
            document.body.style.overflow = 'hidden';
            return () => {
                document.body.style.overflow = originalStyle;
            };
        }

    }, [isTourOpen]);

    function handleCloseOnboarding(key, skipped, global_skip) {
        dispatch(setOnBoarding(key, skipped, global_skip));
        setTourOpen(false);
    }

    function usersArea() {
        return (
            {
                level: 'all',
                selector: '',
                content: ({ goTo }) => (
                    <>
                        <h2>{t(langs.ONBOARDING_USERS_AREA_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_USERS_AREA)}</p>
                        <div className="buttons">
                            <button
                                type="button"
                                className="btn continue-btn"
                                onClick={() => goTo(1)}
                            >
                                {t(langs.ONBOARDING_LETS_START)}
                            </button>
                            <button
                                type="button"
                                className="btn ignore-btn"
                                onClick={() => handleCloseOnboarding('onBoardingOrganizerUsers', true)}
                            >
                                {t(langs.ONBOARDING_SKIP_TOUR)}
                            </button>

                        </div>
                    </>
                ),
                position: 'center',
            }
        );
    }

    function search() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__search"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_USERS_SEARCH_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_USERS_SEARCH)}</p>
                    </>
                ),
            }
        );
    }

    function options() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__more_options"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_USERS_OPTIONS_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_USERS_OPTIONS)}</p>
                    </>
                ),
            }
        );
    }

    function containerTable() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__container_table"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_USERS_CONTAINER_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_USERS_CONTAINER)}</p>
                    </>
                ),
            }
        );
    }

    function containerTableMobile() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__container_table"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_USERS_CONTAINER_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_USERS_CONTAINER)}</p>
                        <button
                            type="button"
                            className="btn complete-btn"
                            onClick={() => handleCloseOnboarding('onBoardingOrganizerUsers')}
                        >
                            {t(langs.ONBOARDING_COMPLETE)}
                        </button>
                    </>
                ),
            }
        );
    }

    function iconDots() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__icon_dots"]',
                content: () => (
                    <>
                        <h2>{t(langs.ONBOARDING_USERS_ICON_DOTS_TITLE)}</h2>
                        <p>{t(langs.ONBOARDING_USERS_ICON_DOTS)}</p>
                        <button
                            type="button"
                            className="btn complete-btn"
                            onClick={() => handleCloseOnboarding('onBoardingOrganizerUsers')}
                        >
                            {t(langs.ONBOARDING_COMPLETE)}
                        </button>
                    </>
                ),
            }
        );
    }

    const mobileSteps = [
        usersArea(),
        search(),
        options(),
        containerTableMobile(),
    ];

    const desktopSteps = [
        usersArea(),
        search(),
        options(),
        containerTable(),
        iconDots(),
    ];

    function defineSteps(width, user) {
        const filteredSteps = width > 800 ? desktopSteps : mobileSteps;

        if (!(user.admin || user.organizer)) {
            const userSteps = filteredSteps.filter(step => step.level === 'all');
            return userSteps;
        }

        return filteredSteps;
    }

    const steps = defineSteps(width, user);

    return (
        <Onboarding
            steps={steps}
            isOpen={(isTourOpen)}
            maskClassName="onboardingOverlay"
            accentColor="#ff438d"
            maskSpace={6}
            disableInteraction
            closeWithMask={false}
            rounded={4}
            onRequestClose={() => handleCloseOnboarding('onBoardingOrganizerUsers', true)}
            closeButtonAriaLabel={t(langs.ONBOARDING_COMPLETE)}

        />
    );
}
