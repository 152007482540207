import React, { useEffect, useState } from "react";
import { Accordion } from "@components/Accordion";
import { useTranslation } from "react-i18next";
import { QUIZ_TYPE } from "@src/utils/constants";
import { numberToLetter } from "@src/utils/functions";

import { SkeletonTheme } from "react-loading-skeleton";
import { Skeleton } from "@material-ui/lab";
import { CustomInput } from "../QuizzesControl/styles";
import langs from "@langs/constants";
import {
    AccordionContainer,
    QuestionContent,
    CustomTextArea,
    SelectOptions,
    ContentLabelTextArea,
} from "./styles";
import { ErrorMessage } from "../styles";
import useRooms from "@src/hooks/useRooms";

const LoadingSkeleton = () => (
    <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
        <div style={{ marginTop: 10 }}>
            <Skeleton height={50} count={10} style={{ margin: "5px 0" }} />
        </div>
    </SkeletonTheme>
);
export const QuestionAccordion = ({
    disabledFields,
    question,
    answers,
    questionNumber,
    questionIndex,
    gamificationPoints,
    onChange,
    onChangeAnswers,
    correctAnswer,
    type,
    register,
    errors,
    loading,
    handleKeyDown
}) => {
    const [isAnswerless, setIsAnswerless] = useState("");
    const { t } = useTranslation();
    const { selectedChannel, channelsListForSelectSidebar } = useRooms();
    const [isGamificationActive, setIsGamificationActive] = useState(false);
    const channelId = selectedChannel?.id;

    useEffect(() => {
        const foundChannelGamificationStatus = channelsListForSelectSidebar.find(channel => channel.id === channelId);
        if (foundChannelGamificationStatus) {
            setIsGamificationActive(foundChannelGamificationStatus.gamificationEnabled);
        }
    }, [channelId, channelsListForSelectSidebar]);

    useEffect(() => {
        !answers?.a || !answers?.b
            ? setIsAnswerless(true)
            : setIsAnswerless(false);
    }, [answers]);

    const CounterCharacters = ({ value }) => {
        let characters = 300;
        if (value) {
            characters = characters - value.length
        }
        return <h5><span>{characters}</span> {t(langs.OPTION_CHARACTERS_INFO)}.</h5>;
    }
      
    return (
        <>
            {loading ? (
                <LoadingSkeleton />
            ) : (
                <AccordionContainer>
                    <Accordion
                        heading={`${t(langs.QUESTION)} ${
                            questionNumber !== 10 ? "0" : ""
                        }${questionNumber}`}
                    >
                        <QuestionContent disabledFields={disabledFields}>
                            <div className="questionHeading">
                                <div className="mainQuestion">
                                    <h4>{t("QUESTION")}</h4>
                                    <CustomInput
                                        disabledFields={disabledFields}
                                        id="question"
                                        name="question"
                                        placeholder={t(langs.TYPE_QUESTION)}
                                        onKeyDown={handleKeyDown}
                                        {...register(
                                            `question${questionIndex}`,
                                            {
                                                value: question,
                                                onChange: onChange,
                                            }
                                        )}
                                    />
                                    <ErrorMessage>
                                        {errors &&
                                            errors?.[
                                                String(
                                                    `question${questionIndex}`
                                                )
                                            ]?.message}
                                    </ErrorMessage>
                                </div>
                                {isGamificationActive ? 
                                (
                                    <div>
                                    <h4>{t(langs.QUESTION_SCORE)}</h4>
                                    <CustomInput
                                        id="gamificationPoints"
                                        name="gamificationPoints"
                                        inputProps={{ min: "1", step: "1" }}
                                        type="number"
                                        onKeyDown={handleKeyDown}
                                        {...register(
                                            `gamificationPoints${questionIndex}`,
                                            {
                                                value: gamificationPoints,
                                                onChange: onChange,
                                            }
                                        )}
                                    />
                                    <ErrorMessage>
                                        {errors &&
                                            errors?.[
                                                String(
                                                    `gamificationPoints${questionIndex}`
                                                )
                                            ]?.message}
                                    </ErrorMessage>
                                </div>
                                ) : ''}
                                {QUIZ_TYPE.QUIZ === type && (
                                    <div className="cont-select-quiz">
                                        <h4>{t(langs.CORRECT_ANSWER)}</h4>
                                        {isAnswerless ? (
                                            <SelectOptions
                                                disabledFields={disabledFields}
                                                name="correctAnswer"
                                                id="correctAnswer"
                                                onKeyPress={handleKeyDown}
                                            >
                                                <option>
                                                   {t(langs.REQUIRED_QUESTIONS_FIELDS)}
                                                </option>
                                            </SelectOptions>
                                        ) : (
                                            <SelectOptions
                                                disabledFields={disabledFields}
                                                name="correctAnswer"
                                                id="correctAnswer"
                                                onChange={onChange}
                                                value={correctAnswer}
                                                onKeyPress={handleKeyDown}
                                            >
                                                {[
                                                    answers?.a,
                                                    answers?.b,
                                                    answers?.c,
                                                    answers?.d,
                                                ]
                                                    .filter(
                                                        (item) =>
                                                            item !== "" &&
                                                            item !== undefined
                                                    )
                                                    .map((value, index) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                name={numberToLetter(
                                                                    index
                                                                )}
                                                                value={numberToLetter(
                                                                    index
                                                                )}
                                                            >
                                                                ITEM {numberToLetter(index).toUpperCase()}
                                                            </option>
                                                        );
                                                    })}
                                            </SelectOptions>
                                        )}
                                    </div>
                                )}
                                <div />
                            </div>

                            <div className="questionsBody" name="options">
                                <div>
                                    <ContentLabelTextArea>
                                        <h4 htmlFor="option-a">Item A</h4>
                                        <CounterCharacters value={answers?.a || answers?.[0]} />
                                    </ContentLabelTextArea>
                                    <CustomTextArea
                                        id="option-a"
                                        name="a"
                                        data-ls-module="charCounter"
                                        maxLength={300}
                                        placeholder="Item A"
                                        onKeyDown={handleKeyDown}
                                        {...register(`a${questionIndex}`, {
                                            value: answers?.a || answers?.[0],
                                            onChange: onChangeAnswers,
                                        })}
                                    />
                                    <ErrorMessage>
                                        {errors &&
                                            errors?.[
                                                String(`a${questionIndex}`)
                                            ]?.message}
                                    </ErrorMessage>
                                </div>

                                <div disabled={false}>
                                    <ContentLabelTextArea>
                                        <h4 htmlFor="option-b">Item B</h4>
                                        <CounterCharacters value={answers?.b || answers?.[1]} />
                                    </ContentLabelTextArea>
                                    <CustomTextArea
                                        id="option-b"
                                        placeholder="Item B"
                                        name="b"
                                        maxLength={300}
                                        onKeyDown={handleKeyDown}
                                        {...register(`b${questionIndex}`, {
                                            value: answers?.b || answers?.[1],
                                            onChange: onChangeAnswers,
                                        })}
                                    />
                                    <ErrorMessage>
                                        {errors &&
                                            errors?.[
                                                String(`b${questionIndex}`)
                                            ]?.message}
                                    </ErrorMessage>
                                </div>
                            </div>

                            <div className="questionsBody">
                                <div disabled={false}>
                                    <ContentLabelTextArea>
                                        <h4 htmlFor="option-c">
                                            Item C<span> {t(langs.OPTIONAL).toUpperCase()}</span>
                                        </h4>
                                        <CounterCharacters value={answers?.c || answers?.[2]} />
                                    </ContentLabelTextArea>
                                    <CustomTextArea
                                        id="option-c"
                                        placeholder="Item C"
                                        name="c"
                                        maxLength={300}
                                        onKeyDown={handleKeyDown}
                                        {...register(`c${questionIndex}`, {
                                            value: answers?.c || answers?.[2],
                                            onChange: onChangeAnswers,
                                        })}
                                    />
                                    <ErrorMessage>
                                        {errors &&
                                            errors?.[
                                                String(`c${questionIndex}`)
                                            ]?.message}
                                    </ErrorMessage>
                                </div>

                                <div disabled={false}>
                                    <ContentLabelTextArea>
                                        <h4 htmlFor="option-d">
                                            Item D<span> {t(langs.OPTIONAL).toUpperCase()}</span>
                                        </h4>
                                        <CounterCharacters value={answers?.d || answers?.[3]} />
                                    </ContentLabelTextArea>
                                    <CustomTextArea
                                        id="option-d"
                                        placeholder="Item D"
                                        name="d"
                                        maxLength={300}
                                        onKeyDown={handleKeyDown}
                                        {...register(`d${questionIndex}`, {
                                            value: answers?.d || answers?.[3],
                                            onChange: onChangeAnswers,
                                        })}
                                    />
                                    <ErrorMessage>
                                        {errors &&
                                            errors?.[
                                                String(`d${questionIndex}`)
                                            ]?.message}
                                    </ErrorMessage>
                                </div>
                            </div>
                        </QuestionContent>
                    </Accordion>
                </AccordionContainer>
            )}
        </>
    );
};

//
