import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import langs from '@langs/constants';
import ButtonDefaultTheme from '../ButtonDefaultTheme';
import CustomFormInput from '../CustomFormInput';
import CourseFormTextArea from '../CustomFormTextArea';
import { KopaMultipleUpload } from '../KopaUpload/MultipleFilesUpload';

import {
    ButtonArea,
    ButtonAreaContainer,
    CourseForm,
    CourseFormTextAreaContainer,
    CourseFormUploadArea,
    CourseFormUploadDropzone,
    CourseFormUploadHeader,
    CourseVideoFormContainer,
    GroupInput,
} from './styles';
import CustomDateFormInput from '../CustomDateFormInput';
import CustomFormTimezoneDropdown from './CustomFormTimezoneDropdown';

const LoadingSkeleton = () => (
    <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
        <div style={{ marginTop: 10 }}>
            <Skeleton height={50} count={10} style={{ margin: '5px 0' }} />
        </div>
    </SkeletonTheme>
);

const CourseVideoForm = ({
    roomName,
    setRoomName,
    roomDescription,
    setRoomDescription,
    roomEditMode,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    timezoneValue,
    setTimezoneValue,
    selectedChannel,
    uploadedMultipleFiles = [],
    setUploadedMultipleFiles,
    handleSubmitForm,
    userData,
    showSkeleton,
    submitButtonDisabled,
}) => {
    const [isUploading, setIsUploading] = useState([]);
    const { t } = useTranslation();

    const isPortal = window.screen.width < 800;

    const schema = yup.object({
        videoTitle: yup.string()
            .min(3, t(langs.AT_LEAST_3_CHARS))
            .required(t(langs.REQUIRED_FIELD)),
    }).required();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: { videoTitle: roomName || '' },
    });

    function countCharacters() {
        let count = 333;
        count -= roomDescription?.length || 0;
        if (count < 0) {
            count = 0;
        }
        return count;
    }

    const handleStartDateValue = (dateValue) => {
        setStartDate(dateValue);
    };

    const handleEndDateValue = (dateValue) => {
        setEndDate(dateValue);
    };

    const handleTitleChange = (e) => {
        setRoomName(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        const { value } = e.target;
        if (value.length <= 333) {
            setRoomDescription(e.target.value);
        }
    };

    useEffect(() => {
        setValue('videoTitle', roomName);
    }, [roomName, setValue]);

    const onSubmit = (data) => {
        if (!isUploading.length) {
            handleSubmitForm(data);
        }
    };

    const renderSubmitButtonText = () => {
        if (isUploading.length) {
            return t(langs.UPLOADING_ATTACHMENTS).toUpperCase();
        }
        if (roomEditMode) {
            return t(langs.SAVE_CHANGES).toUpperCase();
        }
        return t(langs.CHANNEL_CREATEVIDEO).toUpperCase();
    };

    const warnUser = useCallback((e) => {
        if (roomName || roomDescription) {
            e.preventDefault();
            e.returnValue = '';
        }
    }, [roomName, roomDescription]);

    useEffect(() => {
        window.addEventListener('beforeunload', warnUser);
        return () => window.removeEventListener('beforeunload', warnUser);
    }, [roomName, warnUser]);

    return (
        <>
            {showSkeleton ? <LoadingSkeleton /> : (

                <CourseForm>
                    <CourseVideoFormContainer>
                        <div>
                            <CustomFormInput
                                value={roomName}
                                onChange={handleTitleChange}
                                labelTitle={t(langs.CHANNEL_VIDEOTITLE).toUpperCase()}
                                placeholder={t(langs.VIDEO_TITLE_PLACEHOLDER)}
                                register={register('videoTitle', { value: roomName, onChange: handleTitleChange })}
                            />
                            <em>{errors?.videoTitle?.message}</em>
                        </div>
                        <GroupInput>
                            <CustomFormInput
                                value={`${selectedChannel?.name} #${selectedChannel?.id}`}
                                disabled
                                labelTitle={t(langs.CHANNEL_SELECTCOURSE).toUpperCase()}
                            />
                            <CustomFormTimezoneDropdown
                                value={timezoneValue}
                                setTimezoneValue={setTimezoneValue}
                                labelTitle={t(langs.CHANNEL_SELECTTIMEZONE).toUpperCase()}
                                placeholder="Selecione um fuso horário"
                            />
                            <CustomDateFormInput
                                id="calendar-start"
                                dateValue={startDate}
                                isPortal={isPortal}
                                setDateValue={handleStartDateValue}
                                labelTitle={t(langs.CHANNEL_STARTDATE).toUpperCase()}
                                user={userData}
                            />
                            <CustomDateFormInput
                                id="calendar-finishes"
                                dateValue={endDate}
                                isPortal={isPortal}
                                setDateValue={handleEndDateValue}
                                labelTitle={t(langs.CHANNEL_ENDDATE).toUpperCase()}
                                user={userData}
                            />
                        </GroupInput>
                        <CourseFormTextAreaContainer>
                            <CourseFormTextArea
                                labelTitle={t(langs.VIDEO_DESCRIPTION).toUpperCase()}
                                helpText={t(langs.OPTIONAL).toUpperCase()}
                                maxChars={`${countCharacters()} ${t(langs.CHARACTERS)}`.toUpperCase()}
                                placeholder={t(langs.CONTENT_DESCRIPTION_PLACEHOLDER)}
                                value={roomDescription || ''}
                                onChange={handleDescriptionChange}
                            />
                        </CourseFormTextAreaContainer>
                        <CourseFormUploadArea>
                            <CourseFormUploadHeader>
                                <p>{t(langs.CHANNEL_UPLOADFILES).toUpperCase()} <span>{t(langs.OPTIONAL).toUpperCase()}</span></p>
                            </CourseFormUploadHeader>
                            <CourseFormUploadDropzone>
                                <KopaMultipleUpload
                                    multiple
                                    uploadBaseUrl="/config/attachment/upload"
                                    fileType="attachments"
                                    accept=".ppt,.pptx,.doc,.docx,.xls,.xlsx,.pdf,.jpg,.jpeg,.png"
                                    uploadedMultipleFiles={uploadedMultipleFiles}
                                    setUploadedMultipleFiles={setUploadedMultipleFiles}
                                    setIsUploading={setIsUploading}
                                />
                            </CourseFormUploadDropzone>
                        </CourseFormUploadArea>
                    </CourseVideoFormContainer>
                    <ButtonArea>
                        <ButtonAreaContainer>
                            <ButtonDefaultTheme onClick={handleSubmit(onSubmit)} disabled={submitButtonDisabled}>
                                {renderSubmitButtonText()}
                            </ButtonDefaultTheme>
                        </ButtonAreaContainer>
                    </ButtonArea>
                </CourseForm>
            )}
        </>
    );
};

export default CourseVideoForm;
