import styled from 'styled-components';

export const Button = styled.button`
  border: none;
  background: none;
  height: 100%;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }

  span {
    color: #FFF;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.21px;
    text-align: center;
  }  
`;

export const RoomCard = styled.ul`
  display: flex;
  align-self: stretch;
  flex-wrap: wrap;
  margin: 0;
  
  @media(max-width: 500px) {
    flex-direction: column;
    margin: 0;
    align-items: center;
  }

  .--uploading-animation {
    position: absolute;
    display: flex;
    justify-content: center; 
    width: 100%; 
    align-items: center; 
  }

  .--uploading{
    opacity: 0.7;
  }

  .thumb {
    position: relative;

    .thumb-img {
      height: 180px;
      border-radius: 5px 5px 0 0;
      object-fit: cover;
    }

    button {
      img {
        height: 18px;
      }
    }
  }

  .title {
    h4 {
      font-size: 18px;
      margin-left: 5px;
      text-align: left;
    }

    span {
      font-size: 14px;
      border-bottom: 1px solid #979797;
      padding-bottom: 10px;
    }
  }

  .active {
    color: #ff8072;
    display: inline-block;
    font-weight: bold;
    margin-left: 4px;
  }

  .inactive {
    margin-left: 4px;
    display: inline-block;
  }

  .dates {
    span {
      font-size: 12px; 
      padding-bottom: 2px;
    }
  }

  .dropdown {
    left: auto;
    right: -185px;
    width: 176px;
    top: -180px;
    z-index: 10;
    h3 {
      font-size: 11px;
    }

    p {
      font-size: 16px;
      line-height: 1;
      padding: 0 10px;
    }
    .checked {
      ${props => (props.dropIsVisible ? 'display: none;' : '')} 
    }
  }
  
  li {
    margin: 10px 11px;
    border-radius: 8px;
    background-color: #555151;
    color: #FFF;
    display: block;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
    min-height: 150px;
    width: 243px;
    cursor: pointer;
    ${props => (props.dropIsVisible ? '' : 'transition: all .2s ease-in-out;')} 
    

    h2 {    
      font-weight: 800;
      font-size: 21px;
      flex: 2;
      display: flex;
      align-items: center;
      font-stretch: normal;
      font-style: normal;
      position: relative;
      word-break: break-word;
      padding-right: 30px;
    }

    div {
      flex: 4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        padding-bottom: 10%;
      }
      padding-bottom: 2%;
    }

    p {
      font-size: 18;
      font-weight: 600;
    }

    button {
      border-radius: 50%;
      width: 28px;
      height: 28px;
      background-color: #626262;
      position: absolute;
      right: 5px;
      top: 5px;

      img {
        margin: auto;
      }
    }

    span {
      display: flex;
      padding: 0;

      div {
        padding-left: 5px;
      }
    }

    @media(min-width: 280px) {
      li {
        min-width: 275px;
      }
    }

    @media(max-width: 500px) {
      .dropdown {
        right: 0;
        top: -140px;
      }
    }
    
  }

  li:hover{
    ${props => (props.dropIsVisible ? '' : 'transform: scale(1.05);')} 
    .dropdown {
      
    }
  }

  
`;
