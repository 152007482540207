import CONSTANTS from '../constants';

export default {
    [CONSTANTS.ACTIVE]: 'Activo',
    [CONSTANTS.ACCEPTTERMS]: 'Acepto los términos de uso',
    [CONSTANTS.ALREADY_LOGED_IN]: 'Ya estás conectado a otra máquina.',
    [CONSTANTS.ANSWER_DIFFERENT_MANDATORY]: '¡Las respuestas deben ser diferentes!',
    [CONSTANTS.ALL_ANSWERS]: 'TOTAL DE RESPUESTAS',
    [CONSTANTS.APP_LOGIN]: 'INICIAR SESIÓN EN LA APLICACIÓN',
    [CONSTANTS.APPLY]: 'Aplicar',
    [CONSTANTS.ATTACHMENTS]: 'Archivos adjuntos',
    [CONSTANTS.AT_LEAST_3_CHARS]: 'AL MENOS 3 CARACTERES',
    [CONSTANTS.BACKTO]: 'Regresar a',
    [CONSTANTS.BACK_INITIAL_PAGE]: 'VOLVER A LA PÁGINA INICIAL',
    [CONSTANTS.BACK_TO_GUEST_PRESENTER_LIST]: 'VOLVER A LA LISTA DE INVITADOS',
    [CONSTANTS.BITRATE]: 'Bitrate',
    [CONSTANTS.BLOCK_USER_BY_CHAT]: 'Usa el chat para bloquear a un usuario.',
    [CONSTANTS.BUFFER_CLASSIFICATION]: 'Clasificación de búfer',
    [CONSTANTS.BUFFER_CLASSIFICATION_ABREVIATION]: 'Clas. de búfer',
    [CONSTANTS.DISABLED_CAMERA]: 'Cámara Desactivada',
    [CONSTANTS.CANCEL]: 'Cancelar',
    [CONSTANTS.CLICK_TO_ENDLIVE]: 'Haga clic para terminar en vivo',
    [CONSTANTS.CLICK_TO_GOLIVE]: 'Haga clic para comenzar en vivo',
    [CONSTANTS.CANT_GOLIVE_YET]: 'La sala aún no está disponible, espere',
    [CONSTANTS.CHANGES_SUCCESS]: 'Cambio realizado con éxito',
    [CONSTANTS.CHANGES_FAIL]: 'No se pudieron realizar cambios',
    [CONSTANTS.CHANNEL_ACCEPTED_FORMATS]: 'Formatos aceptados',
    [CONSTANTS.CHANNEL_ADDNEWVIDEO]: 'Nuevo video',
    [CONSTANTS.CHANNEL_ADDNEWLIVEVIDEO]: 'En vivo | En vivo',
    [CONSTANTS.CHANNEL_ADDNEWRECORDEDVIDEO]: 'Carga de video',
    [CONSTANTS.CHANNEL_CHAT_ENABLED]: 'CHAT',
    [CONSTANTS.CHANNEL_CLEANVIDEO]: 'Borrar video',
    [CONSTANTS.CHANNEL_COPIED]: 'Copiado',
    [CONSTANTS.CHANNEL_CREATEVIDEO]: 'Crear video',
    [CONSTANTS.CHANNEL_CREATEVIDEO2]: 'Crear nuevo video',
    [CONSTANTS.CHANNEL_EDITVIDEO]: 'Editar video',
    [CONSTANTS.CHANNEL_ENDDATE]: 'FECHA Y HORA FINAL',
    [CONSTANTS.CHANNEL_ENDLIVE]: 'End live!',
    [CONSTANTS.CHANNEL_ENDLIVEDESCRIPTION]: '¿De verdad quieres terminar la transmisión?',
    [CONSTANTS.CHANNEL_ENDLIVEIRREVERSIBLE]: 'Esta operación no se puede deshacer',
    [CONSTANTS.CHANNEL_FILES]: 'Archivo',
    [CONSTANTS.CHANNEL_FILEFORMAT]: 'Los archivos deben tener los siguientes formatos: PPT, PPTX, DOC, DOCX, PDF, JPG, PNG',
    [CONSTANTS.CHANNEL_GOLIVE]: 'Go live!',
    [CONSTANTS.CHANNEL_GOLIVE_UNAVAILABLE]: 'Go live indisponible!',
    [CONSTANTS.CHANNEL_MYVIDEOS]: 'Mis vídeos',
    [CONSTANTS.CHANNEL_NEWVIDEO]: 'Nuevo video',
    [CONSTANTS.CHANNEL_NOVIDEO]: 'No hay videos registrados actualmente',
    [CONSTANTS.CHANNEL_PRIMARYURL]: 'URL PRINCIPAL',
    [CONSTANTS.CHANNEL_PRIMARY_PASSWORD]: 'CONTRASEÑA DE LA URL PRINCIPAL',
    [CONSTANTS.CHANNEL_SECONDARY_PASSWORD]: 'CONTRASEÑA DE LA URL SECUNDARIA',
    [CONSTANTS.CHANNEL_PRODUCER_LINK]: 'Enlace productor',
    [CONSTANTS.CHANNEL_PRODUCER_BACKUP_LINK]: 'Enlace de respaldo del productor',
    [CONSTANTS.CHANNEL_ROOMNOTFOUND]: 'Habitación no encontrada',
    [CONSTANTS.CHANNEL_ROOM_LOAD_DATA_ERROR]: 'Hubo un error al cargar los datos',
    [CONSTANTS.CHANNEL_SECONDARYURL]: 'URL SECUNDARIA',
    [CONSTANTS.CHANNEL_SELECTCOURSE]: 'EL VIDEO PERTENECE AL EVENTO',
    [CONSTANTS.CHANNEL_SELECTCOURSEPLACEHOLDER]: 'Seleccione un curso',
    [CONSTANTS.CHANNEL_SELECTTIMEZONE]: 'SELECCIONE LA ZONA HORARIA',
    [CONSTANTS.CHANNEL_SENDVIDEO]: 'Subir video',
    [CONSTANTS.CHANNEL_STARTDATE]: 'FECHA Y HORA DE INICIO',
    [CONSTANTS.CHANNEL_THUMBNAIL]: 'Miniatura',
    [CONSTANTS.CHANNEL_THUMBNAILSIZE]: 'Tamaño mínimo 300x300',
    [CONSTANTS.CHANNEL_UPLOADFILES]: 'SUBIR ARCHIVO',
    [CONSTANTS.CHANNEL_UPLOADVIDEO]: 'CARGA DE VIDEO',
    [CONSTANTS.CHANNEL_UPLOADSPREADSHEET]: 'UPLOAD XLSX',
    [CONSTANTS.CHANNEL_VIDEOCOVER]: 'PORTADA DE VIDEO',
    [CONSTANTS.CHANNEL_VIDEODESCRIPTION]: 'DESCRIPCIÓN DEL VIDEO',
    [CONSTANTS.CHANNEL_VIDEOFORMAT]: 'El video debe tener los siguientes formatos: MP4, MKV',
    [CONSTANTS.CHANNEL_VIDEOTITLE]: 'TÍTULO DO VÍDEO',
    [CONSTANTS.VIDEO_STATS]: 'Estadísticas de vídeo',
    [CONSTANTS.CHAT_INTERACTION]: 'INTERACCIÓN DE CHARLA',
    [CONSTANTS.CHAT_INTERACTION_RULES]: 'La regla de interacción de chat se refiere a la puntuación obtenida por el participante al realizar su primera interacción en el chat en vivo. Recuerde, el puntaje es solo para la primera interacción.',
    [CONSTANTS.CHUNK_UPLOAD_FAILURE]: 'No se pudo subir a [ROOM_NAME]. Data: [ERROR_DATA]',
    [CONSTANTS.COMEBACK]: 'Vuelve',
    [CONSTANTS.CONFIRM]: 'Confirmar',
    [CONSTANTS.CONTENT_DESCRIPTION_PLACEHOLDER]: 'Escribe aquí el contenido de tu descripción',
    [CONSTANTS.CORRECT_ANSWER_MANDATORY]: '¡El campo "RESPUESTA CORRECTA" es obligatorio!',
    [CONSTANTS.CREATE_ROOM_SUCCESS]: 'Video creado con exito',
    [CONSTANTS.DISABLE_QUESTION]: 'DESABILITAR?',
    [CONSTANTS.DISABLE_QUESTION]: '¿DESHABILITAR?',
    [CONSTANTS.DOUBTS]: 'Dudas',
    [CONSTANTS.DOWNLOAD_ATTACHMENTS]: 'DESCARGAR ADJUNTOS',
    [CONSTANTS.DURATION_MANDATORY]: '¡El campo "DURACIÓN" es obligatorio!',
    [CONSTANTS.DURATION_NUMERIC]: '¡El campo "DURACIÓN" debe ser numérico!',
    [CONSTANTS.EDIT]: 'Para editar',
    [CONSTANTS.EDIT_CONTENT]: 'Cambiar Contenido',
    [CONSTANTS.VIEW_CONTENT]: 'Ver Contenido',
    [CONSTANTS.EDIT_ROOM_FAIL]: 'No se pudo editar la sala',
    [CONSTANTS.EDIT_VIDEO_COVER]: 'EDITAR PORTADA',
    [CONSTANTS.ENABLE_QUESTION]: '¿HABILITAR?',
    [CONSTANTS.ENABLED]: 'ACTIVADA',
    [CONSTANTS.ENABLED_CAPS]: 'HABILITADO',
    [CONSTANTS.ENDDATE]: 'Final',
    [CONSTANTS.ENGLISH]: 'Inglés',
    [CONSTANTS.EMAIL]: 'CORREO ELECTRÓNICO',
    [CONSTANTS.ENROLLED]: 'Inscrito',
    [CONSTANTS.ENTER_TITLE]: 'Introduzca el título',
    [CONSTANTS.ERROR404]: 'Error 404',
    [CONSTANTS.ERROR_CHANGEPASSWORD]: 'No se pudo cambiar la contraseña, estado de error:',
    [CONSTANTS.ERROR_CREDENTIALS]: 'Credenciales de acceso inválidas, caducadas o inexistentes',
    [CONSTANTS.ERROR_PARAMETERS]: 'Uno o más parámetros de entrada no son válidos.',
    [CONSTANTS.ERROR_DEFAULT]: 'Error inesperado del servidor',
    [CONSTANTS.ERROR_EMAILNOTFOUND]: 'El correo electrónico no encontrado',
    [CONSTANTS.ERROR_LOGIN]: 'Usuario o contraseña incorrecta',
    [CONSTANTS.ERROR_LOGINFAIL]: 'Error de inicio de sesión, estado de error:',
    [CONSTANTS.ERROR_NONETWORK]: 'Sin acceso a Internet',
    [CONSTANTS.ERROR_RECOVERPASSFAIL]: 'No se pudo restaurar la contraseña, estado de error: ',
    [CONSTANTS.FAIL_CHANGELANGUAGE]: 'No se pudo realizar el cambio de idioma',
    [CONSTANTS.FAIL_CHANGEOFFERSTATUS]: 'No se pudo cambiar el estado de la oferta',
    [CONSTANTS.FAIL_CHATNOTFOUND]: 'No se encontró la sala de chat',
    [CONSTANTS.FAIL_CONNECTWEBSOCKET]: 'No se pudo conectar a websocket',
    [CONSTANTS.FAIL_CREATEOFFER]: 'No se pudo crear la oferta',
    [CONSTANTS.FAIL_CREATETEAMS]: 'No se pudieron crear equipos',
    [CONSTANTS.FAIL_ENTERROOM]: 'No se pudo entrar a la habitación',
    [CONSTANTS.FAIL_EXITROOM]: 'No pudo salir de la habitación',
    [CONSTANTS.FAIL_GETCHAT]: 'No se encontró la sala de chat',
    [CONSTANTS.FAIL_GETOFFER]: 'La solicitud de oferta falló',
    [CONSTANTS.FAIL_GETTEAMSSTATUS]: 'No se pudo solicitar el estado del equipo',
    [CONSTANTS.FAIL_GETTEAMS]: 'No se pudieron solicitar equipos',
    [CONSTANTS.FAIL_LOGOUT]: 'No se pudo cerrar la sesión',
    [CONSTANTS.FAIL_SETTEAMSSTATUS]: 'No se pudo cambiar el estado del equipo',
    [CONSTANTS.FILL_FIELDS_ADD_USER]: 'Complete todos los campos para agregar un usuario',
    [CONSTANTS.FILTER_FIRST]: 'Seleccione el primer filtro',
    [CONSTANTS.FILTER_SECOND]: 'Seleccione el segundo filtro',
    [CONSTANTS.FILTER_SPECIFIC]: 'Período de tiempo',
    [CONSTANTS.FILTER_PRESETOPTIONS]: ['1 minuto', '5 minutos', '30 minutos', '1 hora', '3 horas', '6 horas', '24 horas', '3 dias', '7 dias'],
    [CONSTANTS.FILTER_TIME]: 'Selecione o tempo',
    [CONSTANTS.FILTER_NAME_ID]: 'NOMBRE O ID DEL FILTRO',
    [CONSTANTS.FLOW_PRIMARY]: 'Flujo primario',
    [CONSTANTS.FLOW_SECONDARY]: 'Flujo secundario',
    [CONSTANTS.FORGETPASSWORD]: 'OLVIDE MI CONTRASEÑA',
    [CONSTANTS.FROM_TIME]: 'En',
    [CONSTANTS.FULL_SCREEN]: 'Pantalla Completa',
    [CONSTANTS.GAMIFICATION]: 'Gamificación',
    [CONSTANTS.GAMIFICATION_DOWNLOAD_RULES]: 'La regla de descarga de archivos adjuntos se refiere a la puntuación obtenida por el participante al descargar cada archivo adjunto de video.',
    [CONSTANTS.GAMIFICATION_PUNCTUALITY_MODE_RULE]: `Modalidad simple: El participante obtiene la puntuación ingresando durante el período de gracia. Después del período de gracia, el participante no gana ningún punto.
    Modo gradual: El participante obtiene la puntuación máxima ingresando la hora exacta del inicio de la transmisión en vivo. Al ingresar al live después de que haya transcurrido el período de gracia, el participante no obtendrá el puntaje máximo.
    Cálculo de granularidad: puntos máximos / tiempo de tolerancia (en minutos)`,
    [CONSTANTS.GAMIFICATION_ACCESS_ROOM_RULE]: `La regla de puntualidad está dirigida a los participantes que ingresan a la hora de inicio del live o durante el período de gracia.
    La puntualidad se puede medir utilizando el modo simple o gradual.`,
    [CONSTANTS.GAMIFICATION_LOGIN_APP_RULES]: 'La regla de inicio de sesión de la aplicación se refiere a la puntuación obtenida por el participante al iniciar sesión en la aplicación Kopa.',
    [CONSTANTS.GAMIFICATION_POINTS_NUMERIC]: 'Usar valores numéricos',
    [CONSTANTS.GAMIFICATION_QUIZZ_RULE]: `Quizz: Se refiere a los puntos que obtiene el participante al contestar correctamente un quiz durante el live.
    Encuestas: Se refiere a los puntos que obtiene el participante simplemente por responder una encuesta durante el live.`,
    [CONSTANTS.GAMIFICATION_DISABLED]: 'deshabilitada',
    [CONSTANTS.GAMIFICATION_SETTINGS]: 'Ajustes de gamificación',
    [CONSTANTS.GAMIFICATION_SETTINGS_QUIZZ_TIP_A]: 'La configuración de los puntos de gamificación de',
    [CONSTANTS.GAMIFICATION_SETTINGS_QUIZZ_TIP_B]: 'está en la pantalla de',
    [CONSTANTS.GAMIFICATION_RULES_REQUEST_FAIL]: 'Error al capturar las reglas de gamificación',
    [CONSTANTS.GENERAL]: 'General',
    [CONSTANTS.GEOLOCATION]: 'Geolocalización',
    [CONSTANTS.GRADUAL_MODE]: 'MODO GRADUAL',
    [CONSTANTS.GRAPH_AVG_VIEWBITRATE]: 'Tasa de bits promedio',
    [CONSTANTS.GRAPH_AVG_VIEWDOWNSTREAMBANDWIDTH]: 'Ancho de banda descendente',
    [CONSTANTS.GRAPH_AVG_VIEWDROPPEDFRAMESRATIO]: 'Cuadros perdidos promedio',
    [CONSTANTS.GRAPH_AVG_VIEWLIVELATENCY]: 'Latencia media',
    [CONSTANTS.GRAPH_SUMVIEWTIME]: 'Suma del tiempo de visualización',
    [CONSTANTS.GRAPH_VIEWERS]: 'Usuarios',
    [CONSTANTS.GRAPH_VIEWBUFFERTIMERATIO]: 'Tiempo medio de búfer',
    [CONSTANTS.GRAPH_VIEWERSBUFFERING]: 'Buffering de usuários',
    [CONSTANTS.GRAPH_VIEWERSENGAGEMENT]: 'Participación del usuario',
    [CONSTANTS.GRAPH_VIEWERSDVR]: 'Usuarios DVR',
    [CONSTANTS.HOME]: 'Inicio',
    [CONSTANTS.HOMEPAGE]: 'Página de inicio',
    [CONSTANTS.HOME_WELCOME]: 'ES UN PLACER TENERTE AQUÍ CON NOSOTROS',
    [CONSTANTS.HOME_NOLIVE]: 'Lo sentimos, actualmente no tenemos ningún live en progreso.',
    [CONSTANTS.HOURS]: 'Horas',
    [CONSTANTS.INACTIVE]: 'Inactivo',
    [CONSTANTS.INVALID_IMG_TYPE]: 'Tipo no válido. El archivo debe ser una imagen.',
    [CONSTANTS.INITIAL_DATE_CANT_CHANGE]: 'La fecha inicial de esta sala no se puede cambiar.',
    [CONSTANTS.INSERT_VIDEO_THUMBNAIL]: '¡Inserta la miniatura del video!',
    [CONSTANTS.FINISHED]: 'Indisponible',
    [CONSTANTS.LANGUAGE]: 'Idioma',
    [CONSTANTS.LAST]: 'Ultimo',
    [CONSTANTS.LIVE]: 'En vivo',
    [CONSTANTS.LIVE_UNAVAILABLE]: 'En vivo no disponible',
    [CONSTANTS.RECORDED]: 'Grabado',
    [CONSTANTS.LOADING]: 'Cargando',
    [CONSTANTS.LOADINGCAPS]: 'CARGANDO',
    [CONSTANTS.LOADINGMESSAGELINK]: 'Espere mientras se crean los enlaces de transmisión...',
    [CONSTANTS.LOADINGPDF]: 'Cargando PDF',
    [CONSTANTS.LOGIN]: 'Login',
    [CONSTANTS.LOGIN_EMAIL]: 'CORREO ELECTRÓNICO',
    [CONSTANTS.LOGIN_PASSWORD]: 'CONTRASEÑA',
    [CONSTANTS.LOGIN_SIGNIN]: 'INICIAR SESIÓN',
    [CONSTANTS.MAX_SCORE]: 'MAXIMO PUNTAJE',
    [CONSTANTS.MIN_SCORE]: 'la puntuación mínima es 10',
    [CONSTANTS.MAXIMUM_IMAGE_SIZE]: 'Tamaño máximo de imagen: 300 x 300',
    [CONSTANTS.MINIMUM_IMAGE_SIZE]: 'Tamaño mínimo: 300 x 300',
    [CONSTANTS.MESSAGE]: 'Mensaje',
    [CONSTANTS.MINUTES]: 'MINUTOS',
    [CONSTANTS.MUTED_PARTICIPANT_ERROR]: 'No se pudo silenciar al participante',
    [CONSTANTS.MUTED_USER]: 'Usuario silenciado',
    [CONSTANTS.HOUR_ERROR_MESSAGE]: 'La hora de finalización debe ser mayor que la hora actual.',
    [CONSTANTS.MONITORS]: 'Monitores',
    [CONSTANTS.NAMECAPS]: 'NOMBRE',
    [CONSTANTS.NEXT_STEP]: 'Próxima Etapa',
    [CONSTANTS.NO]: 'No',
    [CONSTANTS.NO_USER_REGISTERED_CHECK_XLSX]: '¡NINGÚN USUARIO SE HA REGISTRADO! COMPRUEBE SU ARCHIVO XLSX. ',
    [CONSTANTS.NO_USERS_SHOW]: 'No hay usuarios para mostrar',
    [CONSTANTS.NO_QUIZ_SURVEY_REGISTERED]: 'NO HAY TEST O ENCUESTA REGISTRADA',
    [CONSTANTS.NO_QUIZ_SURVEY_REGISTERED_MESSAGE]: '¿Aún no ha registrado ningún cuestionario o encuesta? Es simple, fácil y solo toma unos minutos.',
    [CONSTANTS.NO_USER_BLOCKED]: 'NINGÚN HAY USUARIO BLOQUEADO',
    [CONSTANTS.NO_USER_CONNECTED]: 'NINGÚN USUARIO CONECTADO',
    [CONSTANTS.NO_USER_FOUND]: 'USUARIO NO ENCONTRADO',
    [CONSTANTS.NO_USER_FOUND_MESSAGE]: '¡Lo siento! No encontramos al usuario que buscaba.',
    [CONSTANTS.NO_USERS_ROOM]: 'No hay usuarios en la sala',
    [CONSTANTS.NO_PARTICIPANT_REPORT]: 'No hay participante en el informe de presencia',
    [CONSTANTS.LIVE_ENDED]: 'Transmisión cerrado',
    [CONSTANTS.NEXTPAGE]: 'Siguiente página',
    [CONSTANTS.OF]: 'en',
    [CONSTANTS.OFFERS]: 'QR CODE',
    [CONSTANTS.OFFERS_ACTIVATEOFFERS]: 'Activar QR CODE',
    [CONSTANTS.OFFERS_BASEURL]: 'Insertar la plantilla de URL',
    [CONSTANTS.OFFERS_CREATEOFFER]: 'Crear QR CODE',
    [CONSTANTS.OFFERS_EDITOFFER]: 'Editar QR CODE',
    [CONSTANTS.OFFERS_ENABLECONFIRM]: '¿Quieres activar un QR CODE?',
    [CONSTANTS.OFFERS_MODEOFFERSINACTIVATE]: 'Los QR CODEs están deshabilitadas',
    [CONSTANTS.OFFERS_NOOFFERS]: 'No hay QR CODEs registrados',
    [CONSTANTS.OFFERS_SALESFORCEID]: 'Salesforce Id',
    [CONSTANTS.OFFERS_SELECTOR]: 'Seleccione el tipo de ID de plantilla',
    [CONSTANTS.OFFERS_TEMPLATEURL]: 'Template url',
    [CONSTANTS.OFFERS_TYPEID]: 'Tipo de identificación',
    [CONSTANTS.OFFERS_WARN]: 'Atención: QR CODE solo está disponible para contenido / videos EN VIVO',
    [CONSTANTS.OPS]: 'Ops!',
    [CONSTANTS.PARTICIPANT_WITHOUT_GAMIFICATION]: 'De momento ninguno de los participantes ha generado puntos de gamificación.',
    [CONSTANTS.PARTICIPANT_NOT_FOUND]: 'PARTICIPANTE NO ENCONTRADO',
    [CONSTANTS.PARTICIPANT_NOT_FOUND_MESSAGE]: '¡Lo siento! No pudimos encontrar al participante que buscaba.',
    [CONSTANTS.PARTICIPANT_OUT_LIST]: 'Por el momento, el participante no está en el informe de presencia de esta sala.',
    [CONSTANTS.PERMISSION_ENTERCHAT]: 'No se le permite ingresar a esta sala de chat',
    [CONSTANTS.PERMISSION_PINMESSAGE]: 'No se le permite fijar un mensaje en esta sala de chat',
    [CONSTANTS.PUNCTUALITY]: 'PUNTUALIDAD',
    [CONSTANTS.PUNCTUALITY_MODE]: 'MODO PUNTUALIDAD',
    [CONSTANTS.PERMISSION_UNPINMESSAGE]: 'No puedes desanclar mensajes en esta sala de chat',
    [CONSTANTS.QUESTION_MANDATORY]: '¡El campo "PREGUNTA" es obligatorio!',
    [CONSTANTS.TYPE_QUESTION]: 'Escriba su pregunta',
    [CONSTANTS.TITLE_MANDATORY]: '¡El campo "TÍTULO" es obligatorio!',
    [CONSTANTS.USERNAME_MIN]: 'El nombre de usuario debe tener más de 5 caracteres',
    [CONSTANTS.VALID_DATE]: 'Por favor introduzca una fecha valida',
    [CONSTANTS.VALID_EMAIL]: 'Introduzca una dirección de email válida',
    [CONSTANTS.VALID_PHONE]: 'Por favor ingrese un número de teléfono válido',
    [CONSTANTS.VOUCHER_LOGIN]: 'Regístrese con su bono',
    [CONSTANTS.VOUCHER_PASSWORD_CREATION_TITLE]: 'CREACIÓN DE CONTRASEÑA',
    [CONSTANTS.VOUCHER_CODE_TITLE]: 'CÓDIGO DE CUPÓN',
    [CONSTANTS.VOUCHER_PASSWORD_LABEL]: 'CONTRASEÑA',
    [CONSTANTS.VOUCHER_PASSWORD_CONFIRM_LABEL]: 'CONFIRMAR CONTRASEÑA',
    [CONSTANTS.VOUCHER_PASSWORD_SAME]: 'Las contraseñas deben ser las mismas',
    [CONSTANTS.VOUCHER_PASSWORD_MIN]: 'La contraseña debe tener al menos 6 caracteres',
    [CONSTANTS.VOUCHER_FINALIZE_REGISTRATION]: 'Finalizar registro',
    [CONSTANTS.VOUCHER_CODE_SUBTITLE]: 'Inserte su código de cupón aquí',
    [CONSTANTS.VOUCHER_BUTTON_SUBMIT]: 'VALIDAR VOUCHER',
    [CONSTANTS.VOUCHER_REGISTER_TITLE]: 'REGISTRO EXITOSO',
    [CONSTANTS.VOUCHER_TERMS]: ['O Usuário está ciente de que fornece informação de forma consciente e voluntária por meio de [FORMULÁRIO/ETC], ou por meio dos sites operados pela LBCA.',
        'O Usuário está ciente de que fornece informação de forma consciente e voluntária por meio de [FORMULÁRIO/ETC], ou por meio dos sites operados pela LBCA.'],
    [CONSTANTS.VOUCHER_TERMS_ACEPPT]: 'ACEPTO LOS TÉRMINOS DE USO',
    [CONSTANTS.VOUCHER_TERMS_TITLE]: 'TÉRMINOS DE USO',
    [CONSTANTS.VOUCHER_TERMS_VALIDATION]: 'Acepte los términos para continuar',
    [CONSTANTS.VOUCHER_ACEPPT_TERMS_PRIVACY_PT1]: 'ACEPTO LA ',
    [CONSTANTS.VOUCHER_ACEPPT_TERMS_PRIVACY_PT2]: 'POLÍTICA DE PRIVACIDAD',
    [CONSTANTS.VOUCHER_NEXT_STEP_BUTTON]: 'Proximo paso',
    [CONSTANTS.VOUCHER_USERDATA_TITLE]: 'INFORMACIÓN PERSONAL',
    [CONSTANTS.VOUCHER_USERDATA_FILL]: 'Completa todos los datos',
    [CONSTANTS.VOUCHER_USERDATA_FULL_NAME]: 'NOMBRE COMPLETO',
    [CONSTANTS.VOUCHER_USERDATA_FULL_NAME_CC]: 'Nombre Completo',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL]: 'E-MAIL',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL_CONFIRM]: 'CONFIRMAR E-MAIL',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL_DIFFERS]: 'Las direcciones de correo electrónico no son las mismas',
    [CONSTANTS.VOUCHER_USERDATA_DATE_OF_BIRTH]: 'FECHA DE NACIMIENTO',
    [CONSTANTS.VOUCHER_USERDATA_GENDER]: 'GÉNERO',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_MALE]: 'Masculino',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_FEM]: 'Femenino',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_OTHER]: 'Otro',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_SELECT]: 'Seleccione el sexo',
    [CONSTANTS.VOUCHER_USERDATA_INVALID_DATE]: 'Fecha de nacimiento no válida',
    [CONSTANTS.VOUCHER_USERDATA_PHONE]: 'TELÉFONO',
    [CONSTANTS.VOUCHER_USERDATA_PHONE_PATTERN]: 'Use solo números en el campo del teléfono',
    [CONSTANTS.VOUCHER_USERDATA_TYPE_FULL_NAME]: 'Nombre completo del usuario',
    [CONSTANTS.PAGE]: 'Página',
    [CONSTANTS.PLEASECLOSE]: 'Por favor cierra',
    [CONSTANTS.PORTUGUESE]: 'Portugués',
    [CONSTANTS.PRESET]: 'Preestablecido',
    [CONSTANTS.PREVIOUSPAGE]: 'Página anterior',
    [CONSTANTS.RECOVERPASSWORD_MINLENGTH]: 'La contraseña debe tener al menos 8 dígitos',
    [CONSTANTS.RECOVERPASSWORD_NEWPASSWORD]: 'NUEVA CONTRASEÑA',
    [CONSTANTS.RECOVERPASSWORD_NEWPASSWORDREPEAT]: 'REPITA LA NUEVA CONTRASEÑA',
    [CONSTANTS.RECOVERPASSWORD_NOLINK]: 'O link não está mais disponível',
    [CONSTANTS.RECOVERPASSWORD_NOMATCH]: 'Las contraseñas no coinciden',
    [CONSTANTS.RECOVERPASSWORD_REDEFINE]: 'REDEFINIR CONTRASEÑA',
    [CONSTANTS.RECOVERPASSWORD_SUCCESS]: 'Contraseña restablecida correctamente',
    [CONSTANTS.RECOVERPASSWORD_VERIFY]: 'CONSULTAR SU CORREO ELECTRÓNICO',
    [CONSTANTS.RECOVERPASSWORD_VERIFYDESC]: 'Se enviará un correo electrónico a su casilla de correo electrónico para que cambie su contraseña.',
    [CONSTANTS.REGISTER_QUIZ_SURVEY]: 'REGISTRAR PRUEBA O ENCUESTA',
    [CONSTANTS.REQUIRED_FIELD]: 'CAMPO REQUERIDO',
    [CONSTANTS.SAVE_GAMIFICATION_RULES_SUCCESS]: 'Las reglas se han guardado correctamente',
    [CONSTANTS.SAVE_GAMIFICATION_RULES_FAIL]: 'Las reglas no se guardaron correctamente',
    [CONSTANTS.SEARCH]: 'Buscar',
    [CONSTANTS.SEARCH_MIN_CHARACTERS]: 'Ingrese al menos 3 caracteres para buscar',
    [CONSTANTS.SEARCH_NOT_FOUND]: 'No hay entradas para mostrar',
    [CONSTANTS.SELECTQUESTION]: 'Selecciona una pregunta',
    [CONSTANTS.SEND]: 'Enviar',
    [CONSTANTS.SEVERITY_BAD]: 'Malo',
    [CONSTANTS.SEVERITY_GOOD]: 'Bueno',
    [CONSTANTS.SEVERITY_WARN]: 'Advertencia',
    [CONSTANTS.SORRYPROBLEM]: 'Lo sentimos, tuvimos un problema.',
    [CONSTANTS.SPANISH]: 'Español',
    [CONSTANTS.STARTDATE]: 'Inicio',
    [CONSTANTS.STATUS]: 'Status',
    [CONSTANTS.STATUS_LIVE]: 'Transmisión status',
    [CONSTANTS.SUCCESS]: 'ÉXITO',
    [CONSTANTS.TEAM]: 'Equipo',
    [CONSTANTS.TEAMS]: 'Equipos',
    [CONSTANTS.TEAMS_ACTIVATETEAMS]: 'Habilitar equipos',
    [CONSTANTS.TEAMS_CREATETEAMS]: 'Crear equipos',
    [CONSTANTS.TEAMS_CREATETEAMSDESC]: 'Crea equipos y haz que la experiencia de los participantes sea aún más extraordinaria',
    [CONSTANTS.TEAMS_GENERATETEAMS]: 'Generar equipos',
    [CONSTANTS.TEAMS_MODETEAMS]: 'Modo equipo',
    [CONSTANTS.TEAMS_MODETEAMSINACTIVATE]: 'MODO EQUIPOS APAGADO',
    [CONSTANTS.TEAMS_NOTEAMS]: 'No hay equipos registrados en este momento',
    [CONSTANTS.TEAMS_QUANTITY]: 'Número de personas del equipo',
    [CONSTANTS.THE]: 'El',
    [CONSTANTS.TRY_AGAIN]: 'Intentar nuevamente',
    [CONSTANTS.TOLERANCY]: 'TOLERANCIA',
    [CONSTANTS.TOGGLE_GAMIFICATION_SUCCESS]: 'La gamificación se ha activado con éxito.',
    [CONSTANTS.TOGGLE_GAMIFICATION_FAIL]: 'Hubo un error al activar la gamificación.',
    [CONSTANTS.TWO_ITEMS_MANDATORY]: '¡Complete al menos dos elementos!',
    [CONSTANTS.UNMUTED_PARTICIPANT_ERROR]: 'No se pudo activar al participante',
    [CONSTANTS.UPLOAD_ERROR_MESSAGE]: 'Formato de archivo no soportado',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE]: 'Suelta la imagen aquí',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE_MULTIPLE]: 'Suelta el (los) archivo (s) aquí',
    [CONSTANTS.UPLOAD_COVER_PLACEHOLDER]: 'Haga clic aquí o arrastre la imagen',
    [CONSTANTS.UPLOAD_VIDEO_PLACEHOLDER]: 'Haga clic aquí o arrastre el video',
    [CONSTANTS.UPLOAD_VIDEO_PROGRESS]: 'PROGRESO',
    [CONSTANTS.UPLOAD_VIDEO_SUCCESS]: 'Carga de video finalizada!',
    [CONSTANTS.UPLOAD_VIDEO_FAILURE]: 'Error de carga de video!',
    [CONSTANTS.UPLOAD_VIDEO_PREPARING]: 'Preparando video.',
    [CONSTANTS.UPLOADING]: 'Subiendo',
    [CONSTANTS.UPLOADING_ATTACHMENTS]: 'Enviar archivos adjuntos',
    [CONSTANTS.CONVERTING]: 'Conversión',
    [CONSTANTS.UPLOAD_ERROR]: 'Error!',
    [CONSTANTS.UPLOAD_VIDEO]: 'Carga de video',
    [CONSTANTS.UPLOAD]: 'Upload',
    [CONSTANTS.UNENROLLED]: 'No inscrito',
    [CONSTANTS.UNABLE_TO_EDIT_VIDEO]: 'No se puede editar el video en este momento. Prueba otra vez en unos instantes',
    [CONSTANTS.UNENROLL_USER]: 'ANULAR',
    [CONSTANTS.USERS]: 'Usuarios',
    [CONSTANTS.USER]: 'Usuario',
    [CONSTANTS.USERS_ACTIVE]: 'Usuarios activos',
    [CONSTANTS.USERS_ACTIVECAPS]: 'USUARIOS ACTIVOS',
    [CONSTANTS.USERS_DISTRIBUTIONSHORT]: 'DIS. DE USUARIOS',
    [CONSTANTS.USERS_ENGAGEMENT]: 'Usuarios comprometidos',
    [CONSTANTS.VIDEO]: 'Vídeo',
    [CONSTANTS.VIDEO_DESCRIPTION]: 'Descripción del video',
    [CONSTANTS.VIDEO_OFF]: 'Video Off',
    [CONSTANTS.VIDEO_UNAVAILABLE]: 'Video No Disponible',
    [CONSTANTS.VIDEO_TITLE_PLACEHOLDER]: 'e.g: Clase interactiva',
    [CONSTANTS.VOUCHER]: 'VOUCHER',
    [CONSTANTS.VOUCHER_ENTER_FIELDS]: 'Complete todos los valores',
    [CONSTANTS.VOUCHER_EMAIL_VALIDATION]: 'VALIDACIÓN DE CORREO ELECTRÓNICO',
    [CONSTANTS.VOUCHER_ERROR_COURSE]: 'El usuario ya tiene el curso',
    [CONSTANTS.VOUCHER_ERROR_VERIFY]: 'No se pudo verificar el cupón',
    [CONSTANTS.VOUCHER_ERROR_USERCREATED]: 'Usuario ya registrado',
    [CONSTANTS.VOUCHER_ERROR_INVALIDCODE]: 'No se pudo verificar el código',
    [CONSTANTS.VOUCHER_ERROR_EXPIREDCODE]: 'Código caducado, reenviar el código al correo electrónico',
    [CONSTANTS.VOUCHER_ERROR_RESENDEMAIL]: 'No se pudo reenviar el correo electrónico',
    [CONSTANTS.VOUCHER_ERROR_CONFIRMPASSWORD]: 'No se pudo confirmar la contraseña',
    [CONSTANTS.VOUCHER_ERROR_REGISTERDATA]: 'No se pudieron registrar los datos',
    [CONSTANTS.VOUCHER_ERROR_REGISTER]: 'No se pudo registrar el vale',
    [CONSTANTS.VOUCHER_INVALID]: 'Código invalido',
    [CONSTANTS.VOUCHER_NEW_VOUCHER]: 'Cupón nuevo',
    [CONSTANTS.VOUCHER_VALIDATE]: 'Tu bono con el código',
    [CONSTANTS.VOUCHER_VALIDATEPT2]: 'ha sido validado con éxito',
    [CONSTANTS.VOUCHER_VALIDATE_CODE]: 'Validar bono',
    [CONSTANTS.VOUCHER_VALIDATE_DENIED]: 'no ha sido validado con éxito',
    [CONSTANTS.VOUCHER_VALIDATE_CODECAPS]: 'VALIDACIÓN DE VALES',
    [CONSTANTS.VOUCHER_VALIDATE_QUESTION]: '¿De verdad quieres validar el bono',
    [CONSTANTS.VOUCHER_VALIDATE_QUESTIONPT2]: 'que libera el canal',
    [CONSTANTS.VOUCHER_REGISTERED]: 'Cupón registrado correctamente',
    [CONSTANTS.VOUCHER_VERIFICATION_CODE]: 'INSPIRE AQUI OU CÓDIGO DE VERIFICAÇÃO QUE FOI ROTEU PARA OU E-MAIL',
    [CONSTANTS.VOUCHER_YOUR_CODE]: 'SU CÓDIGO DE VOUCHER',
    [CONSTANTS.VOUCHER_RESEND_EMAIL]: 'Reenviar código',
    [CONSTANTS.VOUCHER_RESENT_EMAIL]: 'Código reenviado',
    [CONSTANTS.VOUCHER_STEPS]: ['Condiciones de uso', 'Datos personales', 'Validación', 'Creación de contraseña'],
    [CONSTANTS.VOUCHER_WARNING_VERIFY]: 'No se pudo verificar el cupón',
    [CONSTANTS.WARNINGS_MESSAGES]: 'Muchos mensajes expresados ​​en poco tiempo. Vuelve a intentarlo más tarde.',
    [CONSTANTS.WARNINGS_MESSAGES_FAIL]: 'No se pudo enviar el mensaje',
    [CONSTANTS.WARNINGS_MESSAGES_NOTALLOWED]: 'Por su seguridad, no está permitido enviar enlaces, números de teléfono y correos electrónicos.',
    [CONSTANTS.WARNINGS_MESSAGES_NOTFOUND]: 'No se encontró la sala de chat',
    [CONSTANTS.WARNINGS_MESSAGES_PERMISSIONS]: 'No se le permite enviar un mensaje en esta sala de chat',
    [CONSTANTS.YES]: 'Si',
    [CONSTANTS.ORGANIZER]: 'Organizador',
    [CONSTANTS.PARTICIPANT]: 'Partícipe',
    [CONSTANTS.ORGANIZER_DASHBOARD]: 'Organizador',
    [CONSTANTS.NOTIFICATIONS]: 'Notificaciones',
    [CONSTANTS.PITCH]: 'Pitch',
    [CONSTANTS.INFORMATION]: 'información',
    [CONSTANTS.INFORMATIONS]: 'información',
    [CONSTANTS.LOGOUT]: 'Cerrar sesión',
    [CONSTANTS.CHANNEL]: 'Canal',
    [CONSTANTS.CHANNEL]: 'CANAL ACTUAL:',
    [CONSTANTS.CONTENT_TYPE_TO_ADD]: '¿Qué tipo de contenido quieres agregar?',
    [CONSTANTS.CONTENT]: 'Contenido ',
    [CONSTANTS.SAVE_CHANGES]: 'Guardar cambios',
    [CONSTANTS.CLOSE]: 'Cerrar',
    [CONSTANTS.CHARACTERS]: 'Caracteres',
    [CONSTANTS.OPTIONAL]: 'Opcional',
    [CONSTANTS.WHAT_CHANNEL_LOOKING]: '¿QUÉ CANAL ESTÁS BUSCANDO?',
    [CONSTANTS.ROOM]: 'Sala',
    [CONSTANTS.TIME_WATCHED]: 'TIEMPO VISTO',
    [CONSTANTS.USERS_WHO_WATCH_THE_VIDEO]: 'USUARIOS QUE VIERON EL VIDEO',

    [CONSTANTS.BUTTON_MESSAGE_YES]: 'SÍ, QUIERO',
    [CONSTANTS.BUTTON_MESSAGE_NOT]: 'NO, NO QUIERO',
    [CONSTANTS.MODAL_DELETE_MESSAGE]: 'REALMENTE QUIERES BORRAR EL USUARIO',
    [CONSTANTS.MODAL_DELETE_MESSAGE]: 'REALMENTE QUIERES BORRAR EL USUARIO',
    [CONSTANTS.ADD_NEW_USER]: 'AÑADIR NUEVO USUARIO',
    [CONSTANTS.EDIT_USER]: 'EDITAR USUARIO',
    [CONSTANTS.DELETE_USER]: 'BORRAR USUARIO',
    [CONSTANTS.PERMISSION_LEVEL]: 'Nivel de permiso de usuario',
    [CONSTANTS.USERS_PER_PAGE]: 'Usuarios por página',
    [CONSTANTS.PARTICIPANT]: 'Partícipe',
    [CONSTANTS.MONITOR]: 'Moderador',
    [CONSTANTS.DELETE]: 'Borrar',
    [CONSTANTS.NAME]: 'Nombre',
    [CONSTANTS.PERMISSION]: 'Permiso',
    [CONSTANTS.NEW_USER]: 'NUEVO USUARIO',
    [CONSTANTS.IMPORT_SPREADSHEET]: 'IMPORTAR HOJA DE TRABAJO',
    [CONSTANTS.GENDER]: 'género',
    [CONSTANTS.DATE_OF_BIRTH]: 'Fecha de nacimiento',
    [CONSTANTS.SAVE_USER]: 'GUARDAR USUARIO',
    [CONSTANTS.MALE]: 'Masculino',
    [CONSTANTS.FEMALE]: 'Mujer',
    [CONSTANTS.PHONE_CONTACT]: 'Telefono para contacto',
    [CONSTANTS.PHONE]: 'Telefono',
    [CONSTANTS.PASSWORD]: 'Contraseña',
    [CONSTANTS.RESULTS]: 'Resultados encontrados',
    [CONSTANTS.FAIL_EDIT_USER]: 'No se pudo editar el usuario',
    [CONSTANTS.FAIL_DELETE_USER]: 'No fue posible eliminar al usuario',
    [CONSTANTS.FAIL_UNSUBSCRIBE_USER]: 'No fue posible dar de baja al usuario',
    [CONSTANTS.FAIL_GET_USER]: 'No se pudieron enumerar los usuarios',
    [CONSTANTS.FAIL_POST_USER]: 'No se pudo salvar al usuario',
    [CONSTANTS.FAIL_GET_CHANNELS]: 'No se pudieron recuperar los canales',
    [CONSTANTS.FAIL_ADD_USER]: 'No se pudo agregar el usuario',
    [CONSTANTS.BUTTON_MESSAGE_YES]: 'SÍ, QUIERO',
    [CONSTANTS.BUTTON_MESSAGE_NOT]: 'NO, NO QUIERO',
    [CONSTANTS.MODAL_DELETE_MESSAGE]: 'REALMENTE QUIERES BORRAR EL USUARIO',
    [CONSTANTS.MODAL_UNSUBSCRIBE_MESSAGE]: '¿REALMENTE QUIERES DAR DE BAJA EL USUARIO',
    [CONSTANTS.ADD_NEW_USER]: 'AÑADIR NUEVO USUARIO',
    [CONSTANTS.EDIT_USER]: 'EDITAR USUARIO',
    [CONSTANTS.DELETE_USER]: 'BORRAR USUARIO',
    [CONSTANTS.UNSUBSCRIBE_USER]: 'DARSE DE BAJA USUARIO',
    [CONSTANTS.PERMISSION_LEVEL]: 'Nivel de permiso de usuario',
    [CONSTANTS.USERS_PER_PAGE]: 'Usuarios por página',
    [CONSTANTS.PARTICIPANT]: 'Partícipe',
    [CONSTANTS.MONITOR]: 'Moderador',
    [CONSTANTS.DELETE]: 'Borrar',
    [CONSTANTS.NAME]: 'Nombre',
    [CONSTANTS.PERMISSION]: 'Permiso',
    [CONSTANTS.MORE_OPTIONS]: 'MAS ACCIONES',
    [CONSTANTS.SPREADSHEET]: 'hoja de cálculo',
    [CONSTANTS.DOWNLOAD_SPREADSHEET]: 'Descargar hoja de calculo',
    [CONSTANTS.DOWNLOAD_VIDEO_RECORDING]: 'DESCARGAR VIDEO',
    [CONSTANTS.DOWNLOAD_VIDEO_FAIL]: 'No se pudo descargar el video',
    [CONSTANTS.USERS_REPORT]: 'Informe de los usuarios',
    [CONSTANTS.PREPARING_DOWNLOAD]: 'Preparando la descarga...',
    [CONSTANTS.DONE]: 'Terminado',
    [CONSTANTS.ERROR]: 'Error',
    [CONSTANTS.ERROR_LOAD_DATA]: '¡No se pudieron cargar los datos!',
    [CONSTANTS.ERROR_ROOM_ID]: '¡Debes proporcionar la "id de sala" para poder enviar estadísticas!',
    [CONSTANTS.COMPLETEDWITHERROR]: 'Terminado con error',
    [CONSTANTS.USERSLIMITREACHED]: 'Límite de usuarios alcanzado',
    [CONSTANTS.FILE]: 'Archivo',
    [CONSTANTS.PROCESS_STATUS]: 'Proceso',
    [CONSTANTS.CREATION_DATE]: 'Fecha de creación',
    [CONSTANTS.REGISTRATION_ERRORS]: 'Errores de registro',
    [CONSTANTS.IMPORT]: 'Importar',
    [CONSTANTS.EXECUTE_IMPORT]: 'Ejecutar importación',
    [CONSTANTS.DOWNLOAD_SPREADSHEET_SAMPLE]: 'Descargue una plantilla de hoja de trabajo lista para importar',
    [CONSTANTS.DRAG_AND_DROP_FILES]: 'Haga clic aquí o arrastre el archivo',
    [CONSTANTS.IMPORT_LIST_DESCRIPTION]: 'Importar a sus usuarios desde un archivo xlsx',
    [CONSTANTS.IMPORT_LIST_TIP]: 'El archivo XLSX debe tener los siguientes campos, con el mismo orden y formato:',
    [CONSTANTS.OR]: 'o',
    [CONSTANTS.AND]: 'Y',
    [CONSTANTS.XLSX_DETAILS]: 'Detalles de',
    [CONSTANTS.IMPORTXLSXHEADER]: 'IMPORTA A TUS USUARIOS USANDO A XLSX',
    [CONSTANTS.XLSX_FORMAT_ERROR]: '¡EL FORMATO DEL ARCHIVO QUE HA AÑADIDO ES INCORRECTO! AÑADIR UN ARCHIVO CON FORMATO XLSX.',
    [CONSTANTS.XLSX_SAMPLE_FAIL]: 'ERROR AL DESCARGAR EL ARCHIVO.',
    [CONSTANTS.XLSX_FORMAT_ERROR_MESSAGE]: '¿Aún no has importado ningún archivo XLSX? Es simple, fácil y solo toma unos minutos.',
    [CONSTANTS.XLSX_FORMAT_NO_IMPORT]: 'No se realizó ninguna importación.',
    [CONSTANTS.INCORRECT_COLUMNS]: 'Columnas incorrectas',
    [CONSTANTS.USERS_REGISTERED]: 'Usuarios registrados con éxito!',
    [CONSTANTS.USER_REGISTERED]: 'Usuario registrado con éxito!',
    [CONSTANTS.USERS_NOT_REGISTERED]: 'Usuarios no registrados',
    [CONSTANTS.BACK_TO]: 'Volver a los',
    [CONSTANTS.ERROR_IMAGE_UPLOAD]: 'FALLO AL CARGAR LA IMAGEN',
    [CONSTANTS.SOCIAL_MEDIA]: 'REDES SOCIALES',
    [CONSTANTS.PROFILE_UPDATED]: 'PERFIL ACTUALIZADO',
    [CONSTANTS.PROFILE_UPDATE_FAILED]: 'NO SE PUEDO ACTUALIZAR EL PERFIL',
    [CONSTANTS.CROP_IMAGE]: 'DELIMITAR IMAGEN',
    [CONSTANTS.VIEW_PROFILE]: 'VER PERFIL',
    [CONSTANTS.GUEST_AVAILABLE]: 'INVITADO DISPONIBLE',
    [CONSTANTS.GUEST_PRESENTER]: 'USUARIOS CONECTADOS',
    [CONSTANTS.SAVE]: 'GUARDAR',
    [CONSTANTS.SUBS_LIMIT_REACHED]: 'Límite de usuarios alcanzado',
    [CONSTANTS.WELCOME_TO_KOPA]: 'BIENVENIDO A KOPA',
    [CONSTANTS.MY_PROFILE]: 'MI PERFIL',
    [CONSTANTS.GUEST]: 'Convidado',
    [CONSTANTS.BUTTON_GUEST]: 'CONVIDADO',

    [CONSTANTS.REPORTS]: 'Informes',
    [CONSTANTS.PRESENCE_REPORT]: 'Informe de presencia',
    [CONSTANTS.EXPORT]: 'EXPORTAR',
    [CONSTANTS.REFRESH]: 'ACTUALIZAR',
    [CONSTANTS.LOAD_MORE_PAGINATION]: 'CARGAR MÁS',
    [CONSTANTS.VIEW_REPORT]: 'Vista del informe',
    [CONSTANTS.GET_PRESENCE_REPORT_FALURE]: 'Error al recuperar el informe ',
    [CONSTANTS.GET_COURSE_FAILURE]: 'Error al obtener el canal ',
    [CONSTANTS.VIEW_REPORT_WARN]: 'Atención: el informe de presencia solo está disponible para contenido / videos EN VIVO',
    [CONSTANTS.EXPORT_REPORT_FAILURE]: 'Error al descargar el informe',
    [CONSTANTS.OF_VIEW]: 'de vista',
    [CONSTANTS.VIEWING_TIME]: 'Tiempo de visualización',

    [CONSTANTS.QUIZZ]: 'Prueba',
    [CONSTANTS.SURVEY]: 'Encuesta',
    [CONSTANTS.SCORE]: 'PUNTAJE',
    [CONSTANTS.SELECTED]: 'SELECCIONARON',
    [CONSTANTS.SURVEY_RIGHT_ANSWER]: 'son encuestas que tienen la respuesta correcta.',
    [CONSTANTS.QUIZ_DETAIL]: 'Detalles de la prueba',
    [CONSTANTS.SURVEY_DETAIL]: 'Detalle de la encuesta',
    [CONSTANTS.QUIZZES]: 'Quizzes',
    [CONSTANTS.QUIZZES_SURVEYS]: 'Cuestionarios y encuestas',
    [CONSTANTS.IN_PROGRESS]: 'En proceso',
    [CONSTANTS.DISABLED]: 'desactivado',
    [CONSTANTS.NEW_QUIZ]: 'Nuevo cuestionario',
    [CONSTANTS.NEW_SURVEY]: 'Nueva encuesta',
    [CONSTANTS.NEW_QUIZ_OR_SURVEY]: 'Nuevo cuestionario o encuesta',
    [CONSTANTS.TYPE]: 'Tipo',
    [CONSTANTS.TITLE]: 'Título',
    [CONSTANTS.DURATION]: 'Duración',
    [CONSTANTS.ANSWER]: 'Respuesta',
    [CONSTANTS.DETAILS]: 'Detalles',
    [CONSTANTS.QUESTION]: 'Pregunta',
    [CONSTANTS.QUESTIONS]: 'Preguntas',
    [CONSTANTS.QUESTIONS_QUANTITY]: 'Numero de Preguntas',
    [CONSTANTS.QUESTION_SCORE]: 'Puntuación de la Pregunta',
    [CONSTANTS.CORRECT_ANSWER]: 'Respuesta correcta',
    [CONSTANTS.OPTION_CHARACTERS_INFO]: 'caracteres restantes',
    [CONSTANTS.SELECT]: 'Seleccione',
    [CONSTANTS.SCREEN_TIME_DURATION]: 'Duración en ( segundos )',
    [CONSTANTS.SAVE_QUIZ]: 'Guardar prueba',
    [CONSTANTS.SAVE_SURVEY]: 'Guardar encuesta',
    [CONSTANTS.ADD]: 'agregar',
    [CONSTANTS.PUBLISH]: 'Publicar',
    [CONSTANTS.SURVEY_PUBLISHED]: 'Publicada',
    [CONSTANTS.PUBLISHED]: 'Publicado',
    [CONSTANTS.UNPUBLISHED]: 'No Publicado',
    [CONSTANTS.SURVEY_UNPUBLISHED]: 'No Publicada',
    [CONSTANTS.WARNING]: 'Advertencia',
    [CONSTANTS.CHECK_DATA]: 'Revisa tus datos!',
    [CONSTANTS.UPDATE_QUESTION_SUCCESS]: '¡Pregunta actualizada correctamente!',
    [CONSTANTS.UPDATE_QUESTION_FAIL]: '¡No se pudo actualizar la pregunta!',
    [CONSTANTS.ERROR_DUPLICATE_QUESTION]: 'Error: ¡Los elementos del cuestionario deben tener un valor único!',
    [CONSTANTS.LIMIT_CHARACTER]: '¡Límite de caracteres alcanzado!',
    [CONSTANTS.RELEASE_QUESTION_FAIL]: 'No se pudo publicar la pregunta!',
    [CONSTANTS.CREATE_QUESTION_SUCCESS]: '¡Pregunta creada con éxito!',
    [CONSTANTS.CREATE_QUESTION_FAIL]: '¡No se pudo crear la pregunta!',
    [CONSTANTS.QUIZ_RESULT]: 'Resultado de la prueba',
    [CONSTANTS.SURVEY_RESULT]: 'Resultado de la encuesta',
    [CONSTANTS.VIEW_QUIZZES_WARN]: 'Atención: Prueba/Encuesta solo está disponible para contenido / videos EN VIVO',
    [CONSTANTS.FIELD_MANDATORY]: 'Campo obligatorio',
    [CONSTANTS.FIELD_DUPLICATE]: 'Campo duplicado',
    [CONSTANTS.POSITIVE_FIELD]: '¡El campo debe estar por encima de cero!',
    [CONSTANTS.REQUIRED_QUESTIONS_FIELDS]: 'Complete los elementos de pregunta requeridos',
    [CONSTANTS.REQUIRED_OPTIONAL_QUESTION_FIELD]: 'Complete el campo "opcional" en secuencia',

    [CONSTANTS.SORT_BY]: 'ORDENAR POR:',
    [CONSTANTS.TOTAL_OF_POINTS]: 'Total de puntos',
    [CONSTANTS.LABEL_SEARCH_INPUT]: 'BUSCAR POR NOMBRE Y CORREO ELECTRÓNICO',
    [CONSTANTS.DECREASING]: 'Decreciente',
    [CONSTANTS.ASCENDING]: 'Ascendente',
    [CONSTANTS.POSITION]: 'Lugar',
    [CONSTANTS.POINTS]: 'Puntos',
    [CONSTANTS.LAST_UPDATE]: 'Última actualización',
    [CONSTANTS.PLACE]: 'Lugar',
    [CONSTANTS.LABEL_SEARCH_INPUT_USERS]: 'BUSCAR POR CORREO ELECTRÓNICO',
    [CONSTANTS.BLOCK_LIST]: 'Lista de bloqueo de chat',
    [CONSTANTS.BLOCK_DATE]: 'Fecha de bloqueo',
    [CONSTANTS.REMOVE_BLOCKING]: 'Eliminar bloqueo',
    [CONSTANTS.NAME_CHAT]: 'Nombre de chat',
    [CONSTANTS.UNBLOCKING_MSG]: 'Realmente desea eliminar el bloqueo del participante',
    [CONSTANTS.REMOVE]: 'Eliminar el',
    [CONSTANTS.BLOCKING]: 'Bloqueo',
    [CONSTANTS.CONFIRM_UNBLOCKING_YES]: '¡Sí, estoy de acuerdo!',
    [CONSTANTS.CONFIRM_UNBLOCKING_NO]: '¡No, no estoy de acuerdo!',
    [CONSTANTS.VIEW_BLOCKLIST_WARN]: 'Atención: Lista de bloqueo de chat solo está disponible para contenido / videos EN VIVO',
    [CONSTANTS.BLOCK_LIST_FAILURE]: 'No se pueden cargar los usuarios bloqueados!',
    [CONSTANTS.UNBLOCK_USER_SUCCESS]: '¡Usuario desbloqueado con éxito!',
    [CONSTANTS.UNBLOCK_USER_FAILURE]: '¡No se pudo desbloquear al usuario!',
    [CONSTANTS.USERS_THAT_ARE_NOW_VIEWING_THE_BROADCAST]: 'Total de usuarios que acceden a la transmisión.',
    [CONSTANTS.USERS_WITH_TAB_AND_SOUND_ON_OR_IN_FULL_SCREEN_DIVIDED_BY_THE_TOTAL_USERS_VIEWED]: 'Porcentaje de usuarios con pestañas y sonido habilitados o pantalla completa.',
    [CONSTANTS.USERS_THAT_ARE_EXPERIENCING_BUFFERING_ISSUE_OF_OVER_1_SECOND_DIVIDED_BY_TOTAL_USERS]: 'Porcentaje de usuarios que se almacenan en búfer durante más de 1 segundo.',
    [CONSTANTS.THE_AVERAGE_BITRATE_REPORTED_BY_KOPA_PLAYERS]: 'La tasa de bits promedio informada por los reproductores de Kopa.',

    [CONSTANTS.TRANSMISSION]: 'Transmisión',
    [CONSTANTS.WAITING_TRANSMISSION]: 'Esperando transmisión',
    [CONSTANTS.NO_LIVE_IN_PROGRESS]: 'El live solo podrá iniciarse cuando el PRESENTADOR inicie su transmisión.',
    [CONSTANTS.MUTE_USER]: 'Silenciar',
    [CONSTANTS.UNMUTE_USER]: 'Activar',
    [CONSTANTS.CREATING_ROOM_PLEASE_WAIT]: 'La sala se está creando, por favor espere...',

    [CONSTANTS.ONBOARDING_HOME_WELCOME_TITLE]: '¡Bienvenido a Kopa!',
    [CONSTANTS.ONBOARDING_HOME_WELCOME]: 'Es un placer tenerte aquí en Kopa. Estás en el área de organizadores, que es el espacio exclusivo para que administres tus canales. Te invitamos a hacer un recorrido rápido por nuestra plataforma. ¿Ven con nosotros?',
    [CONSTANTS.ONBOARDING_HOME_LOGOUT_TITLE]: 'Salir',
    [CONSTANTS.ONBOARDING_HOME_LOGOUT]: 'Haga clic aquí para salir de Kopa.',
    [CONSTANTS.ONBOARDING_LETS_START]: 'Empecemos nuestro recorrido',
    [CONSTANTS.ONBOARDING_SKIP_TOUR]: 'Omitir recorrido',
    [CONSTANTS.ONBOARDING_HOME_MY_VIDEOS_TITLE]: 'Mis vídeos',
    [CONSTANTS.ONBOARDING_HOME_MY_VIDEOS]: 'Aquí puedes administrar tus videos y transmisiones en vivo.',
    [CONSTANTS.ONBOARDING_HOME_VIDEO_CARD_TITLE]: 'Vídeos',
    [CONSTANTS.ONBOARDING_HOME_VIDEO_CARD]: 'Aquí puedes editar y ver tus videos y vidas, generar informes y cuestionarios.',
    [CONSTANTS.ONBOARDING_HOME_USERS_TITLE]: 'Usuarios',
    [CONSTANTS.ONBOARDING_HOME_USERS]: 'Dentro de usuarios tendrás acceso a la lista de usuarios y podrás realizar acciones para administrar los usuarios de tus canales, tales como: editar, dar de baja y agregar un nuevo usuario.',
    [CONSTANTS.ONBOARDING_HOME_GAMEFICATION_TITLE]: 'Gamificación',
    [CONSTANTS.ONBOARDING_HOME_GAMEFICATION]: 'Dentro de la gamificación tendrás acceso a la lista de participantes con sus respectivos puntos.',
    [CONSTANTS.ONBOARDING_HOME_NEW_VIDEO_TITLE]: 'Nuevo video',
    [CONSTANTS.ONBOARDING_HOME_NEW_VIDEO]: 'Aquí puede iniciar una transmisión en vivo y agregar un nuevo video.',
    [CONSTANTS.ONBOARDING_HOME_USER_AREA_TITLE]: 'Área de usuario',
    [CONSTANTS.ONBOARDING_HOME_USER_AREA]: 'Aquí puede ver y editar su perfil, agregando fotos e información de redes sociales. También puede seleccionar su idioma preferido.',
    [CONSTANTS.ONBOARDING_HOME_COMPLETE]: 'Finaliza el recorrido a través de la página de inicio.',
    [CONSTANTS.ONBOARDING_HOME_MENU_TITLE]: 'Menú del usuario',
    [CONSTANTS.ONBOARDING_HOME_MENU]: 'Dentro del menú de usuario, tendrá acceso al botones inicio, cambiar la configuración de videos, usuarios, código QR, cuestionarios, informes y bloqueo de usuarios en el chat salga de Kopa y cambie el idioma predeterminado de Kopa.',
    [CONSTANTS.ONBOARDING_COMPLETE]: 'tutorial completo',
    [CONSTANTS.ONBOARDING_HOME_TUTORIALS_TITLE]: 'Tutoriales',
    [CONSTANTS.ONBOARDING_HOME_TUTORIALS]: 'Aquí tendrás acceso a tutoriales que explican cómo funciona Kopa.',

    [CONSTANTS.ONBOARDING_USERS_AREA_TITLE]: 'Área do usuário',
    [CONSTANTS.ONBOARDING_USERS_AREA]: 'En el área de usuarios tendrás acceso a la lista de usuarios y podrás realizar acciones para administrar los usuarios de tus canales, tales como: editar, dar de baja y agregar un nuevo usuario.',
    [CONSTANTS.ONBOARDING_USERS_SEARCH_TITLE]: 'Búsqueda',
    [CONSTANTS.ONBOARDING_USERS_SEARCH]: 'Aquí puede buscar a sus usuarios por nombre y correo electrónico.',
    [CONSTANTS.ONBOARDING_USERS_OPTIONS_TITLE]: 'Mas acciones',
    [CONSTANTS.ONBOARDING_USERS_OPTIONS]: 'Aquí puede agregar nuevos usuarios manualmente o mediante una hoja de cálculo XLSX y descargar la lista de usuarios.',
    [CONSTANTS.ONBOARDING_USERS_CONTAINER_TITLE]: 'Listado de usuarios',
    [CONSTANTS.ONBOARDING_USERS_CONTAINER]: 'Aquí tendrá acceso a la lista de usuarios.',
    [CONSTANTS.ONBOARDING_USERS_ICON_DOTS_TITLE]: 'Opciones de listado',
    [CONSTANTS.ONBOARDING_USERS_ICON_DOTS]: 'Finalmente, tendrá acceso para editar el nivel de permiso del usuario y cancelar la inscripción del usuario.',

    [CONSTANTS.ONBOARDING_GAMIFICATION_AREA_TITLE]: 'Área de Gamificación',
    [CONSTANTS.ONBOARDING_GAMIFICATION_AREA]: 'En el área de gamificación tendrás acceso a la lista de usuarios con sus respectivos puntos y podrás realizar configuraciones como: habilitar o deshabilitar la gamificación, elegir la acción que sumará puntos al usuario y elegir cómo será esta suma de puntos.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_SEARCH_AREA_TITLE]: 'Búsqueda',
    [CONSTANTS.ONBOARDING_GAMIFICATION_SEARCH_AREA]: 'Aquí podrás buscar a tus usuarios con sus respectivos puntos por nombre y correo electrónico.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_FILTER_AREA]: 'Filtrar',
    [CONSTANTS.ONBOARDING_GAMIFICATION_FILTER]: 'Aquí puede filtrar la lista de usuarios.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_SETTINGS_TITLE]: 'Ajustes',
    [CONSTANTS.ONBOARDING_GAMIFICATION_SETTINGS]: 'En la configuración, puede habilitar o deshabilitar la gamificación, elegir qué agregará puntos al usuario y elegir cómo se agregarán los puntos.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_MORE_OPTIONS_TITLE]: 'Mas acciones',
    [CONSTANTS.ONBOARDING_GAMIFICATION_MORE_OPTIONS]: 'Aquí puede descargar la lista de usuarios.',
    [CONSTANTS.ONBOARDING_GAMIFICATION_CONTAINER_TITLE]: 'Listado de usuarios',
    [CONSTANTS.ONBOARDING_GAMIFICATION_CONTAINER]: 'Finalmente, tendrás acceso a la lista de usuarios.',

    [CONSTANTS.ONBOARDING_VIDEOS_EDIT_LIVE_TITLE]: 'Área de transmisión en vivo',
    [CONSTANTS.ONBOARDING_VIDEOS_EDIT_LIVE]: 'Aquí puede administrar su transmisión en vivo y obtener información al respecto.',
    [CONSTANTS.ONBOARDING_VIDEOS_DASHBOARD_TITLE]: 'Dashboard',
    [CONSTANTS.ONBOARDING_VIDEOS_DASHBOARD]: 'En el Dashboard podrá iniciar su transmisión en vivo y encontrará un tablero visual que contiene información e indicadores sobre su transmisión en vivo.',
    [CONSTANTS.ONBOARDING_VIDEOS_QRCODE_TITLE]: 'QR-Code',
    [CONSTANTS.ONBOARDING_VIDEOS_QRCODE]: 'Dentro del QR-Code puedes crear un QR-Code para comercializar productos en tu transmisión en vivo.',
    [CONSTANTS.ONBOARDING_VIDEOS_QUIZZES_TITLE]: 'Quizzes',
    [CONSTANTS.ONBOARDING_VIDEOS_QUIZZES]: 'Dentro de los quizzes, puede registrar quizzes o encuestas para usar durante la transmisión en vivo.',
    [CONSTANTS.ONBOARDING_VIDEOS_REPORTS_TITLE]: 'Informes',
    [CONSTANTS.ONBOARDING_VIDEOS_REPORTS]: 'Dentro de los informes, verá el informe de presencia de transmisión en vivo.',
    [CONSTANTS.ONBOARDING_VIDEOS_BLOCK_LIST_CHAT_TITLE]: 'Lista de bloqueo de chat',
    [CONSTANTS.ONBOARDING_VIDEOS_BLOCK_LIST_CHAT]: 'Esta área contiene la lista de usuarios bloqueados de la transmisión en vivo.',
    [CONSTANTS.ONBOARDING_VIDEOS_ENGAGEMENT_TITLE]: 'Engajamento',
    [CONSTANTS.ONBOARDING_VIDEOS_ENGAGEMENT]: 'Aquí verá sus indicadores de participación en vivo.',
    [CONSTANTS.ONBOARDING_VIDEOS_PREVIEW_TITLE]: 'Transmisión en vivo',
    [CONSTANTS.ONBOARDING_VIDEOS_PREVIEW]: 'Aquí puede iniciar y habilitar el chat de transmisión en vivo.',
    [CONSTANTS.ONBOARDING_VIDEOS_GRAPH_TITLE]: 'Gráfico',
    [CONSTANTS.ONBOARDING_VIDEOS_GRAPH]: 'Aquí puedes filtrar por indicador y obtener una gráfica para visualizar mejor la información de tu transmisión en vivo.',
    [CONSTANTS.ONBOARDING_VIDEOS_MONITORS_LIST_TITLE]: 'Lista de monitores',
    [CONSTANTS.ONBOARDING_VIDEOS_MONITORS_LIST]: 'Tendrás acceso a la lista de monitores.',
    [CONSTANTS.ONBOARDING_VIDEOS_GELOCALIZATION_TITLE]: 'Geolocalización',
    [CONSTANTS.ONBOARDING_VIDEOS_GELOCALIZATION]: 'Aquí puedes consultar la geolocalización de los usuarios.',

    [CONSTANTS.CAPTION]: 'Subtítulo',
    [CONSTANTS.CAPTIONS]: 'Subtítulos',
    [CONSTANTS.VIDEO_DETAILS]: 'Detalles del vídeo',
    [CONSTANTS.SELECT_CAPTION_LANGUAGE]: 'Selecciona el idioma de los subtítulos',
    [CONSTANTS.SAVE_CAPTION]: 'Guardar subtítulo',
    [CONSTANTS.ADD_CAPTION]: 'Añadir un subtitulo',
    [CONSTANTS.NEW_CAPTION]: 'Nuevo subtítulo',
    [CONSTANTS.REMOVE_CAPTION_CONFIRMATION]: '¿Estás seguro de que quieres eliminar este subtítulo?',
    [CONSTANTS.CAPTION_UPLOAD_SUCCESSFULLY]: 'Subtítulo subido con éxito!',
    [CONSTANTS.CAPTION_REMOVED_SUCCESSFULLY]: 'Subtítulo eliminado con éxito!',
    [CONSTANTS.CAPTION_REMOVED_FAILED]: '¡Error al eliminar el título!',
    [CONSTANTS.CAPTION_UPLOAD_FAILED]: '¡Error al cargar el título!',
    [CONSTANTS.CAPTION_UPDATE_SUCCESSFULLY]: '¡Subtítulo actualizado con éxito!',
    [CONSTANTS.CAPTION_UPDATE_FAILED]: '¡Error al actualizar el título!',
    [CONSTANTS.CAPTION_GET_FAILED]: '¡Error al obtener subtítulos!',
    [CONSTANTS.CAPTION_STATUS_ERROR]: 'Error',
    [CONSTANTS.CAPTION_STATUS_QUEUED]: 'Puesto en cola',
    [CONSTANTS.CAPTION_STATUS_READY]: 'Listo',
    [CONSTANTS.CAPTION_STATUS_REMOVED]: 'Eliminada',
    [CONSTANTS.CAPTION_STATUS_IMPORTING]: 'Importar',
    [CONSTANTS.CAPTION_STATUS_EXPORTING]: 'Exportar',
    [CONSTANTS.NO_CAPTIONS_FOUND]: 'No se encontraron subtítulos!',
    [CONSTANTS.FIRST_CAPTION_MESSAGE]: '¿Aún no has registrado ningún subtítulo? Es simple, fácil y solo toma unos minutos.',
    [CONSTANTS.REMOVING]: 'Quitando',
    [CONSTANTS.DOWNLOADING]: 'Descargando',
    [CONSTANTS.DOWNLOAD_CAPTION]: 'Descargar subtítulo',
    [CONSTANTS.BACK_TO_VIDEOS]: 'Volver a vídeos',
    [CONSTANTS.CAPTION_LANGUAGE_EXISTS]: 'Ya existe un subtítulo con este idioma!',
    [CONSTANTS.VIDEO_SETTINGS]: 'Ajustes de video',
    [CONSTANTS.LIVE_SETTINGS]: 'Ajustes de transmisión',

    [CONSTANTS.ARABIC]: 'Árabe',
    [CONSTANTS.CANTONESE]: 'Cantonés',
    [CONSTANTS.CATALAN]: 'Catalán',
    [CONSTANTS.CHINESE]: 'Chino',
    [CONSTANTS.DANISH]: 'Danés',
    [CONSTANTS.DUTCH]: 'Holandés',
    [CONSTANTS.ENGLISH]: 'Inglés',
    [CONSTANTS.FINNISH]: 'Finlandés',
    [CONSTANTS.FRENCH]: 'Francés',
    [CONSTANTS.GERMAN]: 'Alemán',
    [CONSTANTS.GREEK]: 'Griego',
    [CONSTANTS.HEBREW]: 'Hebreo',
    [CONSTANTS.HINDI]: 'Hindi',
    [CONSTANTS.HUNGARIAN]: 'Húngaro',
    [CONSTANTS.ICELANDIC]: 'Islandés',
    [CONSTANTS.INDONESIAN]: 'Indonesio',
    [CONSTANTS.IRISH]: 'Irlandesa',
    [CONSTANTS.ITALIAN]: 'Italiano',
    [CONSTANTS.JAPANESE]: 'Japonés',
    [CONSTANTS.KOREAN]: 'Coreano',
    [CONSTANTS.MALAYALAM]: 'Malayalam',
    [CONSTANTS['MANDARIN CHINESE']]: 'Chino mandarín',
    [CONSTANTS.NORWEGIAN]: 'Noruego',
    [CONSTANTS.POLISH]: 'Polaco',
    [CONSTANTS.PORTUGUESE]: 'Portugués',
    [CONSTANTS.ROMANIAN]: 'Rumano',
    [CONSTANTS.RUSSIAN]: 'Ruso',
    [CONSTANTS.SPANISH]: 'Español',
    [CONSTANTS.SWEDISH]: 'Sueco',
    [CONSTANTS['TAIWANESE MANDARIN']]: 'Mandarín taiwanés',
    [CONSTANTS.TAMIL]: 'tamil',
    [CONSTANTS.THAI]: 'Tailandés',
    [CONSTANTS.TURKISH]: 'Turco',
    [CONSTANTS.UKRAINIAN]: 'Ucranio',
    [CONSTANTS.URDU]: 'urdu',
    [CONSTANTS.VIETNAMESE]: 'Vietnamita',
    [CONSTANTS.WELSH]: 'Galés',
    [CONSTANTS.ZULU]: 'Zulú',

    [CONSTANTS.ONBOARDING_TUTORIALS_AREA_TITLE]: 'Área de tutoriales',
    [CONSTANTS.ONBOARDING_TUTORIALS_AREA]: 'En el área de tutoriales tendrás acceso a videos tutoriales que explican cómo funciona Kopa.',
    [CONSTANTS.ONBOARDING_TUTORIALS_TUTORIAL_CARD_TITLE]: 'Tutoriales',
    [CONSTANTS.ONBOARDING_TUTORIALS_TUTORIAL_CARD]: 'Aquí puedes ver los videos tutoriales.',

    [CONSTANTS.TUTORIALS]: 'Tutoriales',
    [CONSTANTS.FAIL_TO_LOAD_VIDEO_PROGRESS]: 'No se pudo cargar el progreso del video!',
    [CONSTANTS.FAIL_TO_LOAD_TUTORIALS]: 'No se pudieron cargar los tutoriales!',

    [CONSTANTS.NETWORK_FAILED]: 'No se puede acceder a nuestro servidor.',
    [CONSTANTS.NETWORK_FAILED_MESSAGE]: 'Comprueba tu conexión y vuelve a intentarlo.',
    // statistic video
    [CONSTANTS.STATISTIC_VIDEO_DETAIL]: 'Detalles',
    [CONSTANTS.STATISTIC_VIDEO_DAY]: 'día',
    [CONSTANTS.STATISTIC_VIDEO_DOWNLOAD]: 'Descargar video',
    [CONSTANTS.STATISTIC_VIDEO_PEOPLE_WATCH]: 'Personas que vieron el video',
    [CONSTANTS.STATISTIC_VIDEO_PEOPLE_AVERAGE_TIME_WATCH]: 'Tiempo Promedio Asistido',
    [CONSTANTS.STATISTIC_VIDEO_PLAY_IN_VIDEO]: 'Reproducir ningún video',
    [CONSTANTS.STATISTIC_VIDEO_ATTACHMENTS_ADDED]: 'Adjuntos agregados',
    [CONSTANTS.STATISTIC_VIDEO_ATTACHMENTS_DOWNLOAD]: 'descargar archivos adjuntos aquí',

    // Poll status notification
    [CONSTANTS.ROOM_POLL_STATUS_NOTIFY_FAILED]: '¡Falló la carga del video [NAME]!',
    [CONSTANTS.ROOM_POLL_STATUS_NOTIFY_SUCCESS]: 'El video [NAME] se ha completado.',

    [CONSTANTS.ROOM_STATUS_CREATED]: 'Creado',
    [CONSTANTS.ROOM_STATUS_ERROR_CONVERTING]: 'Error',
    [CONSTANTS.ROOM_STATUS_UNKNOWN]: 'Desconocido',
    [CONSTANTS.MAX_SCHEDULE_TIME]: '¡Solo es posible programar un vivo a un máximo de 4 horas desde la hora actual!',
};
