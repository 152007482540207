import styled from 'styled-components';

export const DatePickerContainer = styled.div`
  input {
      height: 30px;
      color: ${props => (props.fontColorInput ? props.fontColorInput : '#000')};
      font-weight: 600;
      padding-left: 5px;
  }

  span {
    color: #000;
  }

  img {
    margin-top: ${props => (props.marginTopImg ? props.marginTopImg : '-31px')};
    margin-right: 10px;
    float: right;
    position: relative;
  }

  button {
      border-width: 6px;
  }

  .react-datepicker-wrapper {
    display: flex;

    input {
      letter-spacing: 0.12em;
      height: ${props => (props.inputHeight ? props.inputHeight : '40px')};;
      padding: 0 40px 0 10px;
      font-size: 0.8rem;
      

        @media(max-width:1440px) {
          letter-spacing: 0.03em;
        }
    }
  }

  .react-datepicker-time__input input {
        border-radius: 4px;
        box-shadow: inset 0 2px 2px #e9e9e9;
        border: 1px solid #aeaeae;
        text-align: center;
  }
`;
