import React, { useState, useEffect, useRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';
import { NoVideo } from '../VideoCardsList/styles';
import { ButtonOptions, VideoCardContainer, DropdownOptions } from './styles';

import langs from '../../../assets/i18n/constants';
import dots from '../../../assets/dots.svg';

import noVideoIcon from '../../../assets/no-video.svg';

function VideoCard({
    stream, handleMuted, handleUnmuted, user, handleGotoGuestPresenterView, isPaused, isBlocked,
}) {
    const [showOptions, setShowOptions] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [blocked, setBlocked] = useState(isBlocked);

    const { t } = useTranslation();

    const cardRef = useRef(null);
    useEffect(() => {
        if (cardRef.current && stream) {
            cardRef.current.srcObject = stream;
        }
    }, [stream]);

    const handleMouseLeave = () => {
        setShowDropdown(false);
        setShowOptions(false);
    };

    return (
        <VideoCardContainer
            onMouseOver={() => setShowOptions(true)}
            onMouseLeave={() => handleMouseLeave()}
        >
            {(isPaused || blocked) && (
                <NoVideo>
                    <img src={noVideoIcon} alt="video" />
                    <h2>{blocked ? t(langs.MUTED_USER).toUpperCase() : t(langs.VIDEO_UNAVAILABLE).toUpperCase()}</h2>
                </NoVideo>
            )}
            <video ref={cardRef} autoPlay muted style={{ pointerEvents: 'none', width: '100%', height: '100%', visibility: isPaused ? 'hidden' : 'unset' }} />

            {showOptions && (
                <ButtonOptions onClick={() => setShowDropdown(!showDropdown)}>
                    <img src={dots} alt="dots" />
                </ButtonOptions>

            )}
            {
                showDropdown && showOptions && (

                    <OutsideClickHandler
                        onOutsideClick={() => setShowDropdown(false)}
                        display="contents"
                    >
                        <DropdownOptions>
                            {blocked ? (
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleUnmuted(user.email);
                                        setBlocked(false);
                                    }}
                                >{t(langs.UNMUTE_USER)}
                                </button>
                            ) : (
                                <button
                                    className="btn-mute"
                                    type="button"
                                    onClick={() => {
                                        handleMuted(user.email);
                                        setBlocked(true);
                                    }}
                                >
                                    {t(langs.MUTE_USER)}
                                </button>
                            )}
                            <hr />
                            {!user?.blocked && (
                                <button className="btn-mute" type="button" onClick={() => handleGotoGuestPresenterView(user.id, user.name)}>CONVIDAR</button>

                            )}
                        </DropdownOptions>

                    </OutsideClickHandler>
                )
            }
        </VideoCardContainer>
    );
}

export default VideoCard;
