import styled from 'styled-components';

export const Container = styled.div`
    footer {
        background: linear-gradient(90deg, #ff6e7f 0%, #ff9163 100%);
        height: 3px;
        display: block;
        position: fixed;
        bottom:0px;
        left:0;
        width: 100%;
    }

    @media(max-width: 800px){
        text-align: center;
    }
`;

export const ContainerTutorials = styled.div`
    width: 100%;
    height: calc(100% - 11%);
    padding: 0 23px;
    display: flex;
    justify-content: flex-start;
    flex-flow: column nowrap;
    align-items: center;
    align-content: center;
    position: relative;
`;
