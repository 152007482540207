import React, { useCallback, useEffect, useRef, useState } from 'react';
import fileSize from 'filesize';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import api from '@services/api';
import Notify from '@utils/notification';
import FileList from '@components/FileList';
import AvatarEditor from 'react-avatar-editor';
import Modal from '@src/components/Modal';
import Upload, { openDialog } from '../Upload';
import { CropContainer, UploadContainer } from '../styles';

export const KopaSingleImgUpload = ({
    fileType,
    accept,
    uploadedFile,
    setUploadedFile,
    uploadBaseUrl,
    thumbnailUrl,
    setThumbnailUrl,
}) => {
    const [uploadedFileBackup, setUploadedFilesBackup] = useState({});
    const [isUploading, setIsUploading] = useState([]);
    const [upImg, setUpImg] = useState();

    const setEditorRef = useRef();

    const { t } = useTranslation();

    function updateFileProgress(data, progress) {
        const uploadingFile = data;
        uploadingFile.progress = progress.progress;

        setUploadedFile(uploadingFile);
    }

    function updateFileUrl(data, url) {
        const uploadingFile = data;
        uploadingFile.url = url;
        uploadingFile.uploaded = true;

        setThumbnailUrl(url);
        setUploadedFile(uploadingFile);
    }

    const processUpload = async (uploadedFile) => {
        const data = new FormData();
        const baseUrl = fileType === 'thumbnail' && `${uploadBaseUrl}/${uploadedFile.name}`;

        setIsUploading([...isUploading, uploadedFile.name]);

        try {
            const response = await api._api.post(baseUrl);
            const { fields } = response.data.uploadObject;
            Object.keys(fields).forEach((key) => {
                data.append(key, fields[key]);
            });
            data.append('file', uploadedFile.file);

            await Axios.post(`${response.data.uploadObject.url}`, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: (e) => {
                    const progress = Number(
                        Math.round((e.loaded * 100) / e.total),
                    );
                    updateFileProgress(
                        uploadedFile,
                        { progress },
                    );
                },
            });

            setIsUploading((prev) => prev.filter((item) => item !== uploadedFile.name));
            const urlFile = response.data.imageUrl;
            updateFileUrl(uploadedFile, urlFile);
        } catch (err) {
            const error = uploadedFile;
            error.error = true;
            setIsUploading((prev) => prev.filter((item) => item !== uploadedFile.name));

            setThumbnailUrl('');
            setUploadedFile([error]);
        }
    };

    const handleCutImage = (file) => {
        setUpImg(null);

        const reader = new FileReader();
        reader.addEventListener('load', () => setUpImg(reader.result));
        reader.readAsDataURL(file);
    };

    const handleUpload = ([file]) => {
        setThumbnailUrl('');

        const image = new Image();
        image.addEventListener('load', () => {
            if (image.width < 300 || image.height < 300) {
                Notify.warn(t('MINIMUM_IMAGE_SIZE'));
                return;
            }

            if (image.width > 300 || image.height > 300) {
                handleCutImage(file);
            } else {
                const newUploadedFiles = {
                    file,
                    id: uniqueId(),
                    name: file.name,
                    readableSize: fileSize(file.size),
                    preview: URL.createObjectURL(file),
                    progress: 0,
                    uploaded: false,
                    error: false,
                    url: null,
                };
                setUploadedFile(newUploadedFiles);
                processUpload(newUploadedFiles);
            }
        });
        image.src = URL.createObjectURL(file);
    };

    function handleOpenExplorer() {
        setUploadedFilesBackup(uploadedFile);
        setUploadedFile({});
        // to make it work in Safari
        setTimeout(() => {
            openDialog();
        }, 1);
    }

    function handleCancelDialog() {
        setUploadedFile(uploadedFileBackup);
    }

    const onCroppedEnd = () => {
        let canvasScaled;

        if (setEditorRef.current) {
            canvasScaled = setEditorRef.current.getImageScaledToCanvas();
        } else {
            return;
        }

        setUpImg(null);
        canvasScaled.toBlob((file) => {
            handleUpload([new File([file], `${uniqueId()}.png`, file)]);
        }, 'image/png');
    };

    const warnUser = useCallback((e) => {
        if (uploadedFile && uploadedFile?.uploaded) {
            e.preventDefault();
            e.returnValue = '';
        }
    }, [uploadedFile]);

    useEffect(() => {
        window.addEventListener('beforeunload', warnUser);
        return () => window.removeEventListener('beforeunload', warnUser);
    }, [uploadedFile, warnUser]);

    return (
        <UploadContainer>
            { fileType === 'thumbnail' && (
                <>
                    {upImg && (
                        <Modal
                            isOpen={!!upImg}
                            width={600}
                            handleModal={() => setUpImg(null)}
                            title={t('MY_PROFILE')}
                            bodyWidth={100}
                        >
                            <CropContainer>
                                <div className="crop-component">
                                    <AvatarEditor
                                        image={upImg}
                                        width={300}
                                        height={300}
                                        scale={1}
                                        rotate={0}
                                        disableHiDPIScaling
                                        ref={setEditorRef}
                                    />
                                    <button
                                        type="submit"
                                        onClick={() => onCroppedEnd()}
                                        disabled={!upImg}
                                    >
                                        {t('CROP_IMAGE')}
                                    </button>

                                </div>

                            </CropContainer>
                        </Modal>
                    )}

                    {!uploadedFile.id && (
                    <Upload
                        disabledInput={thumbnailUrl === null}
                        onUpload={handleUpload}
                        message={t('UPLOAD_COVER_PLACEHOLDER')}
                        handleCancelDialog={handleCancelDialog}
                        accept={accept}
                    />
                    )}
                    {!uploadedFile.id && (
                    <div className="--cover-footer">
                        <span className="thumb-span">
                            {' '}
                            {t('CHANNEL', { context: 'THUMBNAIL' })}
                        </span>
                        <span>
                            {t('CHANNEL', { context: 'THUMBNAILSIZE' })}
                        </span>
                    </div>
                    )}
                    {uploadedFile.id && (
                    <FileList
                        clear={handleOpenExplorer}
                        uploadedFile={uploadedFile}
                        url={thumbnailUrl}
                    />
                    )}
                </>

            )}
        </UploadContainer>
    );
};
