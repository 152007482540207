import { Table } from '@mantine/core';
import styled from 'styled-components';

export const Container = styled(Table)`
    &.mantine-Table-root {
        thead {
            border: none;
            background-color: #1c1b2d;
            tr:first-child {
                th {
                    border: none;
                    color: #fff;
                    font-size: 1rem;
                    text-transform: uppercase;
                    :first-child{
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;                    
                    }
                    :last-child{
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                }
            }            
        }
        tbody{
            tr {
                td {
                    height: 41px;
                    color: #fff;
                    font-size: 1rem;
                    font-weight: bold;
                    border: none;
                }
                &:nth-child(even) {
                    background-color: #242339;
                }
            }
        }
    }
`;

export const TableButton = styled.button`
    padding: 2px;
    background-color: transparent;
    border: none;
    color: #fff;
    font-weight: bold;    
    text-transform: uppercase;
    display: flex;
    img {
        width: 18px;
        margin-right: 5px;
    }

    &:hover {
        color: #ff8f63;
    }

    &:disabled {
        opacity: 0.3;
    }
`;
