import styled, { css } from 'styled-components';

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;


export const DropContainer = styled.div.attrs({ className: 'dropzone' })`  
  border-radius:4px;
  cursor: pointer;

  transition: height 0.2s ease;
  
  ${props => props.isDragActive && dragActive};
  ${props => props.isDragReject && dragReject};
`;

const messageColors = {
    default: '#999',
    error: '#e57878',
    success: '#78e5d5',
};
export const UploadMessage = styled.p`
  display:flex;
  flex-direction: column;
  color: ${props => messageColors[props.type || 'default']};
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  font-size: 12px;
  font-weight: bold;  
  color: #474783;
  text-transform: uppercase;

  img {
      margin-bottom: 8px;
  }
`;
