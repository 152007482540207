/* eslint-disable indent */
import actions from '../../../utils/actions';

const initialState = {
    captionList: [],
    isFetching: true,
    removingList: [],
    showUploadModal: false,
    editCaptionObject: {},
};
export default function captionsReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.GET_CAPTIONS:
            return { ...state, isFetching: payload.withLoading };

        case actions.SET_CAPTIONS:
            return {
                ...state,
                captionList: payload.sort((a, b) => (a.language > b.language ? 1 : -1)),
                isFetching: false,
            };

        case actions.REMOVE_CAPTION_REQUEST:
            return { ...state, removingList: [...state.removingList, payload.captionId] };

        case actions.REMOVE_CAPTION_SUCCESS:
            return {
                ...state,
                removingList: state.removingList.filter(item => item !== payload),
                captionList: state.captionList.filter(item => item.id !== payload),
            };

        case actions.TOGGLE_ADD_CAPTION_UPLOAD_MODAL:
            return {
                ...state,
                showUploadModal: payload,
            };

        case actions.EDIT_CAPTION:
            return {
                ...state,
                editCaptionObject: payload,
            };

        default:
            return state;
    }
}
