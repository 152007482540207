import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import { ModalConfirmContainer } from './styles';

const ModalConfirm = ({
    title, featuredTitle, description, handleConfirm, handleCancel, handleModal, visible,
}) => {
    const { t } = useTranslation();

    return (
        <Modal title={title} featuredTitle={featuredTitle} isOpen={visible} handleModal={handleModal}>
            <ModalConfirmContainer>
                <div className="modal-confirm">
                    <p>{description}</p>
                    <div className="confirm-buttons">
                        <button
                            onClick={handleConfirm}
                            type="submit"
                        >{t('CONFIRM')}
                        </button>
                        <button
                            onClick={handleCancel}
                            className="cancel"
                            type="submit"
                        >{t('CANCEL')}
                        </button>
                    </div>
                </div>
            </ModalConfirmContainer>
        </Modal>
    );
};

export default ModalConfirm;
