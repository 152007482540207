import i18next from 'i18next';
import moment from 'moment';

const pattern = /(https?:\/\/|www\.)[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.(xn--)?[a-z0-9-]{2,20}\b([-a-zA-Z0-9@:%_\\+\\[\],.~#?&\\/=]*[-a-zA-Z0-9@:%_\\+\]~#?&\\/=])*/i;

export const isEmpty = (obj) => {
    const { hasOwnProperty } = Object.prototype;
    if (obj === undefined) return true;
    if (obj == null) return true;
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;
    if (typeof obj !== 'object') return true;
    for (const key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
};

export const toISOLocal = date => {
    const z = n => (`0${n}`).slice(-2);
    let off = date.getTimezoneOffset();
    const sign = off < 0 ? '+' : '-';
    off = Math.abs(off);

    return `${date.getFullYear()}-${z(date.getMonth() + 1)}-${z(date.getDate())}T${z(date.getHours())}:${z(date.getMinutes())}:${z(date.getSeconds())
    }${sign}${z(off / 60 | 0)}:${z(off % 60)}`;
};

export const translate = function (key) {
    return i18next.t(key);
};

export const patternUrl = new RegExp(pattern); // fragment locator

export const validURL = function (str) {
    return pattern.test(str);
};

export const getUrlFromString = function (str) {
    return validURL(str) ? pattern.exec(str)[0] : '';
};

export const replaceUntil = function (index, character, message) {
    return character.repeat(index) + message.substr(index);
};

export function limitCharacters(text, num) {
    if (num === 0) {
        return text;
    }

    if (text && num) {
        return text?.length > num ? `${text.substring(0, num--)}...` : text;
    }
    return '';
}

export function numberToLetter(number) {
    const alphabet = String.fromCharCode(97 + number);
    return alphabet;
}

export const handleInputMaskAndPlaceholder = (language) => {
    const inputDatepickerMaskAndPlaceholder = {
        'pt-BR': {
            mask: 'dd/MM/yyyy',
            maskWithTime: 'dd/MM/yyyy | HH:mm  ',
            placeholder: 'DD/MM/AAAA',
            placeholderWithTime: 'DD/MM/AAAA | HH:mm',

        },
        'es-ES': {
            mask: 'dd/MM/yyyy',
            maskWithTime: 'dd/MM/yyyy | HH:mm',
            placeholder: 'DD/MM/AAAA',
            placeholderWithTime: 'DD/MM/AAAA | HH:mm',
        },
        'en-US': {
            mask: 'MM/dd/yyyy',
            maskWithTime: 'MM/dd/yyyy | HH:mm',
            placeholder: 'MM/DD/YYYY',
            placeholderWithTime: 'MM/DD/YYYY | HH:mm',

        },
    };

    if (language) {
        return inputDatepickerMaskAndPlaceholder[language];
    }

    return {
        mask: 'dd/MM/yyyy',
        maskWithTime: 'dd/MM/yyyy | HH:mm  ',
        placeholder: 'DD/MM/AAAA',
        placeholderWithTime: 'DD/MM/AAAA | HH:mm',
    };
};

export const phoneMask = (value) => (
    value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
        .replace(/(-\d{4})\d+?$/, '$1')
);

export const checkUserTokenLocalStorage = () => {
    if (!JSON.parse(localStorage.getItem('KopaLive'))?.accessToken) {
        window.location.replace('/');
    }
};

export function isDateExpired(dateToCheck) {
    const today = moment();
    if (moment(dateToCheck).isBefore(today)) {
        return true;
    }
    return false;
}

export const makeIdsList = (channelList = []) => {
    const resultList = [];

    channelList.forEach(video => {
        if (video.tokyo_video_id) {
            resultList.push(video.tokyo_video_id);
        }
    });

    return resultList;
};

export const splitListInBatches = (list = [], parts = 1) => {
    const result = new Array(Math.ceil(list.length / parts))
        .fill()
        .map(_ => list.splice(0, parts));
    return result;
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i += 1) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};
