import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles({
    root: {
        position: 'absolute',
        left: 80,
        color: '#ff617a',
        zIndex: 10,
    },
});

export const VideoCardContainer = styled.div`
    cursor: pointer;
    display: flex;
    background-color: #212032;

    max-width: 416px;
    max-height: 200px;
    margin: 8px;
    border-radius: 8px;
    align-items: center;
    opacity: 0.75;
    transition: all 0.1s ease-in-out;
    position: relative;
    img {
        width: 200px;
        border-radius: 8px;
        height: 100%;
        object-fit: cover;
    }  

    .--backdrop {
        width: 200px;
        border-radius: 8px;
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        left: 0;
        height: 100%;
        z-index: 10;
    }

    &:hover {
        box-shadow: 0px 4px 10px -4px #000000;
        opacity: 1;
        transform: scale(1.01);
    }
    
`;

export const ThumbnailSection = styled.div`
    overflow: hidden;
`;

export const InfoSection = styled.div`
    opacity: 0.98;
    width: 200px;
    padding: 0 8px;

    
    h5, p {
        color: #ffffff;   
        font-weight: bold;
    }

    h5, .--status-row p,  p, button, span {
        margin-left: 15px;
        text-transform: uppercase;        
    }

    span {
        display: flex;        
    }

    h5 {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.25;
        letter-spacing: 0.38px;  
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;     
    }

    .--status-row {
        display: inline-flex;
        p {
            font-size: 12px;
            &.--active {
                color: #31a6a1;
                margin-left: 5px;
            }
            &.--error {
                color: #da5058;
                margin-left: 5px;
            }
            &.--uploading {
                color: #bb7156;
                margin-left: 5px;
            }
            &.--converting {
                color: #bb7156;
                margin-left: 5px;
            }
            &.--finished {
                color: #3ea0d1;
                margin-left: 5px;
            }
        }
    }


    button {
        font-size: 12px;
        font-weight: 800;
        letter-spacing: 0.29px;
        color: #bb7156;
        background: transparent;
        outline: none;
        border: none;
        cursor: pointer;
        border: 2px solid transparent;
        padding: 5px;
        border-radius: 5px;
        transition: border 0.2s ease-in-out;

        &:hover {
            border: 2px solid #bb7156;
        }
    }
`;

export const Divider = styled.div`  
    background-color: #282b3a;
    width: 100%;
    height: 1px;
    margin: 10px 0;
`;

export const LiveTag = styled.span`
    height: 23px;
    border-radius: 3px;
    background-color: #ff9162;
    position: absolute;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    top: 0;
    right: 0;
    margin-right: 210px;
    margin-top: 10px;
`;

export const RecTag = styled.span`
    height: 23px;
    border-radius: 3px;
    background-color: #ff6479;
    position: absolute;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    top: 0;
    right: 0;
    margin-right: 210px;
    margin-top: 10px;    
`;

export const TransmittingTag = styled.span`
    height: 23px;
    border-radius: 3px;
    background-color: #af0e30;
    position: absolute;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    top: 0;
    right: 0;
    margin-right: 210px;
    margin-top: 10px;  

    &:before {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        background-color: #fff;
        left: 5px;
        border-radius: 50%;
    }  
`;
