import React from 'react';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ROOM_STATUS, LIVE_STATUS } from '@utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import languageConstants from '@langs/constants';
import {
    VideoCardContainer, InfoSection, Divider, LiveTag, TransmittingTag, useStyles,
} from './styles';

function isAvailable(dateToCheck) {
    const today = Moment();
    if (Moment(dateToCheck).isAfter(today)) {
        return true;
    }
    return false;
}

function renderType(roomLive = false, t) {
    let Tag;
    if (roomLive) {
        Tag = TransmittingTag;
    } else {
        Tag = LiveTag;
    }
    const Type = t(languageConstants.LIVE);

    return <Tag>{Type}</Tag>;
}

/**
 * Map room status to status based on language
 * @param {string} status
 * @param {function} t
 */
function renderStatus(status, t) {
    const mapping = {
        [ROOM_STATUS.CONVERTING]: t(languageConstants.CONVERTING),
        [ROOM_STATUS.CONVERTING_ERROR]: t(languageConstants.ROOM_STATUS_ERROR_CONVERTING),

        [ROOM_STATUS.UPLOADING]: t(languageConstants.UPLOADING),
        [ROOM_STATUS.UPLOAD_ERROR]: t(languageConstants.UPLOAD_ERROR),
        [ROOM_STATUS.UPLOADED]: t(languageConstants.DONE),

        [ROOM_STATUS.CREATED]: t(languageConstants.ROOM_STATUS_CREATED),
        [ROOM_STATUS.LIVE]: t(languageConstants.LIVE),
        [ROOM_STATUS.OFF]: t(languageConstants.LIVE_ENDED),
        [ROOM_STATUS.GOING_OFF]: t(languageConstants.LIVE_ENDED),
        [ROOM_STATUS.DONE]: t(languageConstants.DONE),
    };

    return <p className={`--${status}`}>{mapping[status] || t(languageConstants.ROOM_STATUS_UNKNOWN)}</p>;
}

function VideoCard({ room, upload_progress, isUploading = false, onClick = null, button = { text: '', action: null, disabled: false } }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <VideoCardContainer onClick={onClick} data-testid="video-card" data-tour="tour__videocard">
            {isUploading && (
                <>
                    <CircularProgress className={classes.root} size={50} color="secondary" />
                    <div className="--backdrop" />
                </>
            )}
            <img src={room?.image} alt="Vídeo" />
            {room?.liveStatus === LIVE_STATUS.on && renderType(room?.live, t)}
            <InfoSection>
                <h5 className="--title">{room.name.length > 24 ? `${room.name.substring(0, 24)}...` : room.name}</h5>
                <div>
                    <div className="--status-row">
                        <p>{t(languageConstants.TYPE)}:</p>
                        {room?.recorded ? <p>{t(languageConstants.UPLOAD)}</p> : <p>{t(languageConstants.TRANSMISSION)}</p>}
                    </div>
                </div>
                <div className="--status-row">
                    <p>{t(languageConstants.STATUS)}:</p>
                    {room?.status
                        ? (
                            renderStatus(room.status, t)
                        )
                        : (
                            <>
                                {
                                    room?.endDate
                                        ? (
                                            <>
                                                {room?.endDate && isAvailable(room?.endDate) ? (
                                                    <p className="--active">{t(languageConstants.ACTIVE)}</p>
                                                ) : (
                                                    <p className="--finished">{t(languageConstants.FINISHED)}</p>
                                                )}
                                            </>
                                        )
                                        : (
                                            <p className="--active">{t(languageConstants.ACTIVE)}</p>
                                        )
                                }
                            </>
                        )}
                </div>
                { isUploading && room?.status === 'uploading' && upload_progress && (
                    <div className="--status-row">
                        <p>{t(languageConstants.UPLOAD_VIDEO_PROGRESS)}:</p>
                        <p className="--uploading">{upload_progress?.replaceAll('.', ',')}%</p>
                    </div>
                )}
                <span />
                <Divider />
                <p>{t(languageConstants.STARTDATE)}: {room.startDate ? Moment(room.startDate).format('DD/MM/YYYY HH:mm') : ''} </p>
                {
                    room?.endDate && (
                        <p>{t(languageConstants.ENDDATE)}: {Moment(room.endDate).format('DD/MM/YYYY HH:mm')}</p>
                    )
                }
                <Divider />
                <button data-testid="card-video-button" type="button" onClick={button.action} disabled={button.disabled}>{button.text}</button>
            </InfoSection>
        </VideoCardContainer>
    );
}

export default VideoCard;
