import { Tabs } from '@mantine/core';
import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 17px;
    width:100% ;
    padding: 19px 28px;
    border-radius: 8px;
    display: flex;
    background-color: #272637;
    .css-129d476-SkeletonTheme {
        flex: 1;
    }
`;

export const VideoTabs = styled(Tabs)`
    &.mantine-Tabs-root {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-left: 20px;
        color: unset;
    }

    .mantine-Group-root{
        border-radius: 10px;
        overflow: hidden;
    }

    .mantine-Tabs-tabLabel {
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.0rem;
    }

    .mantine-Tabs-tabsListWrapper {
        border: none;
    }
    
    .mantine-Tabs-tabControl {
        flex: 1;
        background-color: #212032;
        height: 64px;
        border-bottom: 3px solid #313044;
    }

    .mantine-Tabs-tabActive {
        border-bottom-color: #ff8f63 ;
        .mantine-Tabs-tabLabel {
            color: #ff8f63;
        }
    }

    .mantine-Tabs-body {
        height: 100%;
        display: flex;
        flex: 1;
    } 
`;

export const CaptionsTableContainer = styled.div`
    background-color: #272637;
    width: 100%;
`;
