import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import screenFull from 'screenfull';
import {
    Container,
    PageHeader,
    VideoWallContainer,
    VideoWallSettingsContainer,
    TilesControlContainer,
    TimerButton,
} from './styles';
import useRooms from '../../hooks/useRooms';
import { getChannelRooms, fetchEditRoom } from '../../store/modules/rooms/actions';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import { limitCharacters } from '../../utils/functions';
import paths from '../../utils/paths';
import webRTCSocket from '../../services/WebRTCSocket';
import mediaSoup from '../../services/MediaSoupConsumerService';
import api from '../../services/api';
import plusIcon from '../../assets/plus-outlined.svg';
import minusIcon from '../../assets/minus-outlined.svg';
import fullScreen from '../../assets/full-screen.svg';
import carouselIcon from '../../assets/carousel-icon.svg';
import VideoCardList from './VideoCardsList';
import ButtonHeader from '../../components/ButtonHeader';

function loadVideoList(videosTotal, tilesTotal) {
    const result = Math.ceil(videosTotal / tilesTotal);
    return result;
}

const timers = [
    { id: 1, time: 'Desativado', msTime: 0 },
    { id: 2, time: '10 Segundos', msTime: 10000 },
    { id: 3, time: '30 Segundos', msTime: 30000 },
    { id: 4, time: '60 Segundos', msTime: 60000 },
];

function VideoWallSettings({
    handleIncrementTiles,
    handleDecrementTiles,
    handleFullScreen,
    videosToShow,
    setSelectedTime,
    selectedTime,
    pages,
}) {
    const { t } = useTranslation();

    return (
        <VideoWallSettingsContainer>
            {pages > 1 && (
                <TilesControlContainer>
                    <img className="carousel" src={carouselIcon} alt="Carousel" />

                    <TimerButton value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)}>
                        {timers.map((item) => (
                            <option key={item.id} value={item.msTime}>{item.time}</option>
                        ))}
                    </TimerButton>
                </TilesControlContainer>
            )}
            <TilesControlContainer>
                <button type="button" onClick={handleIncrementTiles}>
                    <img alt="plusIcon" src={plusIcon} />
                </button>
                <h6> GRID <span>{videosToShow} X {videosToShow}</span></h6>
                <button type="button" onClick={handleDecrementTiles}>
                    <img alt="minusIcon" src={minusIcon} />
                </button>
            </TilesControlContainer>

            <ButtonHeader
                title={screenFull.isFullscreen ? t('COMEBACK') : t('FULL_SCREEN')}
                onClick={handleFullScreen}
                icon={fullScreen}
            />

        </VideoWallSettingsContainer>
    );
}
function removeConsumer(consumers) {
    consumers.forEach(item => {
        mediaSoup.removeConsumer(item.id, 'video');
    });
}

function stopStream(users = []) {
    users.forEach(user => {
        const foundProducer = mediaSoup._producers.find(producer => producer.id === user.id);
        if (foundProducer) {
            if (foundProducer?.stream) {
                foundProducer.stream.getTracks().forEach(async track => {
                    await track.stop();
                    await foundProducer.stream.removeTrack(track);
                    delete foundProducer.stream;
                });
            }
        }
    });
}

function handleFullScreen(element) {
    if (screenFull.isEnabled) {
        screenFull.toggle(element);
    }
}

let lastVideoIndex = 0;
let producers = [];
const RECONNECTION_INTERVAL = 3000;
let videoWallTimer = null;

export default function VideoWall() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { selectedChannel } = useRooms();
    const { id } = useParams();
    const { setItems } = useBreadcrumbs();
    const videoWallFullScreenContainer = useRef(null);
    const { videoUrl, socketPath } = useRooms();
    const reconnectTimer = useRef();

    const [videosList, setVideosList] = useState([]);
    const [videosToShow, setVideosToShow] = useState(4);
    const [base, setBase] = useState(2);
    const [maxGridSize] = useState(49);
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [isWebSocketConnected, setIsWebSocketConnected] = useState(false);
    const [isMediaSoupConnected, setIsMediaSoupConnected] = useState(false);
    const [selectedTime, setSelectedTime] = useState(0);
    const accessToken = api.getAccessToken();

    const loadProducersData = useCallback(() => {
        setPages(loadVideoList(producers.length, videosToShow));
        const list = producers.slice(lastVideoIndex, lastVideoIndex + videosToShow);
        setVideosList(list);
        mediaSoup.processConsume(list);
    }, [videosToShow]);

    const handleOnGotProducer = useCallback((producer) => {
        const foundProducer = producers.find(item => item.id === producer.id);
        if (!foundProducer) {
            producers = [...producers, producer];
            loadProducersData();
        } else {
            producers[producers.findIndex(item => item.id === producer.id)] = producer;
            loadProducersData();
        }
    }, [loadProducersData]);

    const handleOnGotProducersList = useCallback((producersList) => {
        producers = producersList;
        loadProducersData();
    }, [loadProducersData]);

    const nextPage = useCallback(() => {
        if (currentPage < pages) {
            stopStream(videosList);
            removeConsumer(videosList);
            setCurrentPage(state => state + 1);
            lastVideoIndex += videosToShow;
            loadProducersData();
        }
    }, [
        currentPage,
        pages,
        loadProducersData,
        videosToShow,
        videosList,
    ]);

    function previousPage() {
        if (currentPage > 1) {
            stopStream(videosList);
            removeConsumer(videosList);
            setCurrentPage(state => state - 1);
            lastVideoIndex -= videosToShow;
            loadProducersData();
        }
    }

    const goFirstPage = useCallback(() => {
        stopStream(videosList);
        removeConsumer(videosList);
        setCurrentPage(1);
        lastVideoIndex = 0;
        loadProducersData();
    }, [loadProducersData, videosList]);

    const handleIncrementTiles = useCallback(() => {
        if (videosToShow < maxGridSize) {
            setBase(base => base + 1);
            lastVideoIndex = 0;
            loadProducersData();
            setCurrentPage(1);
        }
    }, [loadProducersData, videosToShow, maxGridSize]);

    const handleDecrementTiles = useCallback(() => {
        if (videosToShow > 1) {
            setBase(base => base - 1);
            lastVideoIndex = 0;
            loadProducersData();
            setCurrentPage(1);
        }
    }, [loadProducersData, videosToShow]);

    const dynamicPageChange = useCallback((time) => {
        if (Number(time) > 0) {
            clearInterval(videoWallTimer);
            videoWallTimer = setInterval(() => {
                if (pages === currentPage) {
                    goFirstPage();
                    return;
                }
                nextPage();
            }, time);

            if (pages === 1) {
                clearInterval(videoWallTimer);
            }
        } else {
            clearInterval(videoWallTimer);
            videoWallTimer = null;
        }
    }, [nextPage, currentPage, goFirstPage, pages]);

    const renderVideoWallSettings = useCallback(() => (
        <VideoWallSettings
            handleIncrementTiles={handleIncrementTiles}
            handleDecrementTiles={handleDecrementTiles}
            handleFullScreen={() => handleFullScreen(videoWallFullScreenContainer.current)}
            videosToShow={base}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            pages={pages}

        />
    ), [handleDecrementTiles, handleIncrementTiles, base, selectedTime, setSelectedTime, pages]);

    const handleDisconnectUser = useCallback((user) => {
        producers = producers.filter(producer => producer.id !== user.id);
        setVideosList(state => state.filter(item => item.id !== user.id));
        setPages(loadVideoList(producers.length, videosToShow));
    }, [videosToShow]);

    const handleOnGotStream = useCallback((producer) => {
        setVideosList(state => state.map(user => (user.id === producer.id ? producer : user)).sort(video => (!video?.stream || video?.isPaused ? 1 : -1)));
        mediaSoup._consuming = mediaSoup._consuming.filter(item => item.id !== producer.id);
    }, []);

    function updatePausedVideo(producerId) {
        setVideosList(list => list.map(user => (user.id === producerId ? { ...user, isPaused: true } : user)));
    }

    function updateResumedVideo(producerId) {
        setVideosList(list => list.map(user => (user.id === producerId ? { ...user, isPaused: false } : user)));
    }

    function updateMutedVideo(producerId) {
        setVideosList(list => list.map(user => (user.id === producerId ? { ...user, blocked: true } : user)));
    }

    function updateUnmutedVideo(producerId) {
        setVideosList(list => list.map(user => (user.id === producerId ? { ...user, blocked: false } : user)));
    }

    const handleConnectWebRTC = useCallback(() => {
        mediaSoup.setSocket(webRTCSocket.socket);
        mediaSoup.onConnectListener = () => setIsMediaSoupConnected(true);
        mediaSoup.onDisconnectedListener = () => setIsMediaSoupConnected(false);
        mediaSoup.onPauseVideoListener = updatePausedVideo;
        mediaSoup.onResumeVideoListener = updateResumedVideo;
        mediaSoup.onMutedUserListener = updateMutedVideo;
        mediaSoup.onUnmutedUserListener = updateUnmutedVideo;
        mediaSoup.connect();
    }, []);

    const startWebSocketReconnection = useCallback(() => {
        reconnectTimer.current = setInterval(() => {
            if (!webRTCSocket.socket?.connected) {
                handleConnectWebRTC();
            }
        }, RECONNECTION_INTERVAL);
    }, [handleConnectWebRTC]);

    useEffect(() => {
        setVideosToShow(base ** 2);
    }, [base]);

    useEffect(() => {
        if (videoUrl && socketPath && accessToken) {
            webRTCSocket.onConnectListener.push(() => setIsWebSocketConnected(true));
            webRTCSocket.onDisconnectListener.push(() => setIsWebSocketConnected(false));
            webRTCSocket.authenticate(videoUrl, socketPath, accessToken, true);
        }
        return () => {
            mediaSoup.clearWebSocketInstance();
            webRTCSocket.disconnect();
        };
    }, [socketPath, videoUrl, accessToken, handleConnectWebRTC]);

    useEffect(() => {
        if (!videoUrl || socketPath) {
            dispatch(fetchEditRoom(id));
        }
    }, [dispatch, id, socketPath, videoUrl]);

    useEffect(() => () => {
        mediaSoup._consumers.forEach(consumer => {
            mediaSoup.removeConsumer(String(consumer.producerId), 'video');
            mediaSoup.removeConsumer(String(consumer.producerId), 'audio');
        });
    }, []);

    useEffect(() => {
        loadProducersData();
    }, [loadProducersData, handleIncrementTiles, handleDecrementTiles]);

    useEffect(() => {
        if (selectedChannel?.id) {
            dispatch(getChannelRooms(selectedChannel?.id));
        }
    }, [dispatch, selectedChannel]);

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t('ORGANIZER_DASHBOARD') },
            { to: `${paths.CHANNELS}/${id}/details`, label: t('DASHBOARD') },
            { to: `${paths.VIDEO_WALL}/${id}`, label: 'Video Wall' },
        ];
        setItems(breadcrumbsItems);
    }, [id, selectedChannel, setItems, t]);

    useEffect(() => {
        mediaSoup.onGotProducerListener = handleOnGotProducer;
        mediaSoup.onGotProducersListListener = handleOnGotProducersList;
        mediaSoup.onDisconnectProducerListener = startWebSocketReconnection;
        mediaSoup.onGotStream = handleOnGotStream;
        mediaSoup.onProducerDisconnect = handleDisconnectUser;
        mediaSoup.onReconnectListener = handleConnectWebRTC;
    }, [
        handleConnectWebRTC,
        handleDisconnectUser,
        handleOnGotProducer,
        handleOnGotProducersList,
        handleOnGotStream,
        loadProducersData,
        startWebSocketReconnection,
    ]);

    useEffect(() => {
        if (webRTCSocket.socket?.connected) {
            setIsWebSocketConnected(true);
        }
    }, []);

    useEffect(() => {
        if (isWebSocketConnected && !isMediaSoupConnected) {
            handleConnectWebRTC();
        }
    }, [handleConnectWebRTC, isMediaSoupConnected, isWebSocketConnected, loadProducersData]);

    useEffect(() => {
        dynamicPageChange(selectedTime);
    }, [selectedTime, dynamicPageChange]);

    return (
        <Container>
            <PageHeader>
                <h2>{selectedChannel?.id && limitCharacters(`#${selectedChannel?.id} - ${selectedChannel?.name}`, 40)}</h2>
                {renderVideoWallSettings()}
            </PageHeader>

            <VideoWallContainer>
                <VideoCardList
                    handleNextPage={nextPage}
                    handlePreviewsPage={previousPage}
                    VideoWallSettingsComponent={renderVideoWallSettings}
                    videoWallFullScreenElementRef={videoWallFullScreenContainer}
                    numberOfTiles={videosToShow}
                    videosList={videosList}
                    gridSize={base}
                    currentPage={currentPage}
                    pages={pages}
                />
            </VideoWallContainer>

        </Container>
    );
}
