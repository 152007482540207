import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { MdError } from 'react-icons/md';

import ATTACHMENT from '../../assets/attachments';
import DeleteIcon from '../../assets/delete-white2.svg';

import { Container, Content, FileInfo, Preview, AttachIcon } from './styles';

function MultipleFileList({ files, clearList }) {
    function handleClick(event, uploadedFile) {
        event.preventDefault();
        const index = files.indexOf(uploadedFile);
        files.splice(index, 1);
        clearList([...files]);
    }

    const extensionFile = filename => filename.split('.').reverse()[0].toUpperCase();

    const isImageFile = ext => ['JPG', 'JPEG', 'PNG'].includes(ext.toUpperCase());

    return (
        <Container>
            {files.map(uploadedFile => (
                <div className="div-nexos" key={uploadedFile.id}>
                    <Content>
                        {(isImageFile(extensionFile(uploadedFile.name)) && (
                            <Preview src={uploadedFile.preview} />
                        )) || (<AttachIcon src={ATTACHMENT[extensionFile(uploadedFile.name)]} />)}

                        <FileInfo>
                            <span title={uploadedFile.name}>{uploadedFile.name.substr(0, 40)}{uploadedFile.name.length > 40 ? '...' : null}</span>
                        </FileInfo>

                        <div className="upload-info">
                            {!uploadedFile.uploaded && !uploadedFile.error && (
                                <CircularProgressbar
                                    styles={{
                                        root: { width: 24 },
                                        path: { stroke: '#7159c1' },
                                    }}
                                    strokeWidth={10}
                                    value={uploadedFile.progress}
                                />
                            )}
                            {uploadedFile.uploaded && <button type="submit" onClick={e => handleClick(e, uploadedFile)}><img alt="Delete icon" src={DeleteIcon} /></button>}
                            {uploadedFile.error && <MdError size={24} color="#e57878" />}
                        </div>
                    </Content>
                </div>
            ))}
        </Container>
    );
}

export default MultipleFileList;
