import React, { useState } from 'react';
import { Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Tooltip from '@src/components/Tooltip';
import langs from '@src/assets/i18n/constants';
import QuestionIcon from '@src/assets/question-2.svg';
import arrowDownIcon from '@src/assets/arrow-down-icon.svg';
import alertIco from '@src/assets/alert-grey.svg';

import {
    GamificationSettingsItemContainer,
    GamificationSettingsContentLeft,
    GamificationSettingsContentRight,
    GamificationSection,
    SwitchArea,
    GamificationDefaultScore,
    GamificationAccessRoom,
    GamificationAccessRoomMode,
    GamificationModeSelect,
    GamificationModeOptions,
    GamificationModeOption,
    GamificationQuizzScore,
    GamificationAccessRoomScore,
    TooltipFormatText,
} from './styles';

const GamificationSettingsItem = ({
    sectionTitle,
    isChecked,
    handleSwitchChange,
    settingsType,
    punctualDropdownActive,
    setPunctualDropdownActive,
    gamificationItemSettings,
    pointsValue,
    handlePointsChange,
}) => {
    const { t } = useTranslation();
    const [isErroredAccessRoom, setIsErroredAccessRoom] = useState(false);
    const [isErroredPointsValue, setIsErroredPointsValue] = useState(false);
    const [isErroredDefaultScores, setIsErroredDefaultScores] = useState(false);
    const {
        accessRoomSimpleMode,
        accessRoomTolerance,
        setAccessRoomTolerance,
        setAccessRoomSimpleMode,

    } = gamificationItemSettings || {};

    const handleSelectedOption = () => {
        setPunctualDropdownActive();
        setAccessRoomSimpleMode(oldMode => !oldMode);
    };

    const handleInputChange = (evt) => {
        handlePointsChange(evt.target.value);
    };

    const renderPunctualityModeTooltip = () => (
        <TooltipFormatText>
            {t(langs.GAMIFICATION_PUNCTUALITY_MODE_RULE)}
        </TooltipFormatText>
    );

    const sectionTooltip = {
        accessRoomType: (
            <TooltipFormatText>
                {t(langs.GAMIFICATION_ACCESS_ROOM_RULE)}
            </TooltipFormatText>

        ),
        downloadAttachmentType: (
            <TooltipFormatText>
                {t(langs.GAMIFICATION_DOWNLOAD_RULES)}
            </TooltipFormatText>
        ),
        loginAppType: (
            <TooltipFormatText>
                {t(langs.GAMIFICATION_LOGIN_APP_RULES)}
            </TooltipFormatText>

        ),
        chatInteractionType: (
            <TooltipFormatText>
                {t(langs.CHAT_INTERACTION_RULES)}
            </TooltipFormatText>

        ),
        quizzType: (
            <TooltipFormatText>
                {t(langs.GAMIFICATION_QUIZZ_RULE)}
            </TooltipFormatText>

        ),
    };

    const handleInputBlur = (e) => {
        if (e.target.name === 'accessTolerance' && e.target.value < 1) {
            setIsErroredAccessRoom(true);
        }

        if (e.target.name === 'accessPoint' && e.target.value < 1) {
            setIsErroredPointsValue(true);
        }

        if (e.target.name === 'defaultScores' && e.target.value < 1) {
            setIsErroredDefaultScores(true);
        }
    };

    return (
        <GamificationSettingsItemContainer>
            <GamificationSettingsContentLeft>
                <GamificationSection>
                    {sectionTitle}
                    <SwitchArea>
                        <Switch checked={!!isChecked} onChange={handleSwitchChange} />
                        <Tooltip content={sectionTooltip[settingsType]} direction="right" wrapText>
                            <img src={QuestionIcon} alt="question" />
                        </Tooltip>
                    </SwitchArea>
                </GamificationSection>
            </GamificationSettingsContentLeft>
            <GamificationSettingsContentRight isSectionActive={isChecked}>

                { settingsType === 'accessRoomType' && (
                    <GamificationAccessRoom>
                        <GamificationAccessRoomMode>
                            <div className="punctuality-mode-title">
                                <p>{t(langs.PUNCTUALITY_MODE)}</p>
                                {/* <Tooltip content={renderPunctualityModeTooltip()}> */}
                                <Tooltip content={renderPunctualityModeTooltip()} direction="right" wrapText>
                                    <img src={QuestionIcon} alt="question" />
                                </Tooltip>
                            </div>
                            <GamificationModeSelect isActive={punctualDropdownActive} onClick={setPunctualDropdownActive}>
                                <p>{accessRoomSimpleMode ? t(langs.SIMPLE_MODE) : t(langs.GRADUAL_MODE) }</p>
                                <img src={arrowDownIcon} alt="arrow" />
                            </GamificationModeSelect>
                            <GamificationModeOptions isActive={punctualDropdownActive}>
                                <GamificationModeOption onClick={handleSelectedOption}>
                                    <p>{accessRoomSimpleMode ? t(langs.GRADUAL_MODE) : t(langs.SIMPLE_MODE)}</p>
                                </GamificationModeOption>
                            </GamificationModeOptions>
                        </GamificationAccessRoomMode>
                        <GamificationAccessRoomScore isErroredAccessRoom={isErroredAccessRoom}>
                            <p>{t(langs.TOLERANCY)} ({t(langs.MINUTES)})</p>
                            <input min={1} type="number" value={accessRoomTolerance || ''} onChange={(evt) => setAccessRoomTolerance(evt.target.value)} onBlur={e => handleInputBlur(e)} name="accessTolerance" onFocus={() => setIsErroredAccessRoom(false)} />
                        </GamificationAccessRoomScore>
                        <GamificationAccessRoomScore isErroredAccessRoom={isErroredPointsValue}>
                            <p>{accessRoomSimpleMode ? t(langs.SCORE) : t(langs.MAX_SCORE)}</p>
                            <input min={1} type="number" value={pointsValue} onChange={handleInputChange} name="accessPoint" onBlur={e => handleInputBlur(e)} onFocus={() => setIsErroredPointsValue(false)} />
                        </GamificationAccessRoomScore>
                    </GamificationAccessRoom>
                )}
                {
                    settingsType !== 'accessRoomType' && settingsType !== 'quizzType' && (
                    <GamificationDefaultScore isErroredAccessRoom={isErroredDefaultScores}>
                        <p>{t(langs.SCORE)}</p>
                        <input type="number" value={pointsValue} onChange={handleInputChange} data-testid="gamification-input" name="defaultScores" onBlur={e => handleInputBlur(e)} onFocus={() => setIsErroredDefaultScores(false)} />
                    </GamificationDefaultScore>

                    )
                }
                {
                    settingsType === 'quizzType' && (
                    <GamificationQuizzScore>
                        <div className="image-area">
                            <img src={alertIco} alt="alert" />
                        </div>
                        <div className="text-area">
                            <p>{t(langs.GAMIFICATION_SETTINGS_QUIZZ_TIP_A)} <strong>{t(langs.QUIZZES_SURVEYS)}</strong> {t(langs.GAMIFICATION_SETTINGS_QUIZZ_TIP_B)} <strong>{t(langs.QUIZZES_SURVEYS)}</strong>
                            </p>
                        </div>
                    </GamificationQuizzScore>

                    )
                }

            </GamificationSettingsContentRight>
        </GamificationSettingsItemContainer>
    );
};

export default GamificationSettingsItem;
