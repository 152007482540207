import styled from 'styled-components';

export const VideoCardContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
`;

export const ButtonOptions = styled.div`
    position: absolute;
    align-items: center;
    width: 50px;
    height: 30px;
    top: 20px;
    right: 30px;
    background: transparent;
    border: 0;
    cursor: pointer;
`;

export const DropdownOptions = styled.div`
    opacity: 1;
    position: absolute;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #34334e;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
    padding: 1rem;
    border-top-right-radius: 0;
    z-index: 999;
    top: 45px;
    right: 30px;

    button {
        display: flex;
        padding: 0.5em;
        margin: 4px;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.29px;
        color: #ffffff;
        background: transparent;
        border: 0;
        transition: all 100ms cubic-bezier(0.075, 0.82, 0.165, 1);
        transform: scale(1.05);
        opacity: 1;
        text-transform: uppercase;
        img {
            filter: grayscale(1);

            &.emphasis {
                filter: brightness(4.5);
            }
        }

        &:hover {
            transform: scale(1.05);
            color: #fe8d62;
            img {
                transform: scale(1.05);
                filter: none;
            }
        }
        &:active {
            transform: scale(1);
            img {
                &:active {
                    transform: scale(1);
                }
            }
        }
    }

`;
