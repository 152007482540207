/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import screenfull from 'screenfull';
import { useIdle } from '@mantine/hooks';
import { Container, VideoWallVideo, GridAreaContainer } from './styles';
import paths from '../../../utils/paths';
import useSize from '../../../hooks/useSize';
import ArrowDownIcon from '../../../assets/arrow-down-icon.svg';
import VideoCard from '../VideoCard';
import { muteParticipant, unmuteParticipant } from '../../../store/modules/rooms/actions';

function area({ height, width }) {
    return height * width;
}

function calculateWidth(containerWidth, containerHeight, numTiles, aspectRatio) {
    const containerArea = containerWidth * containerHeight;

    let optimalWidth = 0;
    let optimalCoverage = 0;

    const container = document.getElementById('video-wall');
    const containerWidthBounds = container.offsetWidth;
    const containerHeighBounds = container.offsetHeight - (container.offsetHeight * 20) / 100;

    for (let numRows = 1; numRows <= (numTiles); numRows += 1) {
        const numCols = Math.ceil(numTiles / numRows);
        const maxHeight = Math.trunc(containerHeighBounds / numRows);
        const maxWidth = Math.trunc(containerWidthBounds / numCols);

        // limited by width
        const rectCriteriaWidth = {
            width: maxWidth,
            height: Math.trunc(maxWidth / aspectRatio),
        };
        if (rectCriteriaWidth.height > maxHeight) {
            rectCriteriaWidth.height = 0;
        }

        // limited by height
        const rectCriteriaHeight = {
            width: Math.trunc(maxHeight * aspectRatio),
            height: maxHeight,
        };
        if (rectCriteriaHeight.width > maxWidth) {
            rectCriteriaHeight.width = 0;
        }

        const bestCandidate = area(rectCriteriaWidth) > area(rectCriteriaHeight) ? rectCriteriaWidth : rectCriteriaHeight;
        const bestCandidateArea = area(bestCandidate);

        const coverage = (bestCandidateArea * numTiles) / containerArea;
        if (coverage > optimalCoverage) {
            optimalCoverage = coverage;
            optimalWidth = bestCandidate.width;
        }
    }
    return optimalWidth;
}

function VideoCardList({
    videoWallFullScreenElementRef,
    numberOfTiles = 1,
    videosList = [],
    VideoWallSettingsComponent = null,
    handleNextPage,
    handlePreviewsPage,
    gridSize,
    currentPage,
    pages,
}) {
    const [showControls, setShowControls] = useState(false);
    const [focusedControl, setFocusedControl] = useState(false);
    const [videoWidth, setVideoWidth] = useState(0);

    const history = useHistory();
    const dispatch = useDispatch();
    const { width, height } = useSize();
    const { id: roomId } = useParams();
    const idleStatus = useIdle(2000);

    const isNextButtonDisabled = currentPage === pages || pages === 0;
    const isPreviewsButtonDisabled = currentPage === 1;
    const showControlsInFullScreen = showControls && screenfull.isFullscreen;

    useEffect(() => {
        setShowControls(!idleStatus || focusedControl);
    }, [idleStatus, focusedControl]);

    function handleFocusControls() {
        setFocusedControl(true);
    }

    function handleUnfocusControls() {
        setFocusedControl(false);
    }

    function handleGotoGuestPresenterView(guestId, guestName) {
        const currentPage = 'video_wall';
        history.push(`${paths.GUEST_PRESENTER_VIEW}/${roomId}/${guestId}?guestName=${encodeURIComponent(guestName)}&prevPage=${currentPage}`);
    }

    useEffect(() => {
        setVideoWidth(calculateWidth(width - 200, height, numberOfTiles, (16 / 9)));
    }, [videoWidth, height, numberOfTiles, width]);

    const handleMuted = (userEmail) => {
        dispatch(muteParticipant(roomId, userEmail));
    };

    const handleUnmuted = (userEmail) => {
        dispatch(unmuteParticipant(roomId, userEmail));
    };

    return (
        <Container id="video-wall" ref={videoWallFullScreenElementRef} isFullscreen={screenfull.isFullscreen}>
            {screenfull.isFullscreen && showControls && (
                <div
                    onMouseEnter={handleFocusControls}
                    onMouseLeave={handleUnfocusControls}
                    className="video-wall-full-screen-controls"
                >{VideoWallSettingsComponent()}
                </div>
            )}

            <div
                className={`navigation-button left ${showControlsInFullScreen ? 'showControls' : ''}`}
                onMouseEnter={handleFocusControls}
                onMouseLeave={handleUnfocusControls}
            >
                <button
                    type="button"
                    id="videoWallLeftButton"
                    onClick={handlePreviewsPage}
                    disabled={isPreviewsButtonDisabled}
                >
                    <img src={ArrowDownIcon} alt="arrow icon" />
                </button>
            </div>
            <div
                className={`navigation-button right ${showControlsInFullScreen ? 'showControls' : ''}`}
                onMouseEnter={handleFocusControls}
                onMouseLeave={handleUnfocusControls}
            >
                <button
                    type="button"
                    id="videoWallRightButton"
                    onClick={handleNextPage}
                    disabled={isNextButtonDisabled}

                >
                    <img src={ArrowDownIcon} alt="arrow icon" />
                </button>
            </div>
            <GridAreaContainer gridSize={gridSize}>
                {
                    videosList.map((user) => (
                        <VideoWallVideo
                            key={user.id}
                            className="video-wall-video"
                            width={videoWidth}
                        >
                            <VideoCard
                                key={user.id}
                                user={user}
                                stream={user.stream}
                                width={videoWidth}
                                handleMuted={handleMuted}
                                handleUnmuted={handleUnmuted}
                                handleGotoGuestPresenterView={handleGotoGuestPresenterView}
                                isPaused={user.isPaused}
                                isBlocked={user.blocked}
                            />
                            <div className="video-wall-video-description"> <h5> {user.name} </h5> </div>
                        </VideoWallVideo>
                    ))
                }
            </GridAreaContainer>

        </Container>
    );
}

export default React.memo(VideoCardList);
