import { handleInputMaskAndPlaceholder } from '@src/utils/functions';
import React, { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomFormInputContainer } from './styles';

const CustomDateFormInput = ({
    labelTitle, helpText, isPortal, dateValue, setDateValue, user, id,
}) => {
    const { t } = useTranslation();

    const DatePicker = useMemo(() => React.lazy(() => import('../DatePicker')), []);

    return (
        <CustomFormInputContainer>
            <p>{labelTitle || 'Label'} <span>{helpText}</span></p>
            <Suspense fallback={<div>{t('LOADING')}</div>}>
                <div>
                    <DatePicker
                        id={id || 'start-finish'}
                        withPortal={isPortal}
                        setVariable={setDateValue}
                        valueVariable={dateValue}
                        dateMask={handleInputMaskAndPlaceholder(user?.language).maskWithTime}
                        placeholder={handleInputMaskAndPlaceholder(user?.language).placeholderWithTime}
                        timeLabel={`${t('HOURS')}:`}
                        timeMask="HH:mm"
                        showTimeInput
                        minDate={new Date()}
                    />
                </div>
            </Suspense>
        </CustomFormInputContainer>
    );
};

export default CustomDateFormInput;
