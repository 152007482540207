import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { MdError } from 'react-icons/md';
import EditIcon from '../../assets/pencil-line.svg';

import { Container, Content, FileInfo, Preview } from './styles';

function FileList({ uploadedFile, clear, url }) {
    const { t } = useTranslation();

    function handleEditCoverImg(event) {
        event.preventDefault();
        clear();
    }
    return (
        <Container>
            <Content className="file-list-content">
                <Preview src={url || uploadedFile.preview} />
                <div className="upload-info">
                    {!uploadedFile.uploaded && !uploadedFile.error && (
                    <CircularProgressbar
                        styles={{
                            root: { width: 24 },
                            path: { stroke: '#7159c1' },
                        }}
                        strokeWidth={10}
                        value={uploadedFile.progress}
                    />
                    )}

                    {uploadedFile.error && <MdError size={24} color="#e57878" />}
                </div>
            </Content>
            <FileInfo>
                <div className="--cover-footer">

                    <button
                        className="footer-button"
                        type="submit"
                        onClick={e => handleEditCoverImg(e)}
                    >
                        <img alt="Edit Icon" src={EditIcon} />
                        {t('EDIT_VIDEO_COVER')}
                    </button>
                </div>
            </FileInfo>
        </Container>
    );
}

export default FileList;
