import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonConfirm, ModalContent } from '../style';
import useRooms from '../../../hooks/useRooms';

export default function EditUserModalContent({ editUserAction, userEdited, setUserEdited }) {
    const { loaderInButton } = useSelector(state => state.users);
    const { t } = useTranslation();
    const { selectedChannel } = useRooms();

    return (
        <ModalContent>
            <div className="modalEditContent">
                <span>{t('PERMISSION_LEVEL')}</span>
                <select
                    value={userEdited.permission}
                    onChange={e => setUserEdited({ ...userEdited, permission: e.target.value })}
                >
                    <option value="user">
                        {t('PARTICIPANT')}
                    </option>
                    <option value="organizer">
                        {t('ORGANIZER')}
                    </option>
                    <option value="monitor">
                        {t('MONITOR')}
                    </option>
                </select>
                <ButtonConfirm
                    type="button"
                    onClick={() => editUserAction(userEdited, selectedChannel.id)}
                >
                    {t('SAVE_USER')}
                    {loaderInButton
                        && (
                            <div className="loaderButton">
                                <CircularProgress size={25} />
                            </div>
                        )}
                </ButtonConfirm>
            </div>
        </ModalContent>
    );
}
