import styled from 'styled-components';

export const ButtonDefaultThemeContainer = styled.button`
    display:flex;
    justify-content:center;        
    max-height: 40px;
    border: 0;
    flex: 1;
    color:#fff;
    background: linear-gradient(90deg, rgba(255,66, 141,1) 0%, rgba(255,174,70,1) 100%);
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: 0.21px;
    border-radius: 8px;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    width: 100%;

    &.cancel {
        background: transparent;
        width: 387px;                        
        border: solid 1px #ff5b7d;
        margin-top: 15px;
    } 

    &:hover {
        box-shadow: 0px 0px 10px -4px #000000;
        transform: scale(1.03);
    }

    &:disabled {
        opacity: 0.3;
        transform: none;
    }
`;
