import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  height:calc(100% - 11%);
  width: 100%;
  position: relative;
  flex-flow: column nowrap;
  padding: 1rem;
  overflow: auto;

  .--warn-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;

        h5 {
            font-size: 18px;
            color: #fff;
        }

        button {
            border: none;
            color: #FFF;
            background: linear-gradient(90deg,#FFAC47 0%, #FF438D 100%);
            width: 300px;
            height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-weight: 600;
            border-radius: 8px;  
            font-weight: bold;
            text-transform: uppercase; 
            opacity: 0.8;
            transition: all 0.1s ease-in-out;

            &:hover {
                box-shadow: 0px 0px 10px -4px #000000;
                opacity: 1;
                transform: scale(1.02);
            }
        }

    }

  @media(max-width: 600px) and (orientation: landscape) {
    height:calc(100% - 18%);
  }

  @media(max-width: 990px) and (orientation: landscape) {
    height:calc(100% - 18%);
  }
`;

export const PageHeader = styled.div`  
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        font-size: clamp(18px, 0.5em + 1vw, 1.9em); 
        color:white;
    }
`;

export const RoomsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
`;

export const Section = styled.div`
    display: flex;
    width: 100%;
    align-self: stretch;
    flex-wrap: wrap;
    min-height: 80px;
    
    h2 {
        font-size: 24px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.27px;
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
    }
    
    @media(max-width: 600px) {
      flex-direction: column;
      min-height: 150px;
    }
`;

export const LeftContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
`;

export const Button = styled.button`
   border-width:0px;
   display:flex;
   align-self:flex-end;
   height: 47px;    
   min-width: 186px;
   border-radius: 12px;
   background-color: #212032;
   color: #ffffff;
   font-weight: bold;
   font-size: clamp(12px, 0.4em 1vw, 1.5em);
   align-items: center;
   padding: 0 10px 0 34px;
   text-transform: uppercase;
   transition: all 0.1s ease-in-out;
   position: relative;
   img {
       width: 30px;
   }
   small {        
       position: absolute;
       margin-left: -15px;        
       display: flex;
       justify-content: center;
       align-items: center;
       height: 34px;
       width: 34px;
       left: 0;
       top: 7px;
       border-radius: 7px;
       background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
       background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
   }
   &:hover {
       box-shadow: 0px 0px 10px -4px #000000;
       transform: scale(1.02);
   }

`;

export const ModalButton = styled.button`
    border: none;
    color: #FFF;
    background: linear-gradient(90deg,#FF438D 0%,#FFAC47 100%);
    width: 191px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-weight: 600;
    margin: auto;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    &:hover {
        box-shadow: 0px 0px 10px -4px #000000;
        transform: scale(1.03);
    } 

    &:disabled {
        opacity: 0.2;
        transform: none;
    }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 20px 0;
  width: 100%;
  h2 {
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.16px;
    text-align: center;
    color: #fff;
    padding-bottom: 10px;
    text-transform: uppercase;
  }

  form {
      width: 100%;
  }

  textarea {
      color: #fff;
  }

  legend {
    margin-top: 15px;
    color: #fff;
    margin-bottom: 10px;
  }

  .MuiFormControl-root {
      width: 100%;
  }

  .MuiFilledInput-multiline {
        padding: 27px 12px 10px;
        background: #24243e;
  }

  .MuiIconButton-label {
        color: #ff6876;
    }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
`;

export const OfferList = styled.ul`
  padding: 50px 0 30px;
  display: flex;
  align-self: stretch;
  flex-wrap: wrap;
  margin: 0 -10px;
  
  @media(max-width: 500px) {
    flex-direction: column;
    margin: 0;
  }
  
  button {
      border: none;
      background-color: transparent;
  }

  li {
    margin: 10px;
    border-radius: 8px;
    background-color: #212436;
    color: #FFF;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
    min-height: 180px;
    min-width: 255px;
    text-align: left;
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0px 0px 10px -4px #000000;
    }  

    h2 {
      font-weight: 900;
      display: flex;
      margin: 15px 0;
    }

    p {
      font-size: 18;
      font-weight: 600;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 33.3%;
  flex: 1;
  height: 80px;

  &:last-child {
    justify-content: flex-end;
  }

  &:nth-child(1) {
    justify-content: flex-start;
    p {
        margin-left: 20px;
        color: #FFF;
        font-size: 18px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.21px;
        text-align: center;
        
        span {
            font-weight: 600;
        }
    }
  }

  @media(max-width: 600px) {
    height: 50px;
    &:last-child {
      justify-content: center;
    }

    &:nth-child(1) {
      justify-content: center;
    }
  }
`;
