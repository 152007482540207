import styled from 'styled-components';
import Dropdown from 'react-dropdown';

export const SelectInputFieldContainer = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    
    p {
        top: 0;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
        margin-left: 15px;
        text-transform: uppercase;
    }

    span {
        color: #ff9869;
        font-size: 8px;
    }


    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
`;

export const CustomDropdown = styled(Dropdown)`
    .Dropdown-control {
        width: 100%;
        height: 35px;
        background-color: ${({ color }) => (color || '#212032')} ;
        border: 0;
        outline: 0;
        border-radius: 6px;
        margin-top: 4px;
        font-size: 12px;
        font-weight: bold;
        color: #fff;       
    }

    .Dropdown-menu {
        background-color: #212032;
        border: none;          
    }

    .Dropdown-option {
        color: #fff;
        font-weight: bold;  
        &:hover {
            background-color: #34344c;
        }
    }

    .Dropdown-option.is-selected {
        background-color: #34344c;
        color: #fff;
    }
    
    .Dropdown-placeholder {
        color: ${({ placeHolderColor }) => (placeHolderColor || '#fff')} ;
        &.is-selected {
            color: #fff;
        }
    }
`;
