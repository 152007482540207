import styled from 'styled-components';

export const ButtonHeaderContainer = styled.div`
    &:first-child {
        margin: 0 1.2rem;
    }
    button {
        border-width: 0px;
        display: flex;
        align-self: flex-end;
        height: 47px;
        border-radius: 12px;
        background-color: #212436 !important;
        color: #ffffff;
        font-weight: bold;
        font-size: clamp(12px, 0.4em + 1vw, 1.2em);
        align-items: center;
        padding: 0 15px;
        width: 160px !important;
        text-transform: uppercase;
        transition: all 0.1s ease-in-out;
        position: relative;
        &:hover {
            box-shadow: 0px 0px 10px -4px #000000;
        }
        &:disabled {
            opacity: 0.4;
        }
    }

    small {
        position: absolute;
        left: -5px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        width: 34px;
        border-radius: 7px;
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
        img {
            width: 100%;
            padding: 4px;
        }
    }

    span {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
    }


`;
