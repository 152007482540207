/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';

import ChatSocket from '../../services/ChatSocket';
import {
    setMessages,
    changeTeamChat,
    setTeamInformation,
    setChatEnableRoom,
    setOfferEnableRoom,
    fetchRoom,
} from '../../store/modules/rooms/actions';
import history from '../../services/history';

// configs
const MESSAGE_LIMIT_PER_GENERAL_ROOM = 100;
// const MESSAGE_LIMIT_PER_QUESTIONS_ROOM = 100; Não possui limite de msgs
const MESSAGE_LIMIT_PER_INSTRUCTORS_ROOM = 100;
const MESSAGE_LIMIT_PER_TEAM_ROOM = 100;

const userColorIndex = { count: 0 };

class ChatEvents extends React.Component {
    constructor(props) {
        super(props);
        this.message = ChatSocket.addMessageListener(this.messageHandler);
        this.disconnect = ChatSocket.addDisconnectListener(this.disconnectHandler);
        this.close = ChatSocket.addCloseListener(this.closeConnectionHandler);
    }

    commandHandler = (msg) => {
        switch (msg.action) {
            case 'reload':
                window.location.reload(true);
                break;

            default:
                break;
        }
    }

    messageHandler = (message) => {
        if (Object.prototype.hasOwnProperty.call(message, 'eventType')) {
            switch (message.eventType) {
                case 'teamEnable':
                    this.props.changeTeamChat(message.payload);
                    break;
                case 'teamChange':
                    this.props.setTeamInformation(message.payload);
                    break;
                case 'chatEnable':
                    this.props.setChatEnableRoom(message.payload);
                    break;
                case 'offerEnable':
                    this.props.setOfferEnableRoom(message.payload.enabled);
                    break;
                case 'pin_message':
                case 'unpin_message':
                case 'att_offer':
                    this.props.fetchRoom(message.payload.roomId);
                    break;
                default:
                    console.log('msg event type:', message);
                    break;
            }
            return;
        }
        let initialRoomMessages = Object.assign([], this.props.roomMessages);
        let initialQuestionMessages = Object.assign([], this.props.questionMessages);
        let initialInstructorMessages = Object.assign([], this.props.instructorRoomMessages);
        let initialTeamMessages = Object.assign([], this.props.teamMessages);

        if (message.type === 'error') {
            this.errorMessageHandler(message);
            return;
        }

        if (message.type === 'command') {
            this.commandHandler(message);
            return;
        }

        if (!this.props.selectedRoom) {
            return;
        }

        const { messageSender } = message;

        if (userColorIndex[messageSender] === undefined) {
            userColorIndex[messageSender] = userColorIndex.count;
            userColorIndex.count += 1;
        }

        const toInsert = { ...message, colorIndex: userColorIndex[messageSender] };

        switch (message.type) {
            case 'roomMessage':
                initialRoomMessages = [toInsert, ...initialRoomMessages];
                if (initialRoomMessages.length > MESSAGE_LIMIT_PER_GENERAL_ROOM) {
                    initialRoomMessages.pop();
                }
                break;

            case 'questionMessage':
                initialQuestionMessages = [toInsert, ...initialQuestionMessages];
                break;

            case 'instructorRoomMessage':
                initialInstructorMessages = [toInsert, ...initialInstructorMessages];
                if (initialInstructorMessages.length > MESSAGE_LIMIT_PER_INSTRUCTORS_ROOM) {
                    initialInstructorMessages.pop();
                }
                break;

            case 'teamMessage':
                initialTeamMessages = [toInsert, ...initialTeamMessages];
                if (initialTeamMessages.length > MESSAGE_LIMIT_PER_TEAM_ROOM) {
                    initialTeamMessages.pop();
                }
                break;

            default:
                break;
        }

        this.props.setMessages(
            initialRoomMessages, initialQuestionMessages,
            initialInstructorMessages, initialTeamMessages,
        );
    }

    errorMessageHandler(message) {
        switch (message.error_code) {
            case 'unauthorized':
                history.push('/forcedLogout');
                break;
            default:
                console.log('Not implemented for error code', message.error_code);
        }
    }

    disconnectHandler() {
        console.log('desconectado do socket, tentativa de reconexão será feita');
    }

    closeConnectionHandler(fromLogout = false) {
        console.log('Desistindo de conexão com socket');
        if (fromLogout) return;
        history.push(history.location.pathname === '/forcedLogout' ? '/forcedLogout' : '/networkFailed');
    }

    render() {
        return null;
    }
}

const mapStateToProps = ({ room }) => ({
    selectedRoom: room.selectedRoom || {},
    roomMessages: room.roomMessages || [],
    questionMessages: room.questionMessages || [],
    instructorRoomMessages: room.instructorRoomMessages || [],
    teamMessages: room.teamMessages || [],
});

export default connect(mapStateToProps, {
    setChatEnableRoom, setMessages, changeTeamChat, setTeamInformation, setOfferEnableRoom, fetchRoom,
})(ChatEvents);
