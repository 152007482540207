import styled, { css } from 'styled-components';

export const CourseLiveUploadContainer = styled.div`
    width: 356px;
    background-color: #272637;
    border-radius: 10px;
`;

export const CourseLiveUploadContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 18px 28px;
    display: flex;
    flex-direction: column;
`;

export const UploadVideoArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;

    .liveVideoDownloadButton {
        margin: 0;
        margin-top: 20px;
    }
`;

export const DropzoneCoverArea = styled.div`

    .file-list-content {
        margin-top: 0px;
    }
    .--cover-footer {
                background-color: #212032;
    }

    ${({ hasThumb }) => !hasThumb && css`
        background-color: rgba(33, 32, 50, 0.42);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid #212032;
        border-style: dashed;
        border-radius: 5px;
        height: 300px ;
        overflow: hidden;

        h4 {
            display: none   ;
        }

        & > div {
            width: 100%;
            height: 100%;
        }

        .file-list-content {
            margin-top: 0px;
        }

        .dropzone {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            
        }
        .--cover-footer {
            display: none;
        }
    `}

`;

export const UploadVideoInfo = styled.footer`
    background-color: #212032;
    height: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    border-radius: 5px;

    p {
        color: #ff8e63;
        font-size: 12px;
        font-weight: bold;
    }

    span {
        color: #fff;
        font-size: 10px;
        font-weight: bold;
    }
`;

export const ChatToggleArea = styled.div`
    width: 100%;
    margin: 20px 0;
`;

export const ChatToggleContent = styled.div`
    width: 100%;
    background-color: #212032;
    min-height: 49px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;

    p {
        color: #fff;
        font-weight: bold;
    }

    strong {
        color: ${({ createChatEnabled }) => (createChatEnabled ? '#31a6a1' : '#42426d')} ;
    }
`;

export const ChatToggleItem = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
`;

export const BroadcastInfoArea = styled.div`
    margin-top: 16px;
    width: 100%;
`;

export const DownloadVideoButtonArea = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
        height: 40px;

        .liveVideoDownloadButton {
            margin-top: 0px;
        }
    }
`;

export const MessageLoadingLink = styled.p`
    color: #ff9869;
    font-weight: bold;
    font-size: 16px;
`;

export const ContainerSpinner = styled.div`
    position: flex;
    width: 100%;
    height: 100%;
    min-height:  200px;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiCircularProgress-colorPrimary {
        color: #ff617a;
    }
`;
