import styled from 'styled-components';

export const DropdownContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    .left {
        left: 0;
    }

    .right {
        right: 0;
    }

    .top {
        top: 100%;
    }

    .bottom {
        bottom: 100%;
        margin-bottom: 10px;
    }
    height: 100%;
    border-radius: 3px;
    padding: 8px;
    position: relative;
`;

export const DropdownButtonWrapper = styled.div`
    outline: none;
    cursor: pointer;
    
    .dropdown-component {
        display: flex;
        justify-content: center;
    }
`;

export const SectionContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    img {
        transition: all 0.1s ease-in-out;
        width: 12px;
    }

    small {
        font-size: clamp(12px, 0.2em + 1vw, 1em);
        color: #fff;
        font-weight: bold;
        margin: 0 .5rem 0 0;
    }

    p {
        font-size: clamp(12px, 0.2em + 1vw, 1em);
        font-weight: bold;
        margin: 0 .5rem;
        color: #fff;
        text-transform: uppercase;
        &.emphasis {
            margin-left: 4px;
            color: #ff8f63;
            max-width: 25rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .--open {
        transform: rotate(180deg);
    }
`;

export const DropdownList = styled.div`
    background: #232233;
    display: block;
    z-index: 99;
    position: absolute;
    min-width: 100%;
    box-shadow: 0px 0px 10px -6px #000000;
    max-height: 50vh;
    overflow-y: scroll;
    margin-top: 2px;

    .--search-wrapper {
        position: sticky;
        top: 0;
        background-color: #232233;
        z-index: 5;
        button {
            position: absolute;
            right: 15px;
            bottom: 14px;
            background: transparent;
            border: none;
            margin: 0;
            padding: 1px;
            img {
                width: 12px;
            }
        }
    }

    .--search {
        border-radius: 5px;
        height: 30px;
        width: 100%;
        background-color: #212032;
        border: 1px solid #b8b8ba73;
        padding: 0 20px 0 5px;
        color: #d5d5d5d5;
        font-weight: bold;
        font-size: 12px;
    }

    li {
        list-style: none;
        padding: 10px;
        color: #d5d5d5d5;
        font-weight: bold;
        position: relative;

        &:not(:last-child)::after {
            content: "";
            height: 1px;
            position: absolute;
            bottom: 0;
            background-color: #302f3d;
            width: 100%;
            left: 0;
        }
        &:first-child {
            margin-top: 0;
            padding: 10px 10px 10px 10px;
        }
        &:last-child {
            margin-bottom: 5px;
        }
        &:hover {
            cursor: pointer;
            color: #ff8f63;
        }
    }
`;
