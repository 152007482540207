import { useSelector, shallowEqual } from 'react-redux';

function authSelector({ login }) {
    return {
        user: login.user,
        loading: login.loading,
        isOrganizer: login.user?.organizer,
        onBoarding: login.onBoarding,
    };
}

export default function useAuth() {
    const {
        user,
        loading,
        isOrganizer,
        onBoarding,
    } = useSelector(authSelector, shallowEqual);
    return {
        user,
        loading,
        isOrganizer,
        onBoarding,
    };
}
