import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ToggleSwitch from '@src/components/ToggleSwitch';
import PlusIcon from '@src/assets/plus-icon.svg';
import Modal from '@src/components/Modal';
import Loading from '@src/components/Loading';
import { getOffers, setOffersStatus, putOffer } from '@src/store/modules/offers/actions';
import useOffers from '@src/hooks/useOffers';
import NoContent from '@src/components/NoContent';
import ModalConfirm from '@src/components/ModalConfirm';
import { useBreadcrumbs } from '@src/hooks/useBreadcrumbs';
import Notify from '@src/utils/notification';
import useQuery from '@src/hooks/useQuery';
import useRooms from '@src/hooks/useRooms';
import { validURL } from '@src/utils/functions';
import paths from '@src/utils/paths';
import { useFetchEditRoom } from '@src/hooks/useFetchEditRoom';
import {
    Container,
    Section,
    Content,
    Button,
    ModalButton,
    ModalContainer,
    OfferList,
    Column,
} from './styles';

function Item({ offer, clickHandler }) {
    const { t } = useTranslation();

    return (
        <button type="button" onClick={clickHandler}>
            <li>
                <h2>{t('OFFERS', { context: 'TEMPLATEURL' })}</h2>
                <p>{offer.saleBaseUrl}</p>
                <h2>{t('OFFERS', { context: 'TYPEID' })}</h2>
                <p>{offer.useSalesForceId
                    ? t('OFFERS', { context: 'SALESFORCEID' }) : t('EMAIL')}
                </p>
            </li>
        </button>
    );
}

function ModalContent({
    putOfferHandler,
    offer,
    loading,
}) {
    const urlRef = useRef(null);
    const [identifierValue, setIdentifierValue] = useState('email');
    const { t } = useTranslation();
    const [isValidHttpUrl, setIsValidHttpUrl] = useState(false);

    useEffect(() => {
        if (offer.saleBaseUrl && urlRef.current) {
            urlRef.current.value = offer.saleBaseUrl;
            if (offer.useSalesForceId) {
                setIdentifierValue('salesforceId');
            }
        }
    }, [offer, urlRef]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const useSalesforce = identifierValue === 'salesforceId';
        if (urlRef.current) {
            putOfferHandler(urlRef.current.value, useSalesforce);
        }
    };

    function handleChangeUrl(e) {
        setIsValidHttpUrl(validURL(e.target.value));
        urlRef.current.value = e.target.value;
    }

    return (
        <ModalContainer>
            { loading
                ? <Loading />
                : (
                    <>
                        <form onSubmit={handleSubmit}>
                            <p>{t('OFFERS', { context: 'BASEURL' })}</p>
                            <TextField
                                id="outlined-multiline-static"
                                inputRef={urlRef}
                                multiline
                                onChange={handleChangeUrl}
                                required
                                placeholder="https://example.com/<id>"
                                rows={2}
                                variant="filled"
                                color="secondary"
                            />
                            <FormLabel component="legend">{t('OFFERS', { context: 'SELECTOR' })}</FormLabel>
                            <RadioGroup
                                required
                                aria-label={t('OFFERS', { context: 'SELECTOR' })}
                                name="identifier"
                                value={identifierValue}
                                onChange={e => setIdentifierValue(e.target.value)}
                            >
                                <FormControlLabel value="salesforceId" control={<Radio />} label="Salesforce ID" />
                                <FormControlLabel value="email" control={<Radio />} label={t('EMAIL')} />
                            </RadioGroup>
                            <ModalButton disabled={!isValidHttpUrl}>
                                {offer.saleBaseUrl
                                    ? t('OFFERS', { context: 'EDITOFFER' })
                                    : t('OFFERS', { context: 'CREATEOFFER' })}
                            </ModalButton>
                        </form>

                    </>
                )}
        </ModalContainer>
    );
}

function QRCodeLoadingSkeleton() {
    return (
        <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
            <div style={{ marginTop: 40 }}>
                <Skeleton width={288} height={180} count={1} />
            </div>
        </SkeletonTheme>
    );
}

export default function Offers() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
    const [toggleEnable, setToggleEnable] = useState(false);
    const [id, setId] = useState('');
    const { selectedEditRoom } = useRooms();
    const { offers, loading, isOffersEnabled } = useOffers();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { setItems } = useBreadcrumbs();
    const { id: roomId } = useParams();
    const query = useQuery();

    useFetchEditRoom(roomId);

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t('ORGANIZER_DASHBOARD') },
            { to: paths.OFFERS, label: t('OFFERS') },
            { to: `${paths.OFFERS}/${roomId}/?roomName=${selectedEditRoom?.name || ''}`, label: selectedEditRoom?.name || '' },
        ];
        setItems(breadcrumbsItems);
    }, [query, roomId, setItems, t, selectedEditRoom]);

    useEffect(() => {
        function getRoomId() {
            const path = history.location.pathname;
            return path.split('/')[2];
        }

        const path = getRoomId();
        setId(path);
    }, [setId, history]);

    useEffect(() => {
        if (id !== '') {
            dispatch(getOffers(id));
        }
        // eslint-disable-next-line
    }, [id])

    useEffect(() => {
        setToggleEnable(isOffersEnabled);
        // eslint-disable-next-line
    }, [offers])

    function handleToggle() {
        if ((id !== '') && (offers.saleBaseUrl)) {
            setToggleEnable(toggleEnable => !toggleEnable);
            dispatch(setOffersStatus({ status: !toggleEnable, roomId: id }));
        }
    }

    function handleConfirm() {
        if (!offers?.saleBaseUrl) {
            Notify.warn(t('OFFERS', { context: 'NOOFFERS' }));
            return;
        }
        if (!toggleEnable) {
            setIsModalConfirmOpen(true);
        } else {
            handleToggle();
        }
    }

    function handleEnableOffer() {
        setIsModalConfirmOpen(false);
        handleToggle();
    }

    function handleModal() {
        setIsModalOpen(!isModalOpen);
    }

    function handleModalConfirm() {
        setIsModalConfirmOpen(!isModalConfirmOpen);
    }

    function putOfferHandler(templateUrl, useSalesforce) {
        if (id !== '') {
            dispatch(putOffer({
                roomId: id,
                templateUrl,
                useSalesforce,
            }));
            handleModal();
        }
    }

    return (
        <>

            <Container>
                <Modal isOpen={isModalOpen} handleModal={handleModal} title={t('OFFERS', { context: 'CREATEOFFER' })}>
                    <ModalContent
                        offer={offers}
                        putOfferHandler={putOfferHandler}
                        loading={loading}
                    />
                </Modal>

                <Section>
                    <Column>
                        <ToggleSwitch
                            isOn={toggleEnable}
                            handleToggle={handleConfirm}
                            disabled={loading}
                        />
                        <ModalConfirm
                            visible={isModalConfirmOpen}
                            title={t('OFFERS')}
                            description={t('OFFERS', { context: 'ENABLECONFIRM' })}
                            handleModal={handleModalConfirm}
                            handleConfirm={handleEnableOffer}
                            handleCancel={handleModalConfirm}
                        />
                        <p> {t('OFFERS')}: <span>{toggleEnable ? t('ACTIVE') : t('INACTIVE')}</span></p>
                    </Column>
                    <Column>
                        <h2>{t('OFFERS')}</h2>
                    </Column>
                    <Column>
                        <Button onClick={handleModal}>
                            <small>
                                <img src={PlusIcon} alt={t('OFFERS', { context: 'CREATEOFFER' })} />
                            </small>
                            <span>{offers && offers.saleBaseUrl
                                ? t('OFFERS', { context: 'EDITOFFER' })
                                : t('OFFERS', { context: 'CREATEOFFER' })}
                            </span>
                        </Button>
                    </Column>
                </Section>
                <Content>
                    {
                        loading && <QRCodeLoadingSkeleton />
                    }
                    {
                        !loading && offers?.saleBaseUrl && (
                            <OfferList>
                                <Item offer={offers} clickHandler={handleModal} />
                            </OfferList>
                        )
                    }
                    {
                        !loading && !offers?.saleBaseUrl && (
                            <NoContent
                                title={t('OPS')}
                                text={t('OFFERS', { context: 'NOOFFERS' })}
                                button={{
                                    text: t('OFFERS', { context: 'CREATEOFFER' }),
                                    action: handleModal,
                                }}
                            />
                        )
                    }

                </Content>
            </Container>
        </>
    );
}
