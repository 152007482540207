import React, { useState, useEffect, useCallback } from 'react';
import 'react-dropdown/style.css';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PlusIcon from '../../assets/plus-icon.svg';
import Modal from '../../components/Modal';
import NoContent from '../../components/NoContent';
import VideoCardSkeleton from '../../components/VideoCardSkeleton';
import { Container, CardList } from './styles';
import ModalChannelContent from './ModalChannelContent';
import ModalChannelType from './ModalChannelType';
import RoomsList from './List';
import { clearSelected, getChannelRooms } from '../../store/modules/rooms/actions';
import useRooms from '../../hooks/useRooms';
import { VIDEO_TYPES } from '../../utils/constants';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import paths from '../../utils/paths';

function Channels() {
    const [isModalChannelContentOpen, setIsModalChannelContentOpen] = useState(false);
    const [isModalChannelTypeOpen, setIsModalChannelTypeOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [typeVideo, setTypeVideo] = useState(VIDEO_TYPES.LIVE);
    const {
        selectedEditRoom,
        loading,
        isUploadingInBackground,
        selectedChannel,
        selectedChannelRooms,
    } = useRooms();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { setItems } = useBreadcrumbs();
    const history = useHistory();

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t('ORGANIZER_DASHBOARD') },
            { to: paths.HOME, label: t('CHANNEL_MYVIDEOS') },
        ];
        setItems(breadcrumbsItems);
    }, [setItems, t]);

    const handleVideoType = (isEdit) => {
        setIsEdit(isEdit);
        setIsModalChannelContentOpen(isModalChannelContentOpen => !isModalChannelContentOpen);
        dispatch(clearSelected());
    };

    const handleLiveModal = () => {
        history.push(paths.COURSE_LIVE);
    };

    const handleRecordedModal = () => {
        history.push(paths.COURSE_RECORDED);
    };

    const handleCloseModal = useCallback(() => {
        if (selectedChannel && isEdit) {
            dispatch(getChannelRooms(selectedChannel?.id));
        }
    }, [dispatch, isEdit, selectedChannel]);

    useEffect(() => {
        if (selectedChannel?.id) {
            dispatch(getChannelRooms(selectedChannel?.id));
        }
    }, [dispatch, selectedChannel, handleCloseModal]);

    const warnUser = useCallback((e) => {
        if (isUploadingInBackground?.length > 0) {
            e.preventDefault();
            e.returnValue = '';
        }
    }, [isUploadingInBackground]);

    useEffect(() => {
        window.addEventListener('beforeunload', warnUser);
        return () => window.removeEventListener('beforeunload', warnUser);
    }, [warnUser]);

    return (
        <>
            { loading ? <VideoCardSkeleton />
                : (
                    <Container>
                        <Modal
                            title={t('CONTENT')}
                            featuredTitle={typeVideo === VIDEO_TYPES.RECORDED ? t('RECORDED') : t('LIVE')}
                            isOpen={isModalChannelContentOpen}
                            handleModal={(e) => handleVideoType(e, true)}
                            width={loading ? 410 : ((typeVideo === VIDEO_TYPES.LIVE && isEdit) ? 1000 : 900)}
                        >
                            <ModalChannelContent
                                setIsModalOpen={setIsModalChannelContentOpen}
                                isEditing={isEdit}
                                roomEdit={selectedEditRoom}
                                loading={loading}
                                typeVideo={typeVideo}
                                onClose={handleCloseModal}
                            />
                        </Modal>
                        <Modal
                            isOpen={isModalChannelTypeOpen}
                            handleModal={() => setIsModalChannelTypeOpen(false)}
                            title={t('CONTENT_TYPE_TO_ADD')}
                            width={600}
                        >
                            <ModalChannelType
                                handleLiveModal={handleLiveModal}
                                handleRecordedModal={handleRecordedModal}
                                handleClose={() => setIsModalChannelTypeOpen(false)}
                            />
                        </Modal>

                        <div className="channel-header">
                            <h2>{selectedChannel?.id && `#${selectedChannel?.id} - ${selectedChannel?.name}`}</h2>
                            <button type="button" onClick={() => setIsModalChannelTypeOpen(true)} data-tour="tour__newvideo">
                                <small>
                                    <img alt="plusIcon" src={PlusIcon} />
                                </small>
                                <span>{t('CHANNEL', { context: 'ADDNEWVIDEO' })}</span>
                            </button>
                        </div>
                        {
                            selectedChannelRooms?.length > 0 && (
                            <CardList>
                                <RoomsList
                                    channelId={selectedChannel?.id}
                                    setIsModalOpen={() => handleVideoType(true)}
                                    rooms={selectedChannelRooms}
                                    handleTypeVideo={setTypeVideo}
                                />
                            </CardList>
                            )
                        }
                        {
                            !selectedChannelRooms?.length > 0 && (
                            <NoContent
                                title={t('OPS')}
                                text={t('CHANNEL', { context: 'NOVIDEO' })}
                            />
                            )
                        }
                    </Container>
                )}

        </>
    );
}

export default React.memo(Channels);
