import React from 'react';
import Tz from '@src/assets/timezones.json';
import { CustomDropdown, CustomFormTimezoneDropdownContainer } from './styles';

const CustomFormTimezoneDropdown = ({ labelTitle, helpText, ...props }) => {
    const timezoneOptions = Tz.timezones.map(tz => ({ label: tz.label, value: tz.value }));

    const handleChange = (value) => {
        if (props?.setTimezoneValue) {
            props.setTimezoneValue(value);
        }
    };

    return (
        <CustomFormTimezoneDropdownContainer>
            <p>{labelTitle || 'Label'} <span>{helpText}</span></p>
            <CustomDropdown
                options={timezoneOptions}
                onChange={e => handleChange(e.value)}
                placeholder="Selecione um fuso horário"
                {...props}
            />
        </CustomFormTimezoneDropdownContainer>
    );
};

export default CustomFormTimezoneDropdown;
