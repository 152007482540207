import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getChannelRooms } from '../store/modules/rooms/actions';

export const useFetchChannelsRooms = (selectedChannel, isMenuOpen = false) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedChannel?.id && isMenuOpen) {
            dispatch(getChannelRooms(selectedChannel?.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChannel]);
};
