import React from 'react';

import { Container } from './styles';
import noContent from '../../assets/no-content.svg';
import notFound from '../../assets/search.svg';

const IconType = {
    NO_CONTENT: 'NO_CONTENT',
    NOT_FOUND: 'NOT_FOUND',
};

function NoContent({
    title,
    text,
    type = IconType.NO_CONTENT,
    button = {
        text: '',
        action: null,
    },
    helpButton = {
        text: '',
        action: null,
    },

}) {
    return (
        <Container>
            <img src={type === IconType.NOT_FOUND ? notFound : noContent} alt="No content icon" />
            <h2>{title}</h2>
            <p>{text}</p>
            {button.action && <button type="button" onClick={button.action}>{button.text}</button>}
            {helpButton.action && <button className="--help-button" type="button" onClick={helpButton.action}>{helpButton.text}</button>}
        </Container>
    );
}

export default NoContent;
