import { useSelector, shallowEqual } from 'react-redux';

function teamsSelector({ teams }) {
    return {
        teams: teams.teams || [],
        loading: teams.loading,
        isTeamsEnabled: teams.isTeamsEnabled,
    };
}

export default function useTeams() {
    const {
        teams,
        loading,
        isTeamsEnabled,
    } = useSelector(teamsSelector, shallowEqual);
    return {
        teams,
        loading,
        isTeamsEnabled,
    };
}
