import React from 'react';
import PropTypes from 'prop-types';
import OnboardingHome from '@src/components/Onbordings/onboardingHome';
import useAuth from '@src/hooks/useAuth';
import { Wrapper } from './styles';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import Content from './Content';

export default function DefaultLayout({ children }) {
    const { user } = useAuth();
    return (
        <Wrapper>
            {(user)
                && <OnboardingHome />}
            <Header />
            <Sidebar />
            <Content>
                {children}
            </Content>
        </Wrapper>
    );
}

DefaultLayout.propTypes = { children: PropTypes.element.isRequired };
