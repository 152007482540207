export default {
    // Rooms
    ACCEPT_TERMS_OF_USAGE: 'ACCEPT_TERMS_OF_USAGE',
    GET_ONLINE_MONITORS_REQUEST: 'GET_ONLINE_MONITORS_REQUEST',
    GET_ONLINE_MONITORS_SUCCESS: 'GET_ONLINE_MONITORS_SUCCESS',
    GET_ONLINE_MONITORS_FAIL: 'GET_ONLINE_MONITORS_FAIL',
    MUTED_PARTICIPANT_REQUEST: 'MUTED_PARTICIPANT_REQUEST',
    UNMUTED_PARTICIPANT_REQUEST: 'UNMUTED_PARTICIPANT_REQUEST',
    ROOM_SET_CHAT_USER_TYPE: 'ROOM_SET_CHAT_USER_TYPE',
    ROOM_REPLY_MESSAGE: 'ROOM_REPLY_MESSAGE',
    ROOM_EDIT_SELECT: 'ROOM_EDIT_SELECT',
    ROOM_EDIT_SELECT_LINKS: 'ROOM_EDIT_SELECT_LINKS',
    ROOM_EDIT_SELECT_RECORDED: 'ROOM_EDIT_SELECT_RECORDED',
    ROOM_FAIL: 'ROOM_FAIL',
    ROOM_EDIT_SELECT_SUCCESS: 'ROOM_EDIT_SELECT_SUCCESS',
    ROOM_EDIT_SELECT_SUCCESS_LINKS: 'ROOM_EDIT_SELECT_SUCCESS_LINKS',
    ROOM_CHAT_SET_ENABLE_TEAM: 'ROOM_CHAT_SET_ENABLE_TEAM',
    ROOM_CHAT_SET_TEAM_INFORMATION: 'ROOM_CHAT_SET_TEAM_INFORMATION',
    ROOM_CHAT_ASYNC_SET_CHAT_ENABLE_ROOM: 'ROOM_CHAT_ASYNC_SET_CHAT_ENABLE_ROOM',
    ROOM_CHAT_SET_CHAT_ENABLE_ROOM: 'ROOM_CHAT_SET_CHAT_ENABLE_ROOM',
    ROOM_CREATE: 'ROOM_CREATE',
    ROOM_EDIT: 'ROOM_EDIT',
    ROOM_CLEAR_EDIT_SELECTED: 'ROOM_CLEAR_EDIT_SELECTED',
    ROOM_GOLIVE: 'ROOM_GOLIVE',
    ROOM_ENDLIVE: 'ROOM_ENDLIVE',
    ROOM_IS_LIVE: 'ROOM_IS_LIVE',
    ROOM_ENGAGEMENT: 'ROOM_ENGAGEMENT',
    ROOM_SET_ENGAGEMENT: 'ROOM_SET_ENGAGEMENT',
    ROOM_EVENTLOG: 'ROOM_EVENTLOG',
    ROOM_SET_EVENTLOG: 'ROOM_SET_EVENTLOG',
    ROOM_GRAPH: 'ROOM_GRAPH',
    ROOM_SET_GRAPH: 'ROOM_SET_GRAPH',
    ROOM_GEOLOCALIZATION: 'ROOM_GEOLOCALIZATION',
    ROOM_SET_GEOLOCALIZATION: 'ROOM_SET_GEOLOCALIZATION',
    ROOM_SET_STATUS_ROOM: 'ROOM_SET_STATUS_ROOM',
    ROOM_PIN_MESSAGE: 'ROOM_PIN_MESSAGE',
    ROOM_SET_PIN_MESSAGE: 'ROOM_SET_PIN_MESSAGE',
    ROOM_UNPIN_MESSAGE: 'ROOM_UNPIN_MESSAGE',
    ROOM_SET_CHAT_ENABLED: 'ROOM_SET_CHAT_ENABLED',
    ROOM_SET_VIDEO_UPLOAD: 'ROOM_SET_VIDEO_UPLOAD',
    ROOM_VIDEO_UPLOAD: 'ROOM_VIDEO_UPLOAD',
    ROOM_UPLOAD_VIDEO_UPDATE_PROGRESS: 'ROOM_UPLOAD_VIDEO_UPDATE_PROGRESS',
    ROOM_UPLOAD_VIDEO_STATUS: 'ROOM_UPLOAD_VIDEO_STATUS',
    ROOM_UPLOAD_IN_BACKGROUND: 'ROOM_UPLOAD_IN_BACKGROUND',
    CHANGE_TEAM_CHAT: 'CHANGE_TEAM_CHAT',
    ROOM_SHOW_QRCODE: 'ROOM_SHOW_QRCODE',
    SET_TERM_URL: 'SET_TERM_URL',
    SET_HASEXTRAUSERS: 'SET_HASEXTRAUSERS',
    ROOM_POLL_STATUS: 'ROOM_POLL_STATUS',

    // Teams
    GET_TEAMS_STATUS: 'GET_TEAMS_STATUS',
    SET_TEAMS_STATUS: 'SET_TEAMS_STATUS',
    ASYNC_SET_TEAMS_STATUS: 'ASYNC_SET_TEAMS_STATUS',
    GET_TEAMS: 'GET_TEAMS',
    SET_TEAMS: 'SET_TEAMS',
    ASYNC_CREATE_TEAMS: 'ASYNC_CREATE_TEAMS',
    // Offers
    GET_OFFERS_STATUS: 'GET_OFFERS_STATUS',
    SET_OFFERS_STATUS: 'SET_OFFERS_STATUS',
    SET_OFFERS_STATUS_SOCKET: 'SET_OFFERS_STATUS_SOCKET',
    GET_OFFERS: 'GET_OFFERS',
    GET_OFFER_URL: 'GET_OFFER_URL',
    SET_OFFERS: 'SET_OFFERS',
    SET_OFFER_URL: 'SET_OFFER_URL',
    CREATE_OFFER: 'CREATE_OFFER',
    FINISH_OFFER_REQUEST: 'FINISH_OFFER_REQUEST',
    // Vouchers
    VOUCHER_VERIFY: 'VOUCHER_VERIFY',
    VOUCHER_SET_VERIFICATION: 'VOUCHER_SET_VERIFICATION',
    VOUCHER_REGISTER_USER: 'VOUCHER_REGISTER_USER',
    VOUCHER_VERIFY_EMAIL: 'VOUCHER_VERIFY_EMAIL',
    VOUCHER_RESEND_VERIFY: 'VOUCHER_RESEND_VERIFY',
    VOUCHER_CONFIRM_PASSWORD: 'VOUCHER_CONFIRM_PASSWORD',
    VOUCHER_ASSOCIATE: 'VOUCHER_ASSOCIATE',
    VOUCHER_ASSOCIATE_REGISTERED: 'VOUCHER_ASSOCIATE_REGISTERED',
    VOUCHER_CLEAR_ASSOCIATION: 'VOUCHER_CLEAR_ASSOCIATION',
    VOUCHER_SET_ACTIVATED: 'VOUCHER_SET_ACTIVATED',

    // Users
    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_NEXT_PAGE_REQUEST: 'GET_USERS_NEXT_PAGE_REQUEST',
    USERS_SUCCESS: 'USERS_SUCCESS',
    USERS_PAGINATED_SUCCESS: 'USERS_PAGINATED_SUCCESS',
    GET_CHANNELS_FOR_SIDEBAR: 'GET_CHANNELS_FOR_SIDEBAR',
    SET_CHANNELS_SIDEBAR: 'SET_CHANNELS_SIDEBAR',
    SET_CHANNEL_SELECTED: 'SET_CHANNEL_SELECTED',
    SET_LOADER_STATE: 'SET_LOADER_STATE',
    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_SUCESS: 'DELETE_SUCESS',
    EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
    UPDATE_USER_LIST: 'UPDATE_USER_LIST',
    SET_LOADING: 'SET_LOADING',
    SET_LOADING_NEXT_PAGE: 'SET_LOADING_NEXT_PAGE',
    SET_LOADING_NEXT_PAGE_FAIL: 'SET_LOADING_NEXT_PAGE_FAIL',
    PREV_PAGE: 'PREV_PAGE',
    USERS_SET_ACTIVE_PAGE: 'USERS_SET_ACTIVE_PAGE',
    LAST_EVALUATE_KEY: 'LAST_EVALUATE_KEY',
    CLEAR_USERS: 'CLEAR_USERS',
    USERS_CHANGE_ROWS_PER_PAGE: 'USERS_CHANGE_ROWS_PER_PAGE',
    GET_USER_BY_FILTER: 'GET_USER_BY_FILTER',
    GET_CSV_LIST_REQUEST: 'GET_CSV_LIST_REQUEST',
    GET_CSV_LIST_SUCCESS: 'GET_CSV_LIST_SUCCESS',
    GET_CSV_LIST_FAILURE: 'GET_CSV_LIST_FAILURE',
    DETAIL_IMPORT_LIST_REQUEST: 'DETAIL_IMPORT_LIST_REQUEST',
    DETAIL_IMPORT_LIST_SUCCESS: 'DETAIL_IMPORT_LIST_SUCCESS',
    DETAIL_IMPORT_LIST_FAILURE: 'DETAIL_IMPORT_LIST_FAILURE',
    GET_USER_PROFILE: 'GET_USER_PROFILE',
    GET_USER_ERROR: 'GET_USER_ERROR',

    // Presence Report
    PRESENCE_REPORT_REQUEST: 'PRESENCE_REPORT_REQUEST',
    PRESENCE_REPORT_SUCCESS: 'PRESENCE_REPORT_SUCCESS',
    PRESENCE_REPORT_FAILURE: 'PRESENCE_REPORT_FAILURE',
    PRESENCE_REPORT_NEXT_PAGE: 'PRESENCE_REPORT_NEXT_PAGE',
    PRESENCE_REPORT_NEXT_PAGE_SUCCESS: 'PRESENCE_REPORT_NEXT_PAGE_SUCCESS',
    PRESENCE_REPORT_VERIFIED: 'PRESENCE_REPORT_VERIFIED',

    // Course
    GET_COURSE_REQUEST: 'GET_COURSE_REQUEST',
    GET_COURSE_SUCCESS: 'GET_COURSE_SUCCESS',
    GET_COURSE_FAILURE: 'GET_COURSE_FAILURE',

    // Quizzes
    QUIZZES_REQUEST: 'QUIZZES_REQUEST',
    QUIZZES_SUCCESS: 'QUIZZES_SUCCESS',
    QUIZZES_FAILURE: 'QUIZZES_FAILURE',
    GET_QUIZZES_NEXT_PAGE: 'GET_QUIZZES_NEXT_PAGE',

    // Gamification
    FETCH_GAMIFICATION_RULES: 'FETCH_GAMIFICATION_RULES',
    FETCH_GAMIFICATION_SUCCESS: 'FETCH_GAMIFICATION_SUCCESS',
    FETCH_GAMIFICATION_FAIL: 'FETCH_GAMIFICATION_FAIL',
    TOGGLE_GAMIFICATION_REQUEST: 'TOGGLE_GAMIFICATION_REQUEST',
    TOGGLE_GAMIFICATION_SUCCESS: 'TOGGLE_GAMIFICATION_SUCCESS',
    TOGGLE_GAMIFICATION_FAIL: 'TOGGLE_GAMIFICATION_FAIL',
    GET_RANKING: 'GET_RANKING',
    GET_RANKING_NEXT_PAGE: 'GET_RANKING_NEXT_PAGE',
    SAVE_GAMIFICATION_RULES: 'SAVE_GAMIFICATION_RULES',
    SAVE_GAMIFICATION_RULES_SUCCESS: 'SAVE_GAMIFICATION_RULES_SUCCESS',
    SAVE_GAMIFICATION_RULES_FAIL: 'SAVE_GAMIFICATION_RULES_FAIL',
    SET_RANKING: 'SET_RANKING',
    SET_RANKING_PAGINATED: 'SET_RANKING_PAGINATED',
    GET_RANKING_FAILURE: 'GET_RANKING_FAILURE',
    SET_PAGE_RANKING_LOADING: 'SET_PAGE_RANKING_LOADING',
    SET_HASGAMIFICATION: 'SET_HASGAMIFICATION',

    // Block List
    BLOCK_LIST_REQUEST: 'BLOCK_LIST_REQUEST',
    BLOCK_LIST_NEXT_PAGE_REQUEST: 'BLOCK_LIST_NEXT_PAGE_REQUEST',
    BLOCK_LIST_NEXT_PAGE_REQUEST_SUCCESS: 'BLOCK_LIST_NEXT_PAGE_REQUEST_SUCCESS',
    BLOCK_LIST_SUCCESS: 'BLOCK_LIST_SUCCESS',
    BLOCK_LIST_FAILURE: 'BLOCK_LIST_FAILURE',
    UNBLOCK_USER: 'UNBLOCK_USER',

    // Captions
    GET_CAPTIONS: 'GET_CAPTIONS',
    SET_CAPTIONS: 'SET_CAPTIONS',
    REMOVE_CAPTION_REQUEST: 'REMOVE_CAPTION_REQUEST',
    REMOVE_CAPTION_SUCCESS: 'REMOVE_CAPTION_SUCCESS',
    UPLOAD_CAPTION_REQUEST: 'UPLOAD_CAPTION_REQUEST',
    TOGGLE_ADD_CAPTION_UPLOAD_MODAL: 'TOGGLE_ADD_CAPTION_UPLOAD_MODAL',
    EDIT_CAPTION: 'EDIT_CAPTION',

    // Progress tutorial
    ROOM_GET_PROGRESS: 'ROOM_GET_PROGRESS',
    ROOM_SET_PROGRESS: 'ROOM_SET_PROGRESS',
};
