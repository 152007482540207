import { useSelector, shallowEqual } from 'react-redux';

function roomsSelector({ room }) {
    return {
        rooms: room.rooms,
        selectedRoom: room.selectedRoom,
        selectedEditRoom: room.selectedEditRoom,
        selectedEditRoomLinks: room.selectedEditRoomLinks,
        statusLiveRoomEditSelected: room.statusLiveRoomEditSelected,
        pinnedMessages: room.roomPinnedMessages,
        loading: room.loading,
        roomEngagement: room.roomEngagement,
        roomEventLog: room.roomEventLog,
        roomGraph: room.roomGraph,
        roomGeolocalization: room.roomGeolocalization,
        roomListActiveMonitors: room.roomListActiveMonitors,
        isChatEnabled: room.isChatEnabled,
        videoToUpload: room.videoToUpload,
        progress: room.progress,
        isUploadingInBackground: room.isUploadingInBackground,
        channelsListForSelectSidebar: room.channelsListForSelectSidebar,
        selectedChannel: room.selectedChannel,
        selectedChannelRooms: room.selectedChannelRooms,
        hasGamification: room.hasGamification,
        hasExtraUsers: room.hasExtraUsers,
        technology: room.technology,
        videoUrl: room.videoUrl,
        socketPath: room.socketPath,
        status: room.status,
    };
}

export default function useRooms() {
    const {
        rooms,
        selectedRoom,
        selectedEditRoom,
        selectedEditRoomLinks,
        statusLiveRoomEditSelected,
        pinnedMessages,
        loading,
        roomEngagement,
        roomEventLog,
        roomGraph,
        roomGeolocalization,
        roomListActiveMonitors,
        isChatEnabled,
        videoToUpload,
        progress,
        isUploadingInBackground,
        channelsListForSelectSidebar,
        selectedChannel,
        selectedChannelRooms,
        hasGamification,
        hasExtraUsers,
        technology,
        videoUrl,
        socketPath,
        status,
    } = useSelector(roomsSelector, shallowEqual);
    return {
        rooms,
        selectedRoom,
        selectedEditRoom,
        selectedEditRoomLinks,
        statusLiveRoomEditSelected,
        pinnedMessages,
        loading,
        roomEngagement,
        roomEventLog,
        roomGraph,
        roomGeolocalization,
        roomListActiveMonitors,
        isChatEnabled,
        videoToUpload,
        progress,
        isUploadingInBackground,
        channelsListForSelectSidebar,
        selectedChannel,
        selectedChannelRooms,
        hasGamification,
        hasExtraUsers,
        technology,
        videoUrl,
        socketPath,
        status,
    };
}
