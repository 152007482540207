import { useSelector, shallowEqual } from 'react-redux';

function gamificationSelector({ gamification }) {
    return {
        gamificationRules: gamification.gamificationRules,
        loading: gamification.loading,
    };
}

export default function useGames() {
    const { gamificationRules, loading } = useSelector(gamificationSelector, shallowEqual);
    return { gamificationRules, loading };
}
