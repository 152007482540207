/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import * as mediasoup from 'mediasoup-client';

/**
 * @typedef {import('mediasoup-client').types.Consumer} Consumer
 */

/**
 * @typedef {import('mediasoup-client').types.Producer} Producer
 */

/**
 * @typedef {import('mediasoup-client').types.Transport} Transport
 */

export function indexOfMaxQuality(arr) {
    if (arr.length === 0) {
        return -1;
    }
    let max = arr[0];
    let maxIndex = 0;
    for (let i = 1; i < arr.length; i += 1) {
        if (arr[i].scaleResolutionDownBy < max.scaleResolutionDownBy) {
            maxIndex = i;
            max = arr[i].scaleResolutionDownBy;
        }
    }
    return maxIndex;
}

export function indexOfMinQuality(arr) {
    if (arr.length === 0) {
        return -1;
    }
    let max = arr[0];
    let maxIndex = 0;
    for (let i = 1; i < arr.length; i += 1) {
        if (arr[i].scaleResolutionDownBy > max.scaleResolutionDownBy) {
            maxIndex = i;
            max = arr[i].scaleResolutionDownBy;
        }
    }
    return maxIndex;
}

class MediaSoupConsumerService {
    /**
     * @type { MediaStream }
     */
    _stream = null;

    /**
     * @type { MediaStream }
     */
    _presenterStream = null;

    /** WebSocket instance specific for WebRTC
     * @type { import('socket.io-client').Socket }
     */
    _socket = null;

    /**
     * @type { Transport }
     */
    _recvTransport = null;

    /**
     * @type { Consumer[] }
     */
    _consumers = [];

    /**
     * @type { Producer[] }
     */
    _producers = []

    /**
     * @type { Producer }
     */
    _presenter = {}

    constructor() {
        this._transport = null;
        this._mediaDevice = null;
        this._videoObject = null;
        this._consuming = [];
        this._connectOptions = {};

        this.onGotProducerListener = null;
        this.onGotPresenterListener = null;
        this.onDisconnectProducerListener = null;
        this.onDisconnectPresenterListener = null;
        this.onConnectedListener = null;
        this.onGotProducersListListener = null;
        this.onDisconnectedListener = null;
        this.onReconnectListener = null;
        this.onGotStream = null;
        this.onPauseVideoListener = null;
        this.onResumeVideoListener = null;
        this.onMutedUserListener = null;
        this.onUnmutedUserListener = null;
        this.connected = false;
        this.ignoreNewProducer = false;

        this.consume = this.consume.bind(this);
        this.createProducers = this.createProducers.bind(this);
        this.connect = this.connect.bind(this);
        this.handleTransportConnect = this.handleTransportConnect.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.getVideoStats = this.getVideoStats.bind(this);
        this.onProducerConnect = this.onProducerConnect.bind(this);
        this.onPresenterConnect = this.onPresenterConnect.bind(this);
        this.onProducerDisconnect = this.onProducerDisconnect.bind(this);
        this.onPresenterDisconnect = this.onPresenterDisconnect.bind(this);
        this.clearWebSocketInstance = this.clearWebSocketInstance.bind(this);
        this.hasProducers = this.hasProducers.bind(this);
        this.onConnect = this.onConnect.bind(this);
        this.onDisconnect = this.onDisconnect.bind(this);
        this.mediaProducerClosed = this.mediaProducerClosed.bind(this);
        this.getPresenterStream = this.getPresenterStream.bind(this);
        this.getActiveStream = this.getActiveStream.bind(this);
        this.mediaProducerDisconnect = this.mediaProducerDisconnect.bind(this);
        this.getProducerAudio = this.getProducerAudio.bind(this);
        this.getProducerVideo = this.getProducerVideo.bind(this);
        this.requestChangeMediaQuality = this.requestChangeMediaQuality.bind(this);
        this.addProducer = this.addProducer.bind(this);
        this.removeConsumer = this.removeConsumer.bind(this);
        this.getProducerMedia = this.getProducerMedia.bind(this);
        this.processConsume = this.processConsume.bind(this);
        this.removeProducer = this.removeProducer.bind(this);
        this.reconnect = this.reconnect.bind(this);
        this.handleGotProducer = this.handleGotProducer.bind(this);
        this.processPresenterMedia = this.processPresenterMedia.bind(this);
        this.handleGotProducer = this.handleGotProducer.bind(this);
        this.processPresenterMedia = this.processPresenterMedia.bind(this);
        this.pauseMedia = this.pauseMedia.bind(this);
        this.resumeMedia = this.resumeMedia.bind(this);
        this.muteUser = this.muteUser.bind(this);
        this.unmuteUser = this.unmuteUser.bind(this);
    }

    /**
     *
     * @param { Producer []} producersList
     */
    processConsume(producersList) {
        producersList.forEach(async (user) => {
            if (!user?.stream) {
                const userMedia = await this.getProducerMedia(user.id);
                if (userMedia?.video) {
                    const availableLayers = await this._socket.request('getAvailableLayers', { producerId: user.id, kind: 'video' });
                    if (availableLayers.encodings) {
                        const bestEncodingIndex = indexOfMinQuality(availableLayers.encodings);
                        const foundConsumer = this._consumers.find(consumer => consumer.producerId === user.id && consumer.kind === 'video' && !consumer.closed);
                        if (foundConsumer) {
                            if (foundConsumer) {
                                const producerIndex = this._producers.findIndex(producer => producer.id === user.id);
                                this._producers[producerIndex].stream = new MediaStream();
                                this._producers[producerIndex].stream.addTrack(foundConsumer.track);
                            }
                        } else if (!this._consuming.find(item => item.id === user.id && item.kind === user.kind) && !foundConsumer) {
                            this._consuming.push({ id: user.id });
                            const isProducerPaused = userMedia?.video?.paused;
                            await this.getProducerVideo(user.id, bestEncodingIndex, isProducerPaused);
                        }
                    }
                }
            }
        });
    }

    async processPresenterMedia(producer, kind) {
        if (!producer) {
            return;
        }

        const foundConsumer = this._consumers.find(consumer => consumer.producerId === producer.id && consumer.kind === kind && !consumer.closed);
        const foundConsuming = this._consuming.find(item => item.id === producer.id && item.kind === kind);

        if (!foundConsuming && !foundConsumer) {
            if (kind === 'video') {
                this._consuming.push({ id: producer.id, kind });
                const videoStream = await this.consume(producer, kind);
                if (videoStream) {
                    this._presenterStream.addTrack(...videoStream.getVideoTracks());
                    this.getPresenterStream(producer);
                }
            }
            if (kind === 'audio') {
                this._consuming.push({ id: producer.id, kind });
                const audioStream = await this.consume(producer, kind);
                if (audioStream) {
                    this._presenterStream.addTrack(...audioStream.getAudioTracks());
                    this.getPresenterStream(producer);
                }
            }
        }
        this.onPresenterConnect(producer);
    }

    /**
     * Pause Media
     * @param { producerId: string } producerId
     * @param {[('audio' | 'video')]} kinds
     */
    pauseMedia({ producerId, kinds }) {
        const kind = kinds[0];
        const consumerToPause = this._consumers.find(consumer => consumer.producerId === producerId && consumer.kind === kind);
        if (consumerToPause && kind) {
            const index = this._consumers.findIndex(consumer => consumer.producerId === producerId && consumer.kind === kind);
            this._consumers[index].pause();
        }

        const producerToPause = this._producers.find(producer => producer.id === producerId);
        if (producerToPause) {
            const producerIndex = this._producers.findIndex(producer => producer.id === producerId);
            if (kind === 'video') {
                this._producers[producerIndex].isPaused = true;
                if (this.onPauseVideoListener) this.onPauseVideoListener(producerId);
            }
        }
    }

    /**
     * Resume Media
     * @param { producerId: string } producerId
     * @param {[('audio' | 'video')]} kinds
     */
    resumeMedia({ producerId, kinds }) {
        const kind = kinds[0];
        const consumerToPause = this._consumers.find(consumer => consumer.producerId === producerId && consumer.kind === kind);
        if (consumerToPause && kind) {
            const consumerIndex = this._consumers.findIndex(consumer => consumer.producerId === producerId && consumer.kind === kind);
            this._consumers[consumerIndex].resume();
        }

        const producerToPause = this._producers.find(producer => producer.id === producerId);
        if (producerToPause) {
            const producerIndex = this._producers.findIndex(producer => producer.id === producerId);
            if (kind === 'video') {
                this._producers[producerIndex].isPaused = false;
                if (this.onPauseVideoListener) this.onResumeVideoListener(producerId);
            }
        }
    }

    async muteUser({ producerId }) {
        const producerFound = this._producers.find(producer => producer.id === producerId);
        if (producerFound.stream) {
            producerFound.stream.getTracks().forEach(track => {
                track.stop();
            });
            producerFound.stream = null;
            this.removeConsumer(producerId, 'video');
            producerFound.blocked = true;
        }

        if (this.onMutedUserListener) {
            this.onMutedUserListener(producerId);
        }
    }

    async unmuteUser({ producerId, kinds }) {
        const producerFound = this._producers.find(producer => producer.id === producerId);
        const availableLayers = this._socket.request('getAvailableLayers', { producerId, kind: 'video' });

        if (producerFound) {
            await this.getProducerVideo(producerFound.id, availableLayers, producerFound.isPaused);
            this.resumeMedia({ producerId, kinds });
            producerFound.blocked = false;
        }

        if (this.onUnmutedUserListener) {
            this.onUnmutedUserListener(producerId);
        }
    }

    onConnect() {
        if (this.onConnectedListener) {
            this.onConnectedListener();
        }
    }

    async onDisconnect() {
        this._consuming = [];

        if (this._presenterStream) {
            this._presenterStream.getTracks().forEach(track => {
                track.stop();
                this._presenterStream.removeTrack(track);
            });
        }

        if (this.onDisconnectedListener) {
            this.onDisconnectedListener();
        }
        if (this.onDisconnectPresenterListener) {
            this.onDisconnectPresenterListener();
        }
    }

    onPresenterConnect(producer) {
        if (this.onGotPresenterListener) {
            this.onGotPresenterListener(producer);
        }
    }

    onProducerConnect(producer) {
        if (this.onGotProducerListener) {
            this.onGotProducerListener(producer);
        }
    }

    onGotProducersList(producers) {
        if (this.onGotProducersListListener) {
            this.onGotProducersListListener(producers);
        }
    }

    onProducerDisconnect(producer) {
        if (this.onDisconnectProducerListener) {
            this.onDisconnectProducerListener(producer);
        }
    }

    onPresenterDisconnect() {
        if (this.onDisconnectPresenterListener) {
            this.onDisconnectPresenterListener();
        }
    }

    reconnect() {
        if (this._recvTransport) this._recvTransport.close();
        this._consumers.forEach(consumer => consumer.close());
        this._consumers = [];
        const { listProducers, listPresenter } = this._connectOptions;
        this.connect(listProducers, listPresenter);
    }

    hasProducers() {
        const hasProducers = !!this._producers.length > 0;
        return hasProducers;
    }

    async getVideoStats() {
        if (this._consumer.video) {
            const videoStats = await this._consumer.video.getStats();
            return [...videoStats.values()].filter(item => item.type === 'inbound-rtp')[0];
        }
        return null;
    }

    setSocket(socketInstance) {
        this._socket = socketInstance;
    }

    async clearWebSocketInstance() {
        this._socket = null;
        if (this._recvTransport) {
            this._recvTransport.close();
        }
        if (this._presenterStream) {
            this._presenterStream.getTracks().forEach(track => track.stop());
            this._presenterStream = null;
        }
    }

    async loadDevice(routerRtpCapabilities) {
        try {
            this._mediaDevice = new mediasoup.Device();
        } catch (error) {
            if (error.name === 'UnsupportedError') {
                console.error('browser not supported');
            }
        }
        await this._mediaDevice.load({ routerRtpCapabilities });
        return this._mediaDevice;
    }

    /**
     *
     * @param { String } producerId Producer ID
     * @param { ('audio'| 'video') } kind Media Kind
     */
    async requestChangeMediaQuality(producerId, kind) {
        const availableLayers = await this._socket.request('getAvailableLayers', { producerId, kind });
        if (availableLayers) {
            const bestEncodingIndex = indexOfMaxQuality(availableLayers.encodings);
            this._socket.request('consumerChangeLayer', { producerId, kind, layerIndex: bestEncodingIndex });
        }
    }

    /**
     *
     * @param { Producer } producer
     */
    addProducer(producer) {
        const foundProducer = this._producers.find(item => item.id === producer.id);
        if (foundProducer) {
            const index = this._producers.findIndex(item => item.id === producer.id);
            this._producers[index] = { ...producer };
        } else {
            this._producers = [...this._producers, producer];
        }
    }

    /**
     * @param { {id: string }} producer
     * @param { ('audio' | 'video')  } requestedKind
     * @returns { Promise<MediaStream> } Promise of Stream
     */
    async consume(producer, requestedKind) {
        if (!producer?.id) {
            return null;
        }

        try {
            const { rtpCapabilities } = await this._mediaDevice;

            const data = await this._socket.request('consume', {
                producerId: producer.id,
                kind: requestedKind,
                rtpCapabilities,
            });
            const {
                producerId,
                id,
                kind,
                rtpParameters,
            } = data;

            const codecOptions = {};

            const consumer = await this._recvTransport.consume({
                id,
                producerId,
                kind,
                rtpParameters,
                codecOptions,
            });

            const stream = new MediaStream();

            stream.addTrack(consumer.track);

            this._socket.request('mediaConsumerResume', {
                producerId: producer.id,
                kind: requestedKind,
            });
            this._consumers = [...this._consumers, consumer];
            return stream;
        } catch (error) {
            console.log('Error to consume ', error);
            return null;
        }
    }

    setVideoObject(videoObject) {
        if (!videoObject) {
            throw new Error('Video element not provided');
        }
        this._videoObject = videoObject;
    }

    getPresenterStream(user) {
        const foundProducer = this._producers.find(item => item.id === user.id);

        if (foundProducer) {
            foundProducer.stream = this._presenterStream;
        } else {
            const producer = user;
            producer.stream = this._presenterStream;
            this._producers = [...this._producers, producer];
        }

        if (this._videoObject) {
            this._videoObject.srcObject = this._presenterStream;
        }
    }

    getActiveStream() {
        if (this._stream?.active) {
            return true;
        }
        return false;
    }

    async createProducers(listProducers, listPresenter) {
        let availableProducers = null;
        let availablePresenter = null;
        if (listProducers) {
            availableProducers = await this._socket.request('listProducers');
            this._producers = availableProducers.filter(user => user.level === 'interactive');
        }

        if (listPresenter) {
            availablePresenter = await this._socket.request('getPresenter');
        }

        this._presenter = availablePresenter;
        // Producer is a video wall's user
        if (this.onGotProducersListListener) this.onGotProducersListListener(this._producers);

        if (this._presenter?.id) {
            // Producer is a Presenter
            const producerMedia = await this.getProducerMedia(this._presenter.id);
            this._presenterStream = new MediaStream();
            if (producerMedia) {
                const keys = Object.keys(producerMedia).sort((a) => (a === 'video' ? -1 : 1));
                for (const kind of keys) {
                    if (producerMedia[kind]) {
                        await this.processPresenterMedia(this._presenter, kind);
                    }
                }
                this.onPresenterConnect(this._presenter);
            }
        }
    }

    async handleTransportConnect({ dtlsParameters }, callback, errorCallback) {
        try {
            const res = await this._socket.request('connectConsumerTransport', {
                transportId: this._transport.id,
                dtlsParameters,
            });
            callback(res);
        } catch (err) {
            errorCallback(err);
        }
    }

    /**
     *
     * @param {String} producerId
     */
    async getProducerMedia(producerId) {
        if (this._socket?.connected) {
            const producerMedia = await this._socket.request('getProducerMedia', { id: producerId });
            return producerMedia;
        }
        return null;
    }

    /**
    *
    * @param { string } producerId
    * @returns { Promise<MediaStream> } Promise of AudioStream
    */
    async getProducerAudio(producerId) {
        const producerIndex = this._producers.findIndex(user => user.id === producerId);
        let stream = null;
        const audioStream = await this.consume(this._producers[producerIndex], 'audio');
        if (audioStream) {
            stream = audioStream;
        }

        return stream;
    }

    /**
     *
     * @param { string } producerId Producer's ID
     * @param { Number } layerIndex Quality layer number - defaults 0
     * @param { Boolean } isPaused Whether media is paused - defaults false
     */
    async getProducerVideo(producerId, layerIndex = 0, isPaused = false) {
        const producerIndex = this._producers.findIndex(user => user.id === producerId);
        let stream = null;
        const videoStream = await this.consume(this._producers[producerIndex], 'video', layerIndex);
        if (videoStream) {
            stream = videoStream;
            if (this._producers[producerIndex] && !this._producers[producerIndex]?.stream) {
                this._producers[producerIndex].stream = videoStream;
                if (isPaused) this._producers[producerIndex].isPaused = true;
                if (this.onGotStream) this.onGotStream(this._producers[producerIndex]);
            }
        }

        return stream;
    }

    async handleGotProducer(data) {
        const {
            user,
            kind,
        } = data;

        if (user?.level === 'interactive') {
            // Producer is a video wall's user
            if (!this.ignoreNewProducer) {
                if (user && kind === 'video') {
                    this.addProducer(user);
                    if (this.state === 'connected') {
                        this.onProducerConnect(this._producers[this._producers.findIndex(producer => producer.id === user.id)]);
                    } else if (this.onReconnectListener) {
                        this.onReconnectListener();
                    }
                } else if (user && !kind) {
                    this.addProducer(user);
                    this.onProducerConnect(user);
                }
            }
        } else if (user?.level === 'primary' || user.level === 'secondary') {
            // Producer is a Presenter
            if (!this._presenterStream) {
                this._presenterStream = new MediaStream();
            }
            await this.processPresenterMedia(user, kind);
        }
    }

    async handleStateChange(state) {
        this.state = state;
        switch (state) {
            case 'connected':
                this.connected = true;
                this.onConnect();
                break;
            case 'disconnected':
                this.connected = false;
                this._recvTransport.close();
                this.onDisconnect();
                break;
            case 'failed':
                this.connected = false;
                this._recvTransport.close();
                this.onDisconnect();
                break;
            default:
                break;
        }
    }

    mediaProducerClosed(media) {
        const { kind } = media;
        let track = null;

        if (kind === 'video') {
            [track] = this._stream.getVideoTracks();
        } else if (kind === 'audio') {
            [track] = this._stream.getAudioTracks();
        }
        if (track) {
            track.stop();
            this._stream.removeTrack(track);
        }
    }

    /**
     *
     * @param {String} producerId
     * @param {('audio' | 'video')} kind
     */
    removeConsumer(producerId, kind) {
        const consumersToRemove = this._consumers.filter(consumer => consumer.producerId === producerId);
        if (consumersToRemove.length) {
            consumersToRemove.forEach(consumer => {
                if (consumer.kind === kind) {
                    if (this._socket && this._socket.connected) this._socket.request('consumerMediaClosed', { producerId, kind });
                    consumer.close();
                    this._consuming = this._consuming.filter(item => item.id !== consumer.producerId);
                    this._consumers = this._consumers.filter(item => item._id !== consumer.id);
                }
            });
        }
    }

    removeProducer(producer) {
        const index = this._producers.findIndex(item => item.id === producer.id);

        if (this._producers[index] && this._producers[index]?.stream) {
            this._producers[index].stream.getTracks().forEach(track => {
                track.stop();
                this._producers[index].stream = null;
            });
        }
    }

    mediaProducerDisconnect({ user }) {
        const disconnectedProducer = this._producers.find(producer => producer.id === user.id);
        // Closing and removing Disconnected users's media

        if (disconnectedProducer) {
            if (disconnectedProducer?.stream) {
                for (const track of disconnectedProducer.stream.getTracks()) {
                    track.stop();
                    disconnectedProducer.stream.removeTrack(track);
                }
            }
            this._producers = this._producers.filter(item => item.id !== user.id);

            if (disconnectedProducer?.level === 'interactive') {
                this.onProducerDisconnect(user);
            } else {
                this.onPresenterDisconnect(user);
            }
        }
        // Closing and removing Disconnected users's consumer
        this.removeConsumer(user.id, 'audio');
        this.removeConsumer(user.id, 'video');
    }

    invite(id) {
        this._socket.request('invite', { id });
    }

    uninvite(id) {
        this._socket.request('uninvite', { id });
    }

    async connect(listProducers = true, listPresenter = false) {
        if (!this._socket) {
            throw Error('Socket instance not provided!');
        }

        if (!this._socket.connected) {
            throw Error('Socket not connected!');
        }

        if (!this._recvTransport || this._recvTransport.closed) {
            const serverRtpParameters = await this._socket.request(
                'getRouterRtpCapabilities',
            );

            this._mediaDevice = await this.loadDevice(serverRtpParameters);

            this._transport = await this._socket.request(
                'createConsumerTransport', { forceTcp: false },
            );

            if (this._mediaDevice._loaded) {
                this._recvTransport = await this._mediaDevice.createRecvTransport(
                    this._transport,
                );
            }
        }

        this._recvTransport.on('connect', this.handleTransportConnect.bind({ transport: this._recvTransport }));

        this._recvTransport.on('connectionstatechange', this.handleStateChange.bind({ transport: this._recvTransport }));

        await this.createProducers(listProducers, listPresenter);
        this._connectOptions = { listProducers, listPresenter };

        this._socket.on('gotProducer', this.handleGotProducer);
        this._socket.on('producerTransportClosed', this.mediaProducerDisconnect);
        this._socket.on('mediaProducerClosed', this.mediaProducerClosed);
        this._socket.on('producerDisconnected', this.mediaProducerDisconnect);
        this._socket.on('mediaProducerPaused', this.pauseMedia);
        this._socket.on('mediaProducerResumed', this.resumeMedia);
        this._socket.on('mutedUser', this.muteUser);
        this._socket.on('unmutedUser', this.unmuteUser);
    }
}

export default new MediaSoupConsumerService();
