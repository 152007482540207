import React, { useCallback, useEffect, useState, useRef } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import languageConstants from '@langs/constants';
import 'react-dropdown/style.css';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import moment from 'moment';

import { PROCESSING_USERS } from '@src/utils/constants';
import cloudIcon from '@src/assets/cloud-white-icon.svg';
import leftArrowIcon from '@src/assets/left-arrow-icon.svg';
import alertIco from '@src/assets/alert-grey.svg';
import sampleIcon from '@src/assets/file.svg';

import useRooms from '@hooks/useRooms';
import { useBreadcrumbs } from '@hooks/useBreadcrumbs';
import Modal from '@components/Modal';
import NoContent from '@components/NoContent';
import paths from '@utils/paths';
import { limitCharacters } from '@utils/functions';
import api from '@src/services/api';
import { defaultErrorHandler } from '@src/store/utilities';
import {
    Container,
    PageHeader,
    FileTable,
    TableFilePagination,
    TipMessageArea,
    AttentionIco,
    TipMessage,
    DownloadSample,
    LoadMoreButton,
} from './styles';

import UploadCSVModal from '../UploadCSVModal';
import CSVDetails from '../CSVDetails';
import { downloadXlsx } from '../utils/functions';

const LoadingSkeleton = () => (
    <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
        <div style={{ marginTop: 10 }}>
            <Skeleton height={50} count={10} style={{ margin: '5px 0' }} />
        </div>
    </SkeletonTheme>
);

function isProcessingUsers(status) {
    return (status === PROCESSING_USERS.ERROR || status === PROCESSING_USERS.WAITING);
}

function renderStatus(status, errorRate, t) {
    const type = {
        error: <span className="disabled">{t(languageConstants.ERROR)}</span>,
        waiting: <span className="in-progress">{t(languageConstants.IN_PROGRESS)}</span>,
        done: <span className="finished">{t(languageConstants.DONE)}</span>,
        completedWithError: <span className="completed-with-error">{t(languageConstants.COMPLETEDWITHERROR)}</span>,
        usersLimitReached: <span className="users-limit-reached">{t(languageConstants.USERSLIMITREACHED)}</span>,
    };

    const rate = errorRate?.split('/');
    if (rate[0] !== '0' && status === 'done') {
        return type.completedWithError;
    }
    return type[status];
}

function renderErrorRate(errorRate, t) {
    if (!errorRate) return '';
    const rate = errorRate?.split('/');
    return `${rate[0]} ${t(languageConstants.OF)} ${rate[1]} ${t(languageConstants.USERS)}`;
}

const ROWS_PER_PAGE = 10;

export default function ImportCSV() {
    const [showImportCsv, setShowImportCsv] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [fileOnDetail, setFileOnDetail] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [lastKey, setLastKey] = useState(null);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const { t } = useTranslation();
    const { selectedChannel } = useRooms();
    const { setItems } = useBreadcrumbs();
    const { id: roomId } = useParams();
    const processingUploadRef = useRef([]);
    const history = useHistory();
    const channelId = selectedChannel?.id;

    function handleShowDetails(file) {
        setFileOnDetail(file);
        setShowDetails(true);
    }

    useEffect(() => {
        const breadcrumbsItems = [
            { to: paths.HOME, label: t(languageConstants.ORGANIZER_DASHBOARD) },
            { to: paths.USERS, label: t(languageConstants.USERS) },
            { to: paths.IMPORT_CSV, label: t(languageConstants.IMPORT_SPREADSHEET) },
        ];
        setItems(breadcrumbsItems);
    }, [roomId, setItems, t]);

    const fetchFileList = useCallback((lastEvaluatedKey = null, keepOldListValues = true) => {
        api.getImportCsvList(channelId, ROWS_PER_PAGE, lastEvaluatedKey)
            .then(response => {
                setIsLoading(false);
                setLastKey(response.lastKey);
                setIsLoadingMore(false);
                if (keepOldListValues) {
                    setFileList(prev => [...prev, ...response.items]);
                } else {
                    setFileList([...response.items]);
                }
            })
            .catch(error => {
                setIsLoadingMore(false);
                defaultErrorHandler(error, { default: t(languageConstants.FAIL_GET_DETAIL_IMPORT_LIST) });
            });
    }, [channelId, t]);

    useEffect(() => {
        if (channelId) {
            fetchFileList();
        }
    }, [channelId, fetchFileList]);

    async function handleDownloadSample() {
        await downloadXlsx('import_sample.xlsx', t(languageConstants.XLSX_SAMPLE_FAIL));
    }

    function loadMore() {
        setIsLoadingMore(true);
        fetchFileList(lastKey);
    }

    useEffect(() => {
        const polling = setInterval(async () => {
            const isProcessingFiles = fileList.filter(file => file.status === 'waiting');
            if (isProcessingFiles.length) {
                const { items } = await api.getImportCsvList(channelId, ROWS_PER_PAGE, null);
                isProcessingFiles.forEach(isProcessingFile => {
                    const currentFile = items.find(file => file.id === isProcessingFile.id);
                    if (currentFile && currentFile.status !== isProcessingFile.status) {
                        setFileList(prevState => prevState.map(item => (item.id === currentFile.id ? currentFile : item)));
                    }
                });
            }
        }, 3000);
        return () => clearInterval(polling);
    }, [fileList, channelId]);

    return (
        <Container>
            <Modal
                isOpen={showImportCsv}
                handleModal={() => setShowImportCsv(false)}
                title={t(languageConstants.IMPORT)}
                featuredTitle={t(languageConstants.SPREADSHEET)}
                width={550}
            >
                <UploadCSVModal
                    fetchList={() => fetchFileList(null, false)}
                    handleClose={() => setShowImportCsv(false)}
                />
            </Modal>

            <Modal
                isOpen={showDetails}
                handleModal={() => setShowDetails(false)}
                title={t(languageConstants.XLSX_DETAILS)}
                featuredTitle={t(languageConstants.SPREADSHEET)}
                width={800}
            >
                <CSVDetails
                    fileOnDetail={fileOnDetail}
                    failedUsers={Number(fileOnDetail?.errorRate.split('/')[0])}
                    succeedUsers={Number(fileOnDetail?.errorRate.split('/')[1])}
                />
            </Modal>

            <PageHeader>
                <h2>{channelId && limitCharacters(`#${channelId} - ${selectedChannel?.name}`, 40)}</h2>
                <div>
                    <button type="button" onClick={() => history.push(paths.USERS)}>
                        <small>
                            <img
                                alt={`${t(languageConstants.BACK_TO)} ${t(languageConstants.USERS)}`}
                                src={leftArrowIcon}
                                width={19}
                            />
                        </small>
                        <span>{`${t(languageConstants.BACK_TO)} ${t(languageConstants.USERS)}`}</span>
                    </button>

                    <button type="button" onClick={() => setShowImportCsv(true)}>
                        <small>
                            <img alt="plusIcon" src={cloudIcon} width={19} />
                        </small>
                        <span>{t(languageConstants.CHANNEL_UPLOADSPREADSHEET)}</span>
                    </button>
                </div>
            </PageHeader>

            <TipMessageArea>
                <AttentionIco>
                    <img src={alertIco} alt="alertIco" />
                </AttentionIco>
                <TipMessage>
                    <div>
                        {t(languageConstants.IMPORT_LIST_TIP).toUpperCase()}
                        <span> NAME_CHAT, FULL_NAME, E-MAIL, GENDER (F - <strong>{t(languageConstants.FEMALE).toUpperCase()}</strong>,
                            M - <strong>{t(languageConstants.MALE).toUpperCase()}</strong> {t(languageConstants.OR).toUpperCase()} O - <strong>{t(languageConstants.OTHERS).toUpperCase()}</strong>),
                            DATE_OF_BIRTH, PHONE {t(languageConstants.AND)} PERMISSION (<strong>ORGANIZER, MONITOR, USER</strong>).
                        </span>
                    </div>
                    <DownloadSample onClick={handleDownloadSample}>
                        <img className="icon-xlsx" src={sampleIcon} alt="download sample" />
                        {t(languageConstants.DOWNLOAD_SPREADSHEET_SAMPLE)}
                    </DownloadSample>
                </TipMessage>
            </TipMessageArea>

            {
                !isLoading && !fileList.length && (
                    <NoContent
                        title={t(languageConstants.XLSX_FORMAT_NO_IMPORT)}
                        text={t(languageConstants.XLSX_FORMAT_ERROR_MESSAGE)}
                        button={{
                            text: t(languageConstants.IMPORT_SPREADSHEET),
                            action: () => setShowImportCsv(true),
                        }}
                    />
                )
            }
            {
                isLoading && <LoadingSkeleton />
            }
            {
                ((!isLoading && fileList.length) || processingUploadRef.current.length) ? (
                    <div>
                        <div className="containerTable">
                            <FileTable>
                                <Table>
                                    <TableHead>
                                        <tr>
                                            <th align="left">{t(languageConstants.FILE)}</th>
                                            <th align="left">{t(languageConstants.PROCESS_STATUS)}</th>
                                            <th align="left">{t(languageConstants.CREATION_DATE)}</th>
                                            <th align="left">{t(languageConstants.REGISTRATION_ERRORS)}</th>
                                            <th align="left">{' '}</th>
                                        </tr>
                                    </TableHead>
                                    <TableBody>
                                        {fileList.map((file) => (
                                            <tr key={file?.id}>
                                                <td align="left">{file?.fileName}</td>
                                                <td align="left">{renderStatus(file?.status, file?.errorRate, t)}</td>
                                                <td align="left">{moment(file?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                <td align="left">{renderErrorRate(file.errorRate, t)}</td>
                                                <td align="right">
                                                    <div className="line-controls">
                                                        <button
                                                            className={isProcessingUsers(file?.status) ? 'action-button-disable' : 'action-button'}
                                                            type="button"
                                                            onClick={() => handleShowDetails(file)}
                                                            disabled={isProcessingUsers(file?.status)}
                                                        >
                                                            {t(languageConstants.DETAILS)}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </TableBody>
                                </Table>
                            </FileTable>
                        </div>
                        <TableFilePagination>
                            <div className="table-footer">
                                <LoadMoreButton disabled={!lastKey || isLoadingMore} type="button" onClick={loadMore}>
                                    {
                                        isLoadingMore
                                            ? `${t(languageConstants.LOADING)}...`
                                            : t(languageConstants.LOAD_MORE_PAGINATION)
                                    }
                                </LoadMoreButton>
                            </div>
                        </TableFilePagination>
                    </div>
                ) : null
            }
        </Container>
    );
}
