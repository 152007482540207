import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import { LiveContainer, ControlHeader } from './styles';
import { changeChatEnabled } from '../../../store/modules/rooms/actions';
import ToggleSwitch from '../../ToggleSwitch';
import useRooms from '../../../hooks/useRooms';
import CopyIcon from '../../../assets/copy-gray.svg';

const LiveVideo = ({
    primaryBroadcastingUrl,
    secondaryBroadcastingUrl,
    streamName,
    roomId,

}) => {
    const { t } = useTranslation();
    const { isChatEnabled } = useRooms();
    const [openTooltipPrimary, setOpenTooltipPrimary] = useState(false);
    const [openTooltipSecondary, setOpenTooltipSecondary] = useState(false);
    const [openTooltipPassword, setOpenTooltipPassword] = useState(false);
    const dispatch = useDispatch();

    const handleToggle = () => {
        dispatch(changeChatEnabled(roomId, !isChatEnabled));
    };

    const primaryClickHandle = () => {
        navigator.clipboard.writeText(primaryBroadcastingUrl).then(
            setOpenTooltipPrimary(true),
        );
    };

    const secondaryClickHandle = () => {
        navigator.clipboard.writeText(secondaryBroadcastingUrl).then(
            setOpenTooltipSecondary(true),
        );
    };

    const passwordClickHandle = () => {
        navigator.clipboard.writeText(streamName).then(
            setOpenTooltipPassword(true),
        );
    };

    return (
        <LiveContainer>
            <div>
                <div className="chat-enabled">
                    <ToggleSwitch
                        isOn={isChatEnabled}
                        handleToggle={handleToggle}
                    />
                    <div className="chat-mode-info">
                        <h3>{t('CHANNEL', { context: 'CHAT_ENABLED' })}</h3>
                        <span className={isChatEnabled && '--active'}>{isChatEnabled ? t('ENABLED_CAPS') : t('DISABLED_CAPS')}</span>
                    </div>
                </div>
                <div className="primary-url">
                    <ControlHeader>
                        <h3>{t('CHANNEL', { context: 'PRIMARYURL' })}: </h3>
                        <ClickAwayListener onClickAway={() => setOpenTooltipPrimary(false)}>
                            <Tooltip
                                id="tooltip"
                                PopperProps={{ disablePortal: true }}
                                onClose={() => setOpenTooltipPrimary(false)}
                                open={openTooltipPrimary}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={t('CHANNEL', { context: 'COPIED' })}
                            >
                                <a href="#/"><img onClick={primaryClickHandle} alt="Copy Icon" className="copy-icon" src={CopyIcon} /> </a>
                            </Tooltip>
                        </ClickAwayListener>
                    </ControlHeader>
                    <span>{primaryBroadcastingUrl}</span>
                </div>
                <div className="secondary-url">
                    <ControlHeader>
                        <h3>{t('CHANNEL', { context: 'SECONDARYURL' })}: </h3>
                        <ClickAwayListener onClickAway={() => setOpenTooltipSecondary(false)}>
                            <Tooltip
                                id="tooltip"
                                PopperProps={{ disablePortal: true }}
                                onClose={() => setOpenTooltipSecondary(false)}
                                open={openTooltipSecondary}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={t('CHANNEL', { context: 'COPIED' })}
                            >
                                <a href="#/"><img onClick={secondaryClickHandle} alt="Copy Icon" className="copy-icon" src={CopyIcon} /> </a>
                            </Tooltip>
                        </ClickAwayListener>
                    </ControlHeader>
                    <span>{secondaryBroadcastingUrl}</span>
                </div>
                {
                    streamName && (
                        <div className="password">
                            <ControlHeader>
                                <h3>{t('CHANNEL', { context: 'PASSWORD' })}: </h3>
                                <ClickAwayListener onClickAway={() => setOpenTooltipPassword(false)}>
                                    <Tooltip
                                        id="tooltip"
                                        PopperProps={{ disablePortal: true }}
                                        onClose={() => setOpenTooltipPassword(false)}
                                        open={openTooltipPassword}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={t('CHANNEL', { context: 'COPIED' })}
                                    >
                                        <a href="#/"><img onClick={passwordClickHandle} alt="Copy Icon" className="copy-icon" src={CopyIcon} /> </a>
                                    </Tooltip>
                                </ClickAwayListener>
                            </ControlHeader>
                            <span>{streamName}</span>
                        </div>
                    )
                }

            </div>
        </LiveContainer>
    );
};

export default LiveVideo;
