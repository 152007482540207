import { all, put, takeLatest } from 'redux-saga/effects';

import api from '../../../services/api';
import actions from '../../../utils/actions';
import { defaultErrorHandler } from '../../utilities';
import { translate } from '../../../utils/functions';

function* getTeamStatus({ payload: roomId }) {
    try {
        const data = yield api.getTeamsEnable(roomId);
        const { enabled } = data;
        yield put({ type: actions.SET_TEAMS_STATUS, payload: enabled });

        if (enabled) {
            yield put({ type: actions.GET_TEAMS, payload: roomId });
        }
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_GETTEAMSSTATUS') });
    }
}

function* getTeams({ payload: roomId }) {
    try {
        const data = yield api.getTeams(roomId);
        yield put({ type: actions.SET_TEAMS, payload: data });
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_GETTEAMS') });
    }
}

function* setTeamsStatus({ payload }) {
    const { status, roomId } = payload;
    try {
        yield put({ type: actions.SET_TEAMS_STATUS, payload: status });
        yield api.setTeamsStatus(status, roomId);

        if (status) {
            yield put({ type: actions.GET_TEAMS, payload: roomId });
        }
    } catch (error) {
        yield put({ type: actions.SET_TEAMS_STATUS, payload: !status });
        defaultErrorHandler(error, { default: translate('FAIL_SETTEAMSSTATUS') });
    }
}

function* createTeams({ payload }) {
    const { roomId, people } = payload;
    try {
        yield api.createTeams(roomId, people);
        const data = yield api.getTeams(roomId);
        yield put({ type: actions.SET_TEAMS, payload: data });
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_CREATETEAMS') });
    }
}

export default all([
    takeLatest(actions.GET_TEAMS_STATUS, getTeamStatus),
    takeLatest(actions.GET_TEAMS, getTeams),
    takeLatest(actions.ASYNC_SET_TEAMS_STATUS, setTeamsStatus),
    takeLatest(actions.ASYNC_CREATE_TEAMS, createTeams),
]);
