/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';

import { DropdownButtonWrapper, DropdownContainer, DropdownList, SectionContainer } from './styles';
import arrowDownIcon from '../../../assets/arrow-down-icon.svg';
import cleanIcon from '../../../assets/close.svg';

const DropdownChannelSearch = ({
    showTopHeaderContent,
    setShowTopHeaderContent,
    toggleTopHeaderContent,
    selectedChannel,
    handleClearFilter,
    searchText,
    handleFilter,
    filteredChannelsList,
    handleSelectChannel,

}) => {
    const { t } = useTranslation();

    return (
        <OutsideClickHandler
            onOutsideClick={() => setShowTopHeaderContent(false)}
            display="contents"
        >
            <DropdownContainer>
                <DropdownButtonWrapper
                    tabIndex={0}
                    role="button"
                    onKeyPress={toggleTopHeaderContent}
                    onClick={toggleTopHeaderContent}
                >
                    <div className="dropdown-component">
                        <SectionContainer>
                            <small>{t('CHANNEL_HEADER')}</small>
                            <p className="emphasis">{selectedChannel ? `${selectedChannel.id || '-'} - ${selectedChannel.name || '-'}` : ''}</p>
                            <img
                                src={arrowDownIcon}
                                alt="Mostrar Mais"
                                className={`${showTopHeaderContent ? '--open' : undefined}`}
                            />
                        </SectionContainer>
                    </div>
                </DropdownButtonWrapper>
                <DropdownList className="top right">
                    {showTopHeaderContent
                        && (
                            <>
                                <li className="--search-wrapper">
                                    {searchText !== '' && (
                                        <button type="button" onClick={handleClearFilter}>
                                            <img src={cleanIcon} alt="Limpar" />
                                        </button>
                                    )}
                                    <input
                                        autoFocus
                                        tabIndex={0}
                                        value={searchText}
                                        onChange={handleFilter}
                                        className="--search"
                                        type="text"
                                        placeholder="FILTRAR NOME OU ID"
                                    />
                                </li>
                                {
                                    filteredChannelsList.map(channel => (
                                        <li onClick={() => handleSelectChannel(channel)} key={channel.id}>
                                            {`${channel?.id} - ${channel?.name}`}
                                        </li>
                                    ))
                                }

                            </>
                        )}
                </DropdownList>
            </DropdownContainer>
        </OutsideClickHandler>
    );
};

export default DropdownChannelSearch;
