import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Moment from 'moment-timezone';
import { changeChatEnabled, createRoom, editRoom, roomIsLive } from '@src/store/modules/rooms/actions';
import CourseVideoForm from '@src/components/CourseVideoForm';
import useRooms from '@src/hooks/useRooms';
import Tz from '@src/assets/timezones.json';

import { uniqueId } from 'lodash';
import Notify from '@src/utils/notification';
import { useTranslation } from 'react-i18next';
import { toISOLocal } from '@src/utils/functions';
import useAuth from '@src/hooks/useAuth';
import CaptionsTable from '@src/components/CaptionsTable';
import languageConstants from '@langs/constants';
import history from '@src/services/history';
import useCaptions from '@src/hooks/useCaptions';
import Modal from '@src/components/Modal';
import { CaptionsTableContainer, Container, VideoTabs } from './styles';

function addHours(date, hours) {
    const baseDate = new Date(date);
    baseDate.setHours(baseDate.getHours() + hours);
    return baseDate;
}

function checkRoomScheduling(startDate, timezoneValue) {
    const now = new Date(new Date().toLocaleString('en-us', { timeZone: timezoneValue }));
    const scheduledDate = new Date(startDate.toLocaleString('en-us', { timeZone: timezoneValue }));
    const maxScheduledDate = addHours(now, 4);

    if (scheduledDate >= maxScheduledDate) {
        return false;
    }
    return true;
}

const CourseRecordedForm = ({
    roomId,
    setThumbnailUrl,
    thumbnailUrl,
    setUploadedFile,
    createChatEnabled,
    showSkeleton,
}) => {
    const [roomName, setRoomName] = useState('');
    const [roomDescription, setRoomDescription] = useState('');
    const [uploadedMultipleFiles, setUploadedMultipleFiles] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState();
    const [roomEdit, setRoomEdit] = useState(false);
    const [timezoneValue, setTimezoneValue] = useState();
    const [activeTab, setActiveTab] = useState(0);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [showScheduleWarn, setShowScheduleWarn] = useState(false);
    const { showUploadModal: shouldActiveCaptionTab } = useCaptions();
    const { selectedEditRoom, selectedChannel, status } = useRooms();
    const { user } = useAuth();

    const dispatch = useDispatch();

    const { t } = useTranslation();

    useEffect(() => {
        setStartDate(new Date());
        setEndDate();
        setRoomDescription('');
        setThumbnailUrl('');
        setRoomName('');
        setUploadedFile({});
        setUploadedMultipleFiles([]);

        return () => {
            if (window.revokeObjectURL) {
                uploadedMultipleFiles.forEach(file => URL.revokeObjectURL(file.preview));
            }
        };
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (roomId) setRoomEdit(true);
    }, [roomId]);

    useEffect(() => {
        if (roomEdit && selectedEditRoom) {
            if (selectedEditRoom?.startDate) {
                const gmt = selectedEditRoom.startDate?.substr(selectedEditRoom.startDate.length - 6);
                const currentTz = Tz.timezones.filter(el => el.label.indexOf(gmt) >= 0);
                if (currentTz) {
                    setTimezoneValue(currentTz[0]?.value);
                }
            }
            dispatch(roomIsLive(selectedEditRoom?.id));
            setStartDate(new Date(selectedEditRoom.startDate));
            setRoomDescription(selectedEditRoom.description);
            setThumbnailUrl(selectedEditRoom.image);

            setRoomName(selectedEditRoom?.name);
            const imageName = selectedEditRoom.image ? selectedEditRoom.image.substr(selectedEditRoom.image.lastIndexOf('/') + 1) : '';

            if (selectedEditRoom.endDate) {
                setEndDate(new Date(selectedEditRoom.endDate));
            }
            const uploadedImage = {
                undefined,
                id: uniqueId(),
                name: imageName,
                readableSize: 0,
                preview: selectedEditRoom.image,
                progress: 0,
                uploaded: true,
                error: false,
                url: selectedEditRoom.image,
            };
            setUploadedFile(uploadedImage);

            const uploadedFile = (selectedEditRoom.attachments && selectedEditRoom.attachments.map(attach => ({
                id: uniqueId(),
                name: attach.attachment_name,
                readableSize: 0,
                preview: attach.attachment_url,
                progress: 0,
                uploaded: true,
                error: false,
                url: attach.attachment_url,
            }))) || [];
            setUploadedMultipleFiles(uploadedFile);
        }
    }, [dispatch, roomEdit, selectedEditRoom, setThumbnailUrl, setUploadedFile]);

    const handleNewRoom = (formValuesData) => {
        setSubmitButtonDisabled(true);
        if (!thumbnailUrl) {
            Notify.warn(t('INSERT_VIDEO_THUMBNAIL'));
            setSubmitButtonDisabled(false);
        } else {
            const { videoTitle } = formValuesData;

            const roomObject = {
                room_name: videoTitle,
                description: roomDescription,
                thumbnail_url: thumbnailUrl,
                course_id: selectedChannel.id,
                chat_enabled: createChatEnabled ?? selectedEditRoom.chatEnabled,
                start_date: timezoneValue
                    ? toISOLocal(new Date(Moment(startDate)
                        .tz(timezoneValue, true)
                        .format()))
                    : toISOLocal(startDate),
                end_date: timezoneValue
                    ? endDate ? toISOLocal(new Date(Moment(endDate)
                        .tz(timezoneValue, true)
                        .format())) : ''
                    : endDate ? toISOLocal(endDate) : '',
                attachments: uploadedMultipleFiles.map(file => ({
                    attachment_url: file.url,
                    attachment_extension: file.name.split('.').reverse()[0],
                    attachment_name: file.name,
                })),
            };
            Object.keys(roomObject).forEach((key) => (roomObject[key] === null || roomObject[key] === '') && delete roomObject[key]);

            if (roomObject.end_date) {
                const { start_date, end_date } = roomObject;
                if (Moment(end_date).isBefore(start_date)) {
                    Notify.warn(t('HOUR_ERROR_MESSAGE'));
                    setSubmitButtonDisabled(false);
                    return;
                }
            }

            if (roomEdit) {
                try {
                    if (!checkRoomScheduling(startDate, timezoneValue)) {
                        setShowScheduleWarn(true);
                        setSubmitButtonDisabled(false);
                        return;
                    }
                    dispatch(editRoom(roomObject, selectedEditRoom?.id));
                    dispatch(changeChatEnabled(selectedEditRoom?.id, createChatEnabled ?? selectedEditRoom.chatEnabled));
                    history.push('/');
                } catch (error) {
                    Notify.error(error?.response?.data?.error_description || t('CHANGES_FAIL'));
                    setSubmitButtonDisabled(false);
                }
            } else {
                try {
                    if (!checkRoomScheduling(startDate, timezoneValue)) {
                        setShowScheduleWarn(true);
                        setSubmitButtonDisabled(false);
                        return;
                    }
                    dispatch(createRoom(roomObject));
                } catch (error) {
                    Notify.error(error?.response?.data?.error_description || t('FAIL_CREATE_ROOM'));
                    setSubmitButtonDisabled(false);
                }
            }
        }
    };

    useEffect(() => {
        if (shouldActiveCaptionTab) {
            setActiveTab(1);
        }
    }, [shouldActiveCaptionTab]);

    return (
        <VideoTabs active={activeTab} onTabChange={setActiveTab}>
            <VideoTabs.Tab label={t(languageConstants.VIDEO_DETAILS)}>
                <Container>
                    <Modal
                        isOpen={showScheduleWarn}
                        handleModal={() => setShowScheduleWarn(false)}
                        title={t(languageConstants.WARNING)}
                        featuredTitle={t(languageConstants.CHECK_DATA)}
                    >
                        <div className="--warn-content">
                            <h5>{t(languageConstants.MAX_SCHEDULE_TIME)}</h5>
                            <button
                                type="button"
                                onClick={() => setShowScheduleWarn(false)}
                            >
                                OK
                            </button>
                        </div>
                    </Modal>
                    <CourseVideoForm
                        roomName={roomName}
                        setRoomName={setRoomName}
                        roomDescription={roomDescription}
                        setRoomDescription={setRoomDescription}
                        roomEditMode={roomEdit}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        timezoneValue={timezoneValue}
                        setTimezoneValue={setTimezoneValue}
                        uploadedMultipleFiles={uploadedMultipleFiles}
                        setUploadedMultipleFiles={setUploadedMultipleFiles}
                        selectedChannel={selectedChannel}
                        handleSubmitForm={handleNewRoom}
                        userData={user}
                        showSkeleton={showSkeleton}
                        submitButtonDisabled={submitButtonDisabled}

                    />
                </Container>
            </VideoTabs.Tab>
            {roomEdit && status === 'done' && (
                <VideoTabs.Tab label={t(languageConstants.CAPTIONS)}>
                    <CaptionsTableContainer>
                        <CaptionsTable />
                    </CaptionsTableContainer>
                </VideoTabs.Tab>
            )}
        </VideoTabs>
    );
};

export default CourseRecordedForm;
