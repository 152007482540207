import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 286px; 
`;
export const VideoTypeOption = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #242441;
    width: 260px;
    height: 200px;
    border-radius: 8px;
    background-color: #242441;
    position: relative;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.1s ease-in-out;

    img {
        margin-bottom: 40px;
        filter: grayscale(1) opacity(0.2);
        transition: all 0.1s ease-in-out;
    }

    span {
        transition: all 0.1s ease-in-out; 
    }

    &:hover {
        box-shadow: 0px 0px 10px -4px #000000;
        opacity: 1;
        transform: scale(1.02);

        img {
            filter: none;
        }
        
        span {            
            background: linear-gradient(270deg, rgba(255,166,75,1) 0%, rgba(255,74,136,1) 100%);      
        }
    }

    &:last-child {
        margin-left: 40px;
    }
`;

export const VideoTypeOptionDisabled = styled(VideoTypeOption)`
    opacity: 0.2;
    cursor: not-allowed;
    
    img {
        background: none;
    }
    
    &:hover {
        box-shadow: unset;
        opacity: 0.2;
        transform: unset;
        
        img {
            background: none;
        }
        
        span {            
            background: transparent;    
        }
    }
`;

export const VideoDescription = styled.div`
    width: 260px;
    height: 55px;    
    border-radius: 8px;
    background-color: #2c2c4a;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
`;

export const LiveVideo = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 154px;
    height: 52px;  
    border-radius: 3px;
    background: #35354c;  
    margin-bottom: 35px; 
`;

export const LiveVideoDisabled = styled(LiveVideo)`
    background: none;
`;

export const LiveVideoItem = styled.span`
    color: #FFF;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;    
`;
