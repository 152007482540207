import styled, { css } from 'styled-components';

export const AccordionContainer = styled.div`
    margin-bottom: 2rem;

    .questionHeading, .questionsBody {
        div {
            margin: 0 0.5rem;
        }
    }

    .questionHeading {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        color: #fff;

        h4 {
            margin-bottom: 0.5rem;
            text-transform: uppercase;
        }

        .cont-select-quiz {
            padding-bottom: 0.7rem;
            select{
                overflow-y: scroll;
            }
        }
    }

    .questionsBody {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        color: #fff;

        h4 {
            margin-bottom: 0.5rem;
            text-transform: uppercase;

            span {
                color: #ff8e63;
                font-size: 0.7rem;
            }
        }

        & input {
            height: 61px;
        }
    }
`;

export const QuestionContent = styled.div`
    ${({ disabledFields }) => disabledFields === true
        && css`
            pointer-events: none;
            opacity: 0.1;
        `}
`;

export const SelectOptions = styled.select`
    width: 100%;
    border-radius: 8px;
    padding: 0.7rem 2rem;
    background-color: #1c1b2d;
    color: #fff;
    border: 0;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    min-height: 40px;
    background: #1c1b2d url("../../../assets/arrow-down.svg") no-repeat right
        center;

    ${({ disabledFields }) => disabledFields === true
        && css`
            pointer-events: none;
            opacity: 0.1;
        `}
`;

export const CustomTextArea = styled.textarea`
    color: #fff;
    font-weight: bold;
    border-radius: 8px;
    background-color: #1c1b2d;
    border: 1px solid #24243e;
    font-size: 16;
    width: 100%;
    padding: 10px 12px;
    resize: none;

    &:focus-within {
        border: 1px solid #ff617a;
    }
`;

export const ContentLabelTextArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
        span {
            color: #ff8e63;
            font-size: 0.7rem;
        }
    }
`;

export const WarnContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;

    h5 {
        font-size: 18px;
        color: #fff;
        text-align: center;
    }

    button {
        border: none;
        color: #fff;
        background: linear-gradient(90deg, #ffac47 0%, #ff438d 100%);
        width: 300px;
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        font-weight: 600;
        border-radius: 8px;
        font-weight: bold;
        text-transform: uppercase;
        opacity: 0.8;
        transition: all 0.1s ease-in-out;

        &:hover {
            box-shadow: 0px 0px 10px -4px #000000;
            opacity: 1;
            transform: scale(1.02);
        }
    }
`;

export const NoAnswersCorrectAnswer = styled.input`
    background-color: #1c1b2d;
    border-radius: 8px;
    padding: 0.7rem 2rem;
    text-overflow: ellipsis;
    min-height: 40px;
    border: 0;
    outline: 0;
    color: #fff;
`;
