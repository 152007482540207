import api from '@src/services/api';
import { b64toBlob } from '@src/utils/functions';
import Notify from '@src/utils/notification';

export async function downloadXlsx(
    outputFileName = 'excel_file.xlsx',
    notifyErrorMessage = '',
) {
    try {
        const response = await api.downloadImportCSVSample();
        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const excelFile = b64toBlob(response.bytes, contentType);
        const url = window.URL.createObjectURL(excelFile);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        Notify.error(notifyErrorMessage);
    }
}
