import React, { useEffect, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ptBR, enUS, es } from 'date-fns/locale';
import { DatePickerContainer } from './styles';
import CalendarIcon from '../../assets/calendar.svg';

const DatePicker = ({
    id,
    setVariable,
    valueVariable,
    dateMask,
    timeLabel,
    language,
    withPortal,
    fontColorInput,
    maxDate,
    marginTopImg,
    inputHeight,
    showTimeInput = true,
    timeMask = 'HH:mm',
    inline = false,
    selectsRange = false,
    showYearDropdown = false,
    showMonthDropdown = false,
    minDate,
    placeholder,
}) => {
    const [date, setDate] = useState(valueVariable);

    const handleDate = () => {
        setVariable(date);
    };

    const handleChange = value => {
        setDate(value);
    };

    function registerUserLocale(userLocale) {
        const type = {
            'pt-BR': ptBR,
            'en-US': enUS,
            'es-ES': es,
        };
        registerLocale(type[userLocale], type[userLocale]);
    }
    function getLocale(userLocale) {
        const type = {
            'pt-BR': ptBR,
            'en-US': enUS,
            'es-ES': es,
        };
        return type[userLocale];
    }

    useEffect(() => {
        registerUserLocale(language);
    }, [language]);

    return (
        <DatePickerContainer marginTopImg={marginTopImg} inputHeight={inputHeight} fontColorInput={fontColorInput}>
            <label htmlFor={id || ''}>
                <ReactDatePicker
                    id={id || ''}
                    onChange={date => handleChange(date)}
                    onCalendarClose={() => handleDate()}
                    selected={date}
                    dateFormat={dateMask}
                    timeInputLabel={timeLabel}
                    locale={getLocale(language)}
                    timeFormat={timeMask}
                    showTimeInput={showTimeInput}
                    shouldCloseOnSelect
                    maxDate={maxDate}
                    minDate={minDate}
                    withPortal={withPortal}
                    inline={inline}
                    selectsRange={selectsRange}
                    showYearDropdown={showYearDropdown}
                    showMonthDropdown={showMonthDropdown}
                    placeholderText={placeholder}
                />
                {!inline ? <img src={CalendarIcon} alt="Calendar Icon" /> : ''}
            </label>
        </DatePickerContainer>
    );
};

export default DatePicker;
