import styled from 'styled-components';

const theme = {
    color: '#fff',
    backgroundColor: '#212032',
    margin: '30px',
    arrowSize: '6px',
};

export const TooltipWrapper = styled.div`
    display: inline-block;
    position: relative;

    .Tooltip-Tip-Engagement {
        position: absolute;
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%);
        padding: 6px;
        color: ${theme.color};
        background: ${theme.backgroundColor};
        font-size: 14px;
        font-family: sans-serif;
        line-height: 1;
        z-index: 100;
    }

    .Tooltip-Tip-Engagement::before {
        content: ' ';
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: ${theme.arrowSize};
        margin-left: calc(${theme.arrowSize} * -1);
    }

    .Tooltip-Tip-Engagement.top {
        top: calc(${theme.margin} * -2.3);
        left: -.8rem;
        width: 14rem;
        min-height: 3.3rem;
    }

    .Tooltip-Tip-Engagement.top::before {
        top: 100%;
        border-top-color: ${theme.backgroundColor};
    }
`;
