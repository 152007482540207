import ButtonHeader from '@src/components/ButtonHeader';
import React, { useEffect, useState } from 'react';
import useRooms from '@src/hooks/useRooms';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    clearEditSelected,
    fetchEditRoom,
    fetchEditRoomLinks,
    getChannelRooms,
} from '@src/store/modules/rooms/actions';
import useAuth from '@src/hooks/useAuth';
import { useSidebar } from '@src/hooks/useSidebar';
import AddCaptionModal from '@src/components/AddCaptionModal';
import { getCaptions, toggleAddCaptionUploadModal } from '@src/store/modules/captions/actions';
import languageConstants from '@langs/constants';
import { useTranslation } from 'react-i18next';
import plusIcon from '@src/assets/plus-icon.svg';
import arrowIcon from '../../assets/arrow.svg';
import CourseLiveUpload from './CourseLiveUpload';
import CourseLiveForm from './CourseLiveForm';
import {
    CreateCourseContainer,
    CreateCourseContent,
    CreateCourseFormArea,
    CreateCourseHeader,
    CreateCourseHeaderTitle,
} from './styles';

const CourseLive = () => {
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [createChatEnabled, setCreateChatEnabled] = useState(false);
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [uploadedFile, setUploadedFile] = useState({});
    const [primaryBroadcastingUrl, setPrimaryBroadcastingUrl] = useState('');
    const [secondaryBroadcastingUrl, setSecondaryBroadcastingUrl] = useState('');
    const [primaryStreamName, setPrimaryStreamName] = useState('');
    const [secondaryStreamName, setSecondaryStreamName] = useState('');
    const [streamName, setStreamName] = useState('');
    const [technology, setTechnology] = useState('');
    const [isFirstFetch, setIsFirstFetch] = useState(true);

    const dispatch = useDispatch();
    const history = useHistory();
    const { id: roomId } = useParams();
    const { t } = useTranslation();
    const {
        selectedEditRoom,
        selectedEditRoomLinks,
        selectedChannel,
        selectedChannelRooms,
        status,
        loading,
    } = useRooms();

    const { user } = useAuth();
    const { setIsMenuOpen } = useSidebar();

    const showSkeleton = loading && isFirstFetch;

    useEffect(() => {
        setCreateChatEnabled(false);
        setThumbnailUrl('');
        setUploadedFile({});
        setPrimaryBroadcastingUrl('');
        setSecondaryBroadcastingUrl('');
        setPrimaryStreamName('');
        setSecondaryStreamName('');
        setStreamName('');
        setTechnology('');

        return () => {
            if (window.revokeObjectURL) {
                URL.revokeObjectURL(uploadedFile.preview);
            }
            dispatch(clearEditSelected());
        };
    }
    // eslint-disable-next-line
    , [])

    useEffect(() => {
        if (selectedChannel?.id) {
            dispatch(getChannelRooms(selectedChannel.id));
        }
    }, [selectedChannel, dispatch]);

    useEffect(() => {
        if (roomId) {
            const foundRoom = selectedChannelRooms.find(item => item.id === roomId);
            setSelectedRoom(foundRoom);
        }
    }, [history, roomId, selectedChannelRooms, selectedEditRoom, selectedRoom]);

    useEffect(() => {
        if (selectedRoom && roomId) {
            dispatch(fetchEditRoom(roomId, selectedRoom));
        }
    }, [dispatch, roomId, selectedRoom, user]);

    useEffect(() => {
        if (primaryBroadcastingUrl === '' && secondaryBroadcastingUrl === '') {
            const checkEditRoomChange = setInterval(() => {
                setIsFirstFetch(false);
                if (selectedRoom && roomId) {
                    dispatch(fetchEditRoomLinks(roomId, selectedRoom));
                }
            }, 10000);

            return () => {
                clearInterval(checkEditRoomChange);
            };
        }
    }, [dispatch, primaryBroadcastingUrl, secondaryBroadcastingUrl, roomId, selectedRoom]);

    useEffect(() => {
        if (selectedEditRoom && roomId) {
            setThumbnailUrl(selectedEditRoom?.image);
            setCreateChatEnabled(selectedEditRoom?.chatEnabled);
            setPrimaryBroadcastingUrl(selectedEditRoom?.primaryBroadcastingUrl);
            setSecondaryBroadcastingUrl(selectedEditRoom?.secondaryBroadcastingUrl);
            setPrimaryStreamName(selectedEditRoom?.primaryStreamName);
            setSecondaryStreamName(selectedEditRoom?.secondaryStreamName);
            setStreamName(selectedEditRoom?.streamName);
            setTechnology(selectedEditRoom?.technology);
        }
    }, [selectedEditRoom, roomId]);

    useEffect(() => {
        if (selectedEditRoomLinks) {
            setPrimaryBroadcastingUrl(selectedEditRoomLinks?.primaryBroadcastingUrl);
            setSecondaryBroadcastingUrl(selectedEditRoomLinks?.secondaryBroadcastingUrl);
            setPrimaryStreamName(selectedEditRoomLinks?.primaryStreamName);
            setSecondaryStreamName(selectedEditRoomLinks?.secondaryStreamName);
        }
    }, [selectedEditRoomLinks]);

    useEffect(() => {
        if (Object.keys(uploadedFile).length) {
            setThumbnailUrl(uploadedFile.url);
        }
    }, [uploadedFile, setThumbnailUrl]);

    function handleShowUploadCaption() {
        dispatch(toggleAddCaptionUploadModal(true));
    }

    const handleBackToVideos = () => {
        history.push('/');
    };

    useEffect(() => {
        setIsMenuOpen(false);
    }, [setIsMenuOpen]);

    useEffect(() => {
        if (roomId && status === 'done') {
            dispatch(getCaptions(roomId, true));
        }
    }, [dispatch, roomId, status]);

    return (
        <CreateCourseContainer>
            <CreateCourseContent>
                <CreateCourseHeader>
                    <CreateCourseHeaderTitle>{t(languageConstants.LIVE_SETTINGS)} </CreateCourseHeaderTitle>
                    <div className="header-buttons-holder">
                        {
                            selectedEditRoom?.id && status === 'done' && (
                                <ButtonHeader
                                    onClick={handleShowUploadCaption}
                                    icon={plusIcon}
                                    title={t(languageConstants.NEW_CAPTION)}
                                />
                            )
                        }
                        <ButtonHeader
                            onClick={handleBackToVideos}
                            icon={arrowIcon}
                            title={t(languageConstants.BACK_TO_VIDEOS)}
                        />
                    </div>
                </CreateCourseHeader>
                <CreateCourseFormArea>
                    <CourseLiveUpload
                        thumbnailUrl={thumbnailUrl}
                        setThumbnailUrl={setThumbnailUrl}
                        uploadedFile={uploadedFile}
                        setUploadedFile={setUploadedFile}
                        createChatEnabled={createChatEnabled}
                        setCreateChatEnabled={setCreateChatEnabled}
                        primaryBroadcastingUrl={primaryBroadcastingUrl}
                        secondaryBroadcastingUrl={secondaryBroadcastingUrl}
                        primaryStreamName={primaryStreamName}
                        secondaryStreamName={secondaryStreamName}
                        streamName={streamName}
                        roomEditMode={roomId}
                        technologyType={technology}
                        selectedEditRoom={selectedEditRoom}
                        showSkeleton={showSkeleton}
                    />
                    <AddCaptionModal />
                    <CourseLiveForm
                        roomId={roomId}
                        setThumbnailUrl={setThumbnailUrl}
                        thumbnailUrl={thumbnailUrl}
                        uploadedFile={uploadedFile}
                        setUploadedFile={setUploadedFile}
                        createChatEnabled={createChatEnabled}
                        showSkeleton={showSkeleton}
                    />
                </CreateCourseFormArea>
            </CreateCourseContent>
        </CreateCourseContainer>
    );
};

export default CourseLive;
