import styled, { css } from 'styled-components';

export const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 0.8em;
    width: 100%;
    background-color: #1c1b2d;

    .searchAndFilter {
        display: flex;
        width: 100% ;
    }

    .buttonSettings {
        display: flex;
    }

    .buttonOptions{
        display: flex;
    }
    
    input {
        background-color: #29283f;
        border: none;
        outline: none;
        letter-spacing: 0.38px;
        padding: 0 10px;
        font-weight: bold;
        color: #fff;
        width: 100%;
        ::placeholder {
            color: #ffffff8f;
            font-weight: bold;
        }
    }

    .filteringContent {
        width: 720px;
        display: flex;
        justify-content: center;
        background: green;
    }

    &.mobile {
        height: 36px;
        &.focus {
            transform: none;
        }

        input {
            height: 34px;
        }

        button {
            width: 27px;
            height: 25px;
            img {
                width: 15px;
                height: 15px;
            }
        }
    }

    @media screen and (max-width: 1100px) {
        display: flex;
        flex-direction: column;
        
        .buttonSettings {
            display: flex;
            width: 100%;
            margin-top: 1rem;
        }

        .buttonOptions {
            display: flex;
            width: 100%;
            margin-top: 1rem;
        }
    }
`;
export const InputSearch = styled.div`
    background-color: #29283f;
    display: flex;
    justify-content: space-between;
    padding: 0.6em;
    border-radius: 8px;
    width: 100%;
    transition: all 0.4s ease ;

    ${({ isFocused }) => (
        isFocused && css`
            min-width: 400px;
        `)
};

    .clearIcon {
        width: 16px;
        margin: 0 1rem;
        cursor: pointer;
    }

    .imgSearch {
        width: 30px;
        height: 28px;
        margin: 0 11px 0 0;
        padding: 6px 6px 6px 7px;
        border-radius: 7px;
        background-color: #1f1e34;

        img {
            height: 16px;
        }
    }

    input {
        &::placeholder {
            text-transform: uppercase;
        }
    }

    button {
        width: 114px;
        height: 28px;
        border: none;
        outline: none;
        letter-spacing: 0.38px;
        padding: 0 10px;
        font-weight: bold;
        color: #fff;
        border-radius: 7px;
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
        text-transform: uppercase;
    }
`;

export const DropdownButtonWrapper = styled.div`
    outline: none;
    cursor: pointer;
    small {
        font-size: 10px;
        color: #fff;
        margin-left: 4px;
    }
`;

export const DropdownContainer = styled.div`
    position: relative;
    .left {
        left: 0;
    }

    .right {
        right: 0;
    }

    .top {
        top: 100%;
    }

    .bottom {
        bottom: 100%;
        margin-bottom: 10px;
    }
    max-width: 300px;
    padding: 0 0.5rem;
    height: 43px;
    border-radius: 8px;
    background-color: #29283f;
    margin: 0 2em;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    img {
        margin-left: 5px;
    }
`;

export const DropdownList = styled.div`
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #29283f;
    display: block;
    z-index: 99;
    position: absolute;
    min-width: 100%;
    box-shadow: 0px 0px 10px -6px #000000;
    max-height: 50vh;
    overflow-y: scroll;
    margin-top: 2px;

    li {
        list-style: none;
        padding: 10px;
        color: #d5d5d5d5;
        font-weight: bold;
        position: relative;
        &:not(:last-child)::after {
            content: "";
            height: 1px;
            position: absolute;
            bottom: 0;
            background-color: #302f3d;
            width: 90%;
            left: 0;
            margin-left: 5%;
        }
        &:first-child {
            margin-top: 5px;
        }
        &:last-child {
            margin-bottom: 5px;
        }
        &:hover {    font-size: clamp(10px,0.2em + 1vw,1em);
            cursor: pointer;
            color: #ff8f63;
        }
    }
`;

export const SectionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    img {
        transition: all 0.1s ease-in-out;
        width: 12px;
    }

    p {
        font-size: clamp(8px, 0.2em + 0.6vw, 1em);
        font-weight: bold;
        margin-right: 10px;
        color: #fff;
        text-transform: uppercase;
        
        &.emphasis {
            margin-left: 4px;
            color: #fff;
            font-size: 12px;
            font-weight: bold;

             @media screen and (max-width: 900px) {
            font-size: 11px;
                
        }
        }
    }
    .--open {
        transform: rotate(180deg);
    }

    @media screen and (max-width: 720px) {
        small {
            display: none;
        }
    }
`;

export const Button = styled.button`
    .left {
        left: 0;
    }

    .right {
        right: 0;
    }

    .top {
        top: 100%;
    }

    .bottom {
        bottom: 100%;
        margin-bottom: 10px;
    }
    min-width: 140px;
    width: 100% ;
    height: 43px;
    padding: 0.4em 0.5em;
    border-radius: 8px;
    background-color: #29283f;
    margin: 0 2em 0 2em;
    position: relative;
    text-transform: uppercase;
    border-radius: 12px;
    border: 0;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #ffffff;

    &:hover {
        box-shadow: 0px 0px 10px -4px #000000;
    }

    p {
        margin-left: 0.7em;
    }

    &.active {
        position: relative;
        top: 1px;
    }
    .iconButton {
        position: absolute;
        width: 2.3em;
        height: 2.3em;
        margin: -0.5em 0 0 -2em;
        border-radius: 7px;
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
        display: flex;
        align-items: center;
        justify-content: center;

    }

    @media (max-width: 650px) {
        margin-top: 0.5em;
        padding: 8px 8px;
        font-size: 12px;
    }
`;

export const MoreOptionsList = styled.div`
    display: block;
    z-index: 99;
    position: absolute;
    min-width: 100%;
    max-height: 50vh;
    margin-top: 2px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #34334e;

    li {
        list-style: none;
        padding: 10px;
        font-weight: bold;
        position: relative;
        display: flex;
        margin: 0 10px 0 7px;
        white-space: nowrap;
        font-size: clamp(8px, 1rem, 1.2em);

        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.34px;

        color: #fff;
        transition: transform 300ms cubic-bezier(0, 1, 0, 1);
        text-transform: uppercase;

        img {
            margin: 0 1em;
            filter: grayscale(1) brightness(4.5);

            &.emphasis {
                filter: brightness(4.5);
            }
        }
        &:hover {            
            img {                
                filter: none;
            }
        }
        &:active {
            transform: scale(1);
            img {
                &:active {
                    transform: scale(1);
                }
            }
        }

        &:not(:last-child)::after {
            content: "";
            height: 2px;
            position: absolute;
            bottom: 0;
            background-color: #1e1d30;
            width: 90%;
            left: 0;
            margin-left: 5%;
        }
        &:first-child {
            margin-top: 5px;
        }
        &:last-child {
            margin-bottom: 5px;
        }
        &:hover {
            cursor: pointer;
            color: #fe8f63;
        }
    }
`;

export const SectionContainerMoreOptions = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    img {
        transition: all 0.1s ease-in-out;
        position: absolute;
        right: 5px;
        top: 14px;
    }

    p {
        font-size: clamp(11px, 1rem, 1em);
        font-weight: bold;
        margin-right: 10px;
        color: #fff;
        text-transform: uppercase;
        &.emphasis {
            margin-left: 4px;
            color: #fff;
        }
    }
    .--open {
        transform: rotate(180deg);
    }
`;
