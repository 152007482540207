import AttachIconPdf from './file-pdf.svg';
import AttachIconSvg from './file-csv.svg';
import AttachIconXls from './file-xls.svg';

export default {
    PDF: AttachIconPdf,
    CSV: AttachIconSvg,
    XLSX: AttachIconXls,
    XLS: AttachIconXls,
};
