import { all, put, takeLatest } from 'redux-saga/effects';
import api from '../../../services/api';
import { defaultErrorHandler } from '../../utilities';
import * as quizzesActions from './actions';
import actions from '../../../utils/actions';
import { translate } from '../../../utils/functions';

function* getQuestions({ payload }) {
    try {
        const { questions } = yield api.getQuestions(payload.roomId);
        yield put(quizzesActions.getQuizzesSuccess(questions));
    } catch (error) {
        defaultErrorHandler(error, { default: translate('GET_QUIZZES_FALURE') });
    }
}

export default all([
    takeLatest(actions.QUIZZES_REQUEST, getQuestions),
]);
