import React, { useRef, useEffect } from 'react';
import { select, geoPath, geoMercator, scaleQuantize } from 'd3';
import { Container } from './styles';

function GeoChart({ data, property }) {
    const svgRef = useRef();
    const wrapperRef = useRef();


    // will be called initially and on every data change
    useEffect(() => {
        const svg = select(svgRef.current);
        const uniqueAudienceArr = [];

        property.data.map((country) => uniqueAudienceArr.push(country.view_unique_audience));

        const minProp = Math.min(...uniqueAudienceArr);
        const maxProp = Math.max(...uniqueAudienceArr);

        const colorScale = scaleQuantize()
            .domain([minProp, maxProp])
            .range(['#824C28', '#B06634', '#F99652']);


        // but fall back to getBoundingClientRect, if no dimensions yet.
        const { width } = wrapperRef.current.getBoundingClientRect();

        // projects geo-coordinates on a 2D plane
        const projection = geoMercator()
            .fitSize([width, 500], data)
            .precision(100);

        // takes geojson data,
        // transforms that into the d attribute of a path element
        const pathGenerator = geoPath().projection(projection);

        // const fillHandler = (data, feature) => {
        //     const country = data.find(el => el.country === feature.properties.name);
        //     return country ? country.view_unique_audience : 0;
        // };

        const countryColorFill = (data, feature) => {
            const country = data.find(el => el.country === feature.properties.name);
            return country ? colorScale(country.view_unique_audience) : '#1C1B2D';
        };

        // render each country
        svg
            .selectAll('.country')
            .data(data.features)
            .join('path')
        // .on("click", feature => {
        // setSelectedCountry(selectedCountry === feature ? null : feature);
        // })
            .attr('class', 'country')
            .transition()
            .attr('fill', feature => countryColorFill(property.data, feature))
            .attr('d', feature => pathGenerator(feature));

        // render text
        svg
            .selectAll('.label')
            .data([])
            .join('text')
            .attr('class', 'label')
            .text(
                feature => feature
          && `${feature.properties.name
          }: ${
              feature.properties[property].toLocaleString()}`,
            )
            .attr('x', 10)
            .attr('y', 25);
    }, [data, property]);

    return (
        <Container ref={wrapperRef} style={{ marginBottom: '2rem' }}>
            <svg ref={svgRef} />
        </Container>
    );
}

export default GeoChart;
