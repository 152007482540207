import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    flex-flow: column nowrap;
    overflow: auto;
    padding: 0 4em 25px 25px;
    margin-bottom: 20px;
    td {
        img {
            width: 33px;
        }
    }

    .loader {
        z-index: 999;
        top: 50%;
        left: 50%;
        position: absolute;
    }
    .loaderButton {
        top: 0;
        margin-top: 5px;
        position: absolute;
    }

    .MuiCircularProgress-colorPrimary {
        color: #ffffff;
    }

    .containerTable {
        margin: 1.5em 0;
        margin-bottom: 0;
        max-height: 70vh;
        overflow: auto;
        border-radius: 8px;

        @media (max-width: 650px) {
            max-height: 60vh;
        }
    }

    .buttons-actions {
        display: flex;
        align-items: center;
        justify-content: space-between ;
        padding: 0;
        width: 100%;
    }

    @media (max-width: 650px) {
        padding: 0 1em;
    }
`;

export const AccordionHeader = styled.div`
    background-color: #212032;
    color: #fff;
    width: 100%;
    padding: 1rem 1rem;
    border-radius: 0.5rem;
    margin: 0 auto;

    div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            text-transform: uppercase;

            span {
                color: #ff8e63; 
            }
        }
    }
`;

export const WarnContent = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;

        h5 {
            font-size: 18px;
            color: #fff;
            text-align: center
        }

        button {
            border: none;
            color: #FFF;
            background: linear-gradient(90deg,#FFAC47 0%, #FF438D 100%);
            width: 300px;
            height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-weight: 600;
            border-radius: 8px;  
            font-weight: bold;
            text-transform: uppercase; 
            opacity: 0.8;
            transition: all 0.1s ease-in-out;

            &:hover {
                box-shadow: 0px 0px 10px -4px #000000;
                opacity: 1;
                transform: scale(1.02);
            }
        }
`;

export const QuizStatusText = styled.p`
    margin-left: 5px;
   color: ${({ isPublished }) => (isPublished ? '#2a938e' : '#ff617a')} 
`;

export const CustomInput = styled.input`
    color: #fff;
    font-weight: bold;
    border-radius: 8px;
    background-color: #1c1b2d;
    border: 1px solid #24243e;
    font-size: 16;
    width: 100%;
    padding: 10px 12px;

    ${({ disabledFields }) => disabledFields === true && css`
        pointer-events:none;
        opacity: 0.1;
    `}  
    
    &:focus-within {
        border: 1px solid #ff617a;
    }
`;

export const ErrorMessage = styled.p`
    color:  #ff617a;
`;

export const QuestionsSections = styled.div`
    margin-bottom: 1rem;
    
`;

export const SelectOptions = styled.select`
    width: 100%;
    border-radius: 8px;
    padding: 0.7rem 2rem;
    background-color: #1c1b2d;
    color: #fff;
    border: 0;
    outline: 0;
    text-transform: uppercase;
    -webkit-appearance: none; 
    -moz-appearance: none;

    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    min-height: 40px;
    background: #1c1b2d url("../../../assets/arrow-down.svg") no-repeat right center;

    ${({ disabledFields }) => disabledFields === true && css`
        pointer-events:none;
        opacity: 0.1;
    `} 
`;

const inputWidth = '50%';
export const AccordionContent = styled.div`
    width: 100%;
    background-color: #272637;
    padding: 1rem;
    display: flex;
    color: #fff;
    margin-bottom: 1rem;

    & div:nth-child(1) {
        width: ${inputWidth};
    }

    & div:nth-child(2) {
        width: calc(${inputWidth} / 2);
    }

    & div:nth-child(3) {
        width: calc(${inputWidth} / 2);
    }

    div {
        h4 {
            white-space: nowrap;
            margin-bottom: 0.5rem;

        }
    }


    @media screen and (max-width: 1024px){
        display: flex;
        flex-direction: column;
        width: 100%;

        & div:nth-child(1) {
            width: 100%;
        }

        & div:nth-child(2) {
            width: 100%;

        }

        & div:nth-child(3) {
            width: 100%;

        }

        div {
            width: 100%;
            h4 {
                font-size: 12px;
            }
        }
    }

    div {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 0.5rem;

        

        span {
            margin-bottom: 0.2rem;
        }
    }
`;

export const QuestionsNumber = styled.select`
    color: #fff;
    font-weight: bold;
    border-radius: 8;
    background-color: #1c1b2d;
    border: 1px solid #24243e;
    font-size: 16;
    width: 100%;
    padding: 10px 12px;
    margin: auto;
    height: 41px;
`;

export const PageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
        font-size: clamp(18px, 0.5em + 1vw, 1.9em);
        color: white;
        text-transform: uppercase;
    }

    button {
        border-width: 0px;
        display: flex;
        align-self: flex-end;
        height: 47px;
        border-radius: 12px;
        background-color: #212032;
        color: #ffffff;
        font-weight: bold;
        font-size: clamp(12px, 0.4em + 1vw, 1.2em);
        align-items: center;
        padding: 0 15px;
        text-transform: uppercase;
        transition: all 0.1s ease-in-out;
        &:hover {
            box-shadow: 0px 0px 10px -4px #000000;
            transform: scale(1.01);
        }
        &:disabled {
            opacity: 0.4;
        }
    }

    small {
        margin-left: -15px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        width: 34px;
        border-radius: 7px;
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
        background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
    }

    .quiz-toggle {
        display: flex;
        color: #fff;
        background-color: #212032;
        padding: 0.5rem;
        border-radius: 12px;

        .quizz-toggle-status {
            margin-left: 1rem;
            display: flex;
            text-transform: uppercase;
        }
    }
`;

export const QuizTable = styled.div`
    width: 100%;
    padding: 0 0 1px;
    border-radius: 8px;
    background-color: #212032;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #ffffff;
    max-width: 100%;

    th {
        background-color: #1c1b2d;
        padding: 12px 0.4em;
        text-transform: uppercase;
        &:first-child {
            padding-left: 18px;
        }
    }

    tr {
        cursor: pointer;
        background-color: #212032;
        &:nth-child(even) {
            background-color: #242339;
        }

        &:not(:last-child):hover {
            background-color: #2f2e3e;
        }

        .line-controls {
            display: flex;
            justify-content: flex-end;
        }

        td {
            padding: 10px 0.5em;
            &:first-child {
                padding-left: 18px;
            }

            button {
                &.action-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    color: #ffffff;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 12px;
                    padding: 5px 10px 5px 0;

                    &:hover {
                        color: #ff9460;
                    }
                    img {
                        margin-right: 5px;
                        width: 16px;
                    }
                }
                &.edit {
                    margin-right: 15px;
                }
            }
        }
    }

    .MuiTableCell-root.MuiTableCell-body {
        border-bottom: none;
    }

    .MuiPaginationItem-ellipsis {
        color: #fff;
    }

    .pagination {
        display: flex;
        justify-content: flex-end;

        button {
            color: #ffffff;
        }
        .MuiPaginationItem-textPrimary.Mui-selected {
            background-color: #ff6379;
        }
    }
    .table-footer {
        background-color: #1c1b2d;
        td {
            padding: 12px 0.4em;
        }

        span {
            font-size: 12px;
        }

        select {
            background: #24243e;
            border: 0;
            border-radius: 6px;
            letter-spacing: 0.21px;
            color: #ffffff;
            margin: 0 1em;
            height: 2em;

            &:focus {
                border: solid 1px #ff6479;
            }
        }
    }
    .in-progress {
        color: #ffcc63;
    }

    .disabled {
        color: #ff6363;
    }

    .finished {
        color: #31a6a1;
    }

    @media (max-width: 650px) {
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0;
        @media (max-width: 650px) {
            max-height: 40vh;
        }
    }
`;

export const TableFooterResults = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .table-footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.8rem 1rem;
        border-radius: 0 0 8px 8px;
        background-color: #1c1b2d;
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        color: #ffffff;
    }
    span {
        font-size: 0.8rem;
        min-width: 200px;
        text-align: center;
        align-self: center;
    }
`;

export const LoadMoreButtonSection = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    button {
        color: #ff9162;
        margin: 5px auto;
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 387px;
        border: 0;
        background: transparent;
        font-size: 1.1rem;
        margin: 0 auto;
        color: #fff;
        background: linear-gradient(
            90deg,
            rgba(255, 174, 70, 1) 0%,
            rgba(255, 66, 141, 1) 100%
        );
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 3;
        letter-spacing: 0.21px;
        border-radius: 8px;
        text-transform: uppercase;

        &:disabled {
            opacity: 0.2;
        }
    }
`;

export const Form = styled.form`
    padding: 20px 0px;
    max-height: 85vh;
    overflow-y: scroll;

    .MuiFormControl-root {
        margin-top: 10px;
        width: 100%;
    }

    .MuiInputBase-root {
        textarea {
            height: 75px;
            resize: none;
        }
    }

    .MuiFormLabel-root {
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;

        &.Mui-focused {
            color: #ff6478;
        }
    }

    .switch-wrapper {
        display: flex;
        color: #fff;
        height: 49px;
        border-radius: 6px;
        background-color: #111130;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 18px;

        .MuiFormControlLabel-label {
            margin-top: 2px;
            font-size: 12px;
            font-weight: bold;
            color: #ffffff;
        }

        .MuiSwitch-thumb {
            position: relative;
            &::before {
                content: "DES";
                position: absolute;
                color: #fff;
                font-size: 7px;
                top: 5px;
                left: 3px;
                font-weight: bold;
            }
        }

        .Mui-checked {
            .MuiSwitch-thumb {
                position: relative;
                &::before {
                    content: "PUB";
                }
            }
        }
    }

    .submit-button-wrapper {
        padding: 7px 25px;
    }

    .quiz-form-control-type {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        position: relative;
        z-index: 1;
    }

    .Dropdown-root {
        width: 100%;
    }

    .Dropdown-control {
        background-color: #24243e;
        border: none;
        border-radius: 8px;
        box-sizing: border-box;
        color: #fff;
        font-weight: bold;
        height: 41px;
        justify-content: center;
        align-items: center;
    }

    .Dropdown-arrow {
        border-color: #fff transparent transparent;
        top: 18px;
    }

    .Dropdown-menu {
        background-color: #24243e;
        border: none;
    }

    .Dropdown-option {
        color: #fff;
        font-weight: bold;
        &:hover {
            background-color: #34344c;
        }
    }

    .Dropdown-option.is-selected {
        background-color: #2c2c4a;
        color: #fff;
    }

    .--warn-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;

        h5 {
            font-size: 18px;
            color: #fff;
            text-align: center;
        }

        button {
            border: none;
            color: #fff;
            background: linear-gradient(90deg, #ffac47 0%, #ff438d 100%);
            width: 300px;
            height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-weight: 600;
            border-radius: 8px;
            font-weight: bold;
            text-transform: uppercase;
            opacity: 0.8;
            transition: all 0.1s ease-in-out;

            &:hover {
                box-shadow: 0px 0px 10px -4px #000000;
                opacity: 1;
                transform: scale(1.02);
            }
        }
    }

    .MuiInputLabel-animated.MuiInputLabel-shrink {
        font-size: 14px;
        small {
            color: #ff9754;
            font-size: 11px;
            margin-left: 8px;
            text-transform: uppercase;
        }
    }
    .Mui-disabled.Mui-disabled.MuiFormLabel-filled {
        small {
            opacity: 0.4;
        }
    }

    .MuiInputBase-input.Mui-disabled {
        opacity: 0.4;
    }

    .MuiFormLabel-root.Mui-disabled {
        color: #dddddd78;
    }
    .Dropdown-disabled {
        opacity: 0.4;
    }
`;

export const SubmitButton = styled.button`
    border: none;
    color: #fff;
    background: linear-gradient(90deg, #ffac47 0%, #ff438d 100%);
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border-radius: 8px;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.8;
    transition: all 0.1s ease-in-out;
    width: 100%;
    max-width: 387px;

    &:hover {
        box-shadow: 0px 0px 10px -4px #000000;
        opacity: 1;
        transform: scale(1.02);
    }

    &:disabled {
        opacity: 0.3;
        transform: none;
    }
`;

export const CancelButton = styled.button`
    color: #ff5d7b;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border-radius: 8px;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.8;
    transition: all 0.1s ease-in-out;
    border: 1px solid #ff5d7b;
    background-color: transparent;
    width: 100%;
    max-width: 387px;

    &:hover {
        box-shadow: 0px 0px 10px -4px #000000;
        opacity: 1;
        transform: scale(1.02);
    }

    &:disabled {
        opacity: 0.3;
        transform: none;
    }
`;
