import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    height:calc(100% - 11%);
    width: 100%;
    position: relative;
    flex-flow: column nowrap;
    padding: 1rem;
    overflow: auto;

    .--warn-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;

        h5 {
            font-size: 18px;
            color: #fff;
        }

        button {
            border: none;
            color: #FFF;
            background: linear-gradient(90deg,#FFAC47 0%, #FF438D 100%);
            width: 300px;
            height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-weight: 600;
            border-radius: 8px;  
            font-weight: bold;
            text-transform: uppercase; 
            opacity: 0.8;
            transition: all 0.1s ease-in-out;

            &:hover {
                box-shadow: 0px 0px 10px -4px #000000;
                opacity: 1;
                transform: scale(1.02);
            }
        }

    }

`;

export const PageHeader = styled.div`  
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        font-size: clamp(18px, 0.5em + 1vw, 1.9em); 
        color:white;
    }
`;

export const RoomsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
`;
