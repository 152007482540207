import actions from '../../../utils/actions';

const DEFAULT_STATE = {
    teams: [],
    isTeamsEnabled: false,
    loading: false,
};

export default function roomReducer(state = DEFAULT_STATE, { type, payload }) {
    switch (type) {
    case actions.GET_TEAMS_STATUS:
        return { ...state, loading: true };

    case actions.ASYNC_SET_TEAMS_STATUS:
        return { ...state, loading: true };

    case actions.SET_TEAMS_STATUS:
        return { ...state, loading: false, isTeamsEnabled: payload };

    case actions.GET_TEAMS:
        return { ...state, loading: true };

    case actions.ASYNC_CREATE_TEAMS:
        return { ...state, loading: true };

    case actions.SET_TEAMS:
        return { ...state, loading: false, teams: payload };

    default:
        return state;
    }
}
