/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-autofocus */

import React, { useEffect, useState } from 'react';
// import Searchbar from '../Searchbar'; vai ser implementado
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useBreadcrumbs } from '@src/hooks/useBreadcrumbs';
import ProfileSection from '../ProfileSection';
import {
    HeaderContainer,
    HeaderContent,
    HeaderLeft,
    HeaderMiddle,
    HeaderRight,
} from './styles';
import ProfileModal from './ProfileModal';
import MenuMobile from '../MenuMobile';
import api from '../../services/api';

import { store } from '../../store';
import { clearUserInfo } from '../../store/modules/auth/actions';
import { clearUsers, setLastEvaluateKey } from '../../store/modules/users/actions';
import { setChannelSelected } from '../../store/modules/rooms/actions';
import useRooms from '../../hooks/useRooms';
import { useSidebar } from '../../hooks/useSidebar';
import DropdownChannelSearch from './DropdownChannelSearch';
import Breadcrumbs from '../Breadcrumbs';

export function forcedLogoutExit() {
    api.clear();
    store.dispatch(clearUserInfo());
    localStorage.removeItem('KopaLive');
    window.location.replace('/');
}

function Header() {
    const { channelsListForSelectSidebar, selectedChannel } = useRooms();
    const [confirmBlock, setConfirmBlock] = useState(false);
    const [showTopHeaderContent, setShowTopHeaderContent] = useState(false);
    const [filteredChannelsList, setFilteredChannelsList] = useState();
    const [searchText, setSearchText] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    const { isMenuOpen } = useSidebar();
    const { breadcrumbs } = useBreadcrumbs();
    const route = useRouteMatch();

    function toggleTopHeaderContent() {
        setShowTopHeaderContent(!showTopHeaderContent);
    }

    function handleSelectChannel(channel) {
        setShowTopHeaderContent(false);
        localStorage.setItem('@selectedChannel', JSON.stringify(channel));
        dispatch(setLastEvaluateKey(''));
        dispatch(clearUsers());
        dispatch(setChannelSelected(channel));
        history.push('/');
    }

    function sortCourseList(courseList) {
        const sortedList = courseList.sort((a, b) => ((a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase())
            ? 1 : ((b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()) ? -1 : 0)));
        return sortedList;
    }

    function handleFilter(e) {
        setSearchText(e.target.value);
        const filteredList = sortCourseList(
            channelsListForSelectSidebar.filter((item) => {
                if (e.target.value === '') {
                    return item;
                } if (
                    item.name
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    || item.id
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())) {
                    return item;
                }
            }),
        );
        setFilteredChannelsList(filteredList);
    }

    function handleClearFilter() {
        setSearchText('');
        setFilteredChannelsList(sortCourseList(channelsListForSelectSidebar));
    }

    useEffect(() => {
        setFilteredChannelsList(sortCourseList(channelsListForSelectSidebar));
    }, [channelsListForSelectSidebar]);

    return (
        <HeaderContainer>
            <HeaderContent isMenuOpen={isMenuOpen}>
                <HeaderLeft>
                    {(route.path === '/tutorial' || route.path === '/playTutorials/:tutorialId') ? (
                        <Breadcrumbs
                            items={breadcrumbs}
                        />
                    )
                        : (
                            <DropdownChannelSearch
                                showTopHeaderContent={showTopHeaderContent}
                                setShowTopHeaderContent={setShowTopHeaderContent}
                                toggleTopHeaderContent={toggleTopHeaderContent}
                                selectedChannel={selectedChannel}
                                handleClearFilter={handleClearFilter}
                                searchText={searchText}
                                handleFilter={handleFilter}
                                filteredChannelsList={filteredChannelsList}
                                handleSelectChannel={handleSelectChannel}
                            />
                        )}
                </HeaderLeft>
                <HeaderMiddle />
                <HeaderRight data-tour="tour__user">
                    <ProfileSection
                        setConfirmBlock={setConfirmBlock}
                    />
                </HeaderRight>
            </HeaderContent>

            <MenuMobile
                setConfirmBlock={setConfirmBlock}
            />
            <ProfileModal
                confirmBlock={confirmBlock}
                setConfirmBlock={setConfirmBlock}
            />
        </HeaderContainer>
    );
}

export default Header;
