import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  padding-left: 80px;
  padding-top: 120px;
  height: 100%;  
  ${({ isMenuOpen }) => isMenuOpen && css`
        margin-left: 17em;
    `}     
   transition: cubic-bezier(0.075, 0.82, 0.165, 1) 150ms;
   
  @media (max-width: 800px) {
        padding-left: 0;
    }
`;
