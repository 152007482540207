/* eslint-disable no-unused-expressions */
import React, { createRef } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '@langs/constants';
import UploadIcon from '@src/assets/add-image-icon.svg';
import UploadMultipleIcon from '@src/assets/cloud-icon.svg';
import { DropContainer, UploadMessage } from './styles';

const dropzoneRef = createRef();
export const openDialog = () => {
    if (dropzoneRef.current) {
        dropzoneRef.current.open();
    }
};

function Upload({
    onUpload, multiple = false, accept = 'image/*', message = '', getIsFileRejected = null, handleCancelDialog, disabledInput = false,
}) {
    const { t } = useTranslation();

    const renderDragMessage = (isDragActive, fileRejections) => {
        if (!isDragActive) {
            return (
                <UploadMessage><img src={multiple ? UploadMultipleIcon : UploadIcon} alt="Upload" />
                    {message && (<small>{message}</small>)}
                </UploadMessage>
            );
        }

        if (fileRejections.lenght > 0) {
            return <UploadMessage type="error">{t(CONSTANTS.UPLOAD_ERROR_MESSAGE)}</UploadMessage>;
        }

        return (
            <UploadMessage type="success">
                {
                    multiple
                        ? t(CONSTANTS.UPLOAD_SUCCESS_MESSAGE_MULTIPLE)
                        : t(CONSTANTS.UPLOAD_SUCCESS_MESSAGE)
                }
            </UploadMessage>
        );
    };

    return (
        <>
            <Dropzone ref={dropzoneRef} multiple={multiple} accept={accept} onDropAccepted={onUpload} onFileDialogCancel={handleCancelDialog}>
                { ({ getRootProps, getInputProps, isDragActive, isDragReject, fileRejections }) => (
                    <DropContainer
                        className="upload-content"
                        {...getRootProps()}
                        isDragActive={isDragActive}
                        isDragReject={isDragReject}
                    >
                        <input disabled={disabledInput} multiple={multiple} {...getInputProps()} />
                        {renderDragMessage(isDragActive, fileRejections)}
                        { getIsFileRejected && getIsFileRejected(fileRejections)}
                    </DropContainer>
                )}
            </Dropzone>
        </>
    );
}

export default Upload;
