import styled, { css } from 'styled-components';

export const Container = styled.div`
    background-color: #212032;
    color: #fff;
    width: 100%;
    padding: 1rem 1rem;
    border-radius: 0.5rem;
    margin: 0 auto;
    div {
        width:100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
            text-transform: uppercase;
        }
        button {
            background-color: transparent;
            outline: none;
            border: 0;

            img {
                width: 20px;
            }
        }
    }


`;

export const Content = styled.div`
    background-color: #272637;
    padding: 1rem;
    display: none;
    transition: all 5s ease;

    ${({ showContent }) => showContent && css`
        display: flex;
        flex-direction: column;
    `}
`;
