import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import tutorialIcon from '@src/assets/tutorial-video.svg';
import constants from '@langs/constants';
import { HeaderContentMobile, ExpansionList } from './styles';
import AccordionMenu from '../AccordionMenu';
import Breadcrumbs from '../Breadcrumbs';
import SelectLanguage from '../SelectLanguage';
import UserSection from '../UserSection';
import MenuButton from '../MenuButton';
import logo from '../../assets/logo-kopa.svg';
import homeIcon from '../../assets/home-icon.svg';
import configIcon from '../../assets/config-icon.svg';
import videoIcon from '../../assets/video-icon.svg';
import userIcon from '../../assets/user-icon.svg';
import qrCodeIcon from '../../assets/qr-code-icon.svg';
import quizzesIcon from '../../assets/quizzes-icon.svg';
import paperIcon from '../../assets/paper-icon.svg';
import blockIcon from '../../assets/block-icon.svg';
import signOutIcon from '../../assets/sign-out-icon.svg';
import { logout } from '../../store/modules/auth/actions';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';

function MenuMobile({ setConfirmBlock }) {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showConfigMenu, setShowConfigMenu] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { breadcrumbs } = useBreadcrumbs();

    function toggleMenuMobile() {
        setShowMobileMenu(!showMobileMenu);
    }

    function handleSingleMenuClick(path = null) {
        setShowMobileMenu(!showMobileMenu);
        if (path) {
            history.push(path);
        }
    }

    function handleLogout() {
        setShowMobileMenu(!showMobileMenu);
        dispatch(logout());
    }

    return (
        <HeaderContentMobile>
            <div className={`--top ${showMobileMenu ? 'open' : ''}`}>
                <div className="logo-wrapper">
                    <img src={logo} alt="Kopa" />
                </div>

                <div className="user-wrapper">
                    <UserSection setConfirmBlock={setConfirmBlock} />
                </div>

                <div className="logo-wrapper">
                    <Breadcrumbs items={breadcrumbs} />
                </div>
                <div>
                    <MenuButton
                        open={showMobileMenu}
                        onClick={toggleMenuMobile}
                    />
                </div>
            </div>
            <div className={`menu-list ${showMobileMenu ? 'open' : ''}`}>
                <ul>
                    <li onClick={() => handleSingleMenuClick('/')}>
                        <div className="--menu-item">
                            <img src={homeIcon} alt="Home" />
                            {t(constants.HOME)}
                        </div>
                    </li>
                    <li>
                        <AccordionMenu
                            icon={<img src={configIcon} alt={t(constants.SETTINGS)} />}
                            title={t(constants.SETTINGS)}
                            isOpen={showConfigMenu}
                            toggle={() => setShowConfigMenu(!showConfigMenu)}
                        >
                            <ExpansionList>
                                <ul>
                                    <li onClick={() => handleSingleMenuClick('/')}>
                                        <div className="--sub-menu-item">
                                            <img
                                                src={videoIcon}
                                                alt="Meus Vídeos"
                                            />
                                            <p>{t(constants.CHANNEL_MYVIDEOS)}</p>
                                        </div>
                                    </li>
                                    <li onClick={() => handleSingleMenuClick('/users')}>
                                        <div className="--sub-menu-item">
                                            <img
                                                src={userIcon}
                                                alt={t(constants.USERS)}
                                            />
                                            <p>{t(constants.USERS)}</p>
                                        </div>
                                    </li>
                                    <li onClick={() => handleSingleMenuClick('/offers')}>
                                        <div className="--sub-menu-item">
                                            <img src={qrCodeIcon} alt="QR-CODE" />
                                            <p>{t(constants.OFFERS)}</p>
                                        </div>
                                    </li>
                                    <li onClick={() => handleSingleMenuClick('/quizzes')}>
                                        <div className="--sub-menu-item">
                                            <img src={quizzesIcon} alt="QUIZZES" />
                                            <p>{t(constants.QUIZZES)}</p>
                                        </div>
                                    </li>
                                    <li onClick={() => handleSingleMenuClick('/presenceReport')}>
                                        <div className="--sub-menu-item">
                                            <img src={paperIcon} alt={t(constants.REPORTS)} />
                                            <p>{t(constants.REPORTS)}</p>
                                        </div>
                                    </li>
                                    <li onClick={() => handleSingleMenuClick('/blockList')}>
                                        <div className="--sub-menu-item">
                                            <img src={blockIcon} alt={t(constants.BLOCK_LIST)} />
                                            <p>{t(constants.BLOCK_LIST)}</p>
                                        </div>
                                    </li>
                                </ul>
                            </ExpansionList>
                        </AccordionMenu>
                    </li>
                    <li onClick={() => handleSingleMenuClick('/tutorial')}>
                        <div className="--menu-item">
                            <img src={tutorialIcon} alt="tutorial" />
                            {t(constants.TUTORIALS)}
                        </div>
                    </li>
                    <li onClick={handleLogout}>
                        <div className="--menu-item emphasis">
                            <img src={signOutIcon} alt="Sair" />
                            {t(constants.LOGOUT)}
                        </div>
                    </li>
                </ul>

                <div className="footer">
                    <SelectLanguage position="bottom right" />
                </div>
            </div>
        </HeaderContentMobile>
    );
}

export default MenuMobile;
