import Tooltip from '@src/components/Tooltip';
import useRooms from '@src/hooks/useRooms';
import React from 'react';
import { useTranslation } from 'react-i18next';

function GoLiveButtonTitle({ canGoLive }) {
    const { statusLiveRoomEditSelected } = useRooms();
    const { t } = useTranslation();

    if (!statusLiveRoomEditSelected?.live && !canGoLive) {
        return t('CHANNEL', { context: 'GOLIVE_UNAVAILABLE' });
    }
    if (statusLiveRoomEditSelected && statusLiveRoomEditSelected.live) {
        return t('CHANNEL', { context: 'ENDLIVE' });
    }
    if (!statusLiveRoomEditSelected?.live) {
        return t('CHANNEL', { context: 'GOLIVE' });
    }
    return null;
}

export default function GoLiveButton({ canGoLive, handleGoLive }) {
    const { t } = useTranslation();
    const { statusLiveRoomEditSelected } = useRooms();

    return (
        <Tooltip content={
            !statusLiveRoomEditSelected?.live && !canGoLive ? t('CANT_GOLIVE_YET')
                : statusLiveRoomEditSelected?.live ? t('CLICK_TO_ENDLIVE')
                    : t('CLICK_TO_GOLIVE')
        }
        >
            <button
                disabled={(statusLiveRoomEditSelected && statusLiveRoomEditSelected?.offlive) || (!statusLiveRoomEditSelected?.live && !canGoLive)}
                onClick={handleGoLive}
                className={`goLiveButton ${!statusLiveRoomEditSelected?.live && !canGoLive && 'goLiveButtonDisabled'} `}
                type="submit"
            >
                <GoLiveButtonTitle canGoLive={canGoLive} />
            </button>
        </Tooltip>

    );
}
