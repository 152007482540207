import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonHeader from '@src/components/ButtonHeader';
import useRooms from '@src/hooks/useRooms';
import { fetchEditRoom, getChannelRooms, clearEditSelected } from '@src/store/modules/rooms/actions';
import { useHistory, useParams } from 'react-router-dom';
import useAuth from '@src/hooks/useAuth';
import arrowIcon from '@src/assets/arrow.svg';
import plusIcon from '@src/assets/plus-icon.svg';
import { getCaptions, toggleAddCaptionUploadModal } from '@src/store/modules/captions/actions';
import { useTranslation } from 'react-i18next';
import constantsLanguages from '@langs/constants';
import dashboardIcon from '@src/assets/dashboardIcon.svg';
import detailIcon from '@src/assets/shape.svg';
import { useSidebar } from '@src/hooks/useSidebar';
import CreateCourseUpload from './CourseRecordedUpload';
import CreateCourseForm from './CourseRecordedForm';
import {
    CreateCourseContainer,
    CreateCourseContent,
    CreateCourseFormArea,
    CreateCourseHeader,
    CreateCourseHeaderTitle,
} from './styles';
import AddCaptionModal from '../../components/AddCaptionModal';

const menuItemsListBuilder = (roomId) => ([
    {
        link: `/courseRecorded/${roomId}`,
        title: 'Detalhes',
        className: 'courseRecorded',
        dataTour: ' tour__details',
        icon: detailIcon,
        alt: 'details',
    },
    {
        link: `/videoStats/${roomId}`,
        title: 'Dashboard',
        className: 'videoStats',
        dataTour: 'tour__dashboard',
        icon: dashboardIcon,
        alt: 'dashboard',
    },
]);

const CourseRecorded = () => {
    const [selectedRoom, setSelectedRoom] = useState();
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [uploadedFile, setUploadedFile] = useState({});
    const [videoUrl, setVideoUrl] = useState('');
    const [isEditRoomState, setIsEditRoomState] = useState(false);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const history = useHistory();
    const { setItems } = useSidebar();
    const { id: roomId } = useParams();
    const {
        selectedEditRoom,
        selectedChannel,
        selectedChannelRooms,
    } = useRooms();
    const { user } = useAuth();

    useEffect(() => {
        setThumbnailUrl('');
        setUploadedFile({});
        setVideoUrl('');

        return () => {
            if (window.revokeObjectURL) {
                URL.revokeObjectURL(uploadedFile.preview);
            }
            dispatch(clearEditSelected());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedChannel?.id) {
            dispatch(getChannelRooms(selectedChannel.id));
        }
    }, [selectedChannel, dispatch]);

    useEffect(() => {
        if (roomId) {
            const foundRoom = selectedChannelRooms.find(item => item.id === roomId);
            setSelectedRoom(foundRoom);
        }
    }, [history, roomId, selectedChannelRooms, selectedEditRoom, selectedRoom]);

    useEffect(() => {
        if (selectedRoom && roomId) {
            dispatch(fetchEditRoom(roomId, selectedRoom));
        }
    }, [dispatch, roomId, selectedRoom, user]);

    useEffect(() => {
        if (selectedEditRoom && roomId) {
            setThumbnailUrl(selectedEditRoom?.image);
            setVideoUrl(selectedEditRoom?.videoUrl);
        }
    }, [selectedEditRoom, roomId]);

    useEffect(() => {
        setItems(menuItemsListBuilder(roomId));
    }, [roomId, setItems]);

    const handleBackToVideos = () => {
        history.push('/');
    };

    function handleShowUploadCaption() {
        dispatch(toggleAddCaptionUploadModal(true));
    }

    useEffect(() => {
        if (roomId) {
            dispatch(getCaptions(roomId, true));
        }
    }, [dispatch, roomId]);

    return (
        <CreateCourseContainer>
            <CreateCourseContent>
                <CreateCourseHeader>
                    <CreateCourseHeaderTitle> {t(constantsLanguages.VIDEO_SETTINGS)} </CreateCourseHeaderTitle>
                    <div className="header-buttons-holder">
                        {
                            isEditRoomState && (
                                <ButtonHeader
                                    onClick={handleShowUploadCaption}
                                    icon={plusIcon}
                                    title={t(constantsLanguages.NEW_CAPTION)}
                                />
                            )
                        }
                        <ButtonHeader
                            onClick={handleBackToVideos}
                            icon={arrowIcon}
                            title={t(constantsLanguages.BACK_TO_VIDEOS)}
                        />
                    </div>
                </CreateCourseHeader>
                <AddCaptionModal />
                <CreateCourseFormArea>
                    <CreateCourseUpload
                        uploadedFile={uploadedFile}
                        setUploadedFile={setUploadedFile}
                        setThumbnailUrl={setThumbnailUrl}
                        thumbnailUrl={thumbnailUrl}
                        videoUrl={videoUrl}
                        selectedEditRoom={selectedEditRoom}
                    />
                    <CreateCourseForm
                        getEditRoomState={setIsEditRoomState}
                        setUploadedFile={setUploadedFile}
                        setThumbnailUrl={setThumbnailUrl}
                        roomId={roomId}
                        thumbnailUrl={thumbnailUrl}
                    />
                </CreateCourseFormArea>
            </CreateCourseContent>
        </CreateCourseContainer>
    );
};

export default CourseRecorded;
