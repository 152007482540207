import { all, takeLatest, put } from 'redux-saga/effects';
import {
    clearAll, editProfileFail, editProfileSuccess, getUserError, setUser, setOnBoardingStatus,
} from './actions';
import history from '../../../services/history';
import api from '../../../services/api';
import { defaultErrorHandler } from '../../utilities';
import ChatSocket from '../../../services/ChatSocket';
import { checkUserTokenLocalStorage, translate } from '../../../utils/functions';
import Notify from '../../../utils/notification';

function* logout() {
    try {
        localStorage.removeItem('@selectedChannel');
        localStorage.removeItem('KopaLive');
        yield ChatSocket.disconnect(true);
        yield api.logout();
    } catch (error) {
        const message = defaultErrorHandler(error, { default: translate('FAIL_LOGOUT') });
        console.log('logout error', error, message);
    } finally {
        window.location.replace('/');
    }
}

function* setToken() {
    const token = JSON.parse(localStorage.getItem('KopaLive'));
    if (token) {
        api.setToken(token);
        try {
            const { session_token: session } = yield api.createSession();
            yield ChatSocket.connect(token.accessToken, session);
        } catch (error) {
            console.log('Falha ao conectar com websocket');
            history.push('/networkFailed');
        }
    } else {
        yield put(clearAll());
    }
}

function* changeLanguage({ payload }) {
    try {
        yield api.changeUserLanguage(payload);
        const { data } = yield api.getUser();
        yield put(setUser(data));
    } catch (error) {
        const message = defaultErrorHandler(error, { default: translate('FAIL_CHANGELANGUAGE') });
        console.log('change language error', error, message);
    }
}

function* editProfileRequest({ payload }) {
    try {
        const res = yield api.editProfile(payload);
        yield put(editProfileSuccess(payload));

        if (res.status !== 200) {
            throw res;
        }
        const { data } = yield api.getUser();
        yield put(setUser(data));
        Notify.success(translate('PROFILE_UPDATED'));
    } catch (error) {
        let message = translate('ERROR_NONETWORK');
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    message = translate('ERROR_PARAMETERS');
                    break;
                case 401:
                    message = translate('ERROR_CREDENTIALS');
                    break;
                default:
                    message = translate('ERROR_NONETWORK');
            }
        }
        console.log('update profile error', error, message);
        yield put(editProfileFail(message));
        Notify.error(translate('PROFILE_UPDATE_FAILED'));
    }
}

function* getUserProfile() {
    checkUserTokenLocalStorage();
    try {
        const { data } = yield api.getUser();
        yield put(setUser(data));
    } catch (error) {
        yield put(getUserError(error));
        defaultErrorHandler(error, { default: translate('ERROR_NONETWORK') });
    }
}

function* setOnBoarding({ payload }) {
    const { key, skipped, global_skip } = payload;

    try {
        yield api.setOnBoarding(key, skipped, global_skip);
        const onBoarding = yield api.getUserOnBoarding();
        yield put(setOnBoardingStatus(onBoarding));
    } catch (error) {
        console.log('error -> ', error);
    }
}

function* changeOnBoardingStatus() {
    try {
        const onBoarding = yield api.getUserOnBoarding();
        yield put(setOnBoardingStatus(onBoarding));
    } catch (error) {
        console.log('error -> ', error);
    }
}
export default all([
    takeLatest('SET_TOKEN', setToken),
    takeLatest('LOGOUT', logout),
    takeLatest('CHANGE_USER_LANGUAGE', changeLanguage),
    takeLatest('GET_USER_PROFILE', getUserProfile),
    takeLatest('EDIT_PROFILE_REQUEST', editProfileRequest),
    takeLatest('SET_ONBOARDING', setOnBoarding),
    takeLatest('CHANGE_ONBOARDING_STATUS', changeOnBoardingStatus),
]);
